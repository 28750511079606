import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {UserAccountKeycloak} from "../../../../services/UserAccountKeycloak";
import {
  DashboardControllerService, NotificationControllerService,
  NotificationSearchPojo,
  QueryResultsPojoNotificationSearchPojo
} from "dd-core-api-sdk";
import {BehaviorSubject} from "rxjs";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {Router} from "@angular/router";
import {environment} from "../../../../../environments/environment.prod";
import {IClipboardResponse} from "ngx-clipboard";
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'app-affiliate-dashboard-header',
  templateUrl: './affiliate-dashboard-header.component.html',
  styleUrls: ['./affiliate-dashboard-header.component.css']
})
export class AffiliateDashboardHeaderComponent implements OnInit {

  userAccount: UserKeycloak;
  @Input() user: UserKeycloak;
  userAccountKeycloak: UserAccountKeycloak;
  notificationData: Array<NotificationSearchPojo>;
  resultSubject = new BehaviorSubject<QueryResultsPojoNotificationSearchPojo>({});
  @Output()
  emitNotificationTotal = new EventEmitter();
  officeName: string | undefined;
  pictureId: any;
  showRoute: boolean;
  showToolTip: boolean = false;
  showLinkCopied: boolean = false;

  constructor(private authentication: AuthenticationServiceKeycloak,
              private router: Router,
              private dashboardControllerService: DashboardControllerService,
    private notificationService: NotificationControllerService ) {
  }

  ngOnInit(): void {

    this.authentication.getUser().subscribe(res => {
      this.userAccount = res;
      this.officeName = res.firstName + " " + res.lastName;
      this.getNotifications(res?.id);
    });

    setInterval(() => {
      this.getNotifications(this.userAccount.id);
    }, 30000);

    this.authentication.getAccount().subscribe(v => {
      this.showRoute = this.shouldShowRoute(v);
    })
  }



  getNotifications(portalUserId) {
    this.notificationService.searchNotification({
      filter: {
        isAffiliate: true,
        limit: 3,
        portalUserId: portalUserId
      }
    }).subscribe(res => {
      this.notificationData = res.results;
      this.resultSubject.next(res);
      this.emitNotificationTotal.next(this.resultSubject.value.total);
    }, err => {
    });
  }

  getFileURL() {
    if (this.pictureId?.value?.id != null && this.pictureId?.value?.id != '') {
      return environment.siteUrl + "/files/" + this.pictureId.value.id;
    }
    return "./assets/img/user.png";
  }

  updateReferralStatus(id) {
    this.notificationService.updateNotification({id: id}).subscribe(res => {
      // @ts-ignore
      this.router.navigate(['/in-bound/referrals/', res.id]);
    })
  }

  shouldShoToolTip(){
    this.showToolTip = true;
  }

  logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.authentication.logoutClick(window.location.origin);
  }

  shouldShowRoute(account: UserAccountKeycloak) {
    if (!account?.otherData?.specialityOffice) {
      return true;
    }
    return ((account?.dentalOfficeSubscription?.subscriptionStatus == "ACTIVE"
        || account?.dentalOfficeSubscription?.subscriptionStatus == "TRIAL")
      && account?.otherData?.specialityOffice);
  }

  contentCopied($event: IClipboardResponse) {
    this.showLinkCopied = true;

    setTimeout(v =>{
      this.showLinkCopied = false;
    }, 2000);
  }

  environmentUrl(): any {
    return environment.url;
  }
}
