/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkHistoryPojo } from './work-history-pojo';
import { DentalProfessionalLicencePojo } from './dental-professional-licence-pojo';
import { NameAndCodeDto } from './name-and-code-dto';
import { WorkAddressPojo } from './work-address-pojo';
import { AgeGroup } from './age-group';
import { GroupedCountString } from './grouped-count-string';
import { AddressPojo } from './address-pojo';
import { Title } from './title';
import { DentalOfficePojo } from './dental-office-pojo';
import { PortalUserPojo } from './portal-user-pojo';


export interface DentalProfessionalPojo { 
    id?: number;
    name?: string;
    title?: Title;
    email?: string;
    mobileNumber?: string;
    displayPictureId?: number;
    userId?: string;
    website?: string;
    availableForAppointment?: boolean;
    appointmentId?: number;
    licenses?: Array<DentalProfessionalLicencePojo>;
    dentalOffices?: Array<DentalOfficePojo>;
    specializations?: Array<NameAndCodeDto>;
    endorsements?: Array<GroupedCountString>;
    languages?: Array<NameAndCodeDto>;
    address?: AddressPojo;
    professionalSummary?: string;
    facebookUrl?: string;
    twitterUrl?: string;
    linkedInUrl?: string;
    ageGroups?: Array<AgeGroup>;
    educationHistoryCount?: number;
    gender?: DentalProfessionalPojo.GenderEnum;
    status?: DentalProfessionalPojo.StatusEnum;
    approvalStatus?: DentalProfessionalPojo.ApprovalStatusEnum;
    reasonForDenial?: string;
    dateCreated?: string;
    dateOfApprovalProcessing?: string;
    dateOfBirth?: string;
    schoolAddressCount?: number;
    homeAddressCount?: number;
    officeAddressCount?: number;
    workHistoryCount?: number;
    approvalProcessedBy?: PortalUserPojo;
    favouriteCount?: number;
    likeCount?: number;
    dislikeCount?: number;
    currentUserFavoriteStatus?: DentalProfessionalPojo.CurrentUserFavoriteStatusEnum;
    workAddressPojos?: Array<WorkAddressPojo>;
    workHistoryPojos?: Array<WorkHistoryPojo>;
    currentUserFavorite?: boolean;
}
export namespace DentalProfessionalPojo {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
    export type CurrentUserFavoriteStatusEnum = 'LIKE' | 'UNLIKE' | 'DISLIKE';
    export const CurrentUserFavoriteStatusEnum = {
        Like: 'LIKE' as CurrentUserFavoriteStatusEnum,
        Unlike: 'UNLIKE' as CurrentUserFavoriteStatusEnum,
        Dislike: 'DISLIKE' as CurrentUserFavoriteStatusEnum
    };
}


