<div class="card-header text-center m-0 border-0 p-4">
    <span class="float-start pointer">
    <iconify-icon awPreviousStep class="iconify fs-4 ms-auto pointer" icon="ic:baseline-arrow-back"></iconify-icon>
  </span>
    <span class="h4 text-center text-truncate p-0 m-0" style="width:fit-content;">Account Details</span>

    <span class="float-end pointer">
    <iconify-icon (click)="dismissButtonClicked.emit()" class="iconify fs-4 ms-auto pointer" icon="mdi:close"
                  data-bs-dismiss="alert"></iconify-icon>
  </span>
</div>
<div class="card-body px-3">
    <div class="content">
        <div *ngIf="showErrorMessageTrigger">
            <div class="alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert" [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                <p class="m-0 p-0 d-flex align-items-start">
                    <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ getErrorMessage() | removeUnderscores }}
              </span>
                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                </p>
            </div>
        </div>
        <form class="container" [formGroup]="formGroup">
            <p class="h5 mt-1 text-start heading-text">{{this.socialService?.bankCodeSubject?.value?.name ? this.socialService?.bankCodeSubject?.value?.name : 'BANK DETAILS'}}</p>
            <div class="mb-3" *ngIf="!this.socialService?.bankCodeSubject?.value?.name">
                <label for="bankName" class="form-label float-start">Bank Name</label>
                <input type="text" class="form-control" formControlName="bankName" id="bankName" placeholder="Wells fargo">
                <app-field-error *ngIf="(formGroup.get('bankName')?.errors?.required && formGroup.get('bankName')?.touched) || (formGroup.get('bankName')?.errors?.required && errorSubmission)
                      " [message]="'Bank name cannot be blank.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('bankName')?.errors?.minlength && formGroup.get('bankName')?.touched) ||
                        (formGroup.get('bankName')?.errors?.minlength && errorSubmission)
                      " [message]="'Bank name cannot be less than two(2) characters.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('bankName')?.errors?.maxlength && formGroup.get('bankName')?.touched) ||
                      (formGroup.get('bankName')?.errors?.maxlength && errorSubmission)" [message]="'Bank name cannot be more than fifty(50) characters.'"></app-field-error>
            </div>
            <div class="mb-3">
                <label for="accountName" class="form-label float-start">Account Name</label>
                <input type="text" class="form-control" formControlName="accountName" id="accountName" placeholder="Mary John">
                <app-field-error *ngIf="(formGroup.get('accountName')?.errors?.required && formGroup.get('accountName')?.touched) || (formGroup.get('accountName')?.errors?.required && errorSubmission)
                      " [message]="'Account name cannot be blank.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('accountName')?.errors?.minlength && formGroup.get('accountName')?.touched) ||
                        (formGroup.get('accountName')?.errors?.minlength && errorSubmission)
                      " [message]="'Account name cannot be less than two(2) characters.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('accountName')?.errors?.maxlength && formGroup.get('accountName')?.touched) ||
                      (formGroup.get('accountName')?.errors?.maxlength && errorSubmission)" [message]="'Account name cannot be more than fifty(50) characters.'"></app-field-error>
            </div>

            <div class="mb-3">
                <label for="rnumber" class="form-label float-start">Routing Number</label>
                <input type="text" class="form-control" formControlName="routingNumber" id="rnumber" placeholder="100000001" (keypress)="numberOnly($event)">
                <app-field-error *ngIf="(formGroup.get('routingNumber')?.errors?.required && formGroup.get('routingNumber')?.touched) || (formGroup.get('routingNumber')?.errors?.required && errorSubmission)
                      " [message]="'Routing number cannot be blank.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('firstName')?.errors?.minlength && formGroup.get('routingNumber')?.touched) ||
                        (formGroup.get('routingNumber')?.errors?.minlength && errorSubmission)
                      " [message]="'Routing number cannot be less than nine(9) characters.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('routingNumber')?.errors?.maxlength && formGroup.get('routingNumber')?.touched) ||
                      (formGroup.get('routingNumber')?.errors?.maxlength && errorSubmission)" [message]="'Routing number cannot be more than nine(9) characters.'"></app-field-error>
            </div>

            <div class="mb-3">
                <label for="accnumber" class="form-label float-start">Account Number</label>
                <input type="text" class="form-control" formControlName="accountNumber" id="accnumber" (keypress)="numberOnly($event)" placeholder="000123456789">
                <app-field-error *ngIf="(formGroup.get('accountNumber')?.errors?.required && formGroup.get('accountNumber')?.touched) || (formGroup.get('accountNumber')?.errors?.required && errorSubmission)
                      " [message]="'Account number cannot be blank.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('accountNumber')?.errors?.minlength && formGroup.get('accountNumber')?.touched) ||
                        (formGroup.get('accountNumber')?.errors?.minlength && errorSubmission)
                      " [message]="'Account number cannot be less than six(6) characters.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('accountNumber')?.errors?.maxlength && formGroup.get('accountNumber')?.touched) ||
                      (formGroup.get('accountNumber')?.errors?.maxlength && errorSubmission)" [message]="'Account number cannot be more than twenty(20) characters.'"></app-field-error>
            </div>

            <div class="mb-3">
                <label for="confAccountNumber" class="form-label float-start">Confirm Account Number</label>
                <input type="text" class="form-control" formControlName="confAccountNumber" id="confAccountNumber" (keypress)="numberOnly($event)" placeholder="000123456789">

                <app-field-error *ngIf="formGroup.get('confAccountNumber')!.errors?.accountMismatch" [message]="
                        'Account number and confirm number must be the same.'"></app-field-error>
                <app-field-error *ngIf="(formGroup.get('confAccountNumber')?.errors?.required && formGroup.get('confAccountNumber')?.touched) ||
                        (formGroup.get('confAccountNumber')?.errors?.required && errorSubmission)" [message]="'Confirm account number cannot be blank.'"></app-field-error>
            </div>
            <button class="btn btn-md btn-dark mb-4 mt-3 button py-2 w-100" [disabled]="saving" (click)="this.formGroup.invalid ? submissionForError() : saveBankDetails()">Submit
      </button>
        </form>
    </div>
</div>