import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {WebcamImage, WebcamInitError, WebcamMirrorProperties, WebcamUtil} from 'ngx-webcam';
import {Observable, Subject} from "rxjs";
import {SocialService} from "../../../shared/subject/SocialService";

@Component({
  selector: 'app-insurance-image-capture',
  templateUrl: './insurance-image-capture.component.html',
  styleUrls: ['./insurance-image-capture.component.css']
})
export class InsuranceImageCaptureComponent implements OnInit, AfterViewInit {

  @Output()
  dismissClicked = new EventEmitter<any>();
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  canRecapture = false;
  canRecaptureBackImage = false;
  public videoOptions: MediaTrackConstraints = window.innerWidth > 560 ? {width: 1000} : {width: 140, height: 280};
  canSnap = false;
  canSnapBackImage = false;
  public errors: WebcamInitError[] = [];
  // latest snapshot
  public webcamImage: WebcamImage = null;
  public webcamImageBackImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private triggerBackCamera: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  tabIndex = this.socialService.tabSubject.value;
  @Output()
  emmitFrontImageBase64 = new EventEmitter<WebcamImage>();
  @Output()
  emmitBackImageBase64 = new EventEmitter<WebcamImage>();

  constructor(private bsModalRef: BsModalRef, public socialService: SocialService) {
    setTimeout(() => {
      this.canSnap = true;
      this.canSnapBackImage = true;
    }, 3000);
  }

  ngOnInit(): void {
  }

  dismissModal() {
    this.bsModalRef.hide();
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name == "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
      error.message = 'You need to allow access to a camera on your device!'
    }
    console.log(error)
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;

    console.log(this.webcamImage)
  }

  public handleBackImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImageBackImage = webcamImage;

    console.log(this.webcamImage)
  }

  public getSnapshot(): void {
    this.trigger.next(void 1);
  }

  public getSnapshotBackCamera(): void {
    this.triggerBackCamera.next(void 1);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get triggerObservableBackCamera(): Observable<void> {
    return this.triggerBackCamera.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  private checkForMultipleCameraDevice() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        if (this.multipleWebcamsAvailable) {
          this.triggerCheckOfDeviceRemovedAbruptly();
        }
      });
  }

  private triggerCheckOfDeviceRemovedAbruptly() {
    setTimeout(() => {
      if (this.multipleWebcamsAvailable) {
        WebcamUtil.getAvailableVideoInputs()
          .then((mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            if (this.multipleWebcamsAvailable) {
              this.triggerCheckOfDeviceRemovedAbruptly();
            } else {
              this.showNextWebcam(true);
            }
          });
      }
    }, 100);
  }

  reCapture() {
    this.webcamImage = null;
    this.canRecapture = false;
    this.canSnap = false;
    setTimeout(() => {
      this.canSnap = true;
    }, 2000);
    this.ngAfterViewInit();
  }

  reCaptureBackCamera() {
    this.webcamImageBackImage = null;
    this.canRecaptureBackImage = false;
    this.canSnapBackImage = false;
    setTimeout(() => {
      this.canSnapBackImage = true;
    }, 2000);
    this.ngAfterViewInit();
  }

  changeTab(newIndex: number) {
    this.tabIndex = newIndex;
    this.socialService.tabSubject.next(newIndex);

    if (newIndex == 1 && this.webcamImageBackImage == null) {
      this.canSnapBackImage = false;
      setTimeout(() => {
        this.canSnapBackImage = true;
      }, 2000);
    }
    if (newIndex == 0 && this.webcamImageBackImage == null) {
      this.canSnap = false;
      setTimeout(() => {
        this.canSnap = true;
      }, 2000);
    }

  }

  saveImage(){
    this.emmitFrontImageBase64.emit(this.webcamImage);
    this.emmitBackImageBase64.emit(this.webcamImageBackImage);
  }

  ngAfterViewInit(): void {
    this.checkForMultipleCameraDevice();
  }

}
