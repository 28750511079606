import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PhoneNumberValidator} from "dd-shared-lib";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {Router} from "@angular/router";
import {RegisterUserRequestParams, UserRegistrationDto, UserRegistrationService} from "dd-core-api-sdk";
import {UniqueIdValidatorService} from "../../../shared/unique-id-validator.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {SocialAuthService} from 'angularx-social-login';
import {SuccessModalComponent} from "../../../shared/success-modal/success-modal.component";
import {KeycloakService} from "keycloak-angular";
import {SocialService} from "../../../shared/subject/SocialService";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import * as uuid from 'uuid';
import {environment as env} from '../../../../environments/environment'
import {AccountCreatedSuccessComponent} from "../../account-created-success/account-created-success.component";
import {InvitesModel} from "../../../shared/invites-model";
import {JWT_OPTIONS, JwtHelperService} from "@auth0/angular-jwt";


@Component({
  selector: 'app-create-dental-professional',
  templateUrl: './create-dental-professional.component.html',
  styleUrls: ['./create-dental-professional.component.css'],
  providers: [{provide: JWT_OPTIONS, useValue: JWT_OPTIONS}, JwtHelperService]
})


export class CreateDentalProfessionalComponent implements OnInit, AfterViewInit {

  @Input()
  invitesData: InvitesModel;
  @Output()
  emitOfficeNotFoundLink = new EventEmitter<any>();
  form: FormGroup;
  errorSubmission: boolean;
  @Input()
  user: UserKeycloak;
  displayPassword!: boolean;
  emailExistError: string = 'none';
  public submitted!: boolean;
  submitting: boolean;
  submittingSocial: boolean;
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  strongPassword: boolean;

  placesConfig: any = {
    componentRestrictions: {country: ['US'],}
    // ,
    // types: ['hospital', 'dentist', 'doctor','drugstore', 'pharmacy']
  }

  constructor(private fb: FormBuilder,
              private router: Router,
              private keycloakService: KeycloakService,
              private authenticationService: AuthenticationServiceKeycloak,
              private userRegistration: UserRegistrationService,
              private uniqueIdValidatorService: UniqueIdValidatorService,
              private modalService: BsModalService,
              private jwtHelper: JwtHelperService,
              private socialService: SocialService) {
  }


  ngAfterViewInit(): void {

    if (this.socialService.tabSubject.value == 1)
      // @ts-ignore
      google.accounts.id.initialize({
        context: "signup",
        client_id: env.social.google.clientId,
        callback: (response) => {
          let scope = this.jwtHelper.decodeToken(response.credential)
          this.loginWithGoogle({
            email: scope.email,
            firstName: scope.given_name,
            lastName: scope.family_name,
            image: scope.picture
          })
        },
        auto_select: false,
      });
    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById("googleBtn"),
      {theme: "outline", size: "medium",}  // customization attributes
    );
    // @ts-ignore
    google.accounts.id.prompt(); // also display the One Tap dialog
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
    if (!event)
      this.form.controls['newPassword'].setErrors({'notStrong': !this.strongPassword});
  }

  ngOnInit(): void {
    this.submitted = true;
    this.form = this.fb.group({
      firstName: [this.invitesData?.firstName || '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: [this.invitesData?.lastName || '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      email: [this.invitesData?.email || '', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$")]],
      phoneNumber: [this.invitesData?.phoneNumber || '', [Validators.required, PhoneNumberValidator()]],
      newPassword: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      officeName: [this.invitesData?.officeName || '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      acceptTerms: [false, Validators.requiredTrue]
    });
    /* Password Algorithm Used --- Minimum of four characters, Maximum of 25,  */
    this.form.get('email')?.setAsyncValidators([this.uniqueIdValidatorService.verifier('email', 'emailExists')]);
    this.form.get('officeName')?.setAsyncValidators([this.uniqueIdValidatorService.OfficeVerifier( 'officeNotFound')]);

    if (this.socialService.tabSubject.value == 1)
      // @ts-ignore
      google.accounts.id.initialize({
        context: "signup",
        client_id: env.social.google.clientId,
        callback: (response) => {
          let scope = this.jwtHelper.decodeToken(response.credential)
          this.loginWithGoogle({
            email: scope.email,
            firstName: scope.given_name,
            lastName: scope.family_name,
            image: scope.picture
          })
        },
        auto_select: false,
      });
    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById("googleBtn"),
      {theme: "outline", size: "medium",}  // customization attributes
    );
    // @ts-ignore
    google.accounts.id.prompt(); // also display the One Tap dialog

  }

  submit() {
    this.socialService.behaviorSubject.next('');
    sessionStorage.clear();
    this.submitting = true;
    this.userRegistration.registerUser(this.buildPayload()).subscribe(res => {
      this.submitting = false;
      this.loginUserManually(res.email, this.form.getRawValue().newPassword, this.form.getRawValue().phoneNumber?.e164Number)
    }, error => {
      this.submitting = false;
    });
  }

  submittedForError() {
    this.errorSubmission = true;
  }

  async login() {
    if (this.user) {
      this.authenticationService.logout();
    }

    await this.authenticationService.login({
      redirectUri: window.location.origin + '/dashboard',
      scope: 'offline_access',
      prompt: 'login',
    });
  }

  togglePasswordDisplay() {
    this.displayPassword = !this.displayPassword;
  }

  buildPayload(): RegisterUserRequestParams {
    const form = this.form.getRawValue();

    return {
      userRegistrationDto: {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        mobileNumber: form.phoneNumber.e164Number,
        password: form.newPassword,
        dentalOfficeName: form.officeName,
        userType: UserRegistrationDto.UserTypeEnum.DentalProfessional,
        inviteId: this.invitesData ? +this.invitesData?.code : null
      }
    }

  }

  showModal(component: any, email: string, phone: string, message?: string, isSuccess?: boolean, close?: boolean, dashboard?: boolean) {
    let bsModalRef = this.modalService.show(component, {
      initialState: {
        modalClass: 'modal-sm',
        body: message ? message : 'An email has been sent to you. Please check for an email from\n' +
          '        Dental Door and click on the link to activate your account.',
        email: email,
        accountType: "Dental Professional",
        check: isSuccess,
        fail: !isSuccess,
        close: close,
        routeLink: dashboard ? "/dashboard" : "/verify-email",
        phone: phone
      },
      keyboard: false,
      class: 'modal-lg modal-dialog-centered',
      ignoreBackdropClick: true,
    });

    //@ts-ignore
    bsModalRef?.content?.onClick.subscribe(v => {
      this.router.navigate(['/verify-email'])
      bsModalRef.hide();
    });

  }

  loginUserManually(username: string, password: string, phone: string, showDashboard?: boolean) {
    this.userRegistration.login({loginDto: {username: username, password: password}}).subscribe(res => {
      this.submittingSocial = false;
      this.socialService.save(res.accessToken);
      sessionStorage.setItem('keycloak_token', res.accessToken);
      this.showModal(
        AccountCreatedSuccessComponent, username, phone,
        'Account created successfully. Click the button below to receive an OTP on ',
        true, true, showDashboard);
    }, err => {

    })
  }

  preCreateSocialAccount(email, lastName, firstName, image) {
    this.socialService.isInterceptor.next(false);
    sessionStorage.removeItem("keycloak_token");

    this.submittingSocial = true;
    let password = uuid.v4();
    this.userRegistration.registerSocialUser({
      socialMedialSignUpDto: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        generatedPassword: password,
        accountType: "DENTAL_SPECIALIST",
        userType: "DENTAL_PROFESSIONAL",
        inviteId: this.invitesData ? this?.invitesData.code : null,
        profileImageUrl: image
      }
    }).subscribe(next => {
      this.loginUserManually(email, password, null, true);
      sessionStorage.setItem("REQUESTED_OTP", "TRUE");
    }, error => {
      this.submittingSocial = false;
      if (error.status == '422') {
        this.showModal(SuccessModalComponent, email, null, error.error.message, false, true);
        this.emailExistError = 'block';
      }
    });
  }

  loginWithGoogle(response: any) {
    this.preCreateSocialAccount(response.email, response.lastName, response.firstName, response.image);
  }

  getPlacesAutocomplete(place: any) {
    this.form.get('officeName').patchValue(place.name);
  }

}//
