/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferralRequest } from './referral-request';
import { PortalUser } from './portal-user';


export interface PortalAccount { 
    name?: string;
    type?: PortalAccount.TypeEnum;
    dateCreated?: string;
    lastUpdated?: string;
    dateDeactivated?: string;
    status?: PortalAccount.StatusEnum;
    code?: string;
    id?: number;
    deactivatedBy?: PortalUser;
    createdBy?: PortalUser;
    referralBies?: Array<ReferralRequest>;
}
export namespace PortalAccount {
    export type TypeEnum = 'DENTAL_DOOR_ADMIN' | 'DENTAL_OFFICE' | 'DENTAL_PROFESSIONAL' | 'DENTAL_SPECIALIST' | 'DENTAL_SPECIALIST_OFFICE' | 'SUPER_ADMIN' | 'AFFILIATE';
    export const TypeEnum = {
        DentalDoorAdmin: 'DENTAL_DOOR_ADMIN' as TypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as TypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as TypeEnum,
        DentalSpecialist: 'DENTAL_SPECIALIST' as TypeEnum,
        DentalSpecialistOffice: 'DENTAL_SPECIALIST_OFFICE' as TypeEnum,
        SuperAdmin: 'SUPER_ADMIN' as TypeEnum,
        Affiliate: 'AFFILIATE' as TypeEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


