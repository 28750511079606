import { Component, OnInit } from '@angular/core';
import {SocialService} from "../../../shared/subject/SocialService";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-create-office-affiliate',
  templateUrl: './create-office-affiliate.component.html',
  styleUrls: ['./create-office-affiliate.component.css']
})
export class CreateOfficeAffiliateComponent implements OnInit {

  tabIndex = this.socialService.tabSubject.value;
  type: string;
  affiliateCode: any;

  constructor(private _activatedRoute: ActivatedRoute, public socialService: SocialService) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(param => {
      if (param['code']) {
        this.affiliateCode = param['code'];
      }
    });
  }

  changeTab(newIndex: number) {
    this.tabIndex = newIndex;
    this.socialService.tabSubject.next(newIndex);
  }
}
