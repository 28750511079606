import {Component, OnInit} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {BsModalService} from "ngx-bootstrap/modal";
import {UpdateCreditCardComponent} from "../update-credit-card/update-credit-card.component";
import {UserAccountKeycloak} from "../../../services/UserAccountKeycloak";

@Component({
  selector: 'app-subscription-warning',
  templateUrl: './subscription-warning.component.html',
  styleUrls: ['./subscription-warning.component.css']
})
export class SubscriptionWarningComponent implements OnInit {
  user: UserKeycloak;
  account: UserAccountKeycloak;

  constructor(private authenticationService: AuthenticationServiceKeycloak,
              private modalService: BsModalService) {}

  ngOnInit(): void {
    this.account = AuthenticationServiceKeycloak._currentUserAccount;
    this.authenticationService.getUser().subscribe(res => {
      this.user = res;
    });

  }

  completionModal() {
    let bsModalRef = this.modalService.show(UpdateCreditCardComponent, {
      initialState: {
      },
      keyboard: false,
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered'
    });
  }

}
