<div class="container mt-4">
  <div class="row">
    <div class="d-flex align-items-center">
      <p class="fs-3 textmuted fw-bold my-3">
        <iconify-icon
          inline
          icon="codicon:dashboard"
          class="fs-3 me-2"
        ></iconify-icon
        >Dashboard Overview
      </p>
    </div>

    <!-- search filter  -->
    <div class="my-4">
      <div class="container px-0">
        <div class="row g-2">
          <div class="col-xl col-12 pe-md-4">
            <div class="pb-5" >
              <div
                stepPosition="bottom"
                (click)="goToInvite()"
                *ngIf="!this.user?.dentalProfessional"
                style="border-radius: 10px; !important; border: 2px solid #369dbf;"
                class="pointer btn refer-btn-custom-top d-flex align-items-center p-2 justify-content-between"
              >
                <h4 class="mb-0 mb-0 mx-2">Click To Invite Dental and Specialist Offices</h4>
                <div
                  class="badge rounded-pill text-bg-primary p-1 d-flex justify-content-end align-items-center text-end mb-0 mx-1"
                >
                  <iconify-icon icon="carbon:add" class="fs-5"></iconify-icon>
                </div>
              </div>
            </div>
            <div class="overview-card rounded-4 p-4">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-xl-4 d-flex flex-column px-3">
                    <div>
                      <h5 class="text-muted">Referral Request</h5>
                      <p class="text-muted small">
                        Referral Request awaiting scheduling.
                      </p>
                    </div>
                    <h1 class="text-primary mt-4">
                      {{ this.awaitingReferral || 0 }}
                    </h1>

                    <div class="d-flex justify-content-end">
                      <div
                        class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2"
                      >
                        <p
                          class="mb-0 mx-2 dddb-card-btn pointer"
                          style="line-height: 23px"
                          [routerLink]="'/out-bound/create-referral'"
                        >
                          Refer a patient
                        </p>
                        <div
                          class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0 icon-hld"
                        >
                          <iconify-icon
                            icon="prime:angle-right"
                            class="fs-5"
                          ></iconify-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-xl-4 my-5 my-xl-0 d-flex flex-column px-3 dashb-card-border"
                  >
                    <div>
                      <h5 class="text-muted">Upcoming Appointment</h5>
                      <p class="small text-muted">
                        Scheduled referral appointment
                      </p>
                    </div>
                    <h1 class="text-primary mt-4">
                      {{ this.upcomingAppointment || 0 }}
                    </h1>

                    <div class="d-flex justify-content-end">
                      <div
                        class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2"
                      >
                        <p
                          class="mb-0 mx-2 dddb-card-btn pointer"
                          style="line-height: 23px"
                          [routerLink]="'/in-bound'"
                        >
                          View Scheduled
                        </p>
                        <div
                          class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0"
                        >
                          <iconify-icon
                            icon="prime:angle-right"
                            class="fs-5"
                          ></iconify-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 d-flex flex-column px-3">
                    <div>
                      <h5 class="text-muted">Appointments Today</h5>

                      <p class="text-muted small">
                        Scheduled appointments due for today.
                      </p>
                    </div>
                    <h1 class="text-primary mt-4">
                      {{ this.appointmentsToday || 0 }}
                    </h1>

                    <div class="d-flex justify-content-end">
                      <div
                        class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2"
                      >
                        <p
                          class="mb-0 mx-2 dddb-card-btn pointer"
                          style="line-height: 23px"
                          [routerLink]="'/in-bound'"
                        >
                          View referrals
                        </p>
                        <div
                          class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0"
                        >
                          <iconify-icon
                            icon="prime:angle-right"
                            class="fs-5"
                          ></iconify-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overview-card p-4 mt-4" style="border-radius: 1rem;">
              <div
                class="d-flex align-items-center flex-wrap justify-content-between mb-3"
              >
                <h5 class="mb-0 text-muted">Referral Statistics</h5>
                <form class="text-end d-block" [formGroup]="form">
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    formControlName="years"
                  >
                    <option *ngFor="let v of years" [value]="v">{{ v }}</option>
                  </select>
                </form>
              </div>

              <div class="d-flex justify-content-center align-items-center">
                <canvas
                  *ngIf="!isChartDataEmpty; else elseBlock"
                  baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [chartType]="barChartType"
                >
                </canvas>

                <ng-template #elseBlock>
                  <div class="text-center">
                    <img
                      src="/assets/img/dataEmpty.png"
                      class="img-fluid d-block"
                      width="350"
                    />
                    <p class="my-2 d-block text-muted opacity-75">
                      Looks like there's not enough data yet.
                    </p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <div *ngIf="!professional" class="col-12 col-xl-3 mt-2">
            <div class="ddd-office-address p-4 shadow-sm rounded-4">
              <p class="fs-6 opacity-75 fw-bold">Office Information</p>
              <div class="d-flex text-muted">
                <iconify-icon
                  icon="heroicons:building-office-2"
                  class="fs-5 me-2"
                ></iconify-icon>
                <p>{{ userAccount?.accountName }}</p>
              </div>
              <div class="d-flex text-muted">
                <iconify-icon
                  icon="carbon:location-save"
                  class="fs-5 me-2"
                ></iconify-icon>
                <p>{{ userAccount?.otherData?.streetAddress }}</p>
              </div>
              <div class="d-flex text-muted">
                <iconify-icon icon="mdi:email"
                              class="me-2 fs-5">

                </iconify-icon>

                <p class="text-break ">{{ user?.email }}
                  <span *ngIf="copied">
                    <iconify-icon icon="akar-icons:check" class="fs-5 text-success"></iconify-icon>
                    <span class="text-success fs-12">Copied</span>
                  </span>
                    <span *ngIf="!copied" ngxClipboard (cbOnSuccess)="contentCopied($event) " [cbContent]=user.email class="ms-auto nav-link pointer small ">
                    <iconify-icon icon="akar-icons:copy" class="fs-5 text-primary"></iconify-icon>
                    </span>
                <p>
              </div>
              <div class="d-flex text-muted">
                <iconify-icon
                  icon="carbon:phone-ip"
                  class="me-2 fs-5"
                ></iconify-icon>

                <p>{{ user?.mobileNumber }}</p>
              </div>
            </div>

            <div class="overview-card p-4 mt-xl-5 mt-5" style="border-radius: 1rem;">
              <div class="d-flex justify-content-between mb-2">
                <p class="fs-6 mb-0 opacity-75 fw-bold">Office Members</p>
                <p class="mb-0 small">
                  <a
                    href="/accounts?action=add-member"
                    class="text-decoration-none"
                    >View all
                  </a>
                </p>
              </div>
              <div *ngIf="members?.length > 0; else noData">
                <div
                  *ngFor="let member of members || []; let i = index"
                  class="d-flex align-items-center my-4"
                >
                  <img
                    [src]="
                      member?.profileImageUrl
                        ? member?.profileImageUrl
                        : './assets/img/examplelogo.svg'
                    "
                    alt=""
                    width="40"
                    height="40"
                    class="rounded-circle rounded me-2"
                  />
                  <div class="user-name-avatar">
                    <p class="fw-bold mb-0">{{ member.displayName }}</p>
                    <!--                    <p class="mb-0 fw-light">Dental Specialist</p>-->
                  </div>
                </div>
              </div>
              <ng-template #noData>
                <a href="/accounts?action=add-member" class="btn btn-primary btn-sm rounded-pill">Add Member</a>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
