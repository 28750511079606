import {Component, OnInit} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {ClipboardService, IClipboardResponse} from "ngx-clipboard";
import {environment} from "../../../../../environments/environment";
import {JoyrideService} from "ngx-joyride";
import {
  AffiliateMemberShipControllerService,
  AffiliateMembershipPojo,
  AffiliateMembershipStatPojo,
  UserControllerService,
  UserPojo,
  WalletTransactionControllerService,
  WalletTransactionStatPojo
} from "dd-core-api-sdk";
import {BsModalService} from "ngx-bootstrap/modal";
import TourRideStatusConstantEnum = UserPojo.TourRideStatusConstantEnum;

@Component({
  selector: 'app-affiliate-dashboard',
  templateUrl: './affiliate-dashboard.component.html',
  styleUrls: ['./affiliate-dashboard.component.css']
})
export class AffiliateDashboardComponent implements OnInit {
  user: UserKeycloak;
  copied: any;
  title: string = '';
  affiliateMembershipStat: AffiliateMembershipStatPojo;
  affiliateMembersList: Array<AffiliateMembershipPojo>;
  walletListCommissionStat: WalletTransactionStatPojo;
  tourRideStatus = TourRideStatusConstantEnum;

  constructor(private authenticationServiceKeycloak: AuthenticationServiceKeycloak,
              private affiliateMemberShipControllerService: AffiliateMemberShipControllerService,
              private walletTransactionControllerService: WalletTransactionControllerService,
              private modalService: BsModalService,
              private userControllerService: UserControllerService,
              private clipboardService: ClipboardService, private readonly joyrideService: JoyrideService) {
  }

  ngOnInit(): void {

    this.authenticationServiceKeycloak.getUser().subscribe(res => {
      this.user = res;
    })

    this.affiliateMemberShipControllerService.affiliateMembershipStat().subscribe(v => {
      this.affiliateMembershipStat = v;
    });
    this.affiliateMemberShipControllerService.affliateMembersList({limit: 2}).subscribe(v => {
      this.affiliateMembersList = v;
    });
    this.walletTransactionControllerService.totalWalletStatsForAffiliate().subscribe(v => {
      this.walletListCommissionStat = v;
    });

    if (this.user?.tourRideStatusConstant == null || this.user?.tourRideStatusConstant == this.tourRideStatus.Dashboard)
      this.joyrideService.startTour(
        {
          steps: ["zeroStep", "firstStep", "secondStep", "thirdStep", "fourthStep", "fifthStep"],
        },
      ).subscribe((step) => {

        }, (error) => {
        },
        () => {
          this.updateTourRideStatus(TourRideStatusConstantEnum.Affiliate);
        });
  }

  environmentUrl(): any {
    return environment.url;
  }

  contentCopied($event: IClipboardResponse) {
    this.copied = $event.isSuccess;
    setTimeout(v => {
      this.copied = false;
    }, 1500);
  }

  getFileURL(id) {
    if (id != null && id != '') {
      return environment.siteUrl + "/files/" + id;
    }
    return "./assets/img/user.png";
  }

  updateTourRideStatus(tourRide: TourRideStatusConstantEnum) {
    this.userControllerService.updateTourRide({portalUserId: this.user.id, tourRide: tourRide}).subscribe(res =>{
      this.user.tourRideStatusConstant = tourRide;
    });
  }

}
