<div
  class="p-3"
  style="
    border-top: 1px solid rgba(209, 209, 209, 0.5);
    background-color: #f1f7ff;
  "
>
  <div class="container">
    <div class="row">
      <div
        class="d-flex justify-content-between align-items-center text-dark small text-muted">
        <p class="mb-0">&copy; {{copyRightDate()}} DentalDoor</p>
        <!--        <p class="mb-0">License</p>-->
      </div>
    </div>
  </div>
</div>
