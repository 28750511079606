/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface ReviewPojo { 
    remainAnonymous?: boolean;
    name?: string;
    email?: string;
    phoneNumber?: string;
    comment?: string;
    rating?: number;
    dateCreated?: string;
    lastUpdated?: string;
    status?: ReviewPojo.StatusEnum;
    entityName?: string;
    entityId?: number;
    recordSource?: ReviewPojo.RecordSourceEnum;
    reviewDate?: string;
    sourceLink?: string;
    reviewId?: string;
    id?: number;
    reviewer?: PortalUser;
    displayPictureId?: number;
    favouriteCount?: number;
    likeCount?: number;
    dislikeCount?: number;
    currentUserFavoriteStatus?: ReviewPojo.CurrentUserFavoriteStatusEnum;
    commentCount?: number;
    currentUserFavorite?: boolean;
    reviewedEntity?: object;
    edited?: boolean;
    primaryRating?: number;
    title?: string;
    disputeCount?: number;
}
export namespace ReviewPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type RecordSourceEnum = 'DENTAL_DOOR' | 'GOOGLE' | 'FACEBOOK' | 'YELP' | 'BING' | 'YAHOO';
    export const RecordSourceEnum = {
        DentalDoor: 'DENTAL_DOOR' as RecordSourceEnum,
        Google: 'GOOGLE' as RecordSourceEnum,
        Facebook: 'FACEBOOK' as RecordSourceEnum,
        Yelp: 'YELP' as RecordSourceEnum,
        Bing: 'BING' as RecordSourceEnum,
        Yahoo: 'YAHOO' as RecordSourceEnum
    };
    export type CurrentUserFavoriteStatusEnum = 'LIKE' | 'UNLIKE' | 'DISLIKE';
    export const CurrentUserFavoriteStatusEnum = {
        Like: 'LIKE' as CurrentUserFavoriteStatusEnum,
        Unlike: 'UNLIKE' as CurrentUserFavoriteStatusEnum,
        Dislike: 'DISLIKE' as CurrentUserFavoriteStatusEnum
    };
}


