<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
  <div class="offcanvas-header">
    <button type="button" class="btn-close text-danger" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body pt-0">
    <div class="d-block text-center">
      <h3 class="offcanvas-title text-primary" id="offcanvasNavbarLabel">Need Help?</h3>
      <p> That's why we are here!</p>
    </div>
    <hr class="hr">
    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3 mt-4">
      <li class="nav-item" data-bs-toggle="collapse" href="#faq" role="button" aria-expanded="false"
          aria-controls="faq" >
        <a class="nav-link">
          <div class="card" >
            <div class="card-body p-1 d-flex align-items-center">
              <iconify-icon class="m-0 px-3" icon="wpf:faq" style="color: #369dbf;" width="30"></iconify-icon>
              <p class="mt-3 faq-title">Frequently asked questions</p>
              <div class="toggle-button-cover float-end" >
                <div class="ms-auto me-2">
                    <span class="image">
                      <svg width="15" height="15" viewBox="0 0 7 4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M3.5 4C3.41629 4 3.33531 3.98473 3.25704 3.9542C3.17836 3.92366 3.11285 3.88295 3.06054 3.83206L0.172645 1.0229C0.0575483 0.910942 0 0.768447 0 0.595419C0 0.422392 0.0575483 0.279898 0.172645 0.167939C0.287743 0.0559794 0.43423 0 0.612108 0C0.789985 0 0.936472 0.0559794 1.05157 0.167939L3.5 2.54962L5.94843 0.167939C6.06353 0.0559794 6.21001 0 6.38789 0C6.56577 0 6.71226 0.0559794 6.82735 0.167939C6.94245 0.279898 7 0.422392 7 0.595419C7 0.768447 6.94245 0.910942 6.82735 1.0229L3.93946 3.83206C3.87668 3.89313 3.80867 3.93628 3.73543 3.96153C3.66218 3.98718 3.58371 4 3.5 4V4Z"
                          fill="#369DBF" />
                      </svg>
                    </span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </li>
      <div class="collapse mt-4" id="faq">
        <div class="">
          <div class="row">
            <!-- ***** FAQ Start ***** -->
            <div class="col-md-12">
              <div class="faq" id="accordion">
                <div class="card">
                  <div class="card-header" id="faqHeading-1">
                    <div class="mb-0">
                      <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-1" aria-expanded="true"
                        aria-controls="faqCollapse-1">
                        <span class="badge">1</span>Do I have to create an account in order to refer a patient?
                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-1" class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                    <div class="card-body">
                      <p>Yes. The registration process is seamless and takes less than 2 minutes.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-2">
                    <div class="mb-0">
                      <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-2" aria-expanded="false"
                        aria-controls="faqCollapse-2">
                        <span class="badge">2</span>Is DentalDoor HIPAA compliant?
                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-2" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                    <div class="card-body">
                      <p>Yes. We are HIPPA compliant.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-3">
                    <div class="mb-0">
                      <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-3" aria-expanded="false"
                        aria-controls="faqCollapse-3">
                        <span class="badge">3</span>Does DentalDoor have a privacy policy?
                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-3" class="collapse" aria-labelledby="faqHeading-3" data-parent="#accordion">
                    <div class="card-body">
                      <p>Absolutely. You can read our privacy policy <a href="/review-privacy">here</a></p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-4">
                    <div class="mb-0">
                      <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-4" aria-expanded="false"
                        aria-controls="faqCollapse-4">
                        <span class="badge">4</span>How does DentalDoor secure its platform?
                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-4" class="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
                    <div class="card-body">
                      <p>Our platform is very secure and complies with HIPPA standard. We use 5 star security companies to
                        make sure our platform
                        adheres to the highest security standards.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-5">
                    <div class="mb-0">
                      <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-5" aria-expanded="false"
                        aria-controls="faqCollapse-5">
                        <span class="badge">5</span>Will my data be sold to third parties for any reason?
                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-5" class="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
                    <div class="card-body">
                      <p><strong>Absolutely Not!</strong>We value the privacy of every DentalDoor user.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-6">
                    <div class="mb-0">
                      <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-6" aria-expanded="false"
                        aria-controls="faqCollapse-6">
                        <span class="badge">6</span>What languages does DentalDoor platform support?
                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-6" class="collapse" aria-labelledby="faqHeading-6" data-parent="#accordion">
                    <div class="card-body">
                      <p>We currently support English and Spanish language. We are working towards supporting more
                        languages soon</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-7">
                    <div class="mb-0">
                      <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-7" aria-expanded="false"
                        aria-controls="faqCollapse-7">
                        <span class="badge">7</span>I have forgotten my password
                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                    <div class="card-body">
                      <p>Kindly click here and follow the instructions to reset your Password</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-8">
                    <div class="mb-0">
                      <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-8" aria-expanded="false"
                        aria-controls="faqCollapse-8">
                        <span class="badge">8</span>I have further questions, how can I
                        reach you guys?
                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-8" class="collapse" aria-labelledby="faqHeading-8" data-parent="#accordion">
                    <div class="card-body">
                      <p>We would love to hear from you. You can contact us 24/7 using either of these options:</p>
                      <ul>
                        <li>
                          Email: info@dentaldoor.com
                        </li>
                        <li>
                          Schedule a Phone call <a type="tel" href="">here</a>
                        </li>
                      </ul>
                      <p>
                        You can also leave a quick message using the help option on your dashboard, and one of our
                        representatives will quickly
                        help you.
                      </p>
                    </div>
                  </div>
                  </div>
                  </div>
              </div>
              </div>
              </div>
      </div>

      <li class="nav-item" data-bs-toggle="collapse" href="#tell" role="button" aria-expanded="false"
          aria-controls="tell">
        <a class="nav-link">
          <div class="card">
            <div class="card-body p-1 d-flex align-items-center">
              <iconify-icon class="m-0 px-3" icon="iconoir:emoji-talking-happy" style="color: #369dbf;" width="30">
              </iconify-icon>
              <p class="mt-3 faq-title">Tell us about the issue</p>
                            <div class="toggle-button-cover float-end">
                              <div class="ms-auto me-2">
                                <span class="image">
                                  <svg width="15" height="15" viewBox="0 0 7 4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M3.5 4C3.41629 4 3.33531 3.98473 3.25704 3.9542C3.17836 3.92366 3.11285 3.88295 3.06054 3.83206L0.172645 1.0229C0.0575483 0.910942 0 0.768447 0 0.595419C0 0.422392 0.0575483 0.279898 0.172645 0.167939C0.287743 0.0559794 0.43423 0 0.612108 0C0.789985 0 0.936472 0.0559794 1.05157 0.167939L3.5 2.54962L5.94843 0.167939C6.06353 0.0559794 6.21001 0 6.38789 0C6.56577 0 6.71226 0.0559794 6.82735 0.167939C6.94245 0.279898 7 0.422392 7 0.595419C7 0.768447 6.94245 0.910942 6.82735 1.0229L3.93946 3.83206C3.87668 3.89313 3.80867 3.93628 3.73543 3.96153C3.66218 3.98718 3.58371 4 3.5 4V4Z"
                                      fill="#369DBF" />
                                  </svg>
                                </span>
                              </div>
                            </div>
            </div>
          </div>
        </a>
      </li>
      <div class="collapse mt-3" id="tell">
        <div [ngClass]="{'alert-danger': isError, 'alert-success': !isError}"
             class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show"
             role="alert" *ngIf="showErrorMessageTrigger">
          <p class="m-0 p-0 d-flex align-items-start">
      <span>
        <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
        {{ getErrorMessage() | removeUnderscores }}
      </span>
            <iconify-icon
              class="iconify fs-4 ms-auto pointer"
              icon="mdi:close"
              data-bs-dismiss="alert"></iconify-icon>
          </p>
        </div>
        <form class="" [formGroup]="formGroup">
          <div class="form-floating mb-3">
            <input type="email" formControlName="email" class="form-control" id="email" placeholder="name@example.com">
            <label for="email" class="">Email</label>
            <app-field-error
              *ngIf="
                   (formGroup.get('email')?.errors?.required &&
                   formGroup.get('email')?.touched)
                    "
              [message]="'Email cannot be blank.'"
            ></app-field-error>
          </div>
          <div class="form-floating mb-1">
              <textarea formControlName="description" class="form-control form-control-lg" id="desc" rows="8"
                        maxlength="500" placeholder="Description [max 500]" style="height: 6rem;"></textarea>
            <label for="desc">Description [Max 500]</label>
            <app-field-error
              *ngIf="
                   (formGroup.get('description')?.errors?.required &&
                   formGroup.get('description')?.touched)
                    "
              [message]="'Description cannot be blank.'"
            ></app-field-error>
          </div>
          <div class="col-auto  d-flex align-items-center mb-1 mt-2">
            <label class="filelabel" appDragDropFileUpload (fileDropped)="onFileChange($event)"  style="background-color:none !important;">
              <i class="fa fa-paperclip"> </i>
              <span class="title"> Add/Drop File </span>
              <p class="opacity-25 small mb-0">
                Only .pdf, .png, .jpg, .jpeg supported.
              </p>
              <input
                class="FileUpload1"
                id="FileInput"
                type="file"
                multiple
                hidden
                #fileUpload
                onclick="value = null"
                (change)="onFileChange($event)"
              />
            </label>
          </div>
          <div class="col-auto play" *ngFor="let doc of fileDocs; let i = index">
            <label class="filelabel2 shadow-sm" *ngIf="bwFileIdList">
              <div>
                <img
                  style="width: 70px; height: 70px" class="card-img" *ngIf="bwFileIdList?.length > 0"
                  [src]="getFileURL(doc, this.bwFileIdList[i])"
                  [alt]="fileDocs[i].name"
                />
              </div>
              <p class="text-center mb-1 p-1" style="white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;">
                {{fileDocs[i].name}}
              </p>
              <div
                class=""
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample">
                <span
                  (click)="onFileDelete(i)"
                  class="pointer float-end p-1">
                  <iconify-icon
                    icon="majesticons:delete-bin"
                    style="color: #369dbf"
                    width="18">
                  </iconify-icon>
                </span>
              </div>
              <div class="m-2">
                <div class="progress" *ngIf="progress">
                  <div
                    class="progress-bar bg-primary"
                    [style.width]="(progress || 100) + '%'"
                  >
                    {{ progress || 100 }}%
                  </div>
                </div>
              </div>
            </label>
          </div>

          <div class="float-end">
            <button class="btn btn-primary rounded-0 px-5" [disabled]="formGroup.invalid" (click)="submitHelp()">
              Send
            </button>
          </div>
        </form>
      </div>

      <li class="nav-item" data-bs-toggle="collapse" href="#contact" role="button" aria-expanded="false"
          aria-controls="contact">
        <a class="nav-link">
          <div class="card">
            <div class="card-body p-1 d-flex align-items-center">
              <iconify-icon class="m-0 px-3" icon="mdi:phone-in-talk-outline" style="color: #369dbf;" width="30">
              </iconify-icon>
              <p class="mt-3 faq-title">Contact us</p>
                            <div class="toggle-button-cover float-end">
                              <div class="ms-auto me-2">
                                <span class="image">
                                  <svg width="15" height="15" viewBox="0 0 7 4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M3.5 4C3.41629 4 3.33531 3.98473 3.25704 3.9542C3.17836 3.92366 3.11285 3.88295 3.06054 3.83206L0.172645 1.0229C0.0575483 0.910942 0 0.768447 0 0.595419C0 0.422392 0.0575483 0.279898 0.172645 0.167939C0.287743 0.0559794 0.43423 0 0.612108 0C0.789985 0 0.936472 0.0559794 1.05157 0.167939L3.5 2.54962L5.94843 0.167939C6.06353 0.0559794 6.21001 0 6.38789 0C6.56577 0 6.71226 0.0559794 6.82735 0.167939C6.94245 0.279898 7 0.422392 7 0.595419C7 0.768447 6.94245 0.910942 6.82735 1.0229L3.93946 3.83206C3.87668 3.89313 3.80867 3.93628 3.73543 3.96153C3.66218 3.98718 3.58371 4 3.5 4V4Z"
                                      fill="#369DBF" />
                                  </svg>
                                </span>
                              </div>
                            </div>
            </div>
          </div>
        </a>
      </li>
      <div class="collapse mt-1" id="contact">
        <div class="">
          <div class="screenshot d-flex align-items-center mx-4">
            <a href="#">
              <iconify-icon icon="material-symbols:phone-android-outline-rounded" style="color: #369dbf;"
                            width="20">
              </iconify-icon>
            </a>
            <small class="ms-2"><a class="nav-link" href="tel:775 386 2655"> +1 (775) 386 2655</a></small>
          </div>
                    <div class="screenshot d-flex align-items-center mx-4">
                      <a href="#">
                        <iconify-icon icon="material-symbols:mail-outline-rounded" style="color: #369dbf;"
                            width="20"></iconify-icon>
                      </a>
                      <small class="ms-2"><a class="nav-link" href="mailto:help.dentaldoor.com">help.dentaldoor.com</a></small>
                    </div>
        </div>
      </div>
    </ul>
  </div>
</div>
