<div class="docpage-bg" style="z-index: -1;"></div>
<div class="container">
    <div class="row">
        <div class="col-lg-12" id="faq" style="margin-top: 5rem;">
            <h4 class="mt-4 mb-3 ml-2 text-light fs-900 ">Terms & Conditions - Affiliate Program</h4>
            <div class=" card border-0 docpage-card ">
                <div class="card-body ">
                    <p>Last updated: January 4, 2023</p>
                    <p>By signing up to be an affiliate in the DentalDoor Affiliate Program (the "Program "), you agree to be bound by the following terms and conditions (the "Terms "). Please ensure that you read them carefully before signing up. These
                        Terms are a legal agreement between DentalDoor, a company registered in the United States having its registered office at 304 S Jones Blvd #4064, Las Vegas, NV 89107, USA ("DentalDoor ", "we ", "us ") and You (the "Affiliate ",
                        "you ").</p>
                    <p>We reserve the right to update and change the Terms from time to time without notice. Any amendments, modifications, enhancements or changes to the Program, including the release of new features and resources made available by us from
                        time to time, shall be subject to these Terms. Continued use of the Program after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms at any time at:https://dentaldoor.com/affiliate-terms-and-conditions.</p>
                    <p>Any violation of these Terms may result in, among other things, termination or suspension of your rights to be an Affiliate and forfeiture of any outstanding affiliate referral fee payments earned during the violation.
                    </p>
                    <p class="emphasis ">ACCESS OR USE THE SITE OR THE SITE SERVICES AFTER THE EFFECTIVE DATE. IF YOU AGREE TO THE TERMS OF SERVICE ON BEHALF OF AN ENTITY, OR IN CONNECTION WITH PROVIDING OR RECEIVING SERVICES ON BEHALF OF AN ENTITY OR AGENCY, YOU REPRESENT
                        AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE. IN THAT EVENT, “YOU” AND“YOUR” WILL REFER AND APPLY TO THAT ENTITY OR AGENCY.</p>

                    <div class="heading ">Account Registration & Terms</div>

                    <p>You must provide your full legal name, a valid email address, and any other information requested to complete the signup process for an Affiliate account ("account").</p>

                    <P>You must be 18 years of age or older to join this Program. Each Account is for use by a single legal entity (e.g. a company or a partnership) or an individual user. We do not permit you to share your username and password with any
                        other person or multiple network users. Responsibility for the security of any user names and passwords issued (including those of any Invitees) rests with you. You may not use the Program for any illegal or unauthorized purpose.

                    </P>
                    <p>You must not, in the use of the Program, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>

                    <div class="heading ">Referral Links & Promotion</div>
                    <p>Once you have signed up for the Program, you will be provided with a URL link that must be used to identify you when placing a link from your site, email or other communications to the DentalDoor website. It is your responsibility
                        to ensure that each such link is correctly formatted.</p>
                    <p>We may also provide graphical images that can be used within the links to promote DentalDoor. You may not modify these images in any way. We reserve the right to change the images at any time without notice. You will be solely responsible
                        for the development, operation, and maintenance of your site and for all materials that appear on your site.</p>
                    <p>Unless we have given our advanced written consent, you may not use our name or graphics in any bulk email. We may terminate the Agreement if any meaningful spam complaints naming us or our services result from your marketing activities.</p>
                    <p>You may not issue any press release concerning this Agreement or your participation in the Program; such action may result in your termination from the Program. In addition, you may not in any manner misrepresent or embellish the relationship
                        between you and us, say you develop our services, say you are part of DentalDoor or express or imply any relationship between us and you or any other person or entity, except as expressly permitted by this Agreement.</p>

                    <div class="heading ">Referral Fees</div>
                    <p>For the sale of a subscription to be eligible to earn a referral fee, the customer must click through a link from your site, email, or other communications to the DentalDoor website and sign up within 90 days of the initial click-through.
                        If they fail to sign up within those 90 days and later return without following your link, you will not earn a referral fee.
                    </p>
                    <p>We will only pay referral fees on links automatically tracked and reported by our systems. The visitor must have cookies enabled for our systems to track the referral. We will not pay referral fees if someone says they signed up through
                        you, but our system did not track it.</p>
                    <p>The referral fee is 10% of our revenue from customers that you refer. The referral fee will be credited to your Affiliate account once the customer pays their subscription. Referral fees are only earned if a customer makes a payment
                        in full. Other bonus fees may be included in the future.</p>

                    <div class="heading ">Payment</div>
                    <p>Accrued referral fees are paid via bank transfer roughly once per month and only when your accrued referral fees total $500 or more. The Affiliate will raise an invoice to DentalDoor for the indicated amount. You must have a valid
                        bank account to receive referral fees, as we do not offer payment via cheque/check, credit card, cash or other methods.</p>
                    <p>Customer payments refunded or payments charged back due to credit card fraud do not qualify for referral fees. We may delay crediting referral fees subject to risk analysis considerations and Anti-Money Laundering procedures.</p>
                    <p>A summary of sign-ups and statement of referral fees is available to the Affiliate by logging into their Affiliate account. The referral fee structure is subject to change at our discretion.</p>
                    <p>We reserve the right to disqualify referral fees earned through fraudulent, illegal, or overly aggressive, questionable sales or marketing methods. All fees are exclusive of all taxes, charges, levies, assessments and other fees of
                        any kind imposed on your involvement in this Agreement and shall be the responsibility of and payable by you.</p>
                    <p>We reserve the right to check and change commissions based on orders paid; the notification email is not understood as a confirmed commission - this is only a notification, and every payment will be verified based on actual transactions.</p>

                    <div class="heading ">Customer Definition</div>

                    <p>Every customer who buys a service through this Program is deemed a customer of DentalDoor. Accordingly, our rules, policies and operating procedures concerning pricing, customer orders, customer service, and services sales will apply
                        to those customers. We may change our policies and operating procedures at any time. DentalDoor is not responsible for any representations made by the Affiliate that contradict our rules, policies or operating procedures.</p>

                    <div class="heading">Pricing & Availability</div>
                    <p>We will determine the prices to be charged for services sold under this Program per our pricing policies. Services prices and availability may vary from time to time. Because price changes may affect services listed on your site, you
                        should not display service prices on your site. We will use commercially reasonable efforts to present accurate information, but we cannot guarantee the availability or price of any particular service.</p>


                    <div class="heading ">Copyrighted and Trademarked material</div>
                    <p>You are solely responsible for ensuring that your reviews, product descriptions and articles (if applicable at your site) obey all applicable copyright, trademark, and other laws. DentalDoor will not be responsible if you use another
                        party's copyrighted or trademarked material in violation of the law.</p>

                    <div class="heading ">Term of the Agreement and Program</div>
                    <p>The term of this Agreement will begin upon our acceptance of your Program application and will end when terminated by either party. Either you or we may terminate this Agreement at any time, with or without cause, by giving the other
                        party notice of termination. Notice by email to your address on our records is considered sufficient notice to terminate this Agreement. DentalDoor reserves the right to end the Program at any time, and upon Program termination,
                        DentalDoor will pay any legitimate outstanding earnings.</p>

                    <div class="heading ">Termination</div>
                    <p>DentalDoor, in its sole discretion, has the right to suspend or terminate your Account and refuse any current or future use of the Program, or any other DentalDoor service, for any reason at any time. Such termination would result
                        in the deactivation or deletion of your Affiliate Account and the forfeiture and relinquishment of all potential or accrued referral fees in your Account if they were earned through fraudulent, illegal, or overly aggressive, questionable
                        sales or marketing methods. DentalDoor reserves the right to refuse service to anyone for any reason at any time. Upon the termination of this Agreement for any reason, you will immediately cease use of, and remove from your site,
                        all links to the DentalDoor Affiliate website and all our images and other materials provided under the Program.</p>

                    <div class="heading ">Relationship of Parties</div>
                    <p>You and we are independent contractors, and nothing in this Agreement will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between the parties. You will have no authority to
                        make or accept any offers or representations on our behalf. You will not make any statement, whether on your site or otherwise, that reasonably would contradict anything in this Agreement.
                    </p>

                    <div class="heading ">Limitations of Liability</div>
                    <p>The Company and any of the Company's officers, directors, employees, shareholders or agents of them exclude all liability and responsibility for any amount or kind of loss or damage that may result to you or a third party (including,
                        without limitation, any direct, indirect, punitive or consequential loss or damages, or any loss of income, profits, goodwill, data, contracts, use of money, or loss or damages arising from or connected in any way to business interruption,
                        and whether in tort (including without limitation negligence), contract or otherwise) in connection with this Program.</p>
                    <p>Nothing in this legal notice shall exclude or limit the Company's liability for:</p>
                    <ol class="d">
                        <li class="p">death or personal injury caused by negligence (as such term is defined by the Unfair Contract Terms Act 1977); or</li>
                        <li class="p">fraud; or</li>
                        <li class="p">misrepresentation as to a fundamental matter; or</li>
                        <li class="p">any liability which cannot be excluded or limited under applicable law.</li>
                    </ol>
                    <p>If your use of material provided under this Program results in the need for servicing, repair or correction of equipment, software or data, you assume all costs. The Company's maximum aggregate liability under or in connection with
                        these Terms, or any collateral contract, whether in contract, tort (including negligence) or otherwise (a "Claim"), shall be limited to a sum equal to the aggregate amount which we are obliged to pay you in the twelve (12) month
                        period immediately prior to the period giving rise to such Claim.</p>

                    <div class="heading ">Arbitration</div>
                    <p>Any dispute relating in any way to this Agreement (including any actual or alleged breach hereof), any transactions or activities under this Agreement or your relationship with us or any of our affiliates shall be submitted to confidential
                        arbitration in United States. </p>
                    <p>Arbitration under this Agreement shall be conducted under the rules then prevailing of the United States Arbitration Association. The arbitrator's award shall be binding and may be entered as a judgment in any court of competent jurisdiction.
                        To the fullest extent permitted by applicable law, no arbitration under this Agreement shall be joined to an arbitration involving any other party subject to this Agreement, whether through class arbitration proceedings or otherwise.</p>


                    <div class="heading ">Notice</div>
                    <p>All notices you give to us must be given to DentalDoor at info@dentaldoor.com. We may give notice to you at the email address you provided when registering. Notice will be deemed received and properly served 24 hours after an email
                        is sent. In proving the service of any notice, it will be sufficient to prove in the case of an email, that such email was sent to the specified email address of the addressee.
                    </p>

                    <div class="heading ">Events outside our control</div>
                    <p>We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations hereunder that is caused by events outside our reasonable control (a "Force Majeure Event").</p>
                    <p>A Force Majeure Event includes any act, event, non-happening, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:</p>
                    <ol class="d">
                        <li class="p">strikes, lock-outs or other industrial action;</li>
                        <li class="p">civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;</li>
                        <li class="p">fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;</li>
                        <li class="p">impossibility of the use of public or private telecommunications networks;</li>
                        <li class="p">the acts, decrees, legislation, regulations or restrictions of any government.</li>
                    </ol>
                    <p>Our performance is deemed to be suspended for the period that the Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use our reasonable endeavors to bring the
                        Force Majeure Event to a close or to find a solution by which our obligations under these Terms may be performed despite the Force Majeure Event.</p>

                    <div class="heading ">Waiver</div>
                    <p>If we fail, at any time to insist upon strict performance of any of your obligations under these Terms, or if we fail to exercise any of the rights or remedies to which we are entitled hereunder, this shall not constitute a waiver
                        of such rights or remedies and shall not relieve you from compliance with such obligations.</p>
                    <p>A waiver by us of any default shall not constitute a waiver of any subsequent default. No waiver by us of any of these Terms shall be effective unless it is expressly stated to be a waiver and is communicated to you in writing.</p>

                    <div class="heading ">Severability</div>
                    <p>If any of these Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions
                        which will continue to be valid to the fullest extent permitted by law.</p>


                    <div class="heading ">Entire Agreement</div>
                    <p>These Terms and any document expressly referred to in it represents the entire Agreement between us in relation to the use of the Program and supersedes any prior agreement, understanding or arrangement between us, whether oral or
                        in writing.
                    </p>
                    <p>We each acknowledge that, in entering into these Terms, neither of us has relied on any representation, undertaking or promise given by the other or be implied from anything said or written in negotiations between us prior to entering
                        into these Terms except as expressly stated herein.</p>
                    <p>Neither of us shall have any remedy in respect of any untrue statement made by the other, whether orally or in writing, prior to the date we entered into these Terms (unless such untrue statement was made fraudulently), and the other
                        party's only remedy shall be for breach of contract as provided in these Terms.</p>

                    <div class="heading ">Governing law and jurisdiction</div>
                    <p>This legal notice shall be governed by and construed under Texas law. Disputes arising in connection with this legal notice shall be subject to the exclusive jurisdiction of the Texas Courts.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- /row -->
</div>
<!-- /container -->