<main class="p-3 mt-2 mb-4">
  <div class="card border-light container-fluid success">
    <div class="card-body">
      <h5 class="card-title">{{this.title}}</h5>
      <img src="assets/img/success.svg" class="w-25 m-3" alt="" *ngIf="isSuccess">
      <iconify-icon icon="ci:error" style="color: red;" width="200" *ngIf="!isSuccess"></iconify-icon>
      <p class="card-text">{{this.subTitle}}</p>
      <a (click)="this.buttonClicked()" class="btn btn-md btn-primary w-75 mt-4">{{this.buttonText}}</a>
      <br>
      <br>
    </div>
  </div>
</main>
