<div class="card border-light container-fluid success p-0 m-0">
    <div class="card-header m-0 border-0">
        <img *ngIf="affiliateBankPojo?.bank?.imageUrl" src="{{buildBankUrl(affiliateBankPojo?.bank?.imageUrl)}}" class="card-logo" alt="">
        <h5 class="card-logo text-center mt-3" *ngIf="!affiliateBankPojo?.bank?.imageUrl">{{affiliateBankPojo?.bankName | uppercase}}</h5>
        <span class="float-end pointer" (click)="emitCancelButton.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" preserveAspectRatio="xMidYMid meet"
           viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="m12 13.4l-4.9 4.9q-.275.275-.7.275q-.425 0-.7-.275q-.275-.275-.275-.7q0-.425.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7L13.4 12l4.9 4.9q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275Z"/>
                </svg>
    </span>
    </div>
    <div class="card-body">
        <div *ngIf="showErrorMessageTrigger">
            <div class="alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert" [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                <p class="m-0 p-0 d-flex align-items-start">
                    <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ getErrorMessage() | removeUnderscores }}
              </span>
                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                </p>
            </div>
        </div>
        <div class="px-3 w-100 text-center">
            <p class="h5 mt-3">Enter Withdrawal Amount</p>
            <div class="currency-input border-0">
                <span class="h5">$</span>
                <input type="text" class="form-control-lg bg-transparent pt-3 w-25" placeholder="0.00" [formControl]="amountCtr" [mask]="'separator.2'" [thousandSeparator]="','" aria-label="Amount" />
            </div>
            <hr class="hr w-100 text-center">
            <small class="text-primary">Minimum withdrawal amount: ${{this.walletListCommissionStat?.minWithDrawalAmount}}</small>
        </div>
        <div class="amount-card p-3">
            <div class="row mb-2 small">
                <span class="col-sm text-sm-start">Available for Withdrawal</span>
                <span class="col-sm-auto fw-bold">{{this.walletListCommissionStat?.totalAmountPayable || 0 | noRoundCurrency}}</span>
            </div>
            <div class="row mb-2 small">
                <span class="col-sm text-sm-start">Stripe Fee (2.9% Plus 30 cents)</span>
                <span class="col-sm-auto fw-bold text-sm-start">{{processingFee(this.walletListCommissionStat?.processingFeePercentage, this.walletListCommissionStat?.fixedFee) || 0 | noRoundCurrency}}</span>
            </div>
            <div class="row mb-2 small">
                <span class="col-sm text-sm-start">Balance After Withdrawal</span>
                <span class="col-sm-auto fw-bold text-sm-start">{{balanceAfterWithdrawal() | noRoundCurrency}}</span>
            </div>
            <hr class="hr w-100 text-center">
            <div class="row mb-2 small">
                <span class="col-sm text-sm-start">Total Amount</span>
                <span class="col-sm-auto fw-bold text-sm-start">{{totalWithDrawalAmount() | noRoundCurrency}}</span>
            </div>
        </div>
        <p class="card-text">All withdrawals are subject to review and could be delayed or stopped if we or your bank identify an issue. </p>
        <button (click)="goToNextStep()" class="btn btn-dark w-100 mt-3">Next</button>
    </div>
</div>
