import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  TimeSlotDefinition,
  TimeSlotDefinitionControllerService
} from "dd-core-api-sdk";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-first-contact-i',
  templateUrl: './first-contact-i.component.html',
  styleUrls: ['./first-contact-i.component.css']
})
export class FirstContactIComponent implements OnInit {
  formGroup: FormGroup;
  timeSlotPojo: Array<TimeSlotDefinition>;
  selectedTimeSlot: any[] = [];
  @Output()
  emitFirstStepValues = new EventEmitter<any>();
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  showPreference: boolean = true;

  constructor(private fb: FormBuilder, private timeSlotDefinitionControllerService: TimeSlotDefinitionControllerService) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      morning: [''],
      afternoon: [''],
      evening: ['']
    });

    this.formGroup.get("morning").valueChanges.subscribe(v => {
      if (v == true) {
        this.selectedTimeSlot.push({timeSlot: 'morning', id: this.timeSlotPojo[0].id});
      } else {
        this.selectedTimeSlot = this.selectedTimeSlot.filter(x => x.id !== this.timeSlotPojo[0].id);
      }
      this.showPref();
    });

    this.formGroup.get("afternoon").valueChanges.subscribe(v => {
      if (v == true) {
        this.selectedTimeSlot.push({timeSlot: 'afternoon', id: this.timeSlotPojo[1].id});
      } else {
        this.selectedTimeSlot = this.selectedTimeSlot.filter(x => x.id !== this.timeSlotPojo[1].id);
      }
      this.showPref();
    });

    this.formGroup.get("evening").valueChanges.subscribe(v => {
      if (v == true) {
        this.selectedTimeSlot.push({timeSlot: 'evening', id: this.timeSlotPojo[2].id});
      } else {
        this.selectedTimeSlot = this.selectedTimeSlot.filter(x => x.id !== this.timeSlotPojo[2].id);
      }
      this.showPref();
    });

    this.timeSlotDefinitionControllerService.getAllContactTimeRange().subscribe(res => {
      this.timeSlotPojo = res;
    })
  }

  showPref(){
    if(this.selectedTimeSlot?.length >= 1){
      this.showPreference = false;
    }else {
      this.showPreference = true;
    }
  }
  gotoNextStep() {
    if(this.selectedTimeSlot?.length == 0){
      this.showErrorMessage('Kindly select a most suitable time of the day or kindly click on the no preference button.');
      return;
    }
    this.emitFirstStepValues.emit(this.selectedTimeSlot);
  }

  noPreferenceClicked() {
    this.selectedTimeSlot = [];
    this.emitFirstStepValues.emit();
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }
  getErrorMessage() {
    return this.errMessage;
  }

}
