<app-full-page-loader *ngIf="uploadingImage" [opacity]="0.4"></app-full-page-loader>
<div class="container">
  <div class="row">
    <div class="d-flex align-items-center text-dark">
      <p class="fs-4 fw-bold mb-0 mt-3">
        <iconify-icon inline icon="clarity:settings-line" class="fs-3 me-2"></iconify-icon>
        Settings
      </p>
    </div>

    <!-- <div class="text-secondary col-md m-2"> -->
    <div *ngIf="office" class="mt-5">
      <section id="top">
        <div class="row gx-3">
          <div class="col-lg-3 col-md-12">
            <div *ngIf="showImageErrorTrigger">
              <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show" role="alert"
                   [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                <p class="m-0 p-0 d-flex align-items-start">
                                    <span>
                          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                                      {{ imageError() | removeUnderscores }}
                        </span>
                  <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                  </iconify-icon>
                </p>
              </div>
            </div>
            <figure class="figure" style="min-width: -webkit-fill-available;">
              <div style="width: -webkit-fill-available;">

                <input style="display:none" #filePicker (change)='onImagePicked($event)' type="file">
                <div class="img-card">
                  <img [src]=getFileURL() class="figure-img img-fluid" style="width: 100%; height: 100%;" alt=""/>
                </div>
                <figcaption
                  class="small d-flex align-items-center justify-content-center fig-caption text-center text-muted pointer mt-3"
                  (click)='filePicker.click()'>
                  <iconify-icon icon="uil:image-upload" style="color: #8891aa;" width="20"></iconify-icon>
                  <span style="margin-top: 5px;">Update Image</span>
                </figcaption>
              </div>
            </figure>

            <div class="my-3">
              <h5 class="mb-0 text-primary fw-bold">{{ office.name }}</h5>
              <p class="mb-0">
                {{ getOfficeSpeciality(officeSpecializations) }}
              </p>

              <div class="mt-3">
                <p class="mb-0 text-muted small">Account created on</p>
                <p class="fw-bold mt-0">{{ office.dateCreated | date }}</p>
              </div>
            </div>

            <div id="middle" class="p-2 filter-card-bg">
              <div class="rounded-0 px-0 align-items-center">
                <div class="py-2">
                  <h6 class="m-0 text-dark">OFFICE MEMBERS</h6>
                </div>
              </div>
              <div class="d-flex flex-md-column btn-secondary">
                <div data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top"
                     title="place your information here" class="pro-card row" *ngFor="let member of members;">
                  <div class="bg-transparent border-0 rounded-5 col-auto" style="padding: .1rem;">
                    <img src="./assets/img/user.png" class="rounded-5" alt="" style="width: 40px; height: 40px"/>
                  </div>
                  <div class="border-0 rounded-1 col-md">
                    <p class="mb-0 mt-2 pb-0 fw-bold">{{ member.displayName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="overview-card rounded rounded-sm p-md-4 col-lg-9 col-sm-12">
            <!-- </div> -->
            <div class="border-0 rounded-0">
              <ul class="nav nav-pills mb-3" id="pills-tab">
                <li class="nav-item" role="presentation">
                  <button (click)="selectTab('overview')" class="nav-link" id="pills-home-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                          [attr.aria-selected]="tabSwitch == 'overview'"
                          [ngClass]="{ active: tabSwitch == 'overview' }">Overview
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button (click)="selectTab('profile')" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                          [attr.aria-selected]="tabSwitch == 'profile'" [ngClass]="{ active: tabSwitch == 'profile' }">
                    Profile Settings
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button (click)="selectTab('forgotPassword')" class="nav-link" id="pills-forgotPassword-tab"
                          data-bs-toggle="pill" data-bs-target="#pills-forgotPassword" type="button" role="tab"
                          aria-controls="pills-forgotPassword" [attr.aria-selected]="tabSwitch == 'forgotPassword'"
                          [ngClass]="{ active: tabSwitch == 'forgotPassword' }">Password Settings
                  </button>
                </li>
                <!--                <li class="nav-item" role="presentation">-->
                <!--                  <button-->
                <!--                    (click)="selectTab('admin')"-->
                <!--                    class="nav-link"-->
                <!--                    id="pills-admin-tab"-->
                <!--                    data-bs-toggle="pill"-->
                <!--                    data-bs-target="#pills-admin"-->
                <!--                    type="button"-->
                <!--                    role="tab"-->
                <!--                    aria-controls="pills-admin"-->
                <!--                    [attr.aria-selected]="tabSwitch == 'admin'"-->
                <!--                    [ngClass]="{ active: tabSwitch == 'admin' }"-->
                <!--                  >-->
                <!--                    Admin Settings-->
                <!--                  </button>-->
                <!--                </li>-->
              </ul>
              <div class="tab-content" id="pills-tabContent" [ngSwitch]="tabSwitch">
                <div *ngSwitchCase="'overview'">
                  <div>
                    <div class="row px-2 my-3">
                      <div class="p-2 header-bg" style="background: rgba(138, 152, 190, 0.09)">
                        <h6 class="my-2 px-2">OFFICE PROFILE</h6>
                      </div>
                      <div class="col-md-6 col-sm my-3">
                        <h6>Name</h6>
                        <p>{{ office?.name }}</p>
                      </div>
                      <div class="col-md-6 col-sm my-3">
                        <h6>Type</h6>
                        <p>
                          {{ office?.specialityOffice ? "Speciality Dentistry" : "General Dentistry" }}
                        </p>
                      </div>
                      <div class="col-md-6 col-sm my-3">
                        <h6>Address</h6>
                        <p>{{ office?.address.street }}</p>
                      </div>
                      <div class="col-md-6 col-sm my-3">
                        <h6>Contact</h6>
                        <p type="phone" class="pb-0 mb-0">
                          {{ office.phoneNumber }}
                        </p>
                        <p type="email" class="pt-0 mt-0 office-email">
                          <a href="#" class="nav-link m-0 p-0">{{
                            office.email
                            }}</a>
                        </p>
                      </div>
                    </div>

                    <div>
                      <div class="row px-2 my-3">
                        <div class="p-2" style="background: rgba(138, 152, 190, 0.09)">
                          <h6 class="my-2 px-2"> ADMIN DETAILS</h6>
                        </div>
                        <div class="col-md-6 col-sm my-3">
                          <h6>First Name</h6>
                          <p type="phone" class="pb-0 mb-0">
                            {{ office?.admin.firstName }}
                          </p>
                        </div>
                        <div class="col-md-6 col-sm my-3">
                          <h6>Last Name</h6>
                          <p type="phone" class="pb-0 mb-0">
                            {{ office?.admin.lastName }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="row px-2 my-3">
                        <div class="p-2" style="background: rgba(138, 152, 190, 0.09)">
                          <h6 class="mb-0">Professionals In This Office</h6>
                        </div>
                        <div class="col-md-6 col-sm my-3">
                          <div *ngIf="
                              specialityCount &&
                                objectToArray(specialityCount).length > 0;
                              else empty
                            ">
                            <button type="button" class="btn btn-primary m-1" *ngFor="
                                let speciality of objectToArray(specialityCount)
                              ">
                              {{ speciality }}
                              <span class="badge bg-secondary">{{
                                specialityCount[speciality]
                                }}</span>
                            </button>
                          </div>
                          <ng-template #empty>
                            <div>
                              <a class="btn btn-sm btn-primary rounded-pill" href="/invites-list/create">Invite
                                specialist</a
                              >
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>

                    <h6 class="mt-5">
                      Services Provided
                      <span
                        (click)="selectTab('profile')"
                        class="badge bg-secondary ms-2"
                        style="cursor: pointer"
                      >
                        Edit
                      </span>
                    </h6>
                    <span
                      class="badge bg-primary mx-1 py-1"
                      *ngFor="let service of servicesProvides"
                    >
                      {{ service.name }}
                    </span>
                    <div *ngIf="false" class="col-md-12">
                      <div
                        class="d-flex align-items-center justify-content-between mt-5"
                      >
                        <h6 class="">
                          <span
                            class="fa fa-clock-o ion-clock float-right"
                          ></span>
                          Recent Activity
                        </h6>
                        <span
                          class="link-primary pointer"
                          style="font-size: 16px"
                        >View all</span
                        >
                      </div>
                      <table class="table table-sm">
                        <tbody>
                        <tr>
                          <td>
                            <div
                              class="alert alert-success alert-dismissible fade show"
                              role="alert"
                            >
                              <strong>Dr. Gary</strong> left a note
                              <strong> 'Post referral form'</strong>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                              ></button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              class="alert alert-success alert-dismissible fade show"
                              role="alert"
                            >
                              <strong>Dr. Gary</strong> joined your office
                              <strong> 'The White Dental Clinic'</strong>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                              ></button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              class="alert alert-success alert-dismissible fade show"
                              role="alert"
                            >
                              <strong>Dr. Kensington</strong> joined your
                              office <strong>The White Clinic</strong>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                              ></button>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'profile'">
                  <div *ngIf="showErrorMessageTrigger">
                    <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show"
                         role="alert"
                         [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                      <p class="m-0 p-0 d-flex align-items-start">
                        <span>
                          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ getErrorMessage() | removeUnderscores }}
                        </span>
                        <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                        </iconify-icon>
                      </p>
                    </div>
                  </div>
                  <form [formGroup]="officeInfoForm">
                    <section id="profile-info1">
                      <div class="p-2"
                           style="background: rgba(138, 152, 190, 0.09)">
                        <h6 class="my-2 px-2">PROFILE INFO</h6>
                      </div>
                      <div class="row px-2">
                        <div class="col-md mb-3">
                          <label for="officeName" class="rfr-label "
                          >Office Name</label
                          >
                          <input
                            type="text"
                            class="form-control p-2"
                            id="officeName"
                            placeholder="e.g The White Dentals"
                            formControlName="officeName"
                            disabled
                          />
                        </div>
                        <div class="col-lg mb-3">
                          <label for="officeEmail" class="rfr-label "
                          >Email</label
                          >
                          <input
                            type="email"
                            class="form-control p-2"
                            id="officeEmail"
                            placeholder=""
                            formControlName="email"
                          />
                        </div>
                      </div>
                      <div class="row px-2">
                        <div class="col-md mb-3">
                          <label for="officePhone" class="rfr-label "
                          >Office Phone</label
                          >
                          <ngx-intl-tel-input
                            [cssClass]="'form-control py-2'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            id="officePhone"
                            [maxLength]="'20'"
                            [phoneValidation]="true"
                            [preferredCountries]="preferredCountries"
                            [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name
                            ]"
                            [searchCountryFlag]="true"
                            [selectedCountryISO]="countryISO"
                            [selectFirstCountry]="true"
                            formControlName="phoneNumber"
                          ></ngx-intl-tel-input>
                        </div>
                        <div class="col-lg mb-3">
                          <label for="fax" class="rfr-label ">Office Fax</label>
                          <input
                            formControlName="fax"
                            type="phone"
                            class="form-control p-2"
                            id="fax"
                            placeholder="Enter Office Fax"
                          />
                        </div>
                        <div class="col-lg mb-3">
                          <label class="rfr-label ">Youtube Video URL</label>
                          <input
                            formControlName="aboutUrl"
                            type="text"
                            class="form-control p-2"
                            placeholder="Enter Office Youtube Video URL"
                          />
                          <app-field-error
                            *ngIf="(officeInfoForm.get('aboutUrl')?.errors?.pattern && officeInfoForm.get('aboutUrl')?.touched)"
                            [message]="'Invalid video url pattern.'"></app-field-error>
                        </div>
                        <div class="col-lg mb-3">
                          <label for="web" class="rfr-label ">Website</label>
                          <input
                            formControlName="website"
                            type="text"
                            class="form-control p-2"
                            id="web"
                            placeholder="Enter Office Website"
                          />
                          <app-field-error
                            *ngIf="(officeInfoForm.get('website')?.errors?.pattern && officeInfoForm.get('website')?.touched)"
                            [message]="'Invalid website pattern.'"></app-field-error>
                        </div>
                      </div>
                      <div class="row px-2">
                        <div class="col-md-12 mb-3">
                          <label for="services" class="rfr-label ">Dental Services Offered</label>
                          <tag-input
                            class="form-control p-2"
                            [addOnBlur]="true"
                            [clearOnBlur]="true"
                            [allowDupes]="false"
                            size="20"
                            style="min-height: 70px"
                            id="services"
                            placeholder="Add Services"
                            formControlName="dentalServices"
                            (onRemove)="removeService($event)"
                            [onlyFromAutocomplete]="false">
                            <tag-input-dropdown
                              [showDropdownIfEmpty]="true"
                              [autocompleteItems]="dentalServicesOptions">
                            </tag-input-dropdown>
                          </tag-input>
                        </div>
                        <div class="col-md-12 mb-3">
                          <label for="officeType" class="rfr-label ">Update Office Type</label>
                          <tag-input
                            class="form-control p-2"
                            [addOnBlur]="true"
                            [clearOnBlur]="true"
                            [onlyFromAutocomplete]="true"
                            size="20"
                            style="min-height: 70px"
                            id="officeType"
                            formControlName="officeType"
                            [allowDupes]="false"
                            (onRemove)="removeSpeciality($event)">
                            <tag-input-dropdown
                              [showDropdownIfEmpty]="true"
                              [autocompleteItems]="profession">
                            </tag-input-dropdown>
                          </tag-input>
                        </div>
                        <div class="col-md-12 mb-3">
                          <label for="insuranceService" class="rfr-label">Accepted Insurance Companies</label>
                          <tag-input
                            class="form-control p-2"
                            [addOnBlur]="true"
                            [clearOnBlur]="true"
                            [onlyFromAutocomplete]="true"
                            size="20"
                            style="min-height: 70px"
                            id="insuranceService"
                            formControlName="insuranceService"
                            [allowDupes]="false"
                            (onRemove)="removeInsurance($event)">
                            <tag-input-dropdown
                              [showDropdownIfEmpty]="true"
                              [autocompleteItems]="this.insuranceCompanyOptions">
                            </tag-input-dropdown>
                          </tag-input>
                        </div>
                      </div>
                    </section>

                    <section id="profile-info">
                      <div class="p-2 mb-2" style="background: rgba(138, 152, 190, 0.09)">
                        <h6 class="my-2 px-2 ">OFFICE ADDRESS</h6>
                      </div>
                      <div class="row px-2">
                        <div class="col-lg mb-3">
                          <label for="fullAdd" class="rfr-label">Address</label>
                          <input
                            type="text"
                            class="form-control p-2"
                            id="fullAdd"
                            placeholder="Your office address"
                            formControlName="officeAddress"
                          />

                        </div>
                      </div>
                      <div *ngIf="false" class="row px-2">
                        <div class="col-md mb-3">
                          <label for="city" class="rfr-label ">City</label>
                          <input
                            type="text"
                            class="form-control p-2"
                            id="city"
                            placeholder="e.g New York"
                          />
                        </div>
                        <div class="col-lg mb-3">
                          <label for="state" class="rfr-label">State</label>
                          <input
                            type="text"
                            class="form-control p-2"
                            id="state"
                            placeholder="e.g Ohio"
                          />
                        </div>
                        <div class="col-lg mb-3">
                          <label for="country" class="form-label"
                          >Country</label
                          >
                          <input
                            type="text"
                            class="form-control p-2"
                            id="country"
                            placeholder="e.g USA"
                          />
                        </div>
                      </div>
                    </section>
                    <section id="profile-info">
                      <div class="p-2 mb-2" style="background: rgba(138, 152, 190, 0.09)">
                        <h6 class="my-2 px-2 ">ABOUT US</h6>
                      </div>
                      <div class="row px-2">
                        <div class="col-lg mb-3">
                          <label for="fullAdd" class="rfr-label">About this office</label>
                          <textarea
                            type="text"
                            class="form-control p-2"
                            id="fullAdd"
                            placeholder="Let people know something about your office"
                            formControlName="summary"
                          ></textarea>

                        </div>
                      </div>
                      <div *ngIf="false" class="row px-2">
                        <div class="col-md mb-3">
                          <label for="city" class="rfr-label ">City</label>
                          <input
                            type="text"
                            class="form-control p-2"
                            id="city"
                            placeholder="e.g New York"
                          />
                        </div>
                        <div class="col-lg mb-3">
                          <label for="state" class="rfr-label">State</label>
                          <input
                            type="text"
                            class="form-control p-2"
                            id="state"
                            placeholder="e.g Ohio"
                          />
                        </div>
                        <div class="col-lg mb-3">
                          <label for="country" class="form-label"
                          >Country</label
                          >
                          <input
                            type="text"
                            class="form-control p-2"
                            id="country"
                            placeholder="e.g USA"
                          />
                        </div>
                      </div>
                    </section>
                    <div class="my-4 float-end">
                      <button
                        (click)="
                          loadProfileFormData(office);
                          loadServices(servicesProvides)
                        "
                        *ngIf="false"
                        type="cancel"
                        class="btn btn-outline-custom ps-2"
                        name="button"
                      >
                        Cancel
                      </button>
                      <button
                        (click)="updateProfile()"
                        [disabled]="this.officeInfoForm.untouched"
                        type="submit"
                        class="btn btn-primary btn-sm"
                        name="button"
                      >
                        <iconify-icon
                          *ngIf="updateLoading"
                          icon="eos-icons:bubble-loading"
                          style="color: #ffffff"
                        >
                        </iconify-icon>
                        Update
                      </button>
                    </div>
                  </form>
                </div>
                <div *ngSwitchCase="'admin'">
                  <div class="p-2" style="background: rgba(138, 152, 190, 0.09)">
                    <h6 class="my-2 px-2">ADMIN INFO</h6>
                  </div>
                  <div class="table-responsive">
                    <table class="table caption-top">
                      <thead>
                      <tr>
                        <td scope="col" class="">ID</td>
                        <td scope="col">Name</td>
                        <td scope="col">Email</td>
                        <td scope="col">Role</td>
                        <td scope="col" class="text-center">
                          <button
                            type="button"
                            name="button"
                            data-bs-toggle="modal"
                            class="btn btn-outline-secondary btn-sm"
                            data-bs-target="#addUser"
                          >
                            Add User
                          </button>
                        </td>
                        <!-- Mdal  -->
                        <div
                          class="modal fade"
                          id="addUser"
                          data-bs-keyboard="false"
                          tabindex="-1"
                          aria-labelledby="staticBackdropLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header bg-primary rounded-0 text-light">
                                <h1
                                  class="modal-title fs-5"
                                  id="staticBackdropLabel1"
                                >
                                  Add Member
                                </h1>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div
                                [class]="messageType"
                                *ngIf="addMemberSuccess"
                              >
                                <p class="m-0 p-0">{{ message }}</p>
                              </div>
                              <div class="modal-body">
                                <div
                                  class="d-flex justify-content-between flex-wrap"
                                >
                                  <div [formGroup]="adminForm" class="row">
                                    <div class="col-md-6 pl-0 pr-1 mb-4">
                                      <div class="form-outline">
                                        <label
                                          class="form-label required"
                                          for="firstName"
                                        >First Name</label
                                        >
                                        <input
                                          type="text"
                                          id="firstName"
                                          formControlName="firstName"
                                          class="form-control form-control-lg"
                                          placeholder="e.g Jane"
                                        />
                                        <app-field-error
                                          *ngIf="
                                              (adminForm.get('firstName')
                                                ?.errors?.minlength &&
                                                adminForm.get('firstName')
                                                  ?.touched) ||
                                              (adminForm.get('firstName')
                                                ?.errors?.minlength &&
                                                errorSubmission)
                                            "
                                          [message]="
                                              'First name cannot be less than two(2).'
                                            "
                                        >
                                        </app-field-error>

                                        <app-field-error
                                          *ngIf="
                                              (adminForm.get('firstName')
                                                ?.errors?.required &&
                                                adminForm.get('firstName')
                                                  ?.touched) ||
                                              (adminForm.get('firstName')
                                                ?.errors?.required &&
                                                errorSubmission)
                                            "
                                          [message]="
                                              'First name cannot be blank.'
                                            "
                                        >
                                        </app-field-error>
                                      </div>
                                    </div>
                                    <div class="col-md-6 pl-1 pr-0 mb-4">
                                      <div class="form-outline">
                                        <label class="form-label required"
                                        >Last Name</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control form-control-lg"
                                          formControlName="lastName"
                                          placeholder="e.g Doe"
                                        />
                                        <app-field-error
                                          *ngIf="
                                              (adminForm.get('lastName')?.errors
                                                ?.minlength &&
                                                adminForm.get('lastName')
                                                  ?.touched) ||
                                              (adminForm.get('lastName')?.errors
                                                ?.minlength &&
                                                errorSubmission)
                                            "
                                          [message]="
                                              'Last name cannot be less than two(2).'
                                            "
                                        >
                                        </app-field-error>

                                        <app-field-error
                                          *ngIf="
                                              (adminForm.get('lastName')?.errors
                                                ?.required &&
                                                adminForm.get('lastName')
                                                  ?.touched) ||
                                              (adminForm.get('lastName')?.errors
                                                ?.required &&
                                                errorSubmission)
                                            "
                                          [message]="
                                              'Last name cannot be blank.'
                                            "
                                        ></app-field-error>
                                      </div>
                                    </div>
                                    <div class="form-outline mb-4">
                                      <label
                                        class="form-label required"
                                        for="loginEmail"
                                      >Email Address
                                      </label>
                                      <input
                                        type="text"
                                        id="loginEmail"
                                        class="form-control form-control-lg"
                                        formControlName="email"
                                        placeholder="someone@example.com"
                                      />

                                      <app-field-error
                                        *ngIf="
                                            adminForm.get('email')?.errors
                                              ?.emailExists
                                          "
                                        [message]="
                                            'Email address already exist.'
                                          "
                                      >
                                      </app-field-error>

                                      <app-field-error
                                        *ngIf="
                                            this.adminForm.get('email').errors
                                              ?.pattern &&
                                            adminForm.get('email')?.touched
                                          "
                                        [message]="
                                            'Invalid Email address pattern.'
                                          "
                                      >
                                      </app-field-error>

                                      <app-field-error
                                        *ngIf="
                                            (adminForm.get('email')?.errors
                                              ?.required &&
                                              adminForm.get('email')
                                                ?.touched) ||
                                            (adminForm.get('email')?.errors
                                              ?.required &&
                                              errorSubmission)
                                          "
                                        [message]="
                                            'Email address cannot be blank.'
                                          "
                                      ></app-field-error>
                                    </div>
                                    <div
                                      id="number"
                                      class="form-outline mb-4"
                                    >
                                      <label class="form-label"
                                      >Phone Number</label
                                      >
                                      <ngx-intl-tel-input
                                        [cssClass]="'form-control py-2'"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        id="phoneNumber"
                                        [maxLength]="'20'"
                                        [phoneValidation]="true"
                                        [preferredCountries]="
                                            preferredCountries
                                          "
                                        [searchCountryField]="[
                                            SearchCountryField.Iso2,
                                            SearchCountryField.Name
                                          ]"
                                        [searchCountryFlag]="true"
                                        [selectedCountryISO]="countryISO"
                                        [selectFirstCountry]="true"
                                        formControlName="phoneNumber"
                                      ></ngx-intl-tel-input>
                                      <app-field-error
                                        *ngIf="
                                            (adminForm.get('phoneNumber')
                                              ?.errors?.required &&
                                              adminForm.get('phoneNumber')
                                                ?.touched) ||
                                            (adminForm.get('phoneNumber')
                                              ?.errors?.required &&
                                              errorSubmission)
                                          "
                                        [message]="
                                            'Phone number cannot be blank.'
                                          "
                                      >
                                      </app-field-error>
                                      <app-field-error
                                        class="mt-1"
                                        [message]="
                                            'Phone number already exist.'
                                          "
                                        *ngIf="
                                            adminForm.get('phoneNumber')!.errors
                                              ?.phoneNumberExists
                                          "
                                      ></app-field-error>
                                      <app-field-error
                                        class="mt-1"
                                        [message]="'Phone number is invalid.'"
                                        *ngIf="
                                            adminForm.get('phoneNumber')!.errors
                                              ?.invalidPhoneNumber
                                          "
                                      ></app-field-error>
                                    </div>
                                    <div class="col pl-0 pr-1 mb-4">
                                      <label class="form-label"
                                      >Member Type</label
                                      >
                                      <select
                                        class="form-select"
                                        aria-label="Default select example"
                                        formControlName="membershipType"
                                        style="
                                            padding: 0.7rem 2.25rem 0.375rem
                                              0.75rem;
                                          "
                                      >
                                        <option selected>
                                          Click here to choose...
                                        </option>
                                        <option
                                          *ngFor="
                                              let type of membershipType;
                                              let i = index
                                            "
                                          [id]="type + i"
                                          [value]="type"
                                        >
                                          {{ type | removeUnderscores }}
                                        </option>
                                      </select>
                                      <app-field-error
                                        class="mt-1"
                                        message="Selcet a membership type"
                                        *ngIf="
                                            (adminForm.get('membershipType')
                                              ?.errors?.required &&
                                              adminForm.get('membershipType')
                                                ?.touched) ||
                                            (adminForm.get('membershipType')
                                              ?.errors?.required &&
                                              errorSubmission)
                                          "
                                      ></app-field-error>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  (click)="addMember()"
                                >
                                  Add User
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Modal End -->
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let member of membersRoles; let i = index">
                        <th scope="row" class="text-muted">
                          {{ offset + 1 + i }}
                        </th>
                        <td>
                          {{ member?.membership?.portalUser?.displayName }}
                        </td>
                        <td>someone@example.com</td>
                        <td>
                          <select
                            class="form-select btn btn-primary btn-sm role-button text-light"
                            aria-label="Default select example"
                          >
                            <option selected>Admin</option>
                            <option value="1">Member</option>
                          </select>
                        </td>
                        <td>
                          <i
                            class="fa fa-trash ps-4 pt-2 cursor-pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                          ></i>
                        </td>

                        <!-- Modal  -->
                        <div
                          class="modal fade"
                          id="delete"
                          data-bs-keyboard="false"
                          tabindex="-1"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h1 class="modal-title fs-5">Delete User</h1>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div class="modal-body">
                                <div class="text-center">
                                  <h6>
                                    You are about to remove a user from the
                                    list.
                                  </h6>
                                  <p>Are you sure you want to do this?</p>
                                  <button
                                    type="button"
                                    class="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"
                                  >
                                    No
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-primary mx-2"
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Modal End -->
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div *ngSwitchCase="'forgotPassword'">
                  <div *ngIf="showPasswordTrigger">
                    <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show"
                         role="alert"
                         [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                      <p class="m-0 p-0 d-flex align-items-start">
                        <span>
                          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ showPasswordMessage() | removeUnderscores }}
                        </span>
                        <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                        </iconify-icon>
                      </p>
                    </div>
                  </div>
                  <app-change-password (emitSuccessResponse)="passwordChangeSuccess($event)" (emitErrorResponse)="passwordError($event)"></app-change-password>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- </div> -->
    </div>
  </div>
</div>
