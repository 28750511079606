
<!--<div-->
<!--  class="px-5 flex-column mb-auto flex-grow-1"-->
<!--  style="overflow-x: hidden;"-->
<!--&gt;-->
  <div class="container">
    <div class="d-flex align-items-center mb-4 mt-4">
      <p class="fs-3 fw-bold my-3">
        <iconify-icon
          inline
          icon="fluent:payment-48-regular"
          class="fs-3 me-2"
        ></iconify-icon>
        Billing
      </p>
    </div>
    <div class="row g-3">
      <div class="card rounded-0 shadow-sm border-0 text-secondary col-md m-2">
        <div class="row">
          <div *ngIf="currentSubscription; else defaultTemp" class="rounded-0 border-sm col">
            <div class="d-flex align-items-center">
              <h5 class="m-3 fw-bold">{{ currentSubscription?.subscriptionCycle.revenueItem?.name }}{{currentSubscription?.subscriptionStatus == 'TRIAL' ? '(Trial)' : ''}} Plan</h5>
              <span class="bg-secondary p-2 rounded-5 text-white">{{ currentSubscription?.subscriptionCycle?.billingCycle }}</span>
            </div>
            <p class="mx-3 mt-2">This is your current plan, and your next billing date is
              <code>{{formatUTCMoment(currentSubscription?.subscriptionExpiry ) | date: 'd MMM y, h:mm a': this.utcOffsetMinutes }}</code>
            </p>
<!--            <p class="mx-3 mt-2 fw-bold">10 of 20 users registered</p>-->
          </div>
          <div class="col">
            <p class="fw-bold fs-1 me-2 float-sm-end ms-2 p-1">{{ currentSubscription?.subscriptionStatus == 'TRIAL' ? '$0' : '$' + currentSubscription?.subscriptionCycle.revenueItem?.totalPaid}}</p>
          </div>
          <ng-template #defaultTemp>
            <div class="rounded-0 border-sm col">
              <div class="d-flex align-items-center">
                <h5 class="m-3 fw-bold">No plan attached to account.</h5>
              </div>
            </div>
          </ng-template>
        </div>
        <hr>
        <div *ngIf="false" class="col">
          <a href="/billing/purchase-plan">
            <p class="fs-5 me-2 float-end p-1 upgrade-plan">
              {{ currentSubscription ? 'Upgrade plan' : 'Purchase plan'}}
              <iconify-icon
                inline class="fs-3"
                icon="akar-icons:arrow-up-right"
                style="color: #369dbf;"
              ></iconify-icon>
            </p>
          </a>
        </div>
      </div>
    </div>
    <!-- table section -->
        <div class="overview-card-table p-3 mt-5">
          <div class="text-secondary my-3">
            <h5 class="m-2 fw-bold">Payment History</h5>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead class="py-3">
              <tr class="overview-card shadow-sm">
                <th class="">ID</th>
                <th class="">Start Date</th>
                <th class="">Expiry Date</th>
                <th class="">Status</th>
                <th class="">Plan</th>
                <th class="">Amount</th>
              </tr>
              </thead>
              <tbody class="text-muted">
              <tr
                *ngFor="
                let subscription of queryResults?.results;
                let i = index
              "
                [id]="i"
              >
                <td [id]="'index' + i" class="align-middle" data-label="ID">
                  {{ offset + i + 1 }}
                </td>
                <td
                  [id]="'patient' + i"
                  class="align-middle"
                  data-label="Start Date"
                >
                  {{ formatUTCMoment(subscription?.subscriptionStart ) | date: 'd MMM y, h:mm a': this.utcOffsetMinutes }}
                </td>
                <td
                  [id]="'status' + i"
                  class="fw-bold align-middle text-capitalize"
                  data-label="End Date"
                >
                  {{ formatUTCMoment(subscription?.subscriptionExpiry ) | date: 'd MMM y, h:mm a': this.utcOffsetMinutes  }}
                </td>
                <td [id]="'R' + i" class="align-middle"
                    data-label="Status">
                    {{ subscription?.subscriptionStatus }}
                </td>
                <td
                  [id]="'plan' + i"
                  class="align-middle"
                  data-label="Plan"
                >
                  {{ subscription.subscriptionCycle.revenueItem?.name }} {{subscription.subscriptionStatus == 'TRIAL' ? '(Trial)' : ''}}
                </td>
                <td
                  [id]="'referring' + i"
                  class="align-middle"
                  data-label="Amount"
                >
                  {{ subscription.subscriptionStatus == 'TRIAL' ? '$0' : '$' + subscription.subscriptionCycle.revenueItem?.totalPaid}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div>
            <span
              *ngIf="!queryResults?.results || queryResults.total == 0"
              class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder"
            >
              {{
              queryResults?.results
                ? "NO DATA/RECORDS"
                : "APPLY FILTERS TO VIEW REFERRAL DETAILS"
              }}</span
            >
          </div>

          <div
            class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3"
            style="margin-bottom: -1rem; color: #4e3367"
          >
            <app-page-length
              [pageLength]="getFilter().limit || 10"
              (pageLengthChanged)="changeLimit($event)"
            ></app-page-length>

            <div class="add-group-btn text-right mt-3 mb-3">
              <pagination
                (pageChanged)="onPageChange($event)"
                [boundaryLinks]="true"
                [itemsPerPage]="limit || 10"
                [(ngModel)]="page"
                [maxSize]="5"
                [totalItems]="queryResults?.total || 0"
                firstText="&laquo;"
                lastText="&raquo;"
                nextText="&rsaquo;"
                previousText="&lsaquo;"
              ></pagination>
            </div>
          </div>
        </div>
    <!-- table section End-->
  </div>
<!--</div>-->


