import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CountryISO, PhoneNumberFormat, SearchCountryField} from "ngx-intl-tel-input";
import {CustomSubscriptionRequestControllerService, CustomSubscriptionRequestDto} from "dd-core-api-sdk";

@Component({
  selector: 'app-extranet-pricing',
  templateUrl: './extranet-pricing.component.html',
  styleUrls: ['./extranet-pricing.component.css']
})
export class ExtranetPricingComponent implements OnInit {

  formGroup: FormGroup;
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.UnitedStates;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: string[] = ['us'];
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  errorSubmission: boolean;
  loading: boolean;

  constructor(private fb: FormBuilder,
              private customSubscriptionRequestController: CustomSubscriptionRequestControllerService) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      officeName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      contactPersonFirstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      contactPersonLastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      email: ['', [Validators.email, Validators.required]],
      note: ['', []],
      role: ['', []],
      phoneNumber: [''],
    });

  }

  scheduleCall() {
    const formValue = this.formGroup.getRawValue();
    this.loading = true;

    const dto : CustomSubscriptionRequestDto = {
      officeName: formValue.officeName,
      contactPersonFirstName: formValue.contactPersonFirstName,
      contactPersonLastName: formValue.contactPersonLastName,
      email: formValue.email,
      note: formValue.note,
      role: formValue.role,
      phoneNumber: formValue.phoneNumber.e164Number
    }

    console.log('dtoo ', dto);
    this.customSubscriptionRequestController.createCustomSubscriptionRequest({
      customSubscriptionRequestDto: dto
    }).subscribe(res => {
      this.loading = false;
      this.isError = false;
      this.formGroup.reset();
      this.showErrorMessage('Your request has been submitted. We will contact you shortly.');
    }, error => {
      this.loading = false;
      this.isError = true;
      this.formGroup.reset();
      this.showErrorMessage('Some fields are compulsory, please ensure they are filled.')
    })
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 5000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

}
