import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumericDirective} from './numberic';


@NgModule({
  declarations: [NumericDirective],
  imports: [
    CommonModule
  ], exports: [
    NumericDirective
  ]
})
export class DirectivesModule {
}
