import {Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges} from '@angular/core';

@Component({
  selector: 'app-adult-teeth',
  templateUrl: './adult-teeth.component.html',
  styleUrls: ['./adult-teeth.component.css']
})

export class AdultTeethComponent implements OnInit, OnChanges {

  @Input() editable: boolean;
  @Input() affected: string;
  @Output() outputSelectAdultTeeth = new EventEmitter();

  teeth: any = {};
  value: any = {};
  @Input() deselectedTooth: any;

  constructor() { }

  ngOnInit(): void {
    this.initTeeth()
  }


  ngOnChanges(changes: SimpleChanges) {
    this.initTeeth()
    if (changes.deselectedTooth) {
      this.deselectTooth(this.deselectedTooth);
    }
  }

  initTeeth() {
    let affectedTeeth = this.affected ? this.affected.split(", ") : [];
    for (let i = 0; i < 32; i++){
      this.teeth['' + (i + 1)] = '0';
      if (this.affected) {
        let tooth = affectedTeeth.find(value => value == '' + (i + 1));
        if (tooth) {
          this.teeth[tooth] = '1';
          this.value['value' + (i + 1)] = tooth
        }
      }
    }
  }

  clicked(elementId: string) {

    if (!this.editable)
      return;

    let tooth = document.getElementById(elementId);

    if (tooth.getAttribute('opacity') == '1') {
      tooth.setAttribute('opacity', '0');
      this.value['value' + elementId] = '0';
      this.outputSelectAdultTeeth.emit(Object.values(this.value).filter(value => value !== '0'));
      return;
    }
    this.value['value' + elementId] = elementId;
    tooth.setAttribute('opacity', '1');
    this.outputSelectAdultTeeth.emit(Object.values(this.value).filter(value => value !== '0'));
  }

  deselectTooth(tooth: string) {
    if (this.teeth[tooth]) {
      this.teeth[tooth] = '0';
      this.value['value' + tooth] = '0';
      this.outputSelectAdultTeeth.emit(Object.values(this.value).filter(value => value !== '0'));
    }
  }
}
