/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TemporaryDentalOffice { 
    alternateEmail?: string;
    alternatePhoneNumber?: string;
    appointmentBookingPolicy?: string;
    approvalStatus?: TemporaryDentalOffice.ApprovalStatusEnum;
    apptProcessingTimeInMinutes?: number;
    code?: string;
    dateCreated?: string;
    dateOfApprovalProcessing?: string;
    email?: string;
    facebookUrl?: string;
    fax?: string;
    handle?: string;
    instagramUrl?: string;
    lastReviewPage?: number;
    lastUpdated?: string;
    name?: string;
    phoneNumber?: string;
    reasonForDenial?: string;
    requireIdentityForAppointment?: boolean;
    requireInsuranceForAppointment?: boolean;
    reviewProfileId?: string;
    setupComplete?: boolean;
    specialtyOffice?: boolean;
    status?: TemporaryDentalOffice.StatusEnum;
    summary?: string;
    twitterUrl?: string;
    uniqueStatement?: string;
    website?: string;
    addressDetails?: string;
    specializationDetails?: string;
    id?: number;
}
export namespace TemporaryDentalOffice {
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


