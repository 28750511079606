/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FlashCardOptionPojo } from './flash-card-option-pojo';
import { FlashCardSet } from './flash-card-set';


export interface FlashCardItemPojo { 
    dateCreated?: string;
    status?: FlashCardItemPojo.StatusEnum;
    question?: string;
    color?: string;
    id?: number;
    flashCardSet?: FlashCardSet;
    flashCardOption?: Array<FlashCardOptionPojo>;
    imageId?: number;
}
export namespace FlashCardItemPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


