import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {InvitesRoutingModule} from "./invites-routing.module";
import {InvitesListComponent} from "./invites-list/invites-list.component";
import {CreatesInvitesComponent} from "./create-invites/creates-invites.component";
import {SharedModule} from "../../shared/shared.module";
import {NgbDatepickerModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import { CreateProfInvitesComponent } from './create-prof-invites/create-prof-invites.component';
import { CreateOfficeInvitesComponent } from './create-office-invites/create-office-invites.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { InviteProcessingComponent } from './invite-processing/invite-processing.component';

@NgModule({
  declarations: [
    InvitesListComponent,
    CreatesInvitesComponent,
    CreateProfInvitesComponent,
    CreateOfficeInvitesComponent,
    InviteProcessingComponent
  ],
    imports: [
        ReactiveFormsModule,
        InvitesRoutingModule,
        CommonModule,
        SharedModule,
        NgbDatepickerModule,
        NgxIntlTelInputModule,
        NgbTooltipModule
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InvitesModule {}
