import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AffiliateBankControllerService, CreateAffiliateBankDto} from "dd-core-api-sdk";
import {ConfirmFieldValidator} from "dd-shared-lib";
import {SuccessModalComponent} from "../../../../../shared/success-modal/success-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {SocialService} from "../../../../../shared/subject/SocialService";

@Component({
  selector: 'app-account-linking-form',
  templateUrl: './account-linking-form.component.html',
  styleUrls: ['./account-linking-form.component.css']
})
export class AccountLinkingFormComponent implements OnInit {

  formGroup: FormGroup;
  saving: boolean;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  errorSubmission: boolean;
  @Output()
  dismissButtonClicked = new EventEmitter<any>();
  @Output()
  submitButtonClickedSuccess = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              public socialService: SocialService,
              private affiliateBankControllerService: AffiliateBankControllerService) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      accountName: ['', [Validators.required]],
      bankName: [''],
      routingNumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      accountNumber: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      confAccountNumber: ['', Validators.compose([
        Validators.required, Validators.minLength(6), Validators.maxLength(20),
        ConfirmFieldValidator.matchField('accountNumber', 'accountMismatch')
      ])],
    });
  }
  saveBankDetails() {
    this.saving = true;
    const form = this.formGroup.getRawValue();

    const bankDetails: CreateAffiliateBankDto = {
      accountName: form.accountName,
      accountNumber: form.accountNumber,
      routingNumber: form.routingNumber,
      bankName: form.bankName,
      bankCode: this.socialService.bankCodeSubject.value.code
    }
    this.affiliateBankControllerService.createAffiliateBank({createAffiliateBankDto: bankDetails}).subscribe({
      next: (v) => {
        this.submitButtonClickedSuccess.emit();
        this.successModal();
        this.saving = false;
      },
      error: (e) => {
        this.showErrorMessage('Error in saving account details. Try again later.');
        this.saving = false;
        this.isError = true;
      },
      complete: () => {
        this.saving = false;
      }
    })
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  getErrorMessage() {
    return this.errMessage;
  }

  submissionForError() {
    console.log(this.socialService.bankCodeSubject.value);

    this.errorSubmission = true;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  successModal() {
    let bsModalRef = this.modalService.show(SuccessModalComponent, {
      initialState: {
        body: 'Bank Account has been saved successfully. Account Authenticity will be validated shortly.',
        affiliate: true,
        check: true,
        buttonText: 'Close'
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.onDismissClicked.subscribe(v => {
      this.submitButtonClickedSuccess.emit();
    });
  }


}
