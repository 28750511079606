/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SpecializationSearchFilter { 
    id?: number;
    code?: string;
    limit?: number;
    type?: SpecializationSearchFilter.TypeEnum;
    name?: string;
    offset?: number;
}
export namespace SpecializationSearchFilter {
    export type TypeEnum = 'DENTAL_PROFESSIONAL' | 'STUDENT' | 'DENTAL_OFFICE';
    export const TypeEnum = {
        DentalProfessional: 'DENTAL_PROFESSIONAL' as TypeEnum,
        Student: 'STUDENT' as TypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as TypeEnum
    };
}


