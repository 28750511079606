import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {CreateReferralWizardComponent} from "../create-referral-wizard/create-referral-wizard.component";

@Injectable({
  providedIn: 'root'
})
export class ReferralDraftGuardGuard implements CanDeactivate<CreateReferralWizardComponent> {

  canDeactivate(component: CreateReferralWizardComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // console.log(component.guardFormValue);
    // component.saveDraft(component.guardFormValue);
    return true;
  }

}
