<div class="container my-3">
    <header>
        <div class="pricing-header p-3 pb-4 mx-auto text-center mt-5">
            <h3 *ngIf="isExtranet" class="display-4 fw-bold pb-3 ">Price Plans for Dental Offices</h3>
            <p class="fs-5 text-muted">Upgrade now to get the most out of DentalDoor through our wide range of
                services.</p>
        </div>
    </header>
    <main>
        <div class="row">
            <div [formGroup]="form" class="d-flex align-items-center justify-content-center p-5 wrapper">
                <div class="form-check form-check-inline ">
                    <input class="form-check-input" type="radio" name="cycle" id="inlineRadio1" formControlName="cycle"
                        value="0">
                    <label class="form-check-label mt-1 px-2" for="inlineRadio1">
                        Monthly
                        <p class="text-light ">-</p>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="cycle" id="inlineRadio2" formControlName="cycle"
                        value="1">
                    <label class="form-check-label mt-1 px-2" for="inlineRadio2">
                        Quarterly
                        <p class="text-secondary">(10% off)</p>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="cycle" id="inlineRadio3" formControlName="cycle"
                        value="2" checked>
                    <label class="form-check-label mt-1 px-2" for="inlineRadio3">
                        Yearly
                        <p class="text-secondary">(20% off)</p>
                    </label>
                </div>
            </div>

            <div class="col-md col-lg card-resize">
                <div *ngIf="loaded" class="row mb-3 text-center">
                    <div class="col-md" *ngFor="let sub of subscriptionPackages; let i = index">
                        <div class="card mb-4 rounded-3 shadow-sm">
                            <div class="card-header py-3"
                                [style]="{'background-color': sub.name == 'Pro' ?'#292929' : '#2f8aa8', 'color': '#fff'}">
                                <h4 class="my-0 fw-normal">{{sub.name}}</h4>
                            </div>
                            <div class="card-body">
                                <h1 class="card-title pricing-card-title">
                                    ${{sub.subscriptionCycles[currentCycle].amount}}<small
                                        class="text-muted fw-light">/Month</small>
                                </h1>
                                <ul class="list-unstyled mt-3 mb-4 text-secondary">
                                    <li *ngFor="let packageItem of sortPackages(sub.packageItems)" class="list ">
                                        <iconify-icon icon="bi:check"
                                            style="color: #d1d5db; width: 14px; margin-right: 10px;"></iconify-icon> {{
                                        packageItem.packageItem.name }}
                                    </li>
                                </ul>
                                <button type="button" class="w-100 btn btn-lg btn-outline-success pro-button"
                                    [ngClass]="{'pro-button': sub.name == 'Pro', 'advanced-button': sub.name != 'Pro'}"
                                    (click)="choosePlan(sub)">
                                    {{ isExtranet ? 'Try 30 day Free Trial' : 'Buy Now' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
