/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { DentalOfficeFreeSubPaymentTransactionRequestPojo } from '../model/models';
import { DentalOfficeSubscription } from '../model/models';
import { DentalOfficeSubscriptionHistory } from '../model/models';
import { DentalOfficeSubscriptionPaymentTransactionRequestPojo } from '../model/models';
import { DentalSubscriptionFilter } from '../model/models';
import { FreeSubscriptionDto } from '../model/models';
import { FreeSubscriptionPojo } from '../model/models';
import { HasSubscriptionPojo } from '../model/models';
import { PreviousSubscriptionPojo } from '../model/models';
import { QueryResultsPojoSubscriptionPackagePojo } from '../model/models';
import { QueryResultsSubscriptionSummaryPojo } from '../model/models';
import { Subscription } from '../model/models';
import { SubscriptionDto } from '../model/models';
import { SubscriptionPackageDto } from '../model/models';
import { SubscriptionSearchFilter } from '../model/models';
import { SubscriptionSummaryPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CancelSubscriptionRequestParams {
    id: number;
}

export interface CreateFreeOneMonthDentalSubscriptionRequestParams {
    dentalOfficeId: number;
    provider: 'STRIPE' | 'PAYSTACK';
    freeSubscriptionDto: FreeSubscriptionDto;
}

export interface CreateOfficeSubscriptionRequestRequestParams {
    officeId: number;
    provider: 'STRIPE' | 'PAYSTACK';
    subscriptionDto: SubscriptionDto;
}

export interface CreateOfficeSubscriptionRequestRenewalOrUpgradeRequestParams {
    officeId: number;
    provider: 'STRIPE' | 'PAYSTACK';
    subscriptionDto: SubscriptionDto;
}

export interface CreateSubscriptionPackageRequestParams {
    subscriptionPackageDto: SubscriptionPackageDto;
}

export interface GetCurrentSubscriptionRequestParams {
    officeId: number;
}

export interface GetDentalOfficeSubscriptionHistoryRequestParams {
    dentalOfficeId: number;
    dateFrom?: string;
    dateTo?: string;
    limit?: number;
    offset?: number;
    subscriptionStatus?: 'ACTIVE' | 'EXPIRED' | 'PENDING' | 'CANCELLED' | 'TRIAL';
}

export interface GetOfficeSubscriptionHistoryRequestParams {
    filter: DentalSubscriptionFilter;
}

export interface GetSubscriptionPackagesRequestParams {
    filter: SubscriptionSearchFilter;
}

export interface SetDentalOfficeAutoRenewalRequestParams {
    id: number;
}

export interface UpdateSubscriptionPackageRequestParams {
    code: string;
    subscriptionPackageDto: SubscriptionPackageDto;
}

export interface UpdateSubscriptionPackageStatusRequestParams {
    code: string;
}


@Injectable({
  providedIn: 'root'
})
export class SubscriptionControllerService {

    protected basePath = 'http://demo.dentaldoor.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Cancel Dental Office Subscription 
     * Call this API to cancel an existing dental office subscription
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelSubscription(requestParameters: CancelSubscriptionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DentalOfficeSubscription>;
    public cancelSubscription(requestParameters: CancelSubscriptionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DentalOfficeSubscription>>;
    public cancelSubscription(requestParameters: CancelSubscriptionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DentalOfficeSubscription>>;
    public cancelSubscription(requestParameters: CancelSubscriptionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling cancelSubscription.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<DentalOfficeSubscription>(`${this.configuration.basePath}/dental-offices/${encodeURIComponent(String(id))}/subscriptions/cancel`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if dental office has previous subscriptions
     * Call this API to check if dental office has had previous subscriptions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkForPreviousSubscriptionsByDentalOffice(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PreviousSubscriptionPojo>;
    public checkForPreviousSubscriptionsByDentalOffice(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PreviousSubscriptionPojo>>;
    public checkForPreviousSubscriptionsByDentalOffice(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PreviousSubscriptionPojo>>;
    public checkForPreviousSubscriptionsByDentalOffice(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PreviousSubscriptionPojo>(`${this.configuration.basePath}/dental-offices/dental-office/has-previous-subscriptions`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check Dental Office Subscription status
     * Call this API to check dental office subscription status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkPortalAccountActiveSubscription(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HasSubscriptionPojo>;
    public checkPortalAccountActiveSubscription(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<HasSubscriptionPojo>>;
    public checkPortalAccountActiveSubscription(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<HasSubscriptionPojo>>;
    public checkPortalAccountActiveSubscription(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<HasSubscriptionPojo>(`${this.configuration.basePath}/subscription-status`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create free one month subscription for dental office
     * Call this API to create a new free dental office subscription
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFreeOneMonthDentalSubscription(requestParameters: CreateFreeOneMonthDentalSubscriptionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DentalOfficeFreeSubPaymentTransactionRequestPojo>;
    public createFreeOneMonthDentalSubscription(requestParameters: CreateFreeOneMonthDentalSubscriptionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DentalOfficeFreeSubPaymentTransactionRequestPojo>>;
    public createFreeOneMonthDentalSubscription(requestParameters: CreateFreeOneMonthDentalSubscriptionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DentalOfficeFreeSubPaymentTransactionRequestPojo>>;
    public createFreeOneMonthDentalSubscription(requestParameters: CreateFreeOneMonthDentalSubscriptionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const dentalOfficeId = requestParameters.dentalOfficeId;
        if (dentalOfficeId === null || dentalOfficeId === undefined) {
            throw new Error('Required parameter dentalOfficeId was null or undefined when calling createFreeOneMonthDentalSubscription.');
        }
        const provider = requestParameters.provider;
        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling createFreeOneMonthDentalSubscription.');
        }
        const freeSubscriptionDto = requestParameters.freeSubscriptionDto;
        if (freeSubscriptionDto === null || freeSubscriptionDto === undefined) {
            throw new Error('Required parameter freeSubscriptionDto was null or undefined when calling createFreeOneMonthDentalSubscription.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (provider !== undefined && provider !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>provider, 'provider');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<DentalOfficeFreeSubPaymentTransactionRequestPojo>(`${this.configuration.basePath}/subscriptions/create-one-month-free-subscription/${encodeURIComponent(String(dentalOfficeId))}`,
            freeSubscriptionDto,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request for dental office subscription
     * Call this API to request for dental office subscription
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOfficeSubscriptionRequest(requestParameters: CreateOfficeSubscriptionRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DentalOfficeSubscriptionPaymentTransactionRequestPojo>;
    public createOfficeSubscriptionRequest(requestParameters: CreateOfficeSubscriptionRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DentalOfficeSubscriptionPaymentTransactionRequestPojo>>;
    public createOfficeSubscriptionRequest(requestParameters: CreateOfficeSubscriptionRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DentalOfficeSubscriptionPaymentTransactionRequestPojo>>;
    public createOfficeSubscriptionRequest(requestParameters: CreateOfficeSubscriptionRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const officeId = requestParameters.officeId;
        if (officeId === null || officeId === undefined) {
            throw new Error('Required parameter officeId was null or undefined when calling createOfficeSubscriptionRequest.');
        }
        const provider = requestParameters.provider;
        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling createOfficeSubscriptionRequest.');
        }
        const subscriptionDto = requestParameters.subscriptionDto;
        if (subscriptionDto === null || subscriptionDto === undefined) {
            throw new Error('Required parameter subscriptionDto was null or undefined when calling createOfficeSubscriptionRequest.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (provider !== undefined && provider !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>provider, 'provider');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<DentalOfficeSubscriptionPaymentTransactionRequestPojo>(`${this.configuration.basePath}/dental-offices/${encodeURIComponent(String(officeId))}/subscriptions`,
            subscriptionDto,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request for dental office subscription
     * Call this API to request for dental office subscription renewal or upgrade
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOfficeSubscriptionRequestRenewalOrUpgrade(requestParameters: CreateOfficeSubscriptionRequestRenewalOrUpgradeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DentalOfficeSubscriptionPaymentTransactionRequestPojo>;
    public createOfficeSubscriptionRequestRenewalOrUpgrade(requestParameters: CreateOfficeSubscriptionRequestRenewalOrUpgradeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DentalOfficeSubscriptionPaymentTransactionRequestPojo>>;
    public createOfficeSubscriptionRequestRenewalOrUpgrade(requestParameters: CreateOfficeSubscriptionRequestRenewalOrUpgradeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DentalOfficeSubscriptionPaymentTransactionRequestPojo>>;
    public createOfficeSubscriptionRequestRenewalOrUpgrade(requestParameters: CreateOfficeSubscriptionRequestRenewalOrUpgradeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const officeId = requestParameters.officeId;
        if (officeId === null || officeId === undefined) {
            throw new Error('Required parameter officeId was null or undefined when calling createOfficeSubscriptionRequestRenewalOrUpgrade.');
        }
        const provider = requestParameters.provider;
        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling createOfficeSubscriptionRequestRenewalOrUpgrade.');
        }
        const subscriptionDto = requestParameters.subscriptionDto;
        if (subscriptionDto === null || subscriptionDto === undefined) {
            throw new Error('Required parameter subscriptionDto was null or undefined when calling createOfficeSubscriptionRequestRenewalOrUpgrade.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (provider !== undefined && provider !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>provider, 'provider');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<DentalOfficeSubscriptionPaymentTransactionRequestPojo>(`${this.configuration.basePath}/dental-offices/${encodeURIComponent(String(officeId))}/subscriptions/renewal-or-upgrade`,
            subscriptionDto,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a Subscription Package
     * Call this API to save a new subscription package
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSubscriptionPackage(requestParameters: CreateSubscriptionPackageRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Subscription>;
    public createSubscriptionPackage(requestParameters: CreateSubscriptionPackageRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Subscription>>;
    public createSubscriptionPackage(requestParameters: CreateSubscriptionPackageRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Subscription>>;
    public createSubscriptionPackage(requestParameters: CreateSubscriptionPackageRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const subscriptionPackageDto = requestParameters.subscriptionPackageDto;
        if (subscriptionPackageDto === null || subscriptionPackageDto === undefined) {
            throw new Error('Required parameter subscriptionPackageDto was null or undefined when calling createSubscriptionPackage.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Subscription>(`${this.configuration.basePath}/subscriptions`,
            subscriptionPackageDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentSubscription(requestParameters: GetCurrentSubscriptionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<SubscriptionSummaryPojo>;
    public getCurrentSubscription(requestParameters: GetCurrentSubscriptionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<SubscriptionSummaryPojo>>;
    public getCurrentSubscription(requestParameters: GetCurrentSubscriptionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<SubscriptionSummaryPojo>>;
    public getCurrentSubscription(requestParameters: GetCurrentSubscriptionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const officeId = requestParameters.officeId;
        if (officeId === null || officeId === undefined) {
            throw new Error('Required parameter officeId was null or undefined when calling getCurrentSubscription.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SubscriptionSummaryPojo>(`${this.configuration.basePath}/subscriptions/${encodeURIComponent(String(officeId))}/current`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Dental Office Subscription History
     * Call this API to get dental office Subscription history
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDentalOfficeSubscriptionHistory(requestParameters: GetDentalOfficeSubscriptionHistoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<DentalOfficeSubscriptionHistory>>;
    public getDentalOfficeSubscriptionHistory(requestParameters: GetDentalOfficeSubscriptionHistoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<DentalOfficeSubscriptionHistory>>>;
    public getDentalOfficeSubscriptionHistory(requestParameters: GetDentalOfficeSubscriptionHistoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<DentalOfficeSubscriptionHistory>>>;
    public getDentalOfficeSubscriptionHistory(requestParameters: GetDentalOfficeSubscriptionHistoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const dentalOfficeId = requestParameters.dentalOfficeId;
        if (dentalOfficeId === null || dentalOfficeId === undefined) {
            throw new Error('Required parameter dentalOfficeId was null or undefined when calling getDentalOfficeSubscriptionHistory.');
        }
        const dateFrom = requestParameters.dateFrom;
        const dateTo = requestParameters.dateTo;
        const limit = requestParameters.limit;
        const offset = requestParameters.offset;
        const subscriptionStatus = requestParameters.subscriptionStatus;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateFrom !== undefined && dateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFrom, 'dateFrom');
        }
        if (dateTo !== undefined && dateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateTo, 'dateTo');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (subscriptionStatus !== undefined && subscriptionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subscriptionStatus, 'subscriptionStatus');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<DentalOfficeSubscriptionHistory>>(`${this.configuration.basePath}/dental-office/${encodeURIComponent(String(dentalOfficeId))}/subscription-history`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Count of Dental Office on Free Subscription Package
     * Call this API to get count of free subscription package
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreeSubscriptionCount(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<FreeSubscriptionPojo>;
    public getFreeSubscriptionCount(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<FreeSubscriptionPojo>>;
    public getFreeSubscriptionCount(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<FreeSubscriptionPojo>>;
    public getFreeSubscriptionCount(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FreeSubscriptionPojo>(`${this.configuration.basePath}/dental-offices/free-subscriptions/count`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfficeSubscriptionHistory(requestParameters: GetOfficeSubscriptionHistoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsSubscriptionSummaryPojo>;
    public getOfficeSubscriptionHistory(requestParameters: GetOfficeSubscriptionHistoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsSubscriptionSummaryPojo>>;
    public getOfficeSubscriptionHistory(requestParameters: GetOfficeSubscriptionHistoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsSubscriptionSummaryPojo>>;
    public getOfficeSubscriptionHistory(requestParameters: GetOfficeSubscriptionHistoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const filter = requestParameters.filter;
        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getOfficeSubscriptionHistory.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filter !== undefined && filter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filter, 'filter');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsSubscriptionSummaryPojo>(`${this.configuration.basePath}/subscriptions/history`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Subscription Packages
     * Call this API to get existing subscription packages
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubscriptionPackages(requestParameters: GetSubscriptionPackagesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsPojoSubscriptionPackagePojo>;
    public getSubscriptionPackages(requestParameters: GetSubscriptionPackagesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsPojoSubscriptionPackagePojo>>;
    public getSubscriptionPackages(requestParameters: GetSubscriptionPackagesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsPojoSubscriptionPackagePojo>>;
    public getSubscriptionPackages(requestParameters: GetSubscriptionPackagesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const filter = requestParameters.filter;
        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getSubscriptionPackages.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filter !== undefined && filter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filter, 'filter');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsPojoSubscriptionPackagePojo>(`${this.configuration.basePath}/subscriptions`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Dental office auto renewal
     * Call this API to set auto renew on a dental office subscription
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setDentalOfficeAutoRenewal(requestParameters: SetDentalOfficeAutoRenewalRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<string>;
    public setDentalOfficeAutoRenewal(requestParameters: SetDentalOfficeAutoRenewalRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<string>>;
    public setDentalOfficeAutoRenewal(requestParameters: SetDentalOfficeAutoRenewalRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<string>>;
    public setDentalOfficeAutoRenewal(requestParameters: SetDentalOfficeAutoRenewalRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setDentalOfficeAutoRenewal.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<string>(`${this.configuration.basePath}/dental-offices/${encodeURIComponent(String(id))}/subscriptions/auto-renewal`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Subscription Package
     * Call this API to update an existing subscription package
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubscriptionPackage(requestParameters: UpdateSubscriptionPackageRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Subscription>;
    public updateSubscriptionPackage(requestParameters: UpdateSubscriptionPackageRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Subscription>>;
    public updateSubscriptionPackage(requestParameters: UpdateSubscriptionPackageRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Subscription>>;
    public updateSubscriptionPackage(requestParameters: UpdateSubscriptionPackageRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const code = requestParameters.code;
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling updateSubscriptionPackage.');
        }
        const subscriptionPackageDto = requestParameters.subscriptionPackageDto;
        if (subscriptionPackageDto === null || subscriptionPackageDto === undefined) {
            throw new Error('Required parameter subscriptionPackageDto was null or undefined when calling updateSubscriptionPackage.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<Subscription>(`${this.configuration.basePath}/subscriptions/${encodeURIComponent(String(code))}`,
            subscriptionPackageDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Subscription Package status
     * Call this API to activate/deactivate an existing subscription package
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubscriptionPackageStatus(requestParameters: UpdateSubscriptionPackageStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Subscription>;
    public updateSubscriptionPackageStatus(requestParameters: UpdateSubscriptionPackageStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Subscription>>;
    public updateSubscriptionPackageStatus(requestParameters: UpdateSubscriptionPackageStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Subscription>>;
    public updateSubscriptionPackageStatus(requestParameters: UpdateSubscriptionPackageStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const code = requestParameters.code;
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling updateSubscriptionPackageStatus.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<Subscription>(`${this.configuration.basePath}/subscriptions/${encodeURIComponent(String(code))}/status`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
