/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WalletTransactionPojo { 
    dateCreated?: string;
    bankAccountName?: string;
    maskedBankAccountNumber?: string;
    amountPaid?: number;
    walletTransactionStatus?: WalletTransactionPojo.WalletTransactionStatusEnum;
    transactionCode?: string;
    payOutRetryCount?: number;
    transferRetryCount?: number;
    extimatedPaymentArrivalDate?: string;
    apiMessages?: Array<string>;
}
export namespace WalletTransactionPojo {
    export type WalletTransactionStatusEnum = 'PROCESSING' | 'FAILED_TRANSFER' | 'REVERSED' | 'PAID' | 'PENDING' | 'TRANSFERED' | 'IN_TRANSIT' | 'FAILED_PAYOUT';
    export const WalletTransactionStatusEnum = {
        Processing: 'PROCESSING' as WalletTransactionStatusEnum,
        FailedTransfer: 'FAILED_TRANSFER' as WalletTransactionStatusEnum,
        Reversed: 'REVERSED' as WalletTransactionStatusEnum,
        Paid: 'PAID' as WalletTransactionStatusEnum,
        Pending: 'PENDING' as WalletTransactionStatusEnum,
        Transfered: 'TRANSFERED' as WalletTransactionStatusEnum,
        InTransit: 'IN_TRANSIT' as WalletTransactionStatusEnum,
        FailedPayout: 'FAILED_PAYOUT' as WalletTransactionStatusEnum
    };
}


