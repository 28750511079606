<div class="container my-3">
<div id="price-table">
<section>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <!--PRICE HEADING START-->
                    <header>
                      <div class="pricing-header p-3 pb-4 mx-auto text-center mt-1">
                        <h3 *ngIf="isExtranet" class="display-4 fw-bold pb-3 text-dentaldoor">Price Plans for Dental Offices</h3>
                        <h3 *ngIf="!isExtranet" class="display-4 fw-bold pb-3 text-dentaldoor">Price Plans</h3>
                        <p class="fs-5 text-dentaldoor">Upgrade now to get the most out of DentalDoor through our wide range of
                          services.</p>
                      </div>
                    </header>
                    <!--//PRICE HEADING END-->
                </div>
            </div>
        </div>
        <div class="container">
            <div [formGroup]="form" class="d-flex align-items-center justify-content-center wrapper mx-5">
              <div class=" form-check form-check-inline ">
                <input class="form-check-input" type="radio" name="cycle" id="inlineRadio1" formControlName="cycle" value="0">
                <label class="form-check-label mt-1 px-2" for="inlineRadio1">
                  Monthly
                  <p class="text-light">-</p>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="cycle" id="inlineRadio2" formControlName="cycle" value="1">
                <label class="form-check-label mt-1 px-2" for="inlineRadio2">
                  Quarterly
                  <p class="text-secondary">(10% off)</p>
                </label>
              </div>
              <div class=" form-check form-check-inline">
                <input class="form-check-input" type="radio" name="cycle" id="inlineRadio3" formControlName="cycle" value="2"
                  checked>
                <label class="form-check-label mt-1 px-2" for="inlineRadio3">
                  Yearly
                  <p class="text-secondary">(20% off)</p>
                </label>
              </div>
            </div>
            <!--BLOCK ROW START-->
            <div class="row d-flex align-items-center justify-content-center " *ngIf="loaded">
              <div class="col-md-4" *ngFor="let sub of subscriptionPackages; let i = index">

                <!--PRICE CONTENT START-->
                <div class="price-content shadow">

                  <!--HEAD PRICE DETAIL START-->
                  <div class="price-header clearfix">

                    <!--HEAD CONTENT START-->
                    <div class="header-content clearfix">

                      <!--HEAD START-->
                      <div  [ngClass]="{'advanced_bg': sub.name.toLowerCase() == 'advanced', 'head_bg': sub.name.toLowerCase() == 'pro'}"></div>
                      <div class="head">
                        <span [ngClass]="{'color: #fff': sub.name.toLowerCase() == 'advanced'}">{{ sub.name }}</span>
                      </div>
                      <!--//HEAD END-->

                    </div>
                    <!--//HEAD CONTENT END-->

                    <!--PRICE START-->
                    <div class="price-tag clearfix">
                      <span class="price">
<!--                        <span class="sign">$</span>-->
                        <span class="currency">{{getInitialPart(sub.subscriptionCycles[currentCycle].amount) | currency:'USD' : 'symbol':'1.0'}}
                          <sup style="margin-left: -15px">{{'.' + getDecimalPart(sub.subscriptionCycles[currentCycle].amount)}}</sup>
                        </span>
                        <span class="cent" style="margin-left: -55px; margin-top:50px">/MONTH</span>

                      </span>
                    </div>
                    <!--//PRICE END-->

                  </div>
                  <!--//HEAD PRICE DETAIL END-->

                  <!--FEATURE LIST START-->
                  <div [ngClass]="{'advanced-fl': sub.name.toLowerCase() == 'advanced', 'feature-list': sub.name.toLowerCase() == 'pro'}">
                    <ul *ngFor="let packageItem of sortPackages(sub.packageItems)">
                      <li>{{ packageItem.packageItem.name }}</li>
                    </ul>
                  </div>
                  <!--//FEATURE LIST END-->

                  <!--BUTTON START-->
                  <div class="clearfix" [ngClass]="{'buynowbtn': sub.name.toLowerCase() === 'advanced', 'price-btn': sub.name.toLowerCase() === 'pro'}">
                    <a class="pointer"
                      (click)="choosePlan(sub)">{{sub.name.toLowerCase() === 'advanced' ? 'Get Advanced' : 'Get Pro'}}</a>
                  </div>
                  <!--//BUTTON END-->

                </div>
                <!--//PRICE CONTENT END-->

              </div>

              <div class="col-md-4">

                <!--PRICE CONTENT START-->
                <div class="price-content clearfix shadow">

                  <!--HEAD PRICE DETAIL START-->
                  <div class="price-header clearfix">

                    <!--HEAD CONTENT START-->
                    <div class="header-content clearfix">

                      <!--HEAD START-->
                      <div class="head_bg"></div>
                      <div class="head">
                        <span>Enterprise</span>
                      </div>
                      <!--//HEAD END-->

                    </div>
                    <!--//HEAD CONTENT END-->

                    <!--PRICE START-->
                    <div class="price-tag clearfix">
                      <span class="price">
                        <span class="currency">Custom</span>
                      </span>
                    </div>
                    <!--//PRICE END-->

                  </div>
                  <!--//HEAD PRICE DETAIL END-->

                  <!--FEATURE LIST START-->
                  <div class="feature-list">
                    <ul>
                      <li style="color: transparent;">~</li>
                      <li>Contact our sales department</li>
                      <li>for custom features</li>
                      <li>just for you.</li>
                      <li style="color: transparent;">~</li>
                    </ul>
                  </div>
                  <!--//FEATURE LIST END-->

                  <!--BUTTON START-->
                  <div class="price-btn clearfix pointer" (click)="customPricingModal()">
                    <a class="" data-bs-toggle="modal">Contact Sales</a>
                  </div>
                  <!--//BUTTON END-->

                </div>
                <!--//PRICE CONTENT END-->

              </div>
            </div>
            <!--//BLOCK ROW END-->
        </div>
    </section>
</div>
</div>
