<div
  class="bg-primary ms-lg-2"
  style="border-bottom: 1px solid rgba(209, 209, 209, 0.5)"
>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid px-md-5">
      <button
        class="btn p-1 order-sm-first text-light d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasResponsive"
        aria-controls="offcanvasResponsive">
        <!-- <span class="navbar-toggler-icon"></span> -->
        <iconify-icon icon="ci:menu-alt-03" class="fs-2"></iconify-icon>
      </button>
      <div class="" style="color: white">
        <h5
          class="mb-0"
          style="display: flex; align-items: baseline"
          placement="top"
        >
          <iconify-icon
            icon="ep:office-building"
            style="position: relative; top: 0.5rem"
            width="40"
            height="40"
          >
          </iconify-icon>
          <p class="mb-0 overflow">{{ this.accountName }}</p>
        </h5>
      </div>
      <div class="ddlogo mx-auto">
        <img
          src="./assets/img/ddlogoaltcolor2.svg"
          class="img-fluid"
          alt="DentalDoor Logo"
          width="120"
        />
      </div>
      <div>
        <div class="">
          <div class="d-flex align-items-center">
            <div class="dropdown p-3 pe-0 user-avatar btn-group d-md-none">
              <div
                class="d-flex justify-content-between align-items-center pointer text-truncate"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center">
                  <img
                    src="./assets/img/user.png"
                    alt=""
                    width="40"
                    height="40"
                    class="rounded-circle rounded me-2"
                  />
                </div>
                <div class="">
                  <ul class="dropdown-menu shadow text-small">
                    <li
                      class="d-flex display-content-center align-items-center"
                    >
                      <a class="dropdown-item" [routerLink]="['/accounts']"
                      >
                        <iconify-icon
                          icon="carbon:user-avatar"
                          style="
                            color: #02183d9a;
                            margin-right: 5px;
                            margin-bottom: -3px;
                          "
                          width="18"
                          height="18"
                        ></iconify-icon>
                        Profile</a
                      >
                    </li>
                    <li>
                      <hr class="dropdown-divider"/>
                    </li>
                    <li class="mb-0">
                      <a class="dropdown-item" (click)="logOut()"
                      >
                        <iconify-icon
                          icon="codicon:sign-out"
                          style="
                            color: #02183d9a;
                            margin-right: 5px;
                            margin-bottom: -3px;
                          "
                          width="18"
                          height="18"
                        ></iconify-icon>
                        Logout</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div class="d-lg-none d-block">
    <div
      class="offcanvas-lg offcanvas-start"
      tabindex="-1"
      id="offcanvasResponsive"
      aria-labelledby="offcanvasResponsiveLabel"
      style="width: 7rem"
    >
      <app-admin-dashboard-sidebar></app-admin-dashboard-sidebar>
    </div>
  </div>
</div>
