import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BillingListComponent } from './billing-list/billing-list.component';
import { PlansAndPricingComponent } from './plans-and-pricing/plans-and-pricing.component';
import { CheckoutComponent } from './checkout/checkout.component';
import {BillingRoutingModule} from "./billing-routing.module";
import {SharedModule} from "../../shared/shared.module";
import {NgxMaskModule} from "ngx-mask";
import { BillingWizardComponent } from './billing-wizard/billing-wizard.component';
import {ArchwizardModule} from "angular-archwizard";
import {FormsModule} from "@angular/forms";
import { BillingUpgradeComponent } from './billing-upgrade/billing-upgrade.component';



@NgModule({
    declarations: [
        BillingListComponent,
        PlansAndPricingComponent,
        CheckoutComponent,
        BillingWizardComponent,
        BillingUpgradeComponent
    ],
    imports: [
        BillingRoutingModule,
        SharedModule,
        NgxMaskModule,
        ArchwizardModule,
        FormsModule,
    ],
  exports: [
    PlansAndPricingComponent,
    CheckoutComponent
  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BillingModule { }
