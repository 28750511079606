import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {
  DashboardControllerService,
  DentalOfficeControllerService,
  OfficeMembersFilterHandler,
  PortalUser, ReferralRequestSearchFilter
} from "dd-core-api-sdk";
import ReferralStatusConstantEnum = ReferralRequestSearchFilter.ReferralStatusConstantEnum;
import {KeyValuePipe} from "@angular/common";
import {BehaviorSubject} from "rxjs";
import {BaseChartDirective} from "ng2-charts";
import {FormBuilder, FormGroup} from "@angular/forms";
import * as moment from "moment";
import {UserAccountKeycloak} from "../../../../services/UserAccountKeycloak";
import {IClipboardResponse} from "ngx-clipboard";

@Component({
  selector: "app-dashboard-holder",
  templateUrl: "./dashboard-holder.component.html",
  styleUrls: ["./dashboard-holder.component.css"],
})
export class DashboardHolderComponent implements OnInit, AfterViewInit {

  user: UserKeycloak;
  members = [];
  professional;
  upcomingAppointment: number;
  awaitingReferral: number;
  appointmentsToday: number;
  referralStatus = ReferralStatusConstantEnum;
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  public barChartType: any = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: 'COMPLETED',
      backgroundColor: 'rgba(40,167,69)',
      hoverBackgroundColor: 'rgba(40,167,69)'
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: 'CANCELLED',
      backgroundColor: 'rgba(166,4,9)',
      hoverBackgroundColor: 'rgba(166,4,9)'
    }
  ];
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  form: FormGroup;
  years: number[] = [];
  isChartDataEmpty: boolean;
  selectedYear = new Date().getFullYear();
  userAccount: UserAccountKeycloak;

  copied: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationServiceKeycloak,
    private dashboardControllerService: DashboardControllerService,
    private key: KeyValuePipe,
    private fb: FormBuilder,
    private dentalOfficeService: DentalOfficeControllerService,
  ) {
    for (let year = this.selectedYear; year >= 2010; year--) {
      this.years.push(year);
    }
  }

  ngAfterViewInit(): void {
    if (AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id)
      this.showDashboardChart();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      years: [this.selectedYear]
    });

    this.authenticationService.getUser().subscribe(user => {
      this.userAccount = AuthenticationServiceKeycloak._currentUserAccount;
      this.user = user;
      this.professional = user?.dentalProfessional;
      if (AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id) {
        this.getDashboardStats(AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id);
      }
      if (this.shouldShowRoute() && !user?.dentalProfessional) {
        if (!AuthenticationServiceKeycloak._currentUserAccount?.dentalOfficeSubscription && AuthenticationServiceKeycloak._currentUserAccount?.otherData?.specialityOffice) {
          this.router.navigate(['/billing/purchase-plan']);
        }
      } else {
        this.router.navigate(['/dashboard']);
        this.getMembers(AuthenticationServiceKeycloak._currentUserAccount?.accountId);
      }
      // if (user.invitesSent < 1) {
      //   this.router.navigate(['/invites-list/create']);
      // }
    })
    this.showDashboardChart();

    this.form.controls.years.valueChanges.subscribe(v => {
      this.showDashboardChart(v);
    });
  }

  getDashboardStats(officeId) {
    this.dashboardControllerService.getDentalOfficeDashboardStat({dentalOfficeId: officeId}).subscribe(
      res => {
        this.awaitingReferral = res.referralRequestCount;
        this.upcomingAppointment = res.upcomingAppointmentCount;
        this.appointmentsToday = res.totAppointmentToday;
      }
    )
  }

  shouldShowRoute() {
    return AuthenticationServiceKeycloak._currentUserAccount?.dentalOfficeSubscription?.subscriptionStatus != 'TRIAL'
      && AuthenticationServiceKeycloak._currentUserAccount?.otherData?.specialityOffice;
  }

  getMembers(accountId: number) {
    if (accountId)
      this.dentalOfficeService
        .getOfficeMembers({
          filter: this.searchFilter(accountId),
        })
        .subscribe(query => {
          this.members = query.results;
        });
  }

  showDashboardChart(years?: number) {
    this.dashboardControllerService.getChartStatistics({
      searchFilter: {
        dentalOfficeId: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
        year: years
      }
    }).subscribe(
      res => {
        this.buildChart(this.key.transform(res));
      }
    )
  }

  buildChart(value) {
    if (value?.length >= 1) {
      this.isChartDataEmpty = false;
      value.forEach(item => {
        if (item.value.COMPLETED) {
          this.barChartData[0].data[item.key - 1] = +item.value.COMPLETED.length;
        }
        if (item.value.CANCELLED) {
          this.barChartData[1].data[item.key - 1] = +item.value.CANCELLED.length;
        }
      });
    } else {
      this.isChartDataEmpty = true;
      this.barChartData = [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'COMPLETED',
          backgroundColor: 'rgba(40,167,69)',
          hoverBackgroundColor: 'rgba(40,167,69)'
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'CANCELLED',
          backgroundColor: 'rgba(166,4,9)',
          hoverBackgroundColor: 'rgba(166,4,9)'
        }
      ];
    }
    this.chart?.update();

  }


  searchFilter(accountId: number): OfficeMembersFilterHandler {
    return {
      accountId: accountId,
      limit: 5,
      offset: 0
    }
  }

  contentCopied($event: IClipboardResponse) {
    this.copied = $event.isSuccess;
    setTimeout(v => {
      this.copied = false;
    }, 1500);
  }

  goToInvite(){
    this.router.navigate(['/invites-list'],{queryParams:{popup:'create'}});
  }
}
