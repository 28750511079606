<aw-wizard [navBarLayout]="null" [disableNavigationBar]="true" [defaultStepIndex]="0" class="success">
    <aw-wizard-step>
        <app-account-linking-conditions (dismissButtonClicked)="emitDismissClicked($event)"></app-account-linking-conditions>
    </aw-wizard-step>
    <aw-wizard-step>
        <app-account-linking-bank (moveToNextStep)="moveToForm($event)" (dismissButtonClicked)="emitDismissClicked($event)"></app-account-linking-bank>
    </aw-wizard-step>
    <aw-wizard-step>
        <app-account-linking-form (dismissButtonClicked)="emitDismissClicked($event)" (submitButtonClickedSuccess)="emmitSuccess($event)"></app-account-linking-form>
    </aw-wizard-step>
</aw-wizard>