import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'noRoundCurrency'})
export class NoRoundCurrencyPipe implements PipeTransform {
  transform(vv: any, ...args: any[]): any {
    let n = 2;
    if (Math.abs(vv) < 1.0) {
      let e = parseInt(vv.toString().split('e-')[1]);
      if (e) {
        vv *= Math.pow(10,e-1);
        vv = '0.' + (new Array(e)).join('0') + vv.toString().substring(2);
      }
    } else {
      let e = parseInt(vv.toString().split('+')[1]);
      if (e > 20) {
        e -= 20;
        vv /= Math.pow(10, e);
        vv += (new Array(e+1)).join('0');
      }
    }
    // From here on the code is the same than the original answer
    const v = (typeof vv === 'string' ? vv : vv.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${'$'+v[0]}.${f.substr(0,n)}`;
    while (f.length < n) f += '0';
    return `${'$'+v[0]}.${f}`
  }

}
