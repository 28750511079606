/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentInvoice } from './payment-invoice';
import { DentalOffice } from './dental-office';
import { SubscriptionCycle } from './subscription-cycle';
import { PortalUser } from './portal-user';


export interface DentalOfficeSubscription { 
    dateCreated?: string;
    status?: DentalOfficeSubscription.StatusEnum;
    subscriptionStatus?: DentalOfficeSubscription.SubscriptionStatusEnum;
    lastUpdated?: string;
    startDate?: string;
    endDate?: string;
    dateCancelled?: string;
    notified?: boolean;
    trialStartDate?: string;
    trialEndDate?: string;
    trial?: boolean;
    retryCount?: number;
    id?: number;
    subscriptionCycle?: SubscriptionCycle;
    paymentInvoice?: PaymentInvoice;
    dentalOffice?: DentalOffice;
    cancelledBy?: PortalUser;
}
export namespace DentalOfficeSubscription {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type SubscriptionStatusEnum = 'ACTIVE' | 'EXPIRED' | 'PENDING' | 'CANCELLED' | 'TRIAL';
    export const SubscriptionStatusEnum = {
        Active: 'ACTIVE' as SubscriptionStatusEnum,
        Expired: 'EXPIRED' as SubscriptionStatusEnum,
        Pending: 'PENDING' as SubscriptionStatusEnum,
        Cancelled: 'CANCELLED' as SubscriptionStatusEnum,
        Trial: 'TRIAL' as SubscriptionStatusEnum
    };
}


