import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DentalOfficePojo,
  PaymentControllerService,
  PaymentInvoicePojo,
  SubscriptionControllerService,
  SubscriptionPackageItem,
  SubscriptionPackagePojo,
  SubscriptionSearchFilter
} from "dd-core-api-sdk";
import { removeUndefinedOrNullFields } from "src/app/models/search-model";
import { BsModalService } from "ngx-bootstrap/modal";
import { SocialService } from "../../../shared/subject/SocialService";

@Component({
  selector: 'app-billing-upgrade',
  templateUrl: './billing-upgrade.component.html',
  styleUrls: ['./billing-upgrade.component.css']
})
export class BillingUpgradeComponent implements OnInit {

  subscriptionPackages: Array<SubscriptionPackagePojo>;
  currentCycle: number;
  timer: any;
  @Output() subscriptionCompleted: EventEmitter<any> = new EventEmitter();
  @Input() dentalOffice: DentalOfficePojo;
  invoice: PaymentInvoicePojo = { customerEmail: '' };
  form: FormGroup;
  isExtranet: boolean;
  loaded: boolean;
  cycle: String;
  showErrorMessageTrigger = false;
  errMessage = '';

  constructor(private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private bsModalService: BsModalService,
    private modalService: BsModalService,
    private paymentControllerService: PaymentControllerService,
    private socialService: SocialService,
    private subscriptionControllerService: SubscriptionControllerService,
  ) {
  }

  ngOnInit(): void {
    this.activeRoute.data.subscribe(data => {
      this.isExtranet = data.isExtranet
    })

    this.form = this.fb.group({
      cycle: ['2']
    });

    this.loadSubscriptionPackages();
    this.currentCycle = this.form.get('cycle').value;
    this.form.get('cycle').valueChanges.subscribe(v => {
      this.currentCycle = v;
    });

  }

  sortPackages(packages: Array<SubscriptionPackageItem>): Array<SubscriptionPackageItem> {
    return packages.sort((a, b) => a.packageItem.name > b.packageItem.name ? 1 : -1)
  }

  loadSubscriptionPackages() {
    const searchFilter: SubscriptionSearchFilter = {
      status: SubscriptionSearchFilter.StatusEnum.Active,
    };
    this.searchSubscriptionPackages(searchFilter);
  }

  searchSubscriptionPackages(searchFilter: SubscriptionSearchFilter) {
    if (this.isExtranet)
      this.socialService.isInterceptor.next(false)
    this.subscriptionControllerService
      .getSubscriptionPackages({ filter: removeUndefinedOrNullFields(searchFilter) })
      .subscribe(value => {
        this.loaded = true
        this.subscriptionPackages = value.results
          .sort((a, b) => a.name < b.name ? 1 : -1);
      });
  }

  getErrorMessage() {
    return this.errMessage;
  }

  choosePlan(row: SubscriptionPackagePojo) {
    if (this.isExtranet) {
      this.socialService.tabSubject.next(0);
      this.router.navigate(['/signup'])
      return;
    }
    this.subscriptionCompleted.emit({ subscriptionPackagePojo: row, cycle: this.currentCycle });
  }

}
