import {FormControl} from "@angular/forms";


export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export class Utils {

  get alphabetsOnlyErrorMsg() {
    return [{
      error: 'pattern',
      format: (label) => `${label} can only contain alphabets.`
    }];
  }

  get digitsOnlyErrorMsg() {
    return [{
      error: 'pattern',
      format: (label) => `${label} can only contain digits.`
    }];
  }

  static isString(value: any): boolean {
    return typeof value == 'string';
  }

  get phoneErrorMsg() {
    return [{
      error: 'phone',
      format: (label) => `${label} number is invalid.`
    }];
  }


  static enumEntries<T>(t: T): ReadonlyArray<readonly [keyof T, T[keyof T]]> {
    const entries = Object.entries(t);
    const plainStringEnum = entries.every(
      ([key, value]) => typeof value === 'string'
    );
    return (plainStringEnum
      ? entries
      : entries.filter(([k, v]) => typeof v !== 'string')) as any;
  }

  static enumKeys<T>(t: T): ReadonlyArray<keyof T> {
    return this.enumEntries(t).map(([key]) => key);
  }

  static enumValues<T>(t: T): ReadonlyArray<T[keyof T]> {
    const values = Object.values(t);
    const plainStringEnum = values.every(x => typeof x === 'string');
    return plainStringEnum ? values : values.filter(x => typeof x !== 'string');
  }

  static getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static getUniqueArray(input: any[]) {
    const filteredArr = input.reduce((acc, current) => {
      const x = acc.find(item => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return filteredArr;
  }

  static monthNumToName(monthnum) {
    return months[monthnum - 1] || '';
  }

 static monthNameToNum(monthname) {
    var month = months.indexOf(monthname);
    return month ? month + 1 : 0;
}

  public static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
