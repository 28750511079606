import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.css']
})
export class QuestionModalComponent implements OnInit {
  form: FormGroup;
  sending: Boolean;
  @Output()
  dismissClicked = new EventEmitter<any>();
  @Output()
  emitQuestionData = new EventEmitter<any>();
  @Input()
  message: string;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      questionResponse: ['']
    });
  }

  emitQuestions() {
    const form = this.form.getRawValue();
    this.sending = true;
    this.emitQuestionData.emit(form.questionResponse);
  }
}
