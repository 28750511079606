/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentInvoice } from './payment-invoice';
import { SubscriptionCycle } from './subscription-cycle';


export interface SubscriptionSummaryPojo { 
    subscriptionId?: number;
    subscriptionExpiry?: string;
    subscriptionStart?: string;
    subscriptionStatus?: SubscriptionSummaryPojo.SubscriptionStatusEnum;
    dateCreated?: string;
    status?: SubscriptionSummaryPojo.StatusEnum;
    subscriptionCycle?: SubscriptionCycle;
    paymentInvoice?: PaymentInvoice;
}
export namespace SubscriptionSummaryPojo {
    export type SubscriptionStatusEnum = 'ACTIVE' | 'EXPIRED' | 'PENDING' | 'CANCELLED' | 'TRIAL';
    export const SubscriptionStatusEnum = {
        Active: 'ACTIVE' as SubscriptionStatusEnum,
        Expired: 'EXPIRED' as SubscriptionStatusEnum,
        Pending: 'PENDING' as SubscriptionStatusEnum,
        Cancelled: 'CANCELLED' as SubscriptionStatusEnum,
        Trial: 'TRIAL' as SubscriptionStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


