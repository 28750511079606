<div>
    <div class="top">
        <h4 class="text-center h4">Preferred time(s) for appointment?</h4>
        <p class="text-center p">Choose all the times that work for you.</p>
    </div>

    <div class="header">

        <div class="inner-header">

            <form class="container" [formGroup]="this.formGroup">
                <div *ngIf="showErrorMessageTrigger">
                    <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert">
                        <p class="m-0 p-0 d-flex align-items-start">
                            <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                              {{ getErrorMessage() | removeUnderscores }}
              </span>
                            <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                            </iconify-icon>
                        </p>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="form-check form-check-inline p-0 col-md" *ngFor="let aff of this.timeSlotPojo; let index = index;">
                        <input class="form-check-input" formControlName="morning" type="checkbox" *ngIf="index ==0" id="Checkbox1" value="{{aff.id}}">
                        <input class="form-check-input" formControlName="afternoon" type="checkbox" *ngIf="index ==1" id="Checkbox2" value="{{aff.id}}">
                        <input class="form-check-input" formControlName="evening" type="checkbox" *ngIf="index ==2" id="Checkbox3" value="{{aff.id}}">
                        <label class="form-check-label card d-flex align-items-center pointer" for="Checkbox1" *ngIf="index ==0">
              <svg xmlns="http://www.w3.org/2000/svg" class="m-2" width="2em" height="2em" viewBox="0 0 16 16">
                <path fill="#ddd"
                      d="m14 10l-1.58-1.18L13.2 7l-2-.23L11 4.8l-1.82.78L8 4L6.82 5.58L5 4.8l-.23 2L2.8 7l.78 1.82L2 10H0v1h16v-1h-2zM4 10a4.143 4.143 0 0 1 3.993-4A4.143 4.143 0 0 1 12 9.993L4 10z"/>
              </svg>
              <p class="fw-bold m-0">{{aff.name}}</p>
              <p class="">Before {{aff.endTime | date: 'H a'}}</p>
            </label>
                        <!---- Second Label ---->
                        <label class="form-check-label card d-flex align-items-center pointer" for="Checkbox2" *ngIf="index ==1">
              <svg xmlns="http://www.w3.org/2000/svg" class="m-2" width="2em" height="2em" viewBox="0 0 24 24">
                <g fill="none" stroke="#ddd" stroke-dasharray="2" stroke-dashoffset="2" stroke-linecap="round"
                   stroke-width="2">
                  <path d="M0 0">
                    <animate fill="freeze" attributeName="d" begin="1.2s" dur="0.2s"
                             values="M12 19v1M19 12h1M12 5v-1M5 12h-1;M12 21v1M21 12h1M12 3v-1M3 12h-1"/>
                    <animate fill="freeze" attributeName="stroke-dashoffset" begin="1.2s" dur="0.2s" values="2;0"/>
                  </path>
                  <path d="M0 0">
                    <animate fill="freeze" attributeName="d" begin="1.5s" dur="0.2s"
                             values="M17 17l0.5 0.5M17 7l0.5 -0.5M7 7l-0.5 -0.5M7 17l-0.5 0.5;M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"/>
                    <animate fill="freeze" attributeName="stroke-dashoffset" begin="1.5s" dur="1.2s" values="2;0"/>
                  </path>
                  <animateTransform attributeName="transform" dur="30s" repeatCount="indefinite" type="rotate"
                                    values="0 12 12;360 12 12"/>
                </g>
                <g fill="#ddd">
                  <path
                    d="M15.22 6.03L17.75 4.09L14.56 4L13.5 1L12.44 4L9.25 4.09L11.78 6.03L10.87 9.09L13.5 7.28L16.13 9.09L15.22 6.03Z">
                    <animate fill="freeze" attributeName="fill-opacity" dur="0.4s" values="1;0"/>
                  </path>
                  <path
                    d="M19.61 12.25L21.25 11L19.19 10.95L18.5 9L17.81 10.95L15.75 11L17.39 12.25L16.8 14.23L18.5 13.06L20.2 14.23L19.61 12.25Z">
                    <animate fill="freeze" attributeName="fill-opacity" begin="0.2s" dur="0.4s" values="1;0"/>
                  </path>
                </g>
                <g fill="none" stroke="#ddd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                  <path
                    d="M7 6 C7 12.08 11.92 17 18 17 C18.53 17 19.05 16.96 19.56 16.89 C17.95 19.36 15.17 21 12 21 C7.03 21 3 16.97 3 12 C3 8.83 4.64 6.05 7.11 4.44 C7.04 4.95 7 5.47 7 6 Z"/>
                  <set attributeName="opacity" begin="0.6s" to="0"/>
                </g>
                <mask id="lineMdMoonToSunnyOutlineLoopTransition0">
                  <circle cx="12" cy="12" r="12" fill="#fff"/>
                  <circle cx="12" cy="12" r="8">
                    <animate fill="freeze" attributeName="r" begin="0.6s" dur="0.4s" values="8;4"/>
                  </circle>
                  <circle cx="18" cy="6" r="12" fill="#fff">
                    <animate fill="freeze" attributeName="cx" begin="0.6s" dur="0.4s" values="18;22"/>
                    <animate fill="freeze" attributeName="cy" begin="0.6s" dur="0.4s" values="6;2"/>
                    <animate fill="freeze" attributeName="r" begin="0.6s" dur="0.4s" values="12;3"/>
                  </circle>
                  <circle cx="18" cy="6" r="10">
                    <animate fill="freeze" attributeName="cx" begin="0.6s" dur="0.4s" values="18;22"/>
                    <animate fill="freeze" attributeName="cy" begin="0.6s" dur="0.4s" values="6;2"/>
                    <animate fill="freeze" attributeName="r" begin="0.6s" dur="0.4s" values="10;1"/>
                  </circle>
                </mask>
                <circle cx="12" cy="12" r="10" fill="#ddd" mask="url(#lineMdMoonToSunnyOutlineLoopTransition0)"
                        opacity="0">
                  <set attributeName="opacity" begin="0.6s" to="1"/>
                  <animate fill="freeze" attributeName="r" begin="0.6s" dur="0.4s" values="10;6"/>
                </circle>
              </svg>
              <p class="fw-bold m-0">{{aff.name}}</p>
              <p>{{aff.startTime | date: 'H a'}}-{{aff.endTime | date: 'h a'}}</p>
            </label>
                        <!---- Third Label ---->
                        <label class="form-check-label card d-flex align-items-center pointer" for="Checkbox3" *ngIf="index == 2">
              <svg xmlns="http://www.w3.org/2000/svg" class="m-2" width="2em" height="2em" viewBox="0 0 24 24">
                <g fill="none" stroke="#ddd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                  <g stroke-dasharray="2">
                    <path d="M12 21v1M21 12h1M12 3v-1M3 12h-1">
                      <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.2s" values="4;2"/>
                    </path>
                    <path d="M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5">
                      <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.2s" dur="0.2s" values="4;2"/>
                    </path>
                  </g>
                  <path
                    d="M7 6 C7 12.08 11.92 17 18 17 C18.53 17 19.05 16.96 19.56 16.89 C17.95 19.36 15.17 21 12 21 C7.03 21 3 16.97 3 12 C3 8.83 4.64 6.05 7.11 4.44 C7.04 4.95 7 5.47 7 6 Z"
                    opacity="0">
                    <set attributeName="opacity" begin="0.5s" to="1"/>
                  </path>
                </g>
                <g fill="#ddd" fill-opacity="0">
                  <path
                    d="m15.22 6.03l2.53-1.94L14.56 4L13.5 1l-1.06 3l-3.19.09l2.53 1.94l-.91 3.06l2.63-1.81l2.63 1.81z">
                    <animate id="lineMdSunnyOutlineToMoonLoopTransition0" fill="freeze" attributeName="fill-opacity"
                             begin="0.6s;lineMdSunnyOutlineToMoonLoopTransition0.begin+6s" dur="0.4s" values="0;1"/>
                    <animate fill="freeze" attributeName="fill-opacity"
                             begin="lineMdSunnyOutlineToMoonLoopTransition0.begin+2.2s" dur="0.4s" values="1;0"/>
                  </path>
                  <path
                    d="M13.61 5.25L15.25 4l-2.06-.05L12.5 2l-.69 1.95L9.75 4l1.64 1.25l-.59 1.98l1.7-1.17l1.7 1.17z">
                    <animate fill="freeze" attributeName="fill-opacity"
                             begin="lineMdSunnyOutlineToMoonLoopTransition0.begin+3s" dur="0.4s" values="0;1"/>
                    <animate fill="freeze" attributeName="fill-opacity"
                             begin="lineMdSunnyOutlineToMoonLoopTransition0.begin+5.2s" dur="0.4s" values="1;0"/>
                  </path>
                  <path
                    d="M19.61 12.25L21.25 11l-2.06-.05L18.5 9l-.69 1.95l-2.06.05l1.64 1.25l-.59 1.98l1.7-1.17l1.7 1.17z">
                    <animate fill="freeze" attributeName="fill-opacity"
                             begin="lineMdSunnyOutlineToMoonLoopTransition0.begin+0.4s" dur="0.4s" values="0;1"/>
                    <animate fill="freeze" attributeName="fill-opacity"
                             begin="lineMdSunnyOutlineToMoonLoopTransition0.begin+2.8s" dur="0.4s" values="1;0"/>
                  </path>
                  <path
                    d="m20.828 9.731l1.876-1.439l-2.366-.067L19.552 6l-.786 2.225l-2.366.067l1.876 1.439L17.601 12l1.951-1.342L21.503 12z">
                    <animate fill="freeze" attributeName="fill-opacity"
                             begin="lineMdSunnyOutlineToMoonLoopTransition0.begin+3.4s" dur="0.4s" values="0;1"/>
                    <animate fill="freeze" attributeName="fill-opacity"
                             begin="lineMdSunnyOutlineToMoonLoopTransition0.begin+5.6s" dur="0.4s" values="1;0"/>
                  </path>
                </g>
                <mask id="lineMdSunnyOutlineToMoonLoopTransition1">
                  <circle cx="12" cy="12" r="12" fill="#fff"/>
                  <circle cx="12" cy="12" r="4">
                    <animate fill="freeze" attributeName="r" begin="0.1s" dur="0.4s" values="4;8"/>
                  </circle>
                  <circle cx="22" cy="2" r="3" fill="#fff">
                    <animate fill="freeze" attributeName="cx" begin="0.1s" dur="0.4s" values="22;18"/>
                    <animate fill="freeze" attributeName="cy" begin="0.1s" dur="0.4s" values="2;6"/>
                    <animate fill="freeze" attributeName="r" begin="0.1s" dur="0.4s" values="3;12"/>
                  </circle>
                  <circle cx="22" cy="2" r="1">
                    <animate fill="freeze" attributeName="cx" begin="0.1s" dur="0.4s" values="22;18"/>
                    <animate fill="freeze" attributeName="cy" begin="0.1s" dur="0.4s" values="2;6"/>
                    <animate fill="freeze" attributeName="r" begin="0.1s" dur="0.4s" values="1;10"/>
                  </circle>
                </mask>
                <circle cx="12" cy="12" r="6" fill="#ddd" mask="url(#lineMdSunnyOutlineToMoonLoopTransition1)">
                  <set attributeName="opacity" begin="0.5s" to="0"/>
                  <animate fill="freeze" attributeName="r" begin="0.1s" dur="0.4s" values="6;10"/>
                </circle>
              </svg>
              <p class="fw-bold m-0">{{aff.name}}</p>
              <p>After {{aff.startTime | date: 'h a'}}</p>
            </label>
                    </div>
                    <div class="row align-items-center justify-content-center g-3 mt-3 mt-sm-5 ">
                        <div class="col-auto">
                            <button type="button" name="button" class="btn btn-outline-primary btn-large p-3 rounded-5" (click)="noPreferenceClicked()" *ngIf="showPreference">
                No Preference
              </button>
                        </div>
                        <div class="col-auto" *ngIf="!this.showPreference">
                            <button type="button" name="button" class="btn btn-outline-primary btn-large p-3 rounded-5" (click)="gotoNextStep()">
                Continue
              </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div>
            <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
          <use xlink:href="#gentle-wave" x="48" y="7" fill="hsla(0,0%,100%,.4)"></use>
        </g>
      </svg>

        </div>

    </div>

</div>
