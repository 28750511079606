<div *ngIf="currentAccount">
  <ng-container [ngSwitch]="currentAccount.accountType" *ngIf="currentAccount && user">
    <ng-container *ngSwitchDefault>
      <app-dashboard-holder
        *ngIf="(currentAccount && currentAccount.accountType!='SUPER_ADMIN' && currentAccount.accountType != 'AFFILIATE')"></app-dashboard-holder>
      <app-affiliate-dashboard
        *ngIf="(currentAccount && currentAccount.accountType != 'SUPER_ADMIN' && currentAccount.accountType == 'AFFILIATE')"></app-affiliate-dashboard>
      <app-admin-dashboard *ngIf="(currentAccount && currentAccount.accountType=='SUPER_ADMIN')"></app-admin-dashboard>
    </ng-container>
  </ng-container>
</div>
<app-dashboard-holder *ngIf="!currentAccount && user"></app-dashboard-holder>
