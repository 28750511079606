<div class="vh-100 sidebar-card d-flex flex-column flex-shrink-0 p-3 px-2" id="siderbar-wrapper" style="width: 7rem">
    <div class="">
        <div class="nav_logo d-flex justify-content-between align-items-center">
            <div class="logo-name-main nav_logo-name">
                <a href="/" class="d-flex align-items-center mb-3 mb-md-0">
                    <img src="./assets/img/dashboard-logo.svg" class="img-fluid" alt="" />
                </a>
            </div>
            <div class="nav_logo-name logo-name-icon">
                <a href="/" class="d-flex align-items-center justify-content-center mb-3 mb-md-0">
                    <img src="./assets/img/logoCollapsed.svg" class="img-fluid" alt="" />
                </a>
            </div>
        </div>
    </div>

    <hr class="hr" />

    <div class="mb-auto" style="overflow-y: auto">
        <ul class="nav nav-pills flex-column mb-auto" style="width: fit-content; overflow-x: hidden; overflow-y: auto">
            <li class="nav-item sidebar-item" *ngIf="shouldShowRoute() || this.user?.dentalProfessional">
                <a [routerLinkActive]="'active'" class="nav-link d-block align-items-center" aria-current="page" [routerLink]="'/dashboard'">
                    <iconify-icon inline icon="codicon:dashboard" class="fs-3 ms-3"></iconify-icon>
                    <small class="ms-2">Overview</small>
                </a>
            </li>
            <li class="sidebar-item" *ngIf="shouldShowRoute() || this.user?.dentalProfessional">
                <a [routerLinkActive]="'active'" [routerLink]="'/in-bound'" class="nav-link d-block align-items-center">
                    <div class="ms-3">
                        <div class="">
                            <div style="position: relative;">
                                <iconify-icon icon="healthicons:referral-outline" class="fs-3"></iconify-icon>
                                <span class="badge stat-count-collapsed rounded-pill text-bg-danger" *ngIf="count?.inboundCount != 0">{{ count?.inboundCount }}</span
                >
              </div>
            </div>

            <div>
              <span class="badge stat-count rounded-pill text-bg-danger" *ngIf="count?.inboundCount != 0">{{
                count?.inboundCount
                }}</span>
                            </div>
                        </div>
                        <small class="ms-2">Inbound</small>
                </a>
            </li>
            <li class="sidebar-item" *ngIf="shouldShowRoute()" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <a class="nav-link d-block align-items-center btn-toggle" [routerLinkActive]="'active'" [routerLink]="'/out-bound'">
                    <div class="d-flex justify-content-between align-items-center w-100" style="
    position: relative;">
                        <div class="d-flex align-items-center ms-3">
                            <div>
                                <iconify-icon icon="iconoir:mail-out" class="fs-3"></iconify-icon>
                                <span class="badge stat-count-collapsed rounded-pill text-bg-danger" *ngIf="count?.outboundCount != 0">
                  {{ count?.outboundCount || 0 }}</span>
                            </div>
                        </div>
                        <div>
                            <span class="badge stat-count rounded-pill text-bg-danger" *ngIf="count?.outboundCount != 0">{{
                count?.outboundCount || 0
                }}</span>
                        </div>
                    </div>
                    <small class="ms-2">Outbound</small>
                </a>
            </li>
            <div class="collapse" id="collapseExample" *ngIf="!user?.dentalProfessional">
                <div class="card card-body bg-transparent border-0" style="width: 6rem; height: 5.5rem">
                    <ul class="btn-toggle-nav list-unstyled fw-normal">
                        <li class="pb-1 w-100 pointer">
                            <a class="nav-link text-dark d-inline-flex text-decoration-none rounded w-100" [routerLink]="'/out-bound/create-referral'" [ngClass]="{active: this.currentRoute?.startsWith('/out-bound/create-referral') }" style="width: min-content !important">
                                <iconify-icon icon="iconoir:send-mail" class="me-1"></iconify-icon>
                                Refer
                            </a>
                        </li>
                        <li class="pb-2 w-100 pointer">
                            <a class="nav-link text-dark d-inline-flex text-decoration-none rounded w-100" [routerLink]="'/out-bound/drafts'" [ngClass]="{
                  active: this.currentRoute?.startsWith('/out-bound/drafts')
                }" style="width: min-content !important">
                                <iconify-icon icon="fluent:drafts-20-filled" class="me-1"></iconify-icon>
                                Drafts
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- here -->
            <li class="sidebar-item" *ngIf="shouldShowRoute()">
                <a [routerLinkActive]="'active'" [routerLink]="'/invites-list'" class="nav-link d-block align-items-center">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="d-flex align-items-center ms-3">
                            <iconify-icon icon="carbon:connection-receive" class="fs-3"></iconify-icon>
                        </div>
                    </div>
                    <small class="ms-2" style="white-space: nowrap">My Invites</small>
                </a>
            </li>
            <li class="sidebar-item" *ngIf="
          this.userAccount?.otherData?.specialityOffice &&
          !this.user?.dentalProfessional
        ">
                <a [routerLinkActive]="'active'" [routerLink]="'/billing'" class="nav-link d-block align-items-center pointer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center ms-3">
                            <iconify-icon icon="fluent:payment-48-regular" class="fs-3"></iconify-icon>
                        </div>
                    </div>
                    <small style="margin-left: 0.8rem">Billing</small>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['/accounts']" [routerLinkActive]="'active'" class="nav-link d-block align-items-center">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center ms-3">
                            <iconify-icon icon="clarity:settings-line" class="fs-3 mb-1"></iconify-icon>
                        </div>
                    </div>
                    <small class="ms-2">Settings</small>
                </a>
            </li>
        </ul>
        </div>

        <div>
            <ul class="nav nav-pills flex-column mb-auto">
              <li class="sidebar-item d-flex align-items-center justify-content-center">
                <a class="navbar-toggler d-block align-items-center pointer" (click)="installPwa()" style="padding: 1rem; text-decoration: blink;">
                  <iconify-icon icon="ic:round-install-desktop" class="fs-3 mb-1"></iconify-icon>
                  <small class="d-block">Install?</small>
                </a>
              </li>
                <li class="sidebar-item d-flex align-items-center justify-content-center">
                    <a href="#offcanvasNavbar" class="navbar-toggler d-block align-items-center pointer" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" style="padding: 1rem; text-decoration: blink;">
                        <iconify-icon icon="codicon:info" class="fs-3 mb-1"></iconify-icon>
                        <small class="d-block">Help?</small>
                    </a>
                </li>
            </ul>

            <!-- Help sidebar -->

            <app-dashboard-help></app-dashboard-help>

            <hr class="hr" />
            <!-- Help end -->

            <hr class="hr" />

            <div class="dropdown py-3 user-avatar btn-group dropend">
                <div class="d-flex justify-content-between align-items-center pointer text-truncate" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="d-flex align-items-center">
                        <img [src]=getFileURL() alt="" width="40" height="40" class="rounded-circle rounded ms-4" />
                    </div>

                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start dropcard small p-3" style="min-width: 300px">
                        <h6 *ngIf="user?.accounts > 0" class="p-2 mb-2 fw-bold">My Accounts</h6>
                        <li *ngIf="user?.accounts > 0">
                            <h6 class="dropdown-header text-muted">Dental Offices</h6>
                        </li>
                        <li>
                            <a class="dropdown-item py-2" *ngFor="let account of user?.accounts" (click)="selectAccount(account)">
                                <div class="d-flex align-items-center">
                                    <img [src]=getFileURL() alt="" width="50" height="50" class="rounded-circle rounded me-2" />
                                    <div class="user-name-avatar">
                                        <p class="fw-bold mb-0 small">
                                            {{ account.accountName }}
                                        </p>
                                        <p class="mb-0 fw-light small text-muted">
                                            {{ account.accountType | removeUnderscores | uppercase }}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li>
                            <hr class="hr" />
                        </li>
                        <li>
                            <a class="dropdown-item py-2" (click)="logOut()">
                                <iconify-icon icon="simple-line-icons:logout" class="me-1 mb-0 pb-0 text-danger"></iconify-icon>
                                <span class="text-danger pt-0 mt-0"> Logout </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
