<nav class="navbar  fixed-top  navbar-expand-lg">
    <div class="container">
        <a class="navbar-brand" [routerLink]="['/']"><img src="/assets/img/topNavLogo.svg" class="img-fluid" alt="" /></a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a routerLink="/" routerLinkActive="active" class="nav-link" [ngClass]="{ active: route == '/' }">
            Home
          </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#about" [ngClass]="{ active: route == '/about' }">
            About
          </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/pricing" [ngClass]="{ active: route == '/pricing' }">
            Pricing
          </a>
                </li>
            </ul>
            <div class="d-flex justify-content-between">
                <button type="button" class="btn px-5 me-md-2 border-0 btn-outline-primary" (click)="requestLogin()" *ngIf="!user">
          Log in
        </button>

                <button type="button" class="btn px-5 me-md-2 border-0 btn-outline-primary" [routerLink]="['/dashboard']" *ngIf="user">
          Return to dashboard
        </button>

                <button type="button" class="btn px-5 btn-primary" [routerLink]="!this.isAffiliate ? ['/signup']: ['/affiliates/signup']">
          Get Started
                </button>
            </div>
        </div>
    </div>
</nav>

<!-- Navbar  -->