<div class="container">
  <form class="row" [formGroup]="formGroup">
    <div class="d-flex justify-content-between my-3">
      <div class="col-lg-8 col-sm-12">
        <div class="p-2 d-flex justify-content-between w-100">
          <div class="d-flex align-items-center">
            <ng-container  *ngIf="!extranetReferralId">
              <div class="p-2 me-2 notify-card">
                <div class="align-items-center d-flex ">
                  <iconify-icon (click)="goBack()" icon="bi:arrow-left" class="fs-5 pointer"></iconify-icon>
                </div>
              </div>
              <p class="mb-0 pointer" (click)="goBack()">Go back</p>
            </ng-container>
            <ng-container *ngIf="extranetReferralId">

              <button
                class="btn btn-primary me-1"
                (click)="downloadReferralForm(this.singleReferral?.referralRequestId)"
              >
                Download

                <iconify-icon (click)="downloadReferralForm(this.singleReferral?.referralRequestId)" icon="material-symbols:download-rounded" class="pointer"></iconify-icon>
              </button>
            </ng-container>
          </div>
          <div class="d-flex">
            <div class="input-group flex-nowrap">
              <span class="input-group-text" id="addon-wrapping">Status:</span>
              <select class="form-select" formControlName="referralStatus" (change)="clickStatus($event)">
                <option [value]="'PENDING'">Select Status</option>
                <option [value]="'UNREAD'" disabled>
                  {{ 'UNREAD' | titlecase| removeUnderscores }}
                </option>
                <option [value]="referralStatus.New" disabled>
                  {{ referralStatus.New | titlecase| removeUnderscores }}
                </option>
                <option [value]="referralStatus.Called">
                  {{ referralStatus.Called | titlecase| removeUnderscores }}
                </option>
                <option [value]="referralStatus.Scheduled">
                  {{ referralStatus.Scheduled | titlecase| removeUnderscores }}
                </option>
                <option [value]="referralStatus.Rescheduled">
                  {{ 'Rescheduled' | titlecase| removeUnderscores }}
                </option>
                <option [value]="referralStatus.Completed">
                  {{ referralStatus.Completed | titlecase| removeUnderscores }}
                </option>
                <option [value]="referralStatus.NoShow">
                  {{ referralStatus.NoShow | titlecase| removeUnderscores }}
                </option>
                <option [value]="referralStatus.Custom">
                  {{ referralStatus.Custom | titlecase| removeUnderscores }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" id="target">
      <div class="row flex-column flex-column-reverse flex-xl-row">
        <div class="col-12 col-xl-8 my-xl-0 my-4">
          <div class="overview-card p-3">
            <div class="row gx-3">
              <div class="d-flex justify-content-center">
                <div class="col-12 col-lg-6 text-center">
                  <img [src]=loadOfficeImage(this.singleReferral?.dentalOfficeToImageId) class="img-fluid my-3"
                       width="150" height="auto" alt=""/>
                  <p class="fs-4 fw-bold mb-0">
                    {{ this.singleReferral?.dentalOfficeReferralFrom?.name || this.singleReferral?.temporaryDentalOfficePojo?.name | titlecase }}
                  </p>
                  <p class="mb-0 subttle" *ngIf="this.singleReferral?.streetAddress">
                    {{ this.singleReferral?.streetAddress }}
                  </p>
                  <p class="mb-0 subttle" *ngIf="
                      this.singleReferral?.temporaryDentalOfficePojo
                        ?.addressDetails
                    ">
                    {{ this.singleReferral?.temporaryDentalOfficePojo?.addressDetails }}
                  </p>
                  <p *ngIf="
                      this.singleReferral?.dentalOfficeReferralFrom
                        ?.alternatePhoneNumber
                    ">
                    <strong> Phone: </strong>
                    <span class="subttle">{{
                        this.singleReferral?.dentalOfficeReferralFrom
                          ?.alternatePhoneNumber
                      }}</span>
                  </p>
                  <p class="my-1" *ngIf="
                      this.singleReferral?.dentalOfficeReferralFrom?.email ||
                      this.singleReferral?.temporaryDentalOfficePojo?.email
                    ">
                    <strong> Email: </strong>
                    <span class="subttle">{{
                        this.singleReferral?.dentalOfficeReferralFrom?.email ||
                        this.singleReferral?.temporaryDentalOfficePojo?.email
                      }}</span>
                  </p>
                  <p>
                    {{ formatResponse(this.singleReferral?.dateCreated) }}
                  </p>
                </div>
              </div>


              <div class="container px-4" *ngIf="extranetReferralId">
                <div class="row gx-md-3">
                  <div class="col-12 col-sm-6 mb-3">
                    <p class="video-title"> Specialist Office </p>
                    <iframe class=""
                            [cachedSrc]="(specialistOfficeVideo)"
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                            title="Specialist Office"></iframe>

                  </div>
                  <div class="col-12 col-sm-6 mb-3">
                    <p class="video-title"> Dentist Office </p>
                    <iframe class=""
                            [cachedSrc]="(dentistOfficeVideo)"
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                            title="Dentist Office"></iframe>
                  </div>
                </div>
              </div>
              <div class="container px-4">
                <div class="row gx-md-3">
                  <div class="col-12 col-sm-6 mb-3">
                    <label for="patientFirstName" class="rfr-label">
                      First Name
                    </label>
                    <input id="patientFirstName" type="text" class="form-control form-control-lg"
                           formControlName="patientFirstName" placeholder="First Name" aria-label="First Name"/>
                  </div>
                  <div class="col-12 col-sm-6 mb-3">
                    <label for="patientLastName" class="rfr-label">Last Name</label
                    >
                    <input
                      id="patientLastName"
                      type="text"
                      class="form-control form-control-lg"
                      formControlName="patientLastName"
                      placeholder="Last Name"
                      aria-label="Last Name"
                    />
                  </div>

                  <div class="col-12 col-sm-6">
                    <label for="phone" class="rfr-label"> Phone Number </label>
                    <ngx-intl-tel-input id="phone" [cssClass]="'form-control form-control-lg'"
                                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [id]="'phoneNumber'"
                                        [maxLength]="'20'" [phoneValidation]="true"
                                        [preferredCountries]="preferredCountries" [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]" [searchCountryFlag]="true" [selectedCountryISO]="countryISO" [selectFirstCountry]="true"
                                        formControlName="phoneNumber"></ngx-intl-tel-input>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div>
                      <label class="rfr-label">Date of Birth</label>
                      <input type="text" class="form-control form-control-lg" formControlName="dateOfBirth"
                             placeholder="Date of Birth" aria-label="Date of Birth"/>
                    </div>
                    <p class="mb-0 small text-primary my-1 text-end">
                      Patient’s Age: {{ patientAge }}
                    </p>
                  </div>

                  <div class="col-12 col-sm-6 mb-3">
                    <label for="from" class="rfr-label">Refer From</label>
                    <input id="from" type="text" formControlName="referralFrom" class="form-control form-control-lg"
                           placeholder="Refer From" aria-label="Refer From"/>
                  </div>
                  <div class="col-12 col-sm-6 mb-3">
                    <label for="to" class="rfr-label">Refer To</label>
                    <input id="to" type="text" formControlName="referralTo" class="form-control form-control-lg"
                           placeholder="Refer To" aria-label="Refer To"/>
                  </div>

                  <div class="col-12 mb-3">
                    <label class="form-label">Reason for Referral</label>
                    <div *ngIf="referralReasons">
                                            <span *ngFor="let reason of referralReasons; let i = index"
                                                  class="badge m-2 btn rounded-0 fw-light text-bg-secondary">
                        {{ reason.procedureType }}
                      </span>
                    </div>
                  </div>

                  <div class="col-12 col-xl-6 mb-3">
                    <div class="filter-card-bg p-3">
                      <div *ngIf="affectedTeeth" class="d-flex justify-content-center flex-column align-items-center">
                        <app-adult-teeth *ngIf="adultTeethCheck" [affected]="affectedTeeth"></app-adult-teeth>
                        <app-child-teeth *ngIf="!adultTeethCheck" [affected]="affectedTeeth"></app-child-teeth>
                      </div>
                    </div>

                    <div class="row my-4" *ngIf="hasMixedTeethCheck()">
                      <div class="col-6 p-0">
                        <button
                          (click)="adultTeethToggle()"
                          [class]="
                                adultTeethCheck
                                  ? 'btn btn-primary preview-btn'
                                  : 'btn'">
                          Adult
                        </button>
                      </div>
                      <div class="col-6 p-0">
                        <button
                          (click)="adultTeethToggle()"
                          [class]="
                                !adultTeethCheck
                                  ? 'btn btn-primary preview-btn'
                                  : 'btn'">
                          Child
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-xl-6 mb-3">
                    <div class="col-12 col-sm-6 mb-3" *ngIf="this.insuranceCompany">
                      <label for="patientInsurance" class="rfr-label">Patient Insurance</label>
                      <input id="patientInsurance" type="text" formControlName="patientInsurance"
                             class="form-control form-control-lg" placeholder="Patient Insurance"
                             aria-label="Patient Insurance"/>
                    </div>
                    <div>
                      <div class="col-12 mb-3">
                        <label for="note" class="rfr-label">Referral Note</label
                        >
                        <textarea
                          formControlName="referralReasonNotes"
                          class="form-control form-control-lg"
                          id="note"
                          rows="3"
                        ></textarea>
                      </div>
                      <div class="my-4">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            formControlName="preMedRequired"
                            id="inlineCheckbox1"
                          />
                          <label
                            class="form-check-label mx-2 mt-1"
                            for="inlineCheckbox1"
                          >
                            Pre-med required</label
                          >
                        </div>
                      </div>
                      <div>
                        <p class="fs-6 fw-bold mb-1 text-muted">Priority Level:</p>
                        <div
                          class="form-check form-check-inline"
                          *ngFor="let pro of priorityEnum; let i = index"
                        >
                          <div class="">
                            <input
                              type="radio"
                              class="form-check-input"
                              formControlName="priorityLevel"
                              [value]="pro"
                            />
                            <label class="form-check-label mx-2 mt-1">
                              {{ pro | removeUnderscores }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 nav-item mt-3 p-1"
                           *ngIf="this.singleReferral?.specialistPostReferral?.postTreatmentReport">
                        <div class="d-block">
                          <p class="fs-6 fw-bold mb-1 text-muted">Attachment:</p>
                        </div>
                        <p class="me-1 text-muted">Doc/Image: </p>
                        <div class="col-5 text-center">
                          <img style="width: 110px; height: auto" class="card-img p-1 shadow-sm"
                               [src]=getPostReferralDoc(this.singleReferral?.specialistPostReferral?.postTreatmentReport)>
                          <p class="mt-1">
                            {{ this.singleReferral?.specialistPostReferral?.postTreatmentReport?.description || 'Post_doc_1' }}
                            <span
                              (click)="downloadXrayFile(this.singleReferral?.specialistPostReferral?.postTreatmentReport?.id)"
                              class="pointer">
                                                    <iconify-icon icon="material-symbols:download-rounded"
                                                                  style="color: #7c7c7e;" width="24"></iconify-icon>
                                                    </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="row" *ngIf="singleReferral?.xrayPojo">
                        <div class="col-5 text-center" *ngFor="let file of singleReferral.xrayPojo; let i = index">
                          <img style="width: 100%; height: auto;" class="card-img p-1 shadow-sm"
                               [src]=getFileURL(file)>
                          <p class="w-100 text-right pl-5">
                            {{ "file-" + (i + 1) }}
                            <span (click)="downloadXrayFile(file.bwFileId)" class="pointer"
                                  style="display: inline-block; vertical-align: middle;">
                            <iconify-icon icon="material-symbols:download-rounded" style="color: #7c7c7e;"
                                          width="24"></iconify-icon>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <p class="fs-6 fw-bold bg-light p-2 text-muted">Post Referral Information</p>
                    <div class="row g-3">
                      <div class="col-md">
                        <ul class="nav ">
                          <!--                          <li class="mb-3 nav-item p-1" *ngIf="this.singleReferral?.specialistPostReferral">Estimated-->
                          <!--                            Insurance Portion(for Analytics): <strong-->
                          <!--                              class="fs-8">{{(this.singleReferral?.specialistPostReferral?.estimatedInsurancePortion | currency) || '$0.0'}}</strong>-->
                          <!--                          </li>-->
                          <!--                          <li class="mb-3 nav-item p-1" *ngIf="isInbound">Estimated Patient Portion(for Analytics):-->
                          <!--                            <strong-->
                          <!--                              class="fs-8">{{(this.singleReferral?.specialistPostReferral?.estimatedPatientPortion | currency) || '$0.0'}}</strong>-->
                          <!--                          </li>-->
                          <div class="mt-2">
                            <ul class="nav">
                              <li class="mb-3 nav-item p-1 fw-bold">Insurance Snapshot</li>
                            </ul>
                            <button class="btn btn-primary px-3"
                                    *ngIf="this.singleReferral?.firstContactPojo?.backImageId"
                                    (click)="showModalInfo(this.singleReferral?.firstContactPojo)">Click to see
                              insurance snapshot
                            </button>
                            <div class="nav mt-2 btn-background p-2 rounded-5"
                                 *ngIf="!this.singleReferral?.firstContactPojo?.backImageId">
                              {{ 'No Insurance Snapshot' }}
                            </div>
                          </div>
                        </ul>
                        <p class="nav-item rfr-label" *ngIf="this.notesCompleted">Note: <textarea
                          formControlName="postReferral" class="form-control" cols="20" rows=""></textarea></p>
                      </div>
                      <div class="col-md">
                        <div class="mt-2">
                          <ul class="nav">
                            <li class="mb-3 nav-item p-1 fw-bold">Appointment Preference</li>
                          </ul>
                          <button class="btn btn-primary px-3"
                                  *ngIf="this.singleReferral?.firstContactPojo?.timeSlotDefinitionList?.length != 0 && this.singleReferral?.firstContactPojo?.firstContactTimeRange"
                                  (click)="showAppointmentModal(this.singleReferral?.firstContactPojo)">Click to see
                            appointment preference
                          </button>
                          <div class="nav mt-2 btn-background p-2 rounded-5"
                               *ngIf="!this.singleReferral?.firstContactPojo && this.singleReferral?.firstContactPojo?.timeSlotDefinitionList?.length < 1">
                            {{ 'No Appointment Preference' }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md">
                        <ul class="nav mt-2">
                          <li class="mb-3 nav-item p-1 fw-bold">Questions/Comments</li>
                        </ul>
                        <div class="nav mt-2 btn-background p-2 rounded-5">
                          {{ this.singleReferral?.firstContactPojo?.question ? this.singleReferral?.firstContactPojo?.question : 'No Question' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isInbound" class="col-12 col-xl-4 ps-md-3 mb-4 mb-md-0">
          <div class="overview-card p-3" *ngIf="this.referralStatusNotesList?.length > 0">
            <div class="container-fluid">
              <div class="row gx-3">
                <div class="col-6" *ngFor="let statusTime of this.referralStatusNotesList">
                  <div
                    *ngIf="this.statusTime?.referralRequest?.appointmentDate && statusTime.status == statusNoteEnum.Scheduled">
                    <p class="mb-0 fw-bold">
                      {{ "Appointment Date" | uppercase }}
                    </p>
                    <p class="subttle small">
                      <a class="subttle small"
                         *ngIf="this.statusTime?.referralRequest?.appointmentDate">{{ this.statusTime?.referralRequest?.appointmentDate | datetime }}</a>
                    </p>
                  </div>
                  <p class="mb-0 fw-bold" *ngIf="statusTime.status == statusNoteEnum.Pending">
                    {{ "read" | uppercase }}
                  </p>
                  <p class="mb-0 fw-bold" *ngIf="statusTime.status == statusNoteEnum.Custom">
                    {{ "Rescheduled" | uppercase }}
                  </p>
                  <p class="mb-0 fw-bold"
                     *ngIf="statusTime.status != statusNoteEnum.Pending && statusTime.status != statusNoteEnum.Custom">
                    {{ statusTime.status | uppercase }}
                  </p>
                  <p class="subttle small">
                    {{ statusTime.updatedAt | dateAsAgo }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="position-fixed mt-5 d-none d-xl-block" style="position: relative !important;">
            <div class="d-flex flex-xl-column justify-content-center align-items-center">
              <div
                class="rounded-circle border-outline-wf bg-primary text-white p-2 d-flex align-items-center justify-content-center"
                [ngClass]="{
                  'bg-primary rounded-circle text-white':
                    checkCurrentStatus(statusNoteEnum.Pending) ||
                    checkCurrentStatus(statusNoteEnum.Scheduled) ||
                    checkCurrentStatus(statusNoteEnum.Completed)
                }">
                <iconify-icon icon="fluent:mail-read-48-regular" class="fs-4"></iconify-icon>
              </div>
              <div class="done-activity my-2" *ngIf="
                  checkCurrentStatus(statusNoteEnum.Pending) ||
                  checkCurrentStatus(statusNoteEnum.Scheduled) ||
                  checkCurrentStatus(statusNoteEnum.Completed)
                "></div>
              <a></a>
              <div class="pending-wf-activity my-2" *ngIf="!checkCurrentStatus(statusNoteEnum.Pending)"></div>
              <div
                class="rounded-circle border-outline-wf text-primary p-2 d-flex align-items-center justify-content-center"
                [ngClass]="{
                  'bg-primary rounded-circle text-white':
                    checkCurrentStatus(statusNoteEnum.Called) ||
                    checkCurrentStatus(statusNoteEnum.Scheduled) ||
                    checkCurrentStatus(statusNoteEnum.Completed)
                }">
                <iconify-icon icon="codicon:call-outgoing" class="fs-4"></iconify-icon>
              </div>
              <div class="done-activity my-2" *ngIf="
                  checkCurrentStatus(statusNoteEnum.Called) ||
                  checkCurrentStatus(statusNoteEnum.Scheduled) ||
                  checkCurrentStatus(statusNoteEnum.Completed)
                "></div>
              <a></a>
              <div class="pending-wf-activity my-2" *ngIf="!checkCurrentStatus(statusNoteEnum.Called)"></div>
              <div
                class="status-workflow-inactive border-outline-wf p-2 d-flex align-items-center justify-content-center"
                [ngClass]="{
                  'bg-primary rounded-circle text-white':
                    checkCurrentStatus(statusNoteEnum.Scheduled) ||
                    checkCurrentStatus(statusNoteEnum.Completed)
                }">
                <iconify-icon icon="carbon:event-schedule" class="fs-4"></iconify-icon>
              </div>
              <div class="done-activity my-2" *ngIf="checkCurrentStatus(statusNoteEnum.Completed)"></div>
              <a></a>
              <div class="pending-wf-activity my-2" *ngIf="!checkCurrentStatus(statusNoteEnum.Completed)"></div>
              <div
                class="status-workflow-inactive border-outline-wf p-2 d-flex align-items-center justify-content-center"
                [ngClass]="{
                  'bg-primary rounded-circle text-white': checkCurrentStatus(
                    statusNoteEnum.Completed
                  )
                }">
                <iconify-icon icon="carbon:task-complete" class="fs-4"></iconify-icon>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
