/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PatientPojo { 
    displayName?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    gender?: PatientPojo.GenderEnum;
    dateOfBirth?: string;
    status?: PatientPojo.StatusEnum;
    id?: number;
    participationStatus?: PatientPojo.ParticipationStatusEnum;
    insuranceCompany?: string;
}
export namespace PatientPojo {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type ParticipationStatusEnum = 'ACCEPTED' | 'DECLINED' | 'TENTATIVE' | 'NEEDS_ACTION';
    export const ParticipationStatusEnum = {
        Accepted: 'ACCEPTED' as ParticipationStatusEnum,
        Declined: 'DECLINED' as ParticipationStatusEnum,
        Tentative: 'TENTATIVE' as ParticipationStatusEnum,
        NeedsAction: 'NEEDS_ACTION' as ParticipationStatusEnum
    };
}


