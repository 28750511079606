/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvitationDTO } from './invitation-dto';


export interface InvitationListDTO { 
    invitations: Array<InvitationDTO>;
    channels?: Array<InvitationListDTO.ChannelsEnum>;
}
export namespace InvitationListDTO {
    export type ChannelsEnum = 'SMS' | 'EMAIL' | 'PHONE_CALL';
    export const ChannelsEnum = {
        Sms: 'SMS' as ChannelsEnum,
        Email: 'EMAIL' as ChannelsEnum,
        PhoneCall: 'PHONE_CALL' as ChannelsEnum
    };
}


