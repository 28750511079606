/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InvitationResponseDto { 
    responseConstant: InvitationResponseDto.ResponseConstantEnum;
    inviteId: number;
}
export namespace InvitationResponseDto {
    export type ResponseConstantEnum = 'ACCEPTED' | 'REJECTED';
    export const ResponseConstantEnum = {
        Accepted: 'ACCEPTED' as ResponseConstantEnum,
        Rejected: 'REJECTED' as ResponseConstantEnum
    };
}


