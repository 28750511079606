<div class="card-header m-0 mb-3  border-0 p-4">
    <span class="float-start pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="3.4em" height="2em" viewBox="0 0 512 214">
                    <path fill="#02183D"
                          d="M512 110.08c0-36.409-17.636-65.138-51.342-65.138c-33.85 0-54.33 28.73-54.33 64.854c0 42.808 24.179 64.426 58.88 64.426c16.925 0 29.725-3.84 39.396-9.244v-28.445c-9.67 4.836-20.764 7.823-34.844 7.823c-13.796 0-26.027-4.836-27.591-21.618h69.547c0-1.85.284-9.245.284-12.658Zm-70.258-13.511c0-16.071 9.814-22.756 18.774-22.756c8.675 0 17.92 6.685 17.92 22.756h-36.694Zm-90.31-51.627c-13.939 0-22.899 6.542-27.876 11.094l-1.85-8.818h-31.288v165.83l35.555-7.537l.143-40.249c5.12 3.698 12.657 8.96 25.173 8.96c25.458 0 48.64-20.48 48.64-65.564c-.142-41.245-23.609-63.716-48.498-63.716Zm-8.534 97.991c-8.391 0-13.37-2.986-16.782-6.684l-.143-52.765c3.698-4.124 8.818-6.968 16.925-6.968c12.942 0 21.902 14.506 21.902 33.137c0 19.058-8.818 33.28-21.902 33.28ZM241.493 36.551l35.698-7.68V0l-35.698 7.538V36.55Zm0 10.809h35.698v124.444h-35.698V47.36Zm-38.257 10.524L200.96 47.36h-30.72v124.444h35.556V87.467c8.39-10.951 22.613-8.96 27.022-7.396V47.36c-4.551-1.707-21.191-4.836-29.582 10.524Zm-71.112-41.386l-34.702 7.395l-.142 113.92c0 21.05 15.787 36.551 36.836 36.551c11.662 0 20.195-2.133 24.888-4.693V140.8c-4.55 1.849-27.022 8.391-27.022-12.658V77.653h27.022V47.36h-27.022l.142-30.862ZM35.982 83.484c0-5.546 4.551-7.68 12.09-7.68c10.808 0 24.461 3.272 35.27 9.103V51.484c-11.804-4.693-23.466-6.542-35.27-6.542C19.2 44.942 0 60.018 0 85.192c0 39.252 54.044 32.995 54.044 49.92c0 6.541-5.688 8.675-13.653 8.675c-11.804 0-26.88-4.836-38.827-11.378v33.849c13.227 5.689 26.596 8.106 38.827 8.106c29.582 0 49.92-14.648 49.92-40.106c-.142-42.382-54.329-34.845-54.329-50.774Z"/>
                </svg>
            </span>
    <!-- <span class="h4">Confirmation</span> -->
    <span class="float-end pointer" (click)="dismissButtonClicked.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path fill="currentColor"
              d="m12 13.4l-4.9 4.9q-.275.275-.7.275q-.425 0-.7-.275q-.275-.275-.275-.7q0-.425.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7L13.4 12l4.9 4.9q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275Z"/>
      </svg>
    </span>
</div>
<div class="card-body px-3">
    <p class="h5 mt-1 text-start heading-text">DentalDoor works with Stripe to link your accounts.</p>
    <div class="content">
        <div class="row mb-3">
            <div class="col">
                <span class="float-start me-2 d-block">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                 viewBox="0 0 24 24">
              <path fill="#8d98a7"
                    d="M20 2c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2h-1v1h-4v-1H9v1H5v-1H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h16m-3 10c0-1-.3-2-.8-2.8l1.5-1.5l-1.4-1.4l-1.5 1.5C14 7.3 13 7 12 7c-1 0-2 .3-2.8.8L7.8 6.3L6.3 7.8l1.5 1.5C7.3 10 7 11 7 12c0 1 .3 2 .8 2.8l-1.5 1.5l1.5 1.4l1.5-1.5c.7.5 1.7.8 2.7.8c1 0 2-.3 2.8-.8l1.5 1.5l1.4-1.4l-1.5-1.5c.5-.8.8-1.8.8-2.8m-5-3c1.7 0 3 1.3 3 3s-1.3 3-3 3s-3-1.3-3-3s1.3-3 3-3m0 5c1.1 0 2-.9 2-2s-.9-2-2-2s-2 .9-2 2s.9 2 2 2Z"/>
            </svg>
          </span>
                <div class="col">
                    <span class="small text-start d-block">Stripe will allow DentalDoor to access only the data
              requested. We never share your login details.
            </span>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <span class="float-start me-2 d-block">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                 viewBox="0 0 24 24">
              <path fill="#8d98a7"
                    d="M6 22q-.825 0-1.412-.587Q4 20.825 4 20V10q0-.825.588-1.413Q5.175 8 6 8h1V6q0-2.075 1.463-3.538Q9.925 1 12 1t3.538 1.462Q17 3.925 17 6v2h1q.825 0 1.413.587Q20 9.175 20 10v10q0 .825-.587 1.413Q18.825 22 18 22Zm6-5q.825 0 1.413-.587Q14 15.825 14 15q0-.825-.587-1.413Q12.825 13 12 13q-.825 0-1.412.587Q10 14.175 10 15q0 .825.588 1.413Q11.175 17 12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6Z"/>
            </svg>
          </span>
                <div class="col">
                    <span class="small text-start d-block pt-1">Your data is encrypted for your protection.</span>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <span class="float-start me-2 d-block">
             <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24">
               <path fill="#8d98a7"
                     d="m10 17l-4-4l1.41-1.41L10 14.17l6.59-6.59L18 9m-6-8L3 5v6c0 5.55 3.84 10.74 9 12c5.16-1.26 9-6.45 9-12V5l-9-4Z"/>
             </svg>
          </span>
                <div class="col">
                    <span class="small text-start d-block pt-1">You can disconnect your accounts at anytime.</span>
                </div>
            </div>
        </div>
    </div>
    <p class="card-text">You agree to Stripe’s <a class="text-primary pointer" href="https://stripe.com/gb/legal/connect-account" target="_blank" style="text-decoration: none">Terms</a> and <a href="https://stripe.com/gb/privacy" target="_blank" style="text-decoration: none" class="pointer text-primary" (click)="goToPolicyPage()"> Privacy Policy</a>.
    </p>
    <br>
    <a awNextStep class="btn btn-md btn-dark button py-2">Agree</a>
</div>
<br>
