import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasValue'
})
export class HasValuePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value !== null && value !== undefined;
  }

}
