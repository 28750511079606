<div class="container main">
  <div class="" style="margin: 3%;">
    <img src="assets/img/loginLogo.svg" class="image-responsive" alt="">
  </div>
  <div class="d-flex justify-content-center">
    <div class="col-md-6">
      <div class="alert alert-danger text-center small" *ngIf="showErrorMessageTrigger">{{getErrorMessage()}}</div>
      <div class="alert alert-success text-center small" *ngIf="showSuccessMessageTrigger">{{getSuccessMessage()}}</div>
    </div>
  </div>
  <div class="shadow-sm text-center p-md-5 p-3" *ngIf="!toDashboard">
    <h4 class="text-uppercase m-3 mb-4">Enter otp</h4>
    <div class=""><img src="./assets/img/otp.png" alt="" style="max-width: 30%;"> </div>
    <div class="card-body pt-0 justify-content-center">
      <h6 class="mt-4 mb-1">Enter the OTP sent to <p class="text-primary text-truncate">{{otpEmail}} or {{otpPhone}}</p></h6>
      <ng-otp-input #otpInput
        class="p-3 d-flex align-items-center justify-content-center row"
        (onInputChange)="onOtpChange($event)" [config]="{length: 6, allowNumbersOnly: true, isPasswordInput: false}">
      </ng-otp-input>
      <h6>Didn't receive OTP? <a *ngIf="!intervalStart" (click)="getOtp()" class="link-primary pointer">Resend OTP</a>
      </h6>
      <div>
        <p class="m-0 small text-secondary fw-normal">
          If you didn't get it. Click "Resend OTP" <span *ngIf="otpCountDown>0 && intervalStart">in
            <strong>{{otpCountDown}}</strong> seconds</span>
          to resend.
        </p>
      </div>
      <div class="">
        <button class="btn btn-primary mt-4 btn-md px-5" type="submit" (click)="validateOTP()"
          [disabled]="verifyingOtp">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.verifyingOtp">
  <app-full-page-loader></app-full-page-loader>
</div>

