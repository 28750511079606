/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PromotionType { 
    name?: string;
    type?: PromotionType.TypeEnum;
    status?: PromotionType.StatusEnum;
    dateCreated?: string;
    code?: string;
    id?: number;
}
export namespace PromotionType {
    export type TypeEnum = 'COUPON' | 'DEAL' | 'FREE_OFFER' | 'DENTAL_PLAN';
    export const TypeEnum = {
        Coupon: 'COUPON' as TypeEnum,
        Deal: 'DEAL' as TypeEnum,
        FreeOffer: 'FREE_OFFER' as TypeEnum,
        DentalPlan: 'DENTAL_PLAN' as TypeEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


