/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InsuranceDto { 
    insuranceType: InsuranceDto.InsuranceTypeEnum;
    membershipNumber: string;
    insuranceCompanyCode: string;
    frontImageId?: number;
    backImageId?: number;
}
export namespace InsuranceDto {
    export type InsuranceTypeEnum = 'HMO' | 'PPO' | 'INDEMNITY' | 'DISCOUNT';
    export const InsuranceTypeEnum = {
        Hmo: 'HMO' as InsuranceTypeEnum,
        Ppo: 'PPO' as InsuranceTypeEnum,
        Indemnity: 'INDEMNITY' as InsuranceTypeEnum,
        Discount: 'DISCOUNT' as InsuranceTypeEnum
    };
}


