<div class="modal-content" id="cardDetailsModal">
  <div class="d-flex justify-content-end">
    <button (click)="close()" [disabled]="working" aria-label="Close" class="close btn mtn-btn">
      <span aria-hidden="true">X</span>
    </button>
  </div>

  <div class="modal-body">
    <section id="error-message" *ngIf="showErrorMessageTrigger">
      <div *ngIf="isError">
        <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
             role="alert">
          <p class="m-0 p-0 d-flex align-items-start">
        <span>
          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
          {{ getErrorMessage() | removeUnderscores }}
        </span>

            <iconify-icon
              class="iconify fs-4 ms-auto pointer"
              icon="mdi:close"
              data-bs-dismiss="alert"
            >
            </iconify-icon>
          </p>
        </div>
      </div>
      <div *ngIf="!isError">
        <div class="alert-success alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
             role="alert">
          <p class="m-0 p-0 d-flex align-items-start">
        <span>
          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
          {{ getErrorMessage() | removeUnderscores }}
        </span>

            <iconify-icon
              class="iconify fs-4 ms-auto pointer"
              icon="mdi:close"
              data-bs-dismiss="alert"
            >
            </iconify-icon>
          </p>
        </div>
      </div>
    </section>

    <div class="text-center">
      <div class="logo"><img class="img-fluid" src="/assets/img/dashboard-logo.svg" alt=""></div>
      <form class="rounded-0 p-3 border-0 needs-validation"  [formGroup]="form">
        <h5 class="mb-3 text-secondary">Update Card Information</h5>
        <div class="row gy-3">
          <div class="col-md-6">
            <div>
              <input type="text" class="form-control form-control-lg" id="card-number" formControlName="cardNumber"
                     placeholder="1234 1234 1234 1234" [mask]="'0000 0000 0000 0000'" aria-label="card-number" aria-describedby="card-number">
            </div>
            <div class="d-flex p-2">
              <img src="assets/img/master-card.png" class="" alt="" style="max-width: 50px; max-height:30px;">
              <img src="assets/img/Visa.svg" alt="" style="max-width: 50px; max-height:30px;">
              <img src="assets/img/american-express.png" alt="" style="max-width: 50px; max-height:30px;">
            </div>
            <small
              *ngIf="form.controls.cardNumber.touched && form.controls.cardNumber.hasError('invalidCard')"
              class="text-danger">{{'Invalid Card'}}</small>
          </div>

          <div class="col-md-6">
            <input type="text" class="form-control form-control-lg"
                   [mask]="'00/00'" placeholder="MM/YY" formControlName="expiryDate"
                   id="cc-expiration">
            <small
              *ngIf="form.controls.expiryDate.touched && form.controls.expiryDate.hasError('invalidExpiryDate')"
              class="text-danger">{{'Invalid Expiry Date'}}</small><br/>
          </div>

          <div class="col-md-6">
            <input type="text" formControlName="cvc" [mask]="'0000'" [maxlength]="'4'" class="form-control form-control-lg" id="cc-cvv" placeholder="CVV">

            <small *ngIf="form.controls.cvc.touched && form.controls.cvc.hasError('invalidCvc')"
                   class="text-danger">{{'Invalid cvc'}}</small>
          </div>
          <div class="col-md-6">
            <input type="text" [maxlength]="'20'"
                   formControlName="postalCode" class="form-control form-control-lg"
                   id="cc-zipCode" placeholder="Zip Code (optional)">
          </div>
        </div>

        <button [disabled]="working" (click)="updateCard();false;" class="btn-primary btn mt-3 w-100">
          Update Card
          <i *ngIf="working" class="fa icon-spinner animate-spin"></i>
        </button>
      </form>
    </div>
  </div>
</div>
