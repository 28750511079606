

import { AbstractControl, ValidationErrors } from '@angular/forms';

export class URLValidator {

  static mustBeValid(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;

    if (!control.value) {
      return null;
    }
    if (!(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(value))) { return { invalidURL: true }; }

    return null;
  }
}
