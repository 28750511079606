import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noWhiteSpace'
})
export class NoWhiteSpacePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return value;
    }
    return value.replace(/ /g, '');
  }

}
