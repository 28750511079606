/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReviewLinkDto { 
    link: string;
    recordSource?: ReviewLinkDto.RecordSourceEnum;
    dentalOfficeId: number;
}
export namespace ReviewLinkDto {
    export type RecordSourceEnum = 'DENTAL_DOOR' | 'GOOGLE' | 'FACEBOOK' | 'YELP' | 'BING' | 'YAHOO';
    export const RecordSourceEnum = {
        DentalDoor: 'DENTAL_DOOR' as RecordSourceEnum,
        Google: 'GOOGLE' as RecordSourceEnum,
        Facebook: 'FACEBOOK' as RecordSourceEnum,
        Yelp: 'YELP' as RecordSourceEnum,
        Bing: 'BING' as RecordSourceEnum,
        Yahoo: 'YAHOO' as RecordSourceEnum
    };
}


