import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {DentalOfficeCreationControllerService, DentalOfficePojo} from "dd-core-api-sdk";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../services/UserKeycloak";

@Component({
  selector: 'app-payment-success-dialog',
  templateUrl: './payment-success-dialog.component.html',
  styleUrls: ['./payment-success-dialog.component.css']
})
export class PaymentSuccessDialogComponent implements OnInit {
  @Output()
  onSubmitButtonClick = new EventEmitter();

  @Input() title;
  @Input() subTitle;
  @Input() buttonText;
  @Input() buttonRoute;
  @Input() success;
  @Input() dentalOffice: DentalOfficePojo;
  @Input() setup = false;
  userKeycloak: UserKeycloak;

  constructor(public modalRef: BsModalRef,
              private router: Router,
              private authenticationServiceKeycloak: AuthenticationServiceKeycloak) {
  }

  ngOnInit(): void {
    this.authenticationServiceKeycloak.getUser().subscribe(res =>{
      this.userKeycloak = res;
    });
  }

  buttonClicked() {
    if (this.success) {
      this.completeSetUp();
    } else {
      this.errorResponse();
    }
  }

  completeSetUp() {
    this.createAnchorTag();
    window.scroll(0, 0);
    this.modalRef.hide();
  }

  createAnchorTag() {
    const a = document.createElement('a');
    a.href = "/invites-list/create";
    document.body.appendChild(a);
    a.click();
  }

  errorResponse() {
    window.scroll(0, 0);
    this.modalRef.hide();
  }

}
