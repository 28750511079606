import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {CustomSubscriptionRequestControllerService} from "dd-core-api-sdk";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CountryISO, SearchCountryField, PhoneNumberFormat} from "ngx-intl-tel-input";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../services/UserKeycloak";
import {UserAccountKeycloak} from "../../services/UserAccountKeycloak";
import * as moment from "moment";

@Component({
  selector: 'app-custom-pricing',
  templateUrl: './custom-pricing.component.html',
  styleUrls: ['./custom-pricing.component.css']
})
export class CustomPricingComponent implements OnInit {
  formGroup: FormGroup;
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.UnitedStates;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: string[] = ['us'];
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  errorSubmission: boolean;
  user: UserKeycloak;
  account: UserAccountKeycloak;
  loading: boolean;

  constructor(private modalRef: BsModalRef,
              private fb: FormBuilder,
              private authenticationServiceKeycloak: AuthenticationServiceKeycloak,
              private customSubscriptionRequestController: CustomSubscriptionRequestControllerService) {
    this.authenticationServiceKeycloak.getUser().subscribe(res => {
      this.user = res;
    })
    this.authenticationServiceKeycloak.getAccount().subscribe(res => {
      this.account = res;
    })
  }

  ngOnInit(): void {

    this.formGroup = this.fb.group({
      name: [this.account?.accountName || '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      email: [this.user?.email || '', [Validators.pattern("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$"), Validators.required, Validators.maxLength(40)]],
      note: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(500)]],
      phoneNumber: [this.user?.mobileNumber || ''],
      appointmentDate: ['', [Validators.required]]
    });
  }

  close() {
    window.scroll(0, 0);
    this.modalRef.hide();
  }

  scheduleCall() {
    const formValue = this.formGroup.getRawValue();
    this.loading = true;
    this.customSubscriptionRequestController.createCustomSubscriptionRequest({
      customSubscriptionRequestDto: {
        officeName: formValue.name, email: formValue.email,
        note: formValue.note, phoneNumber: formValue.phoneNumber.e164Number, dentalOfficeId:
        this.account?.otherData?.id, appointmentDate: this.formatDateTime(formValue?.appointmentDate)
      }
    }).subscribe(res => {
      this.loading = false;
      this.isError = false;
      this.showErrorMessage('Saved successfully. You will be contacted by the sales department.');
    }, error => {
      this.loading = false;
      this.isError = true;
      this.showErrorMessage('Some fields are compulsory, please ensure they are filled.')
    })
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 5000);
  }

  formatDateTime(dateTime: string) {
    if (!dateTime) {
      return null;
    }
    return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
  }

  getErrorMessage() {
    return this.errMessage;
  }
}
