<div class="modal-content">
  <div class="modal-header rounded-0 bg-primary">
    <h1 class="modal-title fs-5 text-light" id="messageModalLabel"> Insurance Snapshot
      <iconify-icon icon="material-symbols:credit-card-outline" style="color: #fff;" width="25" height="25">
      </iconify-icon>
    </h1>
    <button type="button" class="btn-close" (click)="dismissModal()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div>
      <div>
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-front-tab" data-bs-toggle="pill" data-bs-target="#pills-front" type="button" role="tab" aria-controls="pills-front" aria-selected="true">Front View</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-back-tab" data-bs-toggle="pill" data-bs-target="#pills-back" type="button" role="tab" aria-controls="pills-back" aria-selected="false">Back View</button>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="pills-tabContent">

        <div class="tab-pane fade show active" id="pills-front" role="tabpanel" aria-labelledby="pills-front-tab" tabindex="0">
          <div class="card">
            <div class="card-body rounded-2 m-auto p-2">
              <img class="img-fluid" [src]=getFileURL(this.firstContactPojo?.frontImageId)
                   alt="patient insurance card front view">
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-back" role="tabpanel" aria-labelledby="pills-back-tab" tabindex="0">
          <div class="card">
            <div class="card-body rounded-2 m-auto p-2">
              <img class="img-fluid" [src]=getFileURL(this.firstContactPojo?.backImageId)
                   alt="patient insurance card back view">
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismissModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="downloadImages()">
      Download
    </button>
  </div>
</div>
