import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TimeSlotDefinition, TimeSlotDefinitionControllerService } from 'dd-core-api-sdk';

@Component({
  selector: 'app-last-contact',
  templateUrl: './last-contact.component.html',
  styleUrls: ['./last-contact.component.css']
})
export class LastContactComponent implements OnInit {
 formGroup: FormGroup;
  timeSlotPojo: Array<TimeSlotDefinition>;

  @Output()
  emitFirstStepValues = new EventEmitter<any>();
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';

  constructor(private fb: FormBuilder, private timeSlotDefinitionControllerService: TimeSlotDefinitionControllerService) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      morning: [''],
      afternoon: [''],
      evening: ['']
    });

    this.timeSlotDefinitionControllerService.getAllContactTimeRange().subscribe(res => {
      this.timeSlotPojo = res;
    })
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }
  getErrorMessage() {
    return this.errMessage;
  }
}
