import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {LoggedInGuard} from "../../shared/logged-in-guard/logged-in.guard";
import {AdminDashboardComponent} from "./admin-dashboard/admin-dashboard.component";
import {AdminReferralsComponent} from "./admin-referrals/admin-referrals.component";
import {ListSubscriptionRequestComponent} from "./list-subscription-request/list-subscription-request.component";
import { HelpListComponent } from "./help-list/help-list.component";
import {ActivityLogComponent} from "./activity-log/activity-log.component";
import {TempDentalOfficeComponent} from "./temp-dental-office/temp-dental-office.component";

const routes: Routes = [
  {
    path: 'overview',
    component: AdminDashboardComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'referrals',
    component: AdminReferralsComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'temp-dental-offices',
    component: TempDentalOfficeComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'custom-subscriptions',
    component: ListSubscriptionRequestComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'activity',
    component: ActivityLogComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'help',
    component: HelpListComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
