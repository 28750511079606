/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferralRequest } from './referral-request';


export interface ReferralStatusNotes { 
    status?: ReferralStatusNotes.StatusEnum;
    note?: string;
    updatedAt?: string;
    id?: number;
    referralRequest?: ReferralRequest;
}
export namespace ReferralStatusNotes {
    export type StatusEnum = 'PENDING' | 'SCHEDULED' | 'COMPLETED' | 'CUSTOM' | 'CANCELLED' | 'REJECTED' | 'NEW' | 'CALLED' | 'RESCHEDULED' | 'NO_SHOW';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Scheduled: 'SCHEDULED' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum,
        Custom: 'CUSTOM' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum,
        Rejected: 'REJECTED' as StatusEnum,
        New: 'NEW' as StatusEnum,
        Called: 'CALLED' as StatusEnum,
        Rescheduled: 'RESCHEDULED' as StatusEnum,
        NoShow: 'NO_SHOW' as StatusEnum
    };
}


