<section class="vh-100" style="background-color: #fafcff;">

    <div class="row justify-content-center align-items-center vh-100">

        <div class="col-md-10 col-lg-6 col-xl-7 text-center d-none d-lg-block vh-100 left">
            <div class="carousel">
                <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="2500">
                            <img src="../../../../../assets/img/lady-laptop.svg" class="carousel-image" alt="..." style="width: 445px !important;">
                            <div class="carousel-caption">
                                <h5 class="carousel-text">Your affiliate referrals in one place</h5>
                                <p>Manage and track all your referrals statuses and dates.</p>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="2500">
                            <img src="../../../../../assets/img/atm.svg" class="carousel-image" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                                <h5 class="carousel-text">Fast, secure and easy payout</h5>
                                <p>Get paid on every referral without hassles.</p>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="2500">
                            <img src="../../../../../assets/img/30percent.svg" class="carousel-image" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                                <h5 class="carousel-text">Earn up to 30% commission</h5>
                                <p>Your referrals subscribe with your link, you earn commission. It's that simple.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-10 col-lg-6 col-xl-5 vh-100 d-flex align-items-center justify-content-center right px-md-5 px-3">
            <div class="custom-card p-md-4">
                <div class="container">
                    <div class="text-center mb-2 mt-5">
                        <img src="../../../../../assets/img/ddlogo.png" alt="" class="pointer img-fluid" routerLink="/affiliates">
                    </div>
                    <aw-wizard [navBarLayout]="null" [defaultStepIndex]="0">
                        <aw-wizard-step>
                            <app-affiliate-signup (nextButtonClicked)="getFormOneData($event)"></app-affiliate-signup>
                        </aw-wizard-step>
                        <aw-wizard-step>
                            <app-affiliate-second-signup (nextButtonClicked)="getFormTwoData($event)" [signUpSubmittingStatus]="eventSubmissionTracking" [affiliateCode]="affiliateCode"></app-affiliate-second-signup>
                        </aw-wizard-step>
                        <aw-wizard-step (stepEnter)="onOtpStepInit($event)">
                            <app-affiliate-otp [otpEmail]="this.otpEmail" [otpPhone]="otpPhone"></app-affiliate-otp>
                        </aw-wizard-step>
                    </aw-wizard>
                </div>
            </div>
        </div>
    </div>
</section>
