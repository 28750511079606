import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {CreateReferralComponent} from "./create-referral/create-referral.component";
import {OutboundRoutingModule} from "./outbound-routing.module";
import { OutboundListComponent } from './outbound-list/outbound-list.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {SharedModule} from "../../../shared/shared.module";
import {TagInputModule} from "ngx-chips";
import { CreateReferralWizardComponent } from './create-referral-wizard/create-referral-wizard.component';
import { PreviewReferralComponent } from './preview-referral/preview-referral.component';
import {ArchwizardModule} from "angular-archwizard";
import { AddReferralProfModalComponent } from './add-referral-prof-modal/add-referral-prof-modal.component';
import { OptionSelectResponseComponent } from './tooth-selection-warning/option-select-response.component';
import {JoyrideModule} from "ngx-joyride";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";

@NgModule({
  declarations: [
    CreateReferralComponent,
    OutboundListComponent,
    CreateReferralWizardComponent,
    PreviewReferralComponent,
    AddReferralProfModalComponent,
    OptionSelectResponseComponent
  ],
  imports: [
    ReactiveFormsModule,
    OutboundRoutingModule,
    CommonModule,
    SharedModule,
    NgxIntlTelInputModule,
    NgbDatepickerModule,
    TagInputModule,
    ArchwizardModule,
    JoyrideModule,
    BsDatepickerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OutboundModule {}
