import {Component, ViewChild} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";
import {FirstContactVideoComponent} from "../first-contact-video/first-contact-video.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FirstContactTokenControllerService, ReferralSearchPojo} from "dd-core-api-sdk";
import {WizardComponent} from "angular-archwizard";
import {SocialService} from "../../../shared/subject/SocialService";

@Component({
  selector: 'app-first-contact-wizard',
  templateUrl: './first-contact-wizard.component.html',
  styleUrls: ['./first-contact-wizard.component.css']
})
export class FirstContactWizardComponent {
  formGroup: FormGroup;
  selectedTimeSlot: any[] = [];
  selectedTimeline: number;
  @ViewChild(WizardComponent)
  public wizard!: WizardComponent;
  firstContactPojo: ReferralSearchPojo;
  code: string;
  token: any;

  constructor(private fb: FormBuilder,
              private route: Router,
              private _activeRoute: ActivatedRoute,
              private modalService: BsModalService,
              private socialService: SocialService,
              private firstContactTokenControllerService: FirstContactTokenControllerService) {
  }

  ngOnInit() {
    this._activeRoute.params.subscribe(param => {
      if (param['code'] && param['token']) {
        this.code = atob(param['code']);
        this.token = atob(param['token']);
        // this.token = param['token'];
        this.getFirstContactDetails(this.code, this.token);
      } else {
        this.route.navigate(['/']);
      }
    });

    this.formGroup = this.fb.group({
      officeName: ['']
    })
  }

  showFirstContactVideo(value: boolean) {
    this.modalService.show(FirstContactVideoComponent, {
      initialState: {
        isIntroduction: value
      },
      keyboard: false,
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered'
    });
  }

  getFirstContactDetails(code: string, token: string) {
    this.firstContactTokenControllerService.getFirstContactReferralDetails({
      referralCode: code,
      token: token
    }).subscribe({
      next: (v) => {
        if (v.firstContactPojo?.tokenUsed != true) {
          this.firstContactPojo = v;
          this.socialService.firstContactSubject.next(this.firstContactPojo?.dentalOfficeReferralTo?.aboutUrl);
        } else {
          this.firstContactPojo = v;
          this.socialService.firstContactSubject.next(this.firstContactPojo?.dentalOfficeReferralTo?.aboutUrl);
          this.wizard.goToStep(3);
        }
      },
      error: (err) => {
        this.wizard.goToStep(3);
      },
      complete: () => {
        // this.showFirstContactVideo();
      }
    });
  }

  firstStepValues($event: any) {
    if ($event) {
      this.selectedTimeSlot = $event;
    }
    this.wizard.goToNextStep();
  }

  secondStepValues($event: any) {
    if ($event) {
      this.selectedTimeline = $event;
    }
    this.wizard.goToNextStep();
  }
}


