<ul>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('newPassword')?.hasError('required') || handleErrorType('noNumber') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('noNumber') ? 'fa-times' : 'fa-check'" class="fa"></i>
      Contains a digit
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('newPassword')?.hasError('required') || handleErrorType('noSymbol') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('noSymbol') ? 'fa-times' : 'fa-check'" class="fa"></i>
      Contains special character
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('newPassword')?.hasError('required') || handleErrorType('invalidLengthMin') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('invalidLengthMin') ? 'fa-times' : 'fa-check'" class="fa"></i>
      Contains at least 6 characters
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('newPassword')?.hasError('required') || handleErrorType('noLowerCase') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('noLowerCase') ? 'fa-times' : 'fa-check'" class="fa"></i>
      Contains lowercase character
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('newPassword')?.hasError('required') || handleErrorType('noUpperCase') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('noUpperCase') ? 'fa-times' : 'fa-check'" class="fa"></i>
      Contains uppercase character
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('newPassword')?.hasError('required') || handleErrorType('invalidLengthMax') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('invalidLengthMax') ? 'fa-times' : 'fa-check'" class="fa"></i>
      Contains not more than 15 characters
    </small>
  </li>
</ul>
