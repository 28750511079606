<app-full-page-loader *ngIf="loading"></app-full-page-loader>

<div *ngIf="!this._isExtranet; else next">
  <ng-container *ngIf="!currentAccount && user">
    <aside>
      <div *ngIf="user" class="d-none d-lg-block">
        <app-sidebar [user]="user" [notificationTot]="inboundTotal"></app-sidebar>
      </div>
      <!-- Inner body -->
      <div class="dashboard-body-wrapper vh-100">
        <div class="d-flex">
          <div class="vh-100 d-flex flex-column flex-shrink-0 w-100">
            <!-- dashboard header  starts -->
            <app-dashboard-header
              (emitNotificationTotal)="showNotificationTotal($event)"></app-dashboard-header>
            <!-- dashboard header  ends -->
            <!-- dashboard body starts -->
            <div class="px-xl-5 flex-column mb-auto flex-grow-1" style="overflow-x: hidden; overflow-y: auto;">
              <router-outlet></router-outlet>
            </div>
            <!-- dashboard body ends -->
            <!-- dashboard footer starts  -->
            <app-dashboard-footer></app-dashboard-footer>
            <!-- dashboard footer end -->
          </div>
        </div>
      </div>
    </aside>
  </ng-container>
  <ng-container [ngSwitch]="currentAccount.accountType" *ngIf="currentAccount && user">
    <ng-container *ngSwitchDefault>
      <aside *ngIf="(currentAccount && currentAccount.accountType!='SUPER_ADMIN' && currentAccount.accountType != 'AFFILIATE')">
        <app-subscription-warning
          *ngIf="!this._isExtranet && this.showExpirySubscriptionBanner"></app-subscription-warning>
        <!---*ngIf="!this._isExtranet && this.showExpirySubscriptionBanner"-->
        <div *ngIf="user" class="d-none d-lg-block">
          <app-sidebar [user]="user" [notificationTot]="inboundTotal"></app-sidebar>
        </div>

        <!-- Inner body -->
        <div class="dashboard-body-wrapper vh-100">
          <div class="d-flex">
            <div class="vh-100 d-flex flex-column flex-shrink-0 w-100">
              <!-- dashboard header  starts -->
              <app-dashboard-header
                (emitNotificationTotal)="showNotificationTotal($event)"></app-dashboard-header>
              <!-- dashboard header  ends -->
              <!-- dashboard body starts -->
              <div class="px-xl-5 flex-column mb-auto flex-grow-1" style="overflow-x: hidden; overflow-y: auto;">
                <router-outlet></router-outlet>
              </div>
              <!-- dashboard body ends -->
              <!-- dashboard footer starts  -->
              <app-dashboard-footer></app-dashboard-footer>
              <!-- dashboard footer end -->
            </div>
          </div>
        </div>
      </aside>
        <!--      Affiliate Structure build-->
      <aside *ngIf="(currentAccount && currentAccount.accountType!='SUPER_ADMIN' && currentAccount.accountType == 'AFFILIATE')">
        <!---*ngIf="!this._isExtranet && this.showExpirySubscriptionBanner"-->
        <div *ngIf="user" class="d-none d-lg-block">
          <app-affiliate-sidebar></app-affiliate-sidebar>
        </div>

        <!-- Inner body -->
        <div class="dashboard-body-wrapper vh-100">
          <div class="d-flex">
            <div class="vh-100 d-flex flex-column flex-shrink-0 w-100">
              <!-- dashboard header  starts -->
              <app-affiliate-dashboard-header></app-affiliate-dashboard-header>
              <!-- dashboard header  ends -->
              <!-- dashboard body starts -->
              <div class="px-xl-5 flex-column mb-auto flex-grow-1" style="overflow-x: hidden; overflow-y: auto;">
                <router-outlet></router-outlet>
              </div>
              <!-- dashboard body ends -->
              <!-- dashboard footer starts  -->
              <app-dashboard-footer></app-dashboard-footer>
              <!-- dashboard footer end -->
            </div>
          </div>
        </div>
      </aside>
      <!--      Admin Side of the divide -->
      <aside *ngIf="(currentAccount && currentAccount.accountType=='SUPER_ADMIN')">
        <div *ngIf="user" class="d-none d-lg-block">
          <app-admin-dashboard-sidebar></app-admin-dashboard-sidebar>
        </div>
        <!-- Inner body -->
        <div class="dashboard-body-wrapper vh-100">
          <div class="d-flex">
            <div class="vh-100 d-flex flex-column flex-shrink-0 w-100">
              <!-- admin dashboard header  starts -->
              <app-admin-dashboard-header></app-admin-dashboard-header>
              <!-- admin dashboard header  ends -->
              <!-- admin dashboard body starts -->
              <div class="px-xl-5 flex-column mb-auto flex-grow-1" style="overflow-x: hidden; overflow-y: auto;">
                <router-outlet></router-outlet>
              </div>
              <!-- admin dashboard body ends -->
              <!-- admin dashboard footer starts  -->
              <app-dashboard-footer></app-dashboard-footer>
              <!-- admin dashboard footer end -->
            </div>
          </div>
        </div>
      </aside>

    </ng-container>
  </ng-container>
</div>
<ng-template #next>
  <app-nav-header *ngIf="isNavBar" [isAffiliate]="_isAffiliate"></app-nav-header>
  <router-outlet></router-outlet>
  <app-footer *ngIf="isFooter"></app-footer>
</ng-template>
