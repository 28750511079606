/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { DentalOffice } from './dental-office';
import { PostReferralTreatment } from './post-referral-treatment';
import { SpecialistPostReferral } from './specialist-post-referral';
import { Patient } from './patient';
import { Procedure } from './procedure';
import { DentalProfessional } from './dental-professional';
import { TemporaryDentalOffice } from './temporary-dental-office';
import { ReferralData } from './referral-data';


export interface ReferralRequest { 
    saveAsDraft?: boolean;
    referralRequestStatus?: ReferralRequest.ReferralRequestStatusEnum;
    dateCreated?: string;
    updatedAt?: string;
    otherProcedures?: string;
    code?: string;
    adult?: boolean;
    appointmentDate?: string;
    dentalProfFirstName?: string;
    dentalProfLastName?: string;
    dentalProfEmail?: string;
    id?: number;
    patient?: Patient;
    referralRequests?: Array<ReferralData>;
    portalAccount?: PortalAccount;
    referralTo?: DentalOffice;
    procedures?: Array<Procedure>;
    specialistPostReferral?: SpecialistPostReferral;
    referredProfessional?: DentalProfessional;
    createdBy?: DentalProfessional;
    postReferralTreatment?: PostReferralTreatment;
    temporaryDentalOffice?: TemporaryDentalOffice;
}
export namespace ReferralRequest {
    export type ReferralRequestStatusEnum = 'PENDING' | 'SCHEDULED' | 'COMPLETED' | 'CUSTOM' | 'CANCELLED' | 'REJECTED' | 'NEW' | 'CALLED' | 'RESCHEDULED' | 'NO_SHOW';
    export const ReferralRequestStatusEnum = {
        Pending: 'PENDING' as ReferralRequestStatusEnum,
        Scheduled: 'SCHEDULED' as ReferralRequestStatusEnum,
        Completed: 'COMPLETED' as ReferralRequestStatusEnum,
        Custom: 'CUSTOM' as ReferralRequestStatusEnum,
        Cancelled: 'CANCELLED' as ReferralRequestStatusEnum,
        Rejected: 'REJECTED' as ReferralRequestStatusEnum,
        New: 'NEW' as ReferralRequestStatusEnum,
        Called: 'CALLED' as ReferralRequestStatusEnum,
        Rescheduled: 'RESCHEDULED' as ReferralRequestStatusEnum,
        NoShow: 'NO_SHOW' as ReferralRequestStatusEnum
    };
}


