/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientModeOfPaymentDto } from './patient-mode-of-payment-dto';
import { UserRegistrationDto } from './user-registration-dto';
import { InsuranceDto } from './insurance-dto';
import { PatientDto } from './patient-dto';


export interface AppointmentBookingDto { 
    dentalOfficeId: number;
    dentalProfessionalId?: number;
    reasonForVisitId: number;
    startTime: string;
    endTime: string;
    durationInMinutes: number;
    patientRelationship: AppointmentBookingDto.PatientRelationshipEnum;
    patient: PatientDto;
    modeOfPayment: PatientModeOfPaymentDto;
    appointmentType: AppointmentBookingDto.AppointmentTypeEnum;
    comment?: string;
    insurance?: InsuranceDto;
    requester?: UserRegistrationDto;
    requesterUserId?: string;
    previouslyScheduledAppointmentId?: number;
    timeslotDefinitionId: number;
    promotionRedemptionCode?: string;
}
export namespace AppointmentBookingDto {
    export type PatientRelationshipEnum = 'SELF' | 'OTHER';
    export const PatientRelationshipEnum = {
        Self: 'SELF' as PatientRelationshipEnum,
        Other: 'OTHER' as PatientRelationshipEnum
    };
    export type AppointmentTypeEnum = 'CHECKUP' | 'EMERGENCY' | 'FOLLOWUP' | 'ROUTINE' | 'WALKIN';
    export const AppointmentTypeEnum = {
        Checkup: 'CHECKUP' as AppointmentTypeEnum,
        Emergency: 'EMERGENCY' as AppointmentTypeEnum,
        Followup: 'FOLLOWUP' as AppointmentTypeEnum,
        Routine: 'ROUTINE' as AppointmentTypeEnum,
        Walkin: 'WALKIN' as AppointmentTypeEnum
    };
}


