import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SelectionManager} from "dd-shared-lib";
import {
  CustomSubscriptionSearchFilter, Help, HelpControllerService, HelpSearchFilter, HelpStatPojo, QueryResultsPojoHelp
} from "dd-core-api-sdk";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {BehaviorSubject} from "rxjs";
import * as moment from "moment";
import {getOffset, removeUndefinedOrNullFields} from "../../../models/search-model";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {BsModalService} from "ngx-bootstrap/modal";
import {NoteViewerComponent} from "../../../shared/note-viewer/note-viewer.component";
import HelpStatusEnum = Help.HelpStatusEnum;
import {Utils} from "../../../shared/utils";

@Component({
  selector: 'app-help-list',
  templateUrl: './help-list.component.html',
  styleUrls: ['./help-list.component.css']
})
export class HelpListComponent implements OnInit {

  utcOffsetMinutes: string;
  showErrorMessageTrigger = false;
  isError: boolean = true;
  errMessage = '';
  searching = true;
  form: FormGroup;
  limit: number;
  offset: number;
  currentStartDate: any;
  selectionManager: SelectionManager<Help, number>;
  user: UserKeycloak;
  resultArray: Array<Help>;
  filterData: HelpSearchFilter = {};
  resultSubject = new BehaviorSubject<QueryResultsPojoHelp>({});
  queryResults: QueryResultsPojoHelp;
  data: HelpSearchFilter;
  helpStatus = HelpStatusEnum;
  completeForm: FormGroup;
  helpStat: HelpStatPojo;

  constructor(private fb: FormBuilder,
              private helpControllerService: HelpControllerService,
              private modalService: BsModalService,
              private authService: AuthenticationServiceKeycloak) {
    this.utcOffsetMinutes = 'UTC' + this.getTimeZone().toString();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [''],
      status: [''],
      startDate: [''],
      endDate: [''],
      limit: [''],
      offset: ['']
    });

    this.completeForm = this.fb.group({
      id: [''],
      markCompleted: [true]
    })
    this.form.controls.startDate.valueChanges.subscribe(v => {
      this.currentStartDate = v;
      if (v != moment(v).format('YYYY-MM-DD')) {
        this.form.patchValue({endDate: ''});
      }
    });

    this.form.controls.endDate.valueChanges.subscribe(v => {
      if (moment(this.currentStartDate) > moment(v)) {
        this.showErrorMessage('Date(from) should be greater than Date(to).');
        this.form.patchValue({endDate: ''});
      }
    });

    this.authService.getUser().subscribe(v => {
      this.user = v
    });
    this.loadHelpStat();
    this.loadHelp();

  }


  loadHelpStat() {
    this.helpControllerService.helpStats().subscribe(res => {
      this.helpStat = res;
    })
  }

  loadHelp() {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);
    this.search(filter);
  }

  search(filter: HelpSearchFilter) {
    this.searching = true;
    this.helpControllerService.searchHelp({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.offset = v.offset;
      this.queryResults = v;
      this.resultArray = v.results!;
      this.resultSubject.next(v);
    }, () => {
      this.searching = false;
    });

    this.data = filter;
    // this.data.page = this.page;
  }

  getFilter(limit?: number): HelpSearchFilter {
    const data = this.form!.value;

    let filter: HelpSearchFilter = {};
    filter.email = data.email;
    filter.helpStatusConstant = data.status;
    filter.startDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }

  onPageChange($event) {
    const searchFilter: HelpSearchFilter = {
      offset: getOffset($event.page, $event.size || 4),
      limit: $event.itemsPerPage,
    };

    this.search(searchFilter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }

  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  formatUTCMoment(dateCreated: string) {
    return moment(dateCreated).utc(true).toString();
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  showInviteOptionModal(notes: Help) {
    let bsModalRef = this.modalService.show(NoteViewerComponent, {
      initialState: {
        notes: notes.description,
        email: notes.senderEmail,
      },
      keyboard: false,
      class: '"modal-dialog modal-dialog-scrollable modal-dialog-centered',
      ignoreBackdropClick: true,
    });
  }

  markAsCompleted(helpID, index) {
    this.helpControllerService.closeHelpRequest({
      helpID: helpID
    }).subscribe(res => {
      this.resultSubject.value.results[index].helpStatus = 'CLOSED';
      this.isError = false;
      this.showErrorMessage('Help request has been marked as closed.')
      this.loadHelpStat();
    }, err => {
      this.isError = true;
      this.completeForm.reset();
      this.showErrorMessage('Error in updating status.')
    })
  }

}
