<div class="modal-content">
  <div class="modal-header rounded-0 bg-primary">
    <h1 class="modal-title fs-5 text-light" id="messageModalLabel"> Patient Appointment Preference
      <iconify-icon icon="material-symbols:calendar-month-outline-rounded" style="color: #fff;" width="25" height="25">
      </iconify-icon>
    </h1>
    <button type="button" class="btn-close" (click)="dismissModal()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div>
      <div class="my-3 text-muted">
        <div class="p-2 align-items-center justify-content-center">
          <div class="col fw-bold ">
            <p class="mt-2 p-2">Preferred time(s) for appointment:</p>
          </div>
          <div class="col" *ngIf="this.firstContactPojo?.timeSlotDefinitionList < 1">
            <div class="btn-background p-2 rounded-5">
              No Preference Selected
            </div>
          </div>
          <div class="col" *ngFor="let aff of this.firstContactPojo?.timeSlotDefinitionList;">
            <div class="btn-background p-2 rounded-5" *ngIf="aff.name =='Morning'">
              Before {{aff.endTime | date: 'H a'}}
            </div>
            <div class="btn-background p-2 rounded-5" *ngIf="aff.name == 'Afternoon'">
              {{aff.startTime | date: 'H a'}}-{{aff.endTime | date: 'h a'}}
            </div>
            <div class="btn-background p-2 rounded-5" *ngIf="aff.name == 'Evening'">
              After {{aff.endTime | date: 'h a'}}
            </div>
          </div>
        </div>
        <div class="p-2 align-items-center justify-content-center" *ngIf="this.firstContactPojo?.firstContactTimeRange">
          <div class="col fw-bold ">
            <p class="mt-2 p-2">How soon would you like to visit:</p>
          </div>
          <div class="">
            <div class="btn-background p-2 rounded-5">
              {{this.firstContactPojo?.firstContactTimeRange?.range}}
            </div>
          </div>
        </div>
        <div class="row p-2 align-items-center justify-content-center" *ngIf="!this.firstContactPojo?.firstContactTimeRange">
          <div class="col fw-bold ">
            <p class="mt-2 p-2">How soon would you like to visit:</p>
          </div>
          <div class="col">
            <div class="btn-background p-2 rounded-5">
              No Preference Selected
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismissModal()">Close</button>
  </div>
</div>
