import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserRegistrationService} from "dd-core-api-sdk";
import {SocialService} from "../../../../shared/subject/SocialService";
import {Router} from "@angular/router";

@Component({
  selector: 'app-affiliate-success-modal',
  templateUrl: './affiliate-success-modal.component.html',
  styleUrls: ['./affiliate-success-modal.component.css']
})
export class AffiliateSuccessModalComponent implements OnInit {

  @Input() accountType: string;
  @Input() email: string;
  @Input() phone: string;
  @Input() message: string;
  @Input() routeLink: string = "/verify-email";
  @Input() body: string;
  @Output() onClick = new EventEmitter<any>();

  constructor(private userRegistrationService: UserRegistrationService, private socialService: SocialService, private router: Router) {
  }

  ngOnInit(): void {
  }

  requestOTP() {
    this.userRegistrationService.requestMobileNumberVerification({
      email: this.email,
      phoneNumber: this.phone,
      deliveryMode: 'PHONE_CALL'
    }).subscribe(res => {
      this.socialService.otpSubject.next("TRUE");
      sessionStorage.setItem("REQUESTED_OTP", "TRUE");
      this.onClick.emit();
    })
  }

}
