/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RecommendationSearchFilter { 
    createdOnOrAfter?: string;
    createdBefore?: string;
    orderBy?: Set<RecommendationSearchFilter.OrderByEnum>;
    entityTypeConstant?: RecommendationSearchFilter.EntityTypeConstantEnum;
    recommenderId?: number;
    entityId?: number;
    limit?: number;
    offset?: number;
}
export namespace RecommendationSearchFilter {
    export type OrderByEnum = 'RATING_ASC' | 'RATING_DESC' | 'DATE_CREATED_DESC';
    export const OrderByEnum = {
        RatingAsc: 'RATING_ASC' as OrderByEnum,
        RatingDesc: 'RATING_DESC' as OrderByEnum,
        DateCreatedDesc: 'DATE_CREATED_DESC' as OrderByEnum
    };
    export type EntityTypeConstantEnum = 'DentalOffice' | 'DentalProfessional' | 'Promotion' | 'Review' | 'FlashCardSet' | 'Comment';
    export const EntityTypeConstantEnum = {
        DentalOffice: 'DentalOffice' as EntityTypeConstantEnum,
        DentalProfessional: 'DentalProfessional' as EntityTypeConstantEnum,
        Promotion: 'Promotion' as EntityTypeConstantEnum,
        Review: 'Review' as EntityTypeConstantEnum,
        FlashCardSet: 'FlashCardSet' as EntityTypeConstantEnum,
        Comment: 'Comment' as EntityTypeConstantEnum
    };
}


