<div class="container mt-4">
  <div class="row">
    <div class="d-flex align-items-center">
      <p class="fs-3 fw-bold my-3">
        <iconify-icon
          inline
          icon="healthicons:referral-outline"
          class="fs-3 me-2"
        ></iconify-icon>
        Inbound
      </p>
    </div>

    <!--    Referral stats card begin-->
    <app-referral-stat-card [isInbound]="true"></app-referral-stat-card>
    <!--    Referral stats card ends-->

    <!-- search filter  -->
    <div class="">
      <div class="my-3"></div>
      <app-referral-list [isInbound]="true"></app-referral-list>
    </div>
  </div>
</div>
