/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReporterDto } from './reporter-dto';


export interface ReportAbuseDto { 
    reporterDto?: ReporterDto;
    comment: string;
    entityId: number;
    entityType: ReportAbuseDto.EntityTypeEnum;
    categoryCode?: string;
}
export namespace ReportAbuseDto {
    export type EntityTypeEnum = 'DentalOffice' | 'DentalProfessional' | 'BwFile' | 'Review' | 'Promotion' | 'FlashCardSet';
    export const EntityTypeEnum = {
        DentalOffice: 'DentalOffice' as EntityTypeEnum,
        DentalProfessional: 'DentalProfessional' as EntityTypeEnum,
        BwFile: 'BwFile' as EntityTypeEnum,
        Review: 'Review' as EntityTypeEnum,
        Promotion: 'Promotion' as EntityTypeEnum,
        FlashCardSet: 'FlashCardSet' as EntityTypeEnum
    };
}


