import { Component, OnInit, Input } from '@angular/core';
import { FormGroup} from '@angular/forms';

@Component({
  selector: 'password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  @Input() form!: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  handleErrorType(errorType: string) {
    return this.form.get('newPassword')?.hasError(errorType);
  }

}
