import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommissionLogPojo } from 'dd-core-api-sdk';

@Component({
  selector: 'app-learn-more-modal',
  templateUrl: './learn-more-modal.component.html',
  styleUrls: ['./learn-more-modal.component.css']
})
export class LearnMoreModalComponent implements OnInit {

  @Output()
  dismissClicked = new EventEmitter<any>();

  @Output()
  closeClicked = new EventEmitter<any>();

  @Input()
  infoTitle: string;
  @Input()
  commissionLogPojo: CommissionLogPojo;
  constructor() { }

  ngOnInit(): void {
    
  }

}
