import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PhoneNumberValidator} from "dd-shared-lib";
import {UniqueIdValidatorService} from "../../../../shared/unique-id-validator.service";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";

@Component({
  selector: 'app-affiliate-signup',
  templateUrl: './affiliate-signup.component.html',
  styleUrls: ['./affiliate-signup.component.css']
})
export class AffiliateSignupComponent implements OnInit {

  formGroup: FormGroup;
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.UnitedStates;
  errorSubmission: boolean;
  preferredCountries: string[] = ['us'];
  displayPassword = false;
  @Output() nextButtonClicked = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder, private uniqueIdValidatorService: UniqueIdValidatorService) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$"), Validators.maxLength(50)]],
      phoneNumber: ['', [Validators.required, PhoneNumberValidator()]],
    });

    this.formGroup.get('phoneNumber')?.setAsyncValidators([this.uniqueIdValidatorService.phoneNumberVerifier()]);
    this.formGroup.get('email')?.setAsyncValidators([this.uniqueIdValidatorService.verifier('email', 'emailExists')]);
  }
  submissionForError(){
    this.errorSubmission = true;
  }
  moveToNext() {
    console.log(this.formGroup)
    this.nextButtonClicked.next(this.formGroup);
  }

}
