import {KeyValuePipe} from '@angular/common';
import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {
  DashboardControllerService,
  DentalDoorAdminControllerService,
  DentalOfficeControllerService,
  OfficeMembersFilterHandler
} from 'dd-core-api-sdk';
import {BaseChartDirective} from 'ng2-charts';
import {AuthenticationServiceKeycloak} from 'src/app/services/AuthenticationServiceKeycloak';
import {UserAccountKeycloak} from 'src/app/services/UserAccountKeycloak';
import {UserKeycloak} from 'src/app/services/UserKeycloak';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  user: UserKeycloak;
  members = [];
  professional;
  totalDentalOffices: number;
  totalOfficeSubscriptions: number;
  totalAmountOfRevenue: any;
  form: FormGroup;
  years: number[] = [];
  selectedYear = new Date().getFullYear();
  userAccount: UserAccountKeycloak;
  showAllOffices = true;
  showSubscription = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationServiceKeycloak,
    private dentalDoorAdminController: DentalDoorAdminControllerService,
    private key: KeyValuePipe,
    private fb: FormBuilder,
    private dentalOfficeService: DentalOfficeControllerService,
  ) {
    for (let year = this.selectedYear; year >= 2010; year--) {
      this.years.push(year);
    }
  }


  ngOnInit(): void {
    this.form = this.fb.group({
      years: [this.selectedYear]
    });

    this.authenticationService.getUser().subscribe(user => {
      this.userAccount = AuthenticationServiceKeycloak._currentUserAccount;
      this.user = user;
      this.professional = user?.dentalProfessional;
      this.getDashboardStats();
      if (this.shouldShowRoute() && !user?.dentalProfessional) {
        if (!AuthenticationServiceKeycloak._currentUserAccount?.dentalOfficeSubscription && AuthenticationServiceKeycloak._currentUserAccount?.otherData?.specialityOffice) {
          this.router.navigate(['/billing/purchase-plan']);
        }
      } else {
        this.router.navigate(['/dashboard']);
        this.getMembers(AuthenticationServiceKeycloak._currentUserAccount?.accountId);
      }
      if (user.invitesSent < 1 && !this.userAccount?.otherData?.specialityOffice) {
        this.router.navigate(['/invites-list/create']);
      }
    })
  }

  getDashboardStats() {
    this.dentalDoorAdminController.getDentalDoorAdminStat().subscribe(
      res => {
        this.totalDentalOffices = res.dentalOfficeCount;
        this.totalOfficeSubscriptions = res.dentalOfficeTotalActiveSubscriptions;
        this.totalAmountOfRevenue = res.totalAmountOfRevenue;
      }
    )
  }

  shouldShowRoute() {
    return AuthenticationServiceKeycloak._currentUserAccount?.dentalOfficeSubscription?.subscriptionStatus != 'TRIAL'
      && AuthenticationServiceKeycloak._currentUserAccount?.otherData?.specialityOffice;
  }

  getMembers(accountId: number) {
    if (accountId)
      this.dentalOfficeService
        .getOfficeMembers({
          filter: this.searchFilter(accountId),
        })
        .subscribe(query => {
          this.members = query.results;
        });
  }

  searchFilter(accountId: number): OfficeMembersFilterHandler {
    return {
      accountId: accountId,
      limit: 5,
      offset: 0
    }
  }

  showOffice() {
    this.showAllOffices = true;
    this.showSubscription = false;
  }

  showAllSubscription() {
    this.showAllOffices = false;
    this.showSubscription = true;
  }
}
