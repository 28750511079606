import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";

@Component({
  selector: 'success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css']
})
export class SuccessDialogComponent implements OnInit {

  constructor(public modalRef: BsModalRef, private router: Router) {
  }

  ngOnInit(): void {
  }

  close() {
    window.scroll(0, 0);
    this.router.navigate(['']);
    this.modalRef.hide();
  }
}
