/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientModeOfPayment } from './patient-mode-of-payment';
import { AppointmentType } from './appointment-type';
import { DentalOffice } from './dental-office';
import { AppointmentCancellationReason } from './appointment-cancellation-reason';
import { Note } from './note';
import { Period } from './period';
import { PromotionRedemption } from './promotion-redemption';
import { BwFile } from './bw-file';
import { PortalUser } from './portal-user';


export interface Appointment { 
    dateCreated?: string;
    status?: Appointment.StatusEnum;
    description?: string;
    startTime?: string;
    endTime?: string;
    minutesDuration?: number;
    comment?: string;
    patientInstruction?: string;
    requesterPatientRelationship?: Appointment.RequesterPatientRelationshipEnum;
    firstTime?: boolean;
    reasonForCancellation?: string;
    dateProcessed?: string;
    fhirId?: string;
    code?: string;
    id?: number;
    requestedPeriod?: Period;
    appointmentCancellationReason?: AppointmentCancellationReason;
    appointmentType?: AppointmentType;
    note?: Note;
    previouslyScheduledAppointment?: Appointment;
    patientModeOfPayment?: PatientModeOfPayment;
    dentalOffice?: DentalOffice;
    processedBy?: PortalUser;
    promotionRedemption?: PromotionRedemption;
    qrCode?: BwFile;
    requestedBy?: PortalUser;
}
export namespace Appointment {
    export type StatusEnum = 'PROPOSED' | 'PENDING' | 'BOOKED' | 'ARRIVED' | 'FULFILLED' | 'CANCELLED' | 'NOSHOW' | 'ENTERED_IN_ERROR' | 'CHECKED_IN' | 'WAITLIST';
    export const StatusEnum = {
        Proposed: 'PROPOSED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum,
        Booked: 'BOOKED' as StatusEnum,
        Arrived: 'ARRIVED' as StatusEnum,
        Fulfilled: 'FULFILLED' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum,
        Noshow: 'NOSHOW' as StatusEnum,
        EnteredInError: 'ENTERED_IN_ERROR' as StatusEnum,
        CheckedIn: 'CHECKED_IN' as StatusEnum,
        Waitlist: 'WAITLIST' as StatusEnum
    };
    export type RequesterPatientRelationshipEnum = 'SELF' | 'OTHER';
    export const RequesterPatientRelationshipEnum = {
        Self: 'SELF' as RequesterPatientRelationshipEnum,
        Other: 'OTHER' as RequesterPatientRelationshipEnum
    };
}


