import {Component, Input, OnInit} from '@angular/core';
import {UserAccountKeycloak} from "../../../services/UserAccountKeycloak";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {BehaviorSubject} from "rxjs";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {Constant} from "../../../shared/constant";

@Component({
  selector: 'app-admin-dashboard-sidebar',
  templateUrl: './admin-dashboard-sidebar.component.html',
  styleUrls: ['./admin-dashboard-sidebar.component.css']
})
export class AdminDashboardSidebarComponent implements OnInit {
  userAccount: UserAccountKeycloak;
  @Input() user: UserKeycloak;
  @Input() notificationTot: any;
  currentRoute: string;
  count: number;
  @Input()
  screenShotEle: any;
  pictureId = new BehaviorSubject<any>({id: ''});
  newInbound: number;

  constructor(private authentication: AuthenticationServiceKeycloak,
              private router: Router) {}

  ngOnInit(): void {
    this.userAccount = AuthenticationServiceKeycloak._currentUserAccount;
    this.router.events.subscribe((url:any) => this.getCurrentUrl(url.url));

    if (AuthenticationServiceKeycloak._currentUserAccount?.otherData?.displayPictureId) {
      this.pictureId.next({id: String(AuthenticationServiceKeycloak?._currentUserAccount?.otherData?.displayPictureId)});
    }

  }
  getFileURL() {
    if (this.pictureId.value.id != null && this.pictureId.value.id != '') {
      return environment.siteUrl + "/files/" + this.pictureId.value.id;
    }
    return "./assets/img/user.png";
  }

  selectAccount(account: UserAccountKeycloak) {
    this.authentication.setCurrentAccount(account);
    sessionStorage.setItem(Constant.ACCOUNT_SELECTED, 'true');
    window.location.replace('/dashboard');
  }

  getCurrentUrl(url: string){
    if(url != undefined || url != null){
      this.currentRoute = url;
    }
  }


  logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.authentication.logoutClick(window.location.origin);
  }

}
