/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SpecialistPostReferralServiceDto } from './specialist-post-referral-service-dto';


export interface UpdateStatusNoteDto { 
    referralRequestId?: number;
    appointmentDate?: string;
    referralStatus?: UpdateStatusNoteDto.ReferralStatusEnum;
    statusNote?: string;
    specialistPostReferralServiceDto?: SpecialistPostReferralServiceDto;
}
export namespace UpdateStatusNoteDto {
    export type ReferralStatusEnum = 'PENDING' | 'SCHEDULED' | 'COMPLETED' | 'CUSTOM' | 'CANCELLED' | 'REJECTED' | 'NEW' | 'CALLED' | 'RESCHEDULED' | 'NO_SHOW';
    export const ReferralStatusEnum = {
        Pending: 'PENDING' as ReferralStatusEnum,
        Scheduled: 'SCHEDULED' as ReferralStatusEnum,
        Completed: 'COMPLETED' as ReferralStatusEnum,
        Custom: 'CUSTOM' as ReferralStatusEnum,
        Cancelled: 'CANCELLED' as ReferralStatusEnum,
        Rejected: 'REJECTED' as ReferralStatusEnum,
        New: 'NEW' as ReferralStatusEnum,
        Called: 'CALLED' as ReferralStatusEnum,
        Rescheduled: 'RESCHEDULED' as ReferralStatusEnum,
        NoShow: 'NO_SHOW' as ReferralStatusEnum
    };
}


