import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ReferralCreationModel} from "../ReferralCreationModel";
import {ReferralDraft, ReferralDraftDto} from "dd-core-api-sdk";


@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  referralCreationSubject: BehaviorSubject<ReferralCreationModel> = new BehaviorSubject<ReferralCreationModel>(null);
  draftCreationSubject: BehaviorSubject<ReferralDraft> = new BehaviorSubject<ReferralDraft>(null);

  saveReferralCreation(referralSubject: ReferralCreationModel) {
    this.referralCreationSubject.next(referralSubject);
  }

  getReferralCreation() {
    return this.referralCreationSubject;
  }

  setDraftCreationSubject(draftSubject: ReferralDraft) {
    this.draftCreationSubject.next(draftSubject);
  }

  getDraftCreationSubject() {
    return this.draftCreationSubject?.value;
  }

  getDraftCode() {
    return this.draftCreationSubject?.value?.draftCode;
  }
}
