<aw-wizard [disableNavigationBar]="true" [defaultStepIndex]="0" navBarLayout="null">
    <aw-wizard-step [canEnter]="true">

        <div *ngIf="!isDraft; else completeReferral">
            <app-create-referral (toothDeselected)="onToothDeselected($event)" [adultTeethComponent]="adultTeethComponent" [childTeethComponent]="childTeethComponent" [affectedTeeth]="affectedTeeth" (emmitNextClicked)="getValuesFromStep($event)" (fileDeleted)="deleteFileInfo($event)"></app-create-referral>
        </div>
        <ng-template #completeReferral>
            <app-create-referral *ngIf="draft"  (toothDeselected)="onToothDeselected($event)" [isDraft]="isDraft" [draft]="draft" [draftFiles]='files' [affectedTeeth]="affectedTeeth" [adultTeethComponent]="adultTeethComponent" [childTeethComponent]="childTeethComponent" (emmitNextClicked)="getValuesFromStep($event)"
                (fileDeleted)="deleteFileInfo($event)"></app-create-referral>
        </ng-template>
    </aw-wizard-step>
    <aw-wizard-step [canEnter]="true">
        <app-preview-referral [adultTeethComponent]="adultTeethComponent" [childTeethComponent]="childTeethComponent" [formOneInputs]="formOneValues" [bwFileIdList]="this.bwFileIdList" (previousClicked)="editFormPage($event)" (referralSent)="deleteDraft($event)"></app-preview-referral>
    </aw-wizard-step>
</aw-wizard>

<ng-template #adultTeethComponent>
    <app-adult-teeth [deselectedTooth]="deselectedTooth" (outputSelectAdultTeeth)="getSelectedTeeth($event,true)" [affected]=affectedTeeth [editable]="editable"></app-adult-teeth>
</ng-template>
<ng-template #childTeethComponent>
    <app-child-teeth [deselectedTooth]="deselectedTooth" (outputSelectChildTeeth)="getSelectedTeeth($event,false)" [affected]=affectedTeeth [editable]="editable"></app-child-teeth>
</ng-template>
