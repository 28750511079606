/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { DentalReferralCreationDto } from '../model/models';
import { DentalReferralCreationPojo } from '../model/models';
import { QueryResultsPojoReferralSearchPojo } from '../model/models';
import { QueryResultsReferralDraft } from '../model/models';
import { ReferralDraft } from '../model/models';
import { ReferralDraftDto } from '../model/models';
import { ReferralDraftPojo } from '../model/models';
import { ReferralDraftSearchFilter } from '../model/models';
import { ReferralReason } from '../model/models';
import { ReferralRequest } from '../model/models';
import { ReferralRequestSearchFilter } from '../model/models';
import { ReferralSearchPojo } from '../model/models';
import { ReferralStatisticPojo } from '../model/models';
import { ReferralStatusNotes } from '../model/models';
import { TemporaryDentalOffice } from '../model/models';
import { UpdateStatusNoteDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateDentalReferralRequestParams {
    dentalReferralCreationDto: DentalReferralCreationDto;
}

export interface DeleteDraftFileInfoRequestParams {
    id: number;
}

export interface GetExtranetReferralDataRequestParams {
    referralDataId: number;
    email: string;
    pin: string;
}

export interface GetReferralMonthlyRequestParams {
    isInbound: boolean;
    portalAccountId?: number;
    dentalProfessionalId?: number;
    receivingDentalOfficeId?: number;
    receivingDentalProfessionalId?: number;
}

export interface GetReferralStatisticsRequestParams {
    dentalProfessionalId?: number;
    receivingDentalOfficeId?: number;
    portalAccountId?: number;
}

export interface GetReferralStatusAndNotesRequestParams {
    referralRequestId: number;
}

export interface GetSingleReferralDataRequestParams {
    referralDataId: number;
}

export interface GetSingleReferralDraftRequestParams {
    draftId: number;
}

export interface GetStatusNoteRequestParams {
    status: 'PENDING' | 'SCHEDULED' | 'COMPLETED' | 'CUSTOM' | 'CANCELLED' | 'REJECTED' | 'NEW' | 'CALLED' | 'RESCHEDULED' | 'NO_SHOW';
    referralRequestId: number;
}

export interface ResendReferralNotificationRequestParams {
    referralRequestId: number;
    type: string;
    email?: string;
    phoneNumber?: string;
}

export interface SaveReferralDraftRequestParams {
    referralDraftDto: ReferralDraftDto;
}

export interface SearchDentalReferralRequestParams {
    filter: ReferralRequestSearchFilter;
}

export interface SearchReferralDraftRequestParams {
    filter: ReferralDraftSearchFilter;
}

export interface SearchReferralServicesRequestParams {
    specializationCode?: Array<string>;
}

export interface SearchTemporaryDentalOfficeRequestParams {
    officeName: string;
}

export interface UpdateDentalReferralRequestStatusRequestParams {
    updateStatusNoteDto: UpdateStatusNoteDto;
}

export interface UpdateStatusNotesRequestParams {
    referralNoteId: number;
    note: string;
}


@Injectable({
  providedIn: 'root'
})
export class DentalReferralControllerService {

    protected basePath = 'http://demo.dentaldoor.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a dental referral request
     * Call this API to create a dental referral request
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDentalReferral(requestParameters: CreateDentalReferralRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DentalReferralCreationPojo>;
    public createDentalReferral(requestParameters: CreateDentalReferralRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DentalReferralCreationPojo>>;
    public createDentalReferral(requestParameters: CreateDentalReferralRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DentalReferralCreationPojo>>;
    public createDentalReferral(requestParameters: CreateDentalReferralRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const dentalReferralCreationDto = requestParameters.dentalReferralCreationDto;
        if (dentalReferralCreationDto === null || dentalReferralCreationDto === undefined) {
            throw new Error('Required parameter dentalReferralCreationDto was null or undefined when calling createDentalReferral.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<DentalReferralCreationPojo>(`${this.configuration.basePath}/dental-referral/create`,
            dentalReferralCreationDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDraftFileInfo(requestParameters: DeleteDraftFileInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deleteDraftFileInfo(requestParameters: DeleteDraftFileInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deleteDraftFileInfo(requestParameters: DeleteDraftFileInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deleteDraftFileInfo(requestParameters: DeleteDraftFileInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDraftFileInfo.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<object>(`${this.configuration.basePath}/dental-referral/draft-file-info/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExtranetReferralData(requestParameters: GetExtranetReferralDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralSearchPojo>;
    public getExtranetReferralData(requestParameters: GetExtranetReferralDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralSearchPojo>>;
    public getExtranetReferralData(requestParameters: GetExtranetReferralDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralSearchPojo>>;
    public getExtranetReferralData(requestParameters: GetExtranetReferralDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const referralDataId = requestParameters.referralDataId;
        if (referralDataId === null || referralDataId === undefined) {
            throw new Error('Required parameter referralDataId was null or undefined when calling getExtranetReferralData.');
        }
        const email = requestParameters.email;
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getExtranetReferralData.');
        }
        const pin = requestParameters.pin;
        if (pin === null || pin === undefined) {
            throw new Error('Required parameter pin was null or undefined when calling getExtranetReferralData.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }
        if (pin !== undefined && pin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pin, 'pin');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ReferralSearchPojo>(`${this.configuration.basePath}/dental-referral/${encodeURIComponent(String(referralDataId))}/extranet-single-referral-data`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get dental referral statistic
     * get dental referral statistic
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReferralMonthly(requestParameters: GetReferralMonthlyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralStatisticPojo>;
    public getReferralMonthly(requestParameters: GetReferralMonthlyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralStatisticPojo>>;
    public getReferralMonthly(requestParameters: GetReferralMonthlyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralStatisticPojo>>;
    public getReferralMonthly(requestParameters: GetReferralMonthlyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const isInbound = requestParameters.isInbound;
        if (isInbound === null || isInbound === undefined) {
            throw new Error('Required parameter isInbound was null or undefined when calling getReferralMonthly.');
        }
        const portalAccountId = requestParameters.portalAccountId;
        const dentalProfessionalId = requestParameters.dentalProfessionalId;
        const receivingDentalOfficeId = requestParameters.receivingDentalOfficeId;
        const receivingDentalProfessionalId = requestParameters.receivingDentalProfessionalId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (portalAccountId !== undefined && portalAccountId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccountId, 'portalAccountId');
        }
        if (dentalProfessionalId !== undefined && dentalProfessionalId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dentalProfessionalId, 'dentalProfessionalId');
        }
        if (receivingDentalOfficeId !== undefined && receivingDentalOfficeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>receivingDentalOfficeId, 'receivingDentalOfficeId');
        }
        if (receivingDentalProfessionalId !== undefined && receivingDentalProfessionalId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>receivingDentalProfessionalId, 'receivingDentalProfessionalId');
        }
        if (isInbound !== undefined && isInbound !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isInbound, 'isInbound');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ReferralStatisticPojo>(`${this.configuration.basePath}/dental-referral/monthly-statistics`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get dental referral statistic
     * get dental referral statistic
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReferralStatistics(requestParameters: GetReferralStatisticsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralStatisticPojo>;
    public getReferralStatistics(requestParameters: GetReferralStatisticsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralStatisticPojo>>;
    public getReferralStatistics(requestParameters: GetReferralStatisticsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralStatisticPojo>>;
    public getReferralStatistics(requestParameters: GetReferralStatisticsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const dentalProfessionalId = requestParameters.dentalProfessionalId;
        const receivingDentalOfficeId = requestParameters.receivingDentalOfficeId;
        const portalAccountId = requestParameters.portalAccountId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dentalProfessionalId !== undefined && dentalProfessionalId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dentalProfessionalId, 'dentalProfessionalId');
        }
        if (receivingDentalOfficeId !== undefined && receivingDentalOfficeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>receivingDentalOfficeId, 'receivingDentalOfficeId');
        }
        if (portalAccountId !== undefined && portalAccountId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccountId, 'portalAccountId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ReferralStatisticPojo>(`${this.configuration.basePath}/dental-referral/statistics`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReferralStatusAndNotes(requestParameters: GetReferralStatusAndNotesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ReferralStatusNotes>>;
    public getReferralStatusAndNotes(requestParameters: GetReferralStatusAndNotesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ReferralStatusNotes>>>;
    public getReferralStatusAndNotes(requestParameters: GetReferralStatusAndNotesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ReferralStatusNotes>>>;
    public getReferralStatusAndNotes(requestParameters: GetReferralStatusAndNotesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const referralRequestId = requestParameters.referralRequestId;
        if (referralRequestId === null || referralRequestId === undefined) {
            throw new Error('Required parameter referralRequestId was null or undefined when calling getReferralStatusAndNotes.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (referralRequestId !== undefined && referralRequestId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>referralRequestId, 'referralRequestId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ReferralStatusNotes>>(`${this.configuration.basePath}/dental-referral/all-referral-status-notes`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSingleReferralData(requestParameters: GetSingleReferralDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralSearchPojo>;
    public getSingleReferralData(requestParameters: GetSingleReferralDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralSearchPojo>>;
    public getSingleReferralData(requestParameters: GetSingleReferralDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralSearchPojo>>;
    public getSingleReferralData(requestParameters: GetSingleReferralDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const referralDataId = requestParameters.referralDataId;
        if (referralDataId === null || referralDataId === undefined) {
            throw new Error('Required parameter referralDataId was null or undefined when calling getSingleReferralData.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (referralDataId !== undefined && referralDataId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>referralDataId, 'referralDataId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ReferralSearchPojo>(`${this.configuration.basePath}/dental-referral/single-referral-data`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSingleReferralDraft(requestParameters: GetSingleReferralDraftRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralDraftPojo>;
    public getSingleReferralDraft(requestParameters: GetSingleReferralDraftRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralDraftPojo>>;
    public getSingleReferralDraft(requestParameters: GetSingleReferralDraftRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralDraftPojo>>;
    public getSingleReferralDraft(requestParameters: GetSingleReferralDraftRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const draftId = requestParameters.draftId;
        if (draftId === null || draftId === undefined) {
            throw new Error('Required parameter draftId was null or undefined when calling getSingleReferralDraft.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ReferralDraftPojo>(`${this.configuration.basePath}/dental-referral/drafts/${encodeURIComponent(String(draftId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search referral status note
     * search referral status note
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatusNote(requestParameters: GetStatusNoteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralStatusNotes>;
    public getStatusNote(requestParameters: GetStatusNoteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralStatusNotes>>;
    public getStatusNote(requestParameters: GetStatusNoteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralStatusNotes>>;
    public getStatusNote(requestParameters: GetStatusNoteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const status = requestParameters.status;
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getStatusNote.');
        }
        const referralRequestId = requestParameters.referralRequestId;
        if (referralRequestId === null || referralRequestId === undefined) {
            throw new Error('Required parameter referralRequestId was null or undefined when calling getStatusNote.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (referralRequestId !== undefined && referralRequestId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>referralRequestId, 'referralRequestId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ReferralStatusNotes>(`${this.configuration.basePath}/dental-referral`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a dental referral request status
     * Call this API to resend a dental referral request
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendReferralNotification(requestParameters: ResendReferralNotificationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public resendReferralNotification(requestParameters: ResendReferralNotificationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public resendReferralNotification(requestParameters: ResendReferralNotificationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public resendReferralNotification(requestParameters: ResendReferralNotificationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const referralRequestId = requestParameters.referralRequestId;
        if (referralRequestId === null || referralRequestId === undefined) {
            throw new Error('Required parameter referralRequestId was null or undefined when calling resendReferralNotification.');
        }
        const type = requestParameters.type;
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling resendReferralNotification.');
        }
        const email = requestParameters.email;
        const phoneNumber = requestParameters.phoneNumber;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>phoneNumber, 'phoneNumber');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/dental-referral/${encodeURIComponent(String(referralRequestId))}/resend-referral-notification`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveReferralDraft(requestParameters: SaveReferralDraftRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralDraft>;
    public saveReferralDraft(requestParameters: SaveReferralDraftRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralDraft>>;
    public saveReferralDraft(requestParameters: SaveReferralDraftRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralDraft>>;
    public saveReferralDraft(requestParameters: SaveReferralDraftRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const referralDraftDto = requestParameters.referralDraftDto;
        if (referralDraftDto === null || referralDraftDto === undefined) {
            throw new Error('Required parameter referralDraftDto was null or undefined when calling saveReferralDraft.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ReferralDraft>(`${this.configuration.basePath}/dental-referral/drafts`,
            referralDraftDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search dental referral history
     * search dental referral history
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDentalReferral(requestParameters: SearchDentalReferralRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsPojoReferralSearchPojo>;
    public searchDentalReferral(requestParameters: SearchDentalReferralRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsPojoReferralSearchPojo>>;
    public searchDentalReferral(requestParameters: SearchDentalReferralRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsPojoReferralSearchPojo>>;
    public searchDentalReferral(requestParameters: SearchDentalReferralRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const filter = requestParameters.filter;
        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling searchDentalReferral.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filter !== undefined && filter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filter, 'filter');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsPojoReferralSearchPojo>(`${this.configuration.basePath}/dental-referral/search`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchReferralDraft(requestParameters: SearchReferralDraftRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsReferralDraft>;
    public searchReferralDraft(requestParameters: SearchReferralDraftRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsReferralDraft>>;
    public searchReferralDraft(requestParameters: SearchReferralDraftRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsReferralDraft>>;
    public searchReferralDraft(requestParameters: SearchReferralDraftRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const filter = requestParameters.filter;
        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling searchReferralDraft.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filter !== undefined && filter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filter, 'filter');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsReferralDraft>(`${this.configuration.basePath}/dental-referral/search-drafts`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchReferralServices(requestParameters: SearchReferralServicesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ReferralReason>>;
    public searchReferralServices(requestParameters: SearchReferralServicesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ReferralReason>>>;
    public searchReferralServices(requestParameters: SearchReferralServicesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ReferralReason>>>;
    public searchReferralServices(requestParameters: SearchReferralServicesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const specializationCode = requestParameters.specializationCode;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (specializationCode) {
            specializationCode.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'specializationCode');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ReferralReason>>(`${this.configuration.basePath}/dental-referral/referral-service`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTemporaryDentalOffice(requestParameters: SearchTemporaryDentalOfficeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<TemporaryDentalOffice>;
    public searchTemporaryDentalOffice(requestParameters: SearchTemporaryDentalOfficeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<TemporaryDentalOffice>>;
    public searchTemporaryDentalOffice(requestParameters: SearchTemporaryDentalOfficeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<TemporaryDentalOffice>>;
    public searchTemporaryDentalOffice(requestParameters: SearchTemporaryDentalOfficeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const officeName = requestParameters.officeName;
        if (officeName === null || officeName === undefined) {
            throw new Error('Required parameter officeName was null or undefined when calling searchTemporaryDentalOffice.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (officeName !== undefined && officeName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>officeName, 'officeName');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<TemporaryDentalOffice>(`${this.configuration.basePath}/dental-referral/temporary-dental-office`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a dental referral request status
     * Call this API to update a dental referral request
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDentalReferralRequestStatus(requestParameters: UpdateDentalReferralRequestStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralRequest>;
    public updateDentalReferralRequestStatus(requestParameters: UpdateDentalReferralRequestStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralRequest>>;
    public updateDentalReferralRequestStatus(requestParameters: UpdateDentalReferralRequestStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralRequest>>;
    public updateDentalReferralRequestStatus(requestParameters: UpdateDentalReferralRequestStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const updateStatusNoteDto = requestParameters.updateStatusNoteDto;
        if (updateStatusNoteDto === null || updateStatusNoteDto === undefined) {
            throw new Error('Required parameter updateStatusNoteDto was null or undefined when calling updateDentalReferralRequestStatus.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ReferralRequest>(`${this.configuration.basePath}/dental-referral/update-referral-status`,
            updateStatusNoteDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatusNotes(requestParameters: UpdateStatusNotesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralStatusNotes>;
    public updateStatusNotes(requestParameters: UpdateStatusNotesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralStatusNotes>>;
    public updateStatusNotes(requestParameters: UpdateStatusNotesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralStatusNotes>>;
    public updateStatusNotes(requestParameters: UpdateStatusNotesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const referralNoteId = requestParameters.referralNoteId;
        if (referralNoteId === null || referralNoteId === undefined) {
            throw new Error('Required parameter referralNoteId was null or undefined when calling updateStatusNotes.');
        }
        const note = requestParameters.note;
        if (note === null || note === undefined) {
            throw new Error('Required parameter note was null or undefined when calling updateStatusNotes.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (referralNoteId !== undefined && referralNoteId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>referralNoteId, 'referralNoteId');
        }
        if (note !== undefined && note !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>note, 'note');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ReferralStatusNotes>(`${this.configuration.basePath}/dental-referral/update-status-notes`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
