import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmFieldValidator } from "dd-shared-lib";
import { EventSubmissionTracking } from "../../../../shared/EventSubmissionTrackingEnum";
import {UniqueIdValidatorService} from "../../../../shared/unique-id-validator.service";

@Component({
  selector: 'app-affiliate-second-signup',
  templateUrl: './affiliate-second-signup.component.html',
  styleUrls: ['./affiliate-second-signup.component.css']
})
export class AffiliateSecondSignupComponent implements OnInit, OnChanges {

  formGroup: FormGroup;
  @Input()
  signUpSubmittingStatus: EventSubmissionTracking;
  @Input()
  affiliateCode: String
  errorSubmission: boolean;
  eventSubmittingStatus = EventSubmissionTracking;

  @Output() nextButtonClicked = new EventEmitter<FormGroup>();
  @Output() previousButtonClicked = new EventEmitter<void>();
  submittingStatus: EventSubmissionTracking;
  currentStep: any;

  constructor(private fb: FormBuilder, private uniqueIdValidatorService: UniqueIdValidatorService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.submittingStatus = this.signUpSubmittingStatus;
  }

  ngOnInit(): void {

    this.formGroup = this.fb.group({
      invitationCode: [this.affiliateCode || '', [Validators.minLength(2), Validators.maxLength(10)]],
      newPassword: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      confirmPassword: ['', Validators.compose([
        Validators.required, Validators.minLength(2), Validators.maxLength(50),
        ConfirmFieldValidator.matchField('newPassword', 'passwordMismatch')
      ])],
      acceptTerms: [false, Validators.requiredTrue],
    });

    this.formGroup.get('invitationCode')?.setAsyncValidators([this.uniqueIdValidatorService.verifier('affiliate', 'affiliateCodeNotFound')]);
  }

  goBack() {
    // Previous logic here
    this.previousButtonClicked.emit();
  }

  onPasswordStrengthMessChanged($event: string) {
    if ($event == 'Poor') {
      this.formGroup.controls['newPassword'].setErrors({ 'notStrong': true });
    } else {
      this.removeErrorFromControl(this.formGroup.controls['newPassword']);
    }
  }

  removeErrorFromControl(control: any) {
    if (control.hasError('notStrong')) {
      delete control.errors['notStrong'];
      control.updateValueAndValidity();
    }
  }

  submittedForError() {
    this.errorSubmission = true;
  }

  moveToNextStep() {
    this.nextButtonClicked.next(this.formGroup);
  }
}
