<div class="">
    <!-- header section  -->
    <section class="header-bg pt-5">
        <div class="container">
            <div class="row p-lg-5 px-lg-0 ps-md-0 d-flex justify-content-between">
                <div class="col-lg-6">
                    <div class="pe-xl-5">
                        <h1 class="header-main-title my-5">
                            Join The Dental<br class="d-sm-none d-block">Door Affiliate Program
                        </h1>
                        <p class="header-main-subtitle pe-xl-5 fs-lg-6 app-download-p">
                            Don’t just tell others about DentalDoor, monetize and earn commissions when you do so. Dental Specialists, Content creators, influencers and platforms can earn commissions and special rewards on every subscription by their referrals.
                        </p>
                    </div>

                    <div class="my-5 d-flex flex-column flex-md-row">
                        <button routerLink="/affiliates/signup" class="btn btn-primary me-md-3 mb-4 mb-md-0 px-5 py-3 " type="button">
                            <div class="d-flex align-items-center justify-content-center">
                            <iconify-icon icon="teenyicons:discount-solid" class="m-0  pe-2" width="24"></iconify-icon>
                            <span class="pointer"> Become an affiliate</span>
                            </div>

                        </button>
                    </div>
                </div>
                <div class="animate__animated animate__pulse col-lg-6 col-md-6 d-flex justify-content-lg-center align-items-center d-md-block d-none">
                    <img src="/assets/img/marketing.svg" class="img-fluid" alt="" />
                </div>
            </div>
        </div>
    </section>

    <section class="doffice-bg steps">
        <div class="container mt-4">
            <div class="mb-5">
                <h3 class="text-light">How Does the DentalDoor Affiliate Work?</h3>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="center">
                        <div class="text">01</div>
                    </div>
                    <div class="mt-3">
                        <h5 class="text-light">Sign up for free</h5>
                    </div>
                    <div class="mt-4">
                        <p class="">Sign up by filling our affiliate form. It is that simple, anyone can become an affiliate.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="center">
                        <div class="text">02</div>
                    </div>
                    <div class="mt-4">
                        <h5 class="text-light">Copy & share your affiliate link</h5>
                    </div>
                    <div class="mt-3">
                        <p class="">When people click on your link they are immediately added to your network. You can track their status right from your dashboard.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="center">
                        <div class="text">03</div>
                    </div>
                    <div class="mt-4">
                        <h5 class="text-light">Earn up to 30% commission</h5>
                    </div>
                    <div class="mt-3">
                        <p class="">When your referrals create an account with your link, you will earn commission on every subscription they make.</p>
                    </div>
                </div>
            </div>
            <div class="">
                <button type="button" name="button" class="btn btn-primary btn-lg rounded-1 px-5 fs-6 mt-5 pointer" routerLink="/affiliates/signup">Get Started</button>
            </div>
        </div>
    </section>


    <section class="mt-5 mt-md-0" id="about">
        <div class="container py-3 py-md-5">
            <div class="row py-md-5 py-3">
                <div class="text-center d-flex justify-content-center">
                    <div class="col-md-8 col-12">
                        <h3 class="chart-h1">
                            Affiliate Program Commission Benefits
                        </h3>
                        <p class="discover-p">
                            Important! Payouts are done on specific days in the month (e.g. 15th and 28th of every month)
                        </p>
                    </div>
                </div>
            </div>
            <div class="container table">
                <div class="row">
                    <div class="col-md-8 col-6 header">
                        <h5 class="text-center ">Action</h5>
                    </div>
                    <div class="col-md-4 col-6 header">
                        <h5 class="text-center">Reward</h5>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-8 col-6 body">
                        <p class="">Get a specialist office to signup and subscribe</p>
                    </div>
                    <div class="col-md-4 col-6 body">
                        <p class="">$100</p>
                    </div>
                </div>

                <div class="row mt-0">
                    <div class="col-md-8 col-6 body">
                        <p class="">Once your invited specialist clocks 1 year on DentalDoor</p>
                    </div>
                    <div class="col-md-4 col-6 body">
                        <p class="">30% of monthly fee, for the life of specialist office subscription</p>
                    </div>
                </div>
                <h6 class="text-center my-4">Primary affiliate</h6>
                <div class="row ">
                    <div class="col-md-8 col-6 body">
                        <p class="">Get a specialist office to signup and subscribe</p>
                    </div>
                    <div class="col-md-4 col-6 body">
                        <p class="">10% of the specialist office monthly subscription</p>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-8 col-6 body">
                        <p class="">Get friends to join your network as an affiliate</p>
                    </div>
                    <div class="col-md-4 col-6 body">
                        <p class="">These friends become your secondary affiliate.</p>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-8 col-6 body">
                        <p class="">When your secondary affiliate signs up a paying specialist office</p>
                    </div>
                    <div class="col-md-4 col-6 body">
                        <p class="">You get $50</p>
                    </div>
                </div>
                <h6 class="text-center my-4">Secondary affiliate</h6>
                <div class="row ">
                    <div class="col-md-8 col-6 body">
                        <p class="">Get a specialist office to signup and subscribe</p>
                    </div>
                    <div class="col-md-4 col-6 body">
                        <p class="">10% of the specialist office monthly subscription</p>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-8 col-6 body">
                        <p class="">Get friends to join your network as an affiliate</p>
                    </div>
                    <div class="col-md-4 col-6 body">
                        <p class="">These friends become your secondary affiliate. You also get all the benefits of a primary affiliate.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="testimonial-bg">
        <div class="container">
            <div class="row d-flex py-5 justify-content-center align-items-center">
                <div class="col-md-6">
                    <img src="/assets/img/faq.svg" class="img-fluid" alt="" />
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <h1 class="h1 text-end">FAQ</h1>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Who is a primary affiliate?
                            </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        An individual who successfully creates an affiliate account becomes a primary affiliate.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed  text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Who is a secondary affiliate?
                            </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Someone who registers as an affiliate through a link from a primary affiliate.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                What do I need to become an affiliate?
                            </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Follow these simple steps to become an affiliate...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Money you will spend for referral pads
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p>
                                    Depending on location, referral pads average anywhere from
                                    <strong class="text-warning">$700</strong> to <strong
                                        class="text-warning">$2000</strong> per year to a specialist office. Having
                                    a
                                    cloud-based referral system like dRefer eliminates that
                                    cost.
                                </p>
                            </div>
                        </div>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="testimonial-bg">
    <div class="container py-5">
        <div class=" accordion-box pb-5 bg-white">
            <div class="row">
                <div class="col-lg-6 pt-5 pb-5">
                    <div class="img-section d-flex justify-content-center align-items-center position-relative pt-3">
                        <div class="woman-div">
                            <img src="/assets/img/faq.svg" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h3 class="text-center fw-bold text-color border-0 faq-text">FAQ</h3>
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="accordion ms-2 me-2 accordion-flush" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header border-0" id="headingOne">
                                    <button class="accordion-button shadow-none bg-white text-color" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        How many people can I invite?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-color">
                                        As many people as you wish to invite.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button
                                        class="accordion-button text-color collapsed shadow-none bg-white text-color"
                                        type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo">
                                        Who is a primary affiliate?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-color">
                                        An individual who successfully creates an affiliate account becomes a primary affiliate.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button
                                        class="accordion-button text-color collapsed shadow-none bg-white text-color"
                                        type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseThree">
                                        Who is a secondary affiliate?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-color">
                                        Someone who registers as an affiliate through a link from a primary affiliate.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button
                                        class="accordion-button text-color collapsed shadow-none bg-white text-color"
                                        type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                        aria-expanded="false" aria-controls="collapseFour">
                                        What do I need to become an affiliate?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-color">
                                        Follow these simple steps to become an affiliate...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

</div>