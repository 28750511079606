import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbCalendar, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {BsModalService} from "ngx-bootstrap/modal";
import {BehaviorSubject} from "rxjs";
import {getOffset, removeUndefinedOrNullFields} from "../../../models/search-model";
import {environment} from "../../../../environments/environment";
import {ActivityLogControllerService, ActivityLogSearchFilter, QueryResultsPojoActivityLog} from "dd-core-api-sdk";
import * as moment from "moment/moment";
import {Utils} from "../../../shared/utils";



@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.css']
})
export class ActivityLogComponent implements OnInit {

  utcOffsetMinutes: string;

  isError: boolean = true;

  searching = true;
  form: FormGroup;

  offset: number;



  resultArray: Array<any>;
  data: ActivityLogSearchFilter;
  _queryResults: QueryResultsPojoActivityLog;

  _offset: number;
  showErrorMessageTrigger = false;
  errMessage = '';

  model: NgbDateStruct;
  model1: NgbDateStruct;
  today = this.calendar.getToday();
  activityTypeEnum = Utils.enumValues(ActivityLogSearchFilter.ActivityTypeEnum);
  activityEnum = ActivityLogSearchFilter.ActivityTypeEnum;
  activityStatusEnum = Utils.enumValues(ActivityLogSearchFilter.ActivityStatusEnum);
  nameForm: FormGroup;
  filterData: ActivityLogSearchFilter;
  resultSubject = new BehaviorSubject<QueryResultsPojoActivityLog>(null);
  limit: number;
  _searching: boolean;
  currentStartDate: any;

  constructor(
    private calendar: NgbCalendar,
    private activityLogService: ActivityLogControllerService,
    private modalService: BsModalService,
    private fb: FormBuilder,

  ) { }

  ngOnInit(): void {


    this.nameForm = this.fb.group({
      userName: [''],
      activityType: [''],
      activityStatus: [''],
      ipAddress: [''],
      startDate: [''],
      endDate: [''],
    });

    this.nameForm.controls.startDate.valueChanges.subscribe(v => {
      this.currentStartDate = v;
      if (v != moment(v).format('YYYY-MM-DD')) {
        this.nameForm.patchValue({endDate: ''});
      }
    });

    this.nameForm.controls.endDate.valueChanges.subscribe(v => {
      if (moment(this.currentStartDate) > moment(v)) {
        this.showErrorMessage('Date(from) should be greater than Date(to).');
        this.nameForm.patchValue({endDate: ''});
      }
    });

    this.searchPage();
  }



  searchPage() {
    console.log("In the filter search")
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);
    this.search(filter);
  }

  search(filter: ActivityLogSearchFilter) {
    this._searching = true;
    this.activityLogService.searchActivityLogs({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this._searching = false;
      this._offset = v.offset;
      this._queryResults = v;
      this.resultArray = v.results!;
      this.resultSubject.next(v);
    }, () => {
      this._searching = false;
    });

    environment.siteUrl
    this.data = filter;
    // this.data.page = this.page;
  }

  getFilter(limit?: number): ActivityLogSearchFilter {
    const data = this.nameForm!.value;

    let filter: ActivityLogSearchFilter = {};
    filter.activityStatus = data.activityStatus;
    filter.userName=data.userName;
    filter.activityType = data.activityType;
    filter.remoteAddress = data.ipAddress;
    filter.startDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }

  onPageChange($event) {
    const searchFilter: ActivityLogSearchFilter = {
      offset: getOffset($event.page, $event.size || 4),
      limit: $event.itemsPerPage,
    };

    this.search(searchFilter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }

  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }


}
