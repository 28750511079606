<div class="" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog m-0" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">{{ 'Resend Notification' }}</h4>
        <button (click)="close()" class="btn">
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
      </div>
      <div class="modal-body">

        <!-- Error Message -->
        <div *ngIf="showErrorMessageTrigger">
          <div
            class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
            role="alert">
            <p class="m-0 p-0 d-flex align-items-start">
              <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                {{ getErrorMessage() | removeUnderscores }}
              </span>
              <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert"></iconify-icon>
            </p>
          </div>
        </div>

        <!-- Form Section -->
        <p class="m-3 text-center">
          {{'Please adjust the details as needed, or click \'Resend\' to re-notify the same details.'}}
        </p>

        <div class="m-3" [formGroup]="resendForm">

          <!-- Buttons for Patient and Specialist Office -->
          <div class="d-flex justify-content-center mb-4">
            <button
              type="button"
              class="btn btn-outline-primary me-2"
              [ngClass]="{ 'active': selectedOption === 'PATIENT' }"
              (click)="selectOption('PATIENT')">
              PATIENT
            </button>
            <button
              *ngIf="referral?.temporaryDentalOfficePojo"
              type="button"
              class="btn btn-outline-primary"
              [ngClass]="{ 'active': selectedOption === 'DENTAL_OFFICE' }"
              (click)="selectOption('DENTAL_OFFICE')">
              Specialist Office
            </button>
          </div>


          <div class="mb-4">
            <label for="office-email-input" class="form-label">Enter Email</label>
            <input
              id="office-email-input"
              class="form-control"
              type="text"
              formControlName="email"
              placeholder="eg. yourofficemail@mail.com"
            />
            <app-field-error
              *ngIf="
                (resendForm.get('email')?.errors?.required && resendForm.get('email')?.touched) ||
                (resendForm.get('email')?.errors?.required && formError)"
              [message]="'Office Email cannot be blank.'">
            </app-field-error>
            <app-field-error
              *ngIf="(resendForm.get('email')?.errors?.pattern && resendForm.get('email')?.touched)"
              [message]="'Invalid email address.'">
            </app-field-error>
            <app-field-error
              *ngIf="
                (resendForm.get('email')?.errors?.maxlength && resendForm.get('email')?.touched) ||
                (resendForm.get('email')?.errors?.maxlength && formError)"
              [message]="'Email cannot be more than fifty(50).'">
            </app-field-error>
            <app-field-error
              *ngIf="
                (resendForm.get('email')?.errors?.notDeliverable && resendForm.get('email')?.touched) ||
                (resendForm.get('email')?.errors?.notDeliverable && formError)"
              [message]="'Please double-check email address'">
            </app-field-error>
          </div>
          <div class="mb-4" *ngIf="selectedOption!='DENTAL_OFFICE'">
            <label class="form-label">Phone Number</label>
            <ngx-intl-tel-input
              [cssClass]="'form-control'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [id]="'phoneNumber'"
              [maxLength]="'20'"
              [phoneValidation]="true"
              [preferredCountries]="preferredCountries"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [searchCountryFlag]="true"
              [selectedCountryISO]="countryISO"
              [selectFirstCountry]="true"
              formControlName="phoneNumber">
            </ngx-intl-tel-input>
            <app-field-error
              *ngIf="
                (resendForm.get('phoneNumber')?.errors?.required && resendForm.get('phoneNumber')?.touched) ||
                (resendForm.get('phoneNumber')?.errors?.required && formError)"
              [message]="'Phone number cannot be blank.'">
            </app-field-error>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary" (click)="submit()">{{ 'Resend' }}</button>
      </div>
    </div>
  </div>
</div>
