import {RouterModule, Routes} from "@angular/router";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {BillingWizardComponent} from "./billing-wizard/billing-wizard.component";
import {BillingListComponent} from "./billing-list/billing-list.component";
import {SubscriptionBillingGuard} from "../../shared/subscription-guard/subscription-billing-guard.service";

const routes: Routes = [
  {
    path: '',
    component: BillingListComponent,
    pathMatch: 'full',
  },
  {
    path: 'purchase-plan',
    component: BillingWizardComponent,
    pathMatch: 'full',
    canActivate: [SubscriptionBillingGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BillingRoutingModule { }
