import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Banks, DentalOfficePojo, QueryResultsPojoReferralSearchPojo} from "dd-core-api-sdk";

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  behaviorSubject: BehaviorSubject<String> = new BehaviorSubject<String>('');
  accountInfoPojo: BehaviorSubject<LoginDetails> = new BehaviorSubject<LoginDetails>(null);
  otpSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  tabSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  isInterceptor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  selectedToothSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  isAdultSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  dentalOfficeSubject: BehaviorSubject<DentalOfficePojo> = new BehaviorSubject<DentalOfficePojo>(null);
  accountCreatedSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  notificationSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  inboundNewCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  reloadListSubject = new BehaviorSubject<boolean>(false);
  kycLinkSubject = new BehaviorSubject<string>('');
  bankCodeSubject = new BehaviorSubject<Banks>(null);
  firstContactSubject = new BehaviorSubject<string>(null);

  save(token: string) {
    this.behaviorSubject.next(token);
  }

  getAccountInformation() {
    if (this.accountInfoPojo?.value?.email == undefined) {
      const accountInfo = localStorage.getItem("ACCOUNT_INFO");
      this.accountInfoPojo.next(JSON.parse(accountInfo));

      return this.accountInfoPojo.value;
    }
    return this.accountInfoPojo.value;
  }

}

export interface LoginDetails {
  email?: string;
  password?: string;
}
