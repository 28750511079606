import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-affiliate-invite-signup',
  templateUrl: './affiliate-invite-signup.component.html',
  styleUrls: ['./affiliate-invite-signup.component.css']
})
export class AffiliateInviteSignupComponent implements OnInit {
  affiliateCode: any;
  constructor(private _activatedRoute: ActivatedRoute) { }
  ngOnInit(): void {

    this._activatedRoute.params.subscribe(param => {
      if (param['code']) {
        this.affiliateCode = param['code'];
        console.log(this.affiliateCode)
      }
    });
  }

}
