import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AffiliateBankPojo, WalletTransactionControllerService} from "dd-core-api-sdk";
import {BsModalService} from "ngx-bootstrap/modal";
import {SuccessModalComponent} from "../../../../../../shared/success-modal/success-modal.component";

@Component({
  selector: 'app-wallet-withdrawal-ii',
  templateUrl: './wallet-withdrawal-ii.component.html',
  styleUrls: ['./wallet-withdrawal-ii.component.css']
})
export class WalletWithdrawalIiComponent implements OnInit {

  @Input()
  affiliateBankPojo: AffiliateBankPojo;
  @Output()
  emitCancelButton = new EventEmitter<any>();

  @Input()
  firstStepValue: any;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  sending: Boolean;

  constructor(
    private modalService: BsModalService,
    private walletTransactionControllerService: WalletTransactionControllerService) {
  }

  ngOnInit(): void {
  }

  createDebitTransaction() {
    this.sending = true;
    this.walletTransactionControllerService.initiateWalletDebitTransaction({walletTransactionDto: {amount: this.firstStepValue?.totalAmountDue}}).subscribe({
      next: (v) => {
        this.emitCancelButton.emit();
        this.statusModal();
        this.sending = false;
      },
      error: (e) => {
        this.isError = true;
        this.sending = false;
        console.log(e)
        this.showErrorMessage(e?.error ? e?.error?.message : 'Couldn\'t Initiate wallet debit transaction. Kindly try again later.');
      },
      complete: () => {
        this.sending = false;
      }
    });
  }

  statusModal() {
    let bsModalRef = this.modalService.show(SuccessModalComponent, {
      initialState: {
        body: 'Wallet transfer has been initiated.',
        affiliate: true,
        buttonText: "Done",
        check: true
      },
      keyboard: false,
      backdrop: true,
      animated: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });
    bsModalRef?.content.onDismissClicked.subscribe(v => {
      this.emitCancelButton.emit();
    })
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

}
