import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommissionLogPojo} from "dd-core-api-sdk";

@Component({
  selector: 'app-more-info-modal',
  templateUrl: './more-info-modal.component.html',
  styleUrls: ['./more-info-modal.component.css']
})
export class MoreInfoModalComponent implements OnInit {

  @Output()
  dismissClicked = new EventEmitter<any>();

  @Output()
  closeClicked = new EventEmitter<any>();

  @Input()
  infoTitle: string;
  @Input()
  commissionLogPojo: CommissionLogPojo;

  subscriptionStatusConstant= CommissionLogPojo.SubscriptionStatusConstantEnum;
  billingCycleConstant= CommissionLogPojo.BillingCycleConstantEnum;

  constructor() { }

  ngOnInit(): void {
  }

}
