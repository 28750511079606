import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SocialService} from "../../../shared/subject/SocialService";
import {InvitesModel} from "../../../shared/invites-model";

@Component({
  selector: 'app-invites-signup',
  templateUrl: './invites-signup.component.html',
  styleUrls: ['./invites-signup.component.css']
})
export class InvitesSignupComponent implements OnInit {

  tabIndex = this.socialService.tabSubject.value;
  type: string;
  inviteId: any;
  email: any;
  mobileNumber: any;
  firstName: any;
  LastName: any;
  payLoad: InvitesModel;

  constructor(private _activatedRoute: ActivatedRoute,
              public socialService: SocialService) {

    this._activatedRoute.queryParams.subscribe(paramMap => {
      this.tabIndex = paramMap?.state;
      this.payLoad = {
        adminLastName: paramMap?.adminLastName,
        adminFirstName: paramMap?.adminFirstName,
        officeName: paramMap?.officeName,
        firstName: paramMap?.firstName,
        lastName: paramMap?.lastName,
        phoneNumber: paramMap?.mobileNumber,
        email: paramMap?.email,
        code: paramMap?.code,
        tabIndex: paramMap?.state
      }
    })

  }

  ngOnInit(): void {
  }

  changeTab(newIndex: number) {
    this.tabIndex = newIndex;
    this.socialService.tabSubject.next(newIndex);
  }

}
