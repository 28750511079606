import {Component, Input, OnInit} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";
import {InsuranceImageCaptureComponent} from "../insurance-image-capture/insurance-image-capture.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {PatientControllerService, PatientFirstContactDto} from "dd-core-api-sdk";
import {SuccessModalComponent} from "../../../shared/success-modal/success-modal.component";
import {Router} from "@angular/router";
import {QuestionModalComponent} from "../../../shared/question-modal/question-modal.component";

@Component({
  selector: 'app-first-contact-iii',
  templateUrl: './first-contact-iii.component.html',
  styleUrls: ['./first-contact-iii.component.css']
})
export class FirstContactIiiComponent implements OnInit {
  form: FormGroup;
  showCaptureImageModal: boolean;
  frontImageBase64: string;
  backImageBase64: string;
  @Input()
  selectedTimeSlot: any[] = [];
  @Input()
  selectedTimeline: number;
  @Input()
  tokenAndCode: any;
  sending: boolean;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  questionData: any;


  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private patientControllerService: PatientControllerService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      insurance: ['']
    });
  }

  captureInsuranceCard() {
    let bsModalRef = this.modalService.show(InsuranceImageCaptureComponent, {
      initialState: {},
      keyboard: false,
      backdrop: true,
      class: 'modal-fullscreen',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.emmitFrontImageBase64.subscribe(v => {
      this.frontImageBase64 = v.imageAsDataUrl;
      bsModalRef.hide();
      this.showQuestioner();
    });
    bsModalRef?.content?.emmitBackImageBase64.subscribe(v => {
      this.backImageBase64 = v.imageAsDataUrl;
      bsModalRef.hide();
    });

    if (this.frontImageBase64 && this.backImageBase64)
      this.showQuestioner();
  }

  showQuestioner() {
    let bsModalRef = this.modalService.show(QuestionModalComponent, {
      initialState: {
        message: 'Comments/Questions?'
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.emitQuestionData.subscribe(v => {
      this.questionData = v;
      bsModalRef.hide();
    });
  }

  saveFirstContactDetails() {
    this.sending = true;
    const timeSlotId = this.selectedTimeSlot.map(function (i) {
      return i.id;
    });
    const patientFirstContactDto: PatientFirstContactDto = {
      insuranceFrontImageBase64: this.frontImageBase64,
      insuranceBackImageBase64: this.backImageBase64,
      referralCode: this.tokenAndCode?.code,
      token: this.tokenAndCode?.token,
      timeOfTheDayIds: timeSlotId,
      timeSlotId: this.selectedTimeline,
      questions: this.questionData
    }

    this.patientControllerService.saveFirstContactDetails({patientFirstContactDto}).subscribe({
      next: (value) => {
        this.sending = false;
        this.successModalResponse();
      },
      error: (err) => {
        this.sending = false;
        this.showErrorMessage(err.error.message ? err.error.message : 'Error in Saving Details. Kindly try again later.');
      },
      complete: () => {
        this.sending = false;
      }
    });
  }

  successModalResponse() {
    let bsModalRef = this.modalService.show(SuccessModalComponent, {
      initialState: {
        body: 'Your response has been saved successfully. Thanks for your time.',
        close: true,
        buttonText: "Close",
        check: true
      },
      keyboard: false,
      backdrop: true,
      animated: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });
    bsModalRef?.content.onDismissClicked.subscribe(v => {
      bsModalRef.hide();
    })
    bsModalRef?.content.onSuccessClicked.subscribe(v => {
      this.router.navigate(['/']);
    })
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }
}
