/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferralRequest } from './referral-request';
import { BwFile } from './bw-file';


export interface ReferralData { 
    preMedRequired?: boolean;
    selectedToothRange?: string;
    dateCreated?: string;
    updatedAt?: string;
    priorityLevel?: ReferralData.PriorityLevelEnum;
    tissueType?: ReferralData.TissueTypeEnum;
    referralReasonNote?: string;
    id?: number;
    referralRequest?: ReferralRequest;
    refferalSummary?: BwFile;
}
export namespace ReferralData {
    export type PriorityLevelEnum = 'NORMAL' | 'SEMI_URGENT' | 'URGENT' | 'EMERGENCY';
    export const PriorityLevelEnum = {
        Normal: 'NORMAL' as PriorityLevelEnum,
        SemiUrgent: 'SEMI_URGENT' as PriorityLevelEnum,
        Urgent: 'URGENT' as PriorityLevelEnum,
        Emergency: 'EMERGENCY' as PriorityLevelEnum
    };
    export type TissueTypeEnum = 'NORMAL' | 'SOFT';
    export const TissueTypeEnum = {
        Normal: 'NORMAL' as TissueTypeEnum,
        Soft: 'SOFT' as TissueTypeEnum
    };
}


