import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AffiliateBankPojo, WalletTransactionControllerService, WalletTransactionStatPojo} from "dd-core-api-sdk";
import {WizardComponent} from "angular-archwizard";

@Component({
  selector: 'app-wallet-withdrawal-wizard',
  templateUrl: './wallet-withdrawal-wizard.component.html',
  styleUrls: ['./wallet-withdrawal-wizard.component.css']
})
export class WalletWithdrawalWizardComponent implements OnInit {

  @Input()
  affiliateBankPojo: AffiliateBankPojo;
  @Output()
  cancelClicked = new EventEmitter<any>();
  walletListCommissionStat: WalletTransactionStatPojo;
  firstStepValue: any;
  @ViewChild(WizardComponent)
  public wizard!: WizardComponent;

  constructor(private walletTransactionControllerService: WalletTransactionControllerService) {
  }

  ngOnInit(): void {
    this.walletTransactionControllerService.totalWalletStatsForAffiliate().subscribe(v => {
      this.walletListCommissionStat = v;
    })
  }

  emitCancelClicked($event: any) {
    this.cancelClicked.emit();
  }

  emitNextClicked($event: any) {
    this.firstStepValue = $event;
    if (this.firstStepValue)
      this.wizard.goToNextStep();
  }
}
