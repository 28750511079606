import {Directive, ElementRef, forwardRef, HostListener, Input, OnChanges, Optional, Renderer2, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[customFormControl]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFormControlDirective),
      multi: true
    }
  ]
})
export class CustomFormControlDirective implements ControlValueAccessor, OnChanges {

  constructor(@Optional() protected formGroupDirective: FormGroupDirective,
              private fb: FormBuilder,
              private renderer: Renderer2,
              private element: ElementRef) {
  }

  @Input() name: string;
  @Input()
  value: any;
  private _registered: boolean;
  private formGroup: FormGroup;

  onChange = (_: any) => {
  };
  onTouched = () => {
  };

  // constructor(@Optional()
  //             @Host()
  //             @SkipSelf()
  //             private parent: ControlContainer, private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document, private cdr: ChangeDetectorRef) {
  //   // const child = document.createElement('input');
  //   // this.renderer.appendChild(this.elementRef.nativeElement, child);
  //   // this.renderer.createElement(this.elementRef.nativeElement, child);
  //   // this.elementRef.nativeElement.
  //   // renderer.setStyle(elementRef.nativeElement, 'backgroundColor', 'gray');
  //
  // }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    // if (!this._registered) {
    //   // dynamically create the form control model on the form group model.
    //   this.formGroup = this.formGroupDirective.form;
    //   this.formGroup.registerControl(this.name, this.fb.control(''));
    //   this._registered = true;
    // }
    //
    // // IMPORTANT - this ties your extended form control to the form control
    // // model on the form group model that we just created above. Take a look
    // // at Angular github source code.
    // super.ngOnChanges(changes);
  }

  @HostListener('input', ['$event.target.value'])
  @HostListener('change', ['$event.target.value'])
  onInput(value): void {
    this.onChange(this.value);
  }

  writeValue(value): void {
    const element = this.element.nativeElement;
    this.onChange(value);
    this.renderer.setProperty(element, 'value', value);
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }
}
