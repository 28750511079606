import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {UserAccountKeycloak} from "../../../../services/UserAccountKeycloak";
import {Constant} from "../../../../shared/constant";
import {Router} from "@angular/router";
import {DashboardControllerService, NewReferralStatisticsPojo} from "dd-core-api-sdk";
import {environment} from "../../../../../environments/environment";
import {BehaviorSubject} from "rxjs";
import {SocialService} from "../../../../shared/subject/SocialService";
import {PwaService} from "../../../../services/pwa-service";
import {SwUpdate} from "@angular/service-worker";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {


  userAccount: UserAccountKeycloak;
  @Input() user: UserKeycloak;
  @Input() notificationTot: any;
  currentRoute: string;
  count: NewReferralStatisticsPojo;
  @Input()
  screenShotEle: any;
  pictureId = new BehaviorSubject<any>({id: ''});
  newInbound: number;

  constructor(private authentication: AuthenticationServiceKeycloak,
              private router: Router,private swUpdate: SwUpdate,
              private socialService: SocialService,
              public pwaService: PwaService,
              private dashboardStat: DashboardControllerService) {
  }

  ngOnInit(): void {
    this.userAccount = AuthenticationServiceKeycloak._currentUserAccount;
    this.router.events.subscribe((url: any) => this.getCurrentUrl(url.url));

    this.getDashboardStat();

    if (AuthenticationServiceKeycloak._currentUserAccount?.otherData?.displayPictureId) {
      this.pictureId.next({id: String(AuthenticationServiceKeycloak?._currentUserAccount?.otherData?.displayPictureId)});
    }

    if (this.user?.dentalProfessional?.displayPictureId) {
      this.pictureId.next({id: String(this.user?.dentalProfessional?.displayPictureId)});
    }


  }

  shouldShowRoute() {
    if (!this.userAccount?.otherData?.specialityOffice) {
      return true;
    }
    return ((this.userAccount?.dentalOfficeSubscription?.subscriptionStatus == "ACTIVE"
        || this.userAccount?.dentalOfficeSubscription?.subscriptionStatus == "TRIAL")
      && this.userAccount?.otherData?.specialityOffice);
  }

  getDashboardStat() {
    this.dashboardStat.getNewReferralStatistics().subscribe(res => {
      this.count = res;
    });
  }

  getFileURL() {
    if (this.pictureId.value.id != null && this.pictureId.value.id != '') {
      return environment.siteUrl + "/files/" + this.pictureId.value.id;
    }
    return "./assets/img/user.png";
  }

  selectAccount(account: UserAccountKeycloak) {
    this.authentication.setCurrentAccount(account);
    sessionStorage.setItem(Constant.ACCOUNT_SELECTED, 'true');
    window.location.replace('/dashboard');
  }

  getCurrentUrl(url: string) {
    if (url != undefined || url != null) {
      this.currentRoute = url;
    }
  }


  logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.authentication.logoutClick(window.location.origin);
  }

  installPwa(): void {
    if (this.pwaService.promptEvent) {
      this.pwaService.promptEvent.prompt();
    }else{
      window.alert("App Already Installed");
    }

  }

}
