/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationContextClassLoader } from './application-context-class-loader';
import { RedirectViewServletContextFilterRegistrations } from './redirect-view-servlet-context-filter-registrations';
import { RedirectViewServletContextJspConfigDescriptor } from './redirect-view-servlet-context-jsp-config-descriptor';
import { RedirectViewServletContextSessionCookieConfig } from './redirect-view-servlet-context-session-cookie-config';
import { RedirectViewServletContextServletRegistrations } from './redirect-view-servlet-context-servlet-registrations';


export interface RedirectViewServletContext { 
    filterRegistrations?: { [key: string]: RedirectViewServletContextFilterRegistrations; };
    sessionCookieConfig?: RedirectViewServletContextSessionCookieConfig;
    initParameterNames?: object;
    servletContextName?: string;
    servletRegistrations?: { [key: string]: RedirectViewServletContextServletRegistrations; };
    sessionTrackingModes?: Set<RedirectViewServletContext.SessionTrackingModesEnum>;
    defaultSessionTrackingModes?: Set<RedirectViewServletContext.DefaultSessionTrackingModesEnum>;
    virtualServerName?: string;
    servlets?: object;
    majorVersion?: number;
    minorVersion?: number;
    effectiveMajorVersion?: number;
    effectiveMinorVersion?: number;
    jspConfigDescriptor?: RedirectViewServletContextJspConfigDescriptor;
    servletNames?: object;
    serverInfo?: string;
    attributeNames?: object;
    contextPath?: string;
    effectiveSessionTrackingModes?: Set<RedirectViewServletContext.EffectiveSessionTrackingModesEnum>;
    classLoader?: ApplicationContextClassLoader;
}
export namespace RedirectViewServletContext {
    export type SessionTrackingModesEnum = 'COOKIE' | 'URL' | 'SSL';
    export const SessionTrackingModesEnum = {
        Cookie: 'COOKIE' as SessionTrackingModesEnum,
        Url: 'URL' as SessionTrackingModesEnum,
        Ssl: 'SSL' as SessionTrackingModesEnum
    };
    export type DefaultSessionTrackingModesEnum = 'COOKIE' | 'URL' | 'SSL';
    export const DefaultSessionTrackingModesEnum = {
        Cookie: 'COOKIE' as DefaultSessionTrackingModesEnum,
        Url: 'URL' as DefaultSessionTrackingModesEnum,
        Ssl: 'SSL' as DefaultSessionTrackingModesEnum
    };
    export type EffectiveSessionTrackingModesEnum = 'COOKIE' | 'URL' | 'SSL';
    export const EffectiveSessionTrackingModesEnum = {
        Cookie: 'COOKIE' as EffectiveSessionTrackingModesEnum,
        Url: 'URL' as EffectiveSessionTrackingModesEnum,
        Ssl: 'SSL' as EffectiveSessionTrackingModesEnum
    };
}


