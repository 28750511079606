import { Injectable } from '@angular/core';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class RandomTrackingIdService {

  constructor() { }

  getRandomTrackingId(){
    return 'DRD_' + moment().format('YYYYMMDDHHmmss') + '_' + moment.now();
  }
}
