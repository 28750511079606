/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserPojo } from './user-pojo';
import { DentalProfessionalPojo } from './dental-professional-pojo';


export interface FavoritePojo { 
    status?: FavoritePojo.StatusEnum;
    dateCreated?: string;
    lastUpdated?: string;
    entityName?: string;
    entityId?: number;
    id?: number;
    createdBy?: UserPojo;
    dentalProfessionalPojo?: DentalProfessionalPojo;
}
export namespace FavoritePojo {
    export type StatusEnum = 'LIKE' | 'UNLIKE' | 'DISLIKE';
    export const StatusEnum = {
        Like: 'LIKE' as StatusEnum,
        Unlike: 'UNLIKE' as StatusEnum,
        Dislike: 'DISLIKE' as StatusEnum
    };
}


