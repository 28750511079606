import {Component, Input, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {FirstContactPojo} from "dd-core-api-sdk";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-insurance-snapshot-modal',
  templateUrl: './insurance-snapshot-modal.component.html',
  styleUrls: ['./insurance-snapshot-modal.component.css']
})
export class InsuranceSnapshotModalComponent implements OnInit {
  @Input()
  firstContactPojo: FirstContactPojo;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void { }

  dismissModal() {
    this.bsModalRef.hide();
  }
  getFileURL(id) {
    return environment.siteUrl + "/files/" + id;
  }

  downloadImages() {
    const imageUrls = [
      environment.siteUrl + "/files/" + this.firstContactPojo?.frontImageId,
      environment.siteUrl + "/files/" + this.firstContactPojo?.backImageId
    ];

    imageUrls.forEach((v, index) =>{
      var a = document.createElement('a');
      a.href = v;
      a.target = '_blank';
      a.download = `image_${index}.jpg`;
      document.body.appendChild(a);
      a.click();
    });

  }
}
