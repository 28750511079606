import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  FirstContactTimeRange,
  FirstContactTimeRangeControllerService,
} from "dd-core-api-sdk";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-first-contact-ii',
  templateUrl: './first-contact-ii.component.html',
  styleUrls: ['./first-contact-ii.component.css']
})
export class FirstContactIiComponent implements OnInit {
  firstContactImage: Array<FirstContactTimeRange>;
  @Output()
  emitSecondStepValues = new EventEmitter<any>();
  selectedTimeline: number;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  showPreference: boolean = true;

  constructor(private fb: FormBuilder,
              private firstContactTimeRangeControllerService: FirstContactTimeRangeControllerService) {
  }

  ngOnInit(): void {
    this.firstContactTimeRangeControllerService.getFirstContactTimeRage().subscribe(v => {
      this.firstContactImage = v;
    });
  }

  select(value: number) {
    this.selectedTimeline = value;
    this.showPreference = false;
  }

  moveToNextStep() {
    if (!this.selectedTimeline) {
      this.showPreference = true;
      this.showErrorMessage('Kindly select an appointment time range or click no preference.');
      return;
    }
    this.emitSecondStepValues.emit(this.selectedTimeline);
  }

  noPreferenceSelected() {
    this.emitSecondStepValues.emit();
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

}
