import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-child-teeth',
  templateUrl: './child-teeth.component.html',
  styleUrls: ['./child-teeth.component.css']
})
export class ChildTeethComponent implements OnInit, OnChanges {

  @Input() editable: boolean;
  @Input() affected: string;
  @Output() outputSelectChildTeeth = new EventEmitter();

  teeth: any = {};
  value: any = {};
  @Input() deselectedTooth: any;

  constructor() { }

  ngOnInit(): void {
    this.initTeeth()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initTeeth()
    if(changes.deselectedTooth) {
      this.deselectTooth(this.deselectedTooth);
    }
  }

  initTeeth() {

    let affectedTeeth = this.affected ? this.affected.split(", ") : [];
    const teethInfo = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
      'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q','R', 'S', 'T']
    for (let i = 0; i < 20; i++){
      this.teeth[teethInfo[i] + '-' + i] = '0';
      if (this.affected) {
        let tooth = affectedTeeth.find(value => value == teethInfo[i]);
        if (tooth) {
          this.teeth[tooth + '-' + teethInfo.indexOf(tooth)] = '0.2';
          this.value['value' + tooth] = tooth;
        }
      }
    }

  }

  clicked(elementId: string) {

    if (!this.editable)
      return;
    let tooth = document.getElementById(elementId);
    let toothId = elementId.split('-')[0];

    if (tooth.getAttribute('opacity') == '0.2') {
      this.value['value' + toothId] = '0';
      this.outputSelectChildTeeth.emit(Object.values(this.value).filter(value => value !== '0'));
      return;
    }
    this.value['value' + toothId] = toothId;
    this.outputSelectChildTeeth.emit(Object.values(this.value).filter(value => value !== '0'));
  }

  deselectTooth(tooth: string) {
    if (this.teeth[tooth]) {
      this.teeth[tooth] = '0';
      this.value['value' + tooth] = '0';
      this.outputSelectChildTeeth.emit(Object.values(this.value).filter(value => value !== '0'));
    }
  }
}
