/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TimeSettingDto { 
    unit: TimeSettingDto.UnitEnum;
    value: number;
    notificationDate?: string;
}
export namespace TimeSettingDto {
    export type UnitEnum = 'MINUTE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH';
    export const UnitEnum = {
        Minute: 'MINUTE' as UnitEnum,
        Hour: 'HOUR' as UnitEnum,
        Day: 'DAY' as UnitEnum,
        Week: 'WEEK' as UnitEnum,
        Month: 'MONTH' as UnitEnum
    };
}


