<div class=" body-cover">

<div class="container text-center">
    <div class="row">
        <div class="col-lg-8 order-lg-1 order-2 mb-4">
            <img src="/assets/img/error-img.svg" class="img-fluid " alt="">
        </div>
        <div class="col order-lg-2 order-1">
            <h1 class="fw-bolder header-text">Oops!</h1>
        </div>
    </div>
    <div class="d-block float-lg-end col-lg-4 error-message-cover">
        <h2 class="error-message">Page not found</h2>
        <p>But don't despair, it's just an opportunity
            to explore the unknown.</p>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center">
        <a routerLink="/" class="btn btn-primary p-3 w-50 wrap">Go to homepage</a>
    </div>

</div>
</div>