/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DentalOfficeSubscriptionHistory { 
    dateCreated?: string;
    amount?: number;
    subscriptionStatus?: DentalOfficeSubscriptionHistory.SubscriptionStatusEnum;
    subscription?: string;
}
export namespace DentalOfficeSubscriptionHistory {
    export type SubscriptionStatusEnum = 'ACTIVE' | 'EXPIRED' | 'PENDING' | 'CANCELLED' | 'TRIAL';
    export const SubscriptionStatusEnum = {
        Active: 'ACTIVE' as SubscriptionStatusEnum,
        Expired: 'EXPIRED' as SubscriptionStatusEnum,
        Pending: 'PENDING' as SubscriptionStatusEnum,
        Cancelled: 'CANCELLED' as SubscriptionStatusEnum,
        Trial: 'TRIAL' as SubscriptionStatusEnum
    };
}


