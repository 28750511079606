/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AppointmentSearchFilter { 
    minutesDuration?: number;
    comment?: string;
    status?: AppointmentSearchFilter.StatusEnum;
    appointmentType?: AppointmentSearchFilter.AppointmentTypeEnum;
    appointmentCategory?: AppointmentSearchFilter.AppointmentCategoryEnum;
    modeOfPayment?: AppointmentSearchFilter.ModeOfPaymentEnum;
    patientName?: string;
    dentalProfessionalName?: string;
    dentalOfficeName?: string;
    dentalOfficeId?: number;
    appointmentStartDate?: string;
    appointmentEndDate?: string;
    requestedSlotStartDate?: string;
    requestedSlotEndDate?: string;
    requestedSlotApprovalStatus?: AppointmentSearchFilter.RequestedSlotApprovalStatusEnum;
    userId?: string;
    dentalProfessionalId?: number;
    filterOpenAppointment?: boolean;
    limit?: number;
    offset?: number;
}
export namespace AppointmentSearchFilter {
    export type StatusEnum = 'PROPOSED' | 'PENDING' | 'BOOKED' | 'ARRIVED' | 'FULFILLED' | 'CANCELLED' | 'NOSHOW' | 'ENTERED_IN_ERROR' | 'CHECKED_IN' | 'WAITLIST';
    export const StatusEnum = {
        Proposed: 'PROPOSED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum,
        Booked: 'BOOKED' as StatusEnum,
        Arrived: 'ARRIVED' as StatusEnum,
        Fulfilled: 'FULFILLED' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum,
        Noshow: 'NOSHOW' as StatusEnum,
        EnteredInError: 'ENTERED_IN_ERROR' as StatusEnum,
        CheckedIn: 'CHECKED_IN' as StatusEnum,
        Waitlist: 'WAITLIST' as StatusEnum
    };
    export type AppointmentTypeEnum = 'CHECKUP' | 'EMERGENCY' | 'FOLLOWUP' | 'ROUTINE' | 'WALKIN';
    export const AppointmentTypeEnum = {
        Checkup: 'CHECKUP' as AppointmentTypeEnum,
        Emergency: 'EMERGENCY' as AppointmentTypeEnum,
        Followup: 'FOLLOWUP' as AppointmentTypeEnum,
        Routine: 'ROUTINE' as AppointmentTypeEnum,
        Walkin: 'WALKIN' as AppointmentTypeEnum
    };
    export type AppointmentCategoryEnum = 'NEW' | 'RESCHEDULED';
    export const AppointmentCategoryEnum = {
        New: 'NEW' as AppointmentCategoryEnum,
        Rescheduled: 'RESCHEDULED' as AppointmentCategoryEnum
    };
    export type ModeOfPaymentEnum = 'SELF' | 'INSURANCE';
    export const ModeOfPaymentEnum = {
        Self: 'SELF' as ModeOfPaymentEnum,
        Insurance: 'INSURANCE' as ModeOfPaymentEnum
    };
    export type RequestedSlotApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const RequestedSlotApprovalStatusEnum = {
        Approved: 'APPROVED' as RequestedSlotApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as RequestedSlotApprovalStatusEnum,
        Revoked: 'REVOKED' as RequestedSlotApprovalStatusEnum,
        Pending: 'PENDING' as RequestedSlotApprovalStatusEnum
    };
}


