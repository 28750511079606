import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpErrorResponse'
})
export class HttpErrorResponsePipe implements PipeTransform {

  transform(httpError: any, defaultErrorMessage: any): any {
    if(httpError && httpError.error && httpError.error.message){
      return httpError.error.message;
    }
    else if(httpError && httpError.message){
      return httpError.message;
  }
  else{
      return defaultErrorMessage;
  }
  }

}
