/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalOfficeInvitationDto } from './dental-office-invitation-dto';


export interface ListDtoDentalOfficeInvitationDtoMessageDeliveryChannelConstant { 
    list: Array<DentalOfficeInvitationDto>;
    batchGroup?: Array<ListDtoDentalOfficeInvitationDtoMessageDeliveryChannelConstant.BatchGroupEnum>;
}
export namespace ListDtoDentalOfficeInvitationDtoMessageDeliveryChannelConstant {
    export type BatchGroupEnum = 'SMS' | 'EMAIL' | 'PHONE_CALL';
    export const BatchGroupEnum = {
        Sms: 'SMS' as BatchGroupEnum,
        Email: 'EMAIL' as BatchGroupEnum,
        PhoneCall: 'PHONE_CALL' as BatchGroupEnum
    };
}


