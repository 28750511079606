<div class="container">
  <div class="row">
    <nav class="navbar sticky-top py-3" style="background-color: #f1f7ff">
      <div class="container-fluid">
        <div class="row w-100">
          <div class="d-flex justify-content-center my-3">
            <div class="col-lg-9 d-flex justify-content-end">
              <button
                class="btn btn-outline-dark me-1"
                (click)="editReferralRequest()"
              >
                Edit referral
              </button>
              <button
                joyrideStep="sendReferral"
                text="Click Send Referral to finish up the flow"
                stepPosition="bottom"
                class="btn btn-primary d-flex align-items-center preview-btn"
                (click)="
                  this.formOneInputs.invalid
                    ? submissionForError()
                    : submitReferralRequest()
                "
              >
                <iconify-icon
                  icon="ant-design:share-alt-outlined"
                  *ngIf="!this.sending"
                  width="25"
                ></iconify-icon>
                <iconify-icon
                  icon="eos-icons:three-dots-loading"
                  *ngIf="this.sending"
                  width="25"
                ></iconify-icon>
                <span class="p-1">Send Referral</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="d-flex justify-content-center">
      <div class="overview-card col-12 col-lg-9 p-4">
        <form class="container" [formGroup]="this.formGroup">
          <div class="row gx-3">
            <div class="d-flex justify-content-center mb-5">
              <div class="col-12 col-xl-6 text-center" style="width: fit-content;">
                <img
                  [src]="getReferralFormImage()"
                  class="img-fluid mt-0 mb-3"
                  width="100"
                  height="auto"
                  alt=""
                />
                <p class="fs-4 fw-bold mb-0">
                  {{ this.currentAccount?.accountName | titlecase }}
                </p>
                <p></p>
                <p class="mb-0 subttle">
                  {{ this.currentAccount?.otherData?.streetAddress }}
                </p>
                <p *ngIf="this.userAccount?.email">
                  <strong> Email: </strong>
                  <span class="subttle">{{ this.userAccount?.email }}</span>
                </p>
                <p></p>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-3">
              <label class="form-label rfr-label">Patient's First Name</label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="First Name"
                aria-label="First Name"
                formControlName="patientFirstName"
              />
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-3">
              <label class="form-label">Patient's Last Name</label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Last Name"
                aria-label="Last Name"
                formControlName="patientLastName"
              />
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-3">
              <label class="form-label">Phone Number</label>
              <ngx-intl-tel-input
                [cssClass]="'form-control form-control-lg'"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [id]="'phoneNumber'"
                [maxLength]="'20'"
                [phoneValidation]="true"
                [preferredCountries]="preferredCountries"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
                [searchCountryFlag]="true"
                [selectedCountryISO]="countryISO"
                [selectFirstCountry]="true"
                formControlName="phoneNumber"
                id="phoneNumber"
              ></ngx-intl-tel-input>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-3">
              <div>
                <label class="form-label">Date of Birth</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="MM/DD/YYYY"
                  aria-label="Date of Birth"
                  formControlName="dateOfBirth"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-3">
              <label class="form-label">Refer From</label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Refer From"
                aria-label="Refer From"
                formControlName="referralFrom"
              />
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-3">
              <label class="form-label">Refer To</label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Refer To"
                aria-label="Refer To"
                formControlName="referralTo"
              />
            </div>
            <div class="col-12 mb-3" *ngIf="this.procedureList?.length > 0">
              <label class="form-label">Reason for Referral</label>
              <div class="col-12 mb-3">
                <span
                  class="badge m-2 btn rounded-0 fw-light text-bg-secondary"
                  *ngFor="let procedure of this.procedureList"
                  >{{ procedure }}</span
                >
              </div>
            </div>
            <div class="col-12 col-xl-4 mb-3">
              <div class="filter-card-bg p-3">
                <div *ngIf="adultTeethCheck; else childTeethComponent">
                  <div *ngIf="!adultTeethCheck; else adultTeethComponent"></div>
                </div>
              </div>
              <div class="row my-4" *ngIf="hasMixedTeethCheck()">
                <div class="col-6 p-0">
                  <button
                    (click)="adultTeethToggle()"
                    [class]="
                                adultTeethCheck
                                  ? 'btn btn-primary preview-btn'
                                  : 'btn'">
                    Adult
                  </button>
                </div>
                <div class="col-6 p-0">
                  <button
                    (click)="adultTeethToggle()"
                    [class]="
                                !adultTeethCheck
                                  ? 'btn btn-primary preview-btn'
                                  : 'btn'">
                    Child
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl mb-3">
              <div>
                <div class="col-12 mb-3">
                  <label for="note" class="form-label">Referral Note</label>
                  <textarea
                    formControlName="referralNotes"
                    class="form-control form-control-lg"
                    id="note"
                    rows="2"
                  ></textarea>
                </div>
                <div class="my-4">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      formControlName="preMedRequired"
                    />
                    <label
                      class="form-check-label mx-1 mt-2"
                      for="inlineCheckbox1"
                      >Pre-med required</label
                    >
                  </div>
                </div>
                <div>
                  <p class="fs-6 fw-bold mb-1">Priority Level</p>
                  <div class="form-check form-check-inline">
                    <div
                      class="row"
                      *ngFor="let pro of priorityEnum; let i = index"
                    >
                      <div class="my-1">
                        <input
                          type="radio"
                          class="form-check-input"
                          formControlName="priorityLevel"
                          [value]="pro"
                        />
                        <label class="form-check-label mx-1 mt-2">
                          {{ pro | removeUnderscores }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" m-2 border-0 row">
                  <div class="card shadow-sm rounded-0 p-3 bg-transparent border-0 col-6 play" *ngFor="let doc of this.fileDocs; let i = index">
                    <label class="filelabel2">
                      <div class="text-center">
                        <img
                          style="width: 40%; height: auto"
                          class="card-img"
                          [src]="getFileURL(doc, this.fileDocs[i].fileId)"
                          [alt]="fileDocs[i].name"
                        />
                      </div>
                      <p
                        class="text-center mb-1 p-1"
                        style="white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;">
                        {{ fileDocs[i].name }}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="text-end">
          <img
            src="/assets/img/watermark.svg"
            class="img-fluid opacity-25"
            width="120"
            height="auto"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
