<footer class="footer-bg pt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-md-4 mb-5">
                <img src="/assets/img/footerLogo.svg" class="img-fluid mb-4" alt="" />
                <p class="footer-address col-md-7 col-8">
                    304 S Jones Blvd #4064, Las Vegas, NV 89107, USA
                </p>
                <div>
                    <ul class="nav">
                        <li class="nav-link px-0">
                            <a href="https://www.facebook.com/dentaldoor" class="nav-item" style="cursor: pointer">
                                <iconify-icon class="fs-4 footer-icon" icon="entypo-social:facebook-with-circle"></iconify-icon>
                            </a>
                        </li>
                        <li class="nav-link">
                            <a href="https://twitter.com/DentalDoor" class="nav-item" style="cursor: pointer">
                                <iconify-icon icon="entypo-social:twitter-with-circle" class="fs-4 footer-icon"></iconify-icon>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-2 my-4">
                <h4 class="text-light border-bottom col-4">About</h4>
                <ul class="p-0 nav-list">
                    <li class="list-group-item my-3 justify-content-between align-items-center">
                        <a href="#about" class="text-decoration-none footer-nav-link">
                            <span class="left me-2"><img src="/assets/img/footerList.png" class="img-fluid" alt=""
              /></span>

                            <span class="marg_left"> About Us</span></a>
                    </li>
                    <li class="list-group-item my-3 justify-content-between align-items-center">
                        <a href="/faq" class="text-decoration-none footer-nav-link">
                            <span class="left me-2">
                <img src="/assets/img/footerList.png" class="img-fluid" alt=""/>
              </span>

                            <span class="marg_left"> FAQs</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="col-md-3 my-4">
                <h4 class="text-light border-bottom col-md-6 col-4">Useful Links</h4>
                <ul class="p-0 nav-list">
                    <li class="list-group-item my-3 justify-content-between align-items-center">
                        <a (click)="goToSignUp('professional')" class="text-decoration-none footer-nav-link" style="cursor: pointer">
                            <span class="left me-2"><img src="/assets/img/footerList.png" class="img-fluid" alt=""/></span>

                            <span class="marg_left"> Dental Professional </span>
                        </a>
                    </li>
                    <li class="list-group-item my-3 justify-content-between align-items-center">
                        <a (click)="goToSignUp('office')" class="text-decoration-none footer-nav-link" style="cursor: pointer">
                            <span class="left me-2"><img src="/assets/img/footerList.png" class="img-fluid" alt=""/></span>

                            <span class="marg_left"> Dental Office</span>
                        </a>
                    </li>
                    <li class="list-group-item my-3 justify-content-between align-items-center">
                        <a (click)="goToAffiliates()" class="text-decoration-none footer-nav-link" style="cursor: pointer">
                            <span class="left me-2"><img src="/assets/img/footerList.png" class="img-fluid" alt=""/></span>

                            <span class="marg_left"> Affiliates</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="col-md-3 my-4">
                <h4 class="text-light border-bottom col-3">Contact</h4>
                <ul class="p-0 nav-list">
                    <li class="list-group-item my-3 justify-content-between align-items-center">
                        <p class="text-decoration-none footer-nav-link">
                            <span class="left me-2"><img src="/assets/img/footerList.png" class="img-fluid" alt=""/></span>

                            <span class="marg_left"><a href="mailto:info@dentaldoor.com"> info@dentaldoor.com</a> </span>
                        </p>
                    </li>
                    <li class="list-group-item my-3 justify-content-between align-items-center">
                        <p class="text-decoration-none footer-nav-link">
                            <span class="left me-2"><img src="/assets/img/footerList.png" class="img-fluid" alt=""
              /></span>

                            <span class="marg_left"><a  href="tel: +1 775 386 2655"> 775 386 2655 </a></span>
                        </p>
                    </li>
                </ul>
            </div>

            <hr class="hr" />

            <div class="d-flex flex-column justify-content-md-between flex-md-row justify-content-center align-items-center mb-4">
                <div class="mb-3 mb-md-0">
                    <ul class="nav nav-list">
                        <li class="nav-link">
                            <a href="/terms-and-conditions" class="nav-item text-decoration-none footer-nav-link">Terms Of Use</a>
                        </li>
                        <li class="nav-link">
                            <a href="/review-privacy" class="nav-item text-decoration-none footer-nav-link">
                Privacy
              </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <p class="copyright mb-0">
                        &copy; Copyright {{copyRightDate()}} <a class="" href="/">DentalDoor</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>