<div *ngIf="showErrorMessageTrigger">
  <div *ngIf="isError" class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
       role="alert">
    <p class="m-0 p-0 d-flex align-items-start">
      <span>
        <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
        {{ getErrorMessage() | removeUnderscores }}
      </span>
      <iconify-icon
        class="iconify fs-4 ms-auto pointer"
        icon="mdi:close"
        data-bs-dismiss="alert"></iconify-icon>
    </p>
  </div>
  <div *ngIf="!isError" class="alert-success alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
       role="alert">
    <p class="m-0 p-0 d-flex align-items-start">
      <span>
        <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
        {{ getErrorMessage() | removeUnderscores }}
      </span>
      <iconify-icon
        class="iconify fs-4 ms-auto pointer"
        icon="mdi:close"
        data-bs-dismiss="alert"></iconify-icon>
    </p>
  </div>
</div>

<div class="">
  <div class="container-fluid">
    <div class="row gy-2">
      <div class="col-12 col-xl-6">
        <div class="container-fluid">
          <form class="row gx-3" [formGroup]="form">
            <div class="col-12 my-3 mb-md-0">
              <label class="form-label required">Office Name </label>
              <input
                type="text"
                class="form-control form-control-lg"
                #officeNameText
                formControlName="officeName"
                autocomplete="on"
              />
              <app-field-error
                *ngIf="(form.get('officeName')?.errors?.required && form.get('officeName')?.touched) || (form.get('officeName')?.errors?.required && errorSubmission)"
                [message]="'Office Name is required'"></app-field-error>
              <app-field-error
                *ngIf="(form.get('officeName')?.errors?.maxlength && form.get('officeName')?.touched) || (form.get('officeName')?.errors?.maxlength && errorSubmission)"
                [message]="'Office Name cannot be more than hundred(100) characters.'"></app-field-error>
            </div>
            <div class="col-12 my-3 mb-md-0">
              <label class="form-label required"> Email Address </label>
              <input
                type="text"
                class="form-control form-control-lg"
                formControlName="email"
                placeholder="someone@example.com"
              />
              <app-field-error
                *ngIf="(this.form.get('email').errors?.pattern && form.get('email')?.touched)"
                [message]="'Invalid email address pattern.'"></app-field-error>
              <app-field-error
                *ngIf="(this.form.get('email').hasError('emailExists') && form.get('email')?.touched)"
                [message]="'Email already exists.'"></app-field-error>
              <app-field-error *ngIf="(this.form.get('email').errors?.notDeliverable && form.get('email')?.touched)"
                               [message]="'Please double-check email address'"></app-field-error>

            </div>
            <div class="col-12 my-3 mb-md-0">
              <label class="form-label">Fax </label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="(02) 9999 9999"
                formControlName="fax"
              />
              <app-field-error
                *ngIf="(this.form.get('fax').errors?.pattern && form.get('fax')?.touched)"
                [message]="'Invalid fax number pattern.'"></app-field-error>
              <app-field-error
                *ngIf="(form.get('fax')?.errors?.maxlength && form.get('fax')?.touched) || (form.get('fax')?.errors?.maxlength && errorSubmission)"
                [message]="'Fax cannot be more than hundred(100) characters.'"></app-field-error>

              <app-field-error
                *ngIf="(form.get('fax')?.errors?.minlength && form.get('fax')?.touched) || (form.get('fax')?.errors?.minlength && errorSubmission)"
                [message]="'Fax cannot be less than two(2) characters.'"></app-field-error>

            </div>
            <div class="col-12 my-3 mb-md-0">
              <label class="form-label">Phone Number </label>
              <ngx-intl-tel-input  [cssClass]="'form-control form-control-lg fresh'"
                                   [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [id]="'phoneNumber'"
                                   [maxLength]="'20'" [phoneValidation]="true" [preferredCountries]="preferredCountries"
                                   [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                   [searchCountryFlag]="false"
                                   [onlyCountries]="['us']"
                                   [selectedCountryISO]="countryISO"
                                   [selectFirstCountry]="true"
                                   formControlName="phoneNumber"></ngx-intl-tel-input>
              <app-field-error
                *ngIf="(form.get('phoneNumber')?.errors?.required && form.get('phoneNumber')?.touched) || (form.get('phoneNumber')?.errors?.required && errorSubmission)"
                [message]="'Phone number cannot be blank.'"></app-field-error>

              <app-field-error class="mt-1" [message]="'Invalid phone number format.'" *ngIf="form.get('phoneNumber')!.errors?.validatePhoneNumber || form.get('phoneNumber')!.errors?.invalidPhoneNumber"></app-field-error>
            </div>

            <div class="col-12 my-3 mb-md-0">
              <label for="inputEmail5" class="form-label">Contact Person </label>
              <input type="text" class="form-control form-control-lg"
                     placeholder="Jane Doe"
                     id="inputEmail5" formControlName="adminName"/>
              <app-field-error
                *ngIf="(form.get('adminName')?.errors?.maxlength && form.get('adminName')?.touched) || (form.get('adminName')?.errors?.maxlength && errorSubmission)"
                [message]="'Admin Name cannot be more than fifty(50) characters.'"></app-field-error>
              <app-field-error
                *ngIf="(form.get('adminName')?.errors?.minlength && form.get('adminName')?.touched) || (form.get('adminName')?.errors?.minlength && errorSubmission)"
                [message]="'Admin Name cannot be less than two(2) characters.'"></app-field-error>
            </div>
            <br>
            <button [disabled]="working" class="btn btn-primary text-center col mt-5 mb-4 mx-2"
                    (click)="this.form.valid ? addNewDentalOffice(): submittedForError()">
              <span *ngIf="working" class="fa fa-spinner fa-spin"></span>
              <span *ngIf="!working">+</span>
              Add Office
            </button>
          </form>
        </div>
      </div>
      <div class="col-lg-6  col-sm-12" *ngIf="false">
        <div class="my-3">
          <h6 class="mb-0 p-3 text-light" style="background-color: #02183d">
            Pending Invites
            <i style="font-size: 0.7rem; color: white" *ngIf="subscriptionType && subscriptionType === 'Pro' && invites$">
              number of invites: <b>{{inviteResult.results.length}}</b> of 5
            </i>
          </h6>
          <ng-container *ngIf="invites$ | async as coupons else loading">
            <div class="card border-0 shadow-sm m-3 hover-card col-md-12 " *ngFor="let dp of inviteResult.results">
              <div class="card-header d-flex bg-transparent row border-0">
                <div class="one col-md-3">
                  <img class="img-fluid rounded-3"
                       width="80" src="assets/img/office-avatar.jpg" alt="">
                </div>
                <div class="two col-md-8 p-2 ms-1" >
                  <div class="d-flex align-items-baseline justify-content-between">
                    <span *ngIf="dp.invitedUser" class="added">Added Dental Office</span>
                    <span *ngIf="!dp.invitedUser" class="invited">Invited Dental Office</span>
                  </div>
                  <div class="d-flex" *ngIf="dp.dentalOfficeName">
                    <div>Name: {{dp.dentalOfficeName | titlecase}}</div>
                  </div>
                  <div class="d-flex" *ngIf="dp.email">
                    <div>Email: {{dp.email}}</div>
                  </div>
                  <div class="d-flex" *ngIf="dp.phoneNumber">
                    <div>Phone: {{dp.phoneNumber}}</div>
                  </div>
                  <div class="d-flex" *ngIf="dp.fax">
                    <div>Fax: {{dp.fax | titlecase}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <pagination (pageChanged)="onPageChange($event)" *ngIf="inviteResult && inviteResult.total > 0"
                    [boundaryLinks]="true" [itemsPerPage]="4" [totalItems]="inviteResult.total"
                    previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
        </pagination>
      </div>
    </div>
  </div>
</div>
<div>
  <ul>
    <li>
      <small>
        {{'* All dental offices invited would be added to your connections when they signup.'}}
      </small>
    </li>
  </ul>
</div>
<ng-template #loading>
  <loader></loader>
</ng-template>
