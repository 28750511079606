/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationChannelPojo { 
    channel?: NotificationChannelPojo.ChannelEnum;
    status?: NotificationChannelPojo.StatusEnum;
    dateCreated?: string;
    lastUpdated?: string;
    messageTemplate?: string;
    id?: number;
}
export namespace NotificationChannelPojo {
    export type ChannelEnum = 'SMS' | 'EMAIL' | 'PHONE_CALL';
    export const ChannelEnum = {
        Sms: 'SMS' as ChannelEnum,
        Email: 'EMAIL' as ChannelEnum,
        PhoneCall: 'PHONE_CALL' as ChannelEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


