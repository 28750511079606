import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PhoneNumberValidator} from "dd-shared-lib";
import {ActivatedRoute, Router} from "@angular/router";
import {UniqueIdValidatorService} from "../../../shared/unique-id-validator.service";
import {UserControllerService, UserRegistrationService} from "dd-core-api-sdk";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {AccountKeycloak} from "../../../services/AccountKeycloak";
import {UserAccountKeycloak} from "../../../services/UserAccountKeycloak";

@Component({
  selector: 'app-update-social-profile',
  templateUrl: './update-social-profile.component.html',
  styleUrls: ['./update-social-profile.component.css']
})
export class UpdateSocialProfileComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  errorSubmission: boolean;
  userId: any;
  sending: boolean;
  user: UserKeycloak;
  selectedCountries: any;
  account: UserAccountKeycloak[];

  profession: Array<any> = [
    { name: 'General Dentist', value: 'General Dentist' },
    { name: 'Endodontist', value: 'Endodontist' },
    { name: 'Orthodontist', value: 'Orthodontist' },
    { name: 'Periodontist', value: 'Periodontist' },
    { name: 'Prediatric Dentist', value: 'Prediatric Dentist' },
    { name: 'Oral Surgeon', value: 'Oral Surgeon' },
    { name: 'Dental Hygenist', value: 'Dental Hygenist' },
    { name: 'Dental Assistant', value: 'Dental Assistant' },
    { name: 'Dental Assistant', value: 'Dental Assistant' },
    { name: 'Student', value: 'Student' },
    { name: 'Manager', value: 'Manager' }
  ];

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private uniqueIdValidatorService: UniqueIdValidatorService,
              private userRegistration: UserRegistrationService,
              private authService: AuthenticationServiceKeycloak) {

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: [''],
      lastName: [''],
      selectedProfession:  new FormArray([]),
      phoneNumber: ['', [Validators.required, PhoneNumberValidator()]],
    });

    this.form.get('phoneNumber')?.setAsyncValidators([this.uniqueIdValidatorService.phoneNumberVerifier()]);
  }

  submittedForError() {
    this.errorSubmission = true;
  }

  updateAccountAndSendOTP() {
    console.log(this.form)
    this.sending = true;
    const phoneNumber = this.form.getRawValue().phoneNumber.e164Number;

    console.log(this.form.getRawValue().phoneNumber)
    const encryptedPhoneNumber = btoa(phoneNumber);

    this.userRegistration.updateAccountInfo({userId: this.userId, phoneNumber: phoneNumber,specializations: this.selectedCountries.value}).subscribe(res => {
      this.router.navigate(['/otp/' + encryptedPhoneNumber + '/' + this.userId])
      this.sending = false;
    }, error => {
      this.sending = false;
    })
  }

  onCheckboxChange(event: any) {

     this.selectedCountries = (this.form.controls['selectedProfession'] as FormArray);
    if (event.target.checked) {
      this.selectedCountries.push(new FormControl(event.target.value));
      console.log(this.selectedCountries);
    } else {
      const index = this.selectedCountries.controls
        .findIndex(x => x.value === event.target.value);
      this.selectedCountries.removeAt(index);
    }
  }

  ngAfterViewInit(): void {
    this.authService.fetchUser().subscribe(res => {
      this.user = res;
      this.userId = res.userId;
      this.account = res.accounts;
      this.form.get('firstName').setValue(res.firstName);
      this.form.get('firstName').disable();
      this.form.get('lastName').setValue(res.lastName);
      this.form.get('lastName').disable();
    })

  };



}
