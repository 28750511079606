/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface ActivityLog { 
    recordId?: string;
    dateCreated?: string;
    remoteAddress?: string;
    action?: string;
    description?: string;
    actor?: string;
    entityName?: string;
    actorType?: string;
    status?: ActivityLog.StatusEnum;
    activityStatus?: ActivityLog.ActivityStatusEnum;
    id?: number;
    recipient?: PortalUser;
    createdBy?: PortalUser;
    updatedBy?: PortalUser;
}
export namespace ActivityLog {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type ActivityStatusEnum = 'FAILED' | 'PENDING' | 'SUCCESSFUL';
    export const ActivityStatusEnum = {
        Failed: 'FAILED' as ActivityStatusEnum,
        Pending: 'PENDING' as ActivityStatusEnum,
        Successful: 'SUCCESSFUL' as ActivityStatusEnum
    };
}


