import {Component, OnInit} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {BwFileControllerService} from "dd-core-api-sdk";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  loading: boolean;
  imageId: number;
  user: UserKeycloak;

  constructor(
    private authenticationService: AuthenticationServiceKeycloak,
    private bwFileService: BwFileControllerService,
  ) {
  }

  ngOnInit(): void {
    this.loading = true
    this.authenticationService.getUser().subscribe(user => {
      this.user = user;
      this.loading = false
    })
  }

  updateImage(payload) {
    this.bwFileService.uploadFile({uploadFileDto: payload})
      .subscribe(res => {
        this.imageId = res.fileId;
      })
  }
}
