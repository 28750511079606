import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../pipe/pipes.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
// import {TranslateModule} from '@ngx-translate/core';
import {JoyrideModule} from 'ngx-joyride';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    // TranslateModule,
    JoyrideModule,
  ],
  exports:[
  ]
})
export class AccountSwitchingModule { }
