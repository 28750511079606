/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ReferralAssignmentPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AssignReferralRequestToDentalProfessionalRequestParams {
    referralId: number;
    dentalProfId: number;
}

export interface UpdateReferralAssignmentStatusRequestParams {
    referralAssignmentId: number;
    assignmentStatus: 'ACCEPTED' | 'DECLINED' | 'PENDING';
}


@Injectable({
  providedIn: 'root'
})
export class DentalOfficeReferralAssignmentControllerService {

    protected basePath = 'http://demo.dentaldoor.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * This API is used to assign a referral request.
     * This API is used to assign a referral request to a dental professional by a dental office
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignReferralRequestToDentalProfessional(requestParameters: AssignReferralRequestToDentalProfessionalRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralAssignmentPojo>;
    public assignReferralRequestToDentalProfessional(requestParameters: AssignReferralRequestToDentalProfessionalRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralAssignmentPojo>>;
    public assignReferralRequestToDentalProfessional(requestParameters: AssignReferralRequestToDentalProfessionalRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralAssignmentPojo>>;
    public assignReferralRequestToDentalProfessional(requestParameters: AssignReferralRequestToDentalProfessionalRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const referralId = requestParameters.referralId;
        if (referralId === null || referralId === undefined) {
            throw new Error('Required parameter referralId was null or undefined when calling assignReferralRequestToDentalProfessional.');
        }
        const dentalProfId = requestParameters.dentalProfId;
        if (dentalProfId === null || dentalProfId === undefined) {
            throw new Error('Required parameter dentalProfId was null or undefined when calling assignReferralRequestToDentalProfessional.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (referralId !== undefined && referralId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>referralId, 'referralId');
        }
        if (dentalProfId !== undefined && dentalProfId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dentalProfId, 'dentalProfId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ReferralAssignmentPojo>(`${this.configuration.basePath}/dental-referral-assignment/create`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This API is used update the status of a referral assignment
     * This API is used update the status of a referral assignment.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReferralAssignmentStatus(requestParameters: UpdateReferralAssignmentStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ReferralAssignmentPojo>;
    public updateReferralAssignmentStatus(requestParameters: UpdateReferralAssignmentStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ReferralAssignmentPojo>>;
    public updateReferralAssignmentStatus(requestParameters: UpdateReferralAssignmentStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ReferralAssignmentPojo>>;
    public updateReferralAssignmentStatus(requestParameters: UpdateReferralAssignmentStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const referralAssignmentId = requestParameters.referralAssignmentId;
        if (referralAssignmentId === null || referralAssignmentId === undefined) {
            throw new Error('Required parameter referralAssignmentId was null or undefined when calling updateReferralAssignmentStatus.');
        }
        const assignmentStatus = requestParameters.assignmentStatus;
        if (assignmentStatus === null || assignmentStatus === undefined) {
            throw new Error('Required parameter assignmentStatus was null or undefined when calling updateReferralAssignmentStatus.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (referralAssignmentId !== undefined && referralAssignmentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>referralAssignmentId, 'referralAssignmentId');
        }
        if (assignmentStatus !== undefined && assignmentStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignmentStatus, 'assignmentStatus');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ReferralAssignmentPojo>(`${this.configuration.basePath}/dental-referral-assignment/update`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
