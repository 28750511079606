import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {
  BwFileControllerService,
  DentalOfficeProfilePojo,
  DentalProfessionalControllerService,
  DentalProfessionalPojo,
  DentalServiceProvided,
  PortalAccountMemberRole,
  PortalUser,
  Specialization,
  UpdateDentalProfessionalRequestParams,
  UploadFileDto,
  UserControllerService
} from "dd-core-api-sdk";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {SuccessModalComponent} from "../../../shared/success-modal/success-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";

type tab = 'overview' | 'profile' | 'forgotPassword'

@Component({
  selector: 'app-professional-profile',
  templateUrl: './professional-profile.component.html',
  styleUrls: ['./professional-profile.component.css']
})
export class ProfessionalProfileComponent implements OnInit {

  @Input() user: UserKeycloak;
  @Output() update: EventEmitter<UploadFileDto> = new EventEmitter<UploadFileDto>()


  officeInfoForm: FormGroup;
  professional: any;
  profileImageUrl: any;
  imageUploadId: any;
  imageForm: FormGroup;
  fileData: any;
  isError: boolean;
  offset = 0;
  messageType: 'successful' | 'error';
  message: string;
  errorSubmission: boolean;
  tabSwitch: tab = 'overview';

  loading: boolean;
  updateLoading: boolean;
  profileUpdated: boolean;
  accountId: number;
  office: DentalOfficeProfilePojo;
  servicesProvides: Array<DentalServiceProvided>;
  specialityCount = {};

  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  phoneNumber: string;
  specializations: Array<Specialization>;
  specializationNames: string[];
  filteredSpecialization: Specialization[];
  errMessage: any;
  showErrorMessageTrigger: boolean;
  isProcessing: boolean;
  formattedValue: any[];
  showPasswordTrigger: boolean;
  passwordErrorMessage: any;

  constructor(
    private professionalService: DentalProfessionalControllerService,
    private bwFileService: BwFileControllerService,
    private userController: UserControllerService,
    private sanitizer: DomSanitizer,
    private _fb: FormBuilder,
    private modalService: BsModalService,
  ) {
  }

  ngOnInit(): void {

    this.officeInfoForm = this._fb.group({
      firstName: [{value: this.user?.firstName || '', disabled: this.user?.firstName}],
      lastName: [{value: this.user?.lastName || '', disabled: this.user?.lastName}],
      email: [{value: this.user?.email || '', disabled: this.user?.email}],
      phoneNumber: [this.user?.mobileNumber || ''],
      fbUrl: [this.user?.dentalProfessional?.facebookUrl || ''],
      twitterUrl: [this.user?.dentalProfessional?.twitterUrl || ''],
      profSpecialization: ['']
    });

    this.professional = this.user?.dentalProfessional;
    this.imageUploadId = this.user?.displayPictureId;

    const dentalProf = this.user?.dentalProfessional?.specializations.map(function (i) {
      return i.name;
    });
    this.officeInfoForm.patchValue({profSpecialization: dentalProf});

    if (this.imageUploadId) {
      this.getProfileImage(this.imageUploadId)
    }
    this.imageForm = this._fb.group({
      profileImage: ['']
    })

    // Get the list of dental professional specializations
    this.getListOfSpecializations();
  }

  selectTab(tab) {
    this.tabSwitch = tab;
  }

  getSpecializations(): string {
    return this.professional
      .specializations
      .map(value => value.name)
      .join(", ");
  }

  updateImage(event) {
    let f = event.target.files[0];
    if (f.size > 1024 * 1024 * 10) {
      return;
    }

    const validExtensions = ["image/jpg", "image/jpeg", "image/png",];
    const isValidExtension = validExtensions.indexOf(f.type) > -1;

    if (!isValidExtension) {
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        this.profileImageUrl = reader.result
        this.uploadProfileImage(reader.result, f.type, f.name)
      }
    }
  }

  uploadProfileImage(file: any, contentType: string, fileName: string) {
    this.professionalService.updateProfileImage({
      profId: this.professional?.id,
      uploadFileDto: {
        file, contentType, fileName
      }
    }).subscribe(res => {
      console.log(res);
    })
  }

  getProfileImage(id: number) {
    this.bwFileService.getIdFileByte(({id: id}))
      .subscribe(file => {
        this.fileData = file.data;
        let objectURL = 'data:image/jpeg;base64,' + this.fileData
        this.profileImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      })
  }

  updateProfile() {

    this.isProcessing = true;
    this.userController.updateDentalProfessional(this.buildUpdateProfessionalPayload()).subscribe(res => {
      this.isError = false;
      this.isProcessing = false;
      this.showErrorMessage("Profile Updated successfully.");
    }, error => {
      this.isProcessing = false;
      this.isError = false;
      this.showErrorMessage("Error in updating profile.");
    })
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }


  buildUpdateProfessionalPayload(): UpdateDentalProfessionalRequestParams {
    const formData = this.officeInfoForm.getRawValue();
    console.log(formData)
    if (formData.profSpecialization != "") {
      this.formattedValue = formData.profSpecialization.map(function (i) {
        return i.value;
      });
    }
    return {
      updateDentalProfessionalDto: {
        specializations: this.formattedValue,
        phoneNumber: formData?.phoneNumber?.internationalNumber,
        facebookUrl: formData?.fbUrl,
        twitterUrl: formData?.twitterUrl
      }
    }
  }

  getListOfSpecializations() {
    this.professionalService.getListOfSpecializations().subscribe(res => {
      this.specializations = res;
      this.specializationNames = res.map(function (i) {
        return i.name;
      });
    })

  }

  showPasswordMessage() {
    return this.passwordErrorMessage;
  }

  showPasswordError(error: any) {
    this.passwordErrorMessage = error;
    this.showPasswordTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showPasswordTrigger = false;
    }, 4000);
  }

  passwordError($event: any) {
    this.isError = true;
    this.showPasswordError($event);
  }

  passwordChangeSuccess($event: any) {
    this.successModalResponse();
  }

  successModalResponse() {
    let bsModalRef = this.modalService.show(SuccessModalComponent, {
      initialState: {
        body: 'Password has been updated successfully.',
        link: '/accounts',
        buttonText: "Close",
        check: true,
        close: true
      },
      keyboard: false,
      backdrop: true,
      animated: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });
    bsModalRef?.content.onDismissClicked.subscribe(v => {
      bsModalRef.hide();
    })
    bsModalRef?.content.onSuccessClicked.subscribe(v => {
      window.location.reload();
    })
  }

}
