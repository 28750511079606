/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CommissionLogPojo { 
    dateCreated?: string;
    name?: string;
    subscriptionStatusConstant?: CommissionLogPojo.SubscriptionStatusConstantEnum;
    paidCommissionMonths?: number;
    amountPaid?: number;
    subscriptionDatePaid?: string;
    dueCommission?: string;
    commissionTypeConstant?: CommissionLogPojo.CommissionTypeConstantEnum;
    rewardStatusConstant?: CommissionLogPojo.RewardStatusConstantEnum;
    commissionLogId?: number;
    checked?: boolean;
    email?: string;
    phoneNumber?: string;
    address?: string;
    billingCycleConstant?: CommissionLogPojo.BillingCycleConstantEnum;
}
export namespace CommissionLogPojo {
    export type SubscriptionStatusConstantEnum = 'ACTIVE' | 'EXPIRED' | 'PENDING' | 'CANCELLED' | 'TRIAL';
    export const SubscriptionStatusConstantEnum = {
        Active: 'ACTIVE' as SubscriptionStatusConstantEnum,
        Expired: 'EXPIRED' as SubscriptionStatusConstantEnum,
        Pending: 'PENDING' as SubscriptionStatusConstantEnum,
        Cancelled: 'CANCELLED' as SubscriptionStatusConstantEnum,
        Trial: 'TRIAL' as SubscriptionStatusConstantEnum
    };
    export type CommissionTypeConstantEnum = 'PRIMARY_AFFILIATE_COMMISSION' | 'SECONDARY_AFFILIATE_COMMISSION';
    export const CommissionTypeConstantEnum = {
        PrimaryAffiliateCommission: 'PRIMARY_AFFILIATE_COMMISSION' as CommissionTypeConstantEnum,
        SecondaryAffiliateCommission: 'SECONDARY_AFFILIATE_COMMISSION' as CommissionTypeConstantEnum
    };
    export type RewardStatusConstantEnum = 'AWARDED' | 'PENDING' | 'REVOKED' | 'PROCESSING';
    export const RewardStatusConstantEnum = {
        Awarded: 'AWARDED' as RewardStatusConstantEnum,
        Pending: 'PENDING' as RewardStatusConstantEnum,
        Revoked: 'REVOKED' as RewardStatusConstantEnum,
        Processing: 'PROCESSING' as RewardStatusConstantEnum
    };
    export type BillingCycleConstantEnum = 'MONTHLY' | 'ANNUAL' | 'QUARTERLY' | 'SEMI_ANNUAL';
    export const BillingCycleConstantEnum = {
        Monthly: 'MONTHLY' as BillingCycleConstantEnum,
        Annual: 'ANNUAL' as BillingCycleConstantEnum,
        Quarterly: 'QUARTERLY' as BillingCycleConstantEnum,
        SemiAnnual: 'SEMI_ANNUAL' as BillingCycleConstantEnum
    };
}


