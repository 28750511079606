<div class="modal-content">
    <span class="position-absolute top-100 start-50 translate-middle badge rounded-circle p-1 bg-danger pointer" data-bs-dismiss="modal" aria-label="Close" (click)="cancelModal()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em"
                       height="2em" viewBox="0 0 24 24">
            <path fill="#ddd"
                  d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/>
          </svg></span>
    <div class="modal-body">
        <div class="ratio ratio-16x9" *ngIf="isIntroduction">
            <iframe [src]="videoz" title="Video" allowfullscreen></iframe>
        </div>
        <div class="ratio ratio-16x9" *ngIf="!isIntroduction">
          <iframe src="https://player.vimeo.com/video/807755063?h=8956d521be&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Why was I referred"></iframe>
        </div>
    </div>
</div>
