import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {InvitationControllerService, InviteSearchFilter} from "dd-core-api-sdk";
import InvitationStatusEnum = InviteSearchFilter.InvitationStatusEnum;
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../services/UserKeycloak";

@Component({
  selector: 'app-invite-processing',
  templateUrl: './invite-processing.component.html',
  styleUrls: ['./invite-processing.component.css']
})
export class InviteProcessingComponent implements OnInit {

  @Input()
  inviteId: number;

  @Input()
  dentalOfficeId: number;

  @Input()
  isDecline: boolean = false;

  @Input()
  message: string;

  @Output()
  successEvent = new EventEmitter<any>();

  @Output()
  errorEvent = new EventEmitter<any>();

  @Output()
  dismissClicked = new EventEmitter<any>();

  form: FormGroup;
  sending: boolean;
  user: UserKeycloak;

  constructor(private fb: FormBuilder,
              private authService: AuthenticationServiceKeycloak,
              private invitesController: InvitationControllerService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      reasonNote: ['']
    });

    this.authService.getUser().subscribe(v => {
      this.user = v
    });
  }

  processInvites() {
    this.sending = true;

    if (this.user.dentalProfessional != null) {
      this.invitesController.processDentalProfessionalInvites({
        dentalOfficeId: this.dentalOfficeId,
        inviteId: this.inviteId,
        inviteStatus: this.isDecline ? InvitationStatusEnum.Declined : InvitationStatusEnum.Accepted,
        reasonForDisApproval: this.form.getRawValue().reasonNote
      }).subscribe(res => {
        this.sending = false;
        this.successEvent.emit();
      }, error => {
        this.sending = false;
        this.errorEvent.emit();
      });
    } else {
      this.invitesController.processDentalOfficeInvites({
        dentalOfficeId: this.dentalOfficeId,
        inviteId: this.inviteId,
        inviteStatus: this.isDecline ? InvitationStatusEnum.Declined : InvitationStatusEnum.Accepted,
        reasonForDisApproval: this.form.getRawValue().reasonNote
      }).subscribe(res => {
        this.sending = false;
        this.successEvent.emit();
      }, error => {
        this.sending = false;
        this.errorEvent.emit();
      });
    }

  }
}
