<div class="custom-card p-0">
  <div class="container">
    <aw-wizard [navBarLayout]="null" [defaultStepIndex]="0">
      <aw-wizard-step>
        <app-plans-and-pricing [dentalOffice]="dentalOffice" (subscriptionCompleted)="emmitValues($event)"></app-plans-and-pricing>
      </aw-wizard-step>
      <aw-wizard-step>
        <app-checkout [value]="this.valuesFromSub" [dentalOffice]="dentalOffice"  (nextClicked)="goToNextStep($event)"></app-checkout>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</div>
