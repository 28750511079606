/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalOffice } from './dental-office';
import { PromotionRedemption } from './promotion-redemption';
import { PortalUser } from './portal-user';


export interface RedemptionOverrideRequest { 
    dateCreated?: string;
    reason?: string;
    dateProcessed?: string;
    approvalStatus?: RedemptionOverrideRequest.ApprovalStatusEnum;
    status?: RedemptionOverrideRequest.StatusEnum;
    id?: number;
    promotionRedemption?: PromotionRedemption;
    createdBy?: PortalUser;
    dentalOffice?: DentalOffice;
    processedBy?: PortalUser;
}
export namespace RedemptionOverrideRequest {
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


