<div *ngIf="showErrorMessageTrigger">
  <div *ngIf="isError" class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
       role="alert">
    <p class="m-0 p-0 d-flex align-items-start">
      <span>
        <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
        {{ getErrorMessage() | removeUnderscores }}
      </span>
      <iconify-icon
        class="iconify fs-4 ms-auto pointer"
        icon="mdi:close"
        data-bs-dismiss="alert">
      </iconify-icon>
    </p>
  </div>
  <div *ngIf="!isError" class="alert-success alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
       role="alert">
    <p class="m-0 p-0 d-flex align-items-start">
      <span>
        <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
        {{ getErrorMessage() | removeUnderscores }}
      </span>
      <iconify-icon
        class="iconify fs-4 ms-auto pointer"
        icon="mdi:close"
        data-bs-dismiss="alert">
      </iconify-icon>
    </p>
  </div>
</div>

<div class="">
  <div class="container-fluid">
    <form class="row gy-5" [formGroup]="form">
      <div class="col-12 col-xl-6">
        <div class="container-fluid">
          <div class="row gx-3">
            <div class="col-xl-6 col-12 mb-3 mb-md-0">
              <label class="form-label required"> First Name</label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="John"
                formControlName="firstName"
              />
              <app-field-error
                *ngIf="(form.get('firstName')?.errors?.minlength && form.get('firstName')?.touched) || (form.get('firstName')?.errors?.minlength && errorSubmission)"
                [message]="'First name cannot be less than two(2).'"></app-field-error>

              <app-field-error
                *ngIf="(form.get('firstName')?.errors?.required && form.get('firstName')?.touched) || (form.get('firstName')?.errors?.required && errorSubmission)"
                [message]="'First name is required'"></app-field-error>

              <app-field-error
                *ngIf="(form.get('firstName')?.errors?.maxlength && form.get('firstName')?.touched) || (form.get('firstName')?.errors?.maxlength && errorSubmission)"
                [message]="'First name greater than fifty(50).'"></app-field-error>
            </div>
            <div class="col-xl-6 col-12 mb-3 mb-md-0">
              <label class="form-label required"> Last Name</label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder=" Doe"
                formControlName="lastName"
              />
              <app-field-error
                *ngIf="(form.get('lastName')?.errors?.minlength && form.get('lastName')?.touched) || (form.get('lastName')?.errors?.minlength && errorSubmission)"
                [message]="'Last name cannot be less than two(2).'"></app-field-error>
              <app-field-error
                *ngIf="(form.get('lastName')?.errors?.required && form.get('lastName')?.touched) || (form.get('lastName')?.errors?.required && errorSubmission)"
                [message]="'Last name is required'"></app-field-error>
              <app-field-error
                *ngIf="(form.get('lastName')?.errors?.maxlength && form.get('lastName')?.touched) || (form.get('lastName')?.errors?.maxlength && errorSubmission)"
                [message]="'Last name cannot be less than two(2).'"></app-field-error>
            </div>
            <div class="col-12 my-3 mb-md-0">
              <label class="form-label">Phone Number </label>
              <ngx-intl-tel-input  [cssClass]="'form-control form-control-lg fresh'"
                                   [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [id]="'phoneNumber'"
                                   [maxLength]="'20'" [phoneValidation]="true" [preferredCountries]="preferredCountries"
                                   [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                   [searchCountryFlag]="false"
                                   [onlyCountries]="['us']"
                                   [selectedCountryISO]="countryISO"
                                   [selectFirstCountry]="true"
                                   formControlName="phoneNumber"></ngx-intl-tel-input>
              <app-field-error
                *ngIf="(form.get('phoneNumber')?.errors?.required && form.get('phoneNumber')?.touched) || (form.get('phoneNumber')?.errors?.required && errorSubmission)"
                [message]="'Phone number cannot be blank.'"></app-field-error>

              <app-field-error class="mt-1" [message]="'Invalid phone number format.'" *ngIf="form.get('phoneNumber')!.errors?.validatePhoneNumber || form.get('phoneNumber')!.errors?.invalidPhoneNumber"></app-field-error>
            </div>

            <div class="col-12 my-3 mb-md-0">
              <label for="inputAddress" class="form-label required">Email Address</label>
              <input type="email" class="form-control form-control-lg" id="inputAddress" formControlName="email"
                     placeholder="someone@example.com">
              <app-field-error
                *ngIf="(form.get('email')?.errors?.required && form.get('email')?.touched) || (form.get('email')?.errors?.required && errorSubmission)"
                [message]="'Email is required'"></app-field-error>
              <app-field-error
                *ngIf="(this.form.get('email').errors?.pattern && form.get('email')?.touched)"
                [message]="'Invalid email address pattern.'"></app-field-error>

              <app-field-error *ngIf="(this.form.get('email').errors?.notDeliverable && form.get('email')?.touched)"
                               [message]="'Please double-check email address'"></app-field-error>
            </div>
            <div class="col-12 mt-3">
              <div class="form-group">
                <div class="checkboxes d-flex align-items-baseline">
                  <input type="checkbox" formControlName="confirm">
                  <label class="container_check mx-2 required">Kindly confirm that this dental Professional is associated with your office
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <app-field-error
                *ngIf="(form.get('confirm')?.errors?.required && form.get('confirm')?.touched) || (form.get('confirm')?.errors?.required && errorSubmission)"
                [message]="'This is required to send an invite.'"></app-field-error>
            </div>
            <br>
            <button [disabled]="working" class="btn btn-primary blue text-center col mt-5 mx-2 mb-4"
                    (click)="this.form.valid ? addNewDentalProfessional(): submittedForError()">
              <span *ngIf="working" class="fa fa-spinner fa-spin"></span>
              <span *ngIf="!working">+</span>
              Invite
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12" *ngIf="false">
        <div>
          <h6 class="mb-0 p-3 text-light w-1" style="background-color: #02183d">
            Pending Invites
          </h6>
          <ng-container *ngIf="invites$ | async as coupons else loading">
            <div class="card border-0 shadow-sm mt-3 mb-3 col-sm-12 col-md-12" *ngFor="let dp of inviteResult.results">
              <div class="card-header d-flex bg-transparent row border-0">
                <div class="one col-md-3">
                  <img class="img-fluid rounded-3"
                       width="80" src="assets/img/user.png" alt="">
                </div>
                <div class="two col-md-8 p-2 ms-1" >
                  <div class="d-flex align-items-baseline justify-content-between">
                    <span *ngIf="dp.invitedUser" class="added">Added Dental Professional</span>
                    <span *ngIf="!dp.invitedUser" class="invited">Invited Dental Professional</span>
                    <!--                    <div *ngIf="result.dentalDoor">-->
                    <!--                      <img src="assets/img/dd-badgegold.svg" [tooltip]="'Office on DentalDoor'"  style="color: green;" width="20"/>-->
                    <!--                    </div>-->
                  </div>
                  <div class="d-flex">
                    <div>Dr. {{dp.name | titlecase}}</div>
                  </div>
                  <div class="d-flex">
                    <div>{{dp.email}}</div>
                  </div>
                  <div class="d-flex">
                    <div>{{dp.phoneNumber}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <pagination (pageChanged)="onPageChange($event)" *ngIf="inviteResult && inviteResult.total > 0"
            class="float-end"
                      [boundaryLinks]="true" [itemsPerPage]="4" [totalItems]="inviteResult.total"
                      previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
          </pagination>

        </div>
      </div>
    </form>
  </div>
</div>
<ng-template #loading>
  <loader></loader>
</ng-template>

