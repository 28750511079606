<div joyrideStep="zeroStep" title="Howdy!" text="Welcome to the affiliate dashboard. Let's take a quick tour of its features and benefits." stepPosition="center"></div>
<div class="container mt-4">
    <div class="row">
        <div class="d-flex align-items-center">
            <p class="fs-3 textmuted fw-bold my-3" joyrideStep="firstStep" title="Dashboard" text="This is your dashboard overview." stepPosition="left">
                <iconify-icon inline icon="codicon:dashboard" class="fs-3 me-2"></iconify-icon>
                Dashboard Overview
            </p>
        </div>

        <!-- search filter  -->
        <div class="my-4">
            <div class="container px-0">
                <div class="row g-2">
                    <div class="col-xl col-12">
                        <div class="overview-card rounded p-4">
                            <div class="container">
                                <div class="row" joyrideStep="secondStep" title="Commission Metrics" text="Check your Key Performance Indicators (KPIs) to see your commission earned and expected monthly earnings." stepPosition="left">
                                    <div class="col-12 col-xl-4 d-flex flex-column px-3">
                                        <div>
                                            <h5 class="text-muted">Total Commission</h5>
                                        </div>
                                        <h1 class="text-primary mt-4">
                                            {{ this.walletListCommissionStat?.totalCommission || 0 | noRoundCurrency}}
                                        </h1>
                                        <p class="text-muted small">
                                            Total amount of commission received.
                                        </p>
                                        <div class="d-flex justify-content-end">
                                            <div class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2">
                                              <div class="mb-0 mx-2 dddb-card-btn pointer" style="line-height: 23px"
                                                 [routerLink]="'/affiliates/withdraw'">
                                                Initiate payout
                                              </div>
                                                <div class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0 icon-hld">
                                                    <iconify-icon icon="prime:angle-right" class="fs-5"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-xl-4 my-5 my-xl-0 d-flex flex-column px-3 dashb-card-border">
                                        <div>
                                            <h5 class="text-muted">Amount Payable</h5>
                                        </div>
                                        <h1 class="text-primary mt-4">
                                            {{ this.walletListCommissionStat?.totalAmountPayable || 0 | noRoundCurrency}}
                                        </h1>
                                        <p class="small text-muted">
                                            Total commission payable.
                                        </p>
                                        <div class="d-flex justify-content-end">
                                            <div class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2">
                                                <!--                        <p class="mb-0 mx-2 dddb-card-btn pointer" style="line-height: 23px" [routerLink]="'/in-bound'">-->
                                                <!--                          View-->
                                                <!--                        </p>-->
                                                <div class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0">
                                                    <iconify-icon icon="prime:angle-right" class="fs-5"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-xl-4 d-flex flex-column px-3">
                                        <div>
                                            <h5 class="text-muted">Today's Commission</h5>
                                        </div>
                                        <h1 class="text-primary mt-4">
                                            {{this.walletListCommissionStat?.totalCommissionToday || 0 | noRoundCurrency}}
                                        </h1>

                                        <p class="text-muted small">
                                            Total commission received today.
                                        </p>
                                        <div class="d-flex justify-content-end">
                                            <div class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2">
                                                <!--                        <p class="mb-0 mx-2 dddb-card-btn pointer" style="line-height: 23px" [routerLink]="'/in-bound'">-->
                                                <!--                          View-->
                                                <!--                        </p>-->
                                                <div class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0">
                                                    <iconify-icon icon="prime:angle-right" class="fs-5"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-3 mt-2  ps-xl-4">
                        <div class="ddd-office-address p-4 shadow-sm rounded">
                            <p class="fs-6 opacity-75 fw-bold">My Information</p>
                            <div class="d-flex text-muted mb-2">
                                <iconify-icon icon="heroicons:building-office-2" class="fs-5 me-2"></iconify-icon>
                                <p>{{(user.firstName | titlecase) + ' ' + (user.lastName | titlecase)}}</p>
                            </div>
                            <div class="d-flex text-muted mb-2">
                                <iconify-icon icon="material-symbols:alternate-email-rounded" class="fs-5 me-2"></iconify-icon>
                                <p class=" text-truncate">{{user?.email}}</p>
                            </div>
                            <div class="d-flex text-muted mb-2">
                                <iconify-icon icon="carbon:phone-ip" class="me-2 fs-5"></iconify-icon>
                                <p>{{user?.mobileNumber}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- (done)="stepDone()" -->
    <div class="">
        <div class="container px-0">
            <div class="row d-flex justify-content-between flex-wrap mt-4">
                <div class="col-xl-3 col-sm-6 my-3 my-xl-0">
                    <div class="d-flex flex-column doffice-bg rounded-4 shadow-lg px-4 p-3">
                        <h5 class="text-light">Total Affiliates</h5>
                        <div class="row justify-content-between">
                            <div class="col">
                                <h1 class=" mt-2 text-light">
                                    {{this.affiliateMembershipStat?.totalAffiliateInvited || 0}}
                                </h1>
                                <p class="text-light">{{this.affiliateMembershipStat?.totalAffiliatesInvitedToday || 0}} conversions today.</p>
                            </div>
                            <div class="col-auto position-relative ">
                                <div class="rounded-circle position-absolute bottom-0 end-0 circle-icon-1 m-3"><svg xmlns="http://www.w3.org/2000/svg" width="2.3em" height="2.3em" viewBox="0 0 16 16"><g fill="#369dbf"><path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26l.001.002l4.995 3.178l1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 0 1 5.026-4.47L15.964.686Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215l7.494-7.494l1.178-.471l-.47 1.178Z"/><path d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95l-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/></g></svg></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 my-3 my-xl-0 ">
                    <div class="d-flex flex-column px-3 overview-card refcard p-3 text-primary ">
                        <h5>Total Subscribed</h5>
                        <div class="row justify-content-between">
                            <div class="col">
                                <h1 class=" mt-2">
                                    {{this.affiliateMembershipStat?.totalAffiliateSubscribed || 0}}
                                </h1>
                                <p class="">{{this.affiliateMembershipStat?.totalAffiliateSubscribedToday || 0}} subscriptions today.</p>
                            </div>
                            <div class="col-auto position-relative ">
                                <div class="rounded-circle position-absolute bottom-0 end-0 circle-icon-2 m-3"><svg xmlns="http://www.w3.org/2000/svg" width="2.3em" height="2.3em" viewBox="0 0 32 32"><path fill="white" d="m22 27.18l-2.59-2.59L18 26l4 4l8-8l-1.41-1.41L22 27.18z"/><path fill="white" d="M25 5h-3V4a2.006 2.006 0 0 0-2-2h-8a2.006 2.006 0 0 0-2 2v1H7a2.006 2.006 0 0 0-2 2v21a2.006 2.006 0 0 0 2 2h9v-2H7V7h3v3h12V7h3v11h2V7a2.006 2.006 0 0 0-2-2Zm-5 3h-8V4h8Z"/></svg></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-12 my-3 my-xl-0 two">
                    <div class="d-flex flex-column px-3 overview-card refcard p-3">
                        <h5>Pending Subscriptions</h5>
                        <div class="row justify-content-between">
                            <div class="col">
                                <h1 class=" mt-2">
                                    {{this.affiliateMembershipStat?.totalAffiliatePending || 0}}
                                </h1>
                                <p class="">Expired / Yet to subscribe.</p>
                            </div>
                            <div class="col-auto position-relative ">
                                <div class="rounded-circle position-absolute bottom-0 end-0 circle-icon-3 m-3"><svg xmlns="http://www.w3.org/2000/svg" width="2.3em" height="2.3em" viewBox="0 0 24 24"><path fill="white" d="M12 2c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12S6.5 2 12 2m0 2c-1.9 0-3.6.6-4.9 1.7l11.2 11.2c1-1.4 1.7-3.1 1.7-4.9c0-4.4-3.6-8-8-8m4.9 14.3L5.7 7.1C4.6 8.4 4 10.1 4 12c0 4.4 3.6 8 8 8c1.9 0 3.6-.6 4.9-1.7Z"/></svg></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-sm my-3 my-xl-0 ps-lg-4">
                    <div class="overview-card p-4 rounded" joyrideStep="fifthStep" title="Active Affiliates" text="Check here to view your most active affiliates" stepPosition="left">
                        <div class="d-flex justify-content-between mb-2 ">
                            <p class="fs-6 mb-0 opacity-75 fw-bold ">My Top Affiliates</p>
                            <p class="mb-0 small ">
                                <a class="text-decoration-none pointer" routerLink="/affiliates/linking">View all</a>
                            </p>
                        </div>
                        <div>
                            <div class="user-name-avatar" *ngIf="this.affiliateMembersList?.length < 1">
                                <p class=" mb-0 ">No affiliate</p>
                            </div>
                            <div *ngFor="let aff of this.affiliateMembersList">
                                <div class="d-flex align-items-center my-4 ">
                                    <img [src]=getFileURL(aff.imageId) width="40 " height="40 " class="rounded-circle rounded me-2 " />
                                    <div class="user-name-avatar ">
                                        <p class="mb-0 small">{{aff.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5" joyrideStep="fourthStep" title="Your Referral Link" text="Invite Secondary Affiliates and Dental Offices by using your unique referral code or links.">

        <div class="mt-4 row align-items-center">
            <p class="col-auto">Invite Affiliates and Dental Offices using the link/code below</p>
            <div *ngIf="copied" class="copy col">
                <div class="alert-primary alert border-0 m-0 p-1 text-center text-light shadow rounded-2 fade show">
                    <p class="m-0 p-0 ">
                        <span>
                  <iconify-icon class="iconify me-2 " icon="ep:info-filled "></iconify-icon>
                          {{ 'Copied to clipboard' }}
                </span>
                        <iconify-icon class="iconify fs-4 ms-auto pointer " icon="mdi:close " data-bs-dismiss="alert ">
                        </iconify-icon>
                    </p>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <div class="row ">
                <div class="col-lg ">
                    <div class="card shadow border-0 code-card mb-3 ">
                        <div class="card-body p-2 ">
                            <div class=" ">
                                <p class="text-start mb-1 small ">Your Referral Code</p>
                            </div>
                            <div class="card shadow-sm bg-light border-0 ">
                                <div class="d-flex align-item-center justify-content-center px-3 pt-1 ">
                                    <p class=" small mb-1 ">{{user?.affiliateCode}}</p>
                                    <a ngxClipboard (cbOnSuccess)="contentCopied($event) " [cbContent]=user.affiliateCode class="ms-auto nav-link pointer small ">Copy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg ">
                    <div class="card shadow border-0 code-card mb-3 ">
                        <div class="card-body p-2 ">
                            <div class=" ">
                                <p class="text-start mb-1 small ">Your Referral Link - Secondary Affiliates</p>
                            </div>
                            <div class="card shadow-sm bg-light border-0 ">
                                <div class="d-flex align-item-center justify-content-center px-3 pt-1 ">
                                    <p class="pe-3 text-truncate small mb-1 ">{{environmentUrl() + 'affiliates/invite/' + user?.affiliateCode}}</p>
                                    <a class="ms-auto nav-link pointer small " ngxClipboard (cbOnSuccess)="contentCopied($event) " [cbContent]="this.environmentUrl() + 'affiliates/invite/'+ user.affiliateCode">Copy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg ">
                    <div class="card shadow border-0 code-card mb-3 ">
                        <div class="card-body p-2 ">
                            <div class=" ">
                                <p class="text-start mb-1 small ">Your Referral Link - Dental Office</p>
                            </div>
                            <div class="card shadow-sm bg-light border-0 ">
                                <div class="d-flex align-item-center justify-content-center px-3 pt-1 ">
                                    <p class="pe-3 text-truncate small mb-1 ">{{this.environmentUrl() + 'office/' + user?.affiliateCode}}</p>
                                    <a class="ms-auto nav-link pointer small " ngxClipboard (cbOnSuccess)="contentCopied($event) " [cbContent]="this.environmentUrl()+'office/'+ user.affiliateCode">Copy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
