import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceToUnderscore'
})
export class SpaceToUnderscorePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !value.replace) {
      return;
    }
    return value.replace(/\s/g, '_');
  }

}
