import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BanksControllerService, QueryResultsPojoBanks } from "dd-core-api-sdk";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SocialService } from "../../../../../shared/subject/SocialService";

@Component({
  selector: 'app-account-linking-bank',
  templateUrl: './account-linking-bank.component.html',
  styleUrls: ['./account-linking-bank.component.css']
})
export class AccountLinkingBankComponent implements OnInit {

  @Output() moveToNextStep = new EventEmitter<any>();
  @Output() dismissButtonClicked = new EventEmitter<any>();

  private _searchFilter: string = "";
  get searchFilter(): string {
    return this._searchFilter;
  }
  set searchFilter(value: string) {
    this._searchFilter = value;
    this.searchBanks();
  }

  resultSubject = new BehaviorSubject<QueryResultsPojoBanks>(null);
  banksPojo: QueryResultsPojoBanks;
  formGroup: FormGroup;

  constructor(private fb: FormBuilder,
    private socialService: SocialService,
    private banksControllerService: BanksControllerService) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: ['']
    })
    this.searchBanks();
  }

  selectBank(bank?: any) {
    this.moveToNextStep.emit();
    this.socialService.bankCodeSubject.next(bank);
  }

  buildBankUrl(url: string) {
    return environment.url + url;
  }

  manualAddition() {
    this.moveToNextStep.emit();
    this.socialService.bankCodeSubject.next(null);
  }

  searchBanks() {
    const name = this.searchFilter;
    this.banksControllerService.getAllMasterRecordBanks({ name: name, limit: 6 }).subscribe(res => {
      this.banksPojo = res;
      this.resultSubject.next(res);
    })
  }

}
