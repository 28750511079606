import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../../services/UserKeycloak";

@Component({
  selector: 'app-outbound-list',
  templateUrl: './outbound-list.component.html',
  styleUrls: ['./outbound-list.component.css']
})
export class OutboundListComponent implements OnInit {

  showDraft: boolean;
  user: UserKeycloak;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authentication: AuthenticationServiceKeycloak
  ) {
  }

  ngOnInit(): void {
    this.authentication.getUser().subscribe(res => {
      this.user = res;
    });

    this.activeRoute.data.subscribe(data => {
      this.showDraft = data.isDraft;
    });
  }
}

