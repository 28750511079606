import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AffiliateBankPojo, CommissionLogPojo, WalletTransactionPojo} from "dd-core-api-sdk";

@Component({
  selector: 'app-affiliate-error-info-modal',
  templateUrl: './affiliate-error-info-modal.component.html',
  styleUrls: ['./affiliate-error-info-modal.component.css']
})
export class AffiliateErrorInfoModalComponent implements OnInit {
  @Output()
  dismissClicked = new EventEmitter<any>();

  @Output()
  closeClicked = new EventEmitter<any>();

  @Input()
  infoTitle: string;

  @Input()
  affiliateBank: AffiliateBankPojo;
  @Input()
  walletTransactionPojo:  WalletTransactionPojo;
  walletTransactionConstant = WalletTransactionPojo.WalletTransactionStatusEnum;
  constructor() { }

  ngOnInit(): void {
  }

}
