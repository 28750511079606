<main>
  <div class="card border-light success">
    <div class="card-body">
      <h5 class="card-title mb-5">{{this.title}}</h5>
      <iconify-icon *ngIf="!success" icon="bxs:error-alt" style="color: red;" width="50"></iconify-icon>
      <iconify-icon *ngIf="success" icon="clarity:success-standard-line" style="color: green;"
                    width="50"></iconify-icon>
      <p class="card-text p-3" style="font-size: 14px;">{{this.subTitle}}</p>
      <div class="row mb-3">
        <div class="col">
          <a id="submit" class="btn btn-primary w-100 mt-3" (click)="this.buttonClicked()">{{this.buttonText}}</a>
        </div>
      </div>
    </div>
  </div>
</main>
