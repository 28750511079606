/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PortalAccountTypeRolePojo { 
    dateCreated?: string;
    dateDeactivated?: string;
    name?: string;
    displayName?: string;
    accountType?: PortalAccountTypeRolePojo.AccountTypeEnum;
    id?: number;
}
export namespace PortalAccountTypeRolePojo {
    export type AccountTypeEnum = 'DENTAL_DOOR_ADMIN' | 'DENTAL_OFFICE' | 'DENTAL_PROFESSIONAL' | 'DENTAL_SPECIALIST' | 'DENTAL_SPECIALIST_OFFICE' | 'SUPER_ADMIN' | 'AFFILIATE';
    export const AccountTypeEnum = {
        DentalDoorAdmin: 'DENTAL_DOOR_ADMIN' as AccountTypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as AccountTypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as AccountTypeEnum,
        DentalSpecialist: 'DENTAL_SPECIALIST' as AccountTypeEnum,
        DentalSpecialistOffice: 'DENTAL_SPECIALIST_OFFICE' as AccountTypeEnum,
        SuperAdmin: 'SUPER_ADMIN' as AccountTypeEnum,
        Affiliate: 'AFFILIATE' as AccountTypeEnum
    };
}


