/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameAndCodeDto } from './name-and-code-dto';
import { PortalAccountTypeRole } from './portal-account-type-role';
import { AddressPojo } from './address-pojo';


export interface PortalUserPojo { 
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    dateCreated?: string;
    status?: PortalUserPojo.StatusEnum;
    username?: string;
    userId?: string;
    accountName?: string;
    accountType?: PortalUserPojo.AccountTypeEnum;
    role?: Set<PortalAccountTypeRole>;
    membershipStatus?: PortalUserPojo.MembershipStatusEnum;
    isStudent?: boolean;
    gender?: PortalUserPojo.GenderEnum;
    languages?: Array<NameAndCodeDto>;
    displayPictureId?: number;
    displayName?: string;
    address?: AddressPojo;
}
export namespace PortalUserPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type AccountTypeEnum = 'DENTAL_DOOR_ADMIN' | 'DENTAL_OFFICE' | 'DENTAL_PROFESSIONAL' | 'DENTAL_SPECIALIST' | 'DENTAL_SPECIALIST_OFFICE' | 'SUPER_ADMIN' | 'AFFILIATE';
    export const AccountTypeEnum = {
        DentalDoorAdmin: 'DENTAL_DOOR_ADMIN' as AccountTypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as AccountTypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as AccountTypeEnum,
        DentalSpecialist: 'DENTAL_SPECIALIST' as AccountTypeEnum,
        DentalSpecialistOffice: 'DENTAL_SPECIALIST_OFFICE' as AccountTypeEnum,
        SuperAdmin: 'SUPER_ADMIN' as AccountTypeEnum,
        Affiliate: 'AFFILIATE' as AccountTypeEnum
    };
    export type MembershipStatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const MembershipStatusEnum = {
        Active: 'ACTIVE' as MembershipStatusEnum,
        Inactive: 'INACTIVE' as MembershipStatusEnum,
        Deactivated: 'DEACTIVATED' as MembershipStatusEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
}


