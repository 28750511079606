/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Banks } from './banks';
import { PortalUser } from './portal-user';


export interface AffiliateBank { 
    accountName?: string;
    routingNumber?: string;
    merchantName?: string;
    currency?: string;
    createdAt?: string;
    accountNumber?: string;
    updatedAt?: string;
    zipCode?: string;
    maskedAccountNumber?: string;
    bankVerificationStatus?: AffiliateBank.BankVerificationStatusEnum;
    bankKycStatusConstant?: AffiliateBank.BankKycStatusConstantEnum;
    bankVerificationCount?: number;
    status?: AffiliateBank.StatusEnum;
    kycVerificationCount?: number;
    bankToken?: string;
    tokenUsed?: boolean;
    accountId?: string;
    bankVerificationId?: string;
    id?: number;
    portalUser?: PortalUser;
    banks?: Banks;
}
export namespace AffiliateBank {
    export type BankVerificationStatusEnum = 'PENDING' | 'SUCCESSFUL' | 'FAILED';
    export const BankVerificationStatusEnum = {
        Pending: 'PENDING' as BankVerificationStatusEnum,
        Successful: 'SUCCESSFUL' as BankVerificationStatusEnum,
        Failed: 'FAILED' as BankVerificationStatusEnum
    };
    export type BankKycStatusConstantEnum = 'REQUIRES_UPDATE' | 'FAILED' | 'SUCCESSFUL' | 'PENDING' | 'PROCESSING' | 'FAILED_KYC';
    export const BankKycStatusConstantEnum = {
        RequiresUpdate: 'REQUIRES_UPDATE' as BankKycStatusConstantEnum,
        Failed: 'FAILED' as BankKycStatusConstantEnum,
        Successful: 'SUCCESSFUL' as BankKycStatusConstantEnum,
        Pending: 'PENDING' as BankKycStatusConstantEnum,
        Processing: 'PROCESSING' as BankKycStatusConstantEnum,
        FailedKyc: 'FAILED_KYC' as BankKycStatusConstantEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


