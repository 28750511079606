<div class="container-fluid">
  <div class="row">
    <div class="overview-card p-3">
      <div class="filter-card-bg p-4">
        <div *ngIf="showErrorMessageTrigger">
          <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert">
            <p class="m-0 p-0 d-flex align-items-start">
              <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                {{ getErrorMessage() | removeUnderscores }}
              </span>
              <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
              </iconify-icon>
            </p>
          </div>
        </div>
        <div class="container-fluid">
          <div [formGroup]="form" class="row">
            <div class="col-md-3 mb-3 mb-md-0 form-floating">
              <div class="form-floating">
                <input type="text" class="form-control" id="firstName" placeholder="Name" aria-label="First Name "
                  formControlName="patientName" />
                <label for="firstName" class="">Name</label>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-3 mb-md-0" style="margin-top: -1.6rem;">
              <div class="">
                <label class="form-label small rfr-label"> Date (from) </label>
                <div class="input-group">
                  <input class="form-control" placeholder="MM-DD-YYYY" formControlName="startDate"
                    [minDate]="{ year: 1910, month: 1, day: 1 }" [maxDate]="{  year: maxYear(), month: +maxMonth(), day: +maxDay() }" [(ngModel)]="model"
                    ngbDatepicker #startDate="ngbDatepicker" (click)="startDate.toggle()" />
                  <span (click)="startDate.toggle()" class="btn-primary-outline input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-3 mb-md-0" style="margin-top: -1.6rem;">
              <div class="">
                <label class="form-label small rfr-label"> Date (to) </label>
                <div class="input-group">
                  <input class="form-control" placeholder="MM-DD-YYYY" formControlName="endDate"
                    [minDate]="{ year: 1910, month: 1, day: 1 }" [maxDate]="{  year: maxYear(), month: +maxMonth(), day: +maxDay() }" [(ngModel)]="model1"
                    ngbDatepicker #endDate="ngbDatepicker" (click)="endDate.toggle()" />
                  <span (click)="endDate.toggle()" class="btn-primary-outline input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="false" class="col-lg-3 col-md-4 col-sm-6 mb-3 mb-md-0 collapse" id="filteroptions">
              <label class="form-label small"> Receiving Office </label>
              <input type="text" class="form-control" aria-label="Receiving office" placeholder="Receiving Office" />
            </div>
            <div class="col-lg-3 col-md-11 col-sm-12 mb-3 mb-md-0">
              <div class="d-flex justify-content-end float-end" style="white-space: nowrap;">
              <button class="bg-primary border-0 d-flex justify-content-center align-items-center px-3 text-light float-end"
                (click)="onSearchClick()" style=" margin-top: 3px; padding: .35rem;">
                <iconify-icon _ngcontent-yfu-c140="" inline="" icon="iconoir:search" width="25" height="25" class="me-2 fs-5">
                </iconify-icon> Apply Filter
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row mt-4">
    <div class="overview-card-table p-3">
      <!--      <div class="d-flex align-items-center justify-content-between flex-wrap mt-4">-->
      <!--        <p class="text-secondary mb-0">-->
      <!--          <i>d</i><strong>Refer</strong>:-->
      <!--          {{ totalReferrals ? "(" + totalReferrals + ")" : "(0)" }} <i class="fa-solid fa-paper-plane me-2"></i>-->
      <!--        </p>-->
      <!--      </div>-->
      <div class="table-responsive">
            <div class="text-secondary my-3">
              <h5 class="m-2 fw-bold">Uncompleted Referrals</h5>
            </div>
        <table class="table">
          <thead class="">
            <tr class="overview-card shadow-sm">
              <th class="">ID</th>
              <th class="">Patient</th>
              <th class="">Referring</th>
              <th class="">Date Created</th>
              <th class="">Receiving</th>
              <th class=""></th>
            </tr>
          </thead>

          <tbody class="text-muted">
            <tr *ngFor="
                let referral of resultSubject.value;
                let i = index" [id]="i" class="bg-transparent shadow-none pointer">
              <td [id]="'index' + i" class="align-middle fw-bold fs-6">
                {{ offset + i + 1 }}
              </td>
              <td [id]="'patient' + i" class="align-middle" [routerLink]="'/out-bound/create-referral'"
                [queryParams]="{isDraft: true, id: referral.id}">
                <p class="mb-0 fw-bold">
                  {{
                  referral.patientFirstName + " " + referral.patientLastName
                  }}
                  <span *ngIf="referral.dateOfBirth">({{ this.getAge(referral.dateOfBirth) }})</span>
                </p>
                <p class="mb-0">{{ referral.phoneNumber }}</p>
              </td>
              <td [id]="'referring' + i" class="align-middle" [routerLink]="'/out-bound/create-referral'"
                [queryParams]="{isDraft: true, id: referral.id}">
                <p *ngIf="referral.referralFrom" class="mb-1 fs-12 fw-bold">
                  {{ referral.referralFrom }}
                </p>
              </td>
              <td [id]="'date' + i" class="align-middle" [routerLink]="'/out-bound/create-referral'"
                [queryParams]="{isDraft: true, id: referral.id}">
                {{ formatDate(referral.createdAt) }}
              </td>
              <td [id]="'receiving' + i" class="align-middle">
                <p *ngIf="referral.referralTo" class="mb-1 fs-12 fw-bold">
                  {{ referral.referralTo }}
                </p>
                </td>
              <td [id]="'action' + i" class="table-action-dropdown align-middle">
                
                  <span type="button" data-bs-toggle="dropdown" aria-expanded="false"
                    class="text-primary text-decoration-none d-flex justify-content-center view-details-button">
                  <ul class=" fs-12 nav">
                    <li class=" pointer me-3" placement="top" ngbTooltip="Edit Draft">
                      <a [routerLink]="'/out-bound/create-referral'" [queryParams]="{isDraft: true, id: referral.id}">
                        <span>
                          <iconify-icon icon="ep:edit" style="color: rgb(39, 40, 39);"></iconify-icon>
                        </span>
                      </a>
                    </li>
                    <li class=" pointer" placement="top" ngbTooltip="Delete Draft">
                      <a data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        <span>
                          <iconify-icon icon="ri:delete-bin-6-line" style="color: rgb(246, 19, 19);"></iconify-icon>
                        </span>

                        <!-- modal -->
                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                          aria-labelledby="staticBackdropLabel" aria-hidden="true">
                          <div class="modal-dialog modal-sm modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hold on!</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body text-center">
                                <p class="mb-0 fs-6">You have not sent this referral. </p>
                                <p class="fs-6 mb-0">Are you sure you want to delete it? </p>
                              </div>
                              <div class="modal-footer d-flex justify-content-center">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                                <button type="button" class="btn btn-primary"  (click)="deleteDraft(referral)">Yes</button>
                              </div>
                            </div>
                            </div>
                            </div>
                            <!-- modal end-->

                      </a>
                    </li>
                  </ul>
                  </span>

                  </td>
                  </tr>
          </tbody>
        </table>
      </div>
      <div>
        <span *ngIf="!resultSubject.value || total == 0"
          class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
          {{
          resultSubject.value
          ? "NO DATA/RECORDS"
          : "APPLY FILTERS TO VIEW REFERRAL DETAILS"
          }}</span>
      </div>

      <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3"
        style="margin-bottom: -1rem; color: #4e3367">
        <app-page-length [pageLength]="getFilter().limit || 10" (pageLengthChanged)="changeLimit($event)">
        </app-page-length>

        <div class="add-group-btn text-right mt-3 mb-3">
          <pagination (pageChanged)="onPageChange($event)" [boundaryLinks]="true" [itemsPerPage]="limit || 10" [(ngModel)]="page"
            [maxSize]="5" [totalItems]="total || 0" firstText="&laquo;" lastText="&raquo;" nextText="&rsaquo;"
            previousText="&lsaquo;"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>