import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, DecimalPipe} from "@angular/common";
import {AffiliateRoutingModule} from "./affiliate-routing.module";
import {SharedModule} from "../../shared/shared.module";
import {NgbDatepickerModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {AffiliateSignupComponent} from "./sign-up/affiliate-signup/affiliate-signup.component";
import {AffiliateHomePageComponent} from "./utils/affiliate-home-page/affiliate-home-page.component";
import {AffiliateWizardComponent} from './sign-up/affiliate-wizard/affiliate-wizard.component';
import {ArchwizardModule} from "angular-archwizard";
import {BillingModule} from "../billing/billing.module";
import {AffiliateOtpComponent} from './sign-up/affiliate-otp/affiliate-otp.component';
import {AffiliateSecondSignupComponent} from './sign-up/affiliate-second-signup/affiliate-second-signup.component';
import {NgOtpInputModule} from "ng-otp-input";
import {AffiliateSuccessModalComponent} from './utils/affiliate-success-modal/affiliate-success-modal.component';
import {AffiliateDashboardComponent} from './utils/affiliate-dashboard/affiliate-dashboard.component';
import {AffiliateSidebarComponent} from './utils/affiliate-sidebar/affiliate-sidebar.component';
import {AffiliateDashboardHeaderComponent} from './utils/affiliate-dashboard-header/affiliate-dashboard-header.component';
import {ClipboardModule} from "ngx-clipboard";
import {DashboardModule} from "../dashboard/dashboard.module";
import {AffiliateSearchComponent} from './linking/affiliate-search/affiliate-search.component';
import {AffiliateInviteSignupComponent} from './sign-up/affiliate-invite-signup/affiliate-invite-signup.component';
import {JoyrideModule, JoyrideStepComponent} from "ngx-joyride";
import {AffiliateWithdrawalComponent} from './withdrawal/affiliate-withdrawal/affiliate-withdrawal.component';
import {AffiliateBankComponent} from './withdrawal/affiliate-withdrawal/affiliate-bank/affiliate-bank.component';
import {
  AffiliateWithdrawalHistoryComponent
} from './withdrawal/affiliate-withdrawal/affiliate-withdrawal-history/affiliate-withdrawal-history.component';
import {
  AccountLinkingWizardComponent
} from './withdrawal/affiliate-withdrawal/account-linking-wizard/account-linking-wizard.component';
import {
  AccountLinkingConditionsComponent
} from './withdrawal/affiliate-withdrawal/account-linking-conditions/account-linking-conditions.component';
import {AccountLinkingBankComponent} from './withdrawal/affiliate-withdrawal/account-linking-bank/account-linking-bank.component';
import {AccountLinkingFormComponent} from './withdrawal/affiliate-withdrawal/account-linking-form/account-linking-form.component';
import {KycStatusPageComponent} from './withdrawal/affiliate-withdrawal/kyc-status-page/kyc-status-page.component';
import {
  WalletWithdrawalWizardComponent
} from './withdrawal/affiliate-withdrawal/wallet-withdrawal-wizard/wallet-withdrawal-wizard.component';
import {
  WalletWithdrawalIComponent
} from './withdrawal/affiliate-withdrawal/wallet-withdrawal-wizard/wallet-withdrawal-i/wallet-withdrawal-i.component';
import {
  WalletWithdrawalIiComponent
} from './withdrawal/affiliate-withdrawal/wallet-withdrawal-wizard/wallet-withdrawal-ii/wallet-withdrawal-ii.component';
import { AffiliateProfileSettingsComponent } from './settings/affiliate-profile-settings/affiliate-profile-settings.component';
import {NgxMaskModule} from "ngx-mask";

@NgModule({
  declarations: [
    AffiliateHomePageComponent,
    AffiliateSignupComponent,
    AffiliateWizardComponent,
    AffiliateOtpComponent,
    AffiliateSecondSignupComponent,
    AffiliateSuccessModalComponent,
    AffiliateDashboardComponent,
    AffiliateSidebarComponent,
    AffiliateDashboardHeaderComponent,
    AffiliateSearchComponent,
    AffiliateInviteSignupComponent,
    AffiliateWithdrawalComponent,
    AffiliateBankComponent,
    AffiliateWithdrawalHistoryComponent,
    AccountLinkingWizardComponent,
    AccountLinkingConditionsComponent,
    AccountLinkingBankComponent,
    AccountLinkingFormComponent,
    KycStatusPageComponent,
    WalletWithdrawalWizardComponent,
    WalletWithdrawalIComponent,
    WalletWithdrawalIiComponent,
    AffiliateProfileSettingsComponent
  ],
  imports: [
    ReactiveFormsModule,
    AffiliateRoutingModule,
    CommonModule,
    SharedModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    NgbTooltipModule,
    ArchwizardModule,
    BillingModule,
    NgOtpInputModule,
    ClipboardModule,
    FormsModule,
    JoyrideModule.forRoot(),
    NgxMaskModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    AffiliateDashboardComponent,
    AffiliateDashboardHeaderComponent,
    AffiliateSidebarComponent
  ],
  providers: [
    DecimalPipe
  ],
  entryComponents: [JoyrideStepComponent],
})
export class AffiliateModule {
}
