/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SocialLinkDto { 
    type: SocialLinkDto.TypeEnum;
    link: string;
}
export namespace SocialLinkDto {
    export type TypeEnum = 'FACEBOOK' | 'TWITTER' | 'LINKEDIN' | 'YOUTUBE';
    export const TypeEnum = {
        Facebook: 'FACEBOOK' as TypeEnum,
        Twitter: 'TWITTER' as TypeEnum,
        Linkedin: 'LINKEDIN' as TypeEnum,
        Youtube: 'YOUTUBE' as TypeEnum
    };
}


