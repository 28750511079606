<div class="container">
  <div class="p-3">
    <div class="row">
      <div class="col-md-4 mb-4 mt-2">
        <img *ngIf="isSuccess" src="../../../../../../assets/img/kyc-success-img.svg" class="img-fluid " alt="">
        <img *ngIf="!isSuccess" src="../../../../../../assets/img/kyc-failed-img.svg" class="img-fluid " alt="">
      </div>
      <div class="col-md-8 text-md-start text-center mt-2">
        <h2 class="mt-lg-4 mt-md-3" *ngIf="isSuccess">KYC Saved!</h2>
        <h2 class="mt-lg-4 mt-md-3" *ngIf="!isSuccess">KYC Failure!</h2>
        <p>{{this.info}}</p>
        <button type="button" class="btn btn-large btn-primary px-5" (click)="closeClicked.emit()">Close</button>
      </div>
    </div>
  </div>
</div>
