/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReasonForDisapproval } from './reason-for-disapproval';
import { SupportedTimeZone } from './supported-time-zone';
import { PortalUser } from './portal-user';


export interface DentalOfficeSearchFilter { 
    addressFilterInitialized?: boolean;
    subscriptionFilterInitialized?: boolean;
    specializationType?: string;
    noSubscriptionFilter?: boolean;
    dentalOfficeId?: number;
    professionalSpecializations?: Set<string>;
    languages?: Set<string>;
    insuranceCompanies?: Set<string>;
    services?: Set<string>;
    favouritesOnly?: boolean;
    dentalOfficeName?: string;
    address?: string;
    createdByUserId?: string;
    startDate?: string;
    endDate?: string;
    setupComplete?: boolean;
    id?: number;
    code?: string;
    email?: string;
    phoneNumber?: string;
    defaultTimeZone?: SupportedTimeZone;
    city?: Set<string>;
    country?: Set<number>;
    reviewProfileId?: string;
    zipCode?: Set<string>;
    subscriptionPackages?: Set<DentalOfficeSearchFilter.SubscriptionPackagesEnum>;
    subscriptionCode?: string;
    subscriptionStartDate?: string;
    subscriptionEndDate?: string;
    cycle?: DentalOfficeSearchFilter.CycleEnum;
    specialtyOffice?: boolean;
    approvalStatus?: DentalOfficeSearchFilter.ApprovalStatusEnum;
    defaultUtcOffsetMinutes?: number;
    dateOfApprovalProcessing?: string;
    reasonForDenial?: string;
    website?: string;
    fax?: string;
    aboutUrl?: string;
    summary?: string;
    facebookUrl?: string;
    twitterUrl?: string;
    reasonForDisapproval?: ReasonForDisapproval;
    approvalProcessedBy?: PortalUser;
    adminNotified?: boolean;
    alternateEmail?: string;
    requireIdentityForAppointment?: boolean;
    requireInsuranceForAppointment?: boolean;
    appointmentBookingPolicy?: string;
    apptProcessingTimeInMinutes?: number;
    alternatePhoneNumber?: string;
    instagramUrl?: string;
    uniqueStatement?: string;
    lastReviewPage?: number;
    requireInsuranceForReferral?: boolean;
    state?: Set<string>;
    limit?: number;
    status?: DentalOfficeSearchFilter.StatusEnum;
    name?: string;
    offset?: number;
    handle?: string;
}
export namespace DentalOfficeSearchFilter {
    export type SubscriptionPackagesEnum = 'OFFICE_GALLERY' | 'EMERGENCY_SOS' | 'SEND_REFERRAL' | 'RECEIVE_REFERRAL' | 'DREFER_ANALYTICS' | 'UP_TO_5_ADMIN_ACCESS' | 'UP_TO_3_ADMIN_ACCESS' | 'UNLIMITED_DENTAL_PROFESSIONALS' | 'UP_TO_5_DENTAL_PROFESSIONALS';
    export const SubscriptionPackagesEnum = {
        OfficeGallery: 'OFFICE_GALLERY' as SubscriptionPackagesEnum,
        EmergencySos: 'EMERGENCY_SOS' as SubscriptionPackagesEnum,
        SendReferral: 'SEND_REFERRAL' as SubscriptionPackagesEnum,
        ReceiveReferral: 'RECEIVE_REFERRAL' as SubscriptionPackagesEnum,
        DreferAnalytics: 'DREFER_ANALYTICS' as SubscriptionPackagesEnum,
        UpTo5AdminAccess: 'UP_TO_5_ADMIN_ACCESS' as SubscriptionPackagesEnum,
        UpTo3AdminAccess: 'UP_TO_3_ADMIN_ACCESS' as SubscriptionPackagesEnum,
        UnlimitedDentalProfessionals: 'UNLIMITED_DENTAL_PROFESSIONALS' as SubscriptionPackagesEnum,
        UpTo5DentalProfessionals: 'UP_TO_5_DENTAL_PROFESSIONALS' as SubscriptionPackagesEnum
    };
    export type CycleEnum = 'MONTHLY' | 'ANNUAL' | 'QUARTERLY' | 'SEMI_ANNUAL';
    export const CycleEnum = {
        Monthly: 'MONTHLY' as CycleEnum,
        Annual: 'ANNUAL' as CycleEnum,
        Quarterly: 'QUARTERLY' as CycleEnum,
        SemiAnnual: 'SEMI_ANNUAL' as CycleEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


