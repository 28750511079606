/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilePojo } from './file-pojo';
import { PortalAccount } from './portal-account';
import { SpecialistPostReferralPojo } from './specialist-post-referral-pojo';
import { FirstContactPojo } from './first-contact-pojo';
import { ReferralStatusNotes } from './referral-status-notes';
import { PatientPojo } from './patient-pojo';
import { XrayPojo } from './xray-pojo';
import { Procedure } from './procedure';
import { TemporaryDentalOfficePojo } from './temporary-dental-office-pojo';
import { DentalProfessionalPojo } from './dental-professional-pojo';
import { Review } from './review';
import { DentalOfficePojo } from './dental-office-pojo';


export interface ReferralSearchPojo { 
    priorityLevel?: ReferralSearchPojo.PriorityLevelEnum;
    patientName?: string;
    patient?: PatientPojo;
    referralReasonTypeConstant?: ReferralSearchPojo.ReferralReasonTypeConstantEnum;
    referralReasonNote?: string;
    isDraft?: boolean;
    code?: string;
    referralStatusConstant?: ReferralSearchPojo.ReferralStatusConstantEnum;
    appointmentDate?: string;
    preMedRequired?: boolean;
    dentalOfficeReferralFrom?: DentalOfficePojo;
    dentalProfessionalFrom?: DentalProfessionalPojo;
    dentalProFromFirstName?: string;
    dentalProFromLastName?: string;
    dentalOfficeReferralTo?: DentalOfficePojo;
    dentalProfessionalReferralToEntity?: DentalProfessionalPojo;
    dentalProfessionalReferralTo?: DentalProfessionalPojo;
    dentalOfficeAccountFrom?: PortalAccount;
    dateCreated?: string;
    referralDataId?: number;
    referralRequestId?: number;
    procedureType?: Array<Procedure>;
    affectedToothRange?: string;
    temporaryDentalOfficePojo?: TemporaryDentalOfficePojo;
    xrayPojo?: Array<XrayPojo>;
    adultTeethCheck?: boolean;
    streetAddress?: string;
    bwFileList?: Array<FilePojo>;
    dentalOfficeFromImageId?: number;
    dentalOfficeToImageId?: number;
    referralStatusNotesList?: Array<ReferralStatusNotes>;
    specialistPostReferral?: SpecialistPostReferralPojo;
    firstContactPojo?: FirstContactPojo;
    firstContactId?: number;
    specialistOfficeVideo?: string;
    dentistOfficeVideo?: string;
    officeReviewDtoList?: Array<Review>;
}
export namespace ReferralSearchPojo {
    export type PriorityLevelEnum = 'NORMAL' | 'SEMI_URGENT' | 'URGENT' | 'EMERGENCY';
    export const PriorityLevelEnum = {
        Normal: 'NORMAL' as PriorityLevelEnum,
        SemiUrgent: 'SEMI_URGENT' as PriorityLevelEnum,
        Urgent: 'URGENT' as PriorityLevelEnum,
        Emergency: 'EMERGENCY' as PriorityLevelEnum
    };
    export type ReferralReasonTypeConstantEnum = 'CONSULTATION' | 'TREATMENT' | 'BOTH';
    export const ReferralReasonTypeConstantEnum = {
        Consultation: 'CONSULTATION' as ReferralReasonTypeConstantEnum,
        Treatment: 'TREATMENT' as ReferralReasonTypeConstantEnum,
        Both: 'BOTH' as ReferralReasonTypeConstantEnum
    };
    export type ReferralStatusConstantEnum = 'PENDING' | 'SCHEDULED' | 'COMPLETED' | 'CUSTOM' | 'CANCELLED' | 'REJECTED' | 'NEW' | 'CALLED' | 'RESCHEDULED' | 'NO_SHOW';
    export const ReferralStatusConstantEnum = {
        Pending: 'PENDING' as ReferralStatusConstantEnum,
        Scheduled: 'SCHEDULED' as ReferralStatusConstantEnum,
        Completed: 'COMPLETED' as ReferralStatusConstantEnum,
        Custom: 'CUSTOM' as ReferralStatusConstantEnum,
        Cancelled: 'CANCELLED' as ReferralStatusConstantEnum,
        Rejected: 'REJECTED' as ReferralStatusConstantEnum,
        New: 'NEW' as ReferralStatusConstantEnum,
        Called: 'CALLED' as ReferralStatusConstantEnum,
        Rescheduled: 'RESCHEDULED' as ReferralStatusConstantEnum,
        NoShow: 'NO_SHOW' as ReferralStatusConstantEnum
    };
}


