import {ToastrService} from "ngx-toastr";
import {KeycloakService} from "keycloak-angular";
import {BsModalService} from "ngx-bootstrap/modal";
// import {AuthenticationServiceKeycloak} from "../services/AuthenticationServiceKeycloak";
import * as moment from 'moment';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {EventEmitter, Injectable} from '@angular/core';
import {AsyncSubject, from, Observable, Observer} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {BrowserInfo, BrowserInformationService} from "../services/browser-information.service";
import {PageManager} from "../services/page-manager";
import {SocialService} from "../shared/subject/SocialService";

@Injectable()
export class HttpInterceptorServiceKeycloak implements HttpInterceptor {

  private static TOKEN_NAME = 'TOKEN';
  private static headers: any = {};
  private _lastSeen!: moment.Moment;
  x
  private accountCode: string | undefined;
  private browserInfo: BrowserInfo | undefined;
  private windowsDeviceId: string;


  constructor(
    private toastr: ToastrService,
    private keycloakService: KeycloakService,
    private browserInfoService: BrowserInformationService,
    private pageManager: PageManager,
    private socialService: SocialService,
    private modalService: BsModalService) {

    const lastSeen = localStorage.getItem(HttpInterceptorServiceKeycloak.name + '.lastSeen');
    if (lastSeen) {
      this._lastSeen = moment.unix(parseInt(lastSeen, 10));
    }
    // this.authenticationService.getAccount().subscribe(value => {
    //   this.accountCode = value?.accountCode;
    // });
    this.browserInfo = this.browserInfoService.getBrowserInfo();
  }

  private _httpError: EventEmitter<HttpErrorResponse> = new EventEmitter();

  public get httpError() {
    return this._httpError;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const keyToken = sessionStorage.getItem("keycloak_token");
    const httpHeaders = {} as any;
    const handled: Observable<HttpEvent<any>> = from(this.keycloakService.getToken().catch(reason => console.log(reason)))
      .pipe(mergeMap(token => {
        if (token || keyToken) {
          httpHeaders['Authorization'] = 'bearer ' + (token || keyToken);
        }
        if (this.accountCode) {
          httpHeaders['X-ACCOUNT-CODE'] = this.accountCode;
        }
        if (this.browserInfo && this.browserInfo.browserVersion) {
          httpHeaders['X-BROWSER-VERSION'] = this.browserInfo.browserVersion;
        }
        if (this.windowsDeviceId) {
          httpHeaders['X-WINDOWS_DEVICE_ID'] = this.windowsDeviceId;
        }
        if (token && moment().subtract(environment.sessionTimeout, 'minutes').isAfter(this._lastSeen)) {

        }
        this._lastSeen = moment();
        localStorage.setItem(HttpInterceptorServiceKeycloak.name + '.lastSeen', `${this._lastSeen.valueOf() / 1000}`);
        return next.handle(req.clone({setHeaders: httpHeaders}));
      }));

    const subject: AsyncSubject<HttpEvent<any>> = new AsyncSubject();
    handled.subscribe(subject);


    subject.subscribe(async (event: HttpEvent<any>) => {

      if (event instanceof HttpErrorResponse) {
        if (event.status === 401) {
          this.toastr.info(`Session timed out after ${environment.sessionTimeout} mins of inactivity`, 'Session Timeout',
            {
              disableTimeOut: false
            });
          // await this.authenticationService.login({
          //   redirectUri: window.location.origin
          // });
          return;
        }
        this._httpError.emit(event);
      }
    }, async (err: HttpEvent<any>) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status < 1) {
          // this.toastr.error('Please check your internet connection', 'Failed to contact server');

          // this.showOfflineDialog();
        } else if (err.status === 401) {
          // await this.authenticationService.logout();
          // await this.authenticationService.login({
          //   redirectUri: window.location.origin + '/dashboard'
          // });
          return;
        } else if (err.status === 404) {
          return;
        } else if (err.status === 403) {
          // this.authenticationService.forbidAccess();
          // return;
        }
        this._httpError.emit(err);
      }
    });
    return Observable.create((obs: Observer<HttpEvent<any>>) => {
      subject.subscribe(obs);
    });
  }
}
