import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";


@Injectable({
  providedIn: 'root'
})
export class SubscriptionBillingGuard implements CanActivate {

  constructor(private router: Router, private authentication: AuthenticationServiceKeycloak) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authentication.getUser().subscribe(res => {
      if (res.accounts.length > 0) {
        this.authentication.getAccount().subscribe(account => {
          if (account?.dentalOfficeSubscription?.subscriptionStatus == 'ACTIVE') {
            return this.router.navigate(['/billing']);
          }
        })
      }
    })
    return true;
  }
}
