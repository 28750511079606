<div class="">
  <div class="container-fluid px-0">
    <div class="row d-flex justify-content-between flex-wrap mt-4">
      <div class="col-xl-4 col-sm-6 my-3 my-xl-0">
        <div
          class="d-flex flex-column doffice-bg rounded-4 shadow-lg px-4 p-3"
        >
          <h5 *ngIf="!isInbound">Total Referrals Sent</h5>
          <h5 *ngIf="isInbound">Total Referrals Received</h5>
          <p *ngIf="isInbound" class="">Referral Request that was received.</p>
          <p *ngIf="!isInbound" class="">Referral Request that was sent.</p>

          <h1 class="stat-card-numbers mb-0 mt-3">
            {{ this.data?.totalNumberOfReferralThisMonth || 0 }}
          </h1>
          <div class="d-flex justify-content-between"></div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6 my-3 my-xl-0 ">
        <div class="d-flex flex-column px-3 overview-card refcard p-3">
          <h5>Total Referrals Completed</h5>
          <p>Referral Request that was completed.</p>
          <h1 class="mt-4">
            {{ this.data?.totalNumberCompletedThisMonth || 0 }}
          </h1>

          <div class="d-flex justify-content-end"></div>
        </div>
      </div>
      <div class="col-xl-4 col-sm my-3 my-xl-0">
        <div class="d-flex flex-column px-3 overview-card refcard p-3">
          <h5>Total Referrals Cancelled</h5>
          <p>Referral Request that was cancelled.</p>

          <h1 class=" mt-4">
            {{ this.data?.totalNumberCancelledThisMonth || 0 }}
          </h1>

          <div class="d-flex justify-content-end"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div>
  <div class="container-fluid ">
    <div class="row">

    </div>
  </div>
</div> -->
