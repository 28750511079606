/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupportingDocumentType } from './supporting-document-type';
import { GoogleTemporaryOffice } from './google-temporary-office';


export interface DentalReferralCreationDto { 
    patientId?: number;
    patientFirstName?: string;
    patientLastName?: string;
    patientImageBase64?: string;
    patientInsuranceCode?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    preMedRequired?: boolean;
    priorityLevelConstant?: DentalReferralCreationDto.PriorityLevelConstantEnum;
    postReferralFormBase64?: string;
    referralReasonNotes?: string;
    procedureList?: Array<string>;
    otherProcedures?: string;
    dentalProfessionalId?: number;
    dentalProFromFirstName?: string;
    dentalProFromLastName?: string;
    dentalOfficeIdTo?: number;
    dentalProfIdTo?: number;
    affectedToothRange?: string;
    supportingDocuments?: Array<SupportingDocumentType>;
    patientImageUrl?: string;
    tissueTypeConstant?: DentalReferralCreationDto.TissueTypeConstantEnum;
    temporaryDentalOfficeId?: number;
    googleTemporaryOffice?: GoogleTemporaryOffice;
    externalReferral?: boolean;
    portalAccountId?: number;
    draft?: boolean;
    adult?: boolean;
    consentGiven?: boolean;
}
export namespace DentalReferralCreationDto {
    export type PriorityLevelConstantEnum = 'NORMAL' | 'SEMI_URGENT' | 'URGENT' | 'EMERGENCY';
    export const PriorityLevelConstantEnum = {
        Normal: 'NORMAL' as PriorityLevelConstantEnum,
        SemiUrgent: 'SEMI_URGENT' as PriorityLevelConstantEnum,
        Urgent: 'URGENT' as PriorityLevelConstantEnum,
        Emergency: 'EMERGENCY' as PriorityLevelConstantEnum
    };
    export type TissueTypeConstantEnum = 'NORMAL' | 'SOFT';
    export const TissueTypeConstantEnum = {
        Normal: 'NORMAL' as TissueTypeConstantEnum,
        Soft: 'SOFT' as TissueTypeConstantEnum
    };
}


