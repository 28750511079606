<div
  class="bg-primary ms-lg-2"
  style="border-bottom: 1px solid rgba(209, 209, 209, 0.5)"
>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid px-md-5">
      <button
        class="btn p-1 order-sm-first text-light d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasResponsive"
        aria-controls="offcanvasResponsive"
      >
        <!-- <span class="navbar-toggler-icon"></span> -->
        <iconify-icon icon="ci:menu-alt-03" class="fs-2"></iconify-icon>
      </button>
      <div class="" style="color: white">
        <h5
          class="mb-0"
          style="display: flex; align-items: baseline"
          placement="top"
        >
          <iconify-icon
            icon="ep:office-building"
            style="position: relative; top: 0.5rem"
            width="40"
            height="40"
          >
          </iconify-icon>
          <p class="mb-0 overflow">{{ this.officeName }}</p>
        </h5>
      </div>
      <div class="ddlogo mx-auto">
        <img
          src="./assets/img/ddlogoaltcolor2.svg"
          class="img-fluid"
          alt="DentalDoor Logo"
          width="120"
        />
      </div>
      <div>
        <div class="">
          <div class="d-flex align-items-center">
            <div class="me-4" *ngIf="showRoute">
              <div
                joyrideStep="startReferral"
                text="Click here to start the flow. Refer a Patient"
                stepPosition="bottom"
                routerLink="/out-bound/create-referral"
                *ngIf="!this.userAccount?.dentalProfessional"
                class="pointer btn refer-btn-custom-top rounded-5 d-flex align-items-center justify-content-around p-2"
              >
                <p class="mb-0 mb-0 mx-2">Refer a patient</p>
                <div
                  class="badge rounded-pill text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0 mx-1"
                >
                  <iconify-icon icon="carbon:add" class="fs-5"></iconify-icon>
                </div>
              </div>
            </div>
            <div class="p-2 notify-card">
              <div class="btn-group dropstart">
                <div
                  class="align-items-center d-flex"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <iconify-icon
                    icon="clarity:notification-line"
                    class="fs-4"
                  ></iconify-icon>
                  <span
                    class="badge position-absolute rounded-pill pb-1 text-bg-danger ms-3 mb-3"
                  >{{ this.resultSubject.value.total }}</span
                  >
                </div>

                <div class="">
                  <ul class="dropdown-menu p-2 mt-4 shadow-sm">
                    <div class="mb-4 border-0 border-bottom pb-1">
                      <p class="mb-0 fw-bold small text-muted">Notifications</p>
                    </div>
                    <li
                      class="mb-2"
                      *ngIf="this.resultSubject.value.total != 0; else next"
                    >
                      <a
                        class="dropdown-item border-radius-md"
                        *ngFor="
                          let notify of resultSubject.value.results;
                          let i = index
                        "
                      >
                        <div
                          class="d-flex py-1 pointer"
                          (click)="this.showRoute ? updateReferralStatus(notify): this.shouldShoToolTip()"
                          ngbTooltip="{{!this.showRoute ? 'No subscription available. Kindly subscribe to a plan.': '  '}}">
                          <div class="my-auto me-2">
                            <img
                              src="assets/img/ref-notify.png"
                              width="30"
                              class="avatar avatar-sm me-3"
                            />
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <h6 class="text-sm font-weight-normal mb-1">
                              <span class="font-weight-bold">
                                {{ notify.notificationMessage }}</span
                              >
                            </h6>
                            <p
                              class="text-xs small text-secondary mb-0 d-flex align-items-center"
                            >
                              <iconify-icon
                                icon="arcticons:clock"
                                class="me-1"
                              ></iconify-icon>
                              {{ notify.dateCreated | dateAsAgo }}
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <ng-template class="w-100 text-center" #next>
                      <span class="small mb-0 text-primary text-center"
                      >No Notifications</span
                      >
                    </ng-template>
                  </ul>
                </div>
              </div>
            </div>
            <div class="dropdown p-3 pe-0 user-avatar btn-group d-md-none">
              <div
                class="d-flex justify-content-between align-items-center pointer text-truncate"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center">
                  <img
                    [src]=getFileURL()
                    alt="user profile image"
                    width="40"
                    height="40"
                    class="rounded-circle rounded me-2"
                  />
                </div>
                <div class="">
                  <ul class="dropdown-menu shadow text-small">
                    <li
                      class="d-flex display-content-center align-items-center"
                    >
                      <a class="dropdown-item" [routerLink]="['/accounts']"
                      >
                        <iconify-icon
                          icon="carbon:user-avatar"
                          style="
                            color: #02183d9a;
                            margin-right: 5px;
                            margin-bottom: -3px;
                          "
                          width="18"
                          height="18"
                        ></iconify-icon>
                        Profile</a
                      >
                    </li>
                    <!--                    <li class=" dropend">-->
                    <!--                      <a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown"><iconify-icon icon="ant-design:user-switch-outlined" style="color: #02183d9a; margin-right: 5px; margin-bottom: -3px;" width="18" height="18"></iconify-icon> Switch Account </a>-->
                    <!--                      <ul-->
                    <!--                        class="dropdown-menu second-dropdown p-3"-->
                    <!--                      >-->
                    <!--                        <li class="dropdown-header mb-2 fw-bold h6">My Accounts</li>-->
                    <!--                        <li>-->
                    <!--                          <h6 class="dropdown-header text-muted">Dental Offices</h6>-->
                    <!--                        </li>-->

                    <!--                        <li>-->
                    <!--                          <a class="dropdown-item py-2" href="#">-->
                    <!--                            <div class="d-flex align-items-center">-->
                    <!--                              <img-->
                    <!--                                src="./assets/img/user.png"-->
                    <!--                                alt=""-->
                    <!--                                width="40"-->
                    <!--                                height="40"-->
                    <!--                                class="rounded-circle rounded me-2"-->
                    <!--                              />-->
                    <!--                              <div class="user-name-avatar">-->
                    <!--                                <p class="fw-bold mb-0 small">-->
                    <!--&lt;!&ndash;                                  {{&ndash;&gt;-->
                    <!--&lt;!&ndash;                                  !this.user?.dentalProfessional&ndash;&gt;-->
                    <!--&lt;!&ndash;                                    ? this.userAccount?&ndash;&gt;-->
                    <!--&lt;!&ndash;                                    : (this.user?.firstName + " " + this.user?.lastName&ndash;&gt;-->
                    <!--&lt;!&ndash;                                      | titlecase)&ndash;&gt;-->
                    <!--&lt;!&ndash;                                  }}&ndash;&gt;-->
                    <!--                                </p>-->
                    <!--                                <p class="mb-0 fw-light small text-muted">-->
                    <!--                                  {{-->
                    <!--                                  this.user?.dentalProfessional-->
                    <!--                                    ? "Dental Professional"-->
                    <!--                                    : "Dental Office"-->
                    <!--                                  }}-->
                    <!--                                </p>-->
                    <!--                              </div>-->
                    <!--                            </div>-->
                    <!--                          </a>-->
                    <!--                        </li>-->

                    <!--                        <li class="my-2">-->
                    <!--                          <h6 class="dropdown-header text-muted">Dental Professional</h6>-->
                    <!--                        </li>-->
                    <!--                        <li>-->
                    <!--                          <a href="" class="dropdown-item py-2">-->
                    <!--                            <div class="d-flex justify-content-between align-items-center">-->
                    <!--                              <div class="d-flex align-items-center">-->
                    <!--                                <img-->
                    <!--                                  src="./assets/img/user.png"-->
                    <!--                                  alt=""-->
                    <!--                                  width="40"-->
                    <!--                                  height="40"-->
                    <!--                                  class="rounded-circle rounded me-2"-->
                    <!--                                />-->
                    <!--                                <div class="user-name-avatar">-->
                    <!--                                  <p class="fw-bold mb-0 small">-->
                    <!--&lt;!&ndash;                                    {{&ndash;&gt;-->
                    <!--&lt;!&ndash;                                    !this.user?.dentalProfessional&ndash;&gt;-->
                    <!--&lt;!&ndash;                                      ? this.userAccount?.accountName&ndash;&gt;-->
                    <!--&lt;!&ndash;                                      : (this.user?.firstName + " " + this.user?.lastName&ndash;&gt;-->
                    <!--&lt;!&ndash;                                        | titlecase)&ndash;&gt;-->
                    <!--&lt;!&ndash;                                    }}&ndash;&gt;-->
                    <!--                                  </p>-->
                    <!--                                  <p class="mb-0 fw-light small text-muted">-->
                    <!--                                    {{-->
                    <!--                                    this.user?.dentalProfessional-->
                    <!--                                      ? "Dental Professional"-->
                    <!--                                      : "Dental Office"-->
                    <!--                                    }}-->
                    <!--                                  </p>-->
                    <!--                                </div>-->
                    <!--                              </div>-->
                    <!--                              <div>-->
                    <!--                                <iconify-icon-->
                    <!--                                  icon="bi:check-circle"-->
                    <!--                                  class="text-primary"-->
                    <!--                                ></iconify-icon>-->
                    <!--                              </div></div-->
                    <!--                            ></a>-->
                    <!--                        </li>-->
                    <!--                      </ul>-->
                    <!--                    </li>-->
                    <li>
                      <hr class="dropdown-divider"/>
                    </li>
                    <li class="mb-0">
                      <a class="dropdown-item" (click)="logOut()"
                      >
                        <iconify-icon
                          icon="codicon:sign-out"
                          style="
                            color: #02183d9a;
                            margin-right: 5px;
                            margin-bottom: -3px;
                          "
                          width="18"
                          height="18"
                        ></iconify-icon>
                        Logout</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div class="d-lg-none d-block">
    <div
      class="offcanvas-lg offcanvas-start"
      tabindex="-1"
      id="offcanvasResponsive"
      aria-labelledby="offcanvasResponsiveLabel"
      style="width: 7rem"
    >
      <app-sidebar></app-sidebar>
    </div>
  </div>
</div>
