/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReviewSummaryPojo { 
    entityName?: ReviewSummaryPojo.EntityNameEnum;
    entityId?: number;
    ratingBreakdown?: { [key: string]: number; };
    totalReviewsReceived?: number;
    averageReview?: number;
}
export namespace ReviewSummaryPojo {
    export type EntityNameEnum = 'DentalOffice' | 'DentalProfessional' | 'Promotion' | 'Review' | 'FlashCardSet' | 'Comment';
    export const EntityNameEnum = {
        DentalOffice: 'DentalOffice' as EntityNameEnum,
        DentalProfessional: 'DentalProfessional' as EntityNameEnum,
        Promotion: 'Promotion' as EntityNameEnum,
        Review: 'Review' as EntityNameEnum,
        FlashCardSet: 'FlashCardSet' as EntityNameEnum,
        Comment: 'Comment' as EntityNameEnum
    };
}


