import {RouterModule, Routes} from "@angular/router";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {AffiliateHomePageComponent} from "./utils/affiliate-home-page/affiliate-home-page.component";
import {AffiliateWizardComponent} from "./sign-up/affiliate-wizard/affiliate-wizard.component";
import {AffiliateSearchComponent} from "./linking/affiliate-search/affiliate-search.component";
import {LoggedInGuard, RoutePermissionRestriction} from "../../shared/logged-in-guard/logged-in.guard";
import {PortalAccountTypeRoleSearchFilter} from "dd-core-api-sdk";
import AccountTypeEnum = PortalAccountTypeRoleSearchFilter.AccountTypeEnum;
import {AffiliateInviteSignupComponent} from "./sign-up/affiliate-invite-signup/affiliate-invite-signup.component";
import { AffiliateDashboardHeaderComponent } from "./utils/affiliate-dashboard-header/affiliate-dashboard-header.component";
import {AffiliateWithdrawalComponent} from "./withdrawal/affiliate-withdrawal/affiliate-withdrawal.component";
import { AffiliateProfileSettingsComponent } from "./settings/affiliate-profile-settings/affiliate-profile-settings.component";


const routes: Routes = [
  {
    path: '',
    component: AffiliateHomePageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true,
      showNavBar: true,
      showFooter: true,
      isAffiliate: true
    },
  },
  {
    path: 'invite/:code',
    component: AffiliateInviteSignupComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true,
      showNavBar: false,
      showFooter: false,
    },
  },
  {
    path: 'signup',
    component: AffiliateWizardComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true,
      showNavBar: false,
      showFooter: false,
    },
  },
  {
    path: 'linking',
    component: AffiliateSearchComponent,
    pathMatch: 'full',
    data: {
      isExtranet: false,
      showNavBar: false,
      showFooter: false,
      permissions: [
        {
          accountType: AccountTypeEnum.Affiliate,
        },
      ] as RoutePermissionRestriction[]
    },
    canActivate: [LoggedInGuard]
  },
  {
    path: 'withdraw',
    component: AffiliateWithdrawalComponent,
    pathMatch: 'full',
    data: {
      isExtranet: false,
      showNavBar: false,
      showFooter: false,
      permissions: [
        {
          accountType: AccountTypeEnum.Affiliate,
        },
      ] as RoutePermissionRestriction[]
    },
    canActivate: [LoggedInGuard]
  },
  {
    path: 'settings',
    component: AffiliateProfileSettingsComponent,
    pathMatch: 'full',
    data: {
      isExtranet: false,
      showNavBar: false,
      showFooter: false,
      permissions: [
        {
          accountType: AccountTypeEnum.Affiliate,
        },
      ] as RoutePermissionRestriction[]
    },
    canActivate: [LoggedInGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AffiliateRoutingModule {
}
