import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {DashboardHolderComponent} from "./layout/dashboard-holder/dashboard-holder.component";
import {CommonModule, KeyValuePipe} from "@angular/common";
import {DashboardRoutingModule} from "./dashboard-routing.module";
import {SharedModule} from "../../shared/shared.module";
import {LayoutComponent} from './layout/layout.component';
import {DashboardHeaderComponent} from './layout/dashboard-header/dashboard-header.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {DashboardFooterComponent} from './layout/dashboard-footer/dashboard-footer.component';
import {ChartsModule} from "ng2-charts";
import { DashboardHelpComponent } from '../../shared/dashboard-help/dashboard-help.component';
import { DashboardSwitchComponent } from './layout/dashboard-switch/dashboard-switch.component';
import {AdminModule} from "../admin/admin.module";
import {NgbDatepickerModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {JoyrideModule} from "ngx-joyride";
import {AffiliateModule} from "../affiliates/affiliate.module";
import {ClipboardModule} from "ngx-clipboard";


@NgModule({
  declarations: [
    DashboardHolderComponent,
    LayoutComponent,
    DashboardHeaderComponent,
    SidebarComponent,
    DashboardFooterComponent,
    DashboardSwitchComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        DashboardRoutingModule,
        ChartsModule,
        AdminModule,
        NgbDatepickerModule,
        JoyrideModule,
        NgbTooltipModule,
        AffiliateModule,
        ClipboardModule,
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [KeyValuePipe],
    exports: [
        SidebarComponent,
        DashboardHeaderComponent,
        DashboardFooterComponent,
    ]
})
export class DashboardModule {
}
