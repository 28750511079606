import {NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from '@angular/core';
import * as moment from "moment";
import {toInteger} from "lodash";

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  readonly DT_FORMAT = 'MM-DD-YYYY';

  parse(value: string): NgbDateStruct {
    if (value) {
      value = moment(value.trim(), this.DT_FORMAT).toString();
      return {day: toInteger(value.split('-')[0]), month: toInteger(value.split('-')[1]), year: toInteger(value.split('-')[2])};
    }
    return null;
  }
  //
  format(date: NgbDateStruct): string {
    if (!date) return '';
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.DT_FORMAT);
  }

  // parse(value: string): NgbDateStruct {
  //   if (value) {
  //     const dateParts = value.trim().split('-');
  //     if (dateParts.length === 1 && isNumber(dateParts[0])) {
  //       return {day: toInteger(dateParts[0]), month: null, year: null};
  //     } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
  //       return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null};
  //     } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
  //       return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: toInteger(dateParts[2])};
  //     }
  //   }
  //   return null;
  // }

  // format(date: NgbDateStruct): string {
  //   return date ?
  //     `${isNumber(date.day) ? padNumber(date.day) : ''}-${isNumber(date.month) ? padNumber(date.month) : ''}-${date.year}` :
  //     '';
  // }

}
