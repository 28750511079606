/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InviteSearchFilter { 
    offset?: number;
    limit?: number;
    order?: InviteSearchFilter.OrderEnum;
    orderColumn?: string;
    dentalProfessional?: number;
    dentalOffice?: number;
    invitationStatus?: InviteSearchFilter.InvitationStatusEnum;
    officeName?: string;
    name?: string;
    invitedOfficeName?: string;
    typeConstant?: InviteSearchFilter.TypeConstantEnum;
    endDate?: string;
    startDate?: string;
}
export namespace InviteSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type InvitationStatusEnum = 'PENDING' | 'ACCEPTED' | 'DECLINED';
    export const InvitationStatusEnum = {
        Pending: 'PENDING' as InvitationStatusEnum,
        Accepted: 'ACCEPTED' as InvitationStatusEnum,
        Declined: 'DECLINED' as InvitationStatusEnum
    };
    export type TypeConstantEnum = 'DENTAL_PRACTITIONER' | 'USER' | 'DENTAL_OFFICE_ADMIN' | 'DENTAL_OFFICE';
    export const TypeConstantEnum = {
        DentalPractitioner: 'DENTAL_PRACTITIONER' as TypeConstantEnum,
        User: 'USER' as TypeConstantEnum,
        DentalOfficeAdmin: 'DENTAL_OFFICE_ADMIN' as TypeConstantEnum,
        DentalOffice: 'DENTAL_OFFICE' as TypeConstantEnum
    };
}


