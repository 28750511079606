/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CommissionLogsFilter { 
    offset?: number;
    limit?: number;
    order?: CommissionLogsFilter.OrderEnum;
    orderColumn?: string;
    endDate?: string;
    startDate?: string;
    name?: string;
    subscriptionStatusConstant?: CommissionLogsFilter.SubscriptionStatusConstantEnum;
    portalUserId?: number;
}
export namespace CommissionLogsFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type SubscriptionStatusConstantEnum = 'ACTIVE' | 'EXPIRED' | 'PENDING' | 'CANCELLED' | 'TRIAL';
    export const SubscriptionStatusConstantEnum = {
        Active: 'ACTIVE' as SubscriptionStatusConstantEnum,
        Expired: 'EXPIRED' as SubscriptionStatusConstantEnum,
        Pending: 'PENDING' as SubscriptionStatusConstantEnum,
        Cancelled: 'CANCELLED' as SubscriptionStatusConstantEnum,
        Trial: 'TRIAL' as SubscriptionStatusConstantEnum
    };
}


