<app-full-page-loader *ngIf="uploadingImage" [opacity]="0.4"></app-full-page-loader>


<div class="container">
  <div class="d-flex align-items-center text-dark">
    <p class="fs-4 fw-bold my-3">
      <iconify-icon inline icon="clarity:settings-line" class="fs-3 me-2"></iconify-icon>
      Settings
    </p>
  </div>
  <section id="top" class=" mt-4">
    <div class="row mx-1">
      <div class="card bg-transparent rounded-0 img-card col-auto border-0 mb-3">
        <figure class="figure">
          <input style="display:none" #filePicker (change)='onImagePicked($event)' type="file" accept="image/*">
          <img [src]=getFileURL() class="img-fluid" alt="" style="width: 100%; height: 100%">
          <figcaption class="fig-caption text-center mt-1 pointer"
                      (click)='filePicker.click()'
          ><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1246_40339)">
                                      <path
                                        d="M5.5464 11.3194C6.0365 11.3194 6.50653 11.1247 6.85308 10.7781C7.19963 10.4316 7.39432 9.96155 7.39432 9.47145C7.39432 8.98135 7.19963 8.51133 6.85308 8.16478C6.50653 7.81823 6.0365 7.62354 5.5464 7.62354C5.0563 7.62354 4.58628 7.81823 4.23973 8.16478C3.89318 8.51133 3.69849 8.98135 3.69849 9.47145C3.69849 9.96155 3.89318 10.4316 4.23973 10.7781C4.58628 11.1247 5.0563 11.3194 5.5464 11.3194Z"
                                        fill="#2F8AA8"/>
                                      <path
                                        d="M17.2497 16.2472C17.2497 16.9007 16.9901 17.5274 16.528 17.9894C16.0659 18.4515 15.4392 18.7111 14.7858 18.7111H2.46633C1.81287 18.7111 1.18617 18.4515 0.724098 17.9894C0.262029 17.5274 0.00244141 16.9007 0.00244141 16.2472V6.39164C0.00244108 5.73861 0.26169 5.11228 0.723226 4.65028C1.18476 4.18828 1.81083 3.92841 2.46387 3.92776C2.46387 3.27429 2.72345 2.64759 3.18552 2.18552C3.64759 1.72345 4.27429 1.46387 4.92776 1.46387H17.2472C17.9007 1.46387 18.5274 1.72345 18.9894 2.18552C19.4515 2.64759 19.7111 3.27429 19.7111 3.92776V13.7833C19.7111 14.4363 19.4518 15.0627 18.9903 15.5247C18.5288 15.9867 17.9027 16.2465 17.2497 16.2472ZM17.2472 2.69581H4.92776C4.60102 2.69581 4.28767 2.82561 4.05664 3.05664C3.8256 3.28767 3.69581 3.60102 3.69581 3.92776H14.7858C15.4392 3.92776 16.0659 4.18734 16.528 4.64941C16.9901 5.11148 17.2497 5.73818 17.2497 6.39164V15.0153C17.576 15.0146 17.8887 14.8845 18.1192 14.6536C18.3497 14.4226 18.4791 14.1096 18.4791 13.7833V3.92776C18.4791 3.60102 18.3493 3.28767 18.1183 3.05664C17.8873 2.82561 17.5739 2.69581 17.2472 2.69581ZM2.46633 5.1597C2.1396 5.1597 1.82625 5.28949 1.59521 5.52053C1.36418 5.75156 1.23439 6.06491 1.23439 6.39164V16.2472L4.49411 13.3472C4.5948 13.2469 4.72709 13.1845 4.86855 13.1705C5.01 13.1566 5.15192 13.1921 5.27024 13.2708L8.54721 15.4551L13.1177 10.8845C13.2091 10.7931 13.3269 10.7327 13.4544 10.7119C13.582 10.6911 13.7129 10.711 13.8286 10.7687L16.0177 13.1673V6.39164C16.0177 6.06491 15.8879 5.75156 15.6569 5.52053C15.4259 5.28949 15.1125 5.1597 14.7858 5.1597H2.46633Z"
                                        fill="#2F8AA8"/>
                                  </g>
                                  <defs>
                                      <clipPath id="clip0_1246_40339">
                                          <rect width="19.7111" height="19.7111" fill="white"
                                                transform="translate(-0.000244141 0.231934)"/>
                                      </clipPath>
                                  </defs>
                              </svg></span> <span class="small text-primary"> Change image</span></figcaption>
        </figure>
      </div>
      <div class="card rounded-0 img-card2 col-md shadow-sm mb-3 py-5 px-4 border-0">
        <div class="row">
          <div class="card bg-transparent col-sm rounded-0 border-0">
            <h5>{{affiliateProfile?.firstName + " " + affiliateProfile?.lastName}}</h5>
            <p>Affiliate User</p>
          </div>
          <div class="card col bg-transparent rounded-0 border-0 text-sm-end">
            <p class="mb-0">Account created</p>
            <p class="fw-bold mt-0">{{affiliateProfile?.createdAt | date}}</p>
          </div>
        </div>
        <div class="row g-3 mt-2">
          <div class="d-grid gap-2 d-md-block">
            <button ngxClipboard (cbOnSuccess)="contentCopied($event) " [cbContent]=user?.affiliateCode type="button"
                    class="btn btn-primary px-5 rounded-1 lh-sm" name="copy link button"><span><svg width="20"
                                                                                                    height="20"
                                                                                                    viewBox="0 0 16 15"
                                                                                                    fill="none"
                                                                                                    xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M6.125 11.25C5.78125 11.25 5.48708 11.1277 5.2425 10.8831C4.9975 10.6381 4.875 10.3438 4.875 10V2.5C4.875 2.15625 4.9975 1.86187 5.2425 1.61687C5.48708 1.37229 5.78125 1.25 6.125 1.25H11.75C12.0938 1.25 12.3881 1.37229 12.6331 1.61687C12.8777 1.86187 13 2.15625 13 2.5V10C13 10.3438 12.8777 10.6381 12.6331 10.8831C12.3881 11.1277 12.0938 11.25 11.75 11.25H6.125ZM6.125 10H11.75V2.5H6.125V10ZM3.625 13.75C3.28125 13.75 2.98687 13.6277 2.74187 13.3831C2.49729 13.1381 2.375 12.8438 2.375 12.5V4.375C2.375 4.19792 2.435 4.04938 2.555 3.92938C2.67458 3.80979 2.82292 3.75 3 3.75C3.17708 3.75 3.32562 3.80979 3.44562 3.92938C3.56521 4.04938 3.625 4.19792 3.625 4.375V12.5H9.875C10.0521 12.5 10.2006 12.56 10.3206 12.68C10.4402 12.7996 10.5 12.9479 10.5 13.125C10.5 13.3021 10.4402 13.4504 10.3206 13.57C10.2006 13.69 10.0521 13.75 9.875 13.75H3.625ZM6.125 2.5V10V2.5Z"
                                        fill="white"/>
                                  </svg></span> Copy affiliate code
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="form-card" class="mt-4">
    <!-- <div class="text-secondary col-md m-2"> -->
    <div class="row gx-3 mx-1">
      <div class="overview-card rounded rounded-sm  pt-4">
        <!-- </div> -->
        <div class="border-0 rounded-0">
          <ul class="nav nav-pills mb-3" id="pills-tab">
            <li class="nav-item" role="presentation">
              <button (click)="selectTab('profile')" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                      [attr.aria-selected]="tabSwitch == 'profile'" [ngClass]="{ active: tabSwitch == 'profile' }">
                Profile Settings
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button (click)="selectTab('forgotPassword')" class="nav-link" id="pills-forgotPassword-tab"
                      data-bs-toggle="pill" data-bs-target="#pills-forgotPassword" type="button" role="tab"
                      aria-controls="pills-forgotPassword" [attr.aria-selected]="tabSwitch == 'forgotPassword'"
                      [ngClass]="{ active: tabSwitch == 'forgotPassword' }">Password Settings
              </button>
            </li>

          </ul>
          <div class="tab-content" id="pills-tabContent" [ngSwitch]="tabSwitch">
            <div *ngSwitchCase="'profile'">
              <div *ngIf="showErrorMessageTrigger">
                <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show"
                     role="alert"
                     [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                  <p class="m-0 p-0 d-flex align-items-start">
                        <span>
                          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ getErrorMessage() | removeUnderscores }}
                        </span>
                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                  </p>
                </div>
              </div>
              <div class="row px-2 my-3">
                <form [formGroup]="nameForm" class="mt-4">

                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-lg">
                        <div class="p-2 header-bg col" style="background: rgba(138, 152, 190, 0.09)">
                          <h6 class="my-2 px-2">PERSONAL INFORMATION</h6>
                        </div>
                        <hr class="hr line">
                        <section id="profile-info1">
                          <div class="row px-2">
                            <div class=" mb-3 col-12">
                              <label for="firstName" class="rfr-label ">Firstname <span class="text-danger">*</span></label>
                              <input type="text" class="form-control p-2" id="firstName"
                                     placeholder="{{affiliateProfile?.firstName}}" formControlName="firstName">
                              <div
                                *ngIf="nameForm?.get('firstName')?.invalid && (nameForm?.get('firstName')?.dirty || nameForm?.get('firstName')?.touched)"
                                class="alert alert-danger">
                                <div *ngIf="nameForm?.get('firstName')?.errors?.required">
                                  First Name is required.
                                </div>
                              </div>
                            </div>
                            <div class="mb-3 col-12">
                              <label for="lastName" class="rfr-label ">Lastname <span class="text-danger">*</span></label>
                              <input type="text" class="form-control p-2" id="lastName"
                                     placeholder="{{affiliateProfile?.lastName}}" formControlName="lastName"/>
                              <div
                                *ngIf="nameForm?.get('lastName')?.invalid && (nameForm?.get('lastName')?.dirty || nameForm?.get('lastName')?.touched)"
                                class="alert alert-danger">
                                <div *ngIf="nameForm?.get('lastName')?.errors?.required">
                                  Last Name is required.
                                </div>
                              </div>
                            </div>
                            <div class="mb-3 col-12">
                              <label for="email" class="rfr-label ">Email <span class="text-danger readonly">*</span></label>
                              <input type="text" class="form-control p-2" id="email" placeholder="e.g. someone@email.com"
                                     formControlName="email" readonly/>
                              <div
                                *ngIf="nameForm?.get('email')?.invalid && (nameForm?.get('email')?.dirty || nameForm?.get('email')?.touched)"
                                class="alert alert-danger">
                                <div *ngIf="nameForm?.get('email')?.errors?.required">
                                  Email is required.
                                </div>
                              </div>
                            </div>
                            <div class="mb-3 col-12 readonly">
                              <label for="phoneNumber" class="rfr-label ">Phone Number</label>
                              <!--                            <input type="text" class="form-control p-2" id="phoneNumber" placeholder="e.g. +1935739485"-->
                              <!--                                   formControlName="phoneNumber" readonly/>-->
                              <ngx-intl-tel-input
                                [cssClass]="'form-control w-100 phone'"
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true"
                                id='phoneNumber'
                                [maxLength]="'20'"
                                [phoneValidation]="true"
                                [preferredCountries]="preferredCountries"
                                [searchCountryField]="[
                              SearchCountryField.Iso2,
                              SearchCountryField.Name]"
                                [searchCountryFlag]="true"
                                [selectedCountryISO]="countryISO"
                                [selectFirstCountry]="true"
                                formControlName="phoneNumber"
                              ></ngx-intl-tel-input>
                              <div
                                *ngIf="nameForm.get('phoneNumber')?.errors?.required &&
                               nameForm.get('phoneNumber')?.touched"
                                class="alert alert-danger">
                                <div>
                                  phone Number is required
                                </div>
                              </div>
                              <div *ngIf="nameForm.get('phoneNumber')!.errors?.phoneNumberExists"
                                   class="alert alert-danger">
                                <div>
                                  Phone number already exist.
                                </div>
                              </div>
                              <div *ngIf="nameForm.get('phoneNumber')!.errors?.invalidPhoneNumber"
                                   class="alert alert-danger">
                                <div>
                                  Phone number is invalid.
                                </div>
                              </div>

                            </div>
                          </div>
                        </section>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="col-lg">
                        <div class="p-2 header-bg col" style="background: rgba(138, 152, 190, 0.09)">
                          <h6 class="my-2 px-2">ADDRESS</h6>
                        </div>
                        <hr class="hr line">

                        <section id="profile-info2">
                          <div class="row">
                            <div class="mb-3">
                              <label for="country" class="rfr-label ">Country</label>
                              <select id="country" class="form-select" aria-label="Default select example" readonly="true">
                                <option selected>United States of America</option>
                              </select>
                            </div>
                            <div class="mb-3">
                              <label for="streetAddress" class="rfr-label ">Street Address/Zip</label>
                              <input type="text" class="form-control p-2" id="streetAddress"
                                     placeholder="{{affiliateProfile?.address?.streetAddress}}"
                                     formControlName="streetAddress"/>
                              <div
                                *ngIf="nameForm?.get('streetAddress')?.invalid && (nameForm?.get('streetAddress')?.dirty || nameForm?.get('streetAddress')?.touched)"
                                class="alert alert-danger">
                                <div *ngIf="nameForm?.get('streetAddress')?.errors?.required">
                                  Street address is required.
                                </div>
                              </div>

                              <div
                                *ngIf="nameForm.get('streetAddress')?.errors?.minlength && nameForm.get('streetAddress').touched"
                                class="alert alert-danger">
                                <div>
                                  Street address is too short.
                                </div>
                              </div>
                              <div
                                *ngIf="nameForm.get('streetAddress')?.errors?.maxlength && nameForm.get('streetAddress').touched"
                                class="alert alert-danger">
                                <div>
                                  Street address is too long.
                                </div>
                              </div>


                            </div>
                            <div class="mb-3">
                              <label for="state" class="rfr-label ">State</label>
                              <select id="state" class="form-select" formControlName="state">
                                <option selected>Select a state...</option>
                                <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
                              </select>
                              <div
                                *ngIf="nameForm?.get('state')?.invalid && (nameForm?.get('state')?.dirty || nameForm?.get('state')?.touched)"
                                class="alert alert-danger">
                                <div *ngIf="nameForm?.get('state')?.errors?.required">
                                  State is required.
                                </div>
                              </div>
                            </div>
                            <div class="mb-3">
                              <label for="city" class="rfr-label ">City</label>
                              <select id="city" class="form-select" formControlName="city">
                                <option selected>Select City...</option>
                                <option *ngFor="let city of cities" [value]="city?.id">{{city?.name}}</option>
                              </select>
                              <div
                                *ngIf="nameForm?.get('city')?.invalid && (nameForm?.get('city')?.dirty || nameForm?.get('city')?.touched)"
                                class="alert alert-danger">
                                <div *ngIf="nameForm?.get('city')?.errors?.required">
                                  City is required.
                                </div>
                              </div>
                            </div>

                          </div>
                        </section>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-primary rounded-1 float-end"
                              [disabled]="submitting" (click)="nameForm?.invalid?  submittedForError() : submitUpdate()">
                        <iconify-icon icon="eos-icons:bubble-loading" style="color: #ffffff"  *ngIf="submitting"></iconify-icon>
                        Save Changes
                      </button>
                    </div>

                  </div>
                </form>
              </div>
            </div>
            <div *ngSwitchCase="'forgotPassword'">
              <div *ngIf="showPasswordTrigger">
                <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show"
                     role="alert"
                     [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                  <p class="m-0 p-0 d-flex align-items-start">
                        <span>
                          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ showPasswordMessage() | removeUnderscores }}
                        </span>
                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                  </p>
                </div>
              </div>
              <app-change-password (emitSuccessResponse)="passwordChangeSuccess($event)"
                                   (emitErrorResponse)="passwordError($event)"></app-change-password>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
