<div class="container main">

    <div class="d-flex justify-content-center">
        <div class="col-md-6">
            <div class="alert alert-danger text-center small" *ngIf="showErrorMessageTrigger">{{getErrorMessage()}}</div>
            <div class="alert alert-success text-center small" *ngIf="showSuccessMessageTrigger">{{getSuccessMessage()}}</div>
        </div>
    </div>
    <div class=" text-center" *ngIf="!toDashboard">
        <h2 class="text-uppercase mb-4">Verification Code</h2>
        <div class="card-body pt-2 justify-content-center">
            <h6 class="mt-4 mb-1">Enter the code sent to
                <p class="text-primary text-truncate digit-p">{{otpEmail}} or {{otpPhone}}
                </p>
            </h6>
            <ng-otp-input #otpInput class="p-3" (onInputChange)="onOtpChange($event)" [config]="{length: 6, allowNumbersOnly: true, isPasswordInput: false, inputClass: 'm-2'}">
                <ng-otp-input-wrapper ngClass="otp-input-wrapper"></ng-otp-input-wrapper>
            </ng-otp-input>
            <h6>Didn't receive Code? <a *ngIf="!intervalStart" (click)="getOtp()" class="link-primary link pointer">Resend
        Code</a>
            </h6>
            <div>
                <p class="m-0 small text-secondary fw-normal">
                    If you didn't get it. Click "Resend Code" <span *ngIf="otpCountDown > 0 && intervalStart">in
                    <strong>{{otpCountDown}}</strong> seconds</span> to resend.
                </p>
            </div>

            <div class="mt-5 mb-3 mb-lg-4 text-center">
                <button type="button" class="btn btn-primary btn-lg w-100 mb-1 rounded-0" (click)="validateOTP()" [disabled]="verifyingOtp">Verify
        </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="this.verifyingOtp">
    <app-full-page-loader></app-full-page-loader>
</div>