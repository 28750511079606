/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RedirectViewServletContextSessionCookieConfig { 
    httpOnly?: boolean;
    comment?: string;
    maxAge?: number;
    path?: string;
    domain?: string;
    secure?: boolean;
    name?: string;
}

