/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewUserDto { 
    accountCode: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    gender?: NewUserDto.GenderEnum;
    userType?: NewUserDto.UserTypeEnum;
    type?: NewUserDto.TypeEnum;
    accountRoles?: Array<string>;
    country?: number;
    fromSocialProvider?: boolean;
    roles: { [key: string]: Set<string>; };
    phoneNumber?: string;
}
export namespace NewUserDto {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type UserTypeEnum = 'PATIENT' | 'DENTAL_PROFESSIONAL' | 'AFFILIATE' | 'STUDENT' | 'SERVICE_PROVIDER' | 'GENERAL';
    export const UserTypeEnum = {
        Patient: 'PATIENT' as UserTypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as UserTypeEnum,
        Affiliate: 'AFFILIATE' as UserTypeEnum,
        Student: 'STUDENT' as UserTypeEnum,
        ServiceProvider: 'SERVICE_PROVIDER' as UserTypeEnum,
        General: 'GENERAL' as UserTypeEnum
    };
    export type TypeEnum = 'DENTAL_DOOR_ADMIN' | 'DENTAL_OFFICE' | 'DENTAL_PROFESSIONAL' | 'DENTAL_SPECIALIST' | 'DENTAL_SPECIALIST_OFFICE' | 'SUPER_ADMIN' | 'AFFILIATE';
    export const TypeEnum = {
        DentalDoorAdmin: 'DENTAL_DOOR_ADMIN' as TypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as TypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as TypeEnum,
        DentalSpecialist: 'DENTAL_SPECIALIST' as TypeEnum,
        DentalSpecialistOffice: 'DENTAL_SPECIALIST_OFFICE' as TypeEnum,
        SuperAdmin: 'SUPER_ADMIN' as TypeEnum,
        Affiliate: 'AFFILIATE' as TypeEnum
    };
}


