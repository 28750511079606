<div class="container-fluid">
  <div class="row g-3">
    <div id="sign-up-container" class="col-lg-5 d-none d-lg-block ps-0">
      <div class="login-side-card">
        <div
          id="option-container"
          class="d-flex justify-content-center align-items-center">
          <div class="col-8 options">
            <div class="d-flex my-4 justify-content-center  mt-5 pointer">
              <img
                [routerLink]="['/']"
                src="assets/img/loginLogo.svg"
                class="img-fluid pointer"
                height="auto"
                width="250"
                alt=""
              />
            </div>
            <div id="header" class="d-flex justify-content-center mb-5">
              <h3 class="login-side-header text-center col-10 mb-5">
                Automate your dental care and referral processes.
              </h3>
            </div>
            <div id="form-options">
              <div id="dental-office-check" class="my-5">
                <label for="dental-office">
                  <div class="login-check-card d-flex justify-content-between" [ngClass]="{'selected-login-check-card ddtype-header-selected': tabIndex == 0}">
                    <div class="d-flex align-items-center p-2 px-4">
                      <img
                        src="/assets/img/do-login-icon.svg"
                        class="img-fluid"
                        alt=""/>
                      <div class="ps-3 py-3">
                        <p class="mb-1 fw-bold fs-5 ddtype-header">
                          Dental Office
                        </p>
                        <p class="mb-0 card-subtitle">
                          Send referrals, invite specialists to the team, manage
                          analytics and stats.
                        </p>
                      </div>
                    </div>

                    <div class="form-check m-3">
                      <input
                        id="dental-office"
                        class="form-check-input"
                        type="radio"
                        name="selector"
                        [checked]="tabIndex == 0"
                        value="office"
                        (change)="changeTab(0)"
                      />
                    </div>
                  </div>
                </label>
              </div>
              <div id="dental-prof-check" class="my-5">
                <label for="dental-prof">
                  <div class="login-check-card d-flex justify-content-between" [ngClass]="{'selected-login-check-card ddtype-header-selected': tabIndex == 1}">
                    <div class="d-flex align-items-center p-2 px-4">
                      <img
                        src="/assets/img/dp-login-icon.svg"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="ps-3 py-3">
                        <p class="mb-1 fw-bold fs-5 ddtype-header">
                          Dental Professional
                        </p>
                        <p class="mb-0 card-subtitle">
                          Receive referrals, process referrals, share report, manage analytics and stats.
                        </p>
                      </div>
                    </div>

                    <div class="form-check m-3">
                      <input
                        id="dental-prof"
                        class="form-check-input"
                        type="radio"
                        name="selector"
                        value="professional"
                        [checked]="tabIndex == 1"
                        (change)="changeTab(1)"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div id="sign-in" class="text-center">
              <p class="mb-0 fs-6">
                Already have an account?
                <a href="/login" class="fw-bold text-decoration-none">Sign In </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg" [ngSwitch]="tabIndex">

      <div class="page-resp-cont d-lg-none px-lg-5 mx-lg-5 px-3 mx-3">
        <div id="options" class="text-center options">
          <div id="logo" class="d-flex my-4 align-items-center justify-content-center">
            <img [routerLink]="['/']" src="assets/img/loginLogo.svg" class="img-fluid" height="auto" width="250"
                 alt=""/>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <h3 class="login-side-header text-center mb-5">
              Automate your dental care and referral processes.
            </h3>
          </div>
          <div>
            <div class="my-5 mt-0 dental-office">
              <div
                (click)="tabIndex = 0"
                class="login-check-card  px-3 d-flex justify-content-between"
                [ngClass]="{'selected-login-check-card ddtype-header-selected': tabIndex == 0}"
              >
                <div class="d-flex align-items-center p-2 px-4">
                  <img src="/assets/img/do-login-icon.svg" class="img-fluid do-image" alt=""/>
                  <div class="ps-3 py-3">
                    <p class="mb-1 fw-bold fs-5 ddtype-header">
                      Dental Office
                    </p>
                    <p class="mb-0 card-subtitle">
                      Send referrals, invite specialists to the team, manage
                      analytics and stats.
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="my-5 mt-0">
              <label for="dental-prof">
                <div
                  (click)="tabIndex = 1"
                  class="login-check-card d-flex justify-content-between"
                  [ngClass]="{'selected-login-check-card ddtype-header-selected': tabIndex == 1}"
                >
                  <div class="d-flex align-items-center p-2 px-4">
                    <img src="/assets/img/dp-login-icon.svg" class="img-fluid do-image" alt=""/>
                    <div class="ps-3 py-3" (click)="tabIndex = 1">
                      <p class="mb-1 fw-bold fs-5 ddtype-header">
                        Dental Professional
                      </p>
                      <p class="mb-0 card-subtitle">
                        Receive referrals, process referrals, share report, manage analytics and stats.
                      </p>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="text-center mb-4">
            <p class="fs-6">
              Already have an account?
              <a href="/login" class="fw-bold text-decoration-none">Sign In </a>
            </p>
          </div>
        </div>
      </div>
      <app-create-dental-office *ngSwitchCase="0" [tabIndex]="tabIndex" [affiliateCode]="affiliateCode"></app-create-dental-office>
      <app-create-dental-professional *ngSwitchCase="1" (emitOfficeNotFoundLink)="changeTab(0)"></app-create-dental-professional>
    </div>
  </div>
</div>
