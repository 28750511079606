import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {SocialService} from "../../../shared/subject/SocialService";

@Component({
  selector: 'app-dental-signup',
  templateUrl: './dental-signup.component.html',
  styleUrls: ['./dental-signup.component.css']
})
export class DentalSignupComponent implements OnInit{
  tabIndex:number;
  type: string;


  constructor(private _activatedRoute: ActivatedRoute,
              public socialService: SocialService) {}

  ngOnInit(): void {
    this.tabIndex = this.socialService.tabSubject.value;
  }

  changeTab(newIndex: number) {
    this.tabIndex = newIndex;
    this.socialService.tabSubject.next(newIndex);
  }
}

