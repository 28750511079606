import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import * as moment from "moment";
import {getOffset, removeUndefinedOrNullFields} from "../../../../models/search-model";
import {PageChangedEvent} from "ngx-bootstrap/pagination";
import {
  AffiliateMemberShipControllerService,
  AffiliateMembershipStatPojo,
  CommissionLogPojo,
  CommissionLogsControllerService,
  CommissionLogsFilter,
  QueryResultsPojoCommissionLogPojo,
  ReferralSearchPojo, UserControllerService, UserPojo
} from "dd-core-api-sdk";
import {BehaviorSubject} from "rxjs";
import {Utils} from "../../../../shared/utils";
import {JoyrideService} from "ngx-joyride";
import SubscriptionStatusConstantEnum = CommissionLogsFilter.SubscriptionStatusConstantEnum;
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {
  OptionSelectResponseComponent
} from "../../../dRefer/outbound-referrals/tooth-selection-warning/option-select-response.component";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {BsModalService} from "ngx-bootstrap/modal";
import {environment} from "../../../../../environments/environment";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import TourRideStatusConstantEnum = UserPojo.TourRideStatusConstantEnum;
import {MoreInfoModalComponent} from "../../../../shared/more-info-modal/more-info-modal.component";

@Component({
  selector: 'app-affiliate-search',
  templateUrl: './affiliate-search.component.html',
  styleUrls: ['./affiliate-search.component.css']
})
export class AffiliateSearchComponent implements OnInit {

  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  form: FormGroup;
  utcOffsetMinutes: string;
  model: NgbDateStruct
  model1: NgbDateStruct
  currentStartDate: any;
  filterData: CommissionLogsFilter = {};
  searching: boolean = false;
  resultArray: any[];
  limit = 10;
  offset: any;
  page: any;
  queryResults!: QueryResultsPojoCommissionLogPojo;
  resultSubject = new BehaviorSubject<QueryResultsPojoCommissionLogPojo>({});
  data: any;
  subscriptionStatusConstantEnum = Utils.enumValues(SubscriptionStatusConstantEnum);
  subscriptionStatusConstant = CommissionLogPojo.SubscriptionStatusConstantEnum;
  affiliateMembershipStat: AffiliateMembershipStatPojo;
  masterSelected: boolean;
  checklist: any;
  checkedList: any;
  sizes: any;
  archiveCommissionIds: any = [];
  user: UserKeycloak;
  tourRideStatus = TourRideStatusConstantEnum;

  constructor(private fb: FormBuilder,
              private authenticationServiceKeycloak: AuthenticationServiceKeycloak,
              private userControllerService: UserControllerService,
              private affiliateMemberShipControllerService: AffiliateMemberShipControllerService, private readonly joyrideService: JoyrideService,
              private commissionLogsControllerService: CommissionLogsControllerService,
              private modalService: BsModalService) {

    this.subscriptionStatusConstantEnum = this.subscriptionStatusConstantEnum
      .filter(v => v != this.subscriptionStatusConstant.Cancelled && v != this.subscriptionStatusConstant.Pending && v != this.subscriptionStatusConstant.Trial).sort();


    this.utcOffsetMinutes = 'UTC' + this.getTimeZone().toString();
  }

  ngOnInit(): void {
    this.masterSelected = false;

    this.form = this.fb.group({
      name: [''],
      subscriptionStatusConstant: [''],
      startDate: [''],
      endDate: [''],
      limit: [''],
      offset: [''],
      singleCommission: [''],
    });

    this.form.controls.startDate.valueChanges.subscribe(v => {
      this.currentStartDate = v;
      if (v != moment(v).format('YYYY-MM-DD')) {
        this.form.patchValue({endDate: ''});
      }
    });

    this.form.controls.endDate.valueChanges.subscribe(v => {
      if (moment(this.currentStartDate) > moment(v)) {
        this.isError = true;
        this.showErrorMessage('Date(from) should be greater than Date(to).');
        this.form.patchValue({endDate: ''});
      }
    });

    this.affiliateMemberShipControllerService.affiliateMembershipStat().subscribe(v => {
      this.affiliateMembershipStat = v;
    });
    this.authenticationServiceKeycloak.getUser().subscribe(v => {
      this.user = v;
    })

    if (this.user?.tourRideStatusConstant == null || this.user?.tourRideStatusConstant == this.tourRideStatus.Affiliate)
      this.joyrideService.startTour(
        {steps: ["one", "two", "three", "four", "five", "six"]} // Your steps order
      ).subscribe((step) => {

        }, (error) => {
        },
        () => {
          this.updateTourRideStatus(TourRideStatusConstantEnum.End);
        });


    this.onSearchClick();
  }

  onSearchClick() {
    if (!this.form?.valid) {
      this.showErrorMessage('Form is invalid.');
      return;
    }
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);

    this.search(filter);
  }

  search(filter: CommissionLogsFilter) {
    this.searching = true;
    this.affiliateMemberShipControllerService.searchAffiliateCommissionHistory({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.offset = v.offset;
      this.queryResults = v;
      this.resultArray = v.results;
      this.resultSubject.next(v);
    }, () => {

    });

    this.data = filter;
  }

  getFilter(limit?: number): CommissionLogsFilter {
    const data = this.form!.value;

    let filter: CommissionLogsFilter = {};
    filter.name = data.name;
    filter.portalUserId = this.user.id;
    filter.subscriptionStatusConstant = data.subscriptionStatusConstant;
    filter.startDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }


  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }

  onPageChange(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.search(filter);
  }

  checkAllCheckBox(ev: any) {
    this.archiveCommissionIds = [];
    this.resultSubject.value.results?.forEach(x => x.checked = ev.target.checked);
    this.resultSubject.value.results.map(i => {
      if (i.checked) {
        this.archiveCommissionIds.push(i.commissionLogId);
      }
    });
  }

  isAllCheckBoxChecked() {
    return this.resultSubject.value.results?.every(p => p.checked);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  formatUTCMoment(dateCreated: string) {
    return moment(dateCreated).subtract(1, 'hours').utc(true).toString();
  }

  archiveCommissionLogs() {
    this.commissionLogsControllerService.archiveCommissionLogs({archiveCommissionLogDto: {commissionLogsIds: this.archiveCommissionIds}}).subscribe(res => {
      this.onSearchClick();
      this.isError = false;
      this.showErrorMessage(this.archiveCommissionIds?.length + " record(s) have been deleted.");
    }, error => {
      this.isError = true;
      this.showErrorMessage("Error in deletion " + this.archiveCommissionIds?.length + " record(s).");
    })
  }

  singleCommissionCheck($event: any, value: any) {
    this.archiveCommissionIds = [];
    this.resultSubject.value.results[value].checked = $event.target.checked;

    this.resultSubject.value.results.map(i => {
      if (i.checked) {
        this.archiveCommissionIds.push(i.commissionLogId);
      }
    });
  }

  showOptionModal() {
    if (this.archiveCommissionIds?.length < 1) {
      this.isError = true;
      this.showErrorMessage("Kindly select a record to archive.");
      return;
    }

    let bsModalRef = this.modalService.show(OptionSelectResponseComponent, {
      initialState: {
        message: 'Are you sure you want to archive this ' + this.archiveCommissionIds?.length + ' record(s).'
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-sm modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.yesClicked.subscribe(v => {
      bsModalRef.hide();
      this.archiveCommissionLogs();
    });
  }

  showMoreInfo(aff: CommissionLogPojo) {
    let bsModalRef = this.modalService.show(MoreInfoModalComponent, {
      initialState: {
        infoTitle: 'Full Details',
        commissionLogPojo: aff,
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.closeClicked.subscribe(v => {
      bsModalRef.hide();
    });
  }

  downloadPDF() {
    var a = document.createElement('a');
    a.href = environment.dentalDoorReportUrl + '/affiliates/linking-csv?limit=' + this.limit + '&offset=' + this.offset + '&ext=pdf' + '&userId=' + this.user.id;
    a.target = '_blank';
    a.download = 'any';
    document.body.appendChild(a);
    a.click();
  }

  updateTourRideStatus(tourRide: TourRideStatusConstantEnum) {
    this.userControllerService.updateTourRide({portalUserId: this.user.id, tourRide: tourRide}).subscribe(res => {
      this.user.tourRideStatusConstant = tourRide;
    });
  }

}
