import {Component, OnInit} from '@angular/core';
import {
  DentalOfficeSubscription, DentalOfficeSubscriptionPojo,
  DentalSubscriptionFilter,
  QueryResultsSubscriptionSummaryPojo,
  ReferralDraftSearchFilter,
  SubscriptionControllerService,
  SubscriptionSummaryPojo
} from "dd-core-api-sdk";
import {PageChangedEvent} from "ngx-bootstrap/pagination";
import {getOffset, removeUndefinedOrNullFields} from "../../../models/search-model";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {SelectionManager} from "dd-shared-lib";
import {Router} from "@angular/router";
import * as moment from "moment";

@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrls: ['./billing-list.component.css']
})
export class BillingListComponent implements OnInit {

  user: UserKeycloak;
  searching: boolean;
  selectionManager: SelectionManager<SubscriptionSummaryPojo, number>;
  filterData: DentalSubscriptionFilter = {};
  offset: number = 0;
  total: number;
  limit: number = 10;
  page: any;
  data: any;
  queryResults!: QueryResultsSubscriptionSummaryPojo;
  currentSubscription: SubscriptionSummaryPojo;
  utcOffsetMinutes: string;

  constructor(
    private authentication: AuthenticationServiceKeycloak,
    private dentalSubmissionService: SubscriptionControllerService,
    private router: Router
  ) {
    this.utcOffsetMinutes = 'UTC'+this.getTimeZone().toString();

    class ItemsSelect extends SelectionManager<any, any> {

      getIdentifier(e: any) {
        return e;
      }

      getState(e: any): number {
        return e.id;
      }

      isSelectable(e: any): boolean {
        return true;
      }
    }

    this.selectionManager = new ItemsSelect();
  }

  ngOnInit(): void {
    this.authentication.getUser().subscribe(user => {
      this.user = user;
      if (!user?.accounts[0].dentalOfficeSubscription){
        this.router.navigate(['/billing/purchase-plan']);
        return;
      }
      this.dentalSubmissionService.getCurrentSubscription({officeId: user.accounts[0].otherData.id})
        .subscribe(res => {
          this.currentSubscription = res;
          console.log(res);
        })
    })

    this.onSearchClick();
  }

  onPageChange(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.offset = filter.offset;
    this.search(filter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.offset = filter.offset;
    this.search(filter)
  }

  onSearchClick() {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);

    this.search(filter);
  }

  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  search(filter: DentalSubscriptionFilter) {
    this.selectionManager.clearSelection();
    this.searching = true;
    this.dentalSubmissionService.getOfficeSubscriptionHistory({
      filter: filter
    }).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.queryResults = v;
      this.total = v.total;
    }, () => {

    });

    this.data = filter;
    // this.data.page = this.page;
  }

  formatUTCMoment(dateCreated: string) {
    return moment(dateCreated).utc(true).toString();
  }

  getFilter(limit?: number): DentalSubscriptionFilter {
    // const data = this.form!.value;

    let filter: ReferralDraftSearchFilter = {};
    // filter.patientFirstName = data.patientName.split(" ")[0];
    // filter.patientLastName = data.patientName.split(" ")[0];
    filter.dentalOfficeId = AuthenticationServiceKeycloak._currentUserAccount?.otherData.id;
    // filter.endDate = data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : undefined;
    // filter.startDate = data.startDate ? moment(data.startDate).format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }

}
