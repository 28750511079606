import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {SelectionManager} from "dd-shared-lib";
import {
  DentalOfficeControllerService, DentalOfficePojo,
  DentalOfficeSearchFilter,
  DentalReferralControllerService, Procedure, QueryResultsPojoDentalOfficePojo,
  QueryResultsPojoReferralSearchPojo,
  ReferralRequestSearchFilter,
  ReferralSearchPojo
} from "dd-core-api-sdk";
import {BehaviorSubject, timer} from "rxjs";
import {Utils} from "../../../shared/utils";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {SocialService} from "../../../shared/subject/SocialService";
import {BsModalService} from "ngx-bootstrap/modal";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import * as moment from "moment";
import {PageChangedEvent} from "ngx-bootstrap/pagination";
import {getOffset, removeUndefinedOrNullFields} from "../../../models/search-model";
import ReferralStatusConstantEnum = ReferralSearchPojo.ReferralStatusConstantEnum;

@Component({
  selector: 'app-admin-dental-office',
  templateUrl: './admin-dental-office.component.html',
  styleUrls: ['./admin-dental-office.component.css']
})
export class AdminDentalOfficeComponent implements OnInit, OnChanges {

  @Input() isInbound: boolean;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  form: FormGroup;
  model: NgbDateStruct
  model1: NgbDateStruct
  selectionManager: SelectionManager<DentalOfficePojo, number>;
  filterData: ReferralRequestSearchFilter = {};
  searching: boolean = false;
  resultArray: any[];
  limit = 10;
  offset: any;
  page: any;
  queryResults!: QueryResultsPojoDentalOfficePojo;
  resultSubject = new BehaviorSubject<QueryResultsPojoDentalOfficePojo>({});
  data: any;
  userAccount: UserKeycloak;
  statusNote?: string;
  currentStartDate: any;
  utcOffsetMinutes: any;
  @Input()
  showAllOffices = true;
  @Input()
  showSubscription = false;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private generalService: SocialService,
              private modalService: BsModalService,
              private authentication: AuthenticationServiceKeycloak,
              private dentalOfficeService: DentalOfficeControllerService) {

    class ItemsSelect extends SelectionManager<any, any> {

      getIdentifier(e: any) {
        return e;
      }

      getState(e: any): number {
        return e.id;
      }

      isSelectable(e: any): boolean {
        return true;
      }
    }

    this.selectionManager = new ItemsSelect();
  }

  ngOnInit(): void {
    this.authentication.getUser().subscribe(res => {
      this.userAccount = res;
      this.utcOffsetMinutes = AuthenticationServiceKeycloak._currentUserAccount?.otherData?.utcOffsetMinutes;
    })

    this.form = this.fb.group({
      dentalOfficeName: [''],
      email: [''],
      startDate: [''],
      endDate: [''],
      limit: [''],
      offset: ['']
    });

    this.form.controls.startDate.valueChanges.subscribe(v => {
      this.currentStartDate = v;
      if (v != moment(v).format('YYYY-MM-DD')) {
        this.form.patchValue({endDate: ''});
      }
    });

    this.form.controls.endDate.valueChanges.subscribe(v => {
      if (moment(this.currentStartDate) > moment(v)) {
        this.showErrorMessage('Date(from) should be greater than Date(to).');
        this.form.patchValue({endDate: ''});
      }
    });

    this.onSearchClick();


  }

  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  onPageChange(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.search(filter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }

  onSearchClick() {
    if (!this.form?.valid) {
      this.toastr.error('Form invalid');
      return;
    }
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);

    this.search(filter);
  }

  search(filter: DentalOfficeSearchFilter) {
    this.selectionManager.clearSelection();
    this.searching = true;
    this.dentalOfficeService.searchDentalOffices({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.offset = v.offset;
      this.queryResults = v;
      this.resultArray = v.results;
      this.resultSubject.next(v);
    }, () => {

    });

    this.data = filter;
  }

  getFilter(limit?: number): DentalOfficeSearchFilter {
    const data = this.form!.value;

    let filter: DentalOfficeSearchFilter = {};
    filter.name = data.dentalOfficeName;
    filter.email = data.email;
    filter.subscriptionFilterInitialized = this.showSubscription;
    if (this.showSubscription) {
      filter.subscriptionStartDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
      filter.subscriptionEndDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    } else {
      filter.startDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
      filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    }

    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showSubscription = changes.showSubscription.currentValue;
    this.showAllOffices = changes.showAllOffices.currentValue;

    this.onSearchClick();
  }

}
