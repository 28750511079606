/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceCompanyService } from './insurance-company-service';
import { BwFile } from './bw-file';
import { PortalUser } from './portal-user';


export interface PortalUserInsuranceProvider { 
    dateCreated?: string;
    dateDeactivated?: string;
    status?: PortalUserInsuranceProvider.StatusEnum;
    membershipNumber?: string;
    id?: number;
    backImage?: BwFile;
    portalUser?: PortalUser;
    frontImage?: BwFile;
    insuranceCompanyService?: InsuranceCompanyService;
}
export namespace PortalUserInsuranceProvider {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


