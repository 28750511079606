import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ProcedureMasterRecordService {

    private _procedureJSON = 'assets/model/Reason.json';
    state: any;

    constructor(private http: HttpClient) {
    }

    public getProcedureJSON() {
        return this.http.get(this._procedureJSON);
    }

}
