/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppointmentType } from './appointment-type';
import { AppointmentReminderFeedback } from './appointment-reminder-feedback';
import { PatientPojo } from './patient-pojo';
import { AppointmentCancellationReason } from './appointment-cancellation-reason';
import { PatientModeOfPaymentPojo } from './patient-mode-of-payment-pojo';
import { Period } from './period';
import { UserPojo } from './user-pojo';
import { DentalService } from './dental-service';
import { DentalProfessionalPojo } from './dental-professional-pojo';
import { AppointmentTimeSlot } from './appointment-time-slot';
import { DentalOfficePojo } from './dental-office-pojo';


export interface AppointmentPojo { 
    dateCreated?: string;
    status?: AppointmentPojo.StatusEnum;
    description?: string;
    startTime?: string;
    endTime?: string;
    minutesDuration?: number;
    comment?: string;
    patientInstruction?: string;
    requesterPatientRelationship?: AppointmentPojo.RequesterPatientRelationshipEnum;
    firstTime?: boolean;
    id?: number;
    appointmentType?: AppointmentType;
    appointmentCancellationReason?: AppointmentCancellationReason;
    patient?: PatientPojo;
    dentalOffice?: DentalOfficePojo;
    dentalProfessional?: DentalProfessionalPojo;
    modeOfPayment?: PatientModeOfPaymentPojo;
    requestedPeriod?: Period;
    category?: AppointmentPojo.CategoryEnum;
    patientFeedback?: Array<AppointmentReminderFeedback>;
    services?: Array<DentalService>;
    requestedSlots?: Array<AppointmentTimeSlot>;
    requestedBy?: UserPojo;
    appointmentNumber?: string;
    qrCodeImage?: number;
    hasReminder?: boolean;
}
export namespace AppointmentPojo {
    export type StatusEnum = 'PROPOSED' | 'PENDING' | 'BOOKED' | 'ARRIVED' | 'FULFILLED' | 'CANCELLED' | 'NOSHOW' | 'ENTERED_IN_ERROR' | 'CHECKED_IN' | 'WAITLIST';
    export const StatusEnum = {
        Proposed: 'PROPOSED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum,
        Booked: 'BOOKED' as StatusEnum,
        Arrived: 'ARRIVED' as StatusEnum,
        Fulfilled: 'FULFILLED' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum,
        Noshow: 'NOSHOW' as StatusEnum,
        EnteredInError: 'ENTERED_IN_ERROR' as StatusEnum,
        CheckedIn: 'CHECKED_IN' as StatusEnum,
        Waitlist: 'WAITLIST' as StatusEnum
    };
    export type RequesterPatientRelationshipEnum = 'SELF' | 'OTHER';
    export const RequesterPatientRelationshipEnum = {
        Self: 'SELF' as RequesterPatientRelationshipEnum,
        Other: 'OTHER' as RequesterPatientRelationshipEnum
    };
    export type CategoryEnum = 'NEW' | 'RESCHEDULED';
    export const CategoryEnum = {
        New: 'NEW' as CategoryEnum,
        Rescheduled: 'RESCHEDULED' as CategoryEnum
    };
}


