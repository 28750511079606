/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReasonForDisapproval } from './reason-for-disapproval';
import { ReferralAssignment } from './referral-assignment';
import { PortalUser } from './portal-user';


export interface DentalOfficeProfessionalSearchFilter { 
    addressFilterInitialized?: boolean;
    name?: string;
    email?: string;
    language?: Set<string>;
    dentalOffice?: Set<number>;
    specialization?: Set<string>;
    address?: string;
    favouritesOnly?: boolean;
    gender?: DentalOfficeProfessionalSearchFilter.GenderEnum;
    officeName?: string;
    endDate?: string;
    startDate?: string;
    id?: number;
    city?: Set<string>;
    country?: Set<number>;
    portalUser?: PortalUser;
    zipCode?: Set<string>;
    countriesWhereLicensed?: Set<number>;
    statesWhereLicensed?: Set<number>;
    licenseNumbers?: Set<string>;
    approvalStatus?: DentalOfficeProfessionalSearchFilter.ApprovalStatusEnum;
    dateOfApprovalProcessing?: string;
    reasonForDenial?: string;
    website?: string;
    professionalSummary?: string;
    facebookUrl?: string;
    twitterUrl?: string;
    linkedInUrl?: string;
    reasonForDisapproval?: ReasonForDisapproval;
    approvalProcessedBy?: PortalUser;
    practicingSince?: string;
    referralAssignments?: Array<ReferralAssignment>;
    state?: Set<string>;
    limit?: number;
    status?: DentalOfficeProfessionalSearchFilter.StatusEnum;
    offset?: number;
}
export namespace DentalOfficeProfessionalSearchFilter {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


