<div
  class="modal modal-xl fade"
  id="exampleModal" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-md-4 rounded-0 border-0">
      <div class="custom-card p-0">
        <div class="">
          <div class="modal-header">
            <h5 class="modal-title">Invites</h5>
            <button type="button" id="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
          </div>
          <div *ngIf="showErrorMessageTrigger">
            <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show" role="alert"
                 [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
              <p class="m-0 p-0 d-flex align-items-start">
                      <span>
                        <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                        {{ getErrorMessage() | removeUnderscores }}
                      </span>
                <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                </iconify-icon>
              </p>
            </div>
          </div>
          <div class="bg-glass">
            <ul class="nav nav-tabs row mb-5 mt-4 mx-auto gap-2">
              <li (click)="changeTab(0)" class="nav-item col-md pointer">
                <a class="nav-link" [ngClass]="{active: tabIndex == 0}">
                  Referral Partners
                </a>
              </li>
              <li (click)="changeTab(1)" class="nav-item col-md pointer">
                <a class="nav-link" [ngClass]="{active: tabIndex == 1}">
                  Dental Professional
                </a>
              </li>
            </ul>
            <div class="pt-2 tab-content tab-pane" [ngSwitch]="tabIndex">
              <app-create-office-invites *ngSwitchCase="0" [tabIndex]="tabIndex" (invitationCount)="getNumberCount($event)"></app-create-office-invites>
              <app-create-prof-invites *ngSwitchCase="1" [tabIndex]="tabIndex"></app-create-prof-invites>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

