<div class="bg-primary ms-lg-2" style="border-bottom: 1px solid rgba(209, 209, 209, 0.5)">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid px-md-5">
      <button class="btn p-1 order-sm-first text-light d-lg-none" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive">
        <!-- <span class="navbar-toggler-icon"></span> -->
        <iconify-icon icon="ci:menu-alt-03" class="fs-2"></iconify-icon>
      </button>
      <div class="" style="color: white">
        <h5 class="mb-0" style="display: flex; align-items: baseline">
          <p class="mb-0 overflow">{{ this.officeName }}</p>
        </h5>
      </div>
      <!--      <div class="ddlogo mx-auto">-->
      <!--        <img-->
      <!--          src="./assets/img/ddlogoaltcolor2.svg"-->
      <!--          class="img-fluid"-->
      <!--          alt="DentalDoor Logo"-->
      <!--          width="120"-->
      <!--        />-->
      <!--      </div>-->
      <div>
        <div class="">
          <div class="d-flex align-items-center">
            <div class="me-4" *ngIf="showRoute">
              <div
                class="pointer btn refer-btn-custom-top rounded-5 d-flex align-items-center justify-content-around p-2"
                ngbTooltip="{{this.showLinkCopied ? 'Link Copied': null}}" ngxClipboard
                (cbOnSuccess)="contentCopied($event)"
                [cbContent]="this.environmentUrl()+ this.userAccount.affiliateCode">
                <p class="mb-0 mb-0 mx-2">Copy Referral Link</p>
                <div
                  class="badge rounded-pill text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0 mx-1">
                  <iconify-icon icon="carbon:add" class="fs-5"></iconify-icon>
                </div>
              </div>
            </div>
            <div class="p-2 notify-card">
              <div class="btn-group dropstart">
                <div
                  class="align-items-center d-flex"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <iconify-icon
                    icon="clarity:notification-line"
                    class="fs-4"
                  ></iconify-icon>
                  <span
                    class="badge position-absolute rounded-pill pb-1 text-bg-danger ms-3 mb-3"
                  >{{ this.resultSubject.value.total || 0}}</span
                  >
                </div>

                <div class="">
                  <ul class="dropdown-menu p-2 mt-4 shadow-sm">
                    <div class="mb-4 border-0 border-bottom pb-1">
                      <p class="mb-0 fw-bold small text-muted">Notifications</p>
                    </div>
                    <li class="mb-2" *ngIf="this.resultSubject.value.total != 0; else next">
                      <a class="dropdown-item border-radius-md"
                         *ngFor="let referral of resultSubject.value.results;let i = index">
                        <div class="d-flex py-1 pointer" (click)="updateReferralStatus(referral.notificationId)">
                          <div class="my-auto me-2">
                            <img
                              src="../../../../../assets/img/ref-notify.png"
                              width="30"
                              class="avatar avatar-sm me-3"
                            />
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="text-sm font-weight-normal mb-1">
                                                      <span class="font-weight-bold">
                                                        {{ referral.notificationMessage }}</span>
                            </h6>
                            <p class="text-xs small text-secondary mb-0 d-flex align-items-center">
                              <iconify-icon
                                icon="arcticons:clock"
                                class="me-1"></iconify-icon>
                              {{ referral.dateCreated | dateAsAgo }}
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <ng-template class="w-100 text-center" #next>
                      <span class="small mb-0 text-primary text-center">No Notifications</span>
                    </ng-template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div class="d-lg-none d-block">
    <div class="offcanvas-lg offcanvas-start" tabindex="-1" id="offcanvasResponsive"
         aria-labelledby="offcanvasResponsiveLabel" style="width: 7rem">
      <app-affiliate-sidebar></app-affiliate-sidebar>
    </div>
  </div>
</div>
