import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  DentalOfficeControllerService,
  DentalOfficePojo, DentalOfficeProfessionalControllerService, DentalProfessionalControllerService,
  DentalProfessionalPojo, InvitationDTO, InvitationListDTO,
  InvitationPojo, InviteSearchControllerService,
  InviteSearchFilter,
  QueryResultsPojoInvitationPojo, ResourceVerificationControllerService
} from 'dd-core-api-sdk';
import {Observable} from "rxjs";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {getOffset, removeUndefinedOrNullFields} from 'src/app/models/search-model';
import {HttpErrorResponse} from "@angular/common/http";
import {PhoneNumberValidator} from "dd-shared-lib";
import {Router} from "@angular/router";
import {UniqueIdValidatorService} from "../../../shared/unique-id-validator.service";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {map} from "rxjs/operators";
import {DeliverableEmailValidator} from "../../../services/deliverable-email.validator";

@Component({
  selector: 'app-create-prof-invites',
  templateUrl: './create-prof-invites.component.html',
  styleUrls: ['./create-prof-invites.component.css'],
  providers: [DeliverableEmailValidator]
})
export class CreateProfInvitesComponent implements OnInit {

  @Input() subscriptionType: string;
  form: FormGroup;
  working = false;
  dentalProfessional: DentalProfessionalPojo;
  dentalProfessionals: DentalProfessionalDto[] = [];
  invites$: Observable<QueryResultsPojoInvitationPojo>;
  inviteResult: QueryResultsPojoInvitationPojo;
  user: UserKeycloak;
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  users: string[] = [];
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  @Output() complete: EventEmitter<number> = new EventEmitter<number>();
  dentalProfessionalInvitationPojo: InvitationPojo[] = [];
  addExistingDPInvites = (val, index, arr) => this.dentalProfessionalInvitationPojo.push(val);
  errorSubmission: boolean;
  dentalOffice: DentalOfficePojo;

  constructor(private fb: FormBuilder,
              private dentalProfessionalService: DentalProfessionalControllerService,
              private dentalOfficeProfessionalControllerService: DentalOfficeProfessionalControllerService,
              private resourceVerificationService: ResourceVerificationControllerService,
              private router: Router,
              private uniqueIdValidatorService: UniqueIdValidatorService,
              private dentalOfficeService: DentalOfficeControllerService,
              private authService: AuthenticationServiceKeycloak,
              private deliverableEmailValidator: DeliverableEmailValidator,
              private inviteSearchService: InviteSearchControllerService) {
  }


  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', {
        Validators: [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$")],
        asyncValidators: [this.deliverableEmailValidator.emailExistsValidator()],
        updateOn: 'blur'
      }],
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      addAdmin: [''],
      phoneNumber: ['', [PhoneNumberValidator()]],
      confirm: [false, Validators.requiredTrue]
    });

    this.authService.getUser().subscribe(v => {
      this.user = v
    });

    this.loadInvitations();
  }

  getErrorMessage() {
    return this.errMessage;
  }

  addNewDentalProfessional() {

    if (this.subscriptionType && this.subscriptionType === "Pro" && this.inviteResult.total === 5)
      this.showErrorMessage("You have reached the limit allowed by your subscription.")

    this.form.controls.firstName.enable();
    this.form.controls.lastName.enable();
    this.form.controls.email.enable();
    this.form.controls.phoneNumber.enable();


    if (this.isExistingUser(this.form.value.email.trim())) {
      this.showErrorMessage('Invites cannot be sent to the owner of this email address at the moment...');
      return;
    }
    const dto: DentalProfessionalDto = {
      name: `${this.form.value.firstName} ${this.form.value.lastName}`,
      email: this.form.value.email,
      displayPictureId: undefined,
      isNewUser: true,
      phoneNumber: this.form?.value?.phoneNumber?.e164Number,
    };
    this.dentalProfessionals.push(dto);

    // this.removeValidity(['email', 'firstName', 'lastName']);
    this.form.reset();
    this.invites$.subscribe(res => {
      if (AuthenticationServiceKeycloak._currentUserAccount?.subscriptionCycle?.subscription?.name.toUpperCase() == 'PRO' && res.total >= 5) {
        this.resetForm();
        this.showErrorMessage('You have reach the maximum limit of invites allocated to your plan.');
        return;
      } else {
        this.inviteDentalProfessionals();
      }
    })

  }

  isExistingUser(email): boolean {
    if (this.users == null || this.users.length < 1) {
      return false;
    }
    return this.users?.filter(f => f === email)[0] === email;
  }


  submittedForError() {
    this.errorSubmission = true;
  }

  inviteDentalProfessionals() {
    const invitationListDTO: InvitationListDTO = {
      invitations: this.dentalProfessionals.map(it => {
        const dto: InvitationDTO = {} as InvitationDTO;
        dto.name = it.name;
        dto.email = it.email;
        dto.phoneNumber = it.phoneNumber
        return dto;
      })
    };
    this.working = true;
    this.dentalOfficeProfessionalControllerService.sendDentalProfessionalInvitation({
      id: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
      invitationListDTO
    }).subscribe(() => {
        this.isError = false;
        this.working = false;
        this.resetForm();
        this.errorSubmission = false;
        this.showErrorMessage("Dental Professional has been invited.");
        this.loadInvitations();
        this.dentalProfessionals = [];
      }, (e: HttpErrorResponse) => {
        this.isError = true;
        this.resetForm();
        this.showErrorMessage('Error! Please Check Again');
        this.working = false;
      });
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  resetForm() {
    this.dentalProfessional = undefined;
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.clearValidators();
    this.form.updateValueAndValidity();
  }

  loadInvitations() {
    if (AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id) {
      const searchFilter: InviteSearchFilter = {
        dentalOffice: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
        invitationStatus: 'PENDING',
        typeConstant: InviteSearchFilter.TypeConstantEnum.DentalPractitioner,
        offset: getOffset(0, 4),
        limit: 4,
      };

      this.searchInvitations(searchFilter);
      this.invites$.subscribe(value => {
        this.dentalProfessionalInvitationPojo = [];
        value.results.forEach(this.addExistingDPInvites);
        // this.doSetUpService.dentalProfessionalInvitationPojo$.next(this.dentalProfessionalInvitationPojo);
      });
    }

  }

  get hasUserEmailError() {
    if (!this.form || !this.form.controls.email.value) {
      return false;
    }
    return this.isExistingUser(this.form.controls.email.value);
  }

  searchInvitations(searchFilter: InviteSearchFilter) {
    this.invites$ = this.inviteSearchService.searchInvitations(
      {filter: removeUndefinedOrNullFields(searchFilter)}).pipe(
      map((it: QueryResultsPojoInvitationPojo) => this.inviteResult = it)
    );
  }

  onPageChange($event) {
    const searchFilter: InviteSearchFilter = {
      dentalOffice: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
      typeConstant: InviteSearchFilter.TypeConstantEnum.DentalPractitioner,
      offset: getOffset($event.page, $event.size || 4),
      limit: $event.itemsPerPage,
    };

    this.searchInvitations(searchFilter);
  }

}

export interface DentalProfessionalDto {
  name: string;
  email: string;
  phoneNumber: string;
  displayPictureId: number;
  isNewUser: boolean;
}
