<app-full-page-loader *ngIf="false"></app-full-page-loader>
<div class="text-secondary col-md m-2">
  <div class="container p-3" *ngIf="true">

    <section id="middle" class=" border-0 mt-5">
      <div class="row p-md-4 g-3">

        <section id="top" class="col-lg-3 rounded-0 border-0 ">
          <div class="g-3">
            <div class="img-card border-0">
              <figure class="figure" style="min-width: -webkit-fill-available;">
                <div style="width: -webkit-fill-available;">

                  <input style="display:none" #filePicker (change)="updateImage($event)" type="file">
                  <div class="img-card">
                    <img [src]="profileImageUrl || './assets/img/user.png'" class="figure-img img-fluid"
                         style="width: 100%; height: 100%;"
                         alt=""/>
                  </div>
                  <figcaption
                    class="small d-flex align-items-center justify-content-center fig-caption text-center text-muted pointer mt-3"
                    (click)='filePicker.click()'>
                    <iconify-icon icon="uil:image-upload" style="color: #8891aa;" width="20"></iconify-icon>
                    <span style="margin-top: 5px;">Update Image</span>
                  </figcaption>
                </div>
              </figure>

            </div>
            <div class="img-card2 p-3 mt-5 border-0">
              <div class="row">
                <div class=" border-0">
                  <h5 class="text-primary">Dr. {{ professional?.name }}</h5>
                  <p>{{ getSpecializations() }}</p>
                </div>
              </div>
              <div class="row rounded-0 border-0">
                <p class="mb-0">Account created on</p>
                <p class="fw-bold mt-0">{{ professional.dateCreated | date }}</p>
              </div>
            </div>
          </div>
        </section>
        <div class="card shadow border-0 col pe-0">
          <div class="">
            <div class="container-fluid mt-2">
              <ul class="nav nav-pills mb-3" id="pills-tab">
                <li class="nav-item" role="presentation">
                  <button (click)="selectTab('overview')" class="nav-link" id="pills-home-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                          [attr.aria-selected]="tabSwitch =='overview'" [ngClass]="{'active': tabSwitch =='overview'}">
                    Overview
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button (click)="selectTab('profile')" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                          [attr.aria-selected]="tabSwitch =='profile'" [ngClass]="{'active': tabSwitch =='profile'}">
                    Profile Settings
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button (click)="selectTab('forgotPassword')" class="nav-link" id="pills-forgotPassword-tab"
                          data-bs-toggle="pill" data-bs-target="#pills-forgotPassword" type="button" role="tab"
                          aria-controls="pills-forgotPassword" [attr.aria-selected]="tabSwitch == 'forgotPassword'"
                          [ngClass]="{ active: tabSwitch == 'forgotPassword' }">Password Settings
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent" [ngSwitch]="tabSwitch">
                <div *ngSwitchCase="'overview'">
                  <div class="row px-2 ">
                    <div class="col-md">
                      <div class="row ml-2 mb-2">
                        <div class="header-bg mb-3 d-flex" data-bs-toggle="pill" data-bs-target="#pills-profile"
                             type="button" role="tab"
                             aria-controls="pills-profile">
                          <h6 class="my-2 p-2">USER PROFILE</h6>
                        </div>
                        <div class="col-md-6 col-sm">
                          <h6>Name</h6>
                          <p>Dr. {{professional?.name}}</p>
                        </div>
                        <div class="col-md-6 col-sm">
                          <h6>Mobile Number</h6>
                          <p type="phone" class="pb-0 mb-0"> {{professional?.mobileNumber}} </p>
                        </div>
                        <div class="col-md-6 col-sm">
                          <h6>Email Address</h6>
                          <p type="email" class="pt-0 mt-0"><a class="nav-link m-0 p-0">{{professional?.email}}</a></p>
                        </div>
                      </div>
                      <div class="col-md-6 mt-md-0 mt-2">
                        <h6 class="mt-5">
                          Speciality
                          <span (click)="selectTab('profile')" class="badge bg-secondary ms-2 nav-link"
                                style="cursor: pointer">
                            Edit
                          </span>
                        </h6>
                        <span class="badge bg-primary mx-1 py-1" *ngFor="let service of servicesProvides">
                          {{ service.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'profile'">
                  <div [class]="messageType" *ngIf="profileUpdated">
                    <p class="m-0 p-0"> {{ message }} </p>
                  </div>
                  <form [formGroup]="officeInfoForm">
                    <section id="profile-info1">
                      <div class="header-bg">
                        <h6 class="my-2 p-2">PROFILE INFO</h6>
                      </div>
                      <div *ngIf="showErrorMessageTrigger">
                        <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show"
                             role="alert"
                             [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                          <p class="m-0 p-0 d-flex align-items-start">
                        <span>
                          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ getErrorMessage() | removeUnderscores }}
                        </span>
                            <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                            </iconify-icon>
                          </p>
                        </div>
                      </div>
                      <div class="row px-2">
                        <div class="col-md mb-3 form-floating mb-md-0">
                          <input type="text" class="form-control" id="ftn" placeholder="e.g John"
                                 formControlName="firstName">
                          <label for="ftn" class="" style="margin-left: .6rem; margin-top:-.2rem;">First Name</label>
                        </div>
                        <div class="col-md mb-3 form-floating mb-md-0">
                          <input type="text" class="form-control" id="ltn" placeholder="e.g  Doe"
                                 formControlName="lastName">
                          <label for="ltn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Last Name</label>
                        </div>
                        <div class="col-md form-floating mb-md-3">
                          <input type="email" class="form-control" id="email" placeholder="e.g someone@example.com"
                                 formControlName="email">
                          <label for="email" class="" style="margin-left: .6rem; margin-top:-.2rem;">Email</label>
                        </div>
                      </div>
                      <div class="row px-2">
                        <div class="col-md mt-md-1 mb-3">
                          <label for="officePhone" class="rfr-label">Phone Number</label>
                          <ngx-intl-tel-input [cssClass]="'form-control py-2'" [enableAutoCountrySelect]="true"
                                              [enablePlaceholder]="true"
                                              id="officePhone" [maxLength]="'20'" [phoneValidation]="true"
                                              [preferredCountries]="preferredCountries"
                                              [searchCountryField]="[
                                              SearchCountryField.Iso2,
                                              SearchCountryField.Name
                                              ]" [searchCountryFlag]="true" [selectedCountryISO]="countryISO" [selectFirstCountry]="true"
                                              formControlName="phoneNumber"></ngx-intl-tel-input>
                        </div>
                        <div class="col-md mb-3  mt-md-4 form-floating mb-md-0">
                          <input type="text" class="form-control" id="fb" placeholder="facebook" formControlName="fbUrl">
                          <label for="fb" class="" style="margin-left: .6rem; margin-top:-.2rem;">Facebook</label>
                        </div>
                        <div class="col-lg mb-3">
                          <div class="col-md mb-3 mt-md-4 form-floating mb-md-0">
                            <input type="text" class="form-control" id="lkn" placeholder="Twitter"
                                   formControlName="twitterUrl">
                            <label for="lkn" class="" style=" margin-top:-.2rem;">Twitter</label>
                          </div>
                        </div>
                      </div>
                      <div class="row px-2">
                        <div class="col-md-12 mb-3">
                          <label for="profSpecialization" class="rfr-label">Professional Specialization</label>
                          <tag-input class="form-control p-2" [addOnBlur]="true" [clearOnBlur]="true"
                                     [onlyFromAutocomplete]="true" size="20" style="min-height: 70px" id="profSpecialization"
                                     formControlName="profSpecialization" [allowDupes]="false">
                            <tag-input-dropdown
                              [showDropdownIfEmpty]="true"
                              [autocompleteItems]="specializationNames">
                            </tag-input-dropdown>
                          </tag-input>
                        </div>
                      </div>
                    </section>
                    <div class="my-4 float-end">
                      <button (click)="updateProfile()" [disabled]="isProcessing" type="submit"
                              class="btn btn-primary btn-sm" name="button">
                        <iconify-icon *ngIf="isProcessing" icon="eos-icons:bubble-loading" style="color: #ffffff ">
                        </iconify-icon>
                        Update
                      </button>
                    </div>
                  </form>
                </div>
                <div *ngSwitchCase="'forgotPassword'">
                  <div *ngIf="showPasswordTrigger">
                    <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show"
                         role="alert"
                         [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                      <p class="m-0 p-0 d-flex align-items-start">
                        <span>
                          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ showPasswordMessage() | removeUnderscores }}
                        </span>
                        <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                        </iconify-icon>
                      </p>
                    </div>
                  </div>
                  <app-change-password (emitSuccessResponse)="passwordChangeSuccess($event)" (emitErrorResponse)="passwordError($event)"></app-change-password>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
