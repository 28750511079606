/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FaxRequestBodyDto { 
    action?: string;
    accessId?: string;
    accessPassword?: string;
    callerId?: string;
    senderEmail?: string;
    faxType?: string;
    fileName?: string;
    fileContentBase64?: string;
    toFaxNumber?: string;
}

