
<div class="">
  <div class="row">
    <div class="d-flex justify-content-center">
      <div class="card p-3">
        <lottie-player
          src="https://assets9.lottiefiles.com/packages/lf20_bskjzmma.json"
          background="transparent"
          speed="1"
          style="width: 100%; height: 100%"
          autoplay
          class="welcome-box"
        ></lottie-player>

        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <div class="col px-4 card-body">
            <div class="text-center">
              <h1 class="mb-3 welcome-title">Welcome to DentalDoor</h1>
              <p class="welcome-subtitle">
                It's fast, easy and convenient.
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <lottie-player
                src="https://assets10.lottiefiles.com/packages/lf20_jC1wXVNzQA.json"
                mode="bounce"
                background="transparent"
                speed="1"
                style="width: 300px; height: 300px"
                loop
                autoplay
              ></lottie-player>
            </div>
            <div class="d-flex justify-content-center text-center">
              <div class="col-9">
                <h2
                  class="fw-bold text-success my-4"
                  style="line-height: 42px"
                >
                  You’ve successfully created a {{accountType}} account!
                </h2>
                <p class="success-subtitle">
                  {{ message }}<span class="text-primary">{{ email }}<span *ngIf="phone"> and {{phone}}</span></span>.
<!--                  Please check for an email from-->
<!--                  <span class="fw-bold"> DentalDoor</span> and click on the-->
<!--                  link to activate your account.-->
                </p>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center mt-5">
              <a style="z-index: 10000" (click)="requestOTP()" class="btn btn-primary w-75 px-3" type="submit">
                Proceed to Verification
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
