<!--<div id="successMdl" tabindex="-1">-->
<!--    <div class="modal-sm modal-dialog-centered">-->
<div class="modal-content border-0">
  <div class="modal-body" style="border-radius: 20px">
    <div class="text-center">
      <div class="d-flex justify-content-evenly">
        <div style="width: 6rem; height: auto">
          <div>
            <iconify-icon
              *ngIf="check"
              icon="clarity:success-standard-solid"
              style="color: green"
              width="100"
            ></iconify-icon>
            <iconify-icon
              *ngIf="fail"
              icon="clarity:error-standard-solid"
              style="color: red"
              width="100"
            ></iconify-icon>
            <iconify-icon
              *ngIf="info"
              icon="akar-icons:info-fill"
              style="color: orange"
              width="100"
            ></iconify-icon>
          </div>
        </div>
      </div>

      <div class="text-center my-3">
        <p>{{ body }}</p>
      </div>

      <div class="my-3">
        <button
          class="btn btn-primary px-4 w-75"
          *ngIf="!close && !affiliate"
          data-bs-toggle="modal"
          data-bs-target="#success"
          (click)="this.router.navigate([this.link])"
        >
          {{ this.buttonText }}
        </button>
        <button
          class="btn btn-primary px-4 w-75"
          *ngIf="affiliate" (click)="dismissAffiliateModal()">
          {{ this.buttonText }}
        </button>
        <button
          class="btn btn-primary px-4 w-75"
          *ngIf="close"
          data-bs-toggle="modal"
          data-bs-target="#success"
          (click)="dismiss()"
        >
          {{ this.buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--</div>-->
<!--</div>-->
