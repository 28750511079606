<!-- Modal -->

<div class="modal-content">
    <div class="modal-header rounded-0 bg-primary">
        <h3 id="tutorialModalLabel" class="text-light mb-0">Navigating DentalDoor</h3>
        <button type="button" class="btn-close" (click)="this.closeClicked.emit()" aria-label="Close"></button>
    </div>
    <div class="modal-body ">
        <div class="row g-3 ">
            <div class="col-12 ">
                <div style="padding:56.25% 0 0 0;position:relative;" id="videolayer">
                    <iframe src="https://player.vimeo.com/video/785826682?h=d1702e81e1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 " frameborder="0 " allow="autoplay; fullscreen; picture-in-picture
                            " allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%; " title="For Specialist Offices ">
                                                    </iframe>
                </div>
            </div>
            <div class="col-12">
                <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/785827397?h=aa85bea2b3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 " frameborder="0
                            " allow="autoplay; fullscreen; picture-in-picture " allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%; " title="For General Offices "></iframe></div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="this.closeClicked.emit()">Close</button>
    </div>
</div>


<!-- Modal -->