import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  DashboardControllerService,
  NotificationControllerService,
  NotificationSearchPojo,
  QueryResultsPojoNotificationSearchPojo, UserControllerService, UserPojo
} from "dd-core-api-sdk";
import {BehaviorSubject} from "rxjs";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {Router} from "@angular/router";
import {environment} from 'src/environments/environment.prod';
import {UserAccountKeycloak} from "../../../../services/UserAccountKeycloak";
import {NotificationService} from "../../../../notifications/notification-service";
import {JoyrideService} from "ngx-joyride";
import TourRideStatusConstantEnum = UserPojo.TourRideStatusConstantEnum;
import {
  ReferralPatientModalComponent
} from "../../../../shared/referral-patient-modal/referral-patient-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit {
  userAccount: UserKeycloak;
  @Input() user: UserKeycloak;
  userAccountKeycloak: UserAccountKeycloak;
  notificationData: Array<NotificationSearchPojo>;
  resultSubject = new BehaviorSubject<QueryResultsPojoNotificationSearchPojo>({});
  @Output()
  emitNotificationTotal = new EventEmitter();
  officeName: string | undefined;
  pictureId: any;
  showRoute: boolean;
  showToolTip: boolean = false;

  tourRideStatus = TourRideStatusConstantEnum;


  constructor(private authentication: AuthenticationServiceKeycloak,
              private router: Router,
              private userControllerService: UserControllerService,
              private joyrideService: JoyrideService,
              private bsModalService: BsModalService,
              private notificationService: NotificationControllerService) {
  }

  ngOnInit(): void {

    this.authentication.getUser().subscribe(res => {
      this.userAccount = res;
      this.officeName = AuthenticationServiceKeycloak._currentUserAccount?.accountName || res.firstName + " " + res.lastName;
      this.getNotifications();
    });

    setInterval(() => {
      this.getNotifications();
    }, 10000);

    this.authentication.getAccount().subscribe(v => {
      this.showRoute = this.shouldShowRoute(v);
    });


    //TODO: Update to use the new tour ride status 'CreateReferral'
    // if (this.userAccount?.tourRideStatusConstant == null && this.userAccount?.tourRideStatusConstant != this.tourRideStatus.CreateReferral) {
    //   this.joyrideService.startTour(
    //     {
    //       steps: ["startReferral",
    //         "one@out-bound/create-referral",
    //         "two@out-bound/create-referral",
    //         "sendReferral@out-bound/preview-referral"]
    //     } // Your steps order
    //   ).subscribe((step) => {
    //
    //     }, (error) => {
    //     },
    //     () => {
    //       this.updateTourRideStatus(TourRideStatusConstantEnum.CreateReferral);
    //       this.router.navigate(['/dashboard'])
    //     });
    // } else {
      this.showReferPatientModal();
    // }


  }

  updateTourRideStatus(tourRide: TourRideStatusConstantEnum) {
    this.userControllerService.updateTourRide({portalUserId: this.user.id, tourRide: tourRide}).subscribe(res => {
      this.user.tourRideStatusConstant = tourRide;
    });
  }

  getNotifications() {
    this.notificationService.searchNotification({
      filter: {
        isAffiliate: false,
        limit: 3
      }
    }).subscribe(res => {
      this.notificationData = res.results;
      this.resultSubject.next(res);
      this.emitNotificationTotal.next(this.resultSubject.value.total);
    });
  }

  getFileURL() {
    if (this.pictureId?.value?.id != null && this.pictureId?.value?.id != '') {
      return environment.siteUrl + "/files/" + this.pictureId.value.id;
    }
    return "./assets/img/user.png";
  }

  updateReferralStatus(notify: NotificationSearchPojo) {
    this.notificationService.updateNotification({id: notify.notificationId}).subscribe(res => {
      this.controlNotificationNavigation(notify);
    })
  }

  shouldShoToolTip() {
    this.showToolTip = true;
  }

  logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.authentication.logoutClick(window.location.origin);
  }

  controlNotificationNavigation(notify: NotificationSearchPojo) {
    if (notify.entityName == 'Invite') {
      this.router.navigate(['/invites-list']);
    } else if (notify.entityName == 'ReferralData') {
      this.router.navigate(['/in-bound/referrals/', notify.entityId]);
    }
  }

  shouldShowRoute(account: UserAccountKeycloak) {
    if (!account?.otherData?.specialityOffice) {
      return true;
    }
    return ((account?.dentalOfficeSubscription?.subscriptionStatus == "ACTIVE"
        || account?.dentalOfficeSubscription?.subscriptionStatus == "TRIAL")
      && account?.otherData?.specialityOffice);
  }


  private showReferPatientModal() {
    this.bsModalService.show(ReferralPatientModalComponent, {
      initialState: {},
      keyboard: false,
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered'
    });
  }
}
