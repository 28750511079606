/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReviewCategory { 
    name?: string;
    code?: string;
    status?: ReviewCategory.StatusEnum;
    dateCreated?: string;
    type?: ReviewCategory.TypeEnum;
    id?: number;
}
export namespace ReviewCategory {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type TypeEnum = 'DENTAL_OFFICE' | 'DENTAL_PROFESSIONAL' | 'PROMOTION' | 'FLASHCARD';
    export const TypeEnum = {
        DentalOffice: 'DENTAL_OFFICE' as TypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as TypeEnum,
        Promotion: 'PROMOTION' as TypeEnum,
        Flashcard: 'FLASHCARD' as TypeEnum
    };
}


