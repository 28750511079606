/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalOffice } from './dental-office';
import { DentalProfessional } from './dental-professional';
import { TemporaryDentalOffice } from './temporary-dental-office';
import { DataImportBatch } from './data-import-batch';
import { PortalUser } from './portal-user';


export interface Invite { 
    name?: string;
    email?: string;
    dateCreated?: string;
    lastUpdated?: string;
    status?: Invite.StatusEnum;
    phoneNumber?: string;
    type?: Invite.TypeEnum;
    invitationStatus?: Invite.InvitationStatusEnum;
    dateReviewed?: string;
    dateDeactivated?: string;
    notificationSent?: boolean;
    dateNotified?: string;
    isExistingUser?: boolean;
    dentalOfficeName?: string;
    fhirProviderId?: string;
    fax?: string;
    dentalProfessionalName?: string;
    inviteNotes?: string;
    entityId?: number;
    id?: number;
    createdBy?: PortalUser;
    dentalOffice?: DentalOffice;
    invitedUser?: DentalProfessional;
    deactivatedBy?: PortalUser;
    invitedPatient?: PortalUser;
    dataImportBatch?: DataImportBatch;
    temporaryDentalOffice?: TemporaryDentalOffice;
}
export namespace Invite {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type TypeEnum = 'DENTAL_PRACTITIONER' | 'USER' | 'DENTAL_OFFICE_ADMIN' | 'DENTAL_OFFICE';
    export const TypeEnum = {
        DentalPractitioner: 'DENTAL_PRACTITIONER' as TypeEnum,
        User: 'USER' as TypeEnum,
        DentalOfficeAdmin: 'DENTAL_OFFICE_ADMIN' as TypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as TypeEnum
    };
    export type InvitationStatusEnum = 'PENDING' | 'ACCEPTED' | 'DECLINED';
    export const InvitationStatusEnum = {
        Pending: 'PENDING' as InvitationStatusEnum,
        Accepted: 'ACCEPTED' as InvitationStatusEnum,
        Declined: 'DECLINED' as InvitationStatusEnum
    };
}


