import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {
  AffiliateUserControllerService, AffiliateUserImageControllerService,
  AffiliateUserResponse,
  City, DentalOfficeProfilePojo, DentalServiceProvided, InsuranceCompany,
  LocationService, PortalAccountMemberRole, PortalUser, QueryResultsCity, Specialization,
  StateResponse
} from "dd-core-api-sdk";
import {environment} from "../../../../../environments/environment";
import {IClipboardResponse} from "ngx-clipboard";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {SuccessModalComponent} from "../../../../shared/success-modal/success-modal.component";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {PhoneNumberValidator} from "dd-shared-lib";
import {Router} from "@angular/router";


type tab = 'overview' | 'profile' | 'admin' | 'forgotPassword'

@Component({
  selector: 'app-affiliate-profile-settings',
  templateUrl: './affiliate-profile-settings.component.html',
  styleUrls: ['./affiliate-profile-settings.component.css']
})
export class AffiliateProfileSettingsComponent implements OnInit {
  tabSwitch: string = 'profile';
  loading: boolean;


  affiliateProfile: AffiliateUserResponse

  nameForm: FormGroup;
  firstName: String;

  states: StateResponse[] = [];
  cities: Array<City>;
  uploadingImage: boolean;
  currentState: StateResponse;
  city: City;


  copied: any;
  specializationCode: string[];
  showImageErrorTrigger: boolean;
  imageErrorMessage: any;
  user: UserKeycloak;
  showPasswordTrigger: boolean;
  passwordErrorMessage: any;
  isError: boolean;


  message: string;
  errMessage = '';



  updateLoading: boolean;
  profileUpdated: boolean;
  accountId: number;

  submitting: boolean;
  errorSubmission: boolean;
  @Output()
  emitSuccessResponse = new EventEmitter<any>();
  @Output()
  emitErrorResponse = new EventEmitter<any>();


  showErrorMessageTrigger = false;


  preferredCountries: string[] = ['ng'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;




  constructor(
    private affiliateUserService: AffiliateUserControllerService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: BsModalService,
    private affiliateUserImageService: AffiliateUserImageControllerService,
    private locationService: LocationService,
    private authentication: AuthenticationServiceKeycloak,
  ) {


  }


  selectTab(tab: string): void {
    this.tabSwitch = tab;
  }

  ngOnInit(): void {


    this.nameForm = this.fb.group({
      firstName: new FormControl(this.affiliateProfile?.firstName || '', [Validators.required]),
      lastName: new FormControl(this.affiliateProfile?.lastName || '', [Validators.required]),
      email: new FormControl(this.affiliateProfile?.email || '', [Validators.required, Validators.email]),
      phoneNumber: new FormControl(this.affiliateProfile?.phoneNumber || '', [Validators.required, PhoneNumberValidator()]),
      streetAddress: new FormControl(this.affiliateProfile?.address.streetAddress || '', [Validators.minLength(2), Validators.maxLength(150)]),
      state: ['', [Validators.required]],
      city: new FormControl('', [Validators.required]),

    });


    this.locationService.getUsStates().subscribe(res => {
      this.states = res;

    })

    this.authentication.getUser().subscribe(v => {
      this.user = v;
    })

    this.affiliateUserService.getAffiliateUserProfile().subscribe(res => {
      this.affiliateProfile = res;

      this.nameForm.get('firstName').patchValue(this.affiliateProfile?.firstName);
      this.nameForm.get('lastName').patchValue(this.affiliateProfile?.lastName);
      this.nameForm.get('email').patchValue(this.affiliateProfile?.email);
      this.nameForm.get('phoneNumber').patchValue(this.affiliateProfile?.phoneNumber);
      this.nameForm.get('state').patchValue(this.affiliateProfile?.address?.state?.id);
      this.nameForm.get('streetAddress').patchValue(this.affiliateProfile?.address?.streetAddress);

    })

    this.nameForm.get('state').valueChanges.subscribe(v => {
      this.locationService.searchCities({
        filter: {
          stateId: v
        }
      }).subscribe((v: QueryResultsCity) => {
        this.cities = v.results;
        this.nameForm.get('city').patchValue(this.cities[0].name);
        this.nameForm.get('city').patchValue(this.affiliateProfile?.address?.city?.id);
      });
    });
  }



  onImagePicked(event) {
    let f = event.target.files[0];
    if (f.size > 1024 * 1024 * 5) {
      this.showImageError('File size must not be more than 5MB');
      return;
    }

    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
    const isValidExtension = validExtensions.indexOf(f.type) > -1;

    if (!isValidExtension) {
      this.showImageError('Invalid File Type. Select an image file.');
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        this.uploadFile(reader.result.toString(), f.type, f.name);
      }
    }
  }

  showImageError(error
                   :
                   any
  ) {
    this.imageErrorMessage = error;

    this.showImageErrorTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showImageErrorTrigger = false;
    }, 4000);
  }

  private uploadFile(result, type, name) {
    this.uploadingImage = true;
    const currentUrl = location.pathname;
    this.affiliateUserImageService.addProfileImage({
      portalUserImageDto: {
        profilePicture: result,
        contentType: type,
        description: name
      }
    }).subscribe({
      next(value) {
        this.uploadingImage = false;
        console.log(location.pathname)
        window.location.reload();

      },
      error() {
        this.uploadingImage = false;
      },
      complete() {
        this.uploadingImage = false;
      }
    }).add(() => {
      this.uploadingImage = false
    });
  }


  getFileURL() {
    if (this.affiliateProfile?.profileImageId != null) {
      return environment.siteUrl + "/files/" + this.affiliateProfile?.profileImageId;
    }
    return "./assets/img/user.png";
  }

  contentCopied($event: IClipboardResponse) {
    this.copied = $event.isSuccess;
    setTimeout(v => {
      this.copied = false;
    }, 1500);
  }


  submittedForError() {
    this.errorSubmission = true;
  }






  submitUpdate() {

    this.submitting = true;

    this.affiliateUserService.updateProfile({
      affiliateUserProfileUpdateRequest: {
        firstName: this.nameForm.value.firstName,
        lastName: this.nameForm.value.lastName,
        streetAddress: this.nameForm.value.streetAddress,
        cityId: this.nameForm.value.city
      }
    }).subscribe({
      next: (v) => {


        this.submitting = false;
        this.emitSuccessResponse.emit();
        this.showErrorMessage('Affiliate profile Updated Successfully.');
        // window.location.reload();
      },
      error: (e) => {

        this.submitting = false;
        this.emitErrorResponse.emit(e.error.message ? e.error.message : 'Error in updating profile. Kindly try again later.');
      },
      complete: () => {
        this.submitting = false;
      }
    });

  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  passwordError($event: any) {
    this.isError = true;
    this.showPasswordError($event);
  }

  passwordChangeSuccess($event: any) {
    this.successModalResponse();
  }

  showPasswordMessage() {
    return this.passwordErrorMessage;
  }

  showPasswordError(error: any) {
    this.passwordErrorMessage = error;
    this.showPasswordTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showPasswordTrigger = false;
    }, 4000);
  }
  successModalResponse() {
    let bsModalRef = this.modalService.show(SuccessModalComponent, {
      initialState: {
        body: 'Password has been updated successfully.',
        link: '/accounts',
        buttonText: "Close",
        check: true,
        close: true
      },
      keyboard: false,
      backdrop: true,
      animated: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });
    bsModalRef?.content.onDismissClicked.subscribe(v => {
      bsModalRef.hide();
    })
    bsModalRef?.content.onSuccessClicked.subscribe(v => {
      window.location.reload();
    })
  }

}
