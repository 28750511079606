<div class="modal-content px-3">
  <div class="modal-header border-bottom-0">
    <h5 class="modal-title fw-bold" id="exampleModalLabel">
      Add Doctor From Office
    </h5>
    <button
      type="button"
      class="btn-close text-danger"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body">
    <div>
      <form class="container" [formGroup]="formGroup">
        <div class="col-12 mb-md-0 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control form-control-lg"
              id="firstName"
              placeholder="First Name"
              aria-label="First Name "
              formControlName="profFirstName"
            />
            <label for="firstName" class="required">First Name</label>
          </div>
          <app-field-error
            *ngIf="
              (formGroup.get('profFirstName')?.errors?.required &&
                formGroup.get('profFirstName')?.touched) ||
              (formGroup.get('profFirstName')?.errors?.required &&
                errorSubmission)
            "
            [message]="'Professional first name cannot be blank.'"
          >
          </app-field-error>
        </div>
        <div class="col-12 mt-3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control form-control-lg"
              id="lastName"
              placeholder="Last Name"
              aria-label="Last Name"
              formControlName="profLastName"
            />
            <label for="lastName" class="required">Last Name</label>
          </div>
          <app-field-error
            *ngIf="
              (formGroup.get('profLastName')?.errors?.required &&
                formGroup.get('profLastName')?.touched) ||
              (formGroup.get('profLastName')?.errors?.required &&
                errorSubmission)
            "
            [message]="'Professional last name cannot be blank.'"
          ></app-field-error>
        </div>

        <div class="mt-4">
          <button
            type="submit"
            class="btn btn-primary btn-block mb-4 w-100"
            (click)="onAddButtonClick()"
          >
            <iconify-icon
              icon="ant-design:plus-outlined"
              style="color: #ffffff"
            ></iconify-icon>
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
