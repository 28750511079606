/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionPackageItem } from './subscription-package-item';
import { SubscriptionCyclePojo } from './subscription-cycle-pojo';


export interface SubscriptionPackagePojo { 
    id?: number;
    name?: string;
    code?: string;
    status?: SubscriptionPackagePojo.StatusEnum;
    packageItems?: Array<SubscriptionPackageItem>;
    subscriptionCycles?: Array<SubscriptionCyclePojo>;
    officesSubscribed?: number;
    description?: string;
}
export namespace SubscriptionPackagePojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


