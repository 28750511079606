import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserPojo} from 'dd-core-api-sdk';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {UserKeycloak} from "../services/UserKeycloak";

@Injectable({
  providedIn: 'root'
})
export class TokenStore {

  private static TOKEN_NAME = 'TOKEN';
  location = location;

    private _user: BehaviorSubject<UserKeycloak | null>;
  private userObservable: Observable<UserKeycloak>;

  public constructor(private router: Router) {
    this._user = new BehaviorSubject(undefined);
    this.userObservable = this._user.pipe(filter(it => it !== undefined));
  }

  // setUser(user: UserPojo) {
  //   this._user.next(user && new UserImpl(user));
  // }

  // isInitialized() {
  //   return this._user.value !== undefined;
  // }

  setToken(token: string) {
    localStorage.setItem(TokenStore.TOKEN_NAME, token);
  }

  getToken() {
    return localStorage.getItem(TokenStore.TOKEN_NAME);
  }

  clearStaleSession() {
    localStorage.removeItem(TokenStore.TOKEN_NAME);
    localStorage.removeItem("BANNER_LIST_DH");
    const shouldPromptLogin = !!this._user.value;
    this._user.next(null);

    if (shouldPromptLogin) {
      this.router.navigate(['/login']);
    }
  }

  get user() {
    return this.userObservable;
  }
}
