<!-- profile settings section -->
<div class="container">
  <div>
    <div class="row my-3">
      <div class="col-lg">
<!--        <div class="p-2 header-bg col" style="background: rgba(138, 152, 190, 0.09)">-->
<!--          <h6 class="my-2">CHANGE YOUR PASSWORD</h6>-->
<!--        </div>-->
        <hr class="hr line">
        <form class="mt-4">
          <section id="profile-info1">
            <div class=" mb-3">
              <h2 >Change Password</h2>
              <p>Your password must meet the following requirements</p>
            </div>
            <div class="row px-md-5" [formGroup]="form">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mt-3">
                    <div class="d-flex align-items-center mb-3 gap-3">
                      <span class="material-symbols-rounded" *ngIf="isLongEnough">
                        done
                      </span>
                      <span class="material-symbols-rounded not" *ngIf="!isLongEnough">
                        close
                      </span>
                      <span>Password must contain at least 8 characters</span>
                    </div>

                    <div class="d-flex align-items-center mb-3 gap-3">
                      <span class="material-symbols-rounded" *ngIf="hasSpecialChar">
                        done
                      </span>
                      <span class="material-symbols-rounded not" *ngIf="!hasSpecialChar">
                        close
                      </span>
                      <span>Password must contain at least one special character</span>
                    </div>

                    <div class="d-flex align-items-center mb-3 gap-3">
                      <span class="material-symbols-rounded" *ngIf="hasSmallLetter">
                        done
                      </span>
                      <span class="material-symbols-rounded not" *ngIf="!hasSmallLetter">
                        close
                      </span>
                      <span>Password must contain at least one small letter</span>
                    </div>

                    <div class="d-flex align-items-center mb-3 gap-3">
                      <span class="material-symbols-rounded" *ngIf="hasCapitalLetter">
                        done
                      </span>
                      <span class="material-symbols-rounded not" *ngIf="!hasCapitalLetter">
                        close
                      </span>
                      <span>Password must contain at least one CAPITAL letter</span>
                    </div>

                    <div class="d-flex align-items-center mb-3 gap-3">
                      <span class="material-symbols-rounded" *ngIf="hasNumber">
                        done
                      </span>
                      <span class="material-symbols-rounded not" *ngIf="!hasNumber">
                        close
                      </span>
                      <span>Password must contain at least one number</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">

                                <div class=" mb-3">
                                  <label for="old-password" class="rfr-label ">Old Password <span class="text-danger">*</span></label>
                                  <input type="password" class="form-control p-2" id="old-password" placeholder="e.g. Password123" formControlName="oldPassword" >
                                  <app-field-error *ngIf="(form.get('oldPassword')?.errors?.required && form.get('oldPassword')?.touched) || (form.get('oldPassword')?.errors?.required && errorSubmission)"
                                                   [message]="'Password cannot be blank.'"></app-field-error>
                                </div>
                                <div class="mb-3">
                                  <label for="new-password" class="rfr-label ">New Password <span class="text-danger">*</span></label>
                                  <input type="password" class="form-control p-2" id="new-password" placeholder="e.g. Password123" formControlName="newPassword"/>
                                  <app-field-error class="mt-1" [message]="'Password is poor.'"
                                    *ngIf="form.get('newPassword')!.errors?.notStrong"></app-field-error>
                                  <app-field-error *ngIf="(form.get('newPassword')?.errors?.required && form.get('newPassword')?.touched) || (form.get('newPassword')?.errors?.required && errorSubmission)"
                                    [message]="'New Password cannot be blank.'"></app-field-error>
                                  <app-ng-password-strength-meter [passwordToCheck]="form.getRawValue().newPassword" (passwordStrengthMessage)="onPasswordStrengthMessChanged($event)"></app-ng-password-strength-meter>
                                </div>
                                <div class="mb-3">
                                  <label for="new-password-2" class="rfr-label ">Retype New Password <span class="text-danger">*</span></label>
                                  <input type="password" class="form-control p-2" id="new-password-2" placeholder="e.g. Password123" formControlName="confirmPassword" />
                                  <app-field-error *ngIf="form.get('confirmPassword')!.errors ?.passwordMismatch" [message]="'Password and confirm password must be the same.'"></app-field-error>
                                  <app-field-error *ngIf=" (form.get('confirmPassword')?.errors?.required && form.get('confirmPassword')?.touched) || (form.get('confirmPassword')?.errors?.required && errorSubmission)"
                                    [message]="'Confirm password cannot be blank.'"></app-field-error>
                                </div>
                  <div class="my-4">
                    <button type="submit" class="btn btn-primary  float-end" name="button" [disabled]="submitting" (click)="form.invalid ?  submittedForError() :updatePassword()">
                      <iconify-icon icon="eos-icons:bubble-loading" style="color: #ffffff"  *ngIf="submitting"></iconify-icon>
                      Reset Password
                    </button>
                  </div>

                </div>
              </div>




<!--              <div class=" mb-3">-->
<!--                <label for="old-password" class="rfr-label ">Old Password <span class="text-danger">*</span></label>-->
<!--                <input type="password" class="form-control p-2" id="old-password" placeholder="e.g. Password123" formControlName="oldPassword">-->
<!--                <app-field-error *ngIf="(form.get('oldPassword')?.errors?.required && form.get('oldPassword')?.touched) || (form.get('oldPassword')?.errors?.required && errorSubmission)"-->
<!--                                 [message]="'Password cannot be blank.'"></app-field-error>-->
<!--              </div>-->
<!--              <div class="mb-3">-->
<!--                <label for="new-password" class="rfr-label ">New Password <span class="text-danger">*</span></label>-->
<!--                <input type="password" class="form-control p-2" id="new-password" placeholder="e.g. Password123" formControlName="newPassword" />-->
<!--                <app-field-error class="mt-1" [message]="'Password is poor.'"-->
<!--                  *ngIf="form.get('newPassword')!.errors?.notStrong"></app-field-error>-->
<!--                <app-field-error *ngIf="(form.get('newPassword')?.errors?.required && form.get('newPassword')?.touched) || (form.get('newPassword')?.errors?.required && errorSubmission)"-->
<!--                  [message]="'New Password cannot be blank.'"></app-field-error>-->
<!--                <app-ng-password-strength-meter [passwordToCheck]="form.getRawValue().newPassword" (passwordStrengthMessage)="onPasswordStrengthMessChanged($event)"></app-ng-password-strength-meter>-->
<!--              </div>-->
<!--              <div class="mb-3">-->
<!--                <label for="new-password-2" class="rfr-label ">Retype New Password <span class="text-danger">*</span></label>-->
<!--                <input type="password" class="form-control p-2" id="new-password-2" placeholder="e.g. Password123" formControlName="confirmPassword" />-->
<!--                <app-field-error *ngIf="form.get('confirmPassword')!.errors ?.passwordMismatch" [message]="'Password and confirm password must be the same.'"></app-field-error>-->
<!--                <app-field-error *ngIf=" (form.get('confirmPassword')?.errors?.required && form.get('confirmPassword')?.touched) || (form.get('confirmPassword')?.errors?.required && errorSubmission)"-->
<!--                  [message]="'Confirm password cannot be blank.'"></app-field-error>-->
<!--              </div>-->
            </div>

          </section>
        </form>
      </div>

    </div>
  </div>
</div>
