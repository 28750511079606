/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InsuranceUpdateDto { 
    insuranceCompanyCode: string;
    type: InsuranceUpdateDto.TypeEnum;
    insuranceProviderMemberId: string;
    insuranceCardImageId: number;
    insuranceBackCardImageId: number;
}
export namespace InsuranceUpdateDto {
    export type TypeEnum = 'HMO' | 'PPO' | 'INDEMNITY' | 'DISCOUNT';
    export const TypeEnum = {
        Hmo: 'HMO' as TypeEnum,
        Ppo: 'PPO' as TypeEnum,
        Indemnity: 'INDEMNITY' as TypeEnum,
        Discount: 'DISCOUNT' as TypeEnum
    };
}


