/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalOffice } from './dental-office';


export interface ReferralDraft { 
    patientFirstName?: string;
    patientLastName?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    procedureTypes?: string;
    priority?: ReferralDraft.PriorityEnum;
    preMedRequired?: boolean;
    refferalNote?: string;
    affectedTeeth?: string;
    referralTo?: string;
    referralFrom?: string;
    completed?: boolean;
    valid?: boolean;
    draftCode?: string;
    xrays?: string;
    referralToOfficeId?: string;
    referredByOfficeAccountId?: string;
    referredByProfessionalId?: string;
    referralToProfId?: string;
    isAdult?: boolean;
    createdAt?: string;
    manualProfFirstName?: string;
    manualProfLastName?: string;
    referralToFax?: string;
    referralToPhoneNumber?: string;
    referralToEmail?: string;
    referralToOfficeName?: string;
    entryType?: ReferralDraft.EntryTypeEnum;
    id?: number;
    dentalOffice?: DentalOffice;
}
export namespace ReferralDraft {
    export type PriorityEnum = 'NORMAL' | 'SEMI_URGENT' | 'URGENT' | 'EMERGENCY';
    export const PriorityEnum = {
        Normal: 'NORMAL' as PriorityEnum,
        SemiUrgent: 'SEMI_URGENT' as PriorityEnum,
        Urgent: 'URGENT' as PriorityEnum,
        Emergency: 'EMERGENCY' as PriorityEnum
    };
    export type EntryTypeEnum = 'DRAFT' | 'CUSTOM_REFERRAL';
    export const EntryTypeEnum = {
        Draft: 'DRAFT' as EntryTypeEnum,
        CustomReferral: 'CUSTOM_REFERRAL' as EntryTypeEnum
    };
}


