/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Period } from './period';
import { DentalOfficeProfessional } from './dental-office-professional';


export interface Schedule { 
    actorType?: Schedule.ActorTypeEnum;
    dateCreated?: string;
    status?: Schedule.StatusEnum;
    comment?: string;
    id?: number;
    planningHorizon?: Period;
    dentalOfficeProfessional?: DentalOfficeProfessional;
}
export namespace Schedule {
    export type ActorTypeEnum = 'PATIENT' | 'PRACTITIONER' | 'RELATED_PERSON' | 'DEVICE' | 'HEALTHCARE_SERVICE' | 'LOCATION';
    export const ActorTypeEnum = {
        Patient: 'PATIENT' as ActorTypeEnum,
        Practitioner: 'PRACTITIONER' as ActorTypeEnum,
        RelatedPerson: 'RELATED_PERSON' as ActorTypeEnum,
        Device: 'DEVICE' as ActorTypeEnum,
        HealthcareService: 'HEALTHCARE_SERVICE' as ActorTypeEnum,
        Location: 'LOCATION' as ActorTypeEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


