/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DentalOfficeContentDto { 
    type?: DentalOfficeContentDto.TypeEnum;
    content?: string;
}
export namespace DentalOfficeContentDto {
    export type TypeEnum = 'DID_YOU_KNOW' | 'WHAT_MAKES_US_UNIQUE' | 'GALLERY';
    export const TypeEnum = {
        DidYouKnow: 'DID_YOU_KNOW' as TypeEnum,
        WhatMakesUsUnique: 'WHAT_MAKES_US_UNIQUE' as TypeEnum,
        Gallery: 'GALLERY' as TypeEnum
    };
}


