import {AbstractControl, ValidationErrors} from '@angular/forms';

export class CreditCardValidator {

  static validCardNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    if (!control.value) {
      return null;
    }

    if (value.length < 13) {
      return {invalidCard: true};
    }

    if (!CreditCardValidator.luhnCheck(value)) {
      return {invalidCard: true};
    }

    if (!CreditCardValidator.checkCardType(value)) {
      return {invalidCard: true};
    }
    return null;
  }

  static validExpiryDate(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    if (!control.value) {
      return null;
    }

    if (value.length < 4) {
      return {invalidExpiryDate: true};
    }
    const month = parseInt(value.substr(0, 2));
    const year = parseInt(value.substr(-2));
    const currentYear = parseInt(new Date().getFullYear().toString().substr(-2));

    if (month < 1 || month > 12) {
      return {invalidExpiryDate: true};
    }

    if(year < currentYear){
      return {invalidExpiryDate: true};
    }

    return null;
  }

  static validCvc(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;

    if (!control.value) {
      return null;
    }

    if (value.length < 3) {
      return {invalidCvc: true};
    }
    if (value.length > 4) {
      return {invalidCvc: true};
    }

    return null;
  }

  static luhnCheck(num): boolean {
    // tslint:disable-next-line:one-variable-per-declaration
    let digit, digits, j, len, odd, sum;
    odd = true;
    sum = 0;
    digits = (num + '').split('').reverse();
    for (j = 0, len = digits.length; j < len; j++) {
      digit = digits[j];
      digit = parseInt(digit, 10);
      if ((odd = !odd)) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  }

  static checkCardType(control): CardType {
    const acceptedCreditCards = {
      Visa: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
      MasterCard: /^(?:5[1-5][0-9]{14})$/,
      AmericanExpress: /^(?:3[47][0-9]{13})$/,
      Discover: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/,
      DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
    };

    const value = control.replace(/\D/g, '');
    let cardType: CardType;
    Object.keys(acceptedCreditCards).forEach((key) => {
      const regex = acceptedCreditCards[key];
      if (regex.test(value)) {
        cardType = CardType[key];
      }
    });

    return cardType;
  }

}

export enum CardType {
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  AmericanExpress = 'AmericanExpress',
  Discover = 'Discover',
  DinersClub = 'DinersClub',
  JCB = 'JCB'
}

