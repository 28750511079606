<div class="modal-content">
  <div class="modal-header rounded-0 bg-primary">
    <h5 class="modal-title text-light" id="exampleModalLabel"> Who would you like to refer to? </h5>
    <button type="button" style="color: white;" class="btn-close text-light" data-bs-dismiss="modal" aria-label="Close"
            (click)="close()"></button>
  </div>
  <div class="modal-body">
    <main class="">
      <div *ngIf="showSuccessMessageTrigger">
        <div class="alert-success alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show" role="alert">
          <p class="m-0 p-0 d-flex align-items-start">
                <span>
                  <iconify-icon
                    class="iconify me-2"
                    icon="ep:info-filled"
                  ></iconify-icon>
                  {{ getSuccessMessage() | removeUnderscores }}
                </span>
            <iconify-icon
              class="iconify fs-4 ms-auto pointer"
              icon="mdi:close"
              data-bs-dismiss="alert">
            </iconify-icon>
          </p>
        </div>
      </div>
      <!--new div starts here-->
      <div class="">
        <div class="my-3">
          <div id="main-tab">
            <hr class="line mb-2">
            <div class="row ps-2">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link p-3 rounded-0" id="pills-home-tab" (click)="this.searchClickToggle(null, true);"
                          [ngClass]="{active: isTabActiveConnect}"
                          data-bs-toggle="pill" data-bs-target="#pills-home" type="button"
                          role="tab" aria-controls="pills-home" aria-selected="true"
                          style="background-color: transparent !important; border: solid 2px #369dbf; color: #369dbf !important;">My Connections
                  </button>
                </li>
<!--                <li class="nav-item" role="presentation">-->
<!--                  <button class="nav-link p-3" (click)="this.searchClickToggle(true, null);"-->
<!--                          [ngClass]="{active: isTabActiveHome}"-->
<!--                          id="pills-profile-tab" data-bs-toggle="pill"-->
<!--                          data-bs-target="#pills-profile" type="button" role="tab"-->
<!--                          aria-controls="pills-profile" aria-selected="false"> All Offices-->
<!--                  </button>-->
<!--                </li>-->
                <li class="nav-item" role="presentation">
                  <form [formGroup]="this.form" class="p-1 form-floating">
                    <div class="d-flex justify-content-center">
                      <div class="searchbar">
<!--                        <input *ngIf="!switchToGoogle"  class="search_input" type="text"-->
<!--                               formControlName="dentalOfficeName"-->
<!--                               [style.display]="!switchToGoogle ? 'inline-block' : 'none'"-->
<!--                               placeholder="Search Office..."-->
<!--                               style="width: 80%;">-->

                        <input  class="search_input" type="text"
                               ngx-google-places-autocomplete
                               #searchInput
                               [options]="placesConfig"
                                [style.display]="'inline-block'"
                                formControlName="dentalOfficeName"
                               (onAddressChange)="getPlacesAutocomplete($event)"
                               style="width: 80%;"
                               placeholder="Search Office...">
                        <iconify-icon icon="bi:search" class="search_icon pointer" style="color: white;"
                                      (click)="this.isTabActiveConnect ? this.onSearchClick() :  this.onOfficeSearchClick()"></iconify-icon>
                      </div>
                    </div>
                  </form>
                </li>
              </ul>
            </div>
            <hr class="mb-5 line mt-2">
            <nav class="">
              <p class="fw-bold">Filter by Specialty:</p>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="all-tab" data-bs-toggle="tab"
                        data-bs-target="#all" type="button" role="tab" aria-controls="all"
                        aria-selected="true"
                        (click)="this.isTabActiveHome ? searchClickToggle(true, null) : searchClickToggle(null, true)">
                  All
                </button>
                <button class="nav-link" id="Endodontist-tab"
                        data-bs-toggle="tab" (click)="this.filterBySpeciality('Endodontics')"
                        data-bs-target="#Endodontist" type="button" role="tab"
                        aria-controls="Endodontist" aria-selected="false">Endodontics
                </button>
                <button class="nav-link" (click)="this.filterBySpeciality('Oral Surgery')"
                        id="Oral-Surgeon-tab" data-bs-toggle="tab"
                        data-bs-target="#Oral-Surgeon" type="button" role="tab"
                        aria-controls="Oral Surgeon" aria-selected="false">Oral Surgery
                </button>
                <button class="nav-link" (click)="this.filterBySpeciality('Orthodontics')"
                        id="Orthodontics-tab" data-bs-toggle="tab"
                        data-bs-target="#Orthodontics" type="button" role="tab"
                        aria-controls="Orthodontics" aria-selected="false">Orthodontics
                </button>
                <button class="nav-link" (click)="this.filterBySpeciality('Periodontics')"
                        id="Periodontics-tab" data-bs-toggle="tab"
                        data-bs-target="#Periodontics" type="button" role="tab"
                        aria-controls="Periodontics" aria-selected="false">Periodontics
                </button>
                <button class="nav-link" (click)="this.filterBySpeciality('Pedodontics')"
                        id="Pedodontics-tab" data-bs-toggle="tab"
                        data-bs-target="#Pedodontics" type="button" role="tab"
                        aria-controls="Pedodontics" aria-selected="false">Pedodontics
                </button>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                     role="button" aria-expanded="false">Other</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item pointer" (click)="this.filterBySpeciality('Oral Pathology')">Oral
                      Pathology</a></li>
                    <li><a class="dropdown-item pointer" (click)="this.filterBySpeciality('Prosthodontist')">Prosthodontist</a>
                    </li>
                    <li><a class="dropdown-item pointer" (click)="this.filterBySpeciality('General Dentistry')">General Dentistry</a>
                    </li>
                    <li><a class="dropdown-item pointer" (click)="this.filterBySpeciality('Dental Hygiene School')">Dental Hygiene School</a>
                    </li>
                    <li><a class="dropdown-item pointer" (click)="this.filterBySpeciality('Dental School')">Dental School</a>
                    </li>
                  </ul>
                </li>
              </div>
            </nav>


            <div class="tab-content" id="pills-tabContent">
              <!-- First Tab Search All Offices -->
              <div class="tab-pane fade show" id="pills-profile" role="tabpanel"
                   aria-labelledby="pills-home-tab">
                <div class="card bg-transparent border-light mb-3 mb-5 p-1">
                  <div class="pt-3">
<!--                    NO DATA || GOOGLE PLACES SEARCH-->
                    <div *ngIf="this.resultArrayAllOffices?.length < 1"
                         class="d-flex my-4 w-100 text-bolder">
                      <aside class="justify-content-center text-center text-uppercase">
                        <h5 *ngIf="!this.googleName">NO DATA</h5>
                      </aside>

                      <button type="button" class="col-lg-4 shadow card-border btn" name="button" style="margin: 0 7px 1rem 0;" *ngIf="this.googleName"
                              [ngClass]="{'btn-secondary': false}" (click)="confirmGoogleOffice()">
                        <div class="ps-1 pe-2 ">
                          <div class="row p-2">
                            <div class="col-auto position-relative mb-2">
                              <img [src]=getGoogleOfficeImage() class="rounded-2" style="width:75px;">
                            </div>
                            <div class="col px-1">
                              <div class="float-start">
                                <h6 class="d-block text-start">{{ this.googleName | titlecase}}</h6>
                                <address class=" small text-start">{{ this.googleCountry}}</address>
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>


                    <div class="row">
                      <button type="button" class="col-lg-4 shadow card-border btn" name="button"
                              *ngFor="let result of resultSubjectAllOffices.value.results;  let i = index"
                              style="margin: 0 7px 1rem 0;"
                              [ngClass]="{'btn-secondary': this.form.getRawValue().selectedOfficeId == result.id}">
                        <div class="ps-1 pe-2 ">
                          <div class="row p-2">
                            <div class="col-auto position-relative mb-2" (click)="onSearchProfessional(result, i)">
                              <img [src]=getFileURL(result.dentalOfficeImageId) class="rounded-2" style="width:75px;">
                              <span *ngIf="result.dentalDoor"
                                    class="position-absolute  start-90 translate-middle border-0 rounded-circle">
                                  <img src="assets/img/dd-badgegold.svg" [tooltip]="'Office on DentalDoor'"
                                       style="color: green;"
                                       width="20">
                                </span>
                            </div>
                            <div class="col px-1">
                              <div class="float-start" (click)="onSearchProfessional(result, i)">
                                <h6 class="d-block text-start">{{result.name | titlecase}}</h6>
                                <div class="text-start mb-2">
                                    <span class=" small text-start "
                                          *ngFor="let res of result.specializationPojoList; let i = index; last as isLast">{{
                                      res.name }}{{ !isLast
                                        ? ', ' : ' '}}</span>
                                </div>
                                <p class=" small text-start mb-2 ">{{result?.specializationDetails}}</p>
                                <address class=" small text-start">{{result?.streetAddress}}</address>
                              </div>
                              <div class="p-1 float-end">
                                <label class="switch" *ngIf="hasInsuranceCompany(result)">
                                  <iconify-icon class="icon" style="color: green;" icon="map:insurance-agency" [tooltip]="'Accepts Selected Insurance'"
                                                width="20"></iconify-icon>
                                </label>
                                <label class="switch">
                                  <input type="checkbox" [checked]="result?.currentOfficeFavourite"
                                         (click)="toggleFavorite(result?.currentOfficeFavourite ? this.favoriteEnum.Dislike : this.favoriteEnum.Like, result, false)">
                                  <iconify-icon class="icon" style="color: #aaa;" icon="mdi:cards-heart"
                                                width="20"></iconify-icon>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="this.resultArrayAllOffices?.length > 0">
                      <div
                        class="align-items-center d-flex justify-content-between align-items-baseline rounded-top px-2 py-2 mt-3"
                        style="margin-bottom: -1rem; color: #4e3367">
                        <app-page-length
                          [pageLength]="getFilterAllOffices().limit || 10"
                          (pageLengthChanged)="changeLimitAllOffices($event)"></app-page-length>

                        <div class="add-group-btn text-right mt-3 mb-3">
                          <pagination

                            (pageChanged)="onPageChangeAllOffices($event)"
                            [boundaryLinks]="true"
                            [itemsPerPage]="limit || 10"
                            [(ngModel)]="page"
                            [maxSize]="5"
                            [totalItems]="resultSubjectAllOffices?.value?.total || 0"
                            firstText="&laquo;"
                            lastText="&raquo;"
                            nextText="&rsaquo;"
                            previousText="&lsaquo;"></pagination>
                        </div>
                      </div>
                    </div>

                    <!-- Search Professional Loader-->
                    <div *ngIf=" this.searchingProfessional"
                         class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
                      <div class="spinner-grow text-info" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow text-info" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow text-info" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <!-- End Search Professional Loader-->


                    <!-- Dental Professional List-->
                    <div class="">
                      <div class="row" id="scrollToProfessional">

                        <div class="col-lg-4 col-sm-12 hover-card flip-card p-1"
                             *ngIf="this.resultSubjectProfessional?.value?.results?.length >= 1">
                          <div class="flip-card-inner shadow text-center" (click)="confirm()">
                            <div class="flip-card-front bg-light">
                              <h5
                                class="mt-1 text-truncate text-muted">{{resultSubjectAllOffices?.value?.results[this.selectedOfficeIndex?.value]?.name}}
                              </h5>
                              <img
                                [src]=getFileURL(resultSubjectAllOffices?.value?.results[this.selectedOfficeIndex?.value]?.dentalOfficeImageId)
                                class="rounded-circle my-1" alt="Avatar"
                                style="width: 7rem">
                              <h6 class=" text-truncate text-muted">{{'Available Dr.'}}</h6>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-12 hover-card flip-card p-1"
                             *ngFor="let prof of this.resultSubjectProfessional?.value?.results">
                          <div class="flip-card-inner shadow text-center"
                               *ngIf="this.resultSubjectProfessional?.value?.results"
                               (click)="onClickProfessional(prof?.dentalProfessionalPojo?.id, prof?.dentalProfessionalPojo?.name)">
                            <div class="flip-card-front bg-light">
                              <h5
                                class="mt-1 text-truncate text-muted">{{resultSubjectAllOffices?.value?.results[this.selectedOfficeIndex?.value]?.name}}
                              </h5>
                              <img src="./assets/img/biguser.png" class="rounded-circle my-1" alt="Avatar"
                                   style="width: 7rem">
                              <h6 class=" text-truncate text-muted">Dr. {{prof?.dentalProfessionalPojo.name}}</h6>
                            </div>
                            <div class="flip-card-back bg-primary pt-3 px-2">
                              <div *ngFor="let res of prof.specialization">
                                <h6 class=" text-truncate">Dr. {{res?.specialization?.name}}</h6>
                                <address class="mt-2">{{prof?.addressPojo?.streetAddress}}
                                </address>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="this.resultArrayProfessional?.length > 0">
                        <div
                          class="align-items-center d-flex justify-content-between align-items-baseline rounded-top px-2 py-2 mt-3"
                          style="margin-bottom: -1rem; color: #4e3367">
                          <div class="add-group-btn text-right mt-3 mb-3">
                            <pagination
                              (pageChanged)="onPageChangeProfessional($event)"
                              [boundaryLinks]="true"
                              [itemsPerPage]="limit || 10"
                              [(ngModel)]="page"
                              [maxSize]="5"
                              [totalItems]="resultSubjectProfessional?.value?.total || 0"
                              firstText="&laquo;"
                              lastText="&raquo;"
                              nextText="&rsaquo;"
                              previousText="&lsaquo;"></pagination>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <!-- MyConnection Tab to see favorites -->
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                   aria-labelledby="pills-profile-tab">
                <div class="card bg-transparent border-light mb-3 mb-5">
                  <div class="pt-3">
                    <div *ngIf="this.resultArray?.length < 1"
                         class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
                      <h5 *ngIf="!this.switchToGoogle">NO DATA</h5>
                      <button type="button" class="col-lg-4 shadow card-border btn" name="button" style="margin: 0 7px 1rem 0;" *ngIf="this.googleName"
                              [ngClass]="{'btn-secondary': false}" (click)="confirmGoogleOffice()">
                        <div class="ps-1 pe-2 ">
                          <div class="row p-2">
                            <div class="col-auto position-relative mb-2">
                              <img [src]=getGoogleOfficeImage() class="rounded-2" style="width:75px;">
                            </div>
                            <div class="col px-1">
                              <div class="float-start">
                                <h6 class="d-block text-start">{{ this.googleName | titlecase}}</h6>
                                <address class=" small text-start">{{ this.googleCountry}}</address>
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>

                    </div>
                    <div class="row">
                      <button type="button" class="col-lg-4 shadow card-border btn" name="button"
                              *ngFor="let result of resultSubject.value.results; let i = index"
                              style="margin: 0 7px 1rem 0;"
                              [ngClass]="{'btn-secondary': this.form.getRawValue().selectedOfficeId == result.dentalOfficePojo.id}">
                        <div class="ps-1 pe-2 ">
                          <div class="row p-2">
                            <div class="col-auto position-relative mb-2"
                                 (click)="onSearchProfessional(result.dentalOfficePojo, i, result.specializationPojos)">
                              <img [src]=getFileURL(result?.dentalOfficePojo?.dentalOfficeImageId) class="rounded-2"
                                   style="width:75px;">
                              <span *ngIf="result?.dentalOfficePojo?.dentalDoor"
                                    class="position-absolute  start-90 translate-middle border-0 rounded-circle">
                                  <img src="assets/img/dd-badgegold.svg" [tooltip]="'Office on DentalDoor'"
                                       style="color: green;"
                                       width="20">
                                </span>
                            </div>
                            <div class="col px-1">
                              <div class="float-start" (click)="onSearchProfessional(result.dentalOfficePojo, i, result.specializationPojos)">
                                <h6 class="d-block text-start">{{result?.dentalOfficePojo?.name | titlecase}}</h6>
                                <div class="text-start mb-2">
                                    <span class=" small text-start "
                                          *ngFor="let res of result.specializationPojos; let i = index; last as isLast">{{
                                      res.name }}{{ !isLast
                                        ? ', ' : ' '}}</span>
                                </div>
                                <address class=" small text-start">{{result?.streetAddress}}</address>
                              </div>
                              <div class="p-1 float-end">
                                <label class="switch" *ngIf="hasInsuranceCompany(result)">
                                  <iconify-icon class="icon" style="color: green;" icon="map:insurance-agency" [tooltip]="'Accepts Selected Insurance'"
                                                width="20"></iconify-icon>
                                </label>
                                <form class="switch" [formGroup]="favoriteForm">
                                  <input type="checkbox" formControlName="checkFavourite">
                                  <iconify-icon  (click)="toggleFavorite(result?.dentalOfficePojo?.currentOfficeFavourite ? this.favoriteEnum.Dislike : this.favoriteEnum.Like, result.dentalOfficePojo, true)"
                                    class="icon" style="color: #aaa;" icon="mdi:cards-heart"
                                                width="20"></iconify-icon>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="this.resultArray?.length > 0">
                      <div
                        class="align-items-center d-flex justify-content-between align-items-baseline rounded-top px-2 py-2 mt-3"
                        style="margin-bottom: -1rem; color: #4e3367">
                        <app-page-length
                          [pageLength]="getFilter().limit || 10"
                          (pageLengthChanged)="changeLimit($event)"></app-page-length>

                        <div class="add-group-btn text-right mt-3 mb-3">
                          <pagination
                            (pageChanged)="onPageChange($event)"
                            [boundaryLinks]="true"
                            [itemsPerPage]="limit || 10"
                            [(ngModel)]="page"
                            [maxSize]="5"
                            [totalItems]="resultSubject.value.total || 0"
                            firstText="&laquo;"
                            lastText="&raquo;"
                            nextText="&rsaquo;"
                            previousText="&lsaquo;"></pagination>
                        </div>
                      </div>
                    </div>

                    <div *ngIf=" this.searchingProfessional"
                         class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
                      <div class="spinner-grow text-info" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow text-info" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow text-info" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <!-- Dental Professional List-->
                    <div class="" id="scrollToProfessionalConnect">
                      <div class="d-flex">
                        <div class="card border-0 shadow-sm m-2 hover-card col-4"
                             *ngFor="let prof of this.resultSubjectProfessional.value.results">
                          <div class="card-header d-flex bg-transparent border-0 row"
                               (click)="onClickProfessional(prof?.dentalProfessionalPojo?.id, prof?.dentalProfessionalPojo?.name)">
                            <div class="one col-md-3">
                              <img class="img-fluid rounded-3"
                                   width="50" src="assets/img/user_avatar.png" alt="">
                            </div>
                            <div class="two col-md-8 p-3 ms-1">
                              <p class="card-title">{{prof?.dentalProfessionalPojo.name}}</p>
                              <p class="card-title">{{prof?.dentalProfessionalPojo.email}}</p>
                              <div *ngFor="let res of prof.specialization">
                                <a>{{res?.specialization?.name}}</a>
                              </div>
                              <address>{{prof?.addressPojo?.streetAddress}}</address>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="this.resultArrayProfessional?.length > 0">
                        <div
                          class="align-items-center d-flex justify-content-between align-items-baseline rounded-top px-2 py-2 mt-3"
                          style="margin-bottom: -1rem; color: #4e3367">
                          <div class="add-group-btn text-right mt-3 mb-3">
                            <pagination
                              (pageChanged)="onPageChangeProfessional($event)"
                              [boundaryLinks]="true"
                              [itemsPerPage]="limit || 10"
                              [(ngModel)]="page"
                              [maxSize]="5"
                              [totalItems]="resultSubjectProfessional.value.total || 0"
                              firstText="&laquo;"
                              lastText="&raquo;"
                              nextText="&rsaquo;"
                              previousText="&lsaquo;"></pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
