/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DentalProfessionalAwardPojo { 
    title?: string;
    instituteName?: string;
    yearIssued?: number;
    id?: number;
    verified?: boolean;
    type?: DentalProfessionalAwardPojo.TypeEnum;
    certificateNumber?: string;
}
export namespace DentalProfessionalAwardPojo {
    export type TypeEnum = 'HONORARY' | 'CERTIFICATION' | 'PUBLICATION';
    export const TypeEnum = {
        Honorary: 'HONORARY' as TypeEnum,
        Certification: 'CERTIFICATION' as TypeEnum,
        Publication: 'PUBLICATION' as TypeEnum
    };
}


