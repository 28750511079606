import {PermissionType} from "../shared/account/permission-type.enum";
import {OtherData} from "./OtherData";
import {DentalOfficeSubscriptionPojo, SubscriptionCycle} from "dd-core-api-sdk";

export class UserAccountKeycloak {

  public accountCode!: string;
  public accountId!: number;
  public accountName!: string;
  public accountType!: string;
  public permissions!: string[];
  public roles!: string[];
  public id!: number;
  public enabledForIntegration?: boolean;
  public otherData!: OtherData;
  public subscriptionCycle!: SubscriptionCycle;
  public dentalOfficeSubscription!: DentalOfficeSubscriptionPojo;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public hasPermission(permission: string | PermissionType) {
    if (this.permissions && this.permissions.length > 0) {
      return this.permissions.filter((it: string) => it === permission).length;
    }
    return false;
  }

}
