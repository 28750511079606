import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-add-referral-prof-modal',
  templateUrl: './add-referral-prof-modal.component.html',
  styleUrls: ['./add-referral-prof-modal.component.css']
})
export class AddReferralProfModalComponent implements OnInit {

  formGroup: FormGroup;
  errorSubmission = false;

  @Output()
  emitManualProfDetails = new EventEmitter<any>(null);

  constructor(private fb: FormBuilder, private bsModalRef: BsModalRef,) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      profFirstName: ['', [Validators.required]],
      profLastName: ['', [Validators.required]],
    });
  }

  onAddButtonClick() {
    if (this.formGroup.invalid) {
      this.errorSubmission = true;
      return;
    }
    this.emitManualProfDetails.emit(this.formGroup);
  }

  close() {
    this.bsModalRef.hide();
  }


}
