/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalOffice } from './dental-office';
import { AvailableTime } from './available-time';


export interface DentalOfficeAvailability { 
    status?: DentalOfficeAvailability.StatusEnum;
    dateCreated?: string;
    available?: boolean;
    id?: number;
    availableTime?: AvailableTime;
    dentalOffice?: DentalOffice;
}
export namespace DentalOfficeAvailability {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


