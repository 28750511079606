import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import { UserRegistrationService } from 'dd-core-api-sdk';
// import {BsModalService} from "ngx-bootstrap/modal";
import {SuccessModalComponent} from "../../shared/success-modal/success-modal.component";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {KeycloakService} from "keycloak-angular";
import {SocialService} from "../../shared/subject/SocialService";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  userId: any;
  working: boolean;
  prevUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userRegService: UserRegistrationService,
    private keycloak: KeycloakService,
    private socialService: SocialService,
    private authenticationServiceKeycloak :AuthenticationServiceKeycloak
  ) {
  }

  ngOnInit(): void {
    sessionStorage.clear();
    this.socialService.behaviorSubject.next('');
    this.activatedRoute.params.subscribe(param => {
      if (!param['userId']) {
        this.router.navigate(['/']);
        return;
      }
      this.userId = param.userId;
      this.verifyUserEmail();
    });
  }

  verifyUserEmail() {
     this.userRegService.verifyEmail({userId: this.userId})
      .subscribe(res => {
        this.working = false;
        console.log(res)
        this.loginUserManually(res.email, res.authToken);
      }, (e) => {
        this.working = false;
        this.router.navigate(['/']);
      });
  }

  loginUserManually(username, password) {
    this.userRegService.login({loginDto: {username: username, password: password}}).subscribe(res => {
      this.socialService.save(res.accessToken);
      sessionStorage.setItem('keycloak_token', res.accessToken);
      this.router.navigate(['/dashboard']);
    }, err => {
      this.router.navigate(['/']);
    })
  }

}
