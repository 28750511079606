<section class="faq-section">
    <div class="container">
        <div class="row">
            <!-- ***** FAQ Start ***** -->
            <div class="col-md-6 offset-md-3">

                <div class="faq-title text-center pb-3">
                    <h2>FAQ</h2>
                </div>
                </div>
                <div class="col-md-6 offset-md-3">
                    <div class="faq" id="accordion">
                        <div class="card">
                            <div class="card-header" id="faqHeading-1">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-1" aria-expanded="true"
                                        aria-controls="faqCollapse-1">
                                        <span class="badge">1</span>Do I have to create an account in order to refer a patient?
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-1" class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                                <div class="card-body">
                                    <p>Yes. The registration process is seamless and takes less than 2 minutes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="faqHeading-2">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-2"
                                        aria-expanded="false" aria-controls="faqCollapse-2">
                                        <span class="badge">2</span>Is DentalDoor HIPAA compliant?
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-2" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                                <div class="card-body">
                                    <p>Yes. We are HIPPA compliant.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="faqHeading-3">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-3"
                                        aria-expanded="false" aria-controls="faqCollapse-3">
                                        <span class="badge">3</span>Does DentalDoor have a privacy policy?
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-3" class="collapse" aria-labelledby="faqHeading-3" data-parent="#accordion">
                                <div class="card-body">
                                    <p>Absolutely. You can read our privacy policy <a href="/review-privacy">here</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="faqHeading-4">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-4"
                                        aria-expanded="false" aria-controls="faqCollapse-4">
                                        <span class="badge">4</span>How does DentalDoor secure its platform?
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-4" class="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
                                <div class="card-body">
                                    <p>Our platform is very secure and complies with HIPPA standard. We use 5 star security companies to
                                        make sure our platform
                                        adheres to the highest security standards.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="faqHeading-5">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-5"
                                        aria-expanded="false" aria-controls="faqCollapse-5">
                                        <span class="badge">5</span>Will my data be sold to third parties for any reason?
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-5" class="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
                                <div class="card-body">
                                    <p><strong>Absolutely Not!</strong>We value the privacy of every DentalDoor user.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="faqHeading-6">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-6"
                                        aria-expanded="false" aria-controls="faqCollapse-6">
                                        <span class="badge">6</span>What languages does DentalDoor platform support?
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-6" class="collapse" aria-labelledby="faqHeading-6" data-parent="#accordion">
                                <div class="card-body">
                                    <p>We currently support English language. We are working towards supporting more
                                        languages soon</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="faqHeading-7">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-7"
                                        aria-expanded="false" aria-controls="faqCollapse-7">
                                        <span class="badge">7</span>I created an account but have forgotten my password, how do I
                                        retrieve it?
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                                <div class="card-body">
                                    <p>Kindly click <a href="/login">here</a> and follow the instructions to reset your Password</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="faqHeading-8">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-bs-toggle="collapse" data-bs-target="#faqCollapse-8"
                                        aria-expanded="false" aria-controls="faqCollapse-8">
                                        <span class="badge">8</span>If I can't find what I need or have further questions, how can I
                                        reach you guys?
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-8" class="collapse" aria-labelledby="faqHeading-8" data-parent="#accordion">
                                <div class="card-body">
                                    <p>We would love to hear from you. You can contact us 24/7 using either of these options:</p>
                                    <ul>
                                        <li>
                                            Email:<a type="email" href="mailto:help@dentaldoor.com">help@dentaldoor.com</a>
                                        </li>
                                        <li>
                                            Call us: <a type="tel" href="tel:775 386 2655">(775) 386 2655</a>
                                        </li>
                                    </ul>
                                    <p>
                                    You can also leave a quick message using the help option on your dashboard, and one of our
                                    representatives will quickly
                                    help you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
                </section>