/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceCompany } from './insurance-company';
import { Specialization } from './specialization';
import { Address } from './address';
import { DentalProfessional } from './dental-professional';
import { PortalUser } from './portal-user';


export interface DentalOfficeProfilePojo { 
    name?: string;
    summary?: string;
    email?: string;
    phoneNumber?: string;
    alternatePhoneNumber?: string;
    facebookUrl?: string;
    twitterUrl?: string;
    instagramUrl?: string;
    alternateEmail?: string;
    dateCreated?: string;
    lastUpdated?: string;
    status?: DentalOfficeProfilePojo.StatusEnum;
    uniqueStatement?: string;
    setupComplete?: boolean;
    website?: string;
    reviewProfileId?: string;
    approvalStatus?: DentalOfficeProfilePojo.ApprovalStatusEnum;
    reasonForDenial?: string;
    dateOfApprovalProcessing?: string;
    lastReviewPage?: number;
    code?: string;
    handle?: string;
    requireInsuranceForAppointment?: boolean;
    requireIdentityForAppointment?: boolean;
    appointmentBookingPolicy?: string;
    apptProcessingTimeInMinutes?: number;
    fax?: string;
    mobileNumber?: string;
    specialityOffice?: boolean;
    specialtyOffice?: boolean;
    id?: number;
    address?: Address;
    admin?: PortalUser;
    professionals?: Array<DentalProfessional>;
    professionalCountBySpeciality?: { [key: string]: number; };
    officeSpecialization?: Array<Specialization>;
    insuranceCompanyList?: Array<InsuranceCompany>;
    aboutUrl?: string;
}
export namespace DentalOfficeProfilePojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
}


