import {Component, Input, OnInit} from '@angular/core';
import {SocialService} from "../subject/SocialService";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input()
  infoTitle: string;
  @Input()
  loading: boolean = true;
  link: string;

  constructor(private socialService: SocialService) {}

  ngOnInit(): void {
    this.socialService.kycLinkSubject.subscribe(res => {
      this.link = res;
      if (this.link)
        this.loading = false;
    });
  }


}
