import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";
import {
  AffiliateBankControllerService,
  AffiliateBankPojo,
  CreateCustomerConnectingAcc,
  PaymentControllerService, StripeAccountLinksPojo, WalletTransactionPojo
} from "dd-core-api-sdk";
import {AccountLinkingWizardComponent} from "../account-linking-wizard/account-linking-wizard.component";
import {SpinnerComponent} from "../../../../../shared/spinner/spinner.component";
import {environment} from "../../../../../../environments/environment";
import {SocialService} from "../../../../../shared/subject/SocialService";
import {ActivatedRoute, Router} from "@angular/router";
import {KycStatusPageComponent} from "../kyc-status-page/kyc-status-page.component";
import {WalletWithdrawalWizardComponent} from "../wallet-withdrawal-wizard/wallet-withdrawal-wizard.component";
import {
  AffiliateErrorInfoModalComponent
} from "../../../../../shared/affiliate-error-info-modal/affiliate-error-info-modal.component";

@Component({
  selector: 'app-affiliate-bank',
  templateUrl: './affiliate-bank.component.html',
  styleUrls: ['./affiliate-bank.component.css']
})
export class AffiliateBankComponent implements OnInit {
  affiliateBank: AffiliateBankPojo;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  bankKycStatusConstantEnum = AffiliateBankPojo.BankKycStatusConstantEnum;
  bankVerificationStatusConstantEnum = AffiliateBankPojo.BankVerificationStatusConstantEnum;

  @Output()
  emitBankDetails = new EventEmitter<AffiliateBankPojo>();

  constructor(private modalService: BsModalService,
              private socialService: SocialService,
              private router: Router,
              private _activeRoute: ActivatedRoute,
              private affiliateBankControllerService: AffiliateBankControllerService,
              private paymentControllerService: PaymentControllerService) {
  }

  ngOnInit(): void {
    this._activeRoute.queryParams.subscribe(paramMap => {
      if (paramMap?.kyc) {
        const data = atob(paramMap?.kyc);
        const dataSessions = sessionStorage.getItem("ACCOUNT_LINKS") || '';

        const kycDetails = dataSessions ? JSON.parse(atob(dataSessions)) : null;

        if (data == 'success' && kycDetails != null) {
          this.updateKycStatus(true);
          sessionStorage.removeItem("ACCOUNT_LINKS");
          this.showKycStatus('Your KYC details has been saved successfully and details will be verified in a short while. Click to dismiss the button below to dismiss the modal.', true);
        } else {
          sessionStorage.removeItem("ACCOUNT_LINKS");
          this.updateKycStatus(false);
          this.showKycStatus('KYC Failed!, kindly click to dismiss the button below to dismiss the modal.', false);
        }
      }
    })

    this.getSingleBankAccount();
  }

  getSingleBankAccount() {
    this.affiliateBankControllerService.getSingleAffiliateBank().subscribe(res => {
      this.affiliateBank = res;
      this.emitBankDetails.emit(res);
    });

    // this.initiatePayout(this.affiliateBank);
  }

  addBankAccount() {
    let bsModalRef = this.modalService.show(AccountLinkingWizardComponent, {
      initialState: {},
      keyboard: false,
      backdrop: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.submitButtonClickedSuccess.subscribe(v => {
      bsModalRef.hide();
      this.getSingleBankAccount();
    });
  }

  initiateCustomerKyc() {
    this.loadingSpinner();

    const success = btoa("success");
    const failure = btoa("failure");

    const connectAcc: CreateCustomerConnectingAcc = {
      failureUrl: environment.url + "affiliates/withdraw?kyc=" + failure,
      successUrl: environment.url + "affiliates/withdraw?kyc=" + success
    }
    this.paymentControllerService.createStripeAccountAndLink({createCustomerConnectingAcc: connectAcc}).subscribe({
      next: (v) => {
        this.socialService.kycLinkSubject.next(v.url);
        this.saveWithTime(v);
      },
      error: (e) => {
        this.isError = true;
        this.showErrorMessage(e.error ? e?.error?.message : 'Could not initiate customer KYC. Kindly try again later.');
        this.modalService.hide();
      },
      complete: () => {
      }
    });
  }

  private updateKycStatus(isSuccess) {
    this.affiliateBankControllerService.updateAffiliateBank({isSuccess: isSuccess}).subscribe(res => {
      this.getSingleBankAccount();
    });
  }

  loadingSpinner() {
    this.modalService.show(SpinnerComponent, {
      initialState: {
        infoTitle: 'Please wait while we initiate KYC and redirect you to Stripe Onboarding page.',
      },
      keyboard: false,
      backdrop: true,
      animated: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });
  }

  showKycStatus(message, isSuccess) {
    let bsModalRef = this.modalService.show(KycStatusPageComponent, {
      initialState: {
        info: message,
        isSuccess: isSuccess
      },
      keyboard: false,
      backdrop: true,
      animated: true,
      class: 'modal-lg modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.closeClicked.subscribe(v => {
      bsModalRef.hide();
      this.router.navigate(['/affiliates/withdraw']);
    });

  }

  showMoreInfo(aff: AffiliateBankPojo) {
    let bsModalRef = this.modalService.show(AffiliateErrorInfoModalComponent, {
      initialState: {
        infoTitle: 'More Details',
        affiliateBank: aff
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.closeClicked.subscribe(v => {
      bsModalRef.hide();
    });
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showMessageBasedOnStatus(affiliateBank: AffiliateBankPojo) {
    this.isError = true;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 6000);

    if (affiliateBank.maskedAccountNumber == undefined) {
      this.errMessage = "1. Kindly complete your KYC before initiating payout. \n2. Kindly complete your bank verification before initiating payout.";
      return;
    }
    if (affiliateBank?.bankKycStatusConstant != this.bankKycStatusConstantEnum.Successful) {
      this.errMessage = "Kindly complete your KYC before initiating payout.";
      return;
    }
    if (affiliateBank?.bankVerificationStatusConstant != this.bankVerificationStatusConstantEnum.Successful) {
      this.errMessage = !this.errMessage ? '1. ' + this.errMessage + "\n" + "2. Kindly complete your bank verification before initiating payout." : "Kindly complete your KYC before initiate payout.";
      return;
    }
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  buildBankUrl(url: string) {
    return environment.url + url;
  }

  private saveWithTime(v: StripeAccountLinksPojo) {
    sessionStorage.setItem("ACCOUNT_LINKS", btoa(JSON.stringify(v)));
  }

  initiatePayout(affiliateBankPojo: AffiliateBankPojo) {
    let bsModalRef = this.modalService.show(WalletWithdrawalWizardComponent, {
      initialState: {
        affiliateBankPojo: affiliateBankPojo
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.cancelClicked.subscribe(v => {
      bsModalRef.hide();
    });
  }

}
