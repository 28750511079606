export const environment = {
  production: true,
  staging: false,
  local: false,
  supportedLangs: ['en', 'es'],
  ddCoreApiBaseUrl: '/api',
  authApiBaseUrl: '/auth-api/bw/api/v1',
  accountApiBaseUrl: '/api',
  apiBaseUrl: '/api',
  dentalDoorReportUrl: '/report-api',
  url: 'https://demo.dentaldoor.com/',
  siteUrl: 'https://demo.dentaldoor.com/api',
  sessionTimeout: 1440,
  social: {
    facebook: {
      appId: '359644598652151',
      appSecret: '2964041ffd32f749a3754e0f8000ce45'
    },
    google: {
      clientId: '290064160926-8h86vgo84ek0add530snl2p8n03jl9b0.apps.googleusercontent.com'
    }
  },
  keycloakConfig: {
    clientId: 'dentaldoor-frontend',
    realm: 'dentaldoor',
    url: 'https://demo.dentaldoor.com/auth'
  },
  deploymentConfig: {
    paymentProvider: 'PAYSTACK',
    deploymentCode: 'NG',
    supportEmail: 'info@dentaldoor.ng',
    countryCode: 'NG',
    supportPhone: '+2349094257562',
    facebookPage: 'https://www.facebook.com/dentaldoor',
    twitterPage: 'https://twitter.com/dentaldoor',
    youtubePage: 'https://www.youtube.com/channel/UCTgcgQ212crY3jijbGW-BsQ',
    contactAddress: '12, YPO Shodeinde, Utako, Abuja, FCT, Nigeria',
    currencyCode: 'NGN',
    currencySymbol: '₦',
    linkedIn: '',
    instagramPage: ''
  },
  PUBLIC_VAPID_KEY_OF_SERVER: 'BGDa3Lg53T46ykHhdtXairwZmOYulfywEhpnwE_aiMLaeuPcKnhsCP47e78xnq9vreYrx3HbEdoudyTm2PRviug',
  PUBLIC_VAPID_KEY: 'BHrIQWzeRX-KrUeTILxdH4b3cHc0EfrmPPIEnFsoJWiOZo291sigJ2TsoGwVkPQO0xtX7Teuv8BcJL9l3kdFueQ',
  paystackKey: 'pk_live_d420a6417ddc96bd62ffb8ba322a61102d8ac170',
  firebaseConfig: {
    apiKey: 'AIzaSyATP2pSytFjJBC_tdnpa9P_JMzYM11ldX8',
    authDomain: 'dental-door-2295e.firebaseapp.com',
    projectId: 'dental-door-2295e',
    storageBucket: 'dental-door-2295e.appspot.com',
    messagingSenderId: '851955429739',
    appId: '1:851955429739:web:75877bb11b578912595c2e',
    measurementId: 'G-JB5PD94BB7',
    vapidKey: 'BIbcar_Vk_biIwugsmOH4EuZFOKKHGl-LOlnGBB6tSBLJyZ0Pldws34qARmzRtmRZDOx_6o-8LBDP5ln3L7uGNM'
  }
};
