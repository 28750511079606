<div class="modal-content">
  <div class="modal-header rounded-0 bg-primary">
    <h1 class="modal-title fs-5 text-light" id="messageModalLabel">Capture Image
      <iconify-icon icon="icon-park-outline:message-unread" style="color: #fff;" width="25" height="25">
      </iconify-icon>
    </h1>
    <button type="button" class="btn-close" (click)="dismissModal()" aria-label="Close"></button>
  </div>

  <div class="bg-glass">

    <ul class="nav nav-tabs row mt-4 mx-auto gap-2">
      <li (click)="changeTab(0)" class="nav-item col pointer">
        <a class="nav-link fw-bold text-center" [ngClass]="{active: tabIndex == 0}">
          Front Image
        </a>
      </li>
      <li (click)="changeTab(1)" class="nav-item col pointer">
        <a class="nav-link fw-bold text-center" [ngClass]="{active: tabIndex == 1}">
          Back Image
        </a>
      </li>
    </ul>

    <div class="pt-2 tab-content tab-pane" [ngSwitch]="tabIndex">
      <!--      First Image Capture -->
      <aside class="text-center my-3" *ngSwitchCase="0" [tabIndex]="tabIndex">
        <div class="justify-content-center m-3">
          <webcam class="" [height]="200" [width]="1000" [trigger]="triggerObservable" *ngIf="!webcamImage"
                  (imageCapture)="handleImage($event)" [allowCameraSwitch]="allowCameraSwitch"
                  [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [imageQuality]="5"
                  (cameraSwitched)="cameraWasSwitched($event) " (initError)="handleInitError($event) "></webcam>
          <div class="modal-content justify-content-center " *ngIf="webcamImage">
                        <span
                          class="position-absolute top-0 start-100 translate-middle badge rounded-circle p-1 bg-danger pointer "
                          data-bs-dismiss="modal " aria-label="Close " (click)="reCapture() ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em"
                       height="2em " viewBox="0 0 24 24">
            <path fill="#ddd "
                  d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z "/>
               </svg></span>
            <div class="modal-body p-3 ">
              <img class="img-fluid" [src]="webcamImage.imageAsDataUrl " [height]="350 " [width]="500">
            </div>
          </div>

        </div>
        <div [ngStyle]="{ 'z-index': '3'}" *ngIf="!this.canSnap"
             class="position-absolute d-flex align-items-center justify-content-center "
             style="top: 0; left: 0; right: 0;bottom: 0; background-color: rgba(0, 0, 0, 0.76) ">
          <div class="text-center ">
            <ng-container *ngIf="!canRecapture ">
                            <span>
                  <iconify-icon class="iconify display-1 ms-3" data-icon="carbon:warning-alt-filled "
                                style="color: #ffcb05 "></iconify-icon>
              </span>
              <p class="text-white mt-2"><i>Loading Camera...</i></p>
            </ng-container>
            <ng-container *ngIf="canRecapture">
              <ng-container *ngIf="canRecapture">
                                <span>
                  <iconify-icon class="iconify display-1 ms-3" data-icon="carbon:warning-alt-filled "
                                style="color: #ffcb05 "></iconify-icon>
                </span>
              </ng-container>
              <p class="text-white mt-2 "><i>You need to recapture image...</i></p>
            </ng-container>
          </div>
        </div>

        <div>
          <div class="text-center my-1 small "><span class="text-danger ">*</span> For optimal results, please switch to
            the rear camera.
          </div>
          <div class="row align-items-center justify-content-center ">
            <div class=" col-auto">
              <button type="button" *ngIf="!webcamImageBackImage?.imageAsDataUrl || !webcamImage?.imageAsDataUrl "
                      (click)="getSnapshot() " class="btn btn-outline-primary btn-large p-3 mt-3 rounded-5 ">
                Capture
              </button>
            </div>
            <div class="col-auto" *ngIf="webcamImage?.imageAsDataUrl ">
              <button type="button" (click)="changeTab(1) "
                      class="btn btn-outline-primary btn-large p-3 mt-3 rounded-5 ">
                Back Image
              </button>
            </div>
            <div class="col-auto " *ngIf="webcamImageBackImage?.imageAsDataUrl && webcamImage?.imageAsDataUrl ">
              <button type="button" (click)="saveImage() "
                      class="btn btn-outline-primary btn-large p-3 mt-3 rounded-5 ">
                Save Images
              </button>
            </div>
          </div>


        </div>
      </aside>
      <aside class="text-center my-3" *ngSwitchCase="1" [tabIndex]="tabIndex">
        <div class="justify-content-center m-3">
          <webcam class=" " style="z-index:2" [height]="200" [width]="1000" [trigger]="triggerObservableBackCamera"
                  *ngIf="!webcamImageBackImage" (imageCapture)="handleBackImage($event)" [allowCameraSwitch]="allowCameraSwitch
                        " [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [imageQuality]="5"
                  (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)"></webcam>
          <div class="modal-content justify-content-center" *ngIf="webcamImageBackImage">
                        <span
                          class="position-absolute top-0 start-100 translate-middle badge rounded-circle p-1 bg-danger pointer "
                          data-bs-dismiss="modal " aria-label="Close " (click)="reCaptureBackCamera() ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em "
                       height="2em " viewBox="0 0 24 24 ">
            <path fill="#ddd "
                  d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z "/>
               </svg></span>
            <div class="modal-body p-3 ">
              <img class="img-fluid " [src]="webcamImageBackImage.imageAsDataUrl " [height]="350 " [width]="500 ">
            </div>
          </div>

        </div>
        <div [ngStyle]="{ 'z-index': '3'} " *ngIf="!this.canSnapBackImage "
             class="position-absolute d-flex align-items-center justify-content-center "
             style="top: 0; left: 0; right: 0;bottom: 0; background-color: rgba(0, 0, 0, 0.76) ">
          <div class="text-center ">
            <ng-container *ngIf="!canRecaptureBackImage ">
                            <span>
                  <iconify-icon class="iconify display-1 ms-3 " data-icon="carbon:warning-alt-filled "
                                style="color: #ffcb05 "></iconify-icon>
              </span>
              <p class="text-white mt-2 "><i>Loading Camera...</i></p>
            </ng-container>
            <ng-container *ngIf="canRecaptureBackImage ">
              <ng-container *ngIf="canRecaptureBackImage ">
                                <span>
                  <iconify-icon class="iconify display-1 ms-3 " data-icon="carbon:warning-alt-filled "
                                style="color: #ffcb05 "></iconify-icon>
                </span>
              </ng-container>
              <p class="text-white mt-2 "><i>You need to recapture image...</i></p>
            </ng-container>
          </div>
        </div>

        <div>
          <div class="text-center my-1 small "><span class="text-danger ">*</span> For optimal results, please switch to
            the rear camera.
          </div>
          <div class="row justify-content-center align-items-center ">
            <div class=" col-auto ">
              <button type="button" *ngIf="!webcamImageBackImage?.imageAsDataUrl || !webcamImage?.imageAsDataUrl "
                      (click)="getSnapshotBackCamera() " class="btn btn-outline-primary btn-large p-3 mt-3 rounded-5 ">
                Capture
              </button>
            </div>
            <div class="col-auto " *ngIf="webcamImageBackImage?.imageAsDataUrl ">
              <button type="button" (click)="changeTab(0) "
                      class="btn btn-outline-primary btn-large p-3 mt-3 rounded-5 ">
                Front Image
              </button>
            </div>
            <div class="col-auto " *ngIf="webcamImageBackImage?.imageAsDataUrl && webcamImage?.imageAsDataUrl ">
              <button type="button" (click)="saveImage() "
                      class="btn btn-outline-primary btn-large p-3 mt-3 rounded-5 ">
                Save Images
              </button>
            </div>
          </div>

        </div>
      </aside>
    </div>
  </div>
</div>
