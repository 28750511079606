import {Component, OnInit} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserAccountKeycloak} from "../../../../services/UserAccountKeycloak";
import {Router} from "@angular/router";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-dashboard-switch',
  templateUrl: './dashboard-switch.component.html',
  styleUrls: ['./dashboard-switch.component.css']
})
export class DashboardSwitchComponent implements OnInit {

  currentAccount: UserAccountKeycloak;
  user: UserKeycloak;
  stop$ = new Subject();

  constructor(private authenticationService: AuthenticationServiceKeycloak,
              private router: Router) {
  }

  ngOnInit(): void {

    this.authenticationService.getAccount()
      .subscribe(value => {
        this.currentAccount = value;
      });
    this.getLoggedInUser();
  }

  getLoggedInUser(): void {
    this.authenticationService.getUser().pipe(takeUntil(this.stop$))
      .subscribe((user: any) => {
        this.user = user;
      });
  }

}
