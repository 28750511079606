/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FreeSubscriptionDto { 
    subscriptionCode: string;
    billingCycle: FreeSubscriptionDto.BillingCycleEnum;
    renewal?: boolean;
    upgrade?: boolean;
}
export namespace FreeSubscriptionDto {
    export type BillingCycleEnum = 'MONTHLY' | 'ANNUAL' | 'QUARTERLY' | 'SEMI_ANNUAL';
    export const BillingCycleEnum = {
        Monthly: 'MONTHLY' as BillingCycleEnum,
        Annual: 'ANNUAL' as BillingCycleEnum,
        Quarterly: 'QUARTERLY' as BillingCycleEnum,
        SemiAnnual: 'SEMI_ANNUAL' as BillingCycleEnum
    };
}


