import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UnderConstructionComponent} from "./under-construction/under-construction.component";
import {SubscriptionWarningComponent} from "./subscription/subscription-warning/subscription-warning.component";
import {FullPageLoaderComponent} from "./loaders/full-page-loader/full-page-loader.component";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {FieldErrorComponent} from "./field-error/field-error.component";
import {RemoveUnderscoresPipe} from "./remove-underscores.pipe";
import {DragDropFileUploadDirective} from "./drag-drop-upload.directive";
import {ReferralStatCardComponent} from './referral-stat-card/referral-stat-card.component';
import {AdultTeethComponent} from './adult-teeth/adult-teeth.component';
import {ChildTeethComponent} from './child-teeth/child-teeth.component';
import {ReferralListComponent} from './referral-list/referral-list.component';
import {DatetimePipe} from "./datetime.pipe";
import {RouterModule} from "@angular/router";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {NgbDatepickerModule, NgbModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {SpecialistOfficeModalComponent} from "./specialist-office-modal/specialist-office-modal.component";
import {CompletionModalComponent} from "./completion-modal/completion-modal.component";
import {DateAsAgoPipe} from "./date-ago.pipe";
import {OptionSelectionModalComponent} from "./option-selection-modal/option-selection-modal.component";
import {
  OwlDateTimeModule,
  OwlMomentDateTimeModule,
  OwlNativeDateTimeModule
} from "@danielmoncada/angular-datetime-picker";
import {PageLengthComponent} from "./page-length/page-length.component";
import {ReferralDraftListComponent} from "./referral-draft-list/referral-draft-list.component";
import {TooltipDirective} from "./directive/tool-tip-directive";
import {DateFormatPipe} from "./date-format.pipe";
import { NgPasswordStrengthMeterComponent } from './ng-password-strength-meter/ng-password-strength-meter.component';
import {PaymentSuccessDialogComponent} from "./payment-success-dialog/payment-success-dialog.component";
import {UpdateCreditCardComponent} from "./subscription/update-credit-card/update-credit-card.component";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loaders/loader/loader.component";
import {JoinPipe} from "./JoinPipe";
import {MaskInputDirective} from "./directive/mask-input.directive";
import { FormTemplateComponent } from './form-template/form-template.component';
import { ReferralStatusModalComponent } from './referral-status-modal/referral-status-modal.component';
import {CurrencyMaskModule} from "ng2-currency-mask";
import { CustomPricingComponent } from './custom-pricing/custom-pricing.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { NoteViewerComponent } from './note-viewer/note-viewer.component';
import {DashboardHelpComponent} from "./dashboard-help/dashboard-help.component";
import {NoRoundCurrencyPipe} from "./custom-pipes/no-round-currency.pipe";
import { MoreInfoModalComponent } from './more-info-modal/more-info-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
import {BlurFormatDirective} from "./directive/blur-format.directive";
import { AffiliateErrorInfoModalComponent } from './affiliate-error-info-modal/affiliate-error-info-modal.component';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { ChangePasswordComponent } from './change-password/change-password.component';
import { QuestionModalComponent } from './question-modal/question-modal.component';
import {RecreateViewDirective} from "./directive/recreate-view-directive";
import {BsConfirmDialogComponent} from "./bs-confirm-dialog/bs-confirm-dialog.component";
import {PipesModule} from "dd-shared-lib";
import { CachedSrcDirective } from './cached-src.directive';
import { ReferralPatientModalComponent } from './referral-patient-modal/referral-patient-modal.component';
@NgModule({
  declarations: [
    UnderConstructionComponent,
    SubscriptionWarningComponent,
    FullPageLoaderComponent,
    FieldErrorComponent,
    RemoveUnderscoresPipe,
    DateAsAgoPipe,
    DragDropFileUploadDirective,
    ReferralStatCardComponent,
    AdultTeethComponent,
    ChildTeethComponent,
    SpecialistOfficeModalComponent,
    OptionSelectionModalComponent,
    CompletionModalComponent,
    ReferralListComponent,
    DatetimePipe,
    JoinPipe,
    PageLengthComponent,
    ReferralDraftListComponent,
    TooltipDirective,
    DateFormatPipe,
    NgPasswordStrengthMeterComponent,
    PaymentSuccessDialogComponent,
    UpdateCreditCardComponent,
    LoaderComponent,
    FormTemplateComponent,
    MaskInputDirective,
    ReferralStatusModalComponent,
    CustomPricingComponent,
    NoteViewerComponent,
    DashboardHelpComponent,
    NoRoundCurrencyPipe,
    BlurFormatDirective,
    MoreInfoModalComponent,
    SpinnerComponent,
    AffiliateErrorInfoModalComponent,
    ChangePasswordComponent,
    QuestionModalComponent,
    RecreateViewDirective,
    BsConfirmDialogComponent,
    CachedSrcDirective,
    ReferralPatientModalComponent
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    PaginationModule,
    RouterModule, OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    OwlDateTimeModule,
    NgxMaskModule, NgbModule, CurrencyMaskModule, NgxIntlTelInputModule, TooltipModule, PipesModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    UnderConstructionComponent,
    PaginationModule,
    FullPageLoaderComponent,
    FieldErrorComponent,
    SubscriptionWarningComponent,
    RemoveUnderscoresPipe,
    DateAsAgoPipe,
    DragDropFileUploadDirective,
    ReferralStatCardComponent,
    AdultTeethComponent,
    ChildTeethComponent,
    SpecialistOfficeModalComponent,
    CompletionModalComponent,
    ReferralListComponent,
    DatetimePipe,
    ReferralDraftListComponent,
    NgPasswordStrengthMeterComponent,
    LoaderComponent,
    MaskInputDirective,
    BlurFormatDirective,
    PageLengthComponent, JoinPipe, DashboardHelpComponent,
    NoRoundCurrencyPipe, ChangePasswordComponent, RecreateViewDirective, BsConfirmDialogComponent, CachedSrcDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
//@ts-ignore
export class SharedModule {
}
