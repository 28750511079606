import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule, KeyValuePipe} from "@angular/common";
import {AdminRoutingModule} from "./admin-routing.module";
import {SharedModule} from "../../shared/shared.module";
import {AdminDashboardComponent} from "./admin-dashboard/admin-dashboard.component";
import {ChartsModule} from "ng2-charts";
import { AdminDashboardHeaderComponent } from './admin-dashboard-header/admin-dashboard-header.component';
import { AdminDashboardSidebarComponent } from './admin-dashboard-sidebar/admin-dashboard-sidebar.component';
import { AdminReferralsComponent } from './admin-referrals/admin-referrals.component';
import { AdminDentalOfficeComponent } from './admin-dental-office/admin-dental-office.component';
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import { ListSubscriptionRequestComponent } from './list-subscription-request/list-subscription-request.component';
import { HelpListComponent } from './help-list/help-list.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { TempDentalOfficeComponent } from './temp-dental-office/temp-dental-office.component';
import { TempDentalOfficeModalComponent } from './temp-dental-office-modal/temp-dental-office-modal.component';
import {NgBootstrapFormValidationModule} from "ng-bootstrap-form-validation";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";

@NgModule({
  declarations: [
    AdminDashboardComponent,
    AdminDashboardHeaderComponent,
    AdminDashboardSidebarComponent,
    AdminReferralsComponent,
    AdminDentalOfficeComponent,
    ListSubscriptionRequestComponent,
    HelpListComponent,
    ActivityLogComponent,
    TempDentalOfficeComponent,
    TempDentalOfficeModalComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        AdminRoutingModule,
        ChartsModule,
        NgbDatepickerModule,
        FormsModule,
        NgBootstrapFormValidationModule,
        NgxIntlTelInputModule,
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [KeyValuePipe],
  exports: [
    AdminDashboardComponent,
    AdminDashboardSidebarComponent,
    AdminDashboardHeaderComponent
  ]
})

export class AdminModule {}
