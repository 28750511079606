/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferralRequest } from './referral-request';
import { DentalProfessional } from './dental-professional';


export interface ReferralAssignment { 
    dateCreated?: string;
    updatedAt?: string;
    status?: ReferralAssignment.StatusEnum;
    id?: number;
    referralRequest?: ReferralRequest;
    dentalProfessional?: DentalProfessional;
}
export namespace ReferralAssignment {
    export type StatusEnum = 'ACCEPTED' | 'DECLINED' | 'PENDING';
    export const StatusEnum = {
        Accepted: 'ACCEPTED' as StatusEnum,
        Declined: 'DECLINED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum
    };
}


