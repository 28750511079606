<div class="container mt-4">
  <div class="row">
    <div class="d-flex align-items-center">
      <p class="fs-3 fw-bold my-3">
        <iconify-icon inline icon="iconoir:mail-out" class="fs-3 me-2"></iconify-icon>
        <i>d</i>Referrals
      </p>
    </div>

    <!--    Referral stats card begin-->
    <div class="">
      <div class="container-fluid px-0">
        <div class="row d-flex justify-content-between flex-wrap mt-4">
          <div class="col-xl-4 col-sm-6 my-3 my-xl-0">
            <div
              class="d-flex flex-column doffice-bg text-white rounded-4 shadow-lg px-4 p-3"
            >
              <h5>Completed Referrals</h5>
              <p>Total Number of completed referrals.</p>

              <h1 class="stat-card-numbers mb-0 mt-3">
                {{ this.data?.totNumOfCompletedReferral || 0 }}
              </h1>
              <div class="d-flex justify-content-between"></div>
            </div>
          </div>
          <div class="col-xl-4 col-sm-6 my-3 my-xl-0 ">
            <div class="d-flex flex-column px-3 overview-card refcard p-3">
              <h5>Scheduled referrals</h5>
              <p>Total Number of scheduled referrals</p>
              <h1 class="mt-4">
                {{ this.data?.totNumOfScheduledReferral || 0 }}
              </h1>

              <div class="d-flex justify-content-end"></div>
            </div>
          </div>
          <div class="col-xl-4 col-sm my-3 my-xl-0">
            <div class="d-flex flex-column px-3 overview-card refcard p-3">
              <h5>Cancelled Referrals</h5>
              <p>Totals Number of cancelled referrals</p>

              <h1 class=" mt-4">
                {{ this.data?.totNumOfCancelledReferral || 0 }}
              </h1>

              <div class="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    Referral stats card ends-->

    <div class="">
      <div class="my-3">
      </div>
      <app-referral-list></app-referral-list>
    </div>
  </div>
</div>
