import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  DentalOfficeInvitationDto,
  DentalOfficeProfessionalControllerService,
  DentalProfessionalControllerService,
  DentalProfessionalPojo, InvitationControllerService,
  InvitationPojo, InviteSearchControllerService, InviteSearchFilter,
  QueryResultsPojoInvitationPojo, ResourceVerificationControllerService
} from "dd-core-api-sdk";
import {SearchCountryField, CountryISO} from 'ngx-intl-tel-input';
import {Observable} from 'rxjs';
import {UserKeycloak} from "../../../services/UserKeycloak";
import {Router} from "@angular/router";
import {UniqueIdValidatorService} from "../../../shared/unique-id-validator.service";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {DoSetUpService} from "../../../services/DoSetUpService";
import {PhoneNumberValidator} from "dd-shared-lib";
import {getOffset, removeUndefinedOrNullFields} from "../../../models/search-model";
import {map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {DeliverableEmailValidator} from "../../../services/deliverable-email.validator";

@Component({
  selector: 'app-create-office-invites',
  templateUrl: './create-office-invites.component.html',
  styleUrls: ['./create-office-invites.component.css'],
  providers: [DeliverableEmailValidator]
})
export class CreateOfficeInvitesComponent implements OnInit, AfterViewInit {

  @Input() currentStep: number;
  @Input() totalSteps: number;
  @Input() subscriptionType: string;
  @ViewChild('officeNameText') officeNameText: any;
  form: FormGroup;
  working = false;
  dentalProfessional: DentalProfessionalPojo;
  dentalOffices: DentalOfficeInvitationDto[] = [];
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  invites$: Observable<QueryResultsPojoInvitationPojo>;
  inviteResult: QueryResultsPojoInvitationPojo;
  user: UserKeycloak;
  users: string[] = [];
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  @Output() complete: EventEmitter<any> = new EventEmitter<any>();
  @Output() invitationCount: EventEmitter<any> = new EventEmitter<any>();
  dentalOfficeInvitationPojo: InvitationPojo[] = [];
  addExistingDOInvites = (val, index, arr) => this.dentalOfficeInvitationPojo.push(val);
  errorSubmission: boolean;



  constructor(private fb: FormBuilder,
              private authService: AuthenticationServiceKeycloak,
              private deliverableEmailValidator: DeliverableEmailValidator,
              private invitationControllerService: InvitationControllerService,
              private inviteSearchService: InviteSearchControllerService) {
  }



  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', {
        Validators: [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$")],
        asyncValidators: [this.deliverableEmailValidator.emailExistsValidator()],
        updateOn: 'blur'
      }],
      officeName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      adminName: ['', [Validators.minLength(2), Validators.maxLength(50)]],
      addAdmin: ['', Validators.maxLength(50)],
      temporaryGoogleAddress: [''],
      fax: ['', [Validators.minLength(2), Validators.maxLength(50), Validators.pattern("^\\+?[0-9]{7,}$")]],
      phoneNumber: ['', [PhoneNumberValidator()]]
    });

    this.authService.getUser().subscribe(v => {
      this.user = v
    });

    this.loadInvitations();
  }

  ngAfterViewInit() {
    this.getPlacesAutocomplete();
  }

  getPlacesAutocomplete() {
    //@ts-ignore
    const autocomplete = new google.maps.places.Autocomplete(this.officeNameText.nativeElement, {
      componentRestrictions: {country: 'US'}
      // ,
      // types: ['hospital', 'dentist', 'doctor','drugstore', 'pharmacy']
    });
    //@ts-ignore
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();

      this.form.get('officeName').patchValue(place.name);
      this.form.get('temporaryGoogleAddress').patchValue(place.formatted_address);

      this.form.get('phoneNumber').patchValue(place.international_phone_number);

    });
  }


  getErrorMessage() {
    return this.errMessage;
  }

  addNewDentalOffice() {
    if (this.subscriptionType && this.subscriptionType === "Pro" && this.inviteResult.total === 5)
      this.showErrorMessage("You have reached the limit allowed by your subscription.")

    if (this.form?.getRawValue()?.email?.trim().toLowerCase() == this.user?.email?.toLowerCase()) {
      this.isError = true;
      this.showErrorMessage("Invite cannot be sent to the same logged in account.");
      return;
    }
    this.form.controls.officeName.enable();
    this.form.controls.email.enable();
    this.form.controls.phoneNumber.enable();
    this.form.controls.adminName.enable();
    this.form.controls.temporaryGoogleAddress.enable();


    const dto: DentalOfficeInvitationDto = {
      officeName: `${this.form.value.officeName}`,
      email: this.form.value.email,
      phoneNumber: this.form?.value?.phoneNumber?.e164Number,
      adminName: this.form?.value?.adminName,
      temporaryGoogleAddress: this.form?.value?.temporaryGoogleAddress,
      fax: this.form?.value?.fax
    };
    this.dentalOffices.push(dto);

    this.inviteDentalOffices();
  }

  isExistingUser(email): boolean {
    if (this.users == null || this.users.length < 1) {
      return false;
    }
    return this.users?.filter(f => f === email)[0] === email;
  }

  submittedForError() {
    this.errorSubmission = true;
  }

  inviteDentalOffices() {
    const list = this.dentalOffices.map(it => {
      const dto: DentalOfficeInvitationDto = {} as DentalOfficeInvitationDto;
      dto.officeName = it.officeName;
      dto.email = it.email;
      dto.dentalOfficeId = AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id;
      dto.phoneNumber = it.phoneNumber;
      dto.adminName = it.adminName;
      dto.temporaryGoogleAddress = it.temporaryGoogleAddress;
      dto.fax = it.fax;
      return dto;
    })
    this.working = true;
    this.invitationControllerService.sendDentalOfficeInvitation(
      {
        listDtoDentalOfficeInvitationDtoMessageDeliveryChannelConstant:
          {list: list}
      })
      .subscribe(() => {
        this.isError = false;
        this.working = false;
        this.showErrorMessage("Dental Office has been Invited Successfully.");
        this.loadInvitations();
        this.form.reset();
        this.dentalOffices = [];
      }, (e: HttpErrorResponse) => {
        this.form.reset();
        this.isError = true;
        this.dentalOffices = [];
        this.showErrorMessage(e?.error?.message ? e?.error?.message : 'Error! Please Check Again.');
        this.working = false;
      });
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  loadInvitations() {
    const searchFilter: InviteSearchFilter = {
      dentalOffice: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
      invitationStatus: 'PENDING',
      typeConstant: InviteSearchFilter.TypeConstantEnum.DentalOffice,
      offset: getOffset(0, 5),
      limit: 5,
    };

    this.searchInvitations(searchFilter);
    this.invites$.subscribe(value => {
      this.dentalOfficeInvitationPojo = [];
      value.results.forEach(this.addExistingDOInvites);
      // this.doSetUpService.dentalOfficeInvitationPojo$.next(this.dentalOfficeInvitationPojo);
    });
  }

  searchInvitations(searchFilter: InviteSearchFilter) {
    this.invites$ = this.inviteSearchService.searchOfficeInvitations({filter: removeUndefinedOrNullFields(searchFilter)}).pipe(
      map((it: QueryResultsPojoInvitationPojo) => this.inviteResult = it)
    );
    this.invitationCount.emit(this.inviteResult?.total);
  }

  onPageChange($event) {
    const searchFilter: InviteSearchFilter = {
      dentalOffice: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
      typeConstant: InviteSearchFilter.TypeConstantEnum.DentalOffice,
      offset: getOffset($event.page, $event.size || 4),
      limit: $event.itemsPerPage,
    };

    this.searchInvitations(searchFilter);
  }

}
