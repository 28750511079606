import {Pipe, PipeTransform} from '@angular/core';
import {AddressPojo} from 'dd-core-api-sdk';

@Pipe({
  name: 'fullAddress'
})
export class FullAddressPipe implements PipeTransform {

  transform(value: AddressPojo, ...args: unknown[]): unknown {
    if (value === undefined) {
      return '';
    }

    if (value.houseNumber && value.streetAddress) {
      return `${value.houseNumber} ${value.streetAddress} ${value.city.name}, ${value.state.name} ${value.zipCode}`;
    }

    if (value.streetAddress) {
      return `${value.streetAddress} ${value.city.name}, ${value.state.name} ${value.zipCode}`;
    }

    return `${value.city.name}, ${value.state.name} ${value.zipCode}`;
  }

}
