<div class="container mt-4">
  <div class="row">
    <div class="d-flex align-items-center">
      <p class="fs-3 fw-bold my-3">
        <iconify-icon inline  icon="mdi:call-to-action"
                      class="fs-3"></iconify-icon>
        Activity Logs
      </p>
    </div>

    <div class="">
      <div class="container-fluid">
        <div class="row">
          <div class="overview-card p-3">
            <div class="filter-card-bg p-md-4 py-3">

              <div *ngIf="showErrorMessageTrigger">
                <div class="alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
                     [ngClass]="{'alert-danger': isError, 'alert-success': !isError}"
                     role="alert">
                  <p class="m-0 p-0 d-flex align-items-start">
                            <span>
                                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                              {{ getErrorMessage() | removeUnderscores }}
                            </span>
                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                  </p>
                </div>
              </div>

              <div class="container-fluid">
                <form [formGroup]="nameForm" class="row g-3">

                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <input type="text" class="form-control" id="ptn" placeholder="e.g st.Marys"
                           formControlName="userName">
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Actor</label>
                  </div>

                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <select id="text" class="form-select" formControlName="activityType">
                      <option [selected]="true" [value]="''">Select Activity type...</option>
                      <option *ngFor="let ac of activityTypeEnum" [value]="ac">{{ac | uppercase }}</option>
                    </select>
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.1rem;">Activity Type</label>
                  </div>

                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <input type="text" class="form-control" placeholder="+1 (754) 343 094"
                           aria-label="Phone Number" formControlName="ipAddress">
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">IP Address</label>
                  </div>

                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <select id="activityStatus" class="form-select" formControlName="activityStatus">
                      <option [selected]="true" [value]="''">Select Activity Status...</option>
                      <option *ngFor="let ac of activityStatusEnum" [value]="ac">{{ac | uppercase | removeUnderscores }}</option>
                    </select>
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.1rem;">Activity Status</label>
                  </div>




                  <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0 " style="margin-top: -.6rem;">
                    <div class="">
                      <label class="form-label rfr-label"> Date (from) </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="MM-DD-YYYY" formControlName="startDate"
                               [minDate]="{ year: 1910, month: 1, day: 1 }"
                               [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                               ngbDatepicker #startDate="ngbDatepicker"
                               (click)="startDate?.toggle()"/>
                        <span (click)="startDate?.toggle()" class="btn-primary-outline input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                      </div>
                    </div>
                  </div>


                  <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0" style=" margin-top: -.6rem;">
                    <div class="">
                      <label class="form-label rfr-label"> Date (to) </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="MM-DD-YYYY" formControlName="endDate"
                               [minDate]="{ year: 1910, month: 1, day: 1 }"
                               [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                               ngbDatepicker #endDate="ngbDatepicker"
                               (click)="endDate?.toggle()"/>
                        <span (click)="endDate?.toggle()" class="btn-primary-outline input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0  float-end" style="white-space: nowrap;">
                    <button
                      class="bg-primary border-0 d-flex justify-content-center align-items-center px-3 text-light float-end"
                      (click)="searchPage()" style=" margin-top: 3px; padding: .35rem;">
                      <iconify-icon inline icon="iconoir:search" class="me-2 fs-5" width="25"
                                    height="25"></iconify-icon>
                      Apply Filter
                    </button>
                  </div>



                </form>
              </div>

            </div>
          </div>
        </div>
      </div>




      <div class="container-fluid">
        <div class="row mt-4">
          <div class="overview-card-table p-3">

            <div class="d-flex align-items-center justify-content-between mt-4 mb-3">
              <div class="fs-16 fw-medium  text-muted">
                Total records : {{this.resultSubject?.value?.total || 0}}
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-hover table-bordered">
                <thead class="fs-12">
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col">Actor</th>
                  <th scope="col">Activity</th>
                  <th scope="col">Status</th>
                  <th scope="col">IP Address</th>
                  <th scope="col">Description</th>
                  <th scope="col">Timestamp</th>
                </tr>
                </thead>
                <tbody class="fs-12">
                <tr  *ngFor="let activity of resultSubject?.value?.results ; let i = index">
                  <td>{{i+1}}</td>
                  <td>{{activity?.actor | titlecase}}</td>
                  <td>{{activity?.action | removeUnderscores}}</td>
                  <td>{{activity?.activityStatus | removeUnderscores}}</td>
                  <td>{{activity?.remoteAddress}}</td>
                  <td>{{activity?.description}}</td>
                  <td>{{activity?.dateCreated | date :'d MMM, y, h:mm a'}}</td>

                </tr>

                </tbody>
              </table>
              <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3"
                   style="margin-bottom: -1rem; color: #4e3367">
                <app-page-length [pageLength]="getFilter().limit || 10" (pageLengthChanged)="changeLimit($event)">
                </app-page-length>
                <div class="add-group-btn text-right mt-3 mb-3">
                  <pagination (pageChanged)="onPageChange($event)" [boundaryLinks]="true" [itemsPerPage]="limit || 10"
                              [maxSize]="5" [totalItems]="this.resultSubject?.value?.total || 0"
                              firstText="&laquo;" lastText="&raquo;" nextText="&rsaquo;"
                              previousText="&lsaquo;"></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</div>


