/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalOfficeSubscription } from './dental-office-subscription';
import { SubscriptionCycle } from './subscription-cycle';
import { PackageItemPojo } from './package-item-pojo';


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    subscriptionCycle?: SubscriptionCycle;
    dentalOfficeSubscription?: DentalOfficeSubscription;
    packageItems?: Array<PackageItemPojo>;
    otherData?: object;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'DENTAL_DOOR_ADMIN' | 'DENTAL_OFFICE' | 'DENTAL_PROFESSIONAL' | 'DENTAL_SPECIALIST' | 'DENTAL_SPECIALIST_OFFICE' | 'SUPER_ADMIN' | 'AFFILIATE';
    export const AccountTypeEnum = {
        DentalDoorAdmin: 'DENTAL_DOOR_ADMIN' as AccountTypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as AccountTypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as AccountTypeEnum,
        DentalSpecialist: 'DENTAL_SPECIALIST' as AccountTypeEnum,
        DentalSpecialistOffice: 'DENTAL_SPECIALIST_OFFICE' as AccountTypeEnum,
        SuperAdmin: 'SUPER_ADMIN' as AccountTypeEnum,
        Affiliate: 'AFFILIATE' as AccountTypeEnum
    };
    export type PermissionsEnum = 'VERIFY_DENTAL_OFFICE' | 'DEACTIVATE_USER' | 'UPDATE_ACCOUNT_MEMBER_ROLE' | 'REMOVE_ACCOUNT_MEMBER_ROLE' | 'ADD_ACCOUNT_MEMBER' | 'PROCESS_MANUAL_ACTIVATION' | 'CONFIRM_APPOINTMENT' | 'PROCESS_REFERRAL_STATUS' | 'SEND_REFERRAL' | 'CAN_SEND_AFFILIATE_CODE';
    export const PermissionsEnum = {
        VerifyDentalOffice: 'VERIFY_DENTAL_OFFICE' as PermissionsEnum,
        DeactivateUser: 'DEACTIVATE_USER' as PermissionsEnum,
        UpdateAccountMemberRole: 'UPDATE_ACCOUNT_MEMBER_ROLE' as PermissionsEnum,
        RemoveAccountMemberRole: 'REMOVE_ACCOUNT_MEMBER_ROLE' as PermissionsEnum,
        AddAccountMember: 'ADD_ACCOUNT_MEMBER' as PermissionsEnum,
        ProcessManualActivation: 'PROCESS_MANUAL_ACTIVATION' as PermissionsEnum,
        ConfirmAppointment: 'CONFIRM_APPOINTMENT' as PermissionsEnum,
        ProcessReferralStatus: 'PROCESS_REFERRAL_STATUS' as PermissionsEnum,
        SendReferral: 'SEND_REFERRAL' as PermissionsEnum,
        CanSendAffiliateCode: 'CAN_SEND_AFFILIATE_CODE' as PermissionsEnum
    };
}


