/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MailgunEmailValidationResponse { 
    address?: string;
    is_disposable_address?: boolean;
    is_role_address?: boolean;
    reason?: Array<string>;
    result?: MailgunEmailValidationResponse.ResultEnum;
    risk?: string;
}
export namespace MailgunEmailValidationResponse {
    export type ResultEnum = 'undeliverable' | 'deliverable' | 'catch_all';
    export const ResultEnum = {
        Undeliverable: 'undeliverable' as ResultEnum,
        Deliverable: 'deliverable' as ResultEnum,
        CatchAll: 'catch_all' as ResultEnum
    };
}


