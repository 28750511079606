/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FavoriteDto { 
    entityId: number;
    entityType: FavoriteDto.EntityTypeEnum;
    status: FavoriteDto.StatusEnum;
    dentalOfficeId?: number;
}
export namespace FavoriteDto {
    export type EntityTypeEnum = 'DentalOffice' | 'DentalProfessional' | 'Promotion' | 'Review' | 'FlashCardSet' | 'Comment';
    export const EntityTypeEnum = {
        DentalOffice: 'DentalOffice' as EntityTypeEnum,
        DentalProfessional: 'DentalProfessional' as EntityTypeEnum,
        Promotion: 'Promotion' as EntityTypeEnum,
        Review: 'Review' as EntityTypeEnum,
        FlashCardSet: 'FlashCardSet' as EntityTypeEnum,
        Comment: 'Comment' as EntityTypeEnum
    };
    export type StatusEnum = 'LIKE' | 'UNLIKE' | 'DISLIKE';
    export const StatusEnum = {
        Like: 'LIKE' as StatusEnum,
        Unlike: 'UNLIKE' as StatusEnum,
        Dislike: 'DISLIKE' as StatusEnum
    };
}


