/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReasonForDisapproval { 
    name?: string;
    code?: string;
    status?: ReasonForDisapproval.StatusEnum;
    type?: ReasonForDisapproval.TypeEnum;
    id?: number;
}
export namespace ReasonForDisapproval {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type TypeEnum = 'DENTAL_OFFICE_REGISTRATION' | 'DENTAL_PROFESSIONAL_REGISTRATION' | 'APPOINTMENT_FEEDBACK';
    export const TypeEnum = {
        DentalOfficeRegistration: 'DENTAL_OFFICE_REGISTRATION' as TypeEnum,
        DentalProfessionalRegistration: 'DENTAL_PROFESSIONAL_REGISTRATION' as TypeEnum,
        AppointmentFeedback: 'APPOINTMENT_FEEDBACK' as TypeEnum
    };
}


