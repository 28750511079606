/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentTransaction { 
    paymentProviderReference?: string;
    bwPaymentReference?: string;
    amount?: number;
    dateCreated?: string;
    paymentDate?: string;
    payerName?: string;
    description?: string;
    transactionType?: PaymentTransaction.TransactionTypeEnum;
    paymentReference?: string;
    payerEmail?: string;
    payerPhone?: string;
    paymentProvider?: PaymentTransaction.PaymentProviderEnum;
    notificationSource?: PaymentTransaction.NotificationSourceEnum;
    transactionStatus?: PaymentTransaction.TransactionStatusEnum;
    notificationId?: string;
    externalReceiptNumber?: string;
    paymentMethod?: PaymentTransaction.PaymentMethodEnum;
    id?: number;
}
export namespace PaymentTransaction {
    export type TransactionTypeEnum = 'PAYMENT' | 'REVERSAL' | 'PAYOUT' | 'TRANSFER';
    export const TransactionTypeEnum = {
        Payment: 'PAYMENT' as TransactionTypeEnum,
        Reversal: 'REVERSAL' as TransactionTypeEnum,
        Payout: 'PAYOUT' as TransactionTypeEnum,
        Transfer: 'TRANSFER' as TransactionTypeEnum
    };
    export type PaymentProviderEnum = 'STRIPE' | 'PAYSTACK';
    export const PaymentProviderEnum = {
        Stripe: 'STRIPE' as PaymentProviderEnum,
        Paystack: 'PAYSTACK' as PaymentProviderEnum
    };
    export type NotificationSourceEnum = 'BW_PAYMENT' | 'PAYMENT_PROVIDER';
    export const NotificationSourceEnum = {
        BwPayment: 'BW_PAYMENT' as NotificationSourceEnum,
        PaymentProvider: 'PAYMENT_PROVIDER' as NotificationSourceEnum
    };
    export type TransactionStatusEnum = 'SUCCEEDED' | 'REVERSED' | 'FAILED' | 'PROCESSING';
    export const TransactionStatusEnum = {
        Succeeded: 'SUCCEEDED' as TransactionStatusEnum,
        Reversed: 'REVERSED' as TransactionStatusEnum,
        Failed: 'FAILED' as TransactionStatusEnum,
        Processing: 'PROCESSING' as TransactionStatusEnum
    };
    export type PaymentMethodEnum = 'CARD' | 'ACCOUNT_TRANSFER';
    export const PaymentMethodEnum = {
        Card: 'CARD' as PaymentMethodEnum,
        AccountTransfer: 'ACCOUNT_TRANSFER' as PaymentMethodEnum
    };
}


