import {UserAccountKeycloak} from "./UserAccountKeycloak";
import {RolePojo} from "../shared/account/role.pojo.model";
import {PermissionType} from "../shared/account/permission-type.enum";
import {AccountKeycloak} from "./AccountKeycloak";
import {DentalProfessional} from "./DentalProfessional";
import {UserPojo} from "dd-core-api-sdk";
import TourRideStatusConstantEnum = UserPojo.TourRideStatusConstantEnum;


export class UserKeycloak {

  public accounts!: UserAccountKeycloak[];
  public email!: string;
  public firstName!: string;
  public invitesSent!: number;
  public displayPictureId: number;
  // public address!: AddressPojo;
  public lastName!: string;
  public phone!: string;
  public mobileNumber!: string;
  public dentalProfessional!: DentalProfessional;
  public requiresPasswordUpdate!: boolean;
  public userId!: string;
  public status!: string;
  public id!: number;
  public username!: string;
  public accountPojo!: AccountKeycloak;
  public fullAddress!: string;
  public nin!: string;
  public isAgentOnboarded: boolean;
  public emailVerified: boolean;
  public setupComplete: boolean;
  public affiliateCode!: string;
  public tourRideStatusConstant!: TourRideStatusConstantEnum;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.accounts) {
      this.accounts = data.accounts;
    }
    if (data.accountPojo) {
      this.accountPojo = new AccountKeycloak(data.accountPojo);
    }
    // if (data.address) {
    //   this.address = data.address;
    //   this.fullAddress = this.getFullAddress(this.address);
    // }
  }

  // getFullAddress(address: AddressPojo): string {
  //   return [address.houseNumber, address.streetAddress, address.locality?.name, address.area?.name, address.lga?.name, address.country?.name]
  //     .filter(value => !!value)
  //     .map((value) => value?.[0].toUpperCase() + (value ? value.slice(1).toLowerCase() : ''))
  //     .join(', ');
  // }

  public permissions(): string[] {
    if (!this.accountPojo) {
      return [];
    }
    const roles: RolePojo[] = this.accountPojo.roles;
    let permits: PermissionType[] = [];
    roles.forEach(r => {
      let p: PermissionType[];
      p = r.permissions;
      permits = permits.concat(...p);
    });
    return permits.filter((v, i) => permits.indexOf(v) === i);
  }

  public hasPermission(permissionName: string | PermissionType) {
    // return (this.permissions() || []).filter((it: string) => it === permissionName).length;
    const accounts: UserAccountKeycloak[] = this.accounts.filter((acct: any) => {
      return acct.permissions.filter((it: string) => it === permissionName).length;
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }

  public getAccountsWithPermission(permission: string | PermissionType): UserAccountKeycloak[] {
    return this.accounts.filter((it: UserAccountKeycloak) => it.hasPermission(permission));
  }

  /*public getAccountCodes(): string[] {
      return this.accounts.map((it: UserAccount) => it.accountCode);
  }*/

  // public getRole(roles: RolePojo[]): RolePojo | undefined {
  //   return roles.find(r => {
  //     let role: RolePojo | null = null;
  //
  //     switch (r.name) {
  //       case PortalAccountRole.ADMIN:
  //         role = r;
  //         break;
  //
  //       case PortalAccountRole.MANAGEMENT:
  //         role = r;
  //         break;
  //
  //       case PortalAccountRole.FINANCE_OFFICER:
  //         role = r;
  //         break;
  //
  //       case PortalAccountRole.ASA_ADMIN:
  //         role = r;
  //         break;
  //
  //       case PortalAccountRole.ASA_APPROVER:
  //         role = r;
  //         break;
  //
  //       case PortalAccountRole.SUPPORT:
  //         role = r;
  //         break;
  //     }
  //
  //     return role;
  //   });
  // }


  public hasRole(roleName: string): boolean {
    const accounts: UserAccountKeycloak[] = this.accounts.filter((acct: any) => {
      return acct.roles.filter((it: string) => it === roleName).length;
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }
  public isPortalAccountType(type: string): boolean {
    const accounts: UserAccountKeycloak[] = this.accounts.filter((acct: any) => {
      return acct.accountType.toUpperCase() === type.toUpperCase();
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }
}
