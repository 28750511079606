/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalPlanPojo } from './dental-plan-pojo';
import { DealPojo } from './deal-pojo';
import { PromotionPojo } from './promotion-pojo';
import { CouponPojo } from './coupon-pojo';
import { PortalUser } from './portal-user';


export interface PromotionRedemptionPojo { 
    note?: string;
    status?: PromotionRedemptionPojo.StatusEnum;
    dateCreated?: string;
    validUntilDate?: string;
    redemptionStatus?: PromotionRedemptionPojo.RedemptionStatusEnum;
    redemptionCode?: string;
    id?: number;
    qrCodeImageId?: number;
    promotion?: PromotionPojo;
    claimedBy?: PortalUser;
    approvedBy?: PortalUser;
    usedOn?: string;
    authToken?: string;
    expired?: boolean;
    used?: boolean;
    amount?: number;
    dentalPlan?: DentalPlanPojo;
    deal?: DealPojo;
    coupon?: CouponPojo;
    claimerDisplayPictureId?: number;
}
export namespace PromotionRedemptionPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type RedemptionStatusEnum = 'CLAIMED' | 'USED' | 'APPLIED';
    export const RedemptionStatusEnum = {
        Claimed: 'CLAIMED' as RedemptionStatusEnum,
        Used: 'USED' as RedemptionStatusEnum,
        Applied: 'APPLIED' as RedemptionStatusEnum
    };
}


