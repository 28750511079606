/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PatientModeOfPaymentDto { 
    type: PatientModeOfPaymentDto.TypeEnum;
    insuranceCompanyId?: number;
}
export namespace PatientModeOfPaymentDto {
    export type TypeEnum = 'SELF' | 'INSURANCE';
    export const TypeEnum = {
        Self: 'SELF' as TypeEnum,
        Insurance: 'INSURANCE' as TypeEnum
    };
}


