<div class="modal-content p-md-3 rounded-0 border-0">
    <div class="modal-body">
        <div class="d-flex justify-content-center align-items-center flex-column">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="currentColor" d="M4 2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6.1l-3.7 3.71c-.2.19-.45.29-.7.29H9a1 1 0 0 1-1-1v-3H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m8.19 3.5c-.89 0-1.6.18-2.14.54c-.55.36-.83.96-.78 1.65h1.97c0-.28.1-.49.26-.63c.2-.14.42-.21.69-.21c.31 0 .58.08.76.26c.18.17.27.39.27.69c0 .28-.08.53-.22.74c-.17.22-.38.4-.64.54c-.52.32-.86.6-1.07.84c-.19.24-.29.58-.29 1.08h2c0-.28.05-.5.14-.68c.09-.17.26-.32.52-.47c.46-.21.84-.49 1.13-.85c.29-.37.44-.76.44-1.2c0-.7-.27-1.26-.81-1.68c-.54-.41-1.29-.62-2.23-.62M11 12v2h2v-2h-2Z"/></svg>
            <p class="my-3 text-center">{{message}}</p>
            <form class="col-12 mb-3 mt-2 w-100" [formGroup]="form">
                <textarea formControlName="questionResponse" class="form-control form-control-lg" rows="2" maxlength="500" placeholder="Type here"></textarea>
            </form>
        </div>
        <br>
        <div class="d-grid gap-2 d-sm-block text-center">
            <button type="button" class=" mx-sm-2 btn btn-outline-secondary" (click)="dismissClicked.emit()">
        Dismiss
      </button>
            <button type="button" class=" mx-sm-2 btn btn-primary" (click)="emitQuestions()">
        <iconify-icon *ngIf="sending " icon="eos-icons:bubble-loading " style="color: #ffffff"></iconify-icon>
        Continue
      </button>
        </div>
    </div>
</div>