/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressDto } from './address-dto';


export interface DentalProfessionalWorkAddressDto { 
    type: DentalProfessionalWorkAddressDto.TypeEnum;
    address: AddressDto;
    dentalProfessionalId?: number;
    dentalOfficeId?: number;
    schoolName?: string;
    stateCode?: string;
}
export namespace DentalProfessionalWorkAddressDto {
    export type TypeEnum = 'HOME' | 'SCHOOL' | 'OFFICE';
    export const TypeEnum = {
        Home: 'HOME' as TypeEnum,
        School: 'SCHOOL' as TypeEnum,
        Office: 'OFFICE' as TypeEnum
    };
}


