<div class="container">
    <section class="search-input mt-5">
        <div class="overview-card p-3" joyrideStep="four" title="Table Filter" text="Click here to filter affiliates by name, subscription status, commission, date and due commission." stepPosition="left">
            <div *ngIf="showErrorMessageTrigger">
                <div class="alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert" [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                    <p class="m-0 p-0 d-flex align-items-start">
                        <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                          {{ getErrorMessage() | removeUnderscores }}
              </span>
                        <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                        </iconify-icon>
                    </p>
                </div>
            </div>
            <div class="container-fluid mt-3">
                <form [formGroup]="this.form" class="row g-3">
                    <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                        <input type="text" class="form-control" id="ptn" placeholder="e.g John Doe" formControlName="walletTransactionCode">
                        <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Wallet Transaction Code</label>
                    </div>
                    <div class="col-xl-3 col-md-4 col-sm-6 mb-0 mb-md-0">
                        <select class="form-select" style="line-height: 1.8" id="fs" aria-label="floating-label select" formControlName="walletTransactionStatus">
              <option [selected]="true" [value]="''" style="font-size: 16px !important"> Transaction Status</option>
              <option *ngFor="let item of this.walletTransactionStatusEnum" [value]="item">
                {{item | uppercase | removeUnderscores}}
              </option>
            </select>
                    </div>
                    <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0 " style="margin-top: -.6rem;">
                        <div class="">
                            <label class="form-label rfr-label"> Date (from) </label>
                            <div class="input-group">
                                <input class="form-control" formControlName="startDate" placeholder="MM-DD-YYYY" ngbDatepicker #startDate="ngbDatepicker" [minDate]="{ year: 1910, month: 1, day: 1 }" [(ngModel)]="model" [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                                    (click)="startDate.toggle()" />
                                <span (click)="startDate.toggle()" class="btn-primary-outline input-group-text">
                               <i class="fa fa-calendar"></i>
                  </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0" style="margin-top: -.6rem;">
                        <div class="">
                            <label class="form-label rfr-label"> Date (to) </label>
                            <div class="input-group">
                                <input class="form-control" placeholder="MM-DD-YYYY" formControlName="endDate" [minDate]="{ year: 1910, month: 1, day: 1 }" [(ngModel)]="model1" [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }" ngbDatepicker #endDate="ngbDatepicker"
                                    (click)="endDate.toggle()" />
                                <span (click)="endDate.toggle()" class="btn-primary-outline input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0" style="white-space: nowrap;">
                        <button class="bg-primary rounded-1 border-0 d-flex justify-content-center align-items-center px-3 py-2 text-light" style=" margin-top: 3px; padding: .35rem;" (click)="onSearchClick()">
              <iconify-icon inline icon="eos-icons:bubble-loading" class="me-2 fs-5" style="color: white;"
                            *ngIf="searching" width="25" height="25"></iconify-icon>
              <iconify-icon inline icon="iconoir:search" class="me-2 fs-5" width="25" height="25"
                            *ngIf="!searching"></iconify-icon>
              Apply Filter
            </button>
                    </div>
                </form>
            </div>
        </div>
    </section>

    <section class="">
        <!--    <div class="row justify-content-end mt-5 mb-2">-->
        <!--      <div class="col-md-auto col-sm-12">-->
        <!--        <button class="export-csv border-0 d-flex justify-content-center align-items-center px-3 text-light float-end"-->
        <!--                style="  margin-top: 3px; padding: .35rem;" joyrideStep="six"-->
        <!--                title="Export Data" text="Click here to download single or multiple data."-->
        <!--                stepPosition="left">-->
        <!--          <iconify-icon icon="bi:filetype-pdf" style="color: white;" class="me-2 fs-5" width="25"-->
        <!--                        height="25"></iconify-icon>-->
        <!--          Export as PDF-->
        <!--        </button>-->
        <!--      </div>-->
        <!--    </div>-->
        <div class="overview-card-table" id="table">
            <div class="p-3">
                <div class="table-responsive" [formGroup]="form">
                    <table class="table table-hover table-responsive" joyrideStep="three" title="Affiliate Table" text="View detailed affiliate information by name, subscription status, date and  due commissions." stepPosition="left">
                        <thead>
                            <tr>
                                <!--              <th scope="col">-->
                                <!--                <div class="form-check">-->
                                <!--                  <input class="form-check-input" type="checkbox">-->
                                <!--                </div>-->
                                <!--              </th>-->
                                <th scope="col">S/N</th>
                                <th scope="col">Date</th>
                                <th scope="col">Account Name</th>
                                <th scope="col">Account Number</th>
                                <th scope="col">Amount Paid</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Transaction Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let aff of this.resultSubject.value.results; let i = index;" [id]="'action' + i">
                                <!--              <td data-label="Select" class=""><input class="form-check-input" type="checkbox"></td>-->
                                <td data-label="S/N"> {{ offset + i + 1 }}</td>
                                <td data-label="Date" class=" text-muted">{{aff?.dateCreated ? (formatUTCMoment(aff?.dateCreated) | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes) : 'N/A'}}</td>
                                <td data-label="Account Name" class="text-muted position-relative">{{aff?.bankAccountName || 'N/A' | titlecase}}</td>
                                <td data-label="Account Number" class="text-muted ">{{aff?.maskedBankAccountNumber || 'N/A'}}</td>
                                <td data-label="Amount Paid" class="text-muted ">{{aff?.amountPaid || 'N/A' | noRoundCurrency}}</td>
                                <td data-label="Payment Status" class="text-muted position-relative">{{aff?.walletTransactionStatus | removeUnderscores}} <span class=" ms-1 border-0 rounded-5 info text-light float-right pointer" title="More info " (click)="showMoreInfo(aff)">i</span></td>
                                <td data-label="Transaction Code " class="text-muted ">{{aff?.transactionCode}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div><span *ngIf="!this.resultSubject.value.results || this.resultSubject.value.total==0 " class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder ">
      {{this.resultSubject.value.results ? "NO DATA/RECORDS " : "APPLY FILTERS TO VIEW DETAILS " }}
      </span>
                </div>
                <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3 " style="margin-bottom: -1rem; color: #4e3367 ">
                    <app-page-length [pageLength]="getFilter().limit || 10 " (pageLengthChanged)="changeLimit($event) "></app-page-length>
                    <div class="add-group-btn text-right mt-3 mb-3 ">
                        <pagination (pageChanged)="onPageChange($event) " [boundaryLinks]="true " [itemsPerPage]="limit || 10 " [(ngModel)]="page " [maxSize]="5 " [totalItems]="this.resultSubject.value.total || 0 " firstText="&laquo; " lastText="&raquo;
                                    " nextText="&rsaquo; " previousText="&lsaquo; "></pagination>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
