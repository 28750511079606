import {RouterModule, Routes} from "@angular/router";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {OutboundListComponent} from "./outbound-list/outbound-list.component";
import {CreateReferralWizardComponent} from "./create-referral-wizard/create-referral-wizard.component";
import {ViewReferralComponent} from "../../../components/view-referral/view-referral.component";
import {ReferralDraftGuardGuard} from "./referral-draft-guard/referral-draft-guard.guard";
import {RoutePermissionRestriction} from "../../../shared/logged-in-guard/logged-in.guard";
import {AccountMembershipPojo, SocialMedialSignUpDto} from "dd-core-api-sdk";
import AccountTypeEnum = SocialMedialSignUpDto.AccountTypeEnum;
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;
import {PreviewReferralComponent} from "./preview-referral/preview-referral.component";

const routes: Routes = [
  {
    path: '',
    component: OutboundListComponent,
    pathMatch: 'full',
  },
  {
    path: 'drafts',
    component: OutboundListComponent,
    pathMatch: 'full',
    data: {isDraft: true}
  },
  {path:'preview-referral',component: PreviewReferralComponent,pathMatch:'full'},
  {
    path: 'create-referral',
    component: CreateReferralWizardComponent,
    pathMatch: 'full',
    data:{
      permissions: [
        {
          accountType: AccountTypeEnum.DentalOffice,
          permissions: [PermissionsEnum.SendReferral]
        },
      ] as RoutePermissionRestriction[]
    },
    canDeactivate:[ReferralDraftGuardGuard]
  },
  {
    path: 'referrals/:id',
    component: ViewReferralComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OutboundRoutingModule {
}
