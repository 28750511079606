import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bs-confirm-dialog',
  templateUrl: './bs-confirm-dialog.component.html',
  styleUrls: ['./bs-confirm-dialog.component.css']
})
export class BsConfirmDialogComponent implements OnInit {
  @Input() header = 'Confirm your Action';
  @Input() body = 'Are you sure you want to proceed?';
  @Input() confirmButtonText = 'Yes, Proceed';
  @Input() cancelButtonText = 'No, Cancel';
  @Input() dismissOnConfirm = true;
  @Input() showLoadingOnConfirmClick = true;
  @Input() showCloseButton = true;
  @Input() showConfirmButton = true;

  @Output() confirmClicked: EventEmitter<Boolean> = new EventEmitter();

  loading = false;
  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  close() {
    this.modalRef.hide();
  }

  onConfirm: any = () => {
  }

  confirm(event: boolean) {
    if (this.showLoadingOnConfirmClick && event) {
      this.loading = true;
    }
    this.onConfirm();
    this.confirmClicked.emit(event);
    if (this.dismissOnConfirm) {
      this.modalRef.hide();
    }
  }

}
