<main>
  <aw-wizard [navBarLayout]="null" [defaultStepIndex]="0">
    <aw-wizard-step>
      <app-first-contact-i (emitFirstStepValues)="firstStepValues($event)" awOptionalStep></app-first-contact-i>
    </aw-wizard-step>
    <aw-wizard-step>
      <app-first-contact-ii (emitSecondStepValues)="secondStepValues($event)" awOptionalStep></app-first-contact-ii>
    </aw-wizard-step>
    <aw-wizard-step>
      <app-first-contact-iii [selectedTimeSlot]="selectedTimeSlot" [selectedTimeline]="selectedTimeline"
                             [tokenAndCode]="{token: token, code: code}" awOptionalStep></app-first-contact-iii>
    </aw-wizard-step>
    <aw-wizard-step>
      <app-last-contact awOptionalStep></app-last-contact>
    </aw-wizard-step>
  </aw-wizard>
</main>
<section class="">
  <div class="end rounded-pill">
    <div class="row align-items-center justify-content-center my-5">
      <div class="col-sm-auto" *ngIf="this.firstContactPojo?.dentalOfficeReferralTo?.aboutUrl">
        <div class="end-text ">
          <p class="text-center text-muted end-p p-1" data-bs-toggle="modal " data-bs-target="#exampleModal ">
            <span class="mx-2 fw-bold ">Office Introduction</span>
            <span class="animated-image " (click)="showFirstContactVideo(true) "><svg
              xmlns="http://www.w3.org/2000/svg" width="2em " height="2em " viewBox="0 0 16 16 ">
              <path fill="#369dbf80 "
                    d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7s-7-3.1-7-7s3.1-7 7-7zm0-1C3.6 0 0 3.6 0 8s3.6 8 8 8s8-3.6 8-8s-3.6-8-8-8z "/>
              <path fill="#369dbf80 " d="M6 4v8l6-4z "/>
            </svg></span></p>
        </div>
      </div>
      <div class="col-sm-auto">
        <div class="end-text ">
          <p class="text-center text-muted end-p p-1" data-bs-toggle="modal" data-bs-target="#exampleModal ">
            <span class="mx-2 fw-bold ">Why did I get referred?</span>
            <span class="animated-image " (click)="showFirstContactVideo(false) "><svg
              xmlns="http://www.w3.org/2000/svg" width="2em " height="2em " viewBox="0 0 16 16 ">
              <path fill="#369dbf80 "
                    d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7s-7-3.1-7-7s3.1-7 7-7zm0-1C3.6 0 0 3.6 0 8s3.6 8 8 8s8-3.6 8-8s-3.6-8-8-8z "/>
              <path fill="#369dbf80 " d="M6 4v8l6-4z "/>
            </svg></span></p>
        </div>
      </div>
    </div>

    <div class="row align-items-center justify-content-center g-3 mt-3">
      <div class="col-auto " *ngIf="this.firstContactPojo?.dentalOfficeReferralTo?.website ">
        <a target="_blank " href="{{this.firstContactPojo?.dentalOfficeReferralTo?.website}} "
           class="btn btn-outline-primary btn-set btn-large px-3 py-2 rounded-5 "><span class="mx-1 "><svg
          xmlns="http://www.w3.org/2000/svg" width="1.5em " height="1.5em " viewBox="0 0 24 24 ">
                <path fill="#369dbf "
                      d="M16.36 14c.08-.66.14-1.32.14-2c0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2c0-.68.06-1.35.16-2h4.68c.09.65.16
            1.32.16 2c0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.923 7.923 0 0 1 9.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 0 1 5.08 16m-.82-2C4.1 13.36 4 12.69
            4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2c0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.65 15.65 0 0 0-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10
            10 0 0 0 10-10A10 10 0 0 0 12 2Z "/>
              </svg></span><span>Website</span></a>
      </div>
      <div class="col-auto ">
        <a href="tel:{{this.firstContactPojo?.dentalOfficeReferralTo?.mobileNumber}} "
           class="btn btn-outline-primary btn-set btn-large px-3 py-2 rounded-5 "><span class="mx-1 "><svg
          xmlns="http://www.w3.org/2000/svg" width="1.5em " height="1.5em " viewBox="0 0 24 24 ">
                <path fill="#369dbf "
                      d="M19.95 21q-3.225 0-6.287-1.438q-3.063-1.437-5.425-3.8q-2.363-2.362-3.8-5.425Q3 7.275 3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.225t.325.575l.65 3.5q.05.35-.012.637q-.063.288-.288.513L6.975 10.9q1.05 1.8 2.638 3.375Q11.2 15.85
            13.1 17l2.35-2.35q.225-.225.588-.338q.362-.112.712-.062l3.45.7q.35.075.575.337q.225.263.225.613v4.05q0 .45-.3.75t-.75.3ZM6.025 9l1.65-1.65L7.25 5H5.025q.125 1.025.35 2.025q.225 1 .65 1.975ZM19 18.95v-2.2l-2.35-.475l-1.675 1.675q.975.425 1.988.675q1.012.25
            2.037.325Zm-4-1.025ZM6.025 9Z "/>
              </svg></span><span>Call Now</span></a>
      </div>
    </div>

    <div class="my-5 pb-3 text-muted" *ngIf="this.firstContactPojo?.officeReviewDtoList?.length >= 1">
      <div class="review-title text-center mb-3 ">
        <p class="fw-bold mx-2">See what people are saying<span><svg
          xmlns="http://www.w3.org/2000/svg" width="2em" height="2em"
          viewBox="0 0 32 32 "><path fill="#369dbf60"
                                     d="m16 8l1.912 3.703l4.088.594L19 15l1 4l-4-2.25L12 19l1-4l-3-2.703l4.2-.594L16 8z "/><path
          fill="#F1F7FF "
          d="M17.736 30L16 29l4-7h6a1.997 1.997 0 0 0 2-2V8a1.997 1.997 0 0 0-2-2H6a1.997 1.997 0 0 0-2 2v12a1.997 1.997 0 0 0 2 2h9v2H6a4 4 0 0 1-4-4V8a3.999 3.999 0 0 1 4-4h20a3.999 3.999 0 0 1 4 4v12a4 4 0 0 1-4 4h-4.835Z "/></svg></span>
        </p>
      </div>
      <div class="row g-3">
        <div class="col-md" *ngFor="let ff of this.firstContactPojo?.officeReviewDtoList">
          <div class="card shadow-sm p-1">
            <div class="my-1">
              <ngb-rating [max]="5" [(rate)]="ff.rating" [readonly]="true" class="fs-3"></ngb-rating>
            </div>
            <div class="card-body">
              <h5 class="m-0">{{ff.name | titlecase }}</h5>
              <p class="p-0 text-wrap">{{ff.comment | slice:0:150}}{{ff.comment.length > 150 ? '...' : ''}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
