import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {LoggedInGuard} from "../../shared/logged-in-guard/logged-in.guard";
import {DashboardSwitchComponent} from "./layout/dashboard-switch/dashboard-switch.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardSwitchComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
}
