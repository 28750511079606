import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {SocialService} from "../../../shared/subject/SocialService";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-first-contact-video',
  templateUrl: './first-contact-video.component.html',
  styleUrls: ['./first-contact-video.component.css']
})
export class FirstContactVideoComponent implements OnInit {
  // @Input()
  isIntroduction: boolean;
  videoUrl: string;
  videoz: SafeResourceUrl;

  constructor(private bsModalRef: BsModalRef,
              public socialService: SocialService,
              private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    this.socialService.firstContactSubject.subscribe(res => {
      this.videoz = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + this.getId(res));
      this.videoUrl = res;
    })
  }

  cancelModal() {
    this.bsModalRef.hide();
  }
  getId(url) {
    let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    return regex.exec(url)[3];
  }
}
