<div class="container">
  <div class="row">
    <nav class="navbar sticky-top py-3" style="background-color: #f1f7ff">
      <div class="container-fluid">
        <div class="row w-100">
          <div class="d-block">
            <div class="d-flex align-items-center">
              <p class="small my-3">
                <span> Overview </span> >
                <a routerLink="/out-bound" style="cursor: pointer">Outbound </a>
                >
                <a
                  routerLink="/out-bound/create-referral"
                  style="cursor: pointer"
                >Referral Creation</a
                >
              </p>
            </div>

            <div class="container-fluid">
              <div class="row">
                <div
                  class="d-flex justify-content-between align-items-center flex-wrap"
                >
                  <div
                    class="d-flex align-items-center"
                    routerLink="/out-bound"
                    style="cursor: pointer"
                  >
                    <div class="p-2 me-2 notify-card">
                      <div class="align-items-center d-flex">
                        <iconify-icon
                          class="fs-5"
                          icon="bi:arrow-left"
                        ></iconify-icon>
                      </div>
                    </div>
                    <p class="mb-0">Go back</p>
                  </div>
                  <div
                    class="animate__pulse animate__animated animate__delay-5s 5s animate__slower 3s animate__infinite infinite"
                  >
                    <button
                      class="btn btn-primary d-flex align-items-center"
                      (click)="previewReferralRequest()"

                      joyrideStep="two"
                      stepPosition="bottom"
                      text="Then, select Preview to view the referral before sending."
                    >
                      <iconify-icon
                        icon="fluent:send-28-regular"
                        class="fs-5 me-2"
                      ></iconify-icon>
                      Preview Referral
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- search filter  -->

    <div class="position-relative">
      <div class="mt-4 p-0">
        <div class="container-fluid">
          <div *ngIf="showErrorMessageTrigger">
            <div
              class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
              role="alert"
            >
              <p class="m-0 p-0 d-flex align-items-start">
                <span>
                  <iconify-icon
                    class="iconify me-2"
                    icon="ep:info-filled"
                  ></iconify-icon>
                  {{ getErrorMessage() | removeUnderscores }}
                </span>
                <iconify-icon
                  class="iconify fs-4 ms-auto pointer"
                  icon="mdi:close"
                  data-bs-dismiss="alert"
                >
                </iconify-icon>
              </p>
            </div>
          </div>
          <form class="row" [formGroup]="formGroup" autocomplete="false">
            <div class="col-xl-8 ps-xl-0">
              <div class="overview-card p-md-4 p-2 py-3">
                <div class="container">
                  <div class="row gx-3 gy-3">
                    <div class="col-md-6 col-12 mb-md-0 mb-4"
                         joyrideStep="one"
                         stepPosition="bottom"
                         text="Fill up all the fields with asterisks as these are required."
                    >
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          id="firstName"
                          placeholder="First Name"
                          aria-label="First Name "
                          formControlName="patientFirstName"
                        />
                        <label for="firstName" class="required">First Name</label>
                      </div>
                      <app-field-error
                        *ngIf="
                          (formGroup.get('patientFirstName')?.errors
                            ?.required &&
                            formGroup.get('patientFirstName')?.touched) ||
                          (formGroup.get('patientFirstName')?.errors
                            ?.required &&
                            errorSubmission)
                        "
                        [message]="'Patient first name cannot be blank.'"
                      >
                      </app-field-error>
                      <app-field-error
                        *ngIf="(formGroup.get('patientFirstName')?.errors?.maxlength &&
                        formGroup.get('patientFirstName')?.touched) ||
                      (formGroup.get('patientFirstName')?.errors?.maxlength &&
                        errorSubmission)"
                        [message]="'Patient first name cannot be more than fifty(50) characters.'"
                      ></app-field-error>
                      <app-field-error
                        *ngIf="(formGroup.get('patientFirstName')?.errors?.minlength &&
                        formGroup.get('patientFirstName')?.touched) ||
                      (formGroup.get('patientFirstName')?.errors?.minlength &&
                        errorSubmission)"
                        [message]="'Patient first name cannot be more than two(2) characters.'"
                      ></app-field-error>
                    </div>

                      <div class="col-md-6 col-12">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control form-control-lg"
                            id="lastName"
                            placeholder="Last Name"
                            aria-label="Last Name"
                            formControlName="patientLastName"
                          />
                          <label for="lastName" class="required">Last Name</label>
                        </div>
                        <app-field-error
                          *ngIf="
                          (formGroup.get('patientLastName')?.errors?.required &&
                            formGroup.get('patientLastName')?.touched) ||
                          (formGroup.get('patientLastName')?.errors?.required &&
                            errorSubmission)
                        "
                          [message]="'Patient last name cannot be blank.'"
                        ></app-field-error>
                        <app-field-error
                          *ngIf="(formGroup.get('patientLastName')?.errors?.maxlength &&
                        formGroup.get('patientLastName')?.touched) ||
                      (formGroup.get('patientLastName')?.errors?.maxlength &&
                        errorSubmission)"
                          [message]="'Patient last name cannot be more than fifty(50) characters.'"
                        ></app-field-error>
                        <app-field-error
                          *ngIf="(formGroup.get('patientLastName')?.errors?.minlength &&
                        formGroup.get('patientLastName')?.touched) ||
                      (formGroup.get('patientLastName')?.errors?.minlength &&
                        errorSubmission)"
                          [message]="'Patient last name cannot be more than two(2) characters.'"
                        ></app-field-error>
                      </div>

                      <div class="col-md-6 col-12">
                        <label for="phoneNumber" class="form-label rfr-label required">Phone Number</label>
                        <ngx-intl-tel-input
                          [cssClass]="'form-control form-control-lg'"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="true"
                          [id]="'phoneNumber'"
                          [maxLength]="'20'"
                          [phoneValidation]="true"
                          [preferredCountries]="preferredCountries"
                          [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]"
                          [searchCountryFlag]="true"
                          [selectedCountryISO]="countryISO"
                          [selectFirstCountry]="true"
                          [numberFormat]="PhoneNumberFormat.National"
                          formControlName="phoneNumber"
                          id="phoneNumber"
                        >
                        </ngx-intl-tel-input>

                        <app-field-error
                          *ngIf="
                          (formGroup.get('phoneNumber')?.errors?.required &&
                            formGroup.get('phoneNumber')?.touched) ||
                          (formGroup.get('phoneNumber')?.errors?.required &&
                            errorSubmission)
                        "
                          [message]="'Phone number cannot be blank.'"
                        ></app-field-error>
                        <app-field-error
                          class="mt-1"
                          [message]="'Phone number is invalid.'"
                          *ngIf="
                          formGroup.get('phoneNumber')!.errors?.validatePhoneNumber"
                        ></app-field-error>
                      </div>

                    <div class="col-md-6 col-12">
                      <label for="dateOfBirth" class="form-label rfr-label required">Date of Birth</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          formControlName="dateOfBirth"
                          id="dateOfBirth"
                          placeholder="MM-DD-YYYY"
                          #d="bsDatepicker"
                          [maxDate]="now"
                          [bsConfig]="{isAnimated: true , dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue' }"
                          bsDatepicker
                        />

                        <span
                          (click)="d.toggle()"
                          class="btn-primary-outline py-3 input-group-text pointer"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>

                      <app-field-error
                        *ngIf="
                          formGroup.get('dateOfBirth')?.errors?.failedFormat &&
                          formGroup.get('dateOfBirth')?.touched
                        "
                        [message]="'Invalid date format'"
                      ></app-field-error>

                      <app-field-error
                        *ngIf="
                          (formGroup.get('dateOfBirth')?.errors?.required &&
                            formGroup.get('dateOfBirth')?.touched) ||
                          (formGroup.get('dateOfBirth')?.errors?.required &&
                            errorSubmission)
                        "
                        [message]="'Date of birth cannot be blank.'"
                      ></app-field-error>
                      <span class="text-primary ps-2 fs-12"
                      >Age: {{ fullAge }}</span
                      >
                    </div>

                    <div class="col-md-6 col-12 mt-4">
                      <div class="form-floating">

                        <select class="form-select"
                                aria-label="Patient Insurance"
                                id="patientInsurance"
                                formControlName="patientInsurance"
                                (change)="getFromName()"
                                aria-describedby="Patient Insurance">
                          <option value="" disabled selected> e.g Aetna</option>
                          <option *ngFor="let item of this.insuranceCompanyOptions" [value]="item.code">
                            {{ item.name| titlecase | removeUnderscores}}
                          </option>
                        </select>
                        <label for="patientInsurance">Patient Insurance</label>
                      </div>
                    </div>

                    <div class="col-md-6 col-12 mb-3 mt-md-4 mt-5">
                      <div class="form-floating">

                        <select class="form-select"
                                id="floatingSelect"
                                aria-label="Floating label select "
                                formControlName="referralFrom"
                                (change)="getFromName()"
                                aria-describedby="adddoc">
                          <option [selected]="true"
                                  *ngIf="this.manualDentalProfessionalFirstName">{{'Dr. ' + this.manualDentalProfessionalFirstName + ' ' + this.manualDentalProfessionalLastName}}</option>
                          <option
                            [selected]="false"
                            [value]="''"
                            class="pt-2"
                            style="font-size: 10px">
                            Referring Doctor..
                          </option>
                          <option
                            [id]="item.dentalProfessionalPojo.id"
                            *ngFor="let item of this.professionalList"
                            [value]="item.dentalProfessionalPojo.id">
                            {{"Dr. " + item.dentalProfessionalPojo.name| uppercase| removeUnderscores}}
                          </option>
                        </select>
                        <div id="adddoc" class="form-text float-end pointer" (click)="showAddDoctorModal()">
                          <iconify-icon inline icon="akar-icons:person-add" style="color: #02183D;;" width="16"
                                        height="16"></iconify-icon>
                          Add Doctor
                        </div>
                        <label for="floatingSelect" class="required">Refer From</label>
                      </div>
                      <app-field-error *ngIf="
                          (formGroup.get('referralFrom')?.errors?.required &&
                            formGroup.get('referralFrom')?.touched) ||
                          (formGroup.get('referralFrom')?.errors?.required &&
                            errorSubmission)
                        "
                                       [message]="'Referral from cannot be blank.'"
                      ></app-field-error>
                    </div>

                    <div class="col-12 mt-0">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          id="referTo"
                          placeholder="Refer To"
                          aria-label="Refer To"
                          formControlName="referralTo"
                          (click)="this.selectReceivingDentalOffice()"
                        />
                        <label for="referTo" class="required">Refer To</label>
                      </div>
                      <app-field-error
                        *ngIf="
                          (formGroup.get('referralTo')?.errors?.required &&
                            formGroup.get('referralTo')?.touched) ||
                          (formGroup.get('referralTo')?.errors?.required &&
                            errorSubmission)
                        "
                        [message]="'Select a receiving dentist'"
                      ></app-field-error>
                    </div>

                    <ng-container *ngIf="formGroup.get('isExternalReferral').value">

                    <div class="col-md-6 col-12 mb-md-0 mb-4" *ngIf="false">
                      <div class="form-floating mt-4x">
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          id="externalReferralOfficeEmail"
                          placeholder="E.g. john@doe.com"
                          aria-label="Referral Office's Email Address"
                          formControlName="externalReferralOfficeEmail"
                        />
                        <label for="externalReferralOfficeEmail" class="required ">Referral Office's Email Address</label>
                      </div>
                      <app-field-error
                        *ngIf="
                          (formGroup.get('externalReferralOfficeEmail')?.errors
                            ?.required &&
                            formGroup.get('externalReferralOfficeEmail')?.touched) ||
                          (formGroup.get('externalReferralOfficeEmail')?.errors
                            ?.required &&
                            errorSubmission)
                        "
                        [message]="'Referral Office\'s Email cannot be blank.'"
                      >
                      </app-field-error>
                      <app-field-error *ngIf="(this.formGroup.get('externalReferralOfficeEmail').errors?.pattern && formGroup.get('externalReferralOfficeEmail')?.touched)" [message]="'Invalid email address pattern.'"></app-field-error>
                      <app-field-error *ngIf="(this.formGroup.get('externalReferralOfficeEmail').errors?.notDeliverable && formGroup.get('externalReferralOfficeEmail')?.touched)" [message]="'Please double-check email address'"></app-field-error>

                    </div>

                    <div class="col-md-6 col-12" *ngIf="false">
                      <label for="externalReferralOfficePhone" class="form-label rfr-label ">Referral Office Fax Number</label>
                      <ngx-intl-tel-input
                        [cssClass]="'form-control form-control-lg'"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [id]="'externalReferralOfficePhone'"
                        [maxLength]="'20'"
                        [phoneValidation]="true"
                        [preferredCountries]="preferredCountries"
                        [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]"
                        [searchCountryFlag]="true"
                        [selectedCountryISO]="countryISO"
                        [selectFirstCountry]="true"
                        [numberFormat]="PhoneNumberFormat.National"
                        formControlName="externalReferralOfficePhone"
                        id="externalReferralOfficePhone"
                      >
                      </ngx-intl-tel-input>

                      <app-field-error
                        class="mt-1"
                        [message]="'Phone number is invalid.'"
                        *ngIf="
                          formGroup.get('externalReferralOfficePhone')!.errors?.validatePhoneNumber"
                      ></app-field-error>
                    </div>
                    </ng-container>

                    <div class="col-12 mb-3">
                      <label
                        for="reasonForReferral"
                        class="form-label rfr-label required">Reason for Referral</label>
                      <div>
                        <div class="form-control bordered"
                             style="overflow-y: auto">
                          <tag-input
                            class="border-0"
                            [addOnBlur]="true"
                            [clearOnBlur]="true"
                            [allowDupes]="false"
                            [onlyFromAutocomplete]="false"
                            size="20"
                            style="min-height: 50px"
                            id="reasonForReferral"
                            placeholder="Select Procedures"
                            formControlName="procedureType"
                            [secondaryPlaceholder]="'Procedures & Concerns'">
                            <tag-input-dropdown
                              [showDropdownIfEmpty]="true"
                              [autocompleteItems]="this.dentalServicesOptions || this.procedureList"
                            >
                            </tag-input-dropdown>
                          </tag-input>
                        </div>
                        <ol class="nav d-flex align-items-center">
                          <li (click)="addSuggestedProcedure(suggested)"
                              class="reasons m-2 shadow-sm btn rounded-5 fw-bold text-primary pointer"
                              style="font-size: 13px"
                              *ngFor="let suggested of this.dentalServicesTiles; i as index">
                            <a>{{ suggested }}</a>
                          </li>
                          <iconify-icon *ngIf="this.loadingDentalServices" icon="eos-icons:bubble-loading" width="40" height="40"></iconify-icon>
                        </ol>
                      </div>
                      <app-field-error
                        *ngIf="
                          (formGroup.get('procedureType')?.errors?.required &&
                            formGroup.get('procedureType')?.touched) ||
                          (formGroup.get('procedureType')?.errors?.required &&
                            errorSubmission)
                        "
                        [message]="'Enter at least one procedure.'"
                      ></app-field-error>
                    </div>

                    <div class="my-1 col-lg-12">
                      <div>
                        <div class="my-2">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input form-check-label required"
                              type="checkbox"
                              id="inlineCheckbox2"
                              formControlName="preMedRequired"
                            />
                            <label
                              class="form-label mx-2 mt-1"
                              for="inlineCheckbox2"
                            >Pre-Med Required</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="col-12 mb-3">
                            <div class="form-floating">
                              <textarea
                                class="form-control form-control-lg"
                                formControlName="referralNote"
                                id="floatingTextarea"
                                rows="2"
                                maxlength="500"
                                placeholder="Referral Notes [max 500]"
                                [style.height.px]="height"
                              ></textarea>
                              <label for="floatingTextarea"
                              >Referral Notes [Max 500]</label
                              >
                            </div>
                            <app-field-error
                              *ngIf="
                                formGroup.get('referralNote').errors
                                  ?.maxLength &&
                                formGroup.get('referralNote')?.touched
                              "
                              [message]="'Enter referral notes.'"
                            ></app-field-error>
                          </div>
                          <div>
                            <p class="fs-6 fw-bold mb-1">Priority Level</p>
                            <div class="form-check form-check-inline">
                              <div class="row">
                                <div
                                  class="my-1 col-auto"
                                  *ngFor="
                                    let pro of priorityEnum;
                                    let i = index">
                                  <input
                                    type="radio"
                                    class="form-check-input"
                                    formControlName="priorityLevel"
                                    [value]="pro"
                                  />
                                  <label class="form-check-label mx-2 mt-2">
                                    {{ pro | removeUnderscores }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card bg-transparent container m-2 border-0">
                      <div class="row g-2">
                        <div class="col-auto">
                          <label
                            class="filelabel"
                            appDragDropFileUpload
                            (fileDropped)="onFileChange($event)"
                           >
                            <i class="fa fa-paperclip"> </i>
                            <span class="title"> Add/Drop Files </span>
                            <p class="opacity-25 small mb-0">
                              Only .pdf, .png, .jpg, .jpeg, .dcm supported.
                            </p>
                            <input
                              class="FileUpload1"
                              id="FileInput"
                              type="file"
                              multiple
                              hidden
                              #fileUpload
                              onclick="value = null"
                              (change)="onFileChange($event)"
                            />
                          </label>
                        </div>

                        <div class="col-auto play" *ngFor="let doc of xrayUrl; let i = index">
                          <label class="filelabel2 shadow-sm" *ngIf="xrayUrl">
                            <div>
                              <img
                                style="width: 50%; height: auto"
                                class="card-img"
                                *ngIf="bwFileIdList?.length > 0"
                                [src]="getFileURL(doc, this.bwFileIdList[i])"
                                [alt]="xrayUrl[i].name"
                              />
                            </div>
                            <p
                              class="text-center mb-1 p-1"
                              style="
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;">
                              {{ xrayUrl[i].name }}
                            </p>
                            <div
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample">
                              <span
                                (click)="onFileDelete(i)"
                                class="pointer float-end p-1"
                              >
                                <iconify-icon
                                  icon="majesticons:delete-bin"
                                  style="color: #369dbf"
                                  width="18"
                                >
                                </iconify-icon>
                              </span>
                            </div>
                            <div class="m-2">
                              <div class="progress" *ngIf="isDraft || progress">
                                <div
                                  class="progress-bar bg-primary"
                                  [style.width]="(progress || 100) + '%'"
                                >
                                  {{ progress || 100 }}%
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 my-4 p-0 my-xl-0">
              <!--              <app-field-error class="align-content-center text-center" *ngIf=" (formGroup.get('affectedToothRange')?.errors?.required && formGroup.get('affectedToothRange')?.touched)"-->
              <!--                [message]="'Affected tooth range is required.'"></app-field-error>-->
              <div class="position-relative">
                <div class="">
                  <div *ngIf="affectedTeeth" class="card shadow-sm rounded-0 border-0 mb-2 p-2">
                    <div class="card border-0 rounded-0 tooth-bg">
                      <div class="my-1">
                        <div class="tooth-svg ps-1 pe-2 row">
                          <div
                            class="tooth-item"
                            style="width: fit-content; height: 60px"
                            *ngFor="let tooth of toothArray()"
                          >
                            <div class="col pointer" (click)="removeTooth(tooth)">
                              <span class="remove-x">X</span>
                              <span class="tooth-notify-badge tooth-badge">{{tooth}}</span>
                              <img
                                src="./assets/img/tooth-number.svg"
                                alt=""
                                style="min-width: 25px; max-width: 25px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="overview-card p-3 ">
                    <div class="filter-card-bg p-3">
                      <div class="d-flex justify-content-center flex-column align-items-center" style="cursor: cell;">
                        <div *ngIf="showAdultTeeth; else childTeethComponent">
                          <div *ngIf="!showAdultTeeth; else adultTeethComponent"></div>
                        </div>
                        <div class="row my-4">
                          <div class="col-6 p-0">
                            <button
                              (click)="adultTeethToggle()"
                              [class]="
                                showAdultTeeth
                                  ? 'btn btn-primary preview-btn'
                                  : 'btn'">
                              Adult
                            </button>
                          </div>
                          <div class="col-6 p-0">
                            <button
                              (click)="adultTeethToggle()"
                              [class]="
                                !showAdultTeeth
                                  ? 'btn btn-primary preview-btn'
                                  : 'btn'">
                              Child
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="mt-4">
                <button
                  class="btn btn-primary d-flex align-items-center float-md-end float-sm-start preview-btn"
                  (click)="previewReferralRequest()"
                >
                  <iconify-icon
                    icon="fluent:send-28-regular"
                    class="fs-5 me-2"
                  ></iconify-icon>
                  Preview Referral
                </button>
              </div> -->
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
