import {CUSTOM_ELEMENTS_SCHEMA, NgModule,} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import {AccountSettingsRoutingModule} from "./account-settings-routing.module";
import {SharedModule} from "../../shared/shared.module";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {TagInputModule} from "ngx-chips";
import { ProfessionalProfileComponent } from './professional-profile/professional-profile.component';
import { OfficeProfileComponent } from './office-profile/office-profile.component';
import {NgxCaptureModule} from "ngx-capture";



@NgModule({
    declarations: [
        ProfileComponent,
        ProfessionalProfileComponent,
        OfficeProfileComponent,
    ],
  imports: [
    CommonModule,
    AccountSettingsRoutingModule,
    SharedModule,
    NgxIntlTelInputModule,
    TagInputModule,
    NgxCaptureModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AccountSettingsModule { }
