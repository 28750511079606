/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { ReasonForDisapproval } from './reason-for-disapproval';
import { SupportedTimeZone } from './supported-time-zone';
import { PortalUser } from './portal-user';


export interface DentalOffice { 
    name?: string;
    summary?: string;
    email?: string;
    phoneNumber?: string;
    alternatePhoneNumber?: string;
    facebookUrl?: string;
    twitterUrl?: string;
    instagramUrl?: string;
    alternateEmail?: string;
    dateCreated?: string;
    lastUpdated?: string;
    status?: DentalOffice.StatusEnum;
    uniqueStatement?: string;
    setupComplete?: boolean;
    website?: string;
    reviewProfileId?: string;
    approvalStatus?: DentalOffice.ApprovalStatusEnum;
    reasonForDenial?: string;
    dateOfApprovalProcessing?: string;
    lastReviewPage?: number;
    code?: string;
    handle?: string;
    requireInsuranceForAppointment?: boolean;
    requireIdentityForAppointment?: boolean;
    appointmentBookingPolicy?: string;
    apptProcessingTimeInMinutes?: number;
    fax?: string;
    specialtyOffice?: boolean;
    defaultUtcOffsetMinutes?: number;
    requireInsuranceForReferral?: boolean;
    aboutUrl?: string;
    adminNotified?: boolean;
    id?: number;
    portalAccount?: PortalAccount;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    approvalProcessedBy?: PortalUser;
    reasonForDisapproval?: ReasonForDisapproval;
    defaultTimeZone?: SupportedTimeZone;
}
export namespace DentalOffice {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
}


