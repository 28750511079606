import {Component, OnInit} from '@angular/core';
import {
  InvitationPojo,
  InviteSearchControllerService,
  InviteSearchFilter,
  QueryResultsPojoInvitationPojo
} from "dd-core-api-sdk";
import {getOffset, removeUndefinedOrNullFields} from "../../../models/search-model";
import {BehaviorSubject} from "rxjs";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../services/UserKeycloak";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup} from '@angular/forms';
import * as moment from "moment";
import {SelectionManager} from "dd-shared-lib";
import {Utils} from "../../../shared/utils";
import InvitationStatusEnum = InviteSearchFilter.InvitationStatusEnum;
import {BsModalService} from "ngx-bootstrap/modal";
import {InviteProcessingComponent} from "../invite-processing/invite-processing.component";

@Component({
  selector: 'app-invites-list',
  templateUrl: './invites-list.component.html',
  styleUrls: ['./invites-list.component.css']
})
export class InvitesListComponent implements OnInit {
  selectionManager: SelectionManager<InvitationPojo, number>;
  user: UserKeycloak;
  searching = true;
  form: FormGroup;
  limit: number;
  offset: number;
  resultArray: Array<InvitationPojo>;
  filterData: InviteSearchFilter = {};
  resultSubject = new BehaviorSubject<QueryResultsPojoInvitationPojo>({});
  queryResults: QueryResultsPojoInvitationPojo;
  data: InviteSearchFilter;
  showErrorMessageTrigger = false;
  isError: boolean = true;
  errMessage = '';
  currentStartDate: any;
  type = InvitationPojo.TypeEnum;
  invitesStatusEnum = Utils.enumValues(InvitationStatusEnum);
  invitesStatus = InvitationStatusEnum;
  utcOffsetMinutes: string;
  officeID: number;
  showFilters = false;

  constructor(private fb: FormBuilder,
              private inviteSearchService: InviteSearchControllerService,
              private authService: AuthenticationServiceKeycloak,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private modalService: BsModalService) {

    this.utcOffsetMinutes = 'UTC' + this.getTimeZone().toString();

    class ItemsSelect extends SelectionManager<any, any> {

      getIdentifier(e: any) {
        return e;
      }

      getState(e: any): number {
        return e.id;
      }

      isSelectable(e: any): boolean {
        return true;
      }
    }

    this.selectionManager = new ItemsSelect();
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['popup'] === 'create') {
        this.openCreateInviteModal();
      }
    });


    this.form = this.fb.group({
      name: [''],
      statusConstant: [''],
      typeConstant: [''],
      startDate: [''],
      endDate: [''],
      limit: [''],
      offset: ['']
    });

    this.authService.getUser().subscribe(v => {
      this.user = v
    });

    this.form.controls.startDate.valueChanges.subscribe(v => {
      this.currentStartDate = v;
      if (v != moment(v).format('YYYY-MM-DD')) {
        this.form.patchValue({endDate: ''});
      }
    });

    this.form.controls.endDate.valueChanges.subscribe(v => {
      if (moment(this.currentStartDate) > moment(v)) {
        this.showErrorMessage('Date(from) should be greater than Date(to).');
        this.form.patchValue({endDate: ''});
      }
    });

    this.officeID = AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id;

    this.loadInvitations();
  }

  loadInvitations() {

    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);
    this.search(filter);
    // this.invites$.subscribe(value => {
    //   this.dentalProfessionalInvitationPojo = [];
    //   value.results.forEach(this.addExistingDPInvites);
    //   // this.doSetUpService.dentalProfessionalInvitationPojo$.next(this.dentalProfessionalInvitationPojo);
    // });

  }

  search(filter: InviteSearchFilter) {
    this.selectionManager.clearSelection();
    this.searching = true;
    this.inviteSearchService.searchInvitations({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.offset = v.offset;
      this.queryResults = v;
      this.resultArray = v.results!;
      this.resultSubject.next(v);
    }, () => {
      this.searching = false;
    });

    this.data = filter;
    // this.data.page = this.page;
  }

  getFilter(limit?: number): InviteSearchFilter {
    const data = this.form!.value;

    let filter: InviteSearchFilter = {};
    filter.invitationStatus = data.statusConstant;
    filter.dentalOffice = AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id;
    filter.dentalProfessional = this.user?.dentalProfessional?.id;
    filter.name = data.name;
    filter.startDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }

  onPageChange($event) {
    const searchFilter: InviteSearchFilter = {
      dentalOffice: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
      offset: getOffset($event.page, $event.size || 4),
      limit: $event.itemsPerPage,
    };

    this.search(searchFilter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }

  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  formatUTCMoment(dateCreated: string) {
    return moment(dateCreated).utc(true).toString();
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  showInviteOptionModal(inviteId: number, dentalOfficeId: number, isDecline: boolean, index: number) {
    let bsModalRef = this.modalService.show(InviteProcessingComponent, {
      initialState: {
        inviteId: inviteId,
        dentalOfficeId: dentalOfficeId,
        isDecline: isDecline,
        message: !isDecline ? "Are you sure you want to accept this invite?" : "Are you sure you want to decline this invite?"
      },
      keyboard: false,
      class: '"modal-dialog modal-dialog-scrollable modal-dialog-centered',
      ignoreBackdropClick: true,
    });
    bsModalRef?.content?.successEvent.subscribe(v => {
      this.isError = false;
      this.resultSubject.value.results[index].invitationStatus = isDecline ? InvitationStatusEnum.Declined : InvitationStatusEnum.Accepted;
      bsModalRef.hide();
      this.showErrorMessage(isDecline ? 'Invite declined successfully!!.' : 'Invite accepted successfully!!.');
    });
    bsModalRef?.content?.errorEvent.subscribe(v => {
      bsModalRef.hide();
      this.isError = true;
      this.showErrorMessage(isDecline ? 'Error in declining invite!!.' : 'Error in accepting invite!!.');
    });
    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
  }

  private openCreateInviteModal() {
    this.goToCreate();
  }

  goToCreate() {
    console.log('goToCreate');
    this.router.navigate(['/invites-list/create']);
  }

  toggleFilter(): void {
    this.showFilters = !this.showFilters;
  }

}
