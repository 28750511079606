import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import {AsyncSubject} from 'rxjs';
import {AuthenticationServiceKeycloak} from "./services/AuthenticationServiceKeycloak";
import * as moment from "moment/moment";
import {UserKeycloak} from "./services/UserKeycloak";
import {PwaService} from "./services/pwa-service";
import {UserAccountKeycloak} from "./services/UserAccountKeycloak";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'DentalDoor';
  routeLang: string;
  timer = 0;
  destroy$ = new AsyncSubject<boolean>();
  _showNavBar = false;
  _showFooter = false;
  currentComponent: any;
  working: boolean;
  user: UserKeycloak;
  userResponse = false;
  _isExtranet: boolean;
  loading: boolean = true;
  showExpirySubscriptionBanner: boolean = false;
  minSubscriptionCount = 3;
  inboundTotal: any;
  @ViewChild('screen', {static: true}) screen: any;
  currentAccount: UserAccountKeycloak;
  _isAffiliate: any;

  constructor(
    private router: Router,
    public Pwa: PwaService,
    private authenticationService: AuthenticationServiceKeycloak) {

    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    })

    this.authenticationService.getUser().subscribe(res => {
      if (res) {
        this.user = res;
        if (AuthenticationServiceKeycloak._currentUserAccount?.dentalOfficeSubscription) {
          const numberOfDays = this.formatDate(AuthenticationServiceKeycloak._currentUserAccount?.dentalOfficeSubscription?.endDate);
          if (numberOfDays <= 0 && AuthenticationServiceKeycloak._currentUserAccount?.dentalOfficeSubscription?.retryCount > this.minSubscriptionCount) {
            this.showExpirySubscriptionBanner = true;
          }
        }

        this.authenticationService.getAccount()
          .subscribe(value => {
            this.currentAccount = value;
          });
      }
    })
    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart) {
        if (data) {
          this._showNavBar = data.snapshot.data.showNavBar;
          this._showFooter = data.snapshot.data.showFooter;
          this._isExtranet = data.snapshot.data.isExtranet;
          this._isAffiliate = data.snapshot.data.isAffiliate;
        }
      }
    });
  }

  ngOnInit(): void {

    if (this.Pwa.promptEvent) {
      if (!localStorage.getItem('askedInstall')) {
        this.installPwa();
      }
    }
    this.userResponse = false;
  }

  installPwa(): void {
    localStorage.setItem('askedInstall', "true");
    this.Pwa.promptEvent.prompt();
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }

  componentAdded(component) {
    this.working = true;
    this.currentComponent = component;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get isNavBar() {
    return this._showNavBar;
  }

  get isFooter() {
    return this._showFooter;
  }

  formatDate(date) {
    var now = moment(new Date());
    var end = moment(date);
    var duration = moment.duration(end.diff(now));
    return Math.round(duration.days());
  }

  showNotificationTotal($event: any) {
    this.inboundTotal = $event;
  }


}
