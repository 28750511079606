<div class="card border-light container-fluid success p-0 m-0">
    <div class="card-header m-0 border-0 p-4 text-center">
        <span class="float-start pointer" awPreviousStep>
      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em"
           viewBox="0 0 24 24"><path fill="currentColor"
                                     d="m10.875 19.3l-6.6-6.6q-.15-.15-.213-.325Q4 12.2 4 12t.062-.375q.063-.175.213-.325l6.6-6.6q.275-.275.687-.288q.413-.012.713.288q.3.275.313.687q.012.413-.288.713L7.4 11h11.175q.425 0 .713.287q.287.288.287.713t-.287.712Q19 13 18.575 13H7.4l4.9 4.9q.275.275.288.7q.012.425-.288.7q-.275.3-.7.3q-.425 0-.725-.3Z"/></svg>
    </span>
        <span class="h4">Confirmation</span>
        <span class="float-end pointer" (click)="emitCancelButton.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em"
           viewBox="0 0 24 24"><path fill="currentColor"
                                     d="m12 13.4l-4.9 4.9q-.275.275-.7.275q-.425 0-.7-.275q-.275-.275-.275-.7q0-.425.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7L13.4 12l4.9 4.9q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275Z"/></svg></span>
    </div>
    <div class="card-body">
        <div class="p-3 text-center">
            <p class="h5 mt-4">Withdrawal Amount</p>
            <p class="h2 mt-5">{{firstStepValue?.totalAmountDue || 0 | noRoundCurrency}}</p>
            <hr class="hr w-100 text-center">
        </div>
        <div *ngIf="showErrorMessageTrigger">
            <div class="alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert" [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                <p class="m-0 p-0 d-flex align-items-start">
                    <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                                  {{ getErrorMessage() | removeUnderscores }}
              </span>
                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                </p>
            </div>
        </div>
        <div class="amount-card p-3">
            <div class="row mb-2 small">
                <span class="col-sm text-sm-start">Bank</span>
                <span class="col-sm-auto fw-bold text-sm-end">
         {{affiliateBankPojo?.bankName ? affiliateBankPojo?.bankName : affiliateBankPojo?.bank?.name}}
        </span>
            </div>
            <div class="row mb-2 small">
                <span class="col-sm text-sm-start">Account Name</span>
                <span class="col-sm-auto fw-bold text-sm-start">{{affiliateBankPojo?.accountName}}</span>
            </div>
            <div class="row mb-2 small">
                <span class="col-sm text-sm-start">Account Number</span>
                <span class="col-sm-auto fw-bold text-sm-start">{{affiliateBankPojo?.maskedAccountNumber}}</span>
            </div>
            <div class="row mb-2 small">
                <span class="col-sm text-sm-start">Routing Number</span>
                <span class="col-sm-auto fw-bold text-sm-start">{{affiliateBankPojo?.routingNumber}}</span>
            </div>
        </div>
        <p class="card-text">All withdrawals take seven days before they are transferred to customer's bank. </p>
        <button (click)="createDebitTransaction()" class="btn btn-md btn-dark w-100 mt-3" [disabled]="sending">
      <iconify-icon
        *ngIf="sending"
        icon="eos-icons:bubble-loading"
        style="color: #ffffff "></iconify-icon>
      Confirm
    </button>
    </div>
</div>