/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserPojo } from './user-pojo';
import { DentalOfficePojo } from './dental-office-pojo';


export interface DentalOfficeContentPojo { 
    id?: number;
    dateCreated?: string;
    lastUpdated?: string;
    type?: DentalOfficeContentPojo.TypeEnum;
    status?: DentalOfficeContentPojo.StatusEnum;
    content?: string;
    dentalOffice?: DentalOfficePojo;
    deactivatedBy?: UserPojo;
    createdBy?: UserPojo;
}
export namespace DentalOfficeContentPojo {
    export type TypeEnum = 'DID_YOU_KNOW' | 'WHAT_MAKES_US_UNIQUE' | 'GALLERY';
    export const TypeEnum = {
        DidYouKnow: 'DID_YOU_KNOW' as TypeEnum,
        WhatMakesUsUnique: 'WHAT_MAKES_US_UNIQUE' as TypeEnum,
        Gallery: 'GALLERY' as TypeEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


