<div class="m-4">
  <div class="pointer">
    <div class="pull-left">
    </div>
  </div>
  <div class="modal-body">
    <div
      (click)="close()"
      class="close pull-right"
      type="button"
    >
      <span aria-hidden="true"><i class="fa fa-close"></i></span>
    </div>

    <div class="">

      <h4 class="modal-title text-center text-dark" id="modal-title">{{ 'Refer a Patient Now?' }}</h4>
      <div
        style="
          font-size: 1rem;
          font-weight: 600;
          margin: 0 auto 10px;
          max-width: 340px;
        "
      >
        <div class="container mt-5 pointer" (click)="goToRefer()">
          <div class="custom-card">
            <div>
              <span><strong>Yes</strong></span>
              <p><small class="text-muted">I want to refer a patient to a specialist now</small></p>
            </div>
            <div>
              <input type="radio" name="referral" id="referral">
            </div>
          </div>
        </div>
        <div class="container mt-5 pointer" (click)="goToInvite()">
        <div class="custom-card">
          <div>
            <p><strong>No</strong></p>
            <p><small class="text-muted">I will get to referring a patient at a later time</small></p>
          </div>
          <div>
            <input type="radio" name="referral" id="noReferral">
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
