<div class="modal-content">
  <div class="modal-header rounded-0 bg-primary">
    <h1 class="modal-title fs-5 text-light" id="messageModalLabel"> Referral Status Notes
      <iconify-icon
        icon="icon-park-outline:message-unread" style="color: #fff;" width="25" height="25">
      </iconify-icon>
    </h1>
    <button type="button" class="btn-close" (click)="dismissModal()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="showErrorMessageTrigger">
      <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show" role="alert"
           [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
        <p class="m-0 p-0 d-flex align-items-start">
              <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                {{ getErrorMessage() | removeUnderscores }}
              </span>
          <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
          </iconify-icon>
        </p>
      </div>
    </div>
    <div>
      <div class="mb-3">
        <div class="row mb-3">
          <div class="col col-form-label">Appointment Date &
            Time: {{(referralSearchPojo?.value?.appointmentDate | datetime) || 'N/A'}}</div>
        </div>
        <div id="sender" role="alert"
             *ngFor="let notes of referralSearchPojo?.value?.referralStatusNotesList; let i = index">
          <main class="wrap mb-3">
            <aside class="d-flex flex-wrap justify-content-between">
              <div class="row">
                <div class="col-auto">
                  <iconify-icon *ngIf="notes.status == referralStatus.New" icon="icon-park-outline:message-unread"
                                style="color: #369dbf;" width="20"
                                height="20">
                  </iconify-icon>
                  <iconify-icon *ngIf="notes.status == referralStatus.Pending" icon="icon-park-outline:message-unread"
                                style="color: #369dbf;" width="20"
                                height="20">
                  </iconify-icon>
                  <iconify-icon *ngIf="notes.status == referralStatus.Called" icon="eva:phone-call-fill"
                                style="color: #369dbf;" width="20"
                                height="20">
                  </iconify-icon>
                  <iconify-icon *ngIf="notes.status == referralStatus.Scheduled" icon="gridicons:scheduled"
                                style="color: #369dbf;" width="20"
                                height="20">
                  </iconify-icon>
                  <iconify-icon *ngIf="notes.status == referralStatus.Rescheduled" icon="gridicons:scheduled"
                                style="color: #369dbf;" width="20"
                                height="20">
                  </iconify-icon>
                  <iconify-icon *ngIf="notes.status == referralStatus.Custom" icon="teenyicons:appointments-solid"
                                style="color: #369dbf;" width="20"
                                height="20">
                  </iconify-icon>
                  <iconify-icon *ngIf="notes.status == referralStatus.NoShow" icon="teenyicons:appointments-solid"
                                style="color: #369dbf;" width="20"
                                height="20">
                  </iconify-icon>
                  <iconify-icon *ngIf="notes.status == referralStatus.Completed" icon="fluent-mdl2:completed-solid"
                                style="color: #369dbf;" width="20"
                                height="20">
                  </iconify-icon>
                </div>
                <div class="col-auto">
                  <div>{{notes.status | underscoreToSpace | uppercase}}</div>
<!--                  <div *ngIf="notes.status == referralStatus.Custom">{{'Rescheduled' | uppercase}}</div>-->
                </div>
              </div>
              <div class="row">
                <div class="col-auto"
                     *ngIf="notes.status != referralStatus.Scheduled && notes.status != referralStatus.Custom"> {{formatUTCMoment(notes.updatedAt) | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes}}</div>
                <div class="col-auto"
                     *ngIf="notes.status == referralStatus.Scheduled"> {{(referralSearchPojo?.value?.appointmentDate | datetime) || 'N/A'}}</div>
                <div class="col-auto"
                     *ngIf="notes.status == referralStatus.Custom"> {{(referralSearchPojo?.value?.appointmentDate | datetime) || 'N/A'}}</div>
                <div class="col-auto"
                     *ngIf="notes.status == referralSearchPojo.value.referralStatusConstant && notes.status != referralStatus.Completed && isInbound">
                  <iconify-icon icon="bx:edit" style="color: #369dbf;" width="20" *ngIf="edit"
                                (click)="toggleEditOrClose(true, false)"
                                height="20"></iconify-icon>
                  <iconify-icon icon="carbon:close-filled" style="color: #369dbf;" width="20" *ngIf="close"
                                (click)="toggleEditOrClose(false, true)"
                                height="20"></iconify-icon>
                </div>
              </div>
            </aside>
              <div class="mt-2" *ngIf="notes?.note">
                <div class="col-auto bg-white rounded-3 p-2" [innerText]="notes?.note || ''"></div>
              </div>
          </main>
          <form class="col-12 mb-3 w-100" [formGroup]="formGroup"
                *ngIf="this.close && notes.status == referralSearchPojo.value.referralStatusConstant">
            <textarea formControlName="statusNote" class="form-control form-control-lg dashb-card-border"
                      rows="2" maxlength="500" placeholder="Referral Status Notes [max 500]"></textarea>
            <app-field-error
              *ngIf="(formGroup.get('statusNote')?.errors?.required && formGroup.get('statusNote')?.touched)"
              [message]="'Status note cannot be empty'"></app-field-error>

            <button type="button" class="btn btn-primary w-100 mt-3" [disabled]="!enableButton"
                    (click)="updateStatusNote(notes?.id, notes.status, i)">
              <iconify-icon icon="eos-icons:bubble-loading" style="color: #ffffff " *ngIf="this.sending"></iconify-icon>
              Update Notes
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismissModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="dismissModal()"
            [routerLink]="[isInbound  ? '/in-bound/referrals': '/out-bound/referrals', referralSearchPojo.value.referralDataId]">
      View Details
    </button>
  </div>
</div>

