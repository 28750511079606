/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Banks } from './banks';


export interface AffiliateBankPojo { 
    bankName?: string;
    accountName?: string;
    maskedAccountNumber?: string;
    routingNumber?: string;
    bankKycStatusConstant?: AffiliateBankPojo.BankKycStatusConstantEnum;
    bankVerificationStatusConstant?: AffiliateBankPojo.BankVerificationStatusConstantEnum;
    bank?: Banks;
    apiErrorMessages?: Array<string>;
}
export namespace AffiliateBankPojo {
    export type BankKycStatusConstantEnum = 'REQUIRES_UPDATE' | 'FAILED' | 'SUCCESSFUL' | 'PENDING' | 'PROCESSING' | 'FAILED_KYC';
    export const BankKycStatusConstantEnum = {
        RequiresUpdate: 'REQUIRES_UPDATE' as BankKycStatusConstantEnum,
        Failed: 'FAILED' as BankKycStatusConstantEnum,
        Successful: 'SUCCESSFUL' as BankKycStatusConstantEnum,
        Pending: 'PENDING' as BankKycStatusConstantEnum,
        Processing: 'PROCESSING' as BankKycStatusConstantEnum,
        FailedKyc: 'FAILED_KYC' as BankKycStatusConstantEnum
    };
    export type BankVerificationStatusConstantEnum = 'PENDING' | 'SUCCESSFUL' | 'FAILED';
    export const BankVerificationStatusConstantEnum = {
        Pending: 'PENDING' as BankVerificationStatusConstantEnum,
        Successful: 'SUCCESSFUL' as BankVerificationStatusConstantEnum,
        Failed: 'FAILED' as BankVerificationStatusConstantEnum
    };
}


