import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {SocialService} from "../../../shared/subject/SocialService";
import {BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {routes} from "dd-shared-lib";
import {UserKeycloak} from "../../../services/UserKeycloak";

@Component({
  selector: 'app-creates-invites',
  templateUrl: './creates-invites.component.html',
  styleUrls: ['./creates-invites.component.css']
})
export class CreatesInvitesComponent implements OnInit {

  tabIndex = this.socialService.tabSubject.value;
  @Output() onCloseClicked = new EventEmitter();
  @ViewChild("modalContent") modalContent: any;
  @Input() fromProfile: boolean;
  subscriptionType: string;
  type: string;
  showErrorMessageTrigger = false;
  isError: boolean = true;
  errMessage = '';
  errorSubmission: boolean;
  countInvite: number;
  modal: string = 'modal';
  user: UserKeycloak;
  officeInviteCount: any = 0;

  constructor(
    public socialService: SocialService,
    private router: Router,
    private modalService: BsModalService,
    private authenticationServiceKeycloak: AuthenticationServiceKeycloak
  ) {
  }

  ngOnInit(): void {
    this.createBtnTag();

    this.authenticationServiceKeycloak.getUser().subscribe(res => {
      this.user = res;
    })
  }

  changeTab(newIndex: number) {
    this.tabIndex = newIndex;
    this.socialService.tabSubject.next(newIndex);
  }

  createBtnTag() {

    setTimeout(() => {
      const btn = document.createElement('button');
      btn.setAttribute('data-bs-toggle', 'modal')
      btn.setAttribute('data-bs-target', '#exampleModal')
      document.body.appendChild(btn);
      btn.click();
    }, 200)
  }

  inviteCounts(): any {
    this.authenticationServiceKeycloak.fetchUser().subscribe(res => {
      this.countInvite = res.invitesSent;
    })
    return this.countInvite + this.officeInviteCount;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 5000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  closeModal() {
    if (this.user.dentalProfessional) {
      const btn = document.getElementById('button');
      btn.setAttribute('data-bs-dismiss', 'modal')
      btn.click();
    } else {
      // if (this.inviteCounts() > 0) {
      const btn = document.getElementById('button');
      btn.setAttribute('data-bs-dismiss', 'modal')
      btn.click();
      this.router.navigate(['/invites-list'])
      // } else {
      //   this.showErrorMessage("You must invite at least one(1) referring partner.");
      // }
    }
  }

  getNumberCount($event: any) {
    this.officeInviteCount = $event
  }
}
