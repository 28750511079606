<div class="container mt-4">
  <div class="row">
    <div class="d-flex align-items-center justify-content-between">
      <div class="">
        <p class="fs-3 fw-bold my-3">
          <iconify-icon inline icon="icomoon-free:office" class="fs-2 me-1"></iconify-icon>
          Temporary Dental Offices
        </p>
      </div>
      <div class="d-flex">
        <button
          class="bg-primary border-0 d-flex justify-content-center align-items-center px-3 text-light"
          (click)="createOrUpdate()" style=" margin-top: 3px; padding: .35rem;">
          <iconify-icon inline icon="tabler:plus" class="me-2 fs-5" width="25"
                        height="25"></iconify-icon>
          Add New Office
        </button>
      </div>
    </div>

    <div class="">
      <div class="my-3">
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="overview-card p-3">
            <div class="filter-card-bg p-md-4 py-3">

              <div *ngIf="showErrorMessageTrigger">
                <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert">
                  <p class="m-0 p-0 d-flex align-items-start">
              <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                {{ getErrorMessage() | removeUnderscores }}
              </span>
                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                  </p>
                </div>
              </div>
              <div *ngIf="showSuccessMessageTrigger">
                <div class="alert-success alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show" role="alert">
                  <p class="m-0 p-0 d-flex align-items-start">
                <span>
                  <iconify-icon
                    class="iconify me-2"
                    icon="ep:info-filled"
                  ></iconify-icon>
                  {{ getSuccessMessage() | removeUnderscores }}
                </span>
                    <iconify-icon
                      class="iconify fs-4 ms-auto pointer"
                      icon="mdi:close"
                      data-bs-dismiss="alert">
                    </iconify-icon>
                  </p>
                </div>
              </div>
              <div class="container-fluid">
                <div [formGroup]="form" class="row g-3">
                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <input type="text" class="form-control" id="ptn" placeholder="e.g st.Marys"
                           formControlName="name">
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Name</label>
                  </div>
                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <input type="text" class="form-control" placeholder="e.g abc@gmail.com"
                           aria-label="Email Address" formControlName="email">
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.1rem;">Email Address</label>
                  </div>
                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <input type="text" class="form-control" placeholder="+1 (754) 343 094"
                           aria-label="Phone Number" formControlName="phoneNumber">
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Phone Number</label>
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0 " style="margin-top: -.6rem;">
                    <div class="">
                      <label class="form-label rfr-label"> Date (from) </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="MM-DD-YYYY" formControlName="startDate"
                               [minDate]="{ year: 1910, month: 1, day: 1 }"
                               [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                               ngbDatepicker #startDate="ngbDatepicker"
                               (click)="startDate.toggle()"/>
                        <span (click)="startDate.toggle()" class="btn-primary-outline input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0" style="
                margin-top: -.6rem;">
                    <div class="">
                      <label class="form-label rfr-label"> Date (to) </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="MM-DD-YYYY" formControlName="endDate"
                               [minDate]="{ year: 1910, month: 1, day: 1 }"
                               [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                               ngbDatepicker #endDate="ngbDatepicker"
                               (click)="endDate.toggle()"/>
                        <span (click)="endDate.toggle()" class="btn-primary-outline input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0  float-end" style="white-space: nowrap;">
                    <button
                      class="bg-primary border-0 d-flex justify-content-center align-items-center px-3 text-light float-end"
                      (click)="searchTempDentalOffice()" style=" margin-top: 3px; padding: .35rem;">
                      <iconify-icon inline icon="iconoir:search" class="me-2 fs-5" width="25"
                                    height="25"></iconify-icon>
                      Apply Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row mt-4">
          <div class="overview-card-table p-3">

            <div class="table-responsive">
              <table class="table">
                <thead class="">
                <tr class="overview-card shadow-sm ">
                  <th>ID</th>
                  <th class="">Office Name</th>
                  <th class="">Email Address</th>
                  <th class="" *ngIf="false">Phone Number</th>
                  <th class="">Fax Number</th>
                  <th class="">Address</th>
                  <th class="">Created At</th>
                  <th class="">Action</th>
                </tr>
                </thead>
                <tbody class="text-muted" *ngIf="!this.searching">
                <tr *ngFor="let result of this.resultSubject.value.results; let i = index;"
                    [id]="'action' + i">
                  <td [id]="'index' + i" class="align-middle fw-bold fs-6" data-label="ID">
                    {{ offset + i + 1 }}
                  </td>
                  <td class=" fw-bold align-middle" data-label="Office Name">
                    <p class="mb-0">{{ result?.name }}</p>
                  </td>
                  <td class="align-middle" data-label="Email Address">
                    <p class="mb-0 email-viewer">{{ (result?.email | lowercase) || 'N/A' }}</p>
                    <p class="mb-0 email-viewer" *ngIf="result?.alternateEmail">{{'Alt: '+ (result?.alternateEmail | lowercase) || 'N/A' }}</p>
                  </td>
                  <td *ngIf="false" class="align-middle" data-label="Phone Number">
                    <p class="mb-0">{{ (result?.phoneNumber | lowercase) || 'N/A' }}</p>
                  </td>
                  <td class="align-middle" data-label="Fax Number">
                    <p class="mb-0">{{ (result?.fax | lowercase) || 'N/A' }}</p>
                  </td>
                  <td class="align-middle" data-label="Address Details">
                    <p class="mb-0">{{ (result?.addressDetails | lowercase) || 'N/A' }}</p>
                  </td>
                  <td class="align-middle" data-label="Created At">
                    {{ (this.formatUTCMoment(result) | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes) }}
                  </td>

                  <td [id]="'action' + i" class="table-action-dropdown align-middle">
                    <span class="pointer" style="text-decoration: underline" data-bs-toggle="dropdown">Action</span>
                    <iconify-icon class="iconify pointer" data-icon="ri:more-2-line"></iconify-icon>
                    <ul class="dropdown-menu dropdown-menu-start pointer fs-6">

                      <li>
                        <button class="dropdown-item d-flex align-items-center" href="#"
                                (click)="createOrUpdate(result)">
                      <span>
                        <iconify-icon icon="tabler:edit" style="color: #369dbf;" width="25"
                                      height="25"></iconify-icon>
                      </span>
                          &nbsp; Edit
                        </button>
                      </li>
                      <li>
                        <button class="dropdown-item d-flex align-items-center"
                                (click)="deleteOffice(result.id)">
                          <iconify-icon icon="material-symbols:delete" style="color: #be0a1f;" width="25"
                                        height="25"></iconify-icon>
                          Delete
                        </button>
                      </li>

                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
                <span *ngIf="!this.resultSubject.value.results ||  this.resultSubject.value.total == 0"
                      class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
                    {{
                    this.resultSubject.value.results
                      ? "NO DATA/RECORDS"
                      : "APPLY FILTERS TO VIEW OFFICES"
                  }}</span>
            </div>
            <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3"
                 style="margin-bottom: -1rem; color: #4e3367" *ngIf="this.resultSubject.value.total > 5">
              <app-page-length [pageLength]="getFilter().limit || 10" (pageLengthChanged)="changeLimit($event)">
              </app-page-length>
              <div class="add-group-btn text-right mt-3 mb-3">
                <pagination (pageChanged)="onPageChange($event)" [boundaryLinks]="true" [itemsPerPage]="limit || 10"
                            [maxSize]="5" [totalItems]="this.resultSubject.value.total || 0"
                            firstText="&laquo;" lastText="&raquo;" nextText="&rsaquo;"
                            previousText="&lsaquo;"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
