/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Exemption } from './exemption';


export interface NewDayWorkHoursDto { 
    dayOfWeek: NewDayWorkHoursDto.DayOfWeekEnum;
    startTime: string;
    endTime: string;
    exemptions?: Array<Exemption>;
    validEndTime?: boolean;
}
export namespace NewDayWorkHoursDto {
    export type DayOfWeekEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    export const DayOfWeekEnum = {
        Monday: 'MONDAY' as DayOfWeekEnum,
        Tuesday: 'TUESDAY' as DayOfWeekEnum,
        Wednesday: 'WEDNESDAY' as DayOfWeekEnum,
        Thursday: 'THURSDAY' as DayOfWeekEnum,
        Friday: 'FRIDAY' as DayOfWeekEnum,
        Saturday: 'SATURDAY' as DayOfWeekEnum,
        Sunday: 'SUNDAY' as DayOfWeekEnum
    };
}


