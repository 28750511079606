<div class="my-4">
  <div class="px-0">
    <div class="row g-2">
      <div class="col-xl col-12">
        <div class="overview-card p-4 px-md-5">
          <div class="d-sm-flex align-items-center justify-content-between ">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="3.4em" height="2em" viewBox="0 0 512 214">
                <path fill="#02183D"
                      d="M512 110.08c0-36.409-17.636-65.138-51.342-65.138c-33.85 0-54.33 28.73-54.33 64.854c0 42.808 24.179 64.426 58.88 64.426c16.925 0 29.725-3.84 39.396-9.244v-28.445c-9.67 4.836-20.764 7.823-34.844 7.823c-13.796 0-26.027-4.836-27.591-21.618h69.547c0-1.85.284-9.245.284-12.658Zm-70.258-13.511c0-16.071 9.814-22.756 18.774-22.756c8.675 0 17.92 6.685 17.92 22.756h-36.694Zm-90.31-51.627c-13.939 0-22.899 6.542-27.876 11.094l-1.85-8.818h-31.288v165.83l35.555-7.537l.143-40.249c5.12 3.698 12.657 8.96 25.173 8.96c25.458 0 48.64-20.48 48.64-65.564c-.142-41.245-23.609-63.716-48.498-63.716Zm-8.534 97.991c-8.391 0-13.37-2.986-16.782-6.684l-.143-52.765c3.698-4.124 8.818-6.968 16.925-6.968c12.942 0 21.902 14.506 21.902 33.137c0 19.058-8.818 33.28-21.902 33.28ZM241.493 36.551l35.698-7.68V0l-35.698 7.538V36.55Zm0 10.809h35.698v124.444h-35.698V47.36Zm-38.257 10.524L200.96 47.36h-30.72v124.444h35.556V87.467c8.39-10.951 22.613-8.96 27.022-7.396V47.36c-4.551-1.707-21.191-4.836-29.582 10.524Zm-71.112-41.386l-34.702 7.395l-.142 113.92c0 21.05 15.787 36.551 36.836 36.551c11.662 0 20.195-2.133 24.888-4.693V140.8c-4.55 1.849-27.022 8.391-27.022-12.658V77.653h27.022V47.36h-27.022l.142-30.862ZM35.982 83.484c0-5.546 4.551-7.68 12.09-7.68c10.808 0 24.461 3.272 35.27 9.103V51.484c-11.804-4.693-23.466-6.542-35.27-6.542C19.2 44.942 0 60.018 0 85.192c0 39.252 54.044 32.995 54.044 49.92c0 6.541-5.688 8.675-13.653 8.675c-11.804 0-26.88-4.836-38.827-11.378v33.849c13.227 5.689 26.596 8.106 38.827 8.106c29.582 0 49.92-14.648 49.92-40.106c-.142-42.382-54.329-34.845-54.329-50.774Z"/>
              </svg>
            </div>
            <div class="d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" class="d-block" viewBox="0 0 24 24">
                <path fill="#02183D"
                      d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z"/>
              </svg>
              <p class="d-block mt-3 ps-1">Secure payment</p>
            </div>
          </div>
          <div *ngIf="showErrorMessageTrigger">
            <div class="alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show d-flex justify-content-between"
                 role="alert" [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                            <span>
                 <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
               </span>
              <span class="m-0 p-0 d-flex align-items-start" [innerText]="getErrorMessage()">
              </span>
              <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
              </iconify-icon>
            </div>
          </div>

          <p class="d-block mt-4">Transfer U.S. Dollar to:</p>

          <!-- card on large screen -->
          <div class="row">
            <div class="col-lg-12  mb-3">
              <div class="bank-card rounded-2 p-2 px-4 d-lg-block d-none">
                <div class="row">
                  <div class="col">
                    <p>Bank Name</p>
                  </div>
                  <div class="col">
                    <p class="">Account Name</p>
                  </div>
                  <div class="col">
                    <p>Account Number</p>
                  </div>
                  <div class="col">
                    <p>Routing Number</p>
                  </div>
                  <div class="col">
                    <p class="position-relative">Account Verification Status <span
                      class="ms-1 border-0 rounded-5 info text-light float-right"
                      *ngIf="affiliateBank?.bankVerificationStatusConstant == bankVerificationStatusConstantEnum.Failed"
                      (click)="showMoreInfo(affiliateBank)" title="More info">i</span></p>
                  </div>
                  <div class="col">
                    <p>KYC Status</p>
                  </div>
                </div>
                <div class="row d-flex align-items-center bank-card-bottom rounded-1 pt-2">
                  <div class="col" *ngIf="affiliateBank?.bank">
                    <p>
                      <img *ngIf="affiliateBank?.bank" src="{{buildBankUrl(affiliateBank?.bank?.imageUrl)}}"
                           class="bank-logo" alt="">
                    </p>
                  </div>
                  <div class="col" *ngIf="!affiliateBank?.bank">
                    <p>
                      {{affiliateBank?.bankName || 'N/A'}}
                    </p>
                  </div>
                  <div class="col">
                    <p class="">{{affiliateBank?.accountName || 'N/A'}}</p>
                  </div>
                  <div class="col">
                    <p>{{affiliateBank?.maskedAccountNumber || 'N/A'}}</p>
                  </div>
                  <div class="col">
                    <p>{{affiliateBank?.routingNumber || 'N/A'}}</p>
                  </div>
                  <div class="col">
                    <p>{{affiliateBank?.bankVerificationStatusConstant || 'N/A'}}</p>
                  </div>
                  <div class="col">
                    <p>{{affiliateBank?.bankKycStatusConstant || 'N/A'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- card on large screen -->

          <!-- card on small screen -->
          <div class="bank-card rounded-2 p-2 px-4 d-lg-none d-sm-block d-none mb-3">
            <div class="row">
              <div class="col-md-6 col p-2">
                <p>Bank Name</p>
                <p>Account Name:</p>
                <p>Account Number:</p>
                <p>Routing Number:</p>
                <p class="position-relative">Account Verification Status: <span
                  class="ms-1 border-0 rounded-5 info text-light float-right"
                  *ngIf="affiliateBank?.bankVerificationStatusConstant == bankVerificationStatusConstantEnum.Failed"
                  (click)="showMoreInfo(affiliateBank)" title="More info">i</span></p>
                <p>KYC Status:</p>
              </div>
              <div class="col-md-6 col bank-card-bottom rounded-1 p-2">
                <p class="d-block" *ngIf="affiliateBank?.bank">
                  <img src="{{buildBankUrl(affiliateBank?.bank?.imageUrl)}}" class="bank-logo" alt="">
                </p>
                <p class="d-block" *ngIf="!affiliateBank?.bank">
                  {{affiliateBank?.bankName || 'N/A'}}
                </p>
                <p class=" text-truncate fw-bold">{{affiliateBank?.accountName || 'N/A'}}</p>
                <p class=" text-truncate fw-bold">{{affiliateBank?.maskedAccountNumber || 'N/A'}}</p>
                <p class=" text-truncate fw-bold">{{affiliateBank?.routingNumber || 'N/A'}}</p>
                <p class=" text-truncate fw-bold">{{affiliateBank?.bankVerificationStatusConstant || 'N/A'}}</p>
                <p class=" text-truncate fw-bold">{{affiliateBank?.bankKycStatusConstant || 'N/A' | removeUnderscores}}</p>
              </div>
            </div>

          </div>
          <!-- card on small screen -->

          <!-- card on smaller screen -->
          <div class="bank-card rounded-2 p-2 px-4 d-sm-none d-block mb-3">
            <div class="row">
              <div class="col-md-6 col p-2 card border-0">
                <p class="mb-0">Bank Name:</p>
                <small><p class="d-block" *ngIf="affiliateBank?.bank">
                  <img src="{{buildBankUrl(affiliateBank?.bank?.imageUrl)}}" class="bank-logo" alt="">
                </p>
                  <p class="d-block" *ngIf="!affiliateBank?.bank">
                    {{affiliateBank?.bankName || 'N/A'}}
                  </p></small>
                <p class="mb-0">Account Name:</p>
                <small><p class=" text-truncate fw-bold">{{affiliateBank?.accountName || 'N/A'}}</p></small>

                <p class="mb-0">Account Number:</p>
                <small><p class=" text-truncate fw-bold">{{affiliateBank?.maskedAccountNumber || 'N/A'}}</p></small>
                <p class="mb-0">Routing Number:</p>
                <small><p class=" text-truncate fw-bold">{{affiliateBank?.routingNumber || 'N/A'}}</p></small>
                <p class="mb-0 position-relative">Account verification status: <span
                  class="ms-1 border-0 rounded-5 info text-light float-right" title="More info"
                  *ngIf="affiliateBank?.bankVerificationStatusConstant == bankVerificationStatusConstantEnum.Failed"
                  (click)="showMoreInfo(affiliateBank)">i</span></p>
                <small><p class=" text-truncate fw-bold">{{affiliateBank?.bankVerificationStatusConstant || 'N/A'}}</p>
                </small>
                <p class="mb-0 position-relative">KYC Status:</p>
                <small><p class=" text-truncate fw-bold">{{affiliateBank?.bankKycStatusConstant || 'N/A' | removeUnderscores}}</p></small>
              </div>
            </div>
          </div>
          <!-- card on smaller screen -->
          <div class="d-flex justify-content-between">

            <button (click)="affiliateBank?.bankVerificationStatusConstant == bankVerificationStatusConstantEnum.Successful
                      && affiliateBank?.bankKycStatusConstant == bankKycStatusConstantEnum.Successful ? initiatePayout(affiliateBank) :
                          showMessageBasedOnStatus(affiliateBank) "
                    class="btn btn-primary rounded-1 px-4 py-3 col-md-auto col-12"
                    name="button">
              Initiate Payout
            </button>
          </div>
          <div
            *ngIf="affiliateBank?.bankVerificationStatusConstant == bankVerificationStatusConstantEnum.Successful && affiliateBank?.bankKycStatusConstant != bankKycStatusConstantEnum.Successful">
            <hr class="hr">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-baseline">
                <div class="d-block pe-3">
                  <button (click)="initiateCustomerKyc()" class="btn btn-outline-primary rounded-1 px-4 py-2"
                          name="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
                      <path fill="currentColor"
                            d="M2.727 3.333c-.753 0-1.363.597-1.363 1.334v10.666c0 .737.61 1.334 1.363 1.334h14.546c.753 0 1.363-.597 1.363-1.334V4.667c0-.737-.61-1.334-1.363-1.334H2.727ZM17.273 2C18.779 2 20 3.194 20 4.667v10.666C20 16.806 18.779 18 17.273 18H2.727C1.221 18 0 16.806 0 15.333V4.667C0 3.194 1.221 2 2.727 2h14.546ZM8.167 13.133H4a.674.674 0 0 0-.682.667c0 .368.305.667.682.667h4.167a.674.674 0 0 0 .681-.667a.674.674 0 0 0-.681-.667Zm5.583-7.8c-1.524 0-2.765 1.191-2.765 2.667c0 .773.34 1.468.884 1.955c-1.03.608-1.717 1.703-1.717 2.954c0 .357.056.708.165 1.043c.115.35.499.544.857.432a.664.664 0 0 0 .442-.838a2.043 2.043 0 0 1-.1-.637c0-1.175.997-2.133 2.234-2.133s2.235.958 2.235 2.133c0 .173-.021.342-.063.506a.666.666 0 0 0 .497.808a.683.683 0 0 0 .826-.486c.069-.27.103-.547.103-.828c0-1.251-.687-2.346-1.717-2.956c.544-.485.884-1.18.884-1.953c0-1.476-1.24-2.667-2.765-2.667Zm-5.583 4.3H4a.674.674 0 0 0-.682.667c0 .368.305.667.682.667h4.167a.674.674 0 0 0 .681-.667a.674.674 0 0 0-.681-.667Zm5.583-2.966c.777 0 1.402.6 1.402 1.333c0 .734-.625 1.333-1.402 1.333c-.777 0-1.402-.6-1.402-1.333c0-.734.625-1.333 1.402-1.333Zm-5.583-.534H4a.674.674 0 0 0-.682.667c0 .368.305.667.682.667h4.167a.674.674 0 0 0 .681-.667a.674.674 0 0 0-.681-.667Z"/>
                    </svg>
                  </button>
                </div>
                <div class="d-block mt-3 fw-bold text-primary pointer" (click)="initiateCustomerKyc()">
                  <p>{{this.affiliateBank?.bankKycStatusConstant ? 'Update' : 'Add'}} KYC Details</p>
                </div>
              </div>
            </div>
            <hr class="hr">
          </div>
          <p class="d-block mt-5">Don't want to transfer to this account?</p>
          <hr class="hr">
          <div class="d-flex align-items-center">
            <div class="d-block pe-3">
              <button (click)="addBankAccount()" class="btn btn-outline-primary rounded-1 px-4 py-2" name="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                     viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575q.837 0 1.412.575l1.4 1.4q.575.575.6 1.388q.025.812-.55 1.387ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6Zm-3.525-.725l-.7-.7l1.4 1.4Z"/>
                </svg>
              </button>
            </div>
            <div class="d-block mt-3 fw-bold text-primary pointer" (click)="addBankAccount()">
              <p>{{this.affiliateBank?.bankVerificationStatusConstant == bankVerificationStatusConstantEnum.Successful ? 'Update' : 'Add'}}
                Bank Account</p>
            </div>
          </div>
          <hr class="hr">
          <p class="d-block mt-5 small text-muted">All withdrawals are subject to review and could be delayed or stopped
            if we or your bank identify an issue. </p>
        </div>

      </div>


    </div>
  </div>
</div>
