import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { ResourceVerificationControllerService } from 'dd-core-api-sdk';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UniqueIdValidatorService {

  constructor(private verifyService: ResourceVerificationControllerService) { }

  verifier(type: 'email' | 'mobile_number' | 'license' | 'npi', errorName?: string): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      if (!control.value) {
        return of(null);
      }

      return this.verify(type, control.value, errorName);
    };
  }

  phoneNumberVerifier(errorName = 'phoneNumberExists'): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      if (!control.value) {
        return of(null);
      }

      return this.verify('mobile_number', control.value.internationalNumber.replace(/ /g, ''), errorName);
    };
  }

  private verify(type: 'email' | 'mobile_number' | 'license' | 'npi', value: string, errorName: string): Observable<ValidationErrors> {
    return this.verifyService
      .verifyResource({ identifier: value, type })
      .pipe(map(users => null))
      .pipe(catchError((err: HttpErrorResponse, caught) => {
        const val = {};
        val[errorName || 'invalid'] = true;
        return of(val);
      }));
  }

}
