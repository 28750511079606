/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Specialization } from './specialization';
import { DentalOffice } from './dental-office';
import { DentalProfessional } from './dental-professional';
import { PortalUser } from './portal-user';


export interface DentalOfficeProfessional { 
    status?: DentalOfficeProfessional.StatusEnum;
    dateCreated?: string;
    dateDeactivated?: string;
    email?: string;
    phoneNumber?: string;
    emailVerified?: boolean;
    phoneNumberVerified?: boolean;
    fhirProviderId?: string;
    id?: number;
    dentalProfessional?: DentalProfessional;
    dentalOffice?: DentalOffice;
    deactivatedBy?: PortalUser;
    specialty?: Specialization;
}
export namespace DentalOfficeProfessional {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


