/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HelpSearchFilter { 
    email?: string;
    helpStatusConstant?: HelpSearchFilter.HelpStatusConstantEnum;
    resolved?: boolean;
    startDate?: string;
    endDate?: string;
    limit?: number;
    offset?: number;
}
export namespace HelpSearchFilter {
    export type HelpStatusConstantEnum = 'CLOSED' | 'OPEN' | 'PENDING';
    export const HelpStatusConstantEnum = {
        Closed: 'CLOSED' as HelpStatusConstantEnum,
        Open: 'OPEN' as HelpStatusConstantEnum,
        Pending: 'PENDING' as HelpStatusConstantEnum
    };
}


