/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coupon } from './coupon';
import { PromotionDuration } from './promotion-duration';
import { PromotionType } from './promotion-type';
import { DentalOffice } from './dental-office';
import { DentalPlan } from './dental-plan';
import { Deal } from './deal';
import { PortalUser } from './portal-user';


export interface Promotion { 
    status?: Promotion.StatusEnum;
    dateCreated?: string;
    headline?: string;
    description?: string;
    termsOfUse?: string;
    maxUsageCount?: number;
    value?: number;
    lastUpdated?: string;
    mediaDisplayPreference?: Promotion.MediaDisplayPreferenceEnum;
    expired?: boolean;
    enforceUrgency?: boolean;
    maxUsagePerUser?: number;
    id?: number;
    dentalOffice?: DentalOffice;
    createdBy?: PortalUser;
    promotionType?: PromotionType;
    deal?: Deal;
    coupon?: Coupon;
    promotionDuration?: PromotionDuration;
    dentalPlan?: DentalPlan;
    deactivatedBy?: PortalUser;
}
export namespace Promotion {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type MediaDisplayPreferenceEnum = 'IMAGE' | 'VIDEO';
    export const MediaDisplayPreferenceEnum = {
        Image: 'IMAGE' as MediaDisplayPreferenceEnum,
        Video: 'VIDEO' as MediaDisplayPreferenceEnum
    };
}


