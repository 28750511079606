import {Injectable} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Subject} from 'rxjs';
import {DeviceControllerService, DeviceDto, WebNotificationControllerService} from "dd-core-api-sdk";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {ClientJS} from 'clientjs';
import {SwPush} from "@angular/service-worker";
import {environment} from "../../environments/environment";
import {onMessage} from "@angular/fire/messaging";
import {getMessaging, getToken} from "firebase/messaging";
import {takeUntil} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    message: any = null;
  private unsubscribe$ = new Subject<void>();

    constructor(
        private deviceService: DeviceControllerService,
        private afMessaging: AngularFireMessaging
    ) {}

    requestPermission() {
        this.afMessaging.requestToken.subscribe({
            next: (currentToken) => {
                if (currentToken) {
                    console.log("We got the token :", currentToken);
                    // save token to db
                    this.registerUserAndDevice(currentToken);
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            }, error: (err: unknown) => {
                console.log('An error occurred while retrieving token. ', err);
            }
        });
    }

    registerUserAndDevice(currentToken: string): void {
        const client = new ClientJS();

        const deviceDto: DeviceDto = {
            firebaseToken: currentToken,
            deviceId: client.getFingerprint(),
            deviceName: client.getDevice(),
            brandName: client.getDeviceType(),
            modelName: client.getDeviceVendor(),
            operatingSystem: client.getOS(),
            operatingSystemVersion: client.getOSVersion()
        }

        this.deviceService.createDevice({deviceDto})
            .subscribe(res => {
                console.log("credentials saved to db ", deviceDto)
            });

    }

}
//
