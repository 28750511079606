import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {WizardComponent} from "angular-archwizard";

@Component({
  selector: 'app-account-linking-wizard',
  templateUrl: './account-linking-wizard.component.html',
  styleUrls: ['./account-linking-wizard.component.css']
})
export class AccountLinkingWizardComponent implements OnInit {

  @ViewChild(WizardComponent)
  public wizard!: WizardComponent;

  @Output()
  dismissClicked = new EventEmitter<any>();

  @Output()
  submitButtonClickedSuccess = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  moveToForm($event) {
    this.wizard.goToNextStep();
  }

  emitDismissClicked($event){
    this.dismissClicked.emit();
  }
  emmitSuccess($event){
    this.submitButtonClickedSuccess.emit();
  }
}
