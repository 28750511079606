/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserPojo } from './user-pojo';
import { DentalProfessionalPojo } from './dental-professional-pojo';
import { DentalOfficePojo } from './dental-office-pojo';


export interface InvitationPojo { 
    name?: string;
    email?: string;
    phoneNumber?: string;
    type?: InvitationPojo.TypeEnum;
    dentalOfficeName?: string;
    invitedPatient?: UserPojo;
    invitedUser?: DentalProfessionalPojo;
    dateCreated?: string;
    notificationSent?: boolean;
    dateNotified?: string;
    isExistingUser?: boolean;
    lastUpdated?: string;
    invitationStatus?: InvitationPojo.InvitationStatusEnum;
    id?: number;
    dentalOffice?: DentalOfficePojo;
    fax?: string;
}
export namespace InvitationPojo {
    export type TypeEnum = 'DENTAL_PRACTITIONER' | 'USER' | 'DENTAL_OFFICE_ADMIN' | 'DENTAL_OFFICE';
    export const TypeEnum = {
        DentalPractitioner: 'DENTAL_PRACTITIONER' as TypeEnum,
        User: 'USER' as TypeEnum,
        DentalOfficeAdmin: 'DENTAL_OFFICE_ADMIN' as TypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as TypeEnum
    };
    export type InvitationStatusEnum = 'PENDING' | 'ACCEPTED' | 'DECLINED';
    export const InvitationStatusEnum = {
        Pending: 'PENDING' as InvitationStatusEnum,
        Accepted: 'ACCEPTED' as InvitationStatusEnum,
        Declined: 'DECLINED' as InvitationStatusEnum
    };
}


