import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-account-linking-conditions',
  templateUrl: './account-linking-conditions.component.html',
  styleUrls: ['./account-linking-conditions.component.css']
})
export class AccountLinkingConditionsComponent implements OnInit {

  @Output()
  dismissButtonClicked = new EventEmitter<any>();
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToPolicyPage() {
    this.dismissButtonClicked.emit();
  }
}
