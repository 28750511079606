<div class="modal-content rounded-4 shadow">
  <div class="modal-header p-5 pb-4 border-bottom-0">
    <!-- <h1 class="modal-title fs-5" >Modal title</h1> -->
    <!--    <h1 class="fw-bold mb-0 fs-2">Sign up for free</h1>-->
    <button type="button" class="btn-close pointer" data-bs-dismiss="modal" aria-label="Close"
      (click)="close()"></button>
  </div>
  <div class="modal-body p-3 pt-0">
    <form class="">
      <!--Appointment Section-->
      <section>
        <div class="container">
          <div class="sec-title">
            <h2 class="title">Do You Want a Custom Plan ?</h2>
            <h2 class="text-primary">Let's Talk</h2>
          </div>
          <div class="row clearfix">
            <!--Form Column-->
            <div class="form-column col-lg-8">
              <div class="inner-column">
                <div *ngIf="showErrorMessageTrigger">
                  <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show" role="alert"
                    [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                    <p class="m-0 p-0 d-flex align-items-start">
                      <span>
                        <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                        {{ getErrorMessage() | removeUnderscores }}
                      </span>
                      <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                      </iconify-icon>
                    </p>
                  </div>
                </div>
                <!-- Default Form -->
                <div class="default-form row">
                  <!--Contact Form-->
                  <form [formGroup]="this.formGroup" class="col">
                    <div class="">
                      <div class="row clearfix g-3">
                        <div class="form-group col-lg-6">
                          <div class="form-floating">
                            <input type="text" class="form-control form-control-lg" id="firstName"
                              placeholder="Name" aria-label="Full Name" formControlName="name" />
                            <label for="firstName" class="required">Name</label>
                          </div>
                          <app-field-error *ngIf="
                          (formGroup.get('name')?.errors
                            ?.required &&
                            formGroup.get('name')?.touched) ||
                          (formGroup.get('name')?.errors
                            ?.required &&
                            errorSubmission)" [message]="'First name cannot be blank.'">
                          </app-field-error>
                          <app-field-error *ngIf="(formGroup.get('name')?.errors?.maxlength &&
                        formGroup.get('name')?.touched) ||
                      (formGroup.get('name')?.errors?.maxlength &&
                        errorSubmission)" [message]="'Name cannot be more than fifty(50) characters.'">
                          </app-field-error>
                          <app-field-error *ngIf="(formGroup.get('name')?.errors?.minlength &&
                        formGroup.get('name')?.touched) ||
                      (formGroup.get('name')?.errors?.minlength &&
                        errorSubmission)" [message]="'Name cannot be less than two(2) characters.'"></app-field-error>
                        </div>
                        <div class="form-group col-lg-6">
                          <div class="form-floating">
                            <input type="text" class="form-control form-control-lg" id="email"
                              placeholder="Email Address" aria-label="Email Address" formControlName="email" />
                            <label for="email" class="required">Email Address</label>
                          </div>
                          <app-field-error *ngIf="
                          (formGroup.get('email')?.errors
                            ?.required &&
                            formGroup.get('email')?.touched) ||
                          (formGroup.get('email')?.errors
                            ?.required &&
                            errorSubmission)" [message]="'Email cannot be blank.'">
                          </app-field-error>
                          <app-field-error *ngIf="(formGroup.get('email')?.errors?.maxlength &&
                        formGroup.get('email')?.touched) ||
                      (formGroup.get('email')?.errors?.maxlength &&
                        errorSubmission)" [message]="'Email cannot be more than fifty(50) characters.'">
                          </app-field-error>
                          <app-field-error *ngIf="(formGroup.get('email')?.errors?.minlength &&
                        formGroup.get('email')?.touched) ||
                      (formGroup.get('email')?.errors?.minlength &&
                        errorSubmission)" [message]="'Email cannot be more than two(2) characters.'"></app-field-error>
                        </div>
                        <div class="col-lg-6 mt-0">
                          <div class="form-group">
                            <label for="phoneNumber" class="rfr-label required">Phone Number</label>
                            <ngx-intl-tel-input [cssClass]="'form-control form-control-lg'"
                              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [id]="'phoneNumber'"
                              [maxLength]="'20'" [phoneValidation]="true" [preferredCountries]="preferredCountries"
                              [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name]" [searchCountryFlag]="true" [selectedCountryISO]="countryISO"
                              [selectFirstCountry]="true" [numberFormat]="PhoneNumberFormat.National"
                              formControlName="phoneNumber" id="phoneNumber">
                            </ngx-intl-tel-input>
                            <app-field-error *ngIf="
                            (formGroup.get('phoneNumber')?.errors?.required &&
                              formGroup.get('phoneNumber')?.touched) ||
                            (formGroup.get('phoneNumber')?.errors?.required && errorSubmission)"
                              [message]="'Phone number cannot be blank.'"></app-field-error>
                            <app-field-error class="mt-1" [message]="'Phone number is invalid.'" *ngIf="
                            formGroup.get('phoneNumber')!.errors?.validatePhoneNumber"></app-field-error>
                          </div>
                        </div>
                        <div class="from-group col-lg-6 mt-md-4">
                          <div class="form-floating">
                            <input [owlDateTime]="dt2" placeholder="Select Appointment Time"
                              class="form-control form-control-lg" id="appointment" [owlDateTimeTrigger]="dt2"
                              formControlName="appointmentDate" readonly />
                            <label for="appointment" class="form-label" style="
                                font-size: 14px !important;
                                overflow: inherit;
                            ">Appointment Date</label>
                            <owl-date-time #dt2 [hour12Timer]="true"></owl-date-time>
                          </div>
                          <app-field-error
                            *ngIf="(formGroup.get('appointmentDate')?.errors?.required && formGroup.get('appointmentDate')?.touched) || (formGroup.get('appointmentDate').errors?.required && errorSubmission)"
                            [message]="'Appointment date cannot be blank.'"></app-field-error>
                        </div>
                        <div class="form-group col-12 mb-5 mt-4">
                          <div class="form-floating">
                            <textarea class="form-control form-control-lg" formControlName="note" id="floatingTextarea"
                              rows="2" maxlength="500" placeholder="Notes [max 500]"></textarea>
                            <label for="floatingTextarea">Notes [Max 500]</label>
                          </div>
                          <app-field-error
                            *ngIf="(formGroup.get('note')?.errors?.required && formGroup.get('note')?.touched) || (formGroup.get('note').errors?.required && errorSubmission)"
                            [message]="'Notes cannot be blank.'"></app-field-error>
                          <app-field-error *ngIf="(formGroup.get('note')?.errors?.maxlength &&
                        formGroup.get('note')?.touched) ||
                      (formGroup.get('note')?.errors?.maxlength &&
                        errorSubmission)" [message]="'Notes cannot be more than 500 characters.'"></app-field-error>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <button class="theme-btn appointment-btn" type="submit" name="submit-form"
                          (click)="scheduleCall()" [disabled]="this.formGroup.untouched"> Scheduled A Call <iconify-icon
                            *ngIf="loading" icon="eos-icons:bubble-loading" style="color: #ffffff">
                          </iconify-icon>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <!--End Default Form -->
              </div>
            </div>
            <!--Form Column-->
            <div class="schedule-column col-lg-4">
              <div class="outer-column-bh">
                <div class="inner-column-bh">
                  <h2 class="text-primary">Business Hours:</h2>
                  <ul class="time-list">
                    <li>Monday - Friday 9.00 AM - 5.00 PM</li>
                    <li>Saturday 9.00 AM - 3.00 PM</li>
                    <li><strong>Sunday Closed</strong></li>
                    <li class="closed">sales@dentaldoor.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</div>
