/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Specialization { 
    name?: string;
    code?: string;
    status?: Specialization.StatusEnum;
    type?: Specialization.TypeEnum;
    id?: number;
}
export namespace Specialization {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type TypeEnum = 'DENTAL_PROFESSIONAL' | 'STUDENT' | 'DENTAL_OFFICE';
    export const TypeEnum = {
        DentalProfessional: 'DENTAL_PROFESSIONAL' as TypeEnum,
        Student: 'STUDENT' as TypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as TypeEnum
    };
}


