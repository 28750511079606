import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {interval, Subject} from "rxjs";
import {take} from "rxjs/operators";
import {UserRegistrationService} from "dd-core-api-sdk";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {SocialService} from "../../shared/subject/SocialService";
import {UserKeycloak} from "../../services/UserKeycloak";

@Component({
  selector: 'app-one-time-password',
  templateUrl: './one-time-password.component.html',
  styleUrls: ['./one-time-password.component.css']
})
export class OneTimePasswordComponent implements OnInit {

  form!: FormGroup;
  stop$ = new Subject();
  gettingOtp = false;
  verifyingOtp = false;
  otpEmail: string;
  errMessage = '';
  successMessage = '';
  showErrorMessageTrigger = false;
  showSuccessMessageTrigger = false;
  intervalStart: boolean;
  interval: number;
  otpCountDown = 30;
  phoneNumber: string;
  @ViewChild('otpInput') otpInputRef: any;
  toDashboard: boolean = false;
  prevUrl: String;
  user: UserKeycloak;
  otpPhone: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationServiceKeycloak,
    private socialService: SocialService,
    private userRegistrationService: UserRegistrationService) {

    this.socialService.isInterceptor.next(true);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      otp: ['', [Validators.required, Validators.maxLength(10)]]
    });

    this.authenticationService.fetchUser().subscribe(res => {
      this.socialService.isInterceptor.next(false);
      this.user = res;
      const requested = sessionStorage.getItem("REQUESTED_OTP") || '';

      if (!requested && this.socialService.otpSubject.getValue() != 'TRUE') {
        this.userRegistrationService.requestMobileNumberVerification({
          email: res.email,
          phoneNumber: res.mobileNumber,
          deliveryMode: 'PHONE_CALL'
        }).subscribe(res => {
          sessionStorage.setItem("REQUESTED_OTP", "TRUE");
        })
      }
      this.otpEmail = this.user?.email;
      this.otpPhone = this.user?.mobileNumber || null;
    })
  }

  startCountDown() {
    interval(1000).pipe(take(30)).subscribe(r => {
      this.intervalStart = true;
      this.otpCountDown--;
      if (this.otpCountDown === 0) {
        this.intervalStart = false;
        this.otpCountDown = 30;
      }
    });
  }

  getSuccessMessage() {
    return this.successMessage;
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showSuccessMessage(success: any) {
    this.successMessage = success;
    this.showSuccessMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showSuccessMessageTrigger = false;
    }, 6000);
  }

  validateOTP() {
    if (!this.form.valid) {
      return this.showErrorMessage("Please enter a valid OTP to proceed");
    }
    if (this.verifyingOtp) {
      return;
    }

    this.verifyingOtp = true;
    this.userRegistrationService.validateOTP({
      email: this.otpEmail,
      phoneNumber: this.otpPhone,
      pin: this.form.controls.otp.value.trim(),
      otpRequestTypeConstant: "USER_REGISTRATION"
    }).subscribe(res => {
      this.createAnchorTag();
      this.socialService.isInterceptor.next(true);
      this.socialService.otpSubject.next("VERIFIED");
    }, err => {
      this.otpInputRef.setValue('')
      if (err.error && err.error.message) {
        this.errMessage = err.error.message
        this.showErrorMessage(err.error.message);
      } else {
        this.errMessage = '';
        this.showErrorMessage('Unable to send OTP, Please check your network and try again');
      }
    }).add(() => {
      this.verifyingOtp = false;
    });
  }

  createAnchorTag() {
    var a = document.createElement('a');
    a.href = "/dashboard";
    document.body.appendChild(a);
    a.click();
  }

  getOtp() {
    if (this.gettingOtp) {
      return;
    }
    this.otpInputRef.setValue('')
    this.gettingOtp = true;
    this.userRegistrationService.requestMobileNumberVerification({
      email: this.otpEmail,
      phoneNumber: this.otpPhone,
      deliveryMode: 'PHONE_CALL'
    }).subscribe(res => {
      let maskedEmail = this.otpEmail?.slice(4);
      this.showSuccessMessage("OTP has been sent to the email and phone number you provided");
      this.startCountDown();
    }, err => {
      if (err.error && err.error.message) {
        this.errMessage = err.error.message
        this.showErrorMessage(err.error.message);
        if (err.error.code == 403 || err.error.code === 403) {
          window.location.reload();
        }
      } else {
        this.errMessage = '';
        this.showErrorMessage('Unable to send OTP, Please check your network and try again');
      }
    }).add(() => {
      this.gettingOtp = false;
    });
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  onOtpChange($event: string) {
    if ($event.length == 6) {
      this.form.patchValue({otp: $event});
    } else {
      this.form.reset();
    }
  }
}
