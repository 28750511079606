import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  DentalOfficeControllerService,
  DentalOfficeProfessionalControllerService,
  DentalOfficeProfilePojo,
  DentalOfficeProfileUpdateDto,
  DentalServiceProvided,
  Specialization,
  PortalUser,
  CreateOfficeMemberDto,
  OfficeMembersFilterHandler,
  PortalAccountMemberRole,
  DentalReferralControllerService,
  DentalOfficeImageControllerService,
  InsuranceCompanyControllerService,
  InsuranceCompany
} from "dd-core-api-sdk";
import {HttpErrorResponse} from "@angular/common/http";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {PhoneNumberValidator} from "dd-shared-lib";
import {Utils} from "../../../shared/utils";
import UserTypeEnum = PortalUser.UserTypeEnum;
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {BehaviorSubject} from "rxjs";
import {SuccessModalComponent} from "../../../shared/success-modal/success-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";

type tab = 'overview' | 'profile' | 'admin' | 'forgotPassword'

@Component({
  selector: 'app-office-profile',
  templateUrl: './office-profile.component.html',
  styleUrls: ['./office-profile.component.css']
})
export class OfficeProfileComponent implements OnInit {

  officeInfoForm: FormGroup;
  adminForm: FormGroup;

  offset = 0;
  messageType: 'successful' | 'error';
  message: string;
  errorSubmission: boolean;
  addMemberSuccess = false;
  tabSwitch: tab = 'admin';

  loading: boolean;
  updateLoading: boolean;
  profileUpdated: boolean;
  accountId: number;
  office: DentalOfficeProfilePojo;
  officeSpecializations: Array<Specialization>;
  servicesProvides: Array<DentalServiceProvided>;
  insuranceProvides: Array<InsuranceCompany>;
  members: Array<PortalUser>;
  membersRoles: Array<PortalAccountMemberRole>
  specialityCount = {};

  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  phoneNumber: string;
  membershipType: Array<String>;
  errMessage = '';
  successMessage = '';
  showErrorMessageTrigger = false;
  isError: boolean;

  profession = [
    'Oral and Maxillofacial Surgery',
    'Periodontics',
    'Endodontics',
    'Prosthodontics',
    'Orthodontics',
    'Oral and Maxillofacial Pathology',
    'Pedodontics',
    'Physician Office'
  ];
  specializationCode: string[];
  dentalServicesOptions: string[];
  insuranceCompanyOptions: string[];
  showImageErrorTrigger: boolean;
  imageErrorMessage: any;
  showPasswordTrigger: boolean;
  passwordErrorMessage: any;
  private officeId: number;
  uploadingImage: boolean;
  pictureId = new BehaviorSubject<any>({id: ''});
  insuranceCode: string[];

  constructor(
    private authenticationService: AuthenticationServiceKeycloak,
    private dentalOfficeService: DentalOfficeControllerService,
    private dentalProfessionalService: DentalOfficeProfessionalControllerService,
    private dentalReferralControllerService: DentalReferralControllerService,
    private insuranceCompanyController: InsuranceCompanyControllerService,
    private changeDetectorRef: ChangeDetectorRef,
    private dentalOfficeImageController: DentalOfficeImageControllerService,
    private _activeRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.membershipType = Utils.enumValues(UserTypeEnum)
      .filter(type => type != "PATIENT" && type != "STUDENT")
      .map(type => type)
  }

  ngOnInit(): void {
    this.loading = true
    this._activeRoute.queryParams.subscribe(params => {
      this.tabSwitch = params.action ? 'admin' : 'overview'
    })
    this.authenticationService.getUser().subscribe(user => {
      const id = AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id;
      if (id) {
        this.getOfficeProfile(id);
        this.getOfficeServices(id);
        this.getOfficeInsurance(id);
        this.accountId = AuthenticationServiceKeycloak._currentUserAccount?.accountId;
        this.getMembers(this.accountId)
        this.getMembersWithRoles(this.accountId);
      }
    })

    this.officeId = AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id;

    if (AuthenticationServiceKeycloak._currentUserAccount?.otherData?.displayPictureId) {
      this.pictureId.next({id: String(AuthenticationServiceKeycloak._currentUserAccount.otherData.displayPictureId)});
    }

    this.initOfficeInfoForm();
    this.initAdminForm()
    this.getListOfInsuranceCompanies();
  }

  initOfficeInfoForm() {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    this.officeInfoForm = this._fb.group({
      officeName: [''],
      email: [''],
      aboutUrl: ['', [Validators.pattern(urlRegex)]],
      phoneNumber: ['', [Validators.required]],
      website: ['', [Validators.pattern(urlRegex)]],
      fax: [''],
      officeAddress: [''],
      dentalServices: [''],
      officeType: [''],
      insuranceService: [''],
      summary:['']
    });

    this.officeInfoForm.get('officeName').disable({onlySelf: true});
    this.officeInfoForm.get('email').disable({onlySelf: true});
    this.officeInfoForm.get('officeAddress').disable({onlySelf: true});
  }

  initAdminForm() {
    this.adminForm = this._fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneNumber: ['', [Validators.required, PhoneNumberValidator()]],
      membershipType: ['', [Validators.required]]
    });
  }

  getOfficeProfile(id: number) {
    this.dentalOfficeService.getOfficeProfile({officeId: id})
      .subscribe((v: DentalOfficeProfilePojo) => {
        this.office = v;
        this.officeSpecializations = v.officeSpecialization;
        this.loadProfileFormData(v)
        this.loading = false;
      }, (e: HttpErrorResponse) => {
      });
  }

  getOfficeServices(id: number) {
    this.dentalOfficeService
      .getProvidedServices({officeId: id})
      .subscribe(res => {
        this.servicesProvides = res;
        this.loadServices(res);
      })
  }

  getOfficeInsurance(id: number) {
    this.dentalOfficeService
      .getInsuranceServices({officeId: id})
      .subscribe(res => {
        this.insuranceProvides = res;
        this.loadInsurance(res);
      })
  }

  loadProfileFormData(office: DentalOfficeProfilePojo) {
    this.phoneNumber = office?.mobileNumber;
    this.officeInfoForm.patchValue({
      fax: office?.fax,
      email: office?.email,
      officeName: office?.name,
      website: office?.website,
      aboutUrl: office?.aboutUrl,
      phoneNumber: office?.mobileNumber,
      officeAddress: office?.address.street,
      officeType: this.getSpecializations(office.officeSpecialization)
    })
  }

  loadServices(services: Array<DentalServiceProvided>) {
    let servicesInput = services.map(service => ({display: service?.name, value: service?.name}))
    this.officeInfoForm.patchValue({dentalServices: servicesInput})
  }

  loadInsurance(insuranceCompanies: Array<InsuranceCompany>) {
    let insuranceService = insuranceCompanies.map(insurance => ({display: insurance?.name, value: insurance?.name}))
    this.officeInfoForm.patchValue({insuranceService: insuranceService})
  }

  objectToArray(specialityObj: any): Array<any> {
    return Object.keys(specialityObj)
  }

  selectTab(tab) {
    this.tabSwitch = tab;
    if (tab == 'profile' && this.phoneNumber) {
      this.officeInfoForm.patchValue({phoneNumber: this.phoneNumber})
    }
  }

  getOfficeSpeciality(specialities: Array<Specialization>): string {
    return specialities.map(speciality => speciality.name).join(", ");
  }

  getSpecializations(specialization: Array<Specialization>): any {
    this.specializationCode = specialization.map(function (i) {
      return i.code;
    });
    this.getListOfPossibleDentalServices(this.specializationCode);

    return specialization.map(specialization => ({display: specialization?.name, value: specialization?.name}))
  }


  addMember() {
    if (this.adminForm.invalid) {
      this.errorSubmission = true;
      return;
    }
    this.updateLoading = true;
    this.errorSubmission = false;
    this.dentalOfficeService
      .addMember({
        officeAccountId: this.accountId,
        createOfficeMemberDto: this.buildMemberDto()
      })
      .subscribe(res => {
        this.updateLoading = false;
        this.getMembers(this.office.id);
        this.addMemberSuccess = true;
        this.isError = false;
        this.showErrorMessage('Member Added Successfully.');
        this.adminForm.reset();
      }, error => {
        this.updateLoading = true;
        this.isError = true;
        this.showErrorMessage('Failed to add to add member, try another time.');
      })
  }

  getMembers(accountId: number) {
    this.dentalOfficeService
      .getOfficeMembers({
        filter: this.searchFilter(accountId),
      })
      .subscribe(value => {
        // console.log(members)
        this.members = value.results;
      });
  }

  getMembersWithRoles(accountId: number) {
    this.dentalOfficeService
      .getOfficeMembersWithRoles({
        filter: this.searchFilter(accountId),
      })
      .subscribe(value => {
        console.log(value)
        this.membersRoles = value.results;
      });
  }

  removeService(event) {
    const service = this.servicesProvides
      .find(service => service.name === event.value)
    if (!service)
      return
    const payload = {
      officeId: this.office.id,
      serviceName: event.value
    }
    this.dentalOfficeService.removeService(payload).subscribe(next => {
      this.isError = false;
      this.showErrorMessage('Service Updated Successfully.');
    }, error => {
      this.isError = true;
      this.showErrorMessage('Error in Updating Service.');
    })
  }

  removeSpeciality(event) {
    const speciality = this.officeSpecializations
      .find(specialization => specialization.name === event.value)
    if (!speciality)
      return
    const payload = {
      officeId: this.office.id,
      speciality: event.value
    }
    this.dentalOfficeService.removeOfficeSpeciality(payload).subscribe(next => {
      this.isError = false;
      this.showErrorMessage('Speciality Updated Successfully.');
    }, error => {
      this.isError = true;
      this.showErrorMessage('Error in updating Speciality.');
    })

  }

  removeInsurance(event) {
    const insuranceConstant = this.insuranceProvides
      .find(insurance => insurance.name === event.value)
    if (!insuranceConstant)
      return
    const payload = {
      officeId: this.office.id,
      insuranceName: event.value
    }
    this.dentalOfficeService.removeOfficeInsurance(payload).subscribe(next => {
      this.isError = false;
      this.showErrorMessage('Accepted insurance companies updated Successfully.');
    }, error => {
      this.isError = true;
      this.showErrorMessage('Error in updating accepted insurance services.');
    })

  }

  updateProfile() {
    this.updateLoading = true
    // console.log(this.buildUpdatePayload())
    this.dentalOfficeService
      .addProvidedServices({dentalOfficeProfileUpdateDto: this.buildUpdatePayload()})
      .subscribe(res => {
        this.isError = false;
        this.showErrorMessage('Office Updated Successfully.');
        this.getOfficeProfile(this.office.id)
        this.getOfficeServices(this.office.id)
        this.updateLoading = false;
        this.profileUpdated = true
      }, err => {
        this.updateLoading = false;
        this.isError = true;
        this.showErrorMessage('Office update failed please retry, if error persist notify our support team.');
        this.profileUpdated = true;
      });
  }

  buildMemberDto(): CreateOfficeMemberDto {
    const memberForm = this.adminForm.getRawValue();
    return {
      firstName: memberForm.firstName,
      lastName: memberForm.lastName,
      email: memberForm.email,
      phoneNumber: memberForm.phoneNumber.internationalNumber,
      professionalType: memberForm.membershipType
    }
  }

  buildUpdatePayload(): DentalOfficeProfileUpdateDto {
    const data = this.officeInfoForm.getRawValue();
    // console.log(this.officeInfoForm.getRawValue())
    //filter existing services
    data.dentalServices = data?.dentalServices.map(display => display.value)
      .filter(value => !this.servicesProvides.find(service => service.name === value));

    //filter existing specialization
    data.officeType = data?.officeType.map(display => display.value)
      .filter(value => !this.officeSpecializations.find(specialization => specialization.name === value));

    //filter existing insurance company
    data.insuranceService = data?.insuranceService.map(display => display.value)
      .filter(value => !this.insuranceProvides.find(insurance => insurance.name === value));

    return {
      fax: data.fax,
      id: this.office?.id,
      website: data?.website,
      officeType: data?.officeType,
      dentalServices: data?.dentalServices,
      phoneNumber: data?.phoneNumber?.internationalNumber,
      insuranceServices: data?.insuranceService,
      aboutUrl: data?.aboutUrl,
      summary: data?.summary
    };
  }

  getListOfPossibleDentalServices(specializationCodes: any) {
    this.dentalReferralControllerService.searchReferralServices({specializationCode: specializationCodes}).subscribe(res => {
      this.dentalServicesOptions = res.map(function (i) {
        return i.name;
      });
    });
  }

  getListOfInsuranceCompanies(insuranceCompanyCodes?: any) {
    this.insuranceCompanyController.getAllInsuranceCompanies({insuranceCompanyCodes: insuranceCompanyCodes}).subscribe(res => {
      this.insuranceCompanyOptions = res.map(function (i) {
        return i.name;
      });
    });
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  searchFilter(officeId: number): OfficeMembersFilterHandler {
    return {
      accountId: officeId,
      limit: 5,
      offset: 0
    }
  }

  onImagePicked(event) {
    let f = event.target.files[0];
    if (f.size > 1024 * 1024 * 5) {
      this.showImageError('File size must not be more than 5MB');
      return;
    }

    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
    const isValidExtension = validExtensions.indexOf(f.type) > -1;

    if (!isValidExtension) {
      this.showImageError('Invalid File Type. Select an image file.');
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        this.uploadFile(reader.result, f.type, f.name);
      }
    }
  }

  getFileURL() {
    if (this.pictureId.value.id != null && this.pictureId.value.id != '') {
      return environment.siteUrl + "/files/" + this.pictureId.value.id;
    }
    return "./assets/img/user.png";
  }

  imageError() {
    return this.imageErrorMessage;
  }

  showImageError(error: any) {
    this.imageErrorMessage = error;
    this.showImageErrorTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showImageErrorTrigger = false;
    }, 4000);
  }

  showPasswordMessage() {
    return this.passwordErrorMessage;
  }

  showPasswordError(error: any) {
    this.passwordErrorMessage = error;
    this.showPasswordTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showPasswordTrigger = false;
    }, 4000);
  }

  private uploadFile(result, type, name) {
    this.uploadingImage = true;
    this.dentalOfficeImageController.addNewProfileImage({
      id: this.officeId,
      portalUserImageDto: {description: name, contentType: type, profilePicture: result}
    }).subscribe({
      next(value) {
        window.location.reload();
      },
      error() {
        this.uploadingImage = false;
      },
      complete() {
        this.uploadingImage = false;
      }
    }).add(() => {
      this.uploadingImage = false
    });
  }

  passwordError($event: any) {
    this.isError = true;
    this.showPasswordError($event);
  }

  passwordChangeSuccess($event: any) {
    this.successModalResponse();
  }

  successModalResponse() {
    let bsModalRef = this.modalService.show(SuccessModalComponent, {
      initialState: {
        body: 'Password has been updated successfully.',
        link: '/accounts',
        buttonText: "Close",
        check: true,
        close: true
      },
      keyboard: false,
      backdrop: true,
      animated: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });
    bsModalRef?.content.onDismissClicked.subscribe(v => {
      bsModalRef.hide();
    })
    bsModalRef?.content.onSuccessClicked.subscribe(v => {
      window.location.reload();
    })
  }
}
