/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReasonForDisapproval } from './reason-for-disapproval';
import { ReferralAssignment } from './referral-assignment';
import { PortalUser } from './portal-user';


export interface DentalProfessional { 
    dateCreated?: string;
    status?: DentalProfessional.StatusEnum;
    website?: string;
    professionalSummary?: string;
    facebookUrl?: string;
    twitterUrl?: string;
    linkedInUrl?: string;
    approvalStatus?: DentalProfessional.ApprovalStatusEnum;
    reasonForDenial?: string;
    dateOfApprovalProcessing?: string;
    practicingSince?: string;
    id?: number;
    portalUser?: PortalUser;
    approvalProcessedBy?: PortalUser;
    reasonForDisapproval?: ReasonForDisapproval;
    referralAssignments?: Array<ReferralAssignment>;
}
export namespace DentalProfessional {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
}


