/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { DentalOffice } from './dental-office';
import { Patient } from './patient';
import { DentalProfessional } from './dental-professional';
import { TemporaryDentalOffice } from './temporary-dental-office';


export interface DentalReferralCreationPojo { 
    referralId?: number;
    dateCreated?: string;
    createdByAccount?: number;
    patient?: Patient;
    priorityLevel?: DentalReferralCreationPojo.PriorityLevelEnum;
    patientName?: string;
    referralReasonTypeConstant?: DentalReferralCreationPojo.ReferralReasonTypeConstantEnum;
    referralReasonNote?: string;
    patientId?: number;
    patientPhoneNumber?: string;
    isDraft?: boolean;
    referralStatusConstant?: DentalReferralCreationPojo.ReferralStatusConstantEnum;
    preMedRequired?: boolean;
    dentalOfficeReferralTo?: DentalOffice;
    dentalProfessionalReferralTo?: DentalProfessional;
    dentalOfficeAccountFrom?: PortalAccount;
    temporaryDentalOffice?: TemporaryDentalOffice;
    referralCode?: string;
    firstContactToken?: string;
    professionalFirstName?: string;
    professionalLastName?: string;
    referralDataId?: number;
    dentalOfficeFromName?: string;
    dentalProfFromName?: string;
    dentalOfficeAddress?: string;
    dentalOfficePhoneNumber?: string;
}
export namespace DentalReferralCreationPojo {
    export type PriorityLevelEnum = 'NORMAL' | 'SEMI_URGENT' | 'URGENT' | 'EMERGENCY';
    export const PriorityLevelEnum = {
        Normal: 'NORMAL' as PriorityLevelEnum,
        SemiUrgent: 'SEMI_URGENT' as PriorityLevelEnum,
        Urgent: 'URGENT' as PriorityLevelEnum,
        Emergency: 'EMERGENCY' as PriorityLevelEnum
    };
    export type ReferralReasonTypeConstantEnum = 'CONSULTATION' | 'TREATMENT' | 'BOTH';
    export const ReferralReasonTypeConstantEnum = {
        Consultation: 'CONSULTATION' as ReferralReasonTypeConstantEnum,
        Treatment: 'TREATMENT' as ReferralReasonTypeConstantEnum,
        Both: 'BOTH' as ReferralReasonTypeConstantEnum
    };
    export type ReferralStatusConstantEnum = 'PENDING' | 'SCHEDULED' | 'COMPLETED' | 'CUSTOM' | 'CANCELLED' | 'REJECTED' | 'NEW' | 'CALLED' | 'RESCHEDULED' | 'NO_SHOW';
    export const ReferralStatusConstantEnum = {
        Pending: 'PENDING' as ReferralStatusConstantEnum,
        Scheduled: 'SCHEDULED' as ReferralStatusConstantEnum,
        Completed: 'COMPLETED' as ReferralStatusConstantEnum,
        Custom: 'CUSTOM' as ReferralStatusConstantEnum,
        Cancelled: 'CANCELLED' as ReferralStatusConstantEnum,
        Rejected: 'REJECTED' as ReferralStatusConstantEnum,
        New: 'NEW' as ReferralStatusConstantEnum,
        Called: 'CALLED' as ReferralStatusConstantEnum,
        Rescheduled: 'RESCHEDULED' as ReferralStatusConstantEnum,
        NoShow: 'NO_SHOW' as ReferralStatusConstantEnum
    };
}


