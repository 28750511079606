/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coupon } from './coupon';
import { PromotionDuration } from './promotion-duration';
import { PromotionType } from './promotion-type';
import { AddressPojo } from './address-pojo';
import { DentalPlan } from './dental-plan';
import { Deal } from './deal';
import { PromotionItem } from './promotion-item';
import { DentalOfficePojo } from './dental-office-pojo';
import { PortalUser } from './portal-user';
import { PromotionMediaPojo } from './promotion-media-pojo';
import { PromotionModeOfPayment } from './promotion-mode-of-payment';
import { PromotionRecommendation } from './promotion-recommendation';
import { ReviewSummaryPojo } from './review-summary-pojo';
import { PromotionService } from './promotion-service';
import { PromotionTag } from './promotion-tag';


export interface PromotionPojo { 
    dateCreated?: string;
    headline?: string;
    description?: string;
    termsOfUse?: string;
    maxUsageCount?: number;
    maxUsagePerUser?: number;
    enforceUrgency?: boolean;
    value?: number;
    lastUpdated?: string;
    mediaDisplayPreference?: PromotionPojo.MediaDisplayPreferenceEnum;
    id?: number;
    dentalOffice?: DentalOfficePojo;
    createdBy?: PortalUser;
    promotionType?: PromotionType;
    claimCount?: number;
    deal?: Deal;
    coupon?: Coupon;
    dentalPlan?: DentalPlan;
    promotionDuration?: PromotionDuration;
    promotionStatus?: PromotionPojo.PromotionStatusEnum;
    dentalOfficeAddress?: AddressPojo;
    reviewSummary?: ReviewSummaryPojo;
    favouriteCount?: number;
    currentUserFavorite?: boolean;
    modeOfPayments?: Array<PromotionModeOfPayment>;
    promotionServices?: Array<PromotionService>;
    promotionTags?: Array<PromotionTag>;
    promotionItems?: Array<PromotionItem>;
    promotionMedia?: Array<PromotionMediaPojo>;
    promotionRecommendations?: Array<PromotionRecommendation>;
    likeCount?: number;
    dislikeCount?: number;
    currentUserFavoriteStatus?: PromotionPojo.CurrentUserFavoriteStatusEnum;
}
export namespace PromotionPojo {
    export type MediaDisplayPreferenceEnum = 'IMAGE' | 'VIDEO';
    export const MediaDisplayPreferenceEnum = {
        Image: 'IMAGE' as MediaDisplayPreferenceEnum,
        Video: 'VIDEO' as MediaDisplayPreferenceEnum
    };
    export type PromotionStatusEnum = 'ACTIVE' | 'EXPIRED';
    export const PromotionStatusEnum = {
        Active: 'ACTIVE' as PromotionStatusEnum,
        Expired: 'EXPIRED' as PromotionStatusEnum
    };
    export type CurrentUserFavoriteStatusEnum = 'LIKE' | 'UNLIKE' | 'DISLIKE';
    export const CurrentUserFavoriteStatusEnum = {
        Like: 'LIKE' as CurrentUserFavoriteStatusEnum,
        Unlike: 'UNLIKE' as CurrentUserFavoriteStatusEnum,
        Dislike: 'DISLIKE' as CurrentUserFavoriteStatusEnum
    };
}


