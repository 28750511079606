import {Component, Input, OnInit} from '@angular/core';
import {DentalReferralControllerService, ReferralSearchPojo} from "dd-core-api-sdk";
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import * as moment from "moment";

@Component({
  selector: 'app-referral-status-modal',
  templateUrl: './referral-status-modal.component.html',
  styleUrls: ['./referral-status-modal.component.css']
})
export class ReferralStatusModalComponent implements OnInit {

  formGroup: FormGroup;
  edit = true;
  close = false;
  @Input()
  referralSearchPojo: BehaviorSubject<ReferralSearchPojo>;
  referralStatus = ReferralSearchPojo.ReferralStatusConstantEnum;
  @Input()
  isInbound: boolean;
  enableButton: boolean;
  sending: boolean;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage: any;
  updatedNotes: any;
  subjectNotes = new BehaviorSubject<String>('');
  utcOffsetMinutes: any;

  constructor(private bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private dentalReferralController: DentalReferralControllerService) {

    this.utcOffsetMinutes = 'UTC'+this.getTimeZone().toString();

  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      statusNote: ['', [Validators.required]]
    });

    this.formGroup.controls.statusNote.valueChanges.subscribe(v => {
      if (v) {
        this.enableButton = true;
      }
    });

  }

  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  formatUTCMoment(dateCreated: string) {
    return moment(dateCreated).utc(true).toString();
  }

  toggleEditOrClose(edit, close) {
    if (edit) {
      this.close = true;
      this.edit = false;
    }
    if (close) {
      this.edit = true;
      this.close = false;
    }
  }

  dismissModal() {
    this.bsModalRef.hide();
  }

  updateStatusNote(id, status, index) {
    this.sending = true;
    this.dentalReferralController.updateStatusNotes({
      referralNoteId: id,
      note: this.formGroup.getRawValue().statusNote
    }).subscribe(res => {
      this.sending = false;
      this.isError = false;
      this.referralSearchPojo.value.referralStatusNotesList[index].note = res?.note;
      this.referralSearchPojo.value.referralStatusNotesList[index].updatedAt = res?.updatedAt;
      this.edit = true;
      this.close = false;
      this.showErrorMessage('The note for ' + status.toString().toLowerCase() + ' has been updated!.');
    }, err => {
      this.sending = false;
      this.isError = true;
      this.edit = true;
      this.close = false;
      this.showErrorMessage('Error in updating note for ' + status.toString().toLowerCase() + ' status!.');
    })
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }


  appendNotes(notes) {
    this.subjectNotes.next(notes);
    this.subjectNotes.subscribe(res => {
      return res;
    });
  }
}
