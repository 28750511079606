/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Appointment } from './appointment';
import { InsuranceCompany } from './insurance-company';
import { DentalOffice } from './dental-office';
import { PortalUser } from './portal-user';


export interface Patient { 
    firstName?: string;
    lastName?: string;
    gender?: Patient.GenderEnum;
    dateOfBirth?: string;
    status?: Patient.StatusEnum;
    displayName?: string;
    fhirProviderId?: string;
    email?: string;
    dateCreated?: string;
    phoneNumber?: string;
    code?: string;
    id?: number;
    dentalOffice?: DentalOffice;
    portalUser?: PortalUser;
    insuranceCompany?: InsuranceCompany;
    appointments?: Array<Appointment>;
}
export namespace Patient {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


