import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {KeycloakService} from "keycloak-angular";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {
  @Output()
  onConfirmClicked = new EventEmitter();
  @Output()
  onDismissClicked = new EventEmitter();
  @Output()
  onSuccessClicked = new EventEmitter();
  success = false;
  @Input() body = 'Are you sure you want to perform this action?';
  @Input() buttonText = 'ok';
  @Input() close;
  @Input() affiliate;
  @Input() info;
  @Input() check;
  @Input() fail;
  @Input() allowLogin;
  @Input() goToHome;
  @Input() link: string;

  constructor(
    private bsModalRef: BsModalService,
    private modalService: BsModalRef,
    public router: Router,
    private keycloak: KeycloakService,
    private authenticationService: AuthenticationServiceKeycloak
  ) {
  }

  ngOnInit(): void {
  }

  dismiss() {
    this.modalService.hide();
    window.scroll(0, 0);
    this.onSuccessClicked.emit();
    if (this.link)
      this.router.navigate([this.link]);

  }

  dismissAffiliateModal() {
    this.modalService.hide();
  }

  async login() {
    this.authenticationService.logout(
      this.keycloak.getKeycloakInstance().createLoginUrl({
        redirectUri: window.location.origin + '/dashboard',
        prompt: 'login',
      })
    );
  }
}
