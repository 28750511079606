/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { BwFile } from './bw-file';


export interface Help { 
    senderEmail?: string;
    description?: string;
    dateCreated?: string;
    notified?: boolean;
    dateNotified?: string;
    helpStatus?: Help.HelpStatusEnum;
    id?: number;
    file?: BwFile;
    portalAccount?: PortalAccount;
}
export namespace Help {
    export type HelpStatusEnum = 'CLOSED' | 'OPEN' | 'PENDING';
    export const HelpStatusEnum = {
        Closed: 'CLOSED' as HelpStatusEnum,
        Open: 'OPEN' as HelpStatusEnum,
        Pending: 'PENDING' as HelpStatusEnum
    };
}


