import { Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DentalReferralControllerService, ReferralSearchPojo} from "dd-core-api-sdk";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-extranet-referral-details',
  templateUrl: './extranet-referral-details.component.html',
  styleUrls: ['./extranet-referral-details.component.css']
})
export class ExtranetReferralDetailsComponent implements OnInit {


  form!: FormGroup;
  verifyingOtp = false;
  otpEmail: string;
  errMessage = '';
  successMessage = '';
  showErrorMessageTrigger = false;
  showSuccessMessageTrigger = false;
  @ViewChild('otpInput') otpInputRef: any;

  singleReferral: ReferralSearchPojo = null;
  referralDataId: number;



  constructor(private dentalReferralService : DentalReferralControllerService,
              private activatedRoute : ActivatedRoute,
              private fb : FormBuilder,
              private router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      otp: ['', [Validators.required, Validators.maxLength(10)]]
    });
    console.log('ExtranetReferralDetailsComponent::: ');
    this.activatedRoute.queryParams.subscribe(param => {
      console.log('params ', param);
      if (!param['em'] || !param['rid']) {
        this.router.navigate(['/']);
        return;
      }

      this.otpEmail = atob(param['em']);
      this.referralDataId = Number(atob(param['rid']));
    });


  }



  getErrorMessage() {
    return this.errMessage;
  }

  getSuccessMessage() {
    return this.successMessage;
  }
  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  showSuccessMessage(success: any) {
    this.successMessage = success;
    this.showSuccessMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showSuccessMessageTrigger = false;
    }, 6000);
  }


  validateOTP() {
    if (!this.form.valid) {
      return this.showErrorMessage("Please enter a valid OTP to proceed");
    }
    if (this.verifyingOtp) {
      return;
    }
    this.verifyingOtp = true;
    this.dentalReferralService.getExtranetReferralData({
      email: this.otpEmail,
      referralDataId: this.referralDataId,
      pin: this.form.controls.otp.value.trim(),
    }).subscribe(res => {
      this.singleReferral = res;
    }, err => {
      this.otpInputRef.setValue('')
      if (err.error && err.error.message) {
        this.errMessage = err.error.message
        this.showErrorMessage(err.error.message);
      } else {
        this.errMessage = '';
        this.showErrorMessage('Unable to validate OTP, Please check your network and try again');
      }
    }).add(() => {
      this.verifyingOtp = false;
    });
  }

  onOtpChange($event: string) {
    if ($event.length == 6) {
      this.form.patchValue({otp: $event});
    } else {
      this.form.reset();
    }
  }


}
