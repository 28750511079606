import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserKeycloak} from "../../services/UserKeycloak";
import {DentalReferralControllerService, ReferralStatisticPojo} from "dd-core-api-sdk";
import {Router} from "@angular/router";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {SocialService} from "../subject/SocialService";

@Component({
  selector: 'app-referral-stat-card',
  templateUrl: './referral-stat-card.component.html',
  styleUrls: ['./referral-stat-card.component.css']
})
export class ReferralStatCardComponent implements OnInit {
  @Input() isInbound: boolean = false;
  userAccount: UserKeycloak;
  data: ReferralStatisticPojo;

  constructor(private authentication: AuthenticationServiceKeycloak,
              private router: Router,
              private notificationSubject: SocialService,
              private dentalReferralController: DentalReferralControllerService) {
  }

  ngOnInit(): void {
    this.authentication.getUser().subscribe(res => {
      this.userAccount = res;

      this.dentalReferralController.getReferralMonthly({
        receivingDentalProfessionalId: this.userAccount?.dentalProfessional?.id,
        dentalProfessionalId:  this.userAccount?.dentalProfessional?.id,
        receivingDentalOfficeId: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
        portalAccountId: AuthenticationServiceKeycloak._currentUserAccount?.accountId,
        isInbound: this.isInbound
      }).subscribe(res => {
        this.data = res;
        this.notificationSubject.inboundNewCountSubject.next(this.data.totalNumberOfNewInboundReferrals);
      });
    });
  }

}
