import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FiscalAmountPipe} from './fiscal-amount.pipe';
import {HasValuePipe} from './has-value.pipe';
import {UnderscoreToSpacePipe} from './underscore-to-space.pipe';
import {NoWhiteSpacePipe} from './no-white-space.pipe';
import {FullAddressPipe} from './full-address.pipe';
import {HttpErrorResponsePipe} from './http-error-response.pipe';
import {CapitalizeFirstPipe} from './capitalize-first.pipe';
import {SplitCamelCasePipe} from './split-camel-case.pipe';
import {ItalicizeFirstPipe} from './italicize-first.pipe';
import {HeaderPipe} from './header.pipe';
import {ShortestDatePipe} from './shortest-date.pipe';
import { SpaceToUnderscorePipe } from './space-to-underscore.pipe';

@NgModule({
  imports: [
    CommonModule
  ], providers: [DatePipe],
  declarations: [
    FiscalAmountPipe,
    HasValuePipe,
    UnderscoreToSpacePipe,
    NoWhiteSpacePipe,
    HttpErrorResponsePipe,
    CapitalizeFirstPipe,
    FullAddressPipe,
    HttpErrorResponsePipe,
    SplitCamelCasePipe,
    ItalicizeFirstPipe,
    HeaderPipe,
    ShortestDatePipe,
    SpaceToUnderscorePipe
  ],
  exports: [
    FiscalAmountPipe,
    HasValuePipe,
    UnderscoreToSpacePipe,
    NoWhiteSpacePipe,
    HttpErrorResponsePipe,
    FullAddressPipe,
    CapitalizeFirstPipe,
    SplitCamelCasePipe,
    ItalicizeFirstPipe,
    HeaderPipe,
    ShortestDatePipe
  ]
})
export class PipesModule {
}
