<p class="h1 fw-bold mt-4">
    Sign up</p>
<p>Fill the form to create your affiliate account</p>

<hr class="hr my-4">

<form class="mb-3" [formGroup]="formGroup">
    <div id="name" class="row g-2 mb-3 px-0">
        <div class="col-12">
            <div class="mb-4">
                <div id="invite-code">
                    <label for="invite-code-input" class="form-label">Invitation Code</label>
                    <input id="invite-code-input" class="form-control form-control-lg" type="text" formControlName="invitationCode" placeholder="AF000V01" />
                    <app-field-error *ngIf="(formGroup.get('invitationCode')?.errors?.maxlength && formGroup.get('invitationCode')?.touched) ||
                      (formGroup.get('invitationCode')?.errors?.maxlength && errorSubmission)" [message]="'Invitation code is too long.'"></app-field-error>
                  <app-field-error class="mt-1" [message]="'Invalid affiliate invite code.'"
                                   *ngIf="formGroup.get('invitationCode')!.errors?.affiliateCodeNotFound"></app-field-error>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="mb-4">
                <div>
                    <label for="password-input" class="form-label required">Password</label>
                    <input type="password" id="password-input" class="form-control form-control-lg" formControlName="newPassword" placeholder="Password" />
                    <app-field-error class="mt-1" [message]="'Password is poor.'" *ngIf="formGroup.get('newPassword')!.errors?.notStrong"></app-field-error>
                    <app-field-error *ngIf="(formGroup.get('newPassword')?.errors?.required && formGroup.get('newPassword')?.touched) || (formGroup.get('newPassword')?.errors?.required && errorSubmission) " [message]="'Password cannot be blank.'"></app-field-error>
                    <app-field-error *ngIf=" (formGroup.get('newPassword')?.errors?.minlength && formGroup.get('newPassword')?.touched) || (formGroup.get('newPassword')?.errors?.minlength &&  errorSubmission)" [message]="'Password cannot be less than two(2).'"></app-field-error>
                    <app-field-error *ngIf="(formGroup.get('newPassword')?.errors?.maxlength && formGroup.get('newPassword')?.touched) ||
                      (formGroup.get('newPassword')?.errors?.maxlength && errorSubmission)" [message]="'Password cannot be more than fifty(50).'"></app-field-error>

                    <app-ng-password-strength-meter [passwordToCheck]="formGroup.getRawValue().newPassword" (passwordStrengthMessage)="onPasswordStrengthMessChanged($event)"></app-ng-password-strength-meter>
                </div>
            </div>
        </div>

        <div id="confirm-password" class="col-12">
            <div class="mb-4">
                <div>
                    <label for="confirm-password-input" class="form-label required">
            Confirm Password
          </label>
                    <input type="password" id="confirm-password-input" class="form-control form-control-lg" formControlName="confirmPassword" placeholder="Confirm Password" />
                    <app-field-error *ngIf="formGroup.get('confirmPassword')!.errors?.passwordMismatch" [message]="
                        'Password and confirm password must be the same.'"></app-field-error>
                    <app-field-error *ngIf="(formGroup.get('confirmPassword')?.errors?.required && formGroup.get('confirmPassword')?.touched) ||
                        (formGroup.get('confirmPassword')?.errors?.required && errorSubmission)" [message]="'Confirm password cannot be blank.'"></app-field-error>
                </div>
            </div>
        </div>


        <div class="col-12">
            <div class="mb-4">
                <div>
                    <div class="d-flex">
                        <input class="form-check-input" type="checkbox" formControlName="acceptTerms" id="flexCheckDefault" />
                        <label class="form-check-label ms-2 my-1 pb-0 login-checker" for="flexCheckDefault">
<!--                          I agree to the DentalDoor Affiliate <a href="#!" class="link">terms and conditions.</a>.-->
                          I agree to the DentalDoor Affiliate <a routerLink="/affiliate-terms-and-conditions">terms and conditions</a>.
          </label>
                    </div>
                    <app-field-error *ngIf="(formGroup.get('acceptTerms')?.errors?.required && formGroup.get('acceptTerms')?.touched)
          || (formGroup.get('acceptTerms')?.errors?.required && errorSubmission)
                    " [message]=" 'You must agree to the terms of service.'"></app-field-error>
                </div>
            </div>
        </div>

        <div class="mb-3 mb-lg-4 text-center">
            <div class="row">
                <div class="col-md-4">
                    <button type="button" class="btn btn-primary btn-lg w-100 mb-1 rounded-0" awPreviousStep>
        Back
      </button>
                </div>
                <div class="col-md-8 d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-primary btn-lg w-100 mb-1 rounded-0" (click)="this.formGroup.valid ? moveToNextStep() : submittedForError()">
                      <iconify-icon icon="eos-icons:three-dots-loading" class="p-0"
                      *ngIf="this.submittingStatus == this.eventSubmittingStatus.SENT_REQUEST"
                      width="15"></iconify-icon>
       <span> Next</span>
      </button>
                </div>
            </div>

            <br>
            <br>
            <p>Already have an account? <a routerLink="/login" class="link">Sign In</a>.</p>
        </div>
    </div>
</form>
