<div class="docpage-bg"  style="z-index: -1;"></div>
<div class="container">
    <div class="row">
        <div class="col-lg-12" id="faq">
            <div class="" style="z-index: 1;">
                <h4 class="mt-4 mb-3 ml-2 text-light font-weight-bold">Terms and Conditions</h4>
            </div>
            <div class="card border-0 docpage-card">
                <div class="card-body">
                    <p>Effective Date: January 4, 2016</p>
                    <p>This User Agreement (this “Agreement”) is a Agreement between you (“you” or “User”) and
                        DentalDoor LLC (“DentalDoor”, “we,” or “us”).You must read, agree to, and accept all of the
                        terms and conditions contained in this Agreement in order to use our website located at
                        www.dentaldoor.com, all affiliated websites, including mobile websites, apps and applications,
                        owned and operated by us, our predecessors or successors in interest, or our Affiliates
                        (collectively, the “Site”), all services (except the dental Services provided by users),
                        applications and products that are accessible through the Site and all DentalDoor mobile
                        applications that linkto or reference this Agreement (“Site Services”) whether provided by us or
                        our Affiliates.</p>
                    <p>Subject to the conditions set forth herein, DentalDoor may, in its sole discretion, amend this
                        Agreement and the other Terms of Service at any time by posting a revised version on the Site
                        and will provide reasonable advance notice of any amendment that includes a Substantial Change.
                        If the Substantial Change includes an increase to Fees charged by DentalDoor, DentalDoor will
                        provide at least 30 days’ advance notice of the change, but may not provide any advance notice
                        for changes resulting in a reduction in Fees or any temporary or Promotion Fee change. Any
                        revisions to the Terms of Service will take effect on the noted effective date or when posted if
                        there is no noted effective date (each, as applicable, the “Effective Date”).</p>
                    <p>Your continued use of the Site or the Site Services after the Effective Date of a revised version
                        ofthis Agreement or of any other Terms of Service constitutes your acceptance of and agreement
                        to be bound by the Terms of Service as revised. In the event of a conflict between this
                        Agreementand the other Terms of Service, this Agreement will control unless the other Agreement
                        explicitly states that it controls. Capitalized terms are defined throughout this Agreement .
                    </p>
                    <p class="emphasis">ACCESS OR USE THE SITE OR THE SITE SERVICES AFTER THE EFFECTIVE DATE. IF YOU
                        AGREE TO THE TERMS OF SERVICE ON BEHALF OF AN ENTITY, OR IN CONNECTION WITH PROVIDING OR
                        RECEIVING SERVICES ON BEHALF OF AN ENTITY OR AGENCY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE
                        AUTHORITY TO BIND THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE. IN THAT EVENT, “YOU” AND“YOUR”
                        WILL REFER AND APPLY TO THAT ENTITY OR AGENCY.</p>

                    <div class="heading">Digital Signature</div>
                    <p>By Registering For A Dentaldoor Account On The Site (An “Account”), Or By Clicking To Accept The
                        Terms Of Service When Prompted On The Site, You Are Deemed To Have Executed This Agreement And
                        The Other Terms Of Service Electronically, Effective On The Date You Register Your Account Or
                        Click To Accept The Terms Of Service, Pursuant To The U.S. Electronic Signatures In Global And
                        National Commerce Act (The E-Sign Act) (15 U.S.C. § 7001, Et Seq.). Your Account Registration
                        Constitutes An Acknowledgement That You Are Able To Electronically Receive, Download, And Print
                        This Agreement, The Other Terms Of Service, And Any Amendments.</p>

                    <div class="heading">Consent To Use Electronic Records</div>
                    <p>In Connection With The Terms Of Service, You May Be Entitled To Receive Certain Records From
                        Dentaldoor Or Our Affiliates, Such As Agreements, Notices, And Communications, In Writing. To
                        Facilitate Your Use Of The Site And The Site Services, You Give Us Permission To Provide These
                        Records To You Electronically Instead Of In Paper Form.</p>

                    <div class="heading">Your Consent And Your Right To Withdraw Consent</div>
                    <p>By Registering For An Account, You Consent To Electronically Receive And Access, Via Email OrThe
                        Site, All Records And Notices For The Services Provided To You Under The Terms Of Service That
                        We Or Our Affiliates Would Otherwise Be Required To Provide To You In Paper Form. However, We
                        Reserve The Right, In Our Sole Discretion, To Communicate With You Via The U.S. Postal Service
                        And Other Third-Party Mail Services Using The Address Under Which Your Account Is Registered.
                        Your Consent To Receive Records And Notices Electronically Will Remain In Effect Until You
                        Withdraw It. You May Withdraw Your Consent To Receive Further Records And Notices Electronically
                        At Any Time By Contacting Customer Support. If You Withdraw Your Consent To Receive Such Records
                        And Notices Electronically, We Will Revoke Your Access To The Site And The Site Services, And
                        You Will No Longer Be Permitted To Use The Site Or The Site Services. Any Withdrawal Of Your
                        Consent To Receive Records And Notices Electronically Will Be Effective Only After We Have A
                        Reasonable Period Of Time To Process Your Request For Withdrawal. Please Note That Your
                        Withdrawal Of Consent To Receive Records And Notices Electronically Will Not Apply To Records
                        And Notices ElectronicallyProvided By Us To You Before The Withdrawal Of Your Consent Becomes
                        Effective.</p>

                    <div class="heading">Keeping Your Address And Email Address Current With Us</div>
                    <p>In Order To Ensure That We Are Able To Provide Records And Notices To You Electronically, You
                        Agree To Notify Us Immediately Of Any Change In Your Email Address By Updating Your
                        AccountInformation On The Site Or By Contacting Customer Support. In Addition, So That We May
                        Communicate With You Via The U.S. Postal Service And Other Third-Party Mail Services, You Agree
                        To Notify Us Immediately Of Any Change In Your Address.</p>

                    <div class="heading">Dentaldoor Accounts</div>
                    <div class="title">Account Eligibility</div>
                    <p>To Use The Site And Certain Site Services, You Must Register For An Account. Dentaldoor OffersThe
                        Site And Site Services For Your Dental Business, Personal, Household, Or Consumer Purposes. To
                        Use The Site And Site Services, You Must Be Verified. To Register As A Dental Office/Employer,
                        You Must Have, And Hereby Represent That You Have, Dental Office Business (Whether It Be As A
                        Self-Employed Individual/Sole Proprietor Or As A Corporation Or Other Entity) And Further
                        Represent That You Intend To Use The Site And Site Services For Your Business Purposes Only,
                        Unless You Use The Site And Site Services Solely As An Employee And Agency Member Of A
                        Registered Agency Account. You Understand That You Must Comply With Any Licensing Or
                        Registration Requirements With Respect To Your Business, And You Represent That You Comply With
                        All Such Requirements. To Register For An Account, You Must Be, And Hereby Represent That You
                        Are, A Legal Entity Or An Individual 18 Years Or Older Who Can Form Legally Binding Agreements.
                        By Registering For An Account, By Using The Site Or Site Services After The Effective Date If
                        You Had An Account On The Effective Date, Or By Clicking To Accept The Terms Of Service When
                        Prompted On The Site, You Agree To: (A) Abide By This Agreement And The Other Terms Of Service;
                        (B) Be Financially Responsible For The Use Of The Site Through Your Account And The Purchase Or
                        Delivery Of Services; And (C) Perform Your Obligations As Specified By Any Service Agreement
                        That You Enter Into, Unless Such Obligations Are Prohibited By Applicable Law Or The Terms Of
                        Service. Dentaldoor Reserves The Right, In Our Sole Discretion, To Refuse, Suspend, Or Revoke
                        Your Access To The Site And Site Services Upon Discovery That Any Information You Provided On
                        Any Form Or Posted On The Site Is Not True, Accurate, Or Complete, Or Such Information Or Other
                        Conduct Otherwise Violates The Terms Of Service, Or For Any Other Reason Or No Reason In
                        Dentaldoor’s Sole Discretion</p>

                    <div class="title">Account Registration; Profile</div>
                    <p>By Registering For An Account, You Must Complete A User Profile (“Profile”), Which You ConsentTo
                        Be Shown To Other Users And, Unless You Change Your Privacy Settings, The Public. If You Are A
                        Dental Professional (Temp/Agreement Staff), Unless You Use The Site Solely As An Employee And
                        Agency Member Of A Registered Agency Account, You Represent And Warrant That You Use Your
                        Profile To Market Your Services To Dental Offices For The Purpose Of Entering Into Independent
                        Temporary Contractor Relationships With Them. You Agree To ProvideTrue, Accurate, And Complete
                        Information On Your Profile And All Registration And Other Forms You Access On The Site Or
                        Provide To Us And To Update Your Information To Maintain Its Truthfulness, Accuracy, And
                        Completeness. You Agree Not To Provide And To Correct Any Information About Your Location, Your
                        Business, Your Skills, Or The Services Your Business Provides That Is Or Becomes False Or
                        Misleading. You Agree Not To Register For More Than One User Account Without Express Written
                        Permission From Us. You Agree Not To Ask Or Allow Another Person To Create An Account On Your
                        Behalf, For Your Use, Or For Your Benefit.</p>

                    <div class="title">Identity Verification</div>
                    <p>When You Register For An Account And From Time To Time Thereafter, Your Account Will Be Subject
                        To Verification, Including, But Not Limited To, Validation Against Third-Party Databases Or The
                        Verification Of One Or More Official Government Or Legal Documents That Confirm Your Identity
                        And Your Ability To Represent Your Dental Services On Dentaldoor, If It Is A Separate Legal
                        Entity. You Authorize Dentaldoor, Directly Or Through Third Parties, To Make Any Inquiries
                        Necessary To Validate Your Identity And Confirm Your Ownership Of Your Email Address Or
                        Accounts, Subject To Applicable Law. When Requested, You Must Provide Us With Information About
                        You And Your Business.</p>

                    <div class="title">Usernames And Passwords</div>
                    <p>When You Register For An Account, You Will Be Asked To Choose A Username And Password For The
                        Account. You Are Entirely Responsible For Safeguarding And Maintaining The Confidentiality Of
                        Your Account Username And Password. You Authorize Dentaldoor To Assume That Any Person Using The
                        Site With Your Username And Password, Either Is You Or Is Authorized To Act For You. You Agree
                        To Notify Us Immediately If You Suspect Or Become AwareOf Any Unauthorized Use Of Your Account
                        Or Any Unauthorized Access To Your Password Or The Password Of Any User Of Your Account. You
                        Further Agree Not To Use Any Username, Or Password Of Another User Of The Site That You Are Not
                        Authorized To Use, And Not To Allow Others Who Are Not Authorized To Do So To Use Your Account
                        At Any Time.</p>

                    <div class="title">Marketplace Feedback</div>
                    <p>You Acknowledge And Agree That Feedback Benefits The Marketplace, All Users, And The Efficiency
                        Of The Site And You Specifically Request That Dentaldoor Post Composite Or Compiled Feedback
                        About Users, Including Yourself, On User Profiles And Elsewhere On The Site. You Acknowledge And
                        Agree That Feedback Results For You, Including Your Job Success Score (“Jss”), If Any, Will
                        Consist Of Comments, Ratings, Indicators Of User Satisfaction, And Other Feedback Left By Other
                        Users. You Further Acknowledge And Agree That Dentaldoor Will Make Feedback Results Available To
                        Other Marketplace Users, Including Composite Or Compiled Feedback. Dentaldoor Provides This
                        Feedback System As A Means Through Which Users Can Share Their Opinions Publicly And Dentaldoor
                        Does Not Monitor Or Censor These Opinions. You Acknowledge And Agree That Posted Composite Or
                        Compiled Feedback And Any Jss Relate Only To The Business Advertised In The Profile And Not To
                        Any Individual Person. You Agree Not To Use The Jss To Make Any Employment, Credit, Credit
                        Valuation, Underwriting, Or Other Similar Decision About Any Other User.</p>
                    <p>Dentaldoor Does Not Investigate Any Remarks Posted By Users For Accuracy Or Reliability But May
                        Do So If A User Requests That Dentaldoor Do So. You May Be Held Legally Responsible For Damages
                        Suffered By Other Users Or Third Parties As A Result Of Your Remarks If Such Remarks Are Legally
                        Actionable Or Defamatory. Dentaldoor Is Not Legally Responsible For Any Feedback Or Comments
                        Posted Or Made Available On The Site By Any Users Or Third Parties, Even If That Information Is
                        Defamatory Or Otherwise Legally Actionable. In Order To Protect The Integrity Of The Feedback
                        System And Protect Users From Abuse, Dentaldoor Reserves The Right (But Is Under No Obligation)
                        To Remove Posted Feedback Or Information That, In Dentaldoor’s Sole Judgment, Violates The Terms
                        Of Service Or Negatively Affects Our Marketplace. You Acknowledge And Agree That You Will Notify
                        Dentaldoor Of Any Error Or Inaccurate Statement In Your Feedback Results, Including The Jss, And
                        That If You Do Not Do So, Dentaldoor May Rely On The Accuracy Of Such Information.</p>

                    <div class="heading">Purpose Of The Site And Site Services</div>
                    <p>The Site Is A Marketplace Where Dental Offices, Dental Professionals, Patients, And Students Can
                        Identify Each Other, Interact And Advertise Their Dental Services Online. Subject To The Terms
                        Of Service, Dentaldoor Provides The Site Services To Users, Including Hosting And Maintaining
                        The Site, Enabling The Formation Of Service Agreements, And Coordinating Disputes Related To
                        Those Service Agreements. If Users Agree On Terms For Dental Services, AService Agreement Is
                        Formed Directly Between Such Users, Subject To The Provisions Set ForthIn Section 5 (
                        Relationship Between Users).</p>

                    <div class="heading">Relationship Between Users</div>
                    <div class="title">Service Agreement</div>
                    <p>You Acknowledge And Agree That A Service Agreement Is Comprised Of The Following Agreements (As
                        Applicable): (A) Payment Agreement (B) The Engagement Terms Awarded And Accepted On The Site To
                        The Extent That The Terms Do Not, And Do Not Purport To, Expand Dentaldoor’s Obligations Or
                        Restrict Dentaldoor’s Rights Under The Terms Of Service; (C) The Terms In Section 8 (Service
                        Agreement Terms), Other Terms Agreed To By The Parties, To The Extent That The Provisions Do
                        Not, And Do Not Purport To, Expand Dentaldoor’s Obligations Or Restrict Dentaldoor’s Rights
                        Under The Terms Of Service; And (D) Any Other Provisions Accepted By Both The Dental Office And
                        The Dental Professional, To The Extent That The Provisions Do Not, And Do Not Purport To, Expand
                        Dentaldoor’s Obligations Or Restrict Dentaldoor’s Rights Under The Terms Of Service.</p>
                    <p>You Acknowledge And Agree That Dentaldoor Is Not A Party To Any Service Agreements, And That The
                        Formation Of A Service Agreement Between Users Will Not, Under Any Circumstance, Create An
                        Employment Or Other Service Relationship Between Dentaldoor And Any User.</p>

                    <div class="heading">Payment Terms</div>
                    <div class="title">Temp Staff And Specialist Matching & Placement</div>
                    <p>Dentaldoor Provides Its Temporary Staff And Specialist Matching And Placement (As A Complimentary
                        Service For Dental Offices) Free Of Charge. Dentaldoor Does Not Actively Introduce Dental
                        Professionals To Dental Offices And Does Not Help Dental Professionals Secure Temporary Work.
                        Dentaldoor Merely Makes The Site Services Available To Enable DentalOffices And Dental
                        Professionals To Do So Themselves. Therefore, Dentaldoor Does Not ChargeA Fee When A Dental
                        Office Finds A Suitable Dental Professional Or Vice Versa.</p>

                    <div class="title">Job Posting Fees</div>
                    <p>Fees...</p>

                    <div class="title">Membership Fees</div>
                    <p>Dental Offices Can Subscribe To Different Levels Of Participation And Privileges On The Site, By
                        Payment Of Subscription Fees As Described In And Subject To The Terms Of The Membership
                        Agreement, As May Be Revised From Time To Time Upon Such Notice As May Be Appropriate.</p>

                    <div class="title">Non-Payment</div>
                    <p>If A User Fails To Pay A Dental Professional Or Any Other Amounts Owed Dentaldoor, Whether By
                        Canceling User’s Credit Or Debit Card, Initiating An Improper Chargeback, Or Any Other Means,
                        Dentaldoor May Suspend Or Close User’s Account And Revoke User’s Access To The Site, Including
                        User’s Authority To Use The Site To Enter Into Service Agreements, Or Obtain AnyAdditional
                        Services. Without Limiting Other Available Remedies, Dental Office Must Pay Dentaldoor Upon
                        Demand For Amounts Owed, Plus Interest On The Outstanding Amount At The Lesser Of One And
                        One-Half Percent (1.5%) Per Month Or The Maximum Interest Allowed By Applicable Law, Plus
                        Attorneys’ Fees And Other Costs Of Collection To The Extent Permitted By Applicable Law. To The
                        Extent Permitted By Applicable Law, Dentaldoor, At Our Discretion, May Set Off Amounts Due
                        Against Other Amounts Received, Make Appropriate Reports To Credit Reporting Agencies And Law
                        Enforcement Authorities, And Cooperate With Credit Reporting Agencies And Law Enforcement
                        Authorities In Any Resulting Investigation Or Prosecution.</p>

                    <div class="title">No Return Of Funds</div>
                    <p>Dental Office Acknowledges And Agrees That....</p>

                    <div class="title">Formal Invoices And Taxes</div>
                    <p>Dentaldoor Will Have No Responsibility For Determining The Necessity Of Or For Issuing Any Formal
                        Invoices, Or For Determining, Remitting, Or Withholding Any Taxes. Dental Offices And Dental
                        Professionals Will Be Solely Responsible For Determining Whether It Is Required By Applicable
                        Law To Issue Any Formal Invoices For The Services They Provide Or Receive, And For Issuing Any
                        Invoices So Required. Dental Offices And Dental Professionals Will Also Be Solely Responsible
                        For Determining Whether: (A) They Are Required By Applicable Law To Remit To The Appropriate
                        Authorities Any Value Added Tax Or Any Other Taxes Or Similar Charges Applicable To The Services
                        They Provide Or Receive, And Remitting Any Such Taxes Or Charges To The Appropriate Authorities
                        As Appropriate. In The Event Of An Audit Of Dentaldoor, Dental Offices And Dental Professionals
                        Agree To Promptly Cooperate With Dentaldoor And Provide Copies Of Tax Returns And Other
                        Documents As May Be Reasonably Requested For Purposes Of Such Audit, Including But Not Limited
                        To Records Showing Dental Offices And Dental Professionals Are Engaging In An Independent
                        Business As Represented To Dentaldoor</p>

                    <div class="title">Payment Methods</div>
                    <p>In Order To Use Certain Site Services, User Must Provide Account Information For At Least One
                        Valid Payment Method.</p>
                    <p>User Hereby Authorizes Dentaldoor To Run Credit Card Authorizations On All Credit Cards Provided
                        By User, To Store Credit Card And Banking Or Other Financial Details As User’s Method Of Payment
                        For Services, And To Charge User’s Credit Card (Or Any Other Payment Method). Credit Cards And
                        Paypal Accounts And, If Applicable, Bank Accounts In Some Cases Will Be Charged By Dentaldoor
                    </p>
                    <p>When User Authorizes Or Is Deemed To Have Approved A Payment On The Site By Clicking The
                        Appropriate Button, User Automatically And Irrevocably Authorizes And Instructs Dentaldoor To
                        Charge User’s Payment Method For Service Fees.</p>
                    <p>By Providing Payment Method Information Through The Site, User Represents, Warrants, And
                        Covenants That: (A) User Is Legally Authorized To Provide Such Information; (B) User Is Legally
                        Authorized To Perform Payments Using The Payment Method(S); And (C) Such Action Does Not Violate
                        The Terms And Conditions Applicable To User’s Use Of Such Payment Method(S) Or Applicable Law.
                        When User Authorizes A Payment Using A Payment Method Via The Site, User Represents, Warrants,
                        And Covenants That There Are Sufficient Funds Or Credit Available To Complete The Payment Using
                        The Designated Payment Method. To The Extent That Any Amounts Owed To Dentaldoor Cannot Be
                        Collected From User’s Payment Method(S), User Is Solely Responsible For Paying Such Amounts By
                        Other Means.</p>

                    <div class="heading">Service Agreement Terms</div>
                    <p>Users May Agree Between Them On Any Additional Or Different Terms For Their Service Agreement As
                        Long As Such Terms Do Not Purport To Affect The Rights Or Responsibilities Of Dentaldoor Or
                        Violate The Terms Of Service. Dentaldoor Is Not A Party To Any Service Agreement By Or Between
                        Users.</p>
                    <p>Users Agree To Obtain The Consent Of The Other Before Making Changes To The Service Agreement By
                        Adding Additional Or Different Terms Or Making Other Changes To The Service Agreement On The
                        Site. If Consent Of The Other Party Is Not First Obtained, The Other Party May Reject Such
                        Changes By Terminating The Service Agreement (See Section 8.5) Or Accept Such Changes By
                        Continuing To Work On The Service Agreement.</p>

                    <div class="title">Services</div>
                    <p>Each Dental Professional Will Perform The Services They Agreed To In A Professional And
                        Workmanlike Manner And Will Abide By All Terms Agreed On With Each Dental Office. The Manner And
                        Means Of Performing The Dental Services Will Be Determined And Controlled Solely By The Dental
                        Office, Which Engages The Dental Professional As An Independent Contractor.</p>

                    <div class="title">Dispute Resolution</div>
                    <p>With Respect To Disputes Arising Between Dental Offices And Dental Professionals</p>

                    <div class="title">Termination Of A Service Agreement</div>
                    <p>Under Temporary Work Agreement, Either Dental Office Or Dental Professional Has The Right
                        ToTerminate The Agreement After Providing Any Required Notice, Or Immediately On The End Date
                        Specified In The Service Agreement Terms And/Or Upon Completion Of The Dental Professional’s
                        Services, In The Event Of A Material Breach, Or With The Consent Of The Other Party. Except As
                        Required By Law, Dental Offices Remains Obligated To Pay The Dental Professional For Any
                        Services Provided Prior To Termination Of The Temporary Work Termination.</p>

                    <div class="title">Intellectual Property Rights</div>
                    <p>And Effect As If Executed By</p>

                    <div class="heading">Records Of Compliance</div>
                    <p>Users Will Each (1) Create And Maintain Records To Document Satisfaction Of Their Respective
                        Obligations Under This Agreement; Any Service Agreement, Including, Without Limitation, Their
                        Respective Payment Obligations And Compliance With Tax And Employment Laws; And Any Dentaldoor
                        Payroll Agreement, And (2) Provide Copies Of Such Records To Dentaldoor Upon Request. Nothing In
                        This Subsection Requires Or Will Be Construed As Requiring Dentaldoor To Supervise Or Monitor A
                        User’s Compliance With This Agreement, The Other Terms Of Service, Or A Service Agreement.</p>

                    <div class="heading">Relationship With Dentaldoor</div>
                    <p>Dentaldoor Is Not A Party To The Dealings Between Users, Including Posts, Proposals, Screening,
                        Selection, Agreementing, And Performance Of Dental Services. Dentaldoor Does Not Introduce Any
                        User To Another Or Help Users Find Engagements. Dentaldoor Merely Makes TheSite Services
                        Available To Enable Users To Identify And Determine The Suitability Of Other UsersFor Themselves
                        And To Enable One User To Identify And Determine The Suitability Of Another User For Themselves.
                        Dentaldoor Does Not, In Any Way, Supervise, Direct, Or Control Any User Or Their Work.
                        Dentaldoor Does Not Set User’s Work Hours, Work Schedules, Or Location Of Work, Nor Is
                        Dentaldoor Involved In Determining Fees Paid To Independent Dental ProfessionalsBy Dental
                        Offices. Dentaldoor Will Not Provide Any Temporary Dental Professional With Training Or Any
                        Equipment, Labor, Or Materials Needed For A Particular Service Agreement. Dentaldoor Does Not
                        Provide The Premises At Which The Temporary Dental Professional Will Perform Work. Dentaldoor
                        Makes No Representations About, And Does Not Guarantee The Quality, Safety, Or Legality Of, The
                        Services Provided By Temporary Dental Professionals; The Truth Or Accuracy Of Dental
                        Professional’s Listings On The Site; The Qualifications, Background, Or Identities Of Users; The
                        Ability Of Users To Deliver The Dental Services; The Ability Of Dental Offices To Pay For The
                        Temporary Dental Services Agreed On; Or That A Dental Office Or Temporary Dental Professional
                        Can Or Will Actually Complete A Transaction Agreed Upon.</p>
                    <p>Dentaldoor Is Not Required To And May Not Verify Any Feedback Or Information Given To Us By
                        Dental Offices, Dental Professional, Or Any User, Nor Does Dentaldoor Perform Background Checks
                        On Users.</p>
                    <p><strong>You Hereby Acknowledge And Agree That Dentaldoor May Provide Information On The Site
                            About Users, Such As Feedback, Composite Feedback, Including A Strength Or Risk Score,
                            Geographical Location, Or Verification Of Identity Or Credentials. However, Such Information
                            Is Based Solely On Data That The Users Voluntarily Submit To Dentaldoor AndDoes Not
                            Constitute And Will Not Be Construed As An Introduction, Endorsement, Or Recommendation By
                            Dentaldoor; Dentaldoor Provides Such Information Solely For The Convenience Of
                            Users.</strong></p>

                    <div class="heading">Third-Party Beneficiary</div>
                    <p>Users Appoint Dentaldoor As A Third-Party Beneficiary Of Their Service Agreements For Purposes Of
                        Enforcing Any Obligations Owed To, And Any Benefits Conferred On, Dentaldoor Hereunder. For
                        Example, Section 5.1(C) And Section 5.1(D) Of This Agreement Prohibit Certain Terms In Any
                        Service Agreement And Dentaldoor Is Hereby Made A Third-Party Beneficiary For Purposes Of
                        Enforcing Such Prohibitions. Users Further Agree That Dentaldoor Has The Right To Take Such
                        Actions With Respect To Their Accounts, Including, Without Limitation, Suspension, Termination,
                        Or Legal Actions, As We, In Our Sole Discretion, Deem Necessary To Enforce Our Rights As A
                        Third-Party Beneficiary Under The Service Agreements</p>
                    <p>The Terms Of Service And Any Account Registration Will Not Be Construed As Creating Or Implying
                        Any Relationship Of Agency, Franchise, Partnership, Or Joint Venture Between Users And
                        Dentaldoor, Except And Solely To The Extent Expressly Stated In This Agreement.</p>

                    <div class="heading">Communications From You To Dentaldoor</div>
                    <p>All Notices To Dentaldoor</p>

                    <div class="heading">Dentaldoor’s Role</div>
                    <div class="title">Service Agreements</div>
                    <p>You Expressly Acknowledge, Agree, And Understand That: (A) The Site Is Merely A Venue Where Users
                        May Interact And Develop Working Business Relationships; (B) Dentaldoor Is Not AParty To Any
                        Service Agreements Between Users; (C) You Are Not An Employee Of Dentaldoor, And Dentaldoor Does
                        Not, In Any Way, Supervise, Direct, Or Control The User Or User Services;(D) Dentaldoor Will Not
                        Have Any Liability Or Obligations Under Or Related To Service Agreement For Any Acts Or
                        Omissions By You Or Other Users; (E) Dentaldoor Has No Control Over Users Or The User Services
                        Offered Or Rendered By Any Such User; And (F) Dentaldoor Makes No Representations As To The
                        Reliability, Capability, Or Qualifications Of Any User Or The Quality, Security, Or Legality Of
                        Any Services, And Dentaldoor Disclaims Any And All LiabilityRelating Thereto.</p>

                    <div class="heading">Licenses And Third-Party Content</div>
                    <div class="title">Site License And Intellectual Property Rights</div>
                    <p>Subject To And Conditioned On Compliance With The Terms Of Service, Dentaldoor Grants You A
                        Limited License To Access And, If You Have Created An Account, To Use The Site For The Purpose
                        Of Using The Site Services. You Must Not Access (Or Attempt To Access) The Site Or Site Services
                        By Any Means Other Than The Interface Provided, And You Will Not Use Information From The Site
                        Or Site Services For Any Purposes Other Than The Purposes For Which It Was Made Available. You
                        Agree Not To Use The Site Or Site Services For Offering Any Goods Or Services Other Than Dental
                        Related Services As Permitted By This Agreement. You Must Not Sell, Reproduce, Distribute,
                        Modify, Display, Publicly Perform, Prepare Derivative Works Based On, Repost, Or Otherwise Use
                        Any Content Of The Site Or Site Services In Any Way For Any Public Or Commercial Purpose Without
                        Dentaldoor’s Prior Written Consent. You Must Not Use Any Content Of The Site Or Site Services On
                        Any Other Website Or In A Networked Computer Environment For Any Purpose Except Your Own Viewing
                        Without Dentaldoor’s Prior Written Consent. You Must Not Frame Or Link To The Site Or Site
                        Services Except As Permitted In Writing By Dentaldoor. You Must Not Attempt To Reverse Engineer,
                        Modify, Adapt, Translate, Prepare Derivative Works From, Decompile, Attempt To Interfere With
                        The Operation Of, Or Otherwise Attempt To Derive Source Code From Any Part Of The Site Or Site
                        Services Unless Expressly Permitted By Applicable Law. You Will Not Access Site Services In
                        Order To Build A Similar Service Or Application, Or Publish Any Performance, Or Any Benchmark
                        Test Or Analysis Relating To The Site Services. Dentaldoor And Our Licensors RetainAll Right,
                        Title, And Interest In And To All Intellectual Property Rights Related In And To The Site And
                        The Site Services. The Dentaldoor Logos And Names Are Trademarks Of Dentaldoor And May Be
                        Registered In Certain Jurisdictions. All Other Product Names, Company Names, Marks, Logos, And
                        Symbols On The Site Or Site Services May Be The Trademarks Of Their Respective Owners. Except As
                        Expressly Stated In This Agreement, Nothing In The Terms Of Service Confers Any License Under
                        Any Of Dentaldoor’s Or Any Third Party’s Intellectual Property Rights, Whether By Estoppel,
                        Implication, Or Otherwise.</p>

                    <div class="title">User Content License</div>
                    <p>When You Post User Content On The Site Or Through The Site Services Or Provide Dentaldoor With
                        User Content, You Represent And Warrant That You Have The Right, Power, And Authority To Post
                        That User Content And Grant The Licenses Specified Below. You Further Represent AndWarrant That
                        By Posting Or Providing Such User Content You Will Not Violate Third-Party RightsOf Any Kind,
                        Including, Without Limitation, Any Intellectual Property Rights, Rights Of Publicity, And
                        Privacy Rights. To The Extent Your User Content May Be Copyrightable, You Represent, Warrant,
                        And Covenant That You Are The Owner Of All The Copyright Rights To Such User Content And That
                        Dentaldoor May Exercise The Rights To Your User Content Granted Under TheTerms Of Service
                        Without Any Liability Or Obligation For Any Payment.</p>
                    <p>You Retain All Ownership Rights In Any User Content You Post On Dentaldoor. To The Extent
                        Permitted By Applicable Law, You Also Grant To Dentaldoor And Our Successors And Affiliates A
                        Royalty-Free, Sub-Licensable, Transferable, Perpetual, Irrevocable, Non-Exclusive, Worldwide
                        License To Use, Reproduce, Modify, Publish, List Information Regarding, Edit, Translate,
                        Distribute, Publicly Perform, Publicly Display, And Make Derivative Works Of All Such User
                        Content And Your Name, Voice, And/Or Likeness As Contained In Your User Content, In Whole Or In
                        Part, And In Any Form, Media, Or Technology, Whether Now Known Or Hereafter Developed, For Use
                        In Connection With The Site And Dentaldoor’s (And Our Successors’ And Affiliates’) Business,
                        Including, Without Limitation, For Promoting And Redistributing Part Or All Of The Site (And
                        Derivative Works Thereof) In Any Media Formats And Through Any Media Channels. You Also Hereby
                        Grant Each User A Non-Exclusive License To Access Your User Content Through The Site And To Use,
                        Reproduce, Distribute, Display, And Perform Such User Content To The Extent Permitted Through
                        The Normal Functionality Of The Site And Subject To All Applicable Confidentiality And Other
                        Provisions Of This Agreement, Our Privacy Policy, And Applicable Law.</p>
                    <p>Notwithstanding The Foregoing Paragraph, Dentaldoor Will Only Use Or Disclose User Content You
                        Post To Any Non-Public Area Of The Site To The Extent Necessary To Provide Site Services To You
                        As Further Described In Our Privacy Policy.</p>
                    <p>The Licenses To User Content Granted By You In This Agreement Will Terminate Within A
                        Commercially Reasonable Time After You Remove Or Delete Your User Content From The Site, Except
                        That You Grant Dentaldoor And Our Successors And Affiliates The Irrevocable And Perpetual
                        License To Retain And Use, But Not Publicly Display Or Distribute, Server Or Archival Copies Of
                        All User Content That You Have Removed Or Deleted To The Extent Permitted By Applicable Law.</p>
                    <p>You May Submit Comments Or Ideas About The Site And Site Services, Including Without Limitation
                        About How To Improve The Site Or Site Services (Collectively, “Ideas”). By Submitting Any Ideas,
                        You Agree That: (A) Your Disclosure Is Voluntary, Gratuitous, Unsolicited, And
                        WithoutRestriction And Will Not Place Dentaldoor Under Any Fiduciary Or Other Obligation, (B)
                        Your Ideas Do Not Contain The Confidential Or Proprietary Information Of Third Parties, And (C)
                        We Are Free To Use The Ideas Without Any Additional Compensation To You And To Disclose The
                        Ideas On A Non-Confidential Basis Or Otherwise To Anyone. You Further Acknowledge And Agree
                        That, By Acceptance Of Your Submission, Dentaldoor Does Not Waive Any Rights To Use Similar Or
                        Related Ideas Known Or Developed By Dentaldoor Or Obtained From Sources Other Than You.</p>

                    <div class="title">Unauthorized Access And Use; Site Interference; Malicious Software</div>
                    <p>The Site Contains Robot Exclusion Headers. You Agree That You Will Not Use Any Robot, Spider,
                        Scraper, Or Other Automated Means To Access The Site For Any Purpose Without Our Express Written
                        Permission. You Will Not Access The Audiovisual Content Available On The Site For Any Purpose Or
                        In Any Manner Other Than Streaming. You Agree That You Will Not: (A) Take Any Action That
                        Imposes Or We Believe May Impose (In Our Sole Discretion) An Unreasonable Or Disproportionately
                        Large Load On The Site’s Infrastructure; (B) Copy, Reproduce, Modify, Create Derivative Works
                        From, Distribute, Or Publicly Display Any Content (Other Than Content You Have Submitted To The
                        Site) From The Site, Any Software Code That Is Part Of The Site, Or Any Services That Are
                        Offered On The Site Without The Prior Express Written Permission Of Dentaldoor And The
                        Appropriate Third Party, As Applicable; (C) Interfere Or Attempt To Interfere With The Proper
                        Operation Of The Site Or Any Activities Conducted On The Site; (D) Bypass Any Measures We May
                        Use To Prevent Or Restrict Access To The Site Or Any Subparts Of The Site, Including, Without
                        Limitation, Features That Prevent Or Restrict Use Or Copying Of Any Content Or Enforce
                        Limitations On Use Of The Site Or The Content Therein; (E) Transmit Spam, Chain Letters, Or
                        Other Unsolicited Communications; (F) Attempt To Interfere With Or Compromise The System
                        Integrity Or Security Or Decipher Any Transmissions To Or From The Servers Running The Site; (G)
                        Collect Or Harvest Any Personally Identifiable Information, Including Account Names, From The
                        Site; (H) Access Any Content On The Site Through Any Technology Or Means Other Than Those
                        Provided Or Authorized By The Site; Or (I)Directly Or Indirectly, Advertise Or Promote Another
                        Website, Product, Or Service Or Solicit Other Users For Other Websites, Products, Or Services.
                    </p>
                    <p>Additionally, You Agree That You Will Not Post Or Introduce Any Invalid Data, Virus, Worm, Or
                        Other Harmful Or Malicious Software Code, Agent, Hidden Procedure, Routine, Or Mechanism Through
                        Or To The Site Or The Site Software That Is Designed To Cause To Cease Functioning, Disrupt,
                        Disable, Harm, Or Otherwise Impair In Any Manner, Including Aesthetic Disruptions Or
                        Distortions, The Operation Of (Or To Allow You Or Any Other Person To Access Or Damage Or
                        Corrupt Data, Storage Media, Programs, Equipment, Or Communications Or Otherwise Interfere With
                        Operations Of Or On) The Site Or Any Other Software, Firmware, Hardware, Computer System, Or
                        Network Of Dentaldoor Or Any Third Party</p>

                    <div class="title">Third-Party Verification</div>
                    <p>The Site Makes Available Various Services Provided By Third Parties To Verify A User’s
                        Credentials And Provide Other Information. Any Information Or Content Expressed Or Made
                        Available By These Third Parties Or Any Other Users Is That Of The Respective Author(S) Or
                        Distributor(S) And Not Of Dentaldoor. Dentaldoor Neither Endorses Nor Is Responsible For The
                        Accuracy Or Reliability Of Any Opinion, Advice, Information, Or Statement Made On The Site By
                        Anyone Other Than Dentaldoor’s Authorized Employees Acting In Their Official Capacities.</p>

                    <div class="title">Links And Applications</div>
                    <p>The Site May Contain Links To Third-Party Websites. The Site May Also Contain Applications That
                        Allow You To Access Third-Party Websites Via The Site. Such Third-Party Websites Are Owned And
                        Operated By The Third Parties And/Or Their Licensors. Your Access And Use Of Third-Party
                        Websites, Including Online Communication Services, Such As Chat, Email, And CallsWill Be
                        Governed By The Terms And Policies Of The Applicable Third-Party Websites. You Acknowledge And
                        Agree That Dentaldoor Is Not Responsible Or Liable For: (A) The Availability Or Accuracy Of
                        Third-Party Websites; Or (B) The Content, Advertising, Or Products On Or Available From
                        Third-Party Websites. You Are Responsible For Deciding If You Want To Access Third-Party
                        Websites By Clicking On A Link Or Installing An Application. The Inclusion Of Any Link Or
                        Application On The Site Does Not Imply That We Endorse The Linked Site Or Application. You Use
                        The Links And Third-Party Websites At Your Own Risk And Agree That YourUse Of An Application Or
                        Third-Party Website Via The Site Is On An “As Is” And “As Available” Basis Without Any Warranty
                        For Any Purpose.</p>

                    <div class="title">Mobile And Other Devices</div>
                    <p>When Using Our Mobile Applications, Please Be Aware That Your Carrier’s Normal Rates And Fees,
                        Such As Text Messaging And Data Charges, Will Still Apply. Our Mobile Applications May Not
                        Contain The Same Functionality Available On The Site.</p>

                    <div class="title">Site Updates</div>
                    <p>We May From Time To Time In Our Sole Discretion Develop And Provide Site Services Updates, Which
                        May Include Upgrades, Bug Fixes, Patches, And Other Error Corrections And/Or New Features
                        (Collectively, Including Related Documentation, “Updates”). Updates May Also Modify Or Delete In
                        Their Entirety Certain Features And Functionality. You Agree That We Do Not Have Any Obligation
                        To Provide Any Updates Or To Continue To Provide Or Enable Any Particular Features Or
                        Functionality. You Will Promptly Download And Install All Updates And Acknowledge And Agree That
                        Site Services Or Portions Thereof May Not Work Properly Should You Fail To DoSo. You Further
                        Agree That All Updates Will Be Subject To The Terms Of The Terms Of Service, Unless Otherwise
                        Provided In Terms Associated With Such Update. Dentaldoor Reserves The Right, At Any Time, To
                        Modify, Suspend, Or Discontinue Site Services Or Any Part Thereof Without Notice. You Agree
                        Dentaldoor Will Not Be Liable To You Or Any Third Party For Any Modification, Suspension, Or
                        Discontinuance Of Site Services Or Any Part Thereof.</p>

                    <div class="heading">Confidential Information</div>
                    <div class="title">Confidentiality</div>
                    <p>To The Extent A Dental Office Or Dental Professional Provides Confidential Information To The
                        Other, The Recipient Will Protect The Secrecy Of The Discloser’s Confidential Information With
                        The Same Degree Of Care As It Uses To Protect Its Own Confidential Information, But In No Event
                        With Less Than Due Care, And Will: (A) Not Disclose Or Permit Others To Disclose Another’s
                        Confidential Information To Anyone Without First Obtaining The Express Written Consent Of The
                        Owner Of The Confidential Information; (B) Not Use Or Permit The Use Of Another’s Confidential
                        Information, Except As Necessary For The Performance Of Any Dental Services (Including, Without
                        Limitation, The Storage Or Transmission Of Confidential InformationOn Or Through The Site For
                        Use By User); And (C) Limit Access To Another’s Confidential Information To Its Personnel Who
                        Need To Know Such Information For The Performance Of Dental Services</p>

                    <div class="heading">Warranty Disclaimer</div>
                    <p>You Agree Not To Rely On The Site, The Site Services, Any Information On The Site Or The
                        Continuation Of The Site. The Site And The Site Services Are Provided “As Is” And On An “As
                        Available” Basis. Dentaldoor Makes No Express Representations Or Warranties With Regard To The
                        Site, The Site Services, Work Product, Or Any Activities Or Items Related To This AgreementOr
                        The Othe Terms Of Service. To The Maximum Extent Permitted By Applicable Law, Dentaldoor
                        Disclaims All Express And Implied Conditions, Representations, And Warranties Including, But Not
                        Limited To, The Warranties Of Merchantability, Accuracy, Fitness For A Particular Purpose,
                        Title, And Non-Infringement. Some Jurisdictions May Not Allow For All Of TheForegoing
                        Limitations On Warranties, So To That Extent, Some Or All Of The Above Limitations May Not Apply
                        To You. Section 20 (Term And Termination) States User’s Sole And Exclusive Remedy Against
                        Dentaldoor With Respect To Any Defects, Non-Conformances, Or Dissatisfaction.</p>

                    <div class="heading">Limitation Of Liability</div>
                    <p>17. Limitation Of Liability Dentaldoor Is Not Liable, And You Agree Not To Hold Us Responsible,
                        For Any Damages Or Losses Arising Out Of Or In Connection With The Terms Of Service, Including,
                        But Not Limited To:</p>

                    <ul class="m-0">
                        <li>Your Use Of Or Your Inability To Use Our Site Or Site Services;</li>
                        <li>Delays Or Disruptions In Our Site Or Site Services;</li>
                        <li>Viruses Or Other Malicious Software Obtained By Accessing, Or Linking To, Our Site Or Site
                            Services;</li>
                        <li>Glitches, Bugs, Errors, Or Inaccuracies Of Any Kind In Our Site Or Site Services;</li>
                        <li>Damage To Your Hardware Device From The Use Of The Site Or Site Services;</li>
                        <li>The Content, Actions, Or Inactions Of Third Parties’ Use Of The Site Or Site Services;</li>
                        <li>A Suspension Or Other Action Taken With Respect To Your Account;</li>
                        <li>Your Reliance On The Quality, Accuracy, Or Reliability Of Job Postings, Profiles,
                            Ratings,Recommendations, And Feedback (Including Their Content, Order, And Display), Or
                            Metrics Found On, Used On, Or Made Available Through The Site; And</li>
                        <li>Your Need To Modify Practices, Content, Or Behavior Or Your Loss Of Or Inability To Do
                            Business, As A Result Of Changes To The Terms Of Service.</li>
                    </ul>

                    <p>Additionally, In No Event Will Dentaldoor, Our Affiliates, Our Licensors, Or Our Third-Party
                        Service Providers Be Liable For Any Special, Consequential, Incidental, Punitive, Exemplary, Or
                        Indirect Costs Or Damages, Including, But Not Limited To, Litigation Costs, Installation And
                        Removal Costs, Or Loss Of Data, Production, Profit, Or Business Opportunities. The Liability Of
                        Dentaldoor, Our Affiliates, Our Licensors, And Our Third-Party Service Providers To Any User
                        ForAny Claim Arising Out Of Or In Connection With This Agreement Or The Other Terms Of Service
                        Will Not Exceed The Lesser Of: (A) $100; Or (B) Any Fees Payed To Dentaldoor With Respect To
                        Site Services. These Limitations Will Apply To Any Liability, Arising From Any Cause Of Action
                        Whatsoever Arising Out Of Or In Connection With This Agreement Or The Other Terms Of Service,
                        Whether In Agreement, Tort (Including Negligence), Strict Liability, Or Otherwise, Even
                        IfAdvised Of The Possibility Of Such Costs Or Damages And Even If The Limited Remedies Provided
                        Herein Fail Of Their Essential Purpose. Some States And Jurisdictions Do Not Allow For All Of
                        The Foregoing Exclusions And Limitations, So To That Extent, Some Or All Of These Limitations
                        And Exclusions May Not Apply To You.</p>

                    <div class="heading">Release</div>
                    <p>In Addition To The Recognition That Dentaldoor Is Not A Party To Any Agreement Between Users, You
                        Hereby Release Dentaldoor, Our Affiliates, And Our Respective Officers, Directors, Agents,
                        Subsidiaries, Joint Ventures, And Employees From Claims, Demands, And Damages (Actual And
                        Consequential) Of Every Kind And Nature, Known And Unknown, Arising Out Of Or In Any Way
                        Connected With Any Dispute You Have With Another User, Whether It Be At Law Or In Equity. This
                        Release Includes, For Example And Without Limitation, Any Disputes Regarding The Performance,
                        Functions, And Quality Of The Dental Services Provided By A User And Requests For Refunds Based
                        Upon Disputes. Procedures Regarding The Handling Of Certain Disputes Between Users Are Discussed
                        In Subsection 8.4 (Dispute Resolution).</p>
                    <p><strong>To The Extent Applicable, You Hereby Waive The Protections Of California Civil Code §
                            1542 (And Any Analogous Law In Any Other Applicable Jurisdiction) Which Says: “A General
                            Release Does Not Extend To Claims Which The Creditor Does Not Know Or Suspect To Exist In
                            His Or Her Favor At The Time Of Executing The Release, Which If Known By Him Or Her Must
                            Have Materially Affected His Or Her Settlement With The Debtor.”</strong></p>

                    <div class="heading">Indemnification</div>
                    <p>You Will Indemnify, Defend, And Hold Harmless Dentaldoor, Our Affiliates, And Our Respective
                        Directors, Officers, Employees, Representatives, And Agents (Each An “Indemnified Party”)
                        FromAny And All Claims, Damages, Liabilities, Costs, Losses, And Expenses (Including, But Not
                        Limited To, Reasonable Attorneys’ Fees And All Related Costs And Expenses) Arising From Or
                        Relating To Any Claim, Suit, Proceeding, Demand, Or Action Brought By You Or A Third Party Or
                        Other User Against An Indemnified Party Relating To: (A) Use Of The Site And The Site Services
                        By You Or Your Agents, Including Any Payment Obligations Incurred Through Use Of The Site
                        Services; (B) Any Service Agreement Entered Into By You Or Your Agents, Including, But Not
                        Limited To, The Classification Of A User As An Independent Contractor; The Classification Of
                        Dentaldoor As An Employer Or Joint Employer Of A User; Any Employment-Related Claims, Such As
                        Those Relating To Employment Termination, Employment Discrimination, Harassment, Or Retaliation;
                        And Any Claims For Unpaid Wages Or Other Compensation, Overtime Pay, Sick Leave, Holiday Or
                        Vacation Pay, Retirement Benefits, Worker’s Compensation Benefits, Unemployment Benefits, Or Any
                        Other Employee Benefits; (C) Failure To Comply With The Terms Of Service By You Or Your Agents;
                        (D) Failure To Comply With Applicable Law By You Or Your Agents; (E) Negligence, Willful
                        Misconduct, Or Fraud By You Or Your Agents; And (F) Defamation, Libel, Violation Of Privacy
                        Rights, Unfair Competition, Or Infringement Of IntellectualProperty Rights Or Allegations
                        Thereof To The Extent Caused By You Or Your Agents.</p>

                    <div class="heading">Agreement Term And Termination</div>
                    <p>The Terms Of Service As Amended From Time To Time, Will Become Effective On The Later Of The
                        Effective Date Or Your First Visit To The Site And Will Remain In Effect For The Duration Of
                        Your Use Of The Site Or Site Services. Unless Both You And Dentaldoor Expressly Agree Otherwise
                        In Writing, Either Of Us May Terminate This Agreement In Our Sole Discretion, At Any Time,
                        Without Explanation, Upon Written Notice To The Other, Which Will Result In The Termination Of
                        The Other Terms Of Service As Well, Except As Otherwise Provided Herein. You May Provide Written
                        Notice To Legalnotices@Dentaldoor.Com. In The Event You Properly Terminate This Agreement, Your
                        Right To Use The Site Is Automatically Revoked, And Your Account Will Be Closed; However, (A) If
                        You Have Any Open Engagements When You TerminateThis Agreement, You Will Continue To Be Bound By
                        This Agreement And The Other Terms Of Service Until All Such Engagements Have Closed On The
                        Site; (B) Dentaldoor Will Continue To Perform Those Site Services Necessary To Complete Any Open
                        Engagement Or Related Transaction Between You And Dentaldoor Or Another User; And (C) You Will
                        Continue To Be Obligated To Pay Any Amounts Accrued But Unpaid As Of The Date Of Termination Or
                        As Of TheCompletion Of Any Open Engagements, Whichever Is Later, To Dentaldoor For Any Site
                        ServicesAnd To Any User For Any Services. Without Limiting Any Other Provisions Of The Terms Of
                        Service, The Termination Of This Agreement For Any Reason Will Not Release You, Any User With
                        Whom You Have Entered Into A Service Agreement, Or Dentaldoor From Any Obligations Incurred
                        Prior To Termination Of This Agreement Or That Thereafter May Accrue In Respect Of Any Act Or
                        Omission Prior To Such Termination. Those Portions Of The Terms Of Service Necessary To
                        Implement The Foregoing Survive Termination Of This Agreement For Any Reason.</p>
                    <p>Without Limiting Dentaldoor’s Other Rights Or Remedies, We May Temporarily Suspend, Indefinitely
                        Suspend, Or Permanently Revoke Your Access To The Site And Refuse To Provide Any Or All Site
                        Services To You If: (I) You Breach The Letter Or Spirit Of Any Terms And Conditions Of This
                        Agreement Or Other Parts Of The Terms Of Service; (Ii) We Suspect Or Become Aware That You Have
                        Provided False Or Misleading Information To Us; Or (Iii) We Believe, In Our Sole Discretion,
                        That Your Actions May Cause Legal Liability For You, Our Users,Or Dentaldoor Or Our Affiliates;
                        May Be Contrary To The Interests Of The Site Or The User Community; Or May Involve Illicit
                        Activity. If Your Account Is Suspended Or Closed, You May Not Use The Site Under The Same
                        Account Or A Different Account Or Reregister Under A New Account Without Dentaldoor’s Prior
                        Written Consent. If You Attempt To Use The Site Under A Different Account, We Reserve The Right
                        To Reclaim Available Funds In That Account And/Or Use An Available Payment Method To Pay For Any
                        Amounts Owed By You To The Extent Permitted By Applicable Law.</p>
                    <p>Without Limiting Dentaldoor’s Other Rights Or Remedies, If You Engage In Actions Or Activities
                        That Circumvent The Site Or Otherwise Reduce Fees Owed Dentaldoor Or Our Affiliates Under The
                        Terms Of Service, You Must Pay Dentaldoor, And You Authorize Dentaldoor Or Its Affiliate
                        ToCharge You, For All Fees Owed To Dentaldoor And Our Affiliates And Reimburse Dentaldoor For
                        The Opt-Out Fee, If Applicable, All Losses And Costs (Including Any And All Time Of Dentaldoor’s
                        Employees) And Reasonable Expenses (Including Attorneys’ Fees) Related To Investigating Such
                        Breach And Collecting Such Fees. In Addition, Violations Of The Terms Of Service May Be
                        Prosecuted To The Fullest Extent Of The Law And May Result In Additional Penalties And
                        Sanctions.</p>
                    <p>If Your Account Is Closed For Any Reason, You Will No Longer Have Access To Data, Messages,Files,
                        And Other Material You Keep On The Site. If Practicable Or Required By Law, Dentaldoor Will
                        Retain This Information Along With All Your Previous Posts And Proposals For A Period Of Up To
                        Five Years From The Date Of Closure. However, You Understand That Any Closure Of Your Account
                        May Involve Deletion Of Any Content Stored In Your Account For Which DentaldoorWill Have No
                        Liability Whatsoever.</p>

                    <div class="title">Enforcement Of Agreement</div>
                    <p>Dentaldoor Has The Right, But Not The Obligation, To Suspend Or Revoke Your Access To The Site
                        And Site Services If We Believe That You Have Violated Or Acted Inconsistently With The Letter
                        Or Spirit Of This Agreement Or The Terms Of Service Or Violated Our Rights Or Those Of Another
                        Party. Without Limiting Dentaldoor’s Other Rights Or Remedies, We May Suspend Or Close Your
                        Account, Use Self-Help In Connection With Our Rights To Reclaim Funds, And Refuse To Provide Any
                        Further Access To The Site Or The Services To You If (A) You Breach AnyTerms And Conditions Of
                        This Agreement Or Other Terms Of Service; (B) We Are Unable To Verify Or Authenticate Any
                        Information You Provide To Us; Or (C) We Believe That Your Actions May Cause Legal Liability For
                        You, Other Users, Or Dentaldoor.</p>

                    <div class="title">Consequences Of Agreement Termination</div>
                    <p>Termination Of This Agreement And/Or Closing Of Your Account Will Not Relieve User Of The
                        Requirement To Pay For Temp Services Performed Prior To The Effective Date Of The Termination Or
                        Thereafter For Any Service Agreements Executed Before Termination Of This Agreement, Which Fees
                        And Expenses, Together With Any Applicable Taxes, User Hereby Authorizes Dentaldoor To Charge To
                        Its Payment Method Pursuant To Section 6 (Payment Terms).</p>
                    <p>Except As Otherwise Required By Applicable Law, We Will Notify You If We Close Your Account,
                        Unless We Believe, In Our Sole Judgment, That Giving Notice May Cause Damage. You Acknowledge
                        And Agree That The Value, Reputation, And Goodwill Of The Site Depend On Transparency Of User’s
                        Account Status To All Users, Including Both Yourself And Other Users Who Have Entered Into
                        Service Agreements With You. You Therefore Agree As Follows:</p>
                    <p><strong>If Dentaldoor Decides To Suspend Or Close Your Account, Dentaldoor Has The Right But Not
                            The Obligation To: (A) Notify Other Users That Have Entered Into Service Agreements With You
                            To Inform Them Of Your Suspended Or Closed Account Status, And (B) Provide Those Users With
                            A Summary Of The Reasons For Your Account Suspension Or Closure.</strong></p>

                    <div class="title">Survival</div>
                    <p>After This Agreement Terminates, The Terms Of This Agreement And The Other Terms Of Service That
                        Expressly Or By Their Nature Contemplate Performance After The Agreement Terminates Or Expires
                        Will Survive And Continue In Full Force And Effect. For Example, The Provisions Protecting
                        Confidential Information, Requiring Arbitration, Permitting Audits, ProtectingIntellectual
                        Property, Requiring Non-Circumvention, Indemnification, Payment Of Fees, Reimbursement And
                        Setting Forth Limitations Of Liability Each, By Their Nature, Contemplate Performance Or
                        Observance After This Agreement Terminates</p>

                    <div class="heading">Cancellations, Refunds, And Disputes</div>
                    <div class="title">Dispute Process And Scope</div>
                    <p>If A Dispute Arises Between You And Dentaldoor Or Our Affiliates, Our Goal Is To Resolve The
                        Dispute Quickly And Cost-Effectively. Accordingly, You, Dentaldoor, And Our Affiliates Agree To
                        Resolve Any Claim, Dispute, Or Controversy That Arises Out Of Or Relates To This Agreement, The
                        Other Terms Of Service, Your Relationship With Dentaldoor (Including Any Claimed Employment With
                        Dentaldoor Or One Of Its Affiliates Or Successors), The Termination Of Your Relationship With
                        Dentaldoor, Or The Site Services (Each, A “Claim”) In Accordance With This Section. For The
                        Avoidance Of Doubt, Claims Include, But Are Not Limited To, All Claims, Disputes, Or
                        Controversies Arising Out Of Or Relating To The Terms Of Service, Any Service Agreement,
                        Payments Or Agreements, Any Payments Or Monies You Claim Are Due To You From Dentaldoor Or Its
                        Affiliates Or Successors, Trade Secrets, Unfair Competition, False Advertising, Consumer
                        Protection, Privacy, Compensation, Classification, Expense Reimbursement, Termination,
                        Discrimination Or Harassment And Claims Arising Under The Uniform Trade Secrets Act As Enacted
                        In Any State, Civil Rights Act Of 1964, Americans With Disabilities Act, Age Discrimination In
                        Employment Act, Fair Labor Standards Act, Genetic Information Non-Discrimination Act, State
                        Statutes Or Regulations Addressing The Same Or Similar Subject Matters, And All Other Federal Or
                        State Legal Claims Arising Out Of Or Relating To Your Relationship With Dentaldoor Or The
                        Termination Of That Relationship. Only With Respect To The Arbitration Provision, Claims Do Not
                        Include Disputes That May Not Be Subject To A Pre-Dispute Arbitration Agreement As Provided By
                        The Dodd-Frank Wall Street Reform And Consumer Protection Act (Public Law 111-203) And Are
                        Excluded From The Coverage Of The Arbitration Provision.</p>
                    <p>You Agree That Any Claim Must Be Resolved As Described In The Subsections Below Titled “Informal
                        Dispute Resolution” And “Mandatory Binding Arbitration And Class Action/Jury Trial Waiver.”</p>

                    <div class="title">Choice Of Law</div>
                    <p>This Agreement, The Other Terms Of Service, And Any Claim Will Be Governed By And Construed In
                        Accordance With The Laws Of The State Of Texas.</p>

                    <div class="title">Informal Dispute Resolution</div>
                    <p>Before Serving A Demand For Arbitration Of A Claim....</p>

                    <div class="title">Mandatory Binding Arbitration And Class Action/Jury Trial Waiver</div>
                    <p>Send This Written Notification To ...</p>

                    <div class="heading">General</div>
                    <div class="title">Entire Agreement</div>
                    <p>No modification or amendment to the Terms of Service will be binding upon DentalDoor unless ina
                        written instrument signed by a duly authorized representative of DentalDoor. For the purposes of
                        this subsection, a written instrument will expressly exclude electronic communications, such
                        asemail and electronic notices, but will include facsimiles. This Section 22.4 (Modifications)
                        does not apply to amendments to the Terms of Service posted by DentalDoor to the Site from time
                        to time.</p>

                    <div class="title">SIDE AGREEMENTS</div>
                    <p>Notwithstanding subsection 22.1 (Entire Agreement), dental offices and temporary dental
                        professionals may enter into any supplemental or other written agreements that they deem
                        appropriate (e.g., confidentiality agreements, invention assignment agreements, assignment of
                        rights, etc.) for a temporary business relationship. The terms and conditions of the Terms of
                        Service, however, will govern and supersede any term or condition in a side agreement that
                        purports to expand DentalDoor’s obligations or restrict DentalDoor’s rights under the Terms of
                        Service.</p>

                    <div class="title">COMPLIANCE</div>
                    <p>User will not violate any applicable federal, state, or local laws or third-party rights on or
                        related to the Site. Without limiting the generality of the foregoing, User agrees to comply
                        with all applicable laws and regulations, including, but not limited to third parties’
                        Intellectual Property Rights.</p>

                    <div class="title">MODIFICATIONS</div>
                    <p>No modification or amendment to the Terms of Service will be binding upon DentalDoor unless ina
                        written instrument signed by a duly authorized representative of DentalDoor. For the purposes of
                        this subsection, a written instrument will expressly exclude electronic communications, such
                        asemail and electronic notices, but will include facsimiles. This Section 22.4 (Modifications)
                        does not apply to amendments to the Terms of Service posted by DentalDoor to the Site from time
                        to time.</p>

                    <div class="title">NO WAIVER</div>
                    <p>The failure or delay of either party to exercise or enforce any right or claim does not
                        constitute a waiver of such right or claim and will in no way affect that party’s right to later
                        enforce or exerciseit, unless such party issues an express written waiver, signed by a duly
                        authorized representativeof such party.</p>

                    <div class="title">ASSIGNABILITY</div>
                    <p>User may not assign the Terms of Service, or any of its rights or obligations hereunder, without
                        DentalDoor’s prior written consent in the form of a written instrument signed by a duly
                        authorized representative of DentalDoor (and, for the purposes of this subsection, a written
                        instrument will expressly exclude electronic communications such as email and electronic
                        notices, but will include facsimiles). DentalDoor may freely assign this Agreement or the other
                        Terms of Service without User’s consent. Any attempted assignment or transfer in violation of
                        this subsection will be null and void. Subject to the foregoing restrictions, the Terms of
                        Service will inure to the benefit of the successors and permitted assigns of the parties.</p>

                    <div class="title">SEVERABILITY</div>
                    <p>If and to the extent any provision of this Agreement or the other Terms of Service is held
                        illegal, invalid, or unenforceable in whole or in part under applicable law, such provision or
                        such portion thereof will be ineffective as to the jurisdiction in which it is illegal, invalid,
                        or unenforceable to theextent of its illegality, invalidity, or unenforceability and will be
                        deemed modified to the extent necessary to conform to applicable law so as to give the maximum
                        effect to the intent of the parties. The illegality, invalidity, or unenforceability of such
                        provision in that jurisdiction will not in any way affect the legality, validity, or
                        enforceability of such provision in any other jurisdiction or of any other provision in any
                        jurisdiction.</p>

                    <div class="title">FORCE MAJEURE</div>
                    <p>The parties to this Agreement will not be responsible for the failure to perform or any delay in
                        performance of any obligation hereunder due to labor disturbances, accidents, fires, floods,
                        telecommunications or Internet failures, strikes, wars, riots, rebellions, blockades, acts of
                        government, governmental requirements and regulations or restrictions imposed by law or any
                        other similar conditions beyond the reasonable control of such party. The time for performance
                        ofsuch party will be extended by the period of such delay. Irrespective of any extension of
                        time, if an event of Force Majeure occurs and its effect continues for a period of 60 days,
                        either the partymay give to the other a 30-day notice of termination. If, at the end of the 30
                        day period, the effect of the Force Majeure continues, the Agreement and the other Terms of
                        Service will terminate, except as provided in Section 20.3.</p>

                    <div class="title">PREVAILING LANGUAGE AND LOCATION</div>
                    <p>The English language version of the Terms of Service will be controlling in all respects and will
                        prevail in case of any inconsistencies with translated versions, if any. The Site is controlled
                        and operated from our facilities in the United States. DentalDoor makes no representations that
                        the Site is appropriate or available for use in other locations. Those who access or use the
                        Site from other jurisdictions do so at their own volition and are entirely responsible for
                        compliance with all applicable federal, state, and local laws and regulations. You must not
                        directly or indirectly sell, export, re-export, transfer, divert, or otherwise dispose of any
                        software or service to any end userwithout obtaining any and all required authorizations from
                        the appropriate government authorities. Unless otherwise explicitly stated, all materials found
                        on the Site are solely directed to individuals, dental offices, and dental professionals located
                        in the United States</p>
                </div>
            </div>

        </div>
        </div>
        <!-- /row -->
        </div>
        <!-- /container -->