<div class="row p-4">
  <div class="container">
    <div class="success" >
      <div class="card-body">
        <span class="fa-solid fa-circle-check success-icon fa-5x"></span>
        <h5 style="color: #27AE60; margin-top: 10px">Success !</h5>
      </div>
      <p class="text-xl-center ">An email has been sent to you. Please check for an email from
        Dental Door and click on the link to activate your account.</p>
      <button type="button" class="btn btn-success shadow-sm" style="background-color: #27ae60; border: 0px;" (click)="close()">Home</button>
    </div>
  </div>
</div>
