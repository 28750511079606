import {Component, Renderer2, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {UserKeycloak} from "../../services/UserKeycloak";
import {UserAccountKeycloak} from "../../services/UserAccountKeycloak";
import {routes} from "dd-shared-lib";
// import {PwaService} from "../../services/pwa-service";


@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {

  @Input()
  isAffiliate: boolean;
  user: UserKeycloak;
  currentAccount: UserAccountKeycloak;
  route: string;
  currentRoute: string;

  constructor(
    private authenticationService: AuthenticationServiceKeycloak,
    private keycloak: KeycloakService,
    // public pwaService: PwaService,
    private router: Router,
     private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.route = this.router.url

    this.authenticationService.getUser()
      .subscribe(value => {
        this.user = value;
      });
  }

  requestLogin() {
    this.login();
  }

  async login() {
    if (this.user) {
      this.authenticationService.logout();
    }

    await this.authenticationService.login({
      redirectUri: window.location.origin + '/dashboard',
      scope: 'offline_access',
      prompt: 'login',
    })
  }


  logout() {
    this.clearStorage();
    this.authenticationService.logoutClick(window.location.origin)
  }

  clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  
  @HostListener('window:scroll', [])
  onScroll(): void {
    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > 50) {
      this.renderer.addClass(this.elementRef.nativeElement.querySelector('nav'), 'shadow');
      this.renderer.addClass(this.elementRef.nativeElement.querySelector('nav'), 'bg-light');
    } else{
       this.renderer.removeClass(this.elementRef.nativeElement.querySelector('nav'), 'shadow');
       this.renderer.removeClass(this.elementRef.nativeElement.querySelector('nav'), 'bg-light');
    }
  }

}
