import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MovingDirection, WizardComponent} from "angular-archwizard";
import {RegisterUserRequestParams, UserRegistrationDto, UserRegistrationService} from "dd-core-api-sdk";
import {SocialService} from "../../../../shared/subject/SocialService";
import {EventSubmissionTracking} from "../../../../shared/EventSubmissionTrackingEnum";
import {BsModalService} from "ngx-bootstrap/modal";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {AffiliateSuccessModalComponent} from "../../utils/affiliate-success-modal/affiliate-success-modal.component";

@Component({
  selector: 'app-affiliate-wizard',
  templateUrl: './affiliate-wizard.component.html',
  styleUrls: ['./affiliate-wizard.component.css']
})
export class AffiliateWizardComponent implements OnInit {

  @ViewChild(WizardComponent)
  public wizard!: WizardComponent;
  formOneGroup: FormGroup;
  formTwoGroup: FormGroup;
  submitting: boolean;
  @Input()
  affiliateCode: string;
  eventSubmissionTracking: EventSubmissionTracking;
  otpEmail: any;
  otpPhone: any;
  user: UserKeycloak;

  constructor(private userRegistrationService: UserRegistrationService,
              private modalService: BsModalService,
              private authenticationService: AuthenticationServiceKeycloak,
              private socialService: SocialService) {
  }

  ngOnInit(): void {

  }

  getFormOneData($event: FormGroup) {
    if ($event) {
      this.wizard.goToNextStep();
    }
    this.formOneGroup = $event;
  }

  getFormTwoData($event: FormGroup) {
    this.formTwoGroup = $event;
    this.socialService.behaviorSubject.next('');
    sessionStorage.clear();

    this.eventSubmissionTracking = EventSubmissionTracking.SENT_REQUEST;

    this.userRegistrationService.registerUser(this.buildPayload()).subscribe(res => {
      this.eventSubmissionTracking = EventSubmissionTracking.SENT_REQUEST_TERMINATION;

      this.loginUserManually(res.email, this.formTwoGroup.getRawValue().newPassword, this.formOneGroup.getRawValue().phoneNumber?.e164Number)
    }, error => {
      this.eventSubmissionTracking = EventSubmissionTracking.SENT_REQUEST_TERMINATION;
    });

  }

  loginUserManually(username: string, password: string, phone: string, showDashboard?: boolean) {
    this.userRegistrationService.login({loginDto: {username: username, password: password}}).subscribe(res => {
      this.eventSubmissionTracking = EventSubmissionTracking.SECOND_REQUEST_SUCCESS;
      this.socialService.save(res.accessToken);
      sessionStorage.setItem('keycloak_token', res.accessToken);
      this.showModal(
        AffiliateSuccessModalComponent, username, phone,
        'Account created successfully. Click the button below to receive an OTP on ',
        true, true, showDashboard);
    }, err => {

    })
  }

  buildPayload(): RegisterUserRequestParams {
    const form = this.formOneGroup.getRawValue();
    const formTwo = this.formTwoGroup.getRawValue();
    return {
      userRegistrationDto: {
        firstName: form?.firstName,
        lastName: form?.lastName,
        email: form?.email,
        mobileNumber: form?.phoneNumber?.e164Number,
        password: formTwo?.newPassword,
        userType: UserRegistrationDto.UserTypeEnum.Affiliate,
        accountType: "AFFILIATE",
        affiliateLinkCode: formTwo?.invitationCode
      }
    }
  }

  showModal(component: any, email: string, phone: string, message?: string, isSuccess?: boolean, close?: boolean, dashboard?: boolean) {
    let bsModalRef = this.modalService.show(component, {
      initialState: {
        modalClass: 'modal-sm',
        body: message,
        email: email,
        accountType: "Affiliate",
        check: isSuccess,
        fail: !isSuccess,
        close: close,
        routeLink: dashboard ? "/dashboard" : "/verify-email",
        phone: phone
      },
      keyboard: false,
      class: 'modal-lg modal-dialog-centered',
      ignoreBackdropClick: true,
    });
    //@ts-ignore
    bsModalRef?.content?.onClick.subscribe(v => {
      this.wizard.goToNextStep();
      bsModalRef.hide();
    });
  }

  onOtpStepInit($event: MovingDirection) {
    this.authenticationService.fetchUser().subscribe(res => {
      this.socialService.isInterceptor.next(false);
      this.user = res;
      const requested = sessionStorage.getItem("REQUESTED_OTP") || '';

      if (!requested && this.socialService.otpSubject.getValue() != 'TRUE') {
        this.userRegistrationService.requestMobileNumberVerification({
          email: res.email,
          phoneNumber: res.mobileNumber,
          deliveryMode: 'PHONE_CALL'
        }).subscribe(res => {
          sessionStorage.setItem("REQUESTED_OTP", "TRUE");
        })
      }
      this.otpEmail = this.user?.email;
      this.otpPhone = this.user?.mobileNumber || null;
    })

  }
}


