import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-kyc-status-page',
  templateUrl: './kyc-status-page.component.html',
  styleUrls: ['./kyc-status-page.component.css']
})
export class KycStatusPageComponent implements OnInit {

  @Input()
  info: string;
  isSuccess: boolean;

  @Output()
  closeClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
