/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AppointmentReminderFeedbackDto { 
    comment?: string;
    feedback?: AppointmentReminderFeedbackDto.FeedbackEnum;
    appointmentCancellationReasonCode?: string;
}
export namespace AppointmentReminderFeedbackDto {
    export type FeedbackEnum = 'WILL_HONOUR' | 'WILL_NOT_HONOUR';
    export const FeedbackEnum = {
        Honour: 'WILL_HONOUR' as FeedbackEnum,
        NotHonour: 'WILL_NOT_HONOUR' as FeedbackEnum
    };
}


