import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {TranslateModule} from '@ngx-translate/core';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {CustomFormControlDirective} from './customForm/custom-form-control.directive';
import {TabsModule} from "ngx-bootstrap/tabs";

@NgModule({
  declarations: [CustomFormControlDirective],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        // TranslateModule,
        NgBootstrapFormValidationModule,

        TabsModule,
    ],
  exports: [CustomFormControlDirective]
})
export class UtilsModule {
}
