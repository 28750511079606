import {RolePojo} from "../shared/account/role.pojo.model";
import {PermissionType} from "../shared/account/permission-type.enum";

export class AccountKeycloak {

  public name!: string;
  public code!: string;
  public accountType!: string;
  public roles!: RolePojo[];
  public jurisdiction!: string;
  public countryCode!: string;
  public enabledForIntegration?: boolean;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public hasPermission(permission: string) {
    return this.roles.map((role: RolePojo) => role.permissions.filter((p: PermissionType) =>
      p.toString() === permission).length);
  }

}
