<div>
    <div class="top">
        <h4 class="text-center h4">How soon would you like an appointment?</h4>
    </div>
    <div class="header">
        <div class="inner-header">
            <div class="container">
                <div *ngIf="showErrorMessageTrigger">
                    <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert">
                        <p class="m-0 p-0 d-flex align-items-start">
                            <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                {{ getErrorMessage() | removeUnderscores }}
              </span>
                            <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                            </iconify-icon>
                        </p>
                    </div>
                </div>
                <div class="row g-3">
                    <div class="form-check form-check-inline p-0 col-sm" *ngFor="let aff of firstContactImage; let i = index">
                        <input class="form-check-input" type="radio" [id]="'radio'+i" [name]="'firstContactImage'" [value]="aff.range" (click)="select(aff.id)">
                        <label class="form-check-label card py-4 px-0" [for]="'radio'+i">
              <p class="fw-bold m-0">{{aff.range | titlecase}}</p>
            </label>
                    </div>
                    <div class="row justify-content-center align-items-center mt-3 mt-sm-5 g-3">
                        <div class="col-auto"> <button type="button" (click)="noPreferenceSelected()" *ngIf="showPreference" class="btn btn-outline-primary btn-large p-3 rounded-5">
              No Preference
            </button></div>
                        <div class="col-auto" *ngIf="!showPreference"><button type="button" (click)="moveToNextStep()" class="btn btn-outline-primary btn-large p-3 rounded-5">
              Continue
            </button></div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
          <use xlink:href="#gentle-wave" x="48" y="7" fill="hsla(0,0%,100%,.4)"></use>
        </g>
      </svg>

        </div>

    </div>
</div>