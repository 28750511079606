import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'italicizeFirst'
})
export class ItalicizeFirstPipe implements PipeTransform {

  transform(value: string, args?: any[]): string {
    if (value === null || value.trim() == '') return value;
    if (value.trim().charAt(0) != 'd') return value;
    if (value.length<2) return value.italics();
    return value.charAt(0).italics().toLowerCase()+ value.charAt(1).toUpperCase() + value.slice(2);
  }

}

