<div class="container mt-4" xmlns:javascript="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div class="d-flex align-items-center">
      <p class="fs-3 textmuted fw-bold my-3">
        <iconify-icon inline icon="codicon:dashboard" class="fs-3 me-2"></iconify-icon>
        Dashboard Overview
      </p>
    </div>
    <!-- search filter  -->
    <div class="my-4">
      <div class="container px-0">
        <div class="row g-2">
          <div class="col-xl col-12 ">
            <div class="overview-card rounded-4 p-4 mb-3">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-xl-4 d-flex flex-column px-3">
                    <div>
                      <h5 class="text-muted">Dental Offices</h5>
                      <p class="text-muted small">
                        This consists of all dental offices on dental door.
                      </p>
                    </div>
                    <h1 class="text-primary mt-4">
                      {{ this.totalDentalOffices || 0 }}
                    </h1>

                    <div class="d-flex justify-content-end">
                      <div
                        class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2" (click)="showOffice()">
                        <p class="mb-0 mx-2 dddb-card-btn pointer" style="line-height: 23px">
                          View Offices
                        </p>
                        <div
                          class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0 icon-hld">
                          <iconify-icon icon="prime:angle-right" class="fs-5"></iconify-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 my-5 my-xl-0 d-flex flex-column px-3 dashb-card-border">
                    <div>
                      <h5 class="text-muted">Total Office Subscriptions</h5>
                      <p class="small text-muted">
                        This list show the total amount trial and active subscriptions.
                      </p>
                    </div>
                    <h1 class="text-primary mt-4">
                      {{ this.totalOfficeSubscriptions || 0 }}
                    </h1>

                    <div class="d-flex justify-content-end">
                      <div class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2" (click)="showAllSubscription()">
                        <p class="mb-0 mx-2 dddb-card-btn pointer" style="line-height: 23px">
                          View Office Subscriptions
                        </p>
                        <div
                          class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0">
                          <iconify-icon icon="prime:angle-right" class="fs-5"></iconify-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 d-flex flex-column px-3">
                    <div>
                      <h5 class="text-muted">Total Revenue</h5>

                      <p class="text-muted small">
                        Shows the total amount of paid invoices on the platform
                      </p>
                    </div>
                    <h1 class="text-primary mt-4">
                      {{ this.totalAmountOfRevenue || 0 | currency:'USD':'symbol'}}
                    </h1>

                    <div class="d-flex justify-content-end">
                      <div
                        class="btn refer-btn-custom rounded-5 d-flex align-items-center justify-content-around p-2">
                        <p class="mb-0 mx-2 dddb-card-btn pointer" style="line-height: 23px">

                        </p>
                        <div
                          class="badge rounded-circle text-bg-primary p-1 d-flex justify-content-center align-items-center mb-0">
                          <iconify-icon icon="prime:angle-right" class="fs-5"></iconify-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-admin-dental-office [showAllOffices]="showAllOffices"
                                   [showSubscription]="showSubscription"></app-admin-dental-office>
        </div>
      </div>
    </div>
  </div>
</div>
