import {Component, Input, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {FirstContactPojo} from "dd-core-api-sdk";

@Component({
  selector: 'app-appointment-preference-modal',
  templateUrl: './appointment-preference-modal.component.html',
  styleUrls: ['./appointment-preference-modal.component.css']
})
export class AppointmentPreferenceModalComponent implements OnInit {
  @Input()
  firstContactPojo: FirstContactPojo;
  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void { }

  dismissModal() {
    this.bsModalRef.hide();
  }
}
