/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FlashCardSet } from './flash-card-set';
import { PortalUser } from './portal-user';


export interface FlashCardItem { 
    dateCreated?: string;
    dateDeactivated?: string;
    status?: FlashCardItem.StatusEnum;
    question?: string;
    hint?: string;
    color?: string;
    index?: number;
    id?: number;
    flashCardSet?: FlashCardSet;
    deactivatedBy?: PortalUser;
}
export namespace FlashCardItem {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


