/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PackageItemPojo { 
    packageItemLabel?: string;
    packageItemConstant?: PackageItemPojo.PackageItemConstantEnum;
    recordCount?: number;
    maxRecordCount?: number;
}
export namespace PackageItemPojo {
    export type PackageItemConstantEnum = 'OFFICE_GALLERY' | 'EMERGENCY_SOS' | 'SEND_REFERRAL' | 'RECEIVE_REFERRAL' | 'DREFER_ANALYTICS' | 'UP_TO_5_ADMIN_ACCESS' | 'UP_TO_3_ADMIN_ACCESS' | 'UNLIMITED_DENTAL_PROFESSIONALS' | 'UP_TO_5_DENTAL_PROFESSIONALS';
    export const PackageItemConstantEnum = {
        OfficeGallery: 'OFFICE_GALLERY' as PackageItemConstantEnum,
        EmergencySos: 'EMERGENCY_SOS' as PackageItemConstantEnum,
        SendReferral: 'SEND_REFERRAL' as PackageItemConstantEnum,
        ReceiveReferral: 'RECEIVE_REFERRAL' as PackageItemConstantEnum,
        DreferAnalytics: 'DREFER_ANALYTICS' as PackageItemConstantEnum,
        UpTo5AdminAccess: 'UP_TO_5_ADMIN_ACCESS' as PackageItemConstantEnum,
        UpTo3AdminAccess: 'UP_TO_3_ADMIN_ACCESS' as PackageItemConstantEnum,
        UnlimitedDentalProfessionals: 'UNLIMITED_DENTAL_PROFESSIONALS' as PackageItemConstantEnum,
        UpTo5DentalProfessionals: 'UP_TO_5_DENTAL_PROFESSIONALS' as PackageItemConstantEnum
    };
}


