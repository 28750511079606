import { CommonModule } from '@angular/common';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { NgModule } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';
import { ToastrModule } from 'ngx-toastr';
// import { AvatarPipe } from '../lib/pipe/avatar.pipe';
import { NoWhiteSpacePipe } from '../lib/pipe/no-white-space.pipe';
import { PipesModule } from '../lib/pipe/pipes.module';
import { UnderscoreToSpacePipe } from '../lib/pipe/underscore-to-space.pipe';
import { DummyComponent } from './dummy/dummy.component';
// import { FakeLoader } from './model/fake-loader';

export const routes: any[] = [
  { path: '**', component: DummyComponent }
];

@NgModule({
  declarations: [DummyComponent],
  imports: [
    CommonModule,
    NoopAnimationsModule,
    RouterTestingModule.withRoutes(routes),
    HttpClientTestingModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useValue: new FakeLoader()
    //   }
    // }),
    ToastrModule.forRoot(),
    PipesModule
  ],
  exports: [ RouterTestingModule, PipesModule, ToastrModule],
  providers: [
    UnderscoreToSpacePipe,
    NoWhiteSpacePipe,
    // AvatarPipe
  ]
})
export class TestModule { }
