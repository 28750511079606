<div class="container mt-4">

    <div class="d-flex align-items-center">
        <p class="fs-3 fw-700 my-3  fw-bold" joyrideStep="one" title="Affiliates Dashboard" text="Welcome to your affiliates dashboard." stepPosition="left">
            <iconify-icon icon="carbon:partnership" class="fs-3 ms-3"></iconify-icon>
            Affiliates
        </p>
    </div>

    <div class="mt-4">
        <div class="container px-0">
            <div class="row d-flex justify-content-between flex-wrap mt-4" joyrideStep="two" text="View the count of invited, subscribed, and pending affiliates by clicking here." stepPosition="left">
                <div class="col-xl-4 col-md-6 col-sm-12 my-3 my-xl-0" title="Total number of affiliates that you have invited so far">
                    <div class="d-flex flex-column doffice-bg rounded-2 shadow-lg px-4 p-3">
                        <h5 class="text-light">Total Affiliates Invited</h5>
                        <div class="row justify-content-between">
                            <div class="col">
                                <h1 class=" mt-2 text-light">
                                    {{this.affiliateMembershipStat?.totalAffiliateInvited || 0}}
                                </h1>
                                <p class="text-light">{{this.affiliateMembershipStat?.totalAffiliatesInvitedToday || 0}} conversions today.
                                </p>
                            </div>
                            <div class="col-auto position-relative ">
                                <div class="rounded-circle position-relative bottom-0 end-0 circle-icon-1 mx-3 mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2.3em" height="2.3em" viewBox="0 0 16 16">
                    <g fill="#369dbf">
                      <path
                        d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26l.001.002l4.995 3.178l1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 0 1 5.026-4.47L15.964.686Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215l7.494-7.494l1.178-.471l-.47 1.178Z"/>
                      <path
                        d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95l-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
                    </g>
                  </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 col-sm-12 my-3 my-xl-0 ">
                    <div class="d-flex flex-column px-3 overview-card refcard p-3 text-primary " title="Total number of affiliates that you invited and have subscribed or renewed their subscription">
                        <h5>Total Affiliates Subscribed</h5>
                        <div class="row justify-content-between">
                            <div class="col">
                                <h1 class=" mt-2">
                                    {{this.affiliateMembershipStat?.totalAffiliateSubscribed || 0}}
                                </h1>
                                <p class="">{{this.affiliateMembershipStat?.totalAffiliateSubscribedToday || 0}} subscriptions today.
                                </p>
                            </div>
                            <div class="col-auto position-relative ">
                                <div class="rounded-circle position-relative bottom-0 end-0 circle-icon-2 mx-3 mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2.3em" height="2.3em" viewBox="0 0 32 32">
                    <path fill="white" d="m22 27.18l-2.59-2.59L18 26l4 4l8-8l-1.41-1.41L22 27.18z"/>
                    <path fill="white"
                          d="M25 5h-3V4a2.006 2.006 0 0 0-2-2h-8a2.006 2.006 0 0 0-2 2v1H7a2.006 2.006 0 0 0-2 2v21a2.006 2.006 0 0 0 2 2h9v-2H7V7h3v3h12V7h3v11h2V7a2.006 2.006 0 0 0-2-2Zm-5 3h-8V4h8Z"/>
                  </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-12 col-sm-12 my-3 my-xl-0 two" title="Total number of affiliates that are yet to subscribe or renew their subscription">
                    <div class="d-flex flex-column px-3 overview-card refcard p-3">
                        <h5>Total Affiliates Pending</h5>
                        <div class="row justify-content-between">
                            <div class="col">
                                <h1 class=" mt-2">
                                    {{this.affiliateMembershipStat?.totalAffiliatePending || 0}}
                                </h1>
                                <p class="">Expired / Yet to subscribe.</p>
                            </div>
                            <div class="col-auto position-relative ">
                                <div class="rounded-circle position-relative bottom-0 end-0 circle-icon-3 mx-3 mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2.3em" height="2.3em" viewBox="0 0 24 24">
                    <path fill="white"
                          d="M12 2c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12S6.5 2 12 2m0 2c-1.9 0-3.6.6-4.9 1.7l11.2 11.2c1-1.4 1.7-3.1 1.7-4.9c0-4.4-3.6-8-8-8m4.9 14.3L5.7 7.1C4.6 8.4 4 10.1 4 12c0 4.4 3.6 8 8 8c1.9 0 3.6-.6 4.9-1.7Z"/>
                  </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="search-input mt-5">
            <div class="row">
                <div class="col">
                    <p class="amount">Total Subscription: {{(this.affiliateMembershipStat?.totalSubscriptionAmount || 0 | noRoundCurrency) }}</p>
                </div>
                <div class="col">
                    <p class="amount float-end right">Total Commission: {{(this.affiliateMembershipStat?.totalCommissionDue || 0 | noRoundCurrency)}}</p>
                </div>
            </div>
            <div class="overview-card p-3" joyrideStep="four" title="Table Filter" text="Click here to filter affiliates by name, subscription status, commission, date and due commission." stepPosition="left">
                <div *ngIf="showErrorMessageTrigger">
                    <div class="alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert" [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                        <p class="m-0 p-0 d-flex align-items-start">
                            <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                              {{ getErrorMessage() | removeUnderscores }}
              </span>
                            <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                            </iconify-icon>
                        </p>
                    </div>
                </div>
                <div class="container-fluid">
                    <form [formGroup]="this.form" class="row g-3">
                        <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                            <input type="text" class="form-control" id="ptn" placeholder="e.g John Doe" formControlName="name">
                            <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Name</label>
                        </div>
                        <div class="col-xl-3 col-md-4 col-sm-6 mb-0 mb-md-0">
                            <select class="form-select" style="line-height: 1.8" id="fs" aria-label="floating-label select" formControlName="subscriptionStatusConstant">
                <option [selected]="true" [value]="''" style="font-size: 16px !important"> Subscription Status</option>
                <option *ngFor="let item of this.subscriptionStatusConstantEnum" [value]="item">
                  {{item | uppercase | removeUnderscores}}
                </option>
              </select>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0 " style="margin-top: -.6rem;">
                            <div class="">
                                <label class="form-label rfr-label"> Date (from) </label>
                                <div class="input-group">
                                    <input class="form-control" formControlName="startDate" placeholder="MM-DD-YYYY" ngbDatepicker #startDate="ngbDatepicker" [minDate]="{ year: 1910, month: 1, day: 1 }" [(ngModel)]="model" [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                                        (click)="startDate.toggle()" />
                                    <span (click)="startDate.toggle()" class="btn-primary-outline input-group-text">
                               <i class="fa fa-calendar"></i>
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0" style="margin-top: -.6rem;">
                            <div class="">
                                <label class="form-label rfr-label"> Date (to) </label>
                                <div class="input-group">
                                    <input class="form-control" placeholder="MM-DD-YYYY" formControlName="endDate" [minDate]="{ year: 1910, month: 1, day: 1 }" [(ngModel)]="model1" [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }" ngbDatepicker #endDate="ngbDatepicker"
                                        (click)="endDate.toggle()" />
                                    <span (click)="endDate.toggle()" class="btn-primary-outline input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-12 mb-md-0  float-end" style="white-space: nowrap;">
                            <button class="bg-primary rounded-1 border-0 d-flex justify-content-center align-items-center px-3 text-light float-end" style=" margin-top: 3px; padding: .35rem;" (click)="onSearchClick()">
                <iconify-icon inline icon="eos-icons:bubble-loading" class="me-2 fs-5" style="color: white;"
                              *ngIf="searching" width="25" height="25"></iconify-icon>
                <iconify-icon inline icon="iconoir:search" class="me-2 fs-5" width="25" height="25"
                              *ngIf="!searching"></iconify-icon>
                Apply Filter
              </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>


        <section class="">
            <div class="row mt-5 mb-2">
                <div class="col-md col-sm-12">
                    <button class="delete-btn border-0 d-flex justify-content-center align-items-center px-3 text-light float-end" style=" margin-top: 3px; padding: .35rem;" joyrideStep="five" title="Delete" (click)="showOptionModal()" text="Delete single or multiple data."
                        stepPosition="left">
            <iconify-icon icon="fluent:delete-32-regular" style="color: white;" class="me-2 fs-5" width="25"
                          height="25"></iconify-icon>
            Delete
          </button>
                </div>
                <div class="col-md-auto col-sm-12">
                    <button class="export-csv border-0 d-flex justify-content-center align-items-center px-3 text-light float-end" style="  margin-top: 3px; padding: .35rem;" (click)="downloadPDF()" joyrideStep="six" title="Export Data" text="Click here to download single or multiple data."
                        stepPosition="left">
            <iconify-icon icon="bi:filetype-pdf" style="color: white;" class="me-2 fs-5" width="25"
                          height="25"></iconify-icon>
            Export as PDF
          </button>
                </div>
            </div>
            <div class="overview-card-table" id="table">
                <div class="p-3">
                    <div class="table-responsive" [formGroup]="form">
                        <table class="table table-hover table-responsive" joyrideStep="three" title="Affiliate Table" text="View detailed affiliate information by name, subscription status, date and  due commissions." stepPosition="left">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)">
                                        </div>
                                    </th>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Date Joined</th>
                                    <th scope="col">Affiliate/DO Name</th>
                                    <th scope="col">Subscription Status</th>
                                    <th scope="col">Amount Paid</th>
                                    <th scope="col">Date Paid</th>
                                    <th scope="col">Due Commission</th>
                                    <th scope="col">Commission Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let aff of this.resultSubject.value.results; let i = index;" [id]="'action' + i">
                                    <td data-label="Select" class=""><input class="form-check-input" type="checkbox" (change)="singleCommissionCheck($event, i)" [checked]="resultSubject.value.results[i].checked"></td>
                                    <td data-label="ID"> {{ offset + i + 1 }}</td>
                                    <td data-label="Date Joined" class=" text-muted ">{{(formatUTCMoment(aff.dateCreated) | date: 'd MMM, y h:mm a': this.utcOffsetMinutes) || 'N/A'}}
                                    </td>
                                    <td data-label="Affiliate/DO Name" class="text-muted position-relative ">
                                        {{aff.name}}
                                        <span (click)="showMoreInfo(aff)" class="ms-1 border-0 rounded-5 info text-light float-right" [ngClass]="{'bg_green': aff.subscriptionStatusConstant == this.subscriptionStatusConstant.Active,
                'bg_red': aff.subscriptionStatusConstant == null || aff.subscriptionStatusConstant == this.subscriptionStatusConstant.Expired}" title="See more details">i</span>
                                    </td>
                                    <td data-label="Subscription Status" class="text-muted ">{{(aff.subscriptionStatusConstant | removeUnderscores | uppercase) || 'N/A'}}</td>
                                    <td data-label="Amount Paid" class="text-muted ">{{(aff.amountPaid | currency:'USD':'symbol') || 'N/A'}}</td>
                                    <td data-label="Date Paid" class="text-muted ">{{aff.subscriptionDatePaid ? (formatUTCMoment(aff.subscriptionDatePaid) | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes) : 'N/A'}}</td>
                                    <td data-label="Due Commission" class="text-muted ">{{(aff.dueCommission | noRoundCurrency) || 'N/A'}}</td>
                                    <td data-label="Commission Type" class="text-muted ">{{(aff.commissionTypeConstant | removeUnderscores) || 'N/A'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div><span *ngIf="!this.resultSubject.value.results || this.resultSubject.value.total==0 " class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder ">
      {{this.resultSubject.value.results ? "NO DATA/RECORDS " : "APPLY FILTERS TO VIEW DETAILS " }}
      </span>
                    </div>
                    <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3 " style="margin-bottom: -1rem; color: #4e3367 ">
                        <app-page-length [pageLength]="getFilter().limit || 10 " (pageLengthChanged)="changeLimit($event) "></app-page-length>
                        <div class="add-group-btn text-right mt-3 mb-3 ">
                            <pagination (pageChanged)="onPageChange($event) " [boundaryLinks]="true " [itemsPerPage]="limit || 10 " [(ngModel)]="page " [maxSize]="5 " [totalItems]="this.resultSubject.value.total || 0 " firstText="&laquo; " lastText="&raquo;
                                    " nextText="&rsaquo; " previousText="&lsaquo; "></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>