import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {
  CommissionLogPojo,
  QueryResultsPojoWalletTransactionPojo,
  WalletTransactionControllerService,
  WalletTransactionLogsFilter, WalletTransactionPojo
} from "dd-core-api-sdk";
import {BehaviorSubject} from "rxjs";
import * as moment from "moment";
import {getOffset, removeUndefinedOrNullFields} from "../../../../../models/search-model";
import {PageChangedEvent} from "ngx-bootstrap/pagination";
import {UserKeycloak} from "../../../../../services/UserKeycloak";
import {AuthenticationServiceKeycloak} from "../../../../../services/AuthenticationServiceKeycloak";
import {Utils} from "../../../../../shared/utils";
import WalletTransactionStatusEnum = WalletTransactionLogsFilter.WalletTransactionStatusEnum;
import {MoreInfoModalComponent} from "../../../../../shared/more-info-modal/more-info-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {
  AffiliateErrorInfoModalComponent
} from "../../../../../shared/affiliate-error-info-modal/affiliate-error-info-modal.component";

@Component({
  selector: 'app-affiliate-withdrawal-history',
  templateUrl: './affiliate-withdrawal-history.component.html',
  styleUrls: ['./affiliate-withdrawal-history.component.css']
})
export class AffiliateWithdrawalHistoryComponent implements OnInit {

  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  form: FormGroup;
  utcOffsetMinutes: string;
  model: NgbDateStruct
  model1: NgbDateStruct
  currentStartDate: any;
  filterData: WalletTransactionLogsFilter = {};
  searching: boolean = false;
  resultArray: any[];
  limit = 10;
  offset: any;
  page: any;
  queryResults!: QueryResultsPojoWalletTransactionPojo;
  walletTransactionStatusEnum = Utils.enumValues(WalletTransactionStatusEnum);
  resultSubject = new BehaviorSubject<QueryResultsPojoWalletTransactionPojo>({});
  data: any;
  user: UserKeycloak;
  constructor(private authenticationServiceKeycloak: AuthenticationServiceKeycloak,
              private modalService: BsModalService,
              private fb: FormBuilder, private walletTransactionControllerService: WalletTransactionControllerService) {
    this.utcOffsetMinutes = 'UTC' + this.getTimeZone().toString();
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      walletTransactionCode:[''],
      walletTransactionStatus:[''],
      startDate: [''],
      endDate: [''],
      limit: [''],
      offset: [''],
    });

    this.form.controls.startDate.valueChanges.subscribe(v => {
      this.currentStartDate = v;
      if (v != moment(v).format('YYYY-MM-DD')) {
        this.form.patchValue({endDate: ''});
      }
    });

    this.form.controls.endDate.valueChanges.subscribe(v => {
      if (moment(this.currentStartDate) > moment(v)) {
        this.isError = true;
        this.showErrorMessage('Date(from) should be greater than Date(to).');
        this.form.patchValue({endDate: ''});
      }
    });
    this.authenticationServiceKeycloak.getUser().subscribe(v => {
      this.user = v;
    })
    this.onSearchClick();
  }

  onSearchClick() {
    if (!this.form?.valid) {
      this.showErrorMessage('Form is invalid.');
      return;
    }
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);

    this.search(filter);
  }

  search(filter: WalletTransactionLogsFilter) {
    this.searching = true;
    this.walletTransactionControllerService.searchWithdrawalHistory({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.offset = v.offset;
      this.queryResults = v;
      this.resultArray = v.results;
      this.resultSubject.next(v);
    }, () => {

    });

    this.data = filter;
  }

  getFilter(limit?: number): WalletTransactionLogsFilter {
    const data = this.form!.value;

    let filter: WalletTransactionLogsFilter = {};
    filter.portalUserId = this.user.id;
    filter.walletTransactionCode = data.walletTransactionCode;
    filter.walletTransactionStatus = data.walletTransactionStatus;
    filter.startDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }
  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }
  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  formatUTCMoment(dateCreated: string) {
    return moment(dateCreated).subtract(1, 'hours').utc(true).toString();
  }
  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }

  onPageChange(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.search(filter);
  }

  showMoreInfo(aff: WalletTransactionPojo) {
    let bsModalRef = this.modalService.show(AffiliateErrorInfoModalComponent, {
      initialState: {
        infoTitle: 'More Details',
        walletTransactionPojo: aff
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.closeClicked.subscribe(v => {
      bsModalRef.hide();
    });
  }

}
