import {Component, Input, OnInit} from '@angular/core';
import * as moment from "moment/moment";

import {
  DentalReferralControllerService,
  QueryResultsReferralDraft, ReferralDraft,
  ReferralDraftSearchFilter,
  ReferralRequestSearchFilter,
  ReferralSearchPojo
} from "dd-core-api-sdk";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {SelectionManager} from "dd-shared-lib";
import {BehaviorSubject} from "rxjs";
import {UserKeycloak} from "../../services/UserKeycloak";
import {Router} from "@angular/router";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {PageChangedEvent} from "ngx-bootstrap/pagination";
import {getOffset, removeUndefinedOrNullFields} from "../../models/search-model";


@Component({
  selector: 'app-referral-draft-list',
  templateUrl: './referral-draft-list.component.html',
  styleUrls: ['./referral-draft-list.component.css']
})
export class ReferralDraftListComponent implements OnInit {

  form: FormGroup;
  model: NgbDateStruct
  model1: NgbDateStruct
  selectionManager: SelectionManager<ReferralSearchPojo, number>;
  filterData: ReferralRequestSearchFilter = {};
  searching: boolean = false;
  resultArray: any[];
  limit = 10;
  offset: any;
  page: any;
  queryResults!: QueryResultsReferralDraft;
  resultSubject = new BehaviorSubject<Array<ReferralDraft>>([]);
  total: number
  data: any;
  userAccount: UserKeycloak;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  currentStartDate: any;

  constructor(private fb: FormBuilder,
              private router: Router,
              private authentication: AuthenticationServiceKeycloak,
              private dentalReferralController: DentalReferralControllerService) {
    class ItemsSelect extends SelectionManager<any, any> {

      getIdentifier(e: any) {
        return e;
      }

      getState(e: any): number {
        return e.id;
      }

      isSelectable(e: any): boolean {
        return true;
      }
    }

    this.selectionManager = new ItemsSelect();
  }

  ngOnInit(): void {
    this.authentication.getUser().subscribe(res => {
      this.userAccount = res;
    })

    this.form = this.fb.group({
      patientName: [''],
      startDate: [''],
      endDate: [''],
      limit: [''],
      offset: ['']
    });
    this.onSearchClick();

    this.form.controls.startDate.valueChanges.subscribe(v => {
      this.currentStartDate = v;
      if (v != moment(v).format('YYYY-MM-DD')) {
        this.form.patchValue({endDate: ''});
      }
    });

    this.form.controls.endDate.valueChanges.subscribe(v => {
      if (moment(this.currentStartDate) > moment(v)) {
        this.showErrorMessage('Date(from) should be greater than Date(to).');
        this.form.patchValue({endDate: ''});
      }
    });
  }

  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  onPageChange(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.search(filter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }

  onSearchClick() {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);

    this.search(filter);
  }

  search(filter: ReferralDraftSearchFilter) {
    this.selectionManager.clearSelection();
    this.searching = true;
    this.dentalReferralController.searchReferralDraft({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.offset = v.offset;
      this.queryResults = v;
      this.total = v.total;
      this.resultArray = v.results;
      this.resultSubject.next(v.results);
    }, () => {

    });

    this.data = filter;
    // this.data.page = this.page;
  }

  getFilter(limit?: number): ReferralDraftSearchFilter {
    const data = this.form!.value;

    let filter: ReferralDraftSearchFilter = {};
    filter.patientName = data.patientName;
    filter.dentalOfficeId = AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id;
    filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.startDate = data.startDate ?  moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }

  deleteDraft(draft: ReferralDraft) {
    this.dentalReferralController.saveReferralDraft({
      referralDraftDto: {
        draftCode: draft.draftCode,
        sent: true
      }
    }).subscribe(res => {
      this.onSearchClick()
    })
  }

  getAge(date) {
    if (!date) return '';
    let now = moment(new Date());
    let end = moment(date);
    let duration = moment.duration(now.diff(end));
    return Math.round(duration.asYears());
  }

  formatDate(date) {
    return moment(date).format('MM/DD/YYYY')
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }


}

