import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ReferralSearchPojo} from "dd-core-api-sdk";
import ReferralStatusConstantEnum = ReferralSearchPojo.ReferralStatusConstantEnum;

@Component({
  selector: 'app-option-selection-modal',
  templateUrl: './option-selection-modal.component.html',
  styleUrls: ['./option-selection-modal.component.css']
})
export class OptionSelectionModalComponent implements OnInit {

  @Output() onSubmitButtonClick = new EventEmitter();
  @Output() onDismissButtonClick = new EventEmitter();
  @Input() modalMessage: String;
  @Input() modalTitle: String;
  @Input() updateBtnText: String;
  @Input() noteRequired;

  @Input() status: ReferralStatusConstantEnum;

  statusUpdateForm: FormGroup;
  formError = false;
  errMessage: any;
  showErrorMessageTrigger: boolean;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.statusUpdateForm = this.fb.group({
      note: ['', [Validators.maxLength(100)]],
      appointmentDate: [''],
      estimatedInsurancePortion: [''],
      estimatedPatientPortion: [''],
      referralPostReport: [''],
      reportContentType: [''],
      reportName: ['']
    })

    // this.addValidators();
  }

  addValidators() {
    switch (this.status) {
      case 'SCHEDULED':
        this.statusUpdateForm.get('appointmentDate').setValidators([Validators.required]);
        this.modalMessage = 'Set Appointment Date';
        break;
      case 'RESCHEDULED':
        this.statusUpdateForm.get('appointmentDate').setValidators([Validators.required]);
        this.modalMessage = 'Set New Appointment Date';
        break;
      case "COMPLETED":
        break;
    }
  }

  onFileChange(event) {
    let f = event.target.files[0];
    if (f.size > 1024 * 1024 * 10) {
      this.showErrorMessage('File size must not be more than 10MB');
      return;
    }

    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "image/gif", "application/pdf"];
    const isValidExtension = validExtensions.indexOf(f.type) > -1;

    if (!isValidExtension) {
      this.showErrorMessage('Invalid File Type. Select a PDF or an image');
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        this.statusUpdateForm.patchValue({referralPostReport: reader.result});
        this.statusUpdateForm.patchValue({reportContentType: f.type});
        this.statusUpdateForm.patchValue({reportName: f.name});
      }
    }
  }
  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 5000);
  }

  close() {
    this.onDismissButtonClick.emit();
  }

  submit() {
    if (this.statusUpdateForm.invalid) {
      this.formError = true;
      return;
    }
    this.formError = false;
    this.onSubmitButtonClick.emit(this.statusUpdateForm.getRawValue());
  }
}
