<div class="mt-5 mx-3">
  <div class="row g-3 d-flex align-items-center justify-content-center" style="margin-top:100px">
    <!--              <div class="col-auto">-->
    <!--                <button (click)="signInWithFB()" class="btn btn-outline-primary float-end p-1 mx-1">-->
    <!--                  <iconify-icon icon="fa-brands:facebook-f" style="color: #247d9a" width="14" height="14"></iconify-icon>-->
    <!--                  Sign-up with Facebook-->
    <!--                </button>-->
    <!--              </div>-->
    <div class="col-auto">
      <span id="googleBtn">Sign-up with google</span>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-center">

    <div class="col-md-7 col-12 p-lg-4 p-2">
      <div>
        <form [formGroup]="form" class="mt-4">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="row">
              <div class="col-md-6 pl-0 pr-1 mb-4">
                <div class="form-outline">
                  <label class="form-label required" for="firstName"
                  >First Name</label
                  >
                  <input
                    type="text"
                    id="firstName"
                    formControlName="firstName"
                    class="form-control form-control-lg"
                    placeholder="e.g Jane"
                  />
                  <app-field-error
                    *ngIf="
                      (form.get('firstName')?.errors?.minlength &&
                        form.get('firstName')?.touched) ||
                      (form.get('firstName')?.errors?.minlength &&
                        errorSubmission)
                    "
                    [message]="'First name cannot be less than two(2).'"
                  ></app-field-error>

                  <app-field-error
                    *ngIf="
                      (form.get('firstName')?.errors?.maxlength &&
                        form.get('firstName')?.touched) ||
                      (form.get('firstName')?.errors?.maxlength &&
                        errorSubmission)
                    "
                    [message]="'First name cannot be more than fifty(50).'"
                  ></app-field-error>

                  <app-field-error
                    *ngIf="
                      (form.get('firstName')?.errors?.required &&
                        form.get('firstName')?.touched) ||
                      (form.get('firstName')?.errors?.required &&
                        errorSubmission)
                    "
                    [message]="'First name cannot be blank.'"
                  ></app-field-error>
                </div>
              </div>
              <div class="col-md-6 pl-1 pr-0 mb-4">
                <div class="form-outline">
                  <label class="form-label required">Last Name</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    formControlName="lastName"
                    placeholder="e.g Doe"
                  />
                  <app-field-error
                    *ngIf="
                      (form.get('lastName')?.errors?.minlength &&
                        form.get('lastName')?.touched) ||
                      (form.get('lastName')?.errors?.minlength &&
                        errorSubmission)
                    "
                    [message]="'Last name cannot be less than two(2).'"
                  ></app-field-error>

                  <app-field-error
                    *ngIf="
                      (form.get('lastName')?.errors?.maxlength &&
                        form.get('lastName')?.touched) ||
                      (form.get('lastName')?.errors?.maxlength &&
                        errorSubmission)
                    "
                    [message]="'Last name cannot be more than fifty(50).'"
                  ></app-field-error>

                  <app-field-error
                    *ngIf="
                      (form.get('lastName')?.errors?.required &&
                        form.get('lastName')?.touched) ||
                      (form.get('lastName')?.errors?.required &&
                        errorSubmission)
                    "
                    [message]="'Last name cannot be blank.'"
                  ></app-field-error>
                </div>
              </div>
              <!-- Email input -->
              <div class="form-outline mb-4">
                <label class="form-label required" for="loginEmail"
                >Email Address
                </label>
                <input
                  type="text"
                  id="loginEmail"
                  class="form-control form-control-lg"
                  formControlName="email"
                  placeholder="someone@example.com"
                />

                <app-field-error
                  *ngIf="form.get('email')?.errors?.emailExists"
                  [message]="'Email address already exist.'"
                ></app-field-error>

                <app-field-error
                  *ngIf="
                    this.form.get('email').errors?.pattern &&
                    form.get('email')?.touched
                  "
                  [message]="'Invalid Email address pattern.'"
                ></app-field-error>

                <app-field-error
                  *ngIf="
                    (form.get('email')?.errors?.required &&
                      form.get('email')?.touched) ||
                    (form.get('email')?.errors?.required && errorSubmission)
                  "
                  [message]="'Email address cannot be blank.'"
                ></app-field-error>
              </div>
              <div id="number" class="form-outline mb-4">
                <label class="form-label required">Phone Number</label>
                <ngx-intl-tel-input
                  [cssClass]="'form-control form-control-lg fresh'"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [id]="'phoneNumber'"
                  [maxLength]="'20'"
                  [phoneValidation]="true"
                  [preferredCountries]="preferredCountries"
                  [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                  [searchCountryFlag]="true"
                  [selectedCountryISO]="countryISO"
                  [selectFirstCountry]="true"
                  formControlName="phoneNumber"
                ></ngx-intl-tel-input>
                <app-field-error
                  *ngIf="
                      (form.get('phoneNumber')?.errors?.required &&
                        form.get('phoneNumber')?.touched) ||
                      (form.get('phoneNumber')?.errors?.required &&
                        errorSubmission)
                    "
                  [message]="'Phone number cannot be blank.'"
                ></app-field-error>
                <app-field-error
                  class="mt-1"
                  [message]="'Phone number already exist.'"
                  *ngIf="
                      form.get('phoneNumber')!.errors
                        ?.phoneNumberExists
                    "
                ></app-field-error>
                <app-field-error
                  class="mt-1"
                  [message]="'Phone number is invalid.'"
                  *ngIf="
                      form.get('phoneNumber')!.errors
                        ?.invalidPhoneNumber
                    "
                ></app-field-error>
              </div>
              <div class="form-outline mb-4">
                <label class="form-label required" for="loginEmail">Office Name</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  ngx-google-places-autocomplete
                  [options]="placesConfig"
                  formControlName="officeName"
                  placeholder="Office Name"
                  (onAddressChange)="getPlacesAutocomplete($event)"
                />

                <small *ngIf="form.get('officeName')?.errors?.officeNotFound">N.B Your office must exist on DentalDoor. Click on the <a style="text-decoration: underline; margin-top: 10px; cursor: pointer" (click)="emitOfficeNotFoundLink.emit()">link</a> to sign up your office.</small>

                <app-field-error
                  *ngIf="
                    (form.get('officeName')?.errors?.required &&
                      form.get('officeName')?.touched) ||
                    (form.get('officeName')?.errors?.required && errorSubmission)
                  "
                  [message]="'Office Name cannot be blank.'"
                ></app-field-error>
              </div>

              <div class="mb-4">
                <!-- Password input -->
                <div class="form-outline mb-4">
                  <label class="form-label required" for="pass">Password</label>
                  <div class="input-group">
                    <input
                      class="form-control form-control-lg"
                      placeholder="Password"
                      id="pass"
                      formControlName="newPassword"
                      [type]="displayPassword ? 'text' : 'password'"/>
                    <div class="input-group-append">
                      <span class="input-group-text bg-white px-1 input-bn">
                        <iconify-icon
                          *ngIf="!displayPassword; else open"
                          (click)="togglePasswordDisplay()"
                          icon="ant-design:eye-invisible-outlined"
                          style="color: #247d9a "
                          width="40"
                          height="20"></iconify-icon>
                        <ng-template #open>
                          <iconify-icon
                            (click)="togglePasswordDisplay()"
                            icon="ant-design:eye-outlined"
                            style="color:#247d9a "
                            width="40"
                            height="20"></iconify-icon>
                        </ng-template>
                      </span>
                    </div>
                  </div>
                  <app-field-error
                    class="mt-1"
                    [message]="'Password is poor.'"
                    *ngIf="
                      form.get('newPassword')!.errors?.notStrong"></app-field-error>
                  <app-field-error
                    *ngIf="(form.get('newPassword')?.errors?.required &&
                        form.get('newPassword')?.touched) ||
                      (form.get('newPassword')?.errors?.required &&
                        errorSubmission)"
                    [message]="'Password cannot be blank.'"></app-field-error>
                  <app-field-error
                    *ngIf="
                      (form.get('newPassword')?.errors?.minlength &&
                        form.get('newPassword')?.touched) ||
                      (form.get('newPassword')?.errors?.minlength &&
                        errorSubmission)
                    "
                    [message]="'Password cannot be less than two(2).'"
                  ></app-field-error>

                  <app-field-error
                    *ngIf="
                      (form.get('newPassword')?.errors?.maxlength &&
                        form.get('newPassword')?.touched) ||
                      (form.get('newPassword')?.errors?.maxlength &&
                        errorSubmission)
                    "
                    [message]="'Password cannot be more than fifty(50).'"
                  ></app-field-error>
                </div>
                <app-ng-password-strength-meter [passwordToCheck]="form.get('newPassword').value"
                                                (passwordStrength)="onPasswordStrengthChanged($event)"></app-ng-password-strength-meter>
              </div>
            </div>
          </div>

          <!-- Checkbox -->
          <div class="my-4">
            <div class="form-check d-flex justify-content-left">
              <input
                class="form-check-input me-2"
                type="checkbox"
                formControlName="acceptTerms"
                id="termsCheck"
                aria-describedby="registerCheckHelpText"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    form.get('acceptTerms')?.errors &&
                    form.get('acceptTerms')?.touched
                }"
              />
              <label for="termsCheck" class="form-check-label p-2">
                I agree with DentalDoors'
                <a routerLink="/terms-and-conditions">Terms of Use</a>,
                <a routerLink="/review-privacy"> Privacy and Cookie Policy</a>.
              </label>
            </div>
            <app-field-error
              *ngIf="
                (form.get('acceptTerms')?.errors?.required &&
                  form.get('acceptTerms')?.touched) ||
                (form.get('acceptTerms')?.errors?.required && errorSubmission)
              "
              [message]="
                'You must agree to the terms of use, privacy and cookie policy.'
              "
            ></app-field-error>
          </div>

          <!-- Submit button -->
          <button
            type="submit"
            class="btn btn-primary btn-block mb-4 w-100"
            (click)="this.form.valid ? submit() : submittedForError()" [disabled]="submitting"
          >
            <iconify-icon
              *ngIf="submitting"
              icon="eos-icons:bubble-loading"
              style="color: #ffffff "></iconify-icon>
            Join
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
<app-full-page-loader [opacity]="0.7" *ngIf="submittingSocial"></app-full-page-loader>

