import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {TemporaryDentalOffice, TemporaryDentalOfficeControllerService, TemporaryDentalOfficeDto} from "dd-core-api-sdk";
import {PhoneNumberValidator} from "dd-shared-lib";
import {DeliverableEmailValidator} from "../../../services/deliverable-email.validator";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-temp-dental-office-modal',
  templateUrl: './temp-dental-office-modal.component.html',
  styleUrls: ['./temp-dental-office-modal.component.css'],
  providers: [DeliverableEmailValidator]
})
export class TempDentalOfficeModalComponent implements OnInit {


  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.UnitedStates;


  placesConfig: any = {
    componentRestrictions: {country: ['US'],}
    // ,
    // types: ['hospital', 'dentist', 'doctor','drugstore', 'pharmacy']
  }

  form: FormGroup;
  formError = false;
  errMessage: any;
  showErrorMessageTrigger: boolean;
  @Input() tempDentalOffice: TemporaryDentalOffice;

  @Output() actionCompleted = new EventEmitter<string>();


  successMessage: any;
  showSuccessMessageTrigger: boolean;

  emailRegex = "^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$";

  loading = false;

  constructor(private fb: FormBuilder,
              private temporaryDentalOfficeService: TemporaryDentalOfficeControllerService,
              private bsModalService: BsModalService,
              private deliverableEmailValidator: DeliverableEmailValidator) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.tempDentalOffice?.name || '', [Validators.required]],
      email: [this.tempDentalOffice?.email || '', {
        Validators: [Validators.required, Validators.maxLength(50), Validators.pattern(this.emailRegex)],
        asyncValidators: [this.deliverableEmailValidator.emailExistsValidator()],
        updateOn: 'blur'
      }],
      alternateEmail: [this.tempDentalOffice?.alternateEmail || '', {
        Validators: [Validators.maxLength(50), Validators.pattern(this.emailRegex)],
        asyncValidators: [this.deliverableEmailValidator.emailExistsValidator()],
        updateOn: 'blur'
      }],
      address: [this.tempDentalOffice?.addressDetails || '', [Validators.required]],
      phoneNumber: [this.tempDentalOffice?.phoneNumber || '', [PhoneNumberValidator()]],
      fax: [this.tempDentalOffice?.fax || '', []],
    }, { validators: this.differentEmailsValidator });

    if(this.tempDentalOffice?.name){
      this.form.get('name').disable();
    }
  }

  close() {

    this.bsModalService.hide();
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 5000);
  }

  showSuccessMessage(success: any) {
    this.successMessage = success;
    this.showSuccessMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showSuccessMessageTrigger = false;
    }, 5000);
  }

  getSuccessMessage() {
    return this.successMessage;
  }

  create() {

    this.form.get('name').enable();
    this.form.markAllAsTouched();

    if(this.form.invalid){
      this.formError = true;
      console.log(this.form.getRawValue());
      console.log(this.form);
      return;
    }
    const temporaryDentalOfficeDto: TemporaryDentalOfficeDto = {
      name: this.form?.value?.name,
      phoneNumber: this.form?.value?.phoneNumber?.e164Number,
      faxNumber: this.form?.value?.fax?.e164Number,
      email: this.form?.value?.email,
      alternateEmail: this.form?.value?.alternateEmail,
      address: this.form?.value?.address
    };

    this.loading = true;
      this.temporaryDentalOfficeService.createTempDentalOffice({temporaryDentalOfficeDto}).subscribe(() => {
        this.actionCompleted.emit('Dental Office Created Successfully');
        this.close();
      }, error => {
        this.showErrorMessage(error);
      }).add(() => {this.loading = false});
  }

  update() {

    this.form.get('name').enable();
    this.form.markAllAsTouched();

    if(this.form.invalid){
      this.formError = true;
      console.log(this.form.getRawValue());
      console.log(this.form);
      return;
    }
    const temporaryDentalOfficeDto: TemporaryDentalOfficeDto = {
      name: this.form?.value?.name,
      phoneNumber: this.form?.value?.phoneNumber?.e164Number,
      faxNumber: this.form?.value?.fax?.e164Number,
      email: this.form?.value?.email,
      alternateEmail: this.form?.value?.alternateEmail,
      address: this.form?.value?.address
    };

      this.loading = true;
      this.temporaryDentalOfficeService.updateTempDentalOffice({
        temporaryDentalOfficeId: this.tempDentalOffice.id,
        temporaryDentalOfficeDto: temporaryDentalOfficeDto
      }).subscribe(() => {
        this.actionCompleted.emit('Dental Office Updated Successfully');
        this.close();
      }, error => {
        this.showErrorMessage(error);
      }).add(() => {this.loading = false});

  }


  getPlacesAutocomplete(place: any) {
    this.form.get('name').patchValue(place.name);

    this.form.get('address').patchValue(place.formatted_address);
    this.form.get('phoneNumber').patchValue(place.international_phone_number);
  }

  differentEmailsValidator(group: FormGroup) {
    const email = group.get('email').value;
    const alternateEmail = group.get('alternateEmail').value;

    if (email && alternateEmail) {
      return email !== alternateEmail ? null : { sameEmails: true };
    }
    return null;
  }
}
