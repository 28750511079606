/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReferralRequestSearchFilter { 
    offset?: number;
    limit?: number;
    order?: ReferralRequestSearchFilter.OrderEnum;
    orderColumn?: string;
    endDate?: string;
    startDate?: string;
    referralStatusConstant?: ReferralRequestSearchFilter.ReferralStatusConstantEnum;
    dentalProfessionalToName?: string;
    dentalProfessionalPortalUserId?: number;
    dentalOfficeToName?: string;
    dentalOfficeFromAccountId?: number;
    dentalOfficeToId?: number;
    referralCode?: string;
    patientInfo?: string;
    priorityLevelConstant?: ReferralRequestSearchFilter.PriorityLevelConstantEnum;
}
export namespace ReferralRequestSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type ReferralStatusConstantEnum = 'PENDING' | 'SCHEDULED' | 'COMPLETED' | 'CUSTOM' | 'CANCELLED' | 'REJECTED' | 'NEW' | 'CALLED' | 'RESCHEDULED' | 'NO_SHOW';
    export const ReferralStatusConstantEnum = {
        Pending: 'PENDING' as ReferralStatusConstantEnum,
        Scheduled: 'SCHEDULED' as ReferralStatusConstantEnum,
        Completed: 'COMPLETED' as ReferralStatusConstantEnum,
        Custom: 'CUSTOM' as ReferralStatusConstantEnum,
        Cancelled: 'CANCELLED' as ReferralStatusConstantEnum,
        Rejected: 'REJECTED' as ReferralStatusConstantEnum,
        New: 'NEW' as ReferralStatusConstantEnum,
        Called: 'CALLED' as ReferralStatusConstantEnum,
        Rescheduled: 'RESCHEDULED' as ReferralStatusConstantEnum,
        NoShow: 'NO_SHOW' as ReferralStatusConstantEnum
    };
    export type PriorityLevelConstantEnum = 'NORMAL' | 'SEMI_URGENT' | 'URGENT' | 'EMERGENCY';
    export const PriorityLevelConstantEnum = {
        Normal: 'NORMAL' as PriorityLevelConstantEnum,
        SemiUrgent: 'SEMI_URGENT' as PriorityLevelConstantEnum,
        Urgent: 'URGENT' as PriorityLevelConstantEnum,
        Emergency: 'EMERGENCY' as PriorityLevelConstantEnum
    };
}


