import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "./components/home/home.component";
import {LoggedInGuard, RoutePermissionRestriction} from "./shared/logged-in-guard/logged-in.guard";
import {DentalSignupComponent} from "./components/signup/dental-signup/dental-signup.component";
import {SuccessDialogComponent} from "./shared/success-dialog/success-dialog.component";
import {LoginComponent} from "./components/login/login.component";
import {OneTimePasswordComponent} from "./components/one-time-password/one-time-password.component";
import {PrivacyPolicyComponent} from "./components/privacy-policy/privacy-policy.component";
import {TermsAndConditionsComponent} from "./components/terms-and-conditions/terms-and-conditions.component";
import {InvitesSignupComponent} from "./components/signup/invites-signup/invites-signup.component";
import {FaqComponent} from "./components/faq/faq.component";
import {SocialMedialSignUpDto} from "dd-core-api-sdk";
import AccountTypeEnum = SocialMedialSignUpDto.AccountTypeEnum;
import { ExtranetPricingComponent } from "./extranet-pricing/extranet-pricing.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { AfiliateTermsAndConditionsComponent } from "./afiliate-terms-and-conditions/afiliate-terms-and-conditions.component";
import {
  CreateOfficeAffiliateComponent
} from "./components/signup/create-office-affiliate/create-office-affiliate.component";
import {
  FirstContactWizardComponent
} from "./components/first-contact/first-contact-wizard/first-contact-wizard.component";
import { LastContactComponent } from "./components/first-contact/last-contact/last-contact.component";
import {ExtranetReferralDetailsComponent} from "./extranet-referral-details/extranet-referral-details.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    pathMatch: "full",
    data: {
      isExtranet: true,
      showNavBar: true,
      showFooter: true,
    },
  },
  {
    path: "login",
    component: LoginComponent,
    pathMatch: "full",
    data: {
      isExtranet: true,
      showNavBar: false,
      showFooter: false,
    },
  },
  {
    path: "signup",
    component: DentalSignupComponent,
    pathMatch: "full",
    data: {
      isExtranet: true,
      showNavBar: false,
      showFooter: false,
    },
  },
  {
    path: "office/:code",
    component: CreateOfficeAffiliateComponent,
    pathMatch: "full",
    data: {
      isExtranet: true,
      showNavBar: false,
      showFooter: false,
    },
  },
  {
    path: "verify-email",
    component: OneTimePasswordComponent,
    pathMatch: "full",
    data: {
      isExtranet: true,
      showNavBar: false,
      showFooter: false,
    },
  },
  {
    path: "success",
    component: SuccessDialogComponent,
    pathMatch: "full",
    data: {
      isExtranet: true,
      showNavBar: false,
      showFooter: false,
    },
  },
  {
    path: "pricing",
    component: ExtranetPricingComponent,
    pathMatch: "full",
    data: {
      isExtranet: true,
      showNavBar: true,
      showFooter: false,
    },
  },
  {
    path: "review-privacy",
    component: PrivacyPolicyComponent,
    data: {
      isExtranet: true,
      showNavBar: true,
      showFooter: true,
    },
  },
  {
    path: "terms-and-conditions",
    component: TermsAndConditionsComponent,
    data: {
      showNavBar: true,
      isExtranet: true,
      showFooter: true,
    },
  },

  {
    path: "faq",
    component: FaqComponent,
    data: {
      showNavBar: true,
      isExtranet: true,
      showFooter: true,
    },
  },

  {
    path: "invites",
    component: InvitesSignupComponent,
    data: {
      showNavBar: false,
      isExtranet: true,
      showFooter: false,
    },
  },

  {
    path: "affiliate-terms-and-conditions",
    component: AfiliateTermsAndConditionsComponent,
    data: {
      showNavBar: true,
      isExtranet: true,
      showFooter: true,
    },
  },
  {
    path: "first-contact/:code/:token",
    component: FirstContactWizardComponent,
    data: {
      showNavBar: false,
      isExtranet: true,
      showFooter: false,
    },
  },
    {
    path: "last-contact",
    component: LastContactComponent,
    data: {
      showNavBar: false,
      isExtranet: true,
      showFooter: false,
    },
  },
    {
    path: "extranet-referral-details",
    component: ExtranetReferralDetailsComponent,
    data: {
      showNavBar: true,
      isExtranet: true,
      showFooter: false,
    },
  },
  {
    path: "error",
    component: ErrorPageComponent,
    data: {
      showNavBar: false,
      isExtranet: true,
      showFooter: false,
    },
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule),
    data: {
      isExtranet: false,
      showNavBar: true,
      showFooter: true,
    }
  },
  {
    path: "in-bound",
    loadChildren: () =>
      import("./modules/dRefer/inbound-referral/inbound.module").then((m) => m.InboundModule),
    data: {
      isExtranet: false,
      showNavBar: true,
      showFooter: true,
    },
    canActivate: [LoggedInGuard],
  },
  {
    path: "out-bound",
    loadChildren: () =>
      import("./modules/dRefer/outbound-referrals/outbound.module").then(
        (m) => m.OutboundModule
      ),
    data: {
      isExtranet: false,
      showNavBar: true,
      showFooter: true,
    },
    canActivate: [LoggedInGuard],
  },

  {
    path: 'billing',
    loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule),
    data: {
      isExtranet: false,
      showNavBar: true,
      showFooter: true,
      permissions: [
        {
          accountType: AccountTypeEnum.DentalOffice,
        },
      ] as RoutePermissionRestriction[]
    },
    canActivate: [LoggedInGuard]
  },
  {
    path: 'invites-list',
    loadChildren: () => import('./modules/invites/invites.module').then(m => m.InvitesModule),
    data: {
      isExtranet: false,
      showNavBar: true,
      showFooter: true
    },
  },
  {
    path: 'accounts',
    loadChildren: () => import('./modules/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
    data: {
      isExtranet: false,
      showNavBar: true,
      showFooter: true
    },
    canActivate: [LoggedInGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    data: {
      isExtranet: false,
      showNavBar: true,
      showFooter: true,
      permissions: [
        {
          accountType: AccountTypeEnum.SuperAdmin,
        },
      ] as RoutePermissionRestriction[]
    },
    canActivate: [LoggedInGuard]
  },
  {
    path: 'affiliates',
    loadChildren: () => import('./modules/affiliates/affiliate.module').then(m => m.AffiliateModule)
  },
  {path: '**', redirectTo: '/'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
