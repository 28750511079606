<div class="container m-5">
  <div class="row ">
    <div class="col-lg-6 title">
      <h1>Join DentalDoor</h1>
      <p>Its easy, fast and convenient</p>
    </div>
    <div class="card col-lg-6">
      <div class="card-body">
        <h6 class="card-subtitle mb-2 text-muted">Let's get to know you more</h6>
        <div class="" style="width: auto">
          <form class="row g-3" [formGroup]="form">
            <div class="col-md-6 " style="
    padding-left: 0px;">
              <label for="firstName" class="form-label visually-hidden">First Name</label>
              <input type="text" class="form-control" id="firstName" placeholder="Jamie" formControlName="firstName">
            </div>
            <div class="col-md-6" style="
    padding-right: 0px;">
              <label for="lastName" class="form-label visually-hidden">Last Name</label>
              <input type="text" class="form-control" id="lastName" placeholder="Doe" formControlName="lastName">
            </div>
            <div class="form-outline mb-4">
              <label class="form-label required">Phone Number </label>
              <ngx-intl-tel-input [cssClass]="'form-control'" [enableAutoCountrySelect]="true"
                                  [enablePlaceholder]="true" [id]="'phoneNumber'" [maxLength]="'20'"
                                  [phoneValidation]="true"
                                  [preferredCountries]="preferredCountries"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [searchCountryFlag]="true"
                                  [selectedCountryISO]="countryISO" [selectFirstCountry]="true"
                                  formControlName="phoneNumber"></ngx-intl-tel-input>
              <app-field-error
                *ngIf="(form.get('phoneNumber')?.errors?.required && form.get('phoneNumber')?.touched) || (form.get('phoneNumber')?.errors?.required && errorSubmission)"
                [message]="'Phone number cannot be blank.'"></app-field-error>
              <app-field-error class="mt-1" [message]="'Phone number already exist.'"
                               *ngIf="form.get('phoneNumber')!.errors?.phoneNumberExists"></app-field-error>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  General Dentist
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Endodontist
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Orthodontist
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Periodontist
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Prediatric Dentist
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Oral Surgeon
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Dental Hygenist
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Dental Assistant
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Student
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Manager
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" >
                <label class="form-check-label" >
                  Front Desk
                </label>
              </div>
            </div>
            <div class="d-grid gap-2">
              <button class="btn btn-success" type="button" (click)="form.valid ? updateAccountAndSendOTP() : submittedForError()">Next</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
