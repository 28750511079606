/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GenericResourceSearchFilter { 
    resourceType: GenericResourceSearchFilter.ResourceTypeEnum;
    name?: string;
    code?: string;
    limit?: number;
    offset?: number;
}
export namespace GenericResourceSearchFilter {
    export type ResourceTypeEnum = 'DegreeType' | 'AgeGroup' | 'Race' | 'Title' | 'AppointmentCancellationReason';
    export const ResourceTypeEnum = {
        DegreeType: 'DegreeType' as ResourceTypeEnum,
        AgeGroup: 'AgeGroup' as ResourceTypeEnum,
        Race: 'Race' as ResourceTypeEnum,
        Title: 'Title' as ResourceTypeEnum,
        AppointmentCancellationReason: 'AppointmentCancellationReason' as ResourceTypeEnum
    };
}


