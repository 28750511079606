<div class="" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog m-0" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h4>
        <button
          (click)="close()" class="btn">
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="showErrorMessageTrigger">
          <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
            role="alert">
            <p class="m-0 p-0 d-flex align-items-start">
                <span>
                  <iconify-icon
                    class="iconify me-2"
                    icon="ep:info-filled"
                  ></iconify-icon>
                  {{ getErrorMessage() | removeUnderscores }}
                </span>
              <iconify-icon
                class="iconify fs-4 ms-auto pointer"
                icon="mdi:close"
                data-bs-dismiss="alert"
              >
              </iconify-icon>
            </p>
          </div>
        </div>
        <h5 class="m-3">{{modalMessage}}</h5>
        <div class="m-3" [formGroup]="statusUpdateForm">
          <div class="mb-4" *ngIf="status == 'SCHEDULED' || status == 'CUSTOM' || status == 'RESCHEDULED'">

                <div class="form-floating">
                  <input [owlDateTime]="dt2" placeholder="Select Appointment Time" class="form-control form-control-lg" id="ad"
                    [owlDateTimeTrigger]="dt2" formControlName="appointmentDate" readonly />
                  <label for="ad" class="form-label" style="font-size: 14px !important; overflow: inherit; ">Appointment Date</label>
                  <owl-date-time #dt2 [hour12Timer]="true"></owl-date-time>
                </div>

            <app-field-error
              *ngIf="(statusUpdateForm.get('appointmentDate')?.errors?.required
               && statusUpdateForm.get('appointmentDate')?.touched) || (statusUpdateForm.get('appointmentDate').errors?.required && formError)"
              [message]="'Appointment date cannot be blank.'"></app-field-error>
          </div>
          <div *ngIf="status == 'COMPLETED'">
            <div class="mb-4 form-floating">
              <input
                type="text"
                class="form-control text-start"
                currencyMask
                [align]="'left'"
                id="eip"
                [options]="{ allowNegative: false, prefix: '$ ', thousands: ',', decimal: '.' }"
                formControlName="estimatedInsurancePortion">
                <label class="form-label m-0" for="eip"  style="scale: 0.8; font-size: 14px !important; overflow: inherit; ">Estimated Insurance Portion</label>
            </div>

            <div class="mb-4 form-floating">
              <input
                type="text"
                class="form-control text-start"
                currencyMask
                [align]="'left'"
                id="epp"
                [options]="{ allowNegative: false, prefix: '$ ', thousands: ',', decimal: '.' }"
                formControlName="estimatedPatientPortion">
                <label class="form-label m-0" for="epp"  style="scale: 0.8; font-size: 14px !important; overflow: inherit; ">Estimated Patient Portion(for Analytics)</label>
            </div>

            <div class="form-floating">

              <input
                type="file"
                class="form-control"
                (change)="onFileChange($event)"
                id="upt"
                formControlName="referralPostReport"
                >
                <label class="form-label m-0" for="upt"  style="scale: 0.8; font-size: 14px !important; overflow: inherit; ">Upload Post Treatment Report</label>

              <p class="opacity-25 small mb-0">
                Only .pdf, .png, .jpg, .jpeg supported.
              </p>
              <app-field-error
                *ngIf="(statusUpdateForm.get('referralPostReport')?.errors?.required
              && statusUpdateForm.get('referralPostReport')?.touched) ||  (statusUpdateForm.get('referralPostReport').errors?.required && formError)"
                [message]="'please upload post treatment report'"></app-field-error>
            </div>

          </div>
          <div>
            <label for="statusNote" class="form-label m-0 rfr-label" style="scale: 0.8;">Status Note</label>
            <textarea
              formControlName="note" rows="2"
              id="statusNote" class="form-control"
              placeholder="Drop a note"
            ></textarea>
            <app-field-error
              *ngIf="(statusUpdateForm.get('note').errors?.required && (statusUpdateForm.get('note')?.touched))|| (statusUpdateForm.get('note').errors?.required && formError)"
              [message]="'give more detail about status.'"></app-field-error>
            <app-field-error
              *ngIf="(statusUpdateForm.get('note').errors?.maxLength && (statusUpdateForm.get('note')?.touched))"
              [message]="'Keep it short and simple'"></app-field-error>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary" (click)="submit()">{{updateBtnText}}</button>
      </div>
    </div>
  </div>
</div>
