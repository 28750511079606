import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import { HelpListComponent } from 'src/app/modules/admin/help-list/help-list.component';

@Component({
  selector: 'app-note-viewer',
  templateUrl: './note-viewer.component.html',
  styleUrls: ['./note-viewer.component.css']
})
export class NoteViewerComponent implements OnInit {

  @Input()
  notes: string;
  @Input()
  email: string;
  @Input()
  phoneNumber: string;


  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  dismissModal() {
    this.bsModalRef.hide();
  }

}
