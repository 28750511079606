<div style="text-align: center;">
  <div class="container-fluid wuc">
    <div class="panel panel-default">
      <div class="panel-body">
        <h2><i class="fa fa-exclamation-triangle p-3 mb-3"></i>The {{pageName}} page is still under construction.<i class="fa fa-exclamation-triangle p-3 mb-3"></i></h2>
        <p>Please Check Back Later</p>
      </div>
      <img src="assets/img/2.0/wuc.png" alt="">
    </div>
  </div>
</div>
