/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { School } from './school';
import { Address } from './address';
import { DentalOffice } from './dental-office';


export interface WorkAddress { 
    status?: WorkAddress.StatusEnum;
    dateCreated?: string;
    lastUpdated?: string;
    type?: WorkAddress.TypeEnum;
    id?: number;
    address?: Address;
    dentalOffice?: DentalOffice;
    school?: School;
}
export namespace WorkAddress {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type TypeEnum = 'HOME' | 'SCHOOL' | 'OFFICE';
    export const TypeEnum = {
        Home: 'HOME' as TypeEnum,
        School: 'SCHOOL' as TypeEnum,
        Office: 'OFFICE' as TypeEnum
    };
}


