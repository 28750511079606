import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserRegistrationService} from "dd-core-api-sdk";
import {KeycloakService} from "keycloak-angular";
import {SocialService} from "../../shared/subject/SocialService";

@Component({
  selector: 'app-verify-dental-office',
  templateUrl: './verify-dental-office.component.html',
  styleUrls: ['./verify-dental-office.component.css']
})
export class VerifyDentalOfficeComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userRegService: UserRegistrationService,
    private keycloak: KeycloakService,
    private socialService: SocialService,
  ) {
  }

  ngOnInit(): void {
    sessionStorage.clear();
    this.socialService.behaviorSubject.next('');

    this.activatedRoute.params.subscribe(param => {
      if (!param['userId']) {
        this.router.navigate(['/']);
        return;
      }
      this.updateAccountAndSendOTP(param['userId']);
    });

  }

  loginUserManually(username, password) {
    this.userRegService.login({loginDto: {username: username, password: password}}).subscribe(res => {
      this.socialService.save(res.accessToken);
      sessionStorage.setItem('keycloak_token', res.accessToken);
      this.router.navigate(['/dashboard']);
    }, err => {
      this.router.navigate(['/']);
    })
  }

  updateAccountAndSendOTP(userId: string) {
    this.userRegService.updateAccountInfo({userId: userId}).subscribe(res => {
      this.loginUserManually(res.email, res.authToken);
    }, error => {
      this.router.navigate(['/']);
    })
  }

}
