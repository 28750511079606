

import { Component, OnInit, AfterViewInit , ViewChildren, QueryList } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit, AfterViewInit {

  @ViewChildren('accordionIcon') accordionIconList: QueryList<any>;
  defaultElement: any;
  Environment = environment;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    this.defaultElement = this.accordionIconList.toArray()[0].nativeElement.classList;

  }

  toggleIcon(index) {
    const el = this.accordionIconList.toArray()[index].nativeElement.classList;


    if (el === this.defaultElement) {

      this.defaultElement.toggle('icon_plus_alt2');
    } else {
      el.add('icon_minus_alt2');
      el.remove('icon_plus_alt2');
      this.defaultElement.toggle('icon_plus_alt2', 'icon_minus_alt2');
    }

    this.defaultElement = el;

  }
}
