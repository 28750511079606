import { Component, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-referral-patient-modal',
  templateUrl: './referral-patient-modal.component.html',
  styleUrls: ['./referral-patient-modal.component.css']
})
export class ReferralPatientModalComponent implements OnInit {

  constructor(public bsModalService: BsModalService,
              private activatedRoute: ActivatedRoute,
              private router: Router,) { }

  ngOnInit(): void {
  }

  close() {
    console.log('close');
    this.bsModalService.hide();
  }
  goToRefer(){
    this.router.navigate(['/out-bound/create-referral']);
    this.bsModalService.hide();
  }
  goToInvite(){
    this.router.navigate(['/invites-list'],{queryParams:{popup:'create'}});
    this.bsModalService.hide();
  }
}
