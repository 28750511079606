<div class="modal-content p-md-4 rounded-0 border-0">
  <div class="modal-body">
    <div class="d-flex justify-content-center align-items-center flex-column">
<!--      <h5 class="my-1">Confirmation!</h5>-->

      <iconify-icon
        icon="et:caution"
        class="text-warning"
        style="font-size: 4rem"></iconify-icon>

      <p class="my-3 text-center">
        {{message}}
      </p>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <button
      type="button"
      class="btn btn-outline-secondary" (click)="dismissClicked.emit()">
      No
    </button>
    <button
      type="button"
      class="btn btn-primary" (click)="yesClicked.emit()">
      {{'YES'}}
    </button>
  </div>
</div>
