import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {PaymentInvoice} from 'sdk/dd-core-api-sdk/model/payment-invoice';
import {PaymentInvoicePojo} from 'sdk/dd-core-api-sdk/model/payment-invoice-pojo';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {
  AddCustomerAndCardDetailsRequestParams, ChargeCustomerRequestParams,
  DentalOfficePojo,
  PaymentControllerService,
  SubscriptionControllerService, SubscriptionCyclePojo
} from "dd-core-api-sdk";
import {BsModalService} from "ngx-bootstrap/modal";
import {CreditCardValidator} from "dd-shared-lib";
import {PaymentSuccessDialogComponent} from "../../../shared/payment-success-dialog/payment-success-dialog.component";
import {Utils} from "../../../shared/utils";
import * as moment from "moment";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {SocialService} from "../../../shared/subject/SocialService";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnChanges {

  @Input() value: any;
  @Input() invoiceProvider$: Observable<PaymentInvoicePojo>;
  @Output() onClose$ = new EventEmitter<PaymentInvoice>();
  @Output() onInvoiceFetchFailed = new EventEmitter<any>();
  @Output() nextClicked = new EventEmitter<any>();
  subcription: string;
  form: FormGroup;
  switchPlanForm: FormGroup;
  year = new Date().getFullYear();
  Environment = environment;
  working = false;
  valuesFromSub: any;
  @Input()
  dentalOffice: DentalOfficePojo;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  selectedCycle: any;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private router: Router,
              private socialService: SocialService,
              private authService: AuthenticationServiceKeycloak,
              private paymentControllerService: PaymentControllerService,
              private subscriptionControllerService: SubscriptionControllerService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value?.currentValue) {
      this.valuesFromSub = changes?.value?.currentValue;
      this.selectedCycle = this.valuesFromSub?.cycle;
      console.log(this.valuesFromSub)

      this.switchPlanForm.get("cycle").setValue(this.selectedCycle);
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      cardNumber: ['', Validators.compose([
        Validators.required,
        CreditCardValidator.validCardNumber
      ])],
      cvc: ['', Validators.compose([
        Validators.required,
        CreditCardValidator.validCvc])
      ],
      expiryDate: ['', Validators.compose([
        Validators.required,
        CreditCardValidator.validExpiryDate])
      ],
      postalCode: ['']
    });
    this.switchPlanForm = this.fb.group({
      cycle: ['']
    });
    this.switchPlanForm.get('cycle').valueChanges.subscribe(v => {
      this.selectedCycle = v;
      // console.log(this.selectedCycle)
    });
    if (!this.valuesFromSub?.invoice && this.invoiceProvider$) {
      this.invoiceProvider$.subscribe(value => {
        this.valuesFromSub.invoiceNumber = value;
      }, error => {
        this.onInvoiceFetchFailed.emit(error);
      });
    }

  }

  calculateNextBillingCycle() {
    const startDate = new Date();
    if (this.valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.billingCycle == SubscriptionCyclePojo.BillingCycleEnum.Monthly) {
      return moment(startDate, "DD-MM-YYYY").add(30, "days").format("MMMM D, YYYY");
    } else if (this.valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.billingCycle == SubscriptionCyclePojo.BillingCycleEnum.Quarterly) {
      return moment(startDate, "DD-MM-YYYY").add(90, "days").format("MMMM D, YYYY");
    } else {
      return moment(startDate, "DD-MM-YYYY").add(365, "days").format("MMMM D, YYYY");
    }

  }

  calculateEstimate() {
    if (this.valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.billingCycle == SubscriptionCyclePojo.BillingCycleEnum.Monthly) {
      return "one month."
    } else if (this.valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.billingCycle == SubscriptionCyclePojo.BillingCycleEnum.Quarterly) {
      return "three month."
    } else {
      return "twelve month."
    }
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  createSubscriptionInvoice() {
    this.working = true;
    this.subscriptionControllerService.createOfficeSubscriptionRequest(
      {
        officeId: this.dentalOffice.id,
        provider: "STRIPE",
        subscriptionDto: {
          billingCycle: this.valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.billingCycle,
          subscriptionCode: this.valuesFromSub?.subscriptionPackagePojo?.code
        }
      }).subscribe((v) => {
        // console.log(v)
        /** Make card payment after a successful invoice creation **/
        this.makeCardPayment(v.dentalOfficeSubscription.paymentInvoice.invoiceNumber).subscribe(res => {
          this.responseModal('Card Billed', 'Your card has been billed successfully.', 'Done', true);
          this.working = false;
        }, err => {
          this.working = false;
          let errorMessage = 'Your card was declined';
          if (err.error && typeof err.error == 'object' && err.error.message && Utils.isString(err.error.message)) {
            errorMessage = err.error.message;
          }
          this.responseModal('Card Error', errorMessage, 'Dismiss', false);
        });
      }, (err) => {
        this.working = false;
        this.responseModal('Subscription Error', 'Error in creating subscription packages.', 'Dismiss', false);
      }, () => {
      }
    )
  }

  makeCardPayment(invoiceNumber) {
    this.socialService.isInterceptor.next(false);
    const formVal = this.form.value;
    const expiryDate = formVal.expiryDate;
    const month = parseInt(expiryDate.substr(0, 2));
    const year = parseInt(expiryDate.substr(-2));
    this.working = true;
    return this.paymentControllerService.chargeCustomer(this.buildPaymentPayload(month, year, invoiceNumber));
  }

  buildPaymentPayload(month, year, invoiceNumber): ChargeCustomerRequestParams {
    return {
      invoiceNumber: invoiceNumber,
      dentalOfficeId: this.dentalOffice.id,
      customerCardInfo: {
        cardNumber: this.form.value.cardNumber,
        cvc: this.form.value.cvc,
        expiryMonth: month,
        expiryYear: year
      }
    }
  }

  responseModal(title, subTitle, buttonMessage, success) {
    let bsModalRef = this.modalService.show(PaymentSuccessDialogComponent, {
      initialState: {
        title: title,
        subTitle: subTitle,
        buttonText: buttonMessage,
        dentalOffice: this.dentalOffice,
        success: success,
        setup: true
      },
      keyboard: false,
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered'
    });
  }


}
