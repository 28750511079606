import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from "@angular/core";
import {
  DentalOfficeControllerService, DentalOfficePojo,
  DentalOfficeProfessionalControllerService,
  DentalOfficeProfessionalSearch,
  DentalOfficeSearchFilter,
  DentalOfficeTypePojo, FavoriteDto,
  FavoritesService,
  InviteSearchControllerService,
  MyConnectionSearch,
  QueryResultsPojoDentalOfficePojo,
  QueryResultsPojoDentalOfficeProfessionalPojo,
  QueryResultsPojoDentalOfficeTypePojo, SpecializationPojo, ToggleFavoriteRequestParams,
} from "dd-core-api-sdk";
import {SelectionManager} from "dd-shared-lib";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {
  getOffset,
  removeUndefinedOrNullFields,
} from "../../models/search-model";
import {PageChangedEvent} from "ngx-bootstrap/pagination";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {environment} from "../../../environments/environment";
import StatusEnum = FavoriteDto.StatusEnum;
import EntityTypeEnum = FavoriteDto.EntityTypeEnum;
import {BsConfirmDialogComponent} from "../bs-confirm-dialog/bs-confirm-dialog.component";

@Component({
  selector: "app-specialist-office-modal",
  templateUrl: "./specialist-office-modal.component.html",
  styleUrls: ["./specialist-office-modal.component.css"],
})
export class SpecialistOfficeModalComponent implements OnInit {
  isTabActiveHome = false;
  isTabActiveConnect = true;
  selectedOfficeIndex = new BehaviorSubject<number>(null);
  selectionManager: SelectionManager<DentalOfficeTypePojo, number>;
  form!: FormGroup;
  filterData: MyConnectionSearch = {};
  filterDataProfessional: DentalOfficeProfessionalSearch = {};
  filterDataAllOffices: DentalOfficeSearchFilter = {};
  searching: boolean = false;
  searchingProfessional: boolean = false;
  searchingAllOffices: boolean = false;
  resultArray: any[];
  resultArrayProfessional: any[];
  resultArrayAllOffices: any[];
  limit = 5;
  offset: any;
  page: any;
  data: any;
  @Input() officeId;
  @Input() dismissOnConfirm = true;
  @Input() specializationId: any;
  @Input() selectedInsuranceCode: any;
  @Output()
  onDentalProfPojoEmitter = new EventEmitter<SelectedDentalOffice>();
  queryResults: QueryResultsPojoDentalOfficeTypePojo;
  queryResultsProfessional: QueryResultsPojoDentalOfficeProfessionalPojo;
  queryResultsAllOffices: QueryResultsPojoDentalOfficePojo;
  resultSubject = new BehaviorSubject<QueryResultsPojoDentalOfficeTypePojo>({});
  resultSubjectProfessional =
    new BehaviorSubject<QueryResultsPojoDentalOfficeProfessionalPojo>({});
  resultSubjectAllOffices =
    new BehaviorSubject<QueryResultsPojoDentalOfficePojo>({});
  selectOfficeId: number;
  selectedOfficeName: string;
  selectedDentalOfficeAddress: string;
  selectedDentalOfficeEmail: string;
  selectedProfName: string;
  selectedProfId: number;
  temporaryOfficeName: string;
  temporaryOfficeId: number;
  temporaryOfficeEmail: string;
  temporaryOfficeAddress: string;
  favoriteEnum = FavoriteDto.StatusEnum;
  isAllOffice: any;
  officeSpecializationList: Array<SpecializationPojo>;
  currentOfficeId: number | undefined;
  successMessage: any;
  showSuccessMessageTrigger: boolean;
  googleCountry: string;
  placesConfig: any = {
    componentRestrictions: {country: ['US'],}
    // ,
    // types: ['hospital', 'dentist', 'doctor', 'drugstore', 'pharmacy']
  }
  googleName: any;
  googleIconUrl: any;
  googlePhoneNumber: string | undefined;
  favoriteForm: FormGroup;
  switchToGoogle = true;


  @ViewChild('searchInput') searchInput!: ElementRef;

  constructor(
    private favouriteController: FavoritesService,
    private bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private authenticationService: AuthenticationServiceKeycloak,
    private inviteSearchControllerService: InviteSearchControllerService,
    private dentalOfficeControllerService: DentalOfficeControllerService,
    private dentalOfficeService: DentalOfficeProfessionalControllerService,
    private renderer2: Renderer2,
    private fb: FormBuilder
  ) {
    class ItemsSelect extends SelectionManager<any, any> {
      getIdentifier(e: any) {
        return e;
      }

      getState(e: any): number {
        return e.id;
      }

      isSelectable(e: any): boolean {
        return true;
      }
    }

    this.selectionManager = new ItemsSelect();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      dentalOfficeName: [""],
      selectedOfficeId: [""],
      specializationType: [""],
      limit: [""],
      offset: [""],
    });
    this.onSearchClick();
    this.onOfficeSearchClick();

    this.authenticationService.getAccount().subscribe(res => {
      if (res) {
        this.currentOfficeId = res?.otherData?.id;
      }
    })

    this.favoriteForm = this.fb.group({
      checkFavourite: [true]
    })
  }

  searchClickToggle(isAll, isConnect) {
    this.switchToGoogle = false;
    this.isTabActiveHome = isAll || false;
    this.isTabActiveConnect = isConnect || false;
    this.form.patchValue({specializationType: ""});
    this.form.patchValue({dentalOfficeName: ""});
    if (this.isTabActiveConnect) {
      this.resultSubjectProfessional.next({});
      this.resultArrayProfessional = [];
      this.onSearchClick();
    } else {
      this.resultArrayProfessional = [];
      this.resultSubjectProfessional.next({});
      this.onOfficeSearchClick();
    }
  }

  filterBySpeciality(specializationType: string) {
    this.switchToGoogle = false;
    this.form.patchValue({specializationType: specializationType});
    this.form.patchValue({dentalOfficeName: ""});
    if (this.isTabActiveConnect) {
      this.onSearchClick();
    } else {
      this.onOfficeSearchClick();
    }
  }

  searchDentalOfficeFavourites(filter: MyConnectionSearch) {
    this.switchToGoogle = false;
    this.inviteSearchControllerService
      .searchMyConnections({filter: filter})
      .subscribe((v) => {
        this.filterData = filter;
        Object.keys(this.filterData).forEach((v) => {
          if (v == "limit" || v == "offset") {
            delete this.filterData![v];
          }
        });
        this.searching = false;
        this.offset = v.offset;
        this.queryResults = v;
        this.resultArray = v.results!;
        this.resultSubject.next(v);
        console.log('this.queryResults?.total ' + this.queryResults?.total)
        if (this.queryResults?.total < 1) {
          this.switchToGoogle = true;
          if (!this.googleName) {
            this.simulateEnterKeyPress(filter.dentalOfficeName);
          }
        } else {
          this.switchToGoogle = false;
        }
      });
    this.data = filter;
  }

  scroll() {
    let el = document.getElementById('scrollToProfessional');
    el.scrollIntoView({behavior: 'smooth'});

    let con = document.getElementById('scrollToProfessionalConnect');
    con.scrollIntoView({behavior: 'smooth'});
  }

  onSearchClick() {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);
    this.searchDentalOfficeFavourites(filter);
  }

  onOfficeSearchClick() {
    let filter = this.getFilterAllOffices(this.limit);
    filter.offset = getOffset(0, this.limit);
    this.searchAllOffices(filter);
  }

  onPageChange(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.searchDentalOfficeFavourites(filter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.searchDentalOfficeFavourites(filter);
  }

  getFilter(limit?: number): MyConnectionSearch {
    const data = this.form.value;
    let filter: MyConnectionSearch = {};
    filter.dentalOfficeName = data.dentalOfficeName;
    filter.specializationName = data.specializationType;
    filter.limit = limit || 10;
    filter.dentalOfficeId = this.officeId;

    return removeUndefinedOrNullFields(filter);
  }

  close() {
    this.bsModalRef.hide();
  }

  searchDentalProfessionalsInOffice(filter: DentalOfficeProfessionalSearch) {
    this.searchingProfessional = true;
    this.dentalOfficeService
      .getAllDentalProfessional({filter: filter})
      .subscribe((v) => {
        this.filterDataProfessional = filter;
        this.resultArrayProfessional = v.results!;
        this.searchingProfessional = false;
        if (this.resultArrayProfessional?.length < 1) {
          this.confirm();
          return;
        }
        Object.keys(this.filterDataProfessional).forEach((v) => {
          if (v == "limit" || v == "offset") {
            delete this.filterDataProfessional![v];
          }
        });
        this.offset = v.offset;
        this.queryResultsProfessional = v;
        this.resultSubjectProfessional.next(v);
      });

    this.data = filter;
  }


  onPageChangeProfessional(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.searchDentalProfessionalsInOffice(filter);
  }

  changeLimitProfessional($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.searchDentalProfessionalsInOffice(filter);
  }

  getFilterProfessional(
    limit?: number,
    dentalOfficeId?: number
  ): DentalOfficeProfessionalSearch {
    const data = this.form.value;
    let filter: DentalOfficeProfessionalSearch = {};
    filter.limit = limit || 10;
    filter.dentalOfficeId = dentalOfficeId;

    return removeUndefinedOrNullFields(filter);
  }

  getFileURL(id) {
    if (id != null) {
      return environment.siteUrl + "/files/" + id;
    }
    return "./assets/img/biguser.png";
  }

  onSearchProfessional(result: any, index: number, specialization?: any) {
    this.form.patchValue({selectedOfficeId: result.id})
    this.scroll();
    if (result.dentalDoor) {
      this.selectOfficeId = result.id;
      this.selectedOfficeName = result.name;
      this.selectedDentalOfficeAddress = result.streetAddress;
      this.selectedDentalOfficeEmail = result.email;
      this.officeSpecializationList = result.specializationPojoList || specialization;
      this.selectedOfficeIndex.next(index); //selected office index
      let filter = this.getFilterProfessional(this.limit, result.id);
      filter.offset = getOffset(0, this.limit);
      this.searchDentalProfessionalsInOffice(filter);
    } else {
      this.temporaryOfficeId = result.id;
      this.temporaryOfficeName = result.name;
      this.temporaryOfficeEmail = result.email;
      this.temporaryOfficeAddress = result.streetAddress;
      this.confirm();
    }
  }

  onClickProfessional(dentalProfId: number, dentalProfessionName: string) {
    this.selectedProfId = dentalProfId;
    this.selectedProfName = dentalProfessionName;
    this.confirm();
  }

  /*search all offices*/
  searchAllOffices(filter: DentalOfficeSearchFilter) {
    this.searchingAllOffices = true;
    this.dentalOfficeControllerService
      .searchCombinedDentalOffice({filter: filter})
      .subscribe((v) => {
        this.filterDataAllOffices = filter;
        Object.keys(this.filterDataProfessional).forEach((v) => {
          if (v == "limit" || v == "offset") {
            delete this.filterDataProfessional![v];
          }
        });
        this.searchingAllOffices = false;
        this.offset = v.offset;
        this.queryResultsAllOffices = v;
        this.resultArrayAllOffices = v.results!;
        this.resultSubjectAllOffices.next(v);

        console.log('this.queryResults?.total ' + this.queryResults?.total)
        if (this.queryResultsAllOffices?.total < 1) {
          this.switchToGoogle = true;
          if (!this.googleName) {
            this.simulateEnterKeyPress(filter.dentalOfficeName);
          }
        } else {
          this.switchToGoogle = false;
        }
      });
    this.data = filter;
  }

  onPageChangeAllOffices(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.searchAllOffices(filter);
  }

  changeLimitAllOffices($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.searchAllOffices(filter);
  }

  getFilterAllOffices(limit?: number): DentalOfficeSearchFilter {
    const data = this.form.value;
    let filter: DentalOfficeSearchFilter = {};
    filter.limit = limit || 10;
    filter.dentalOfficeId = this.officeId;
    filter.dentalOfficeName = data.dentalOfficeName;
    filter.specializationType = data.specializationType;

    return removeUndefinedOrNullFields(filter);
  }

  confirm() {
    const selectedOffice: SelectedDentalOffice = {
      dentalOfficeId: this.selectOfficeId,
      dentalOfficeName: this.selectedOfficeName,
      dentalProfessionalId: this.selectedProfId,
      dentalProfName: this.showDentalOfficeName(),
      dentalOfficeEmail: this.selectedDentalOfficeEmail,
      dentalOfficeAddress: this.selectedDentalOfficeAddress,
      specializationCodes: this.officeSpecializationList,
      temporaryOfficeId: this.temporaryOfficeId,
      temporaryOfficeName: this.temporaryOfficeName,
      temporaryOfficeEmail: this.temporaryOfficeEmail,
      temporaryOfficeAddress: this.temporaryOfficeAddress,
    };
    this.onDentalProfPojoEmitter.emit(selectedOffice);
    if (this.dismissOnConfirm) {
      this.bsModalRef.hide();
    }
    return;
  }

  showDentalOfficeName() {
    if (this.selectOfficeId !== undefined && this.selectedProfId == undefined) {
      return this.selectedOfficeName + " [Available Dr.]";
    } else if (
      this.temporaryOfficeId !== undefined &&
      this.selectedProfId == undefined
    ) {
      return this.temporaryOfficeName + " [Available Dr.]";
    } else {
      return this.selectedOfficeName + " [ Dr. " + this.selectedProfName + "]";
    }
  }

  toggleFavorite(statusEnum: StatusEnum, dentalOfficePojo: DentalOfficePojo, isConnection) {

    const bsModalRef = this.bsModalService.show(BsConfirmDialogComponent, {
      initialState: {
        header: 'Confirm Your Action',
        body: 'Are you sure you want to remove this office from your connections?',
        confirmButtonText: 'Yes, Remove',
      },
    });

    bsModalRef.content.confirmClicked.subscribe((action: boolean) => {
      if (action) {
        this.favouriteController.toggleOfficeFavorite(this.buildFavoritePayload(statusEnum, dentalOfficePojo.id)).subscribe(res => {
          if (isConnection) {
            this.onSearchClick();
            this.showSuccessMessage(statusEnum == this.favoriteEnum.Dislike ? dentalOfficePojo.name + " has been removed from your connections" : dentalOfficePojo.name + " has been added to your connections.");
          }
        })
      } else {
      }
      bsModalRef.hide();
    });

  }

  buildFavoritePayload(statusEnum: StatusEnum, dentalOfficeId: number): ToggleFavoriteRequestParams {
    return {
      favoriteDto: {
        dentalOfficeId: this.currentOfficeId,
        status: statusEnum,
        entityType: EntityTypeEnum.DentalOffice,
        entityId: dentalOfficeId
      }
    }
  }

  hasInsuranceCompany(dentalOfficePojo: DentalOfficePojo) {
    return dentalOfficePojo?.insuranceCompany?.some(v => v.code == this.selectedInsuranceCode);
  }

  showSuccessMessage(success: any) {
    this.successMessage = success;
    this.showSuccessMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showSuccessMessageTrigger = false;
    }, 5000);
  }

  getSuccessMessage() {
    return this.successMessage;
  }

  getGoogleOfficeImage() {
    if (this.googleIconUrl != null) {
      return this.googleIconUrl;
    }
    return "./assets/img/biguser.png";
  }

  getPlacesAutocomplete(place: any) {
    this.form.patchValue({dentalOfficeName: place?.name});
    this.googleCountry = place?.formatted_address;
    this.googleName = place?.name;
    this.googleIconUrl = place?.icon;
    this.googlePhoneNumber = place?.international_phone_number;

    console.log(this.googleCountry)
    console.log('Google name: ' + this.googleName);

    this.isTabActiveConnect ? this.onSearchClick() : this.onOfficeSearchClick();
  }

  simulateEnterKeyPress(name: string): void {
    console.log('this.searchInput.nativeElement.value ', name)
    this.form.get('dentalOfficeName').setValue(name);
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0);
  }


  confirmGoogleOffice() {
    const selectedOffice: SelectedDentalOffice = {
      googleOfficePhoneNumber: this.googlePhoneNumber,
      googleOfficeName: this.googleName,
      googleOfficeAddress: this.googleCountry,
      dentalOfficeId: null,
      dentalOfficeName: null,
      dentalOfficeAddress: null,
      dentalOfficeEmail: null,
      dentalProfessionalId: null,
      dentalProfName: null,
      temporaryOfficeId: null,
      temporaryOfficeName: null,
      temporaryOfficeEmail: null,
      temporaryOfficeAddress: null,
      specializationCodes: null,
    };
    console.log(selectedOffice)
    this.onDentalProfPojoEmitter.emit(selectedOffice);
    if (this.dismissOnConfirm) {
      this.bsModalRef.hide();
    }
    return;
  }

}

export interface SelectedDentalOffice {
  dentalOfficeId?: number;
  dentalOfficeName?: string;
  dentalOfficeAddress?: string;
  dentalOfficeEmail?: string;
  dentalProfessionalId?: number;
  dentalProfName?: string;
  temporaryOfficeId?: number;
  temporaryOfficeName?: string;
  temporaryOfficeEmail?: string;
  temporaryOfficeAddress?: string;
  specializationCodes?: Array<SpecializationPojo>;
  googleOfficeName?: string;
  googleOfficeAddress?: string;
  googleOfficePhoneNumber?: string;
}
