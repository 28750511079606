/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RescheduleAppointmentDto { 
    appointmentCancellationReason: string;
    reasonForCancellation?: string;
    startTime: string;
    endTime: string;
    durationInMinutes: number;
    timeslotDefinitionId: number;
}

