/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DentalPlanPojo { 
    name?: string;
    amount?: number;
    value?: number;
    valueType?: DentalPlanPojo.ValueTypeEnum;
    validityPeriod?: DentalPlanPojo.ValidityPeriodEnum;
}
export namespace DentalPlanPojo {
    export type ValueTypeEnum = 'AMOUNT' | 'PERCENTAGE';
    export const ValueTypeEnum = {
        Amount: 'AMOUNT' as ValueTypeEnum,
        Percentage: 'PERCENTAGE' as ValueTypeEnum
    };
    export type ValidityPeriodEnum = 'MONTHLY' | 'ANNUAL' | 'QUARTERLY' | 'SEMI_ANNUAL';
    export const ValidityPeriodEnum = {
        Monthly: 'MONTHLY' as ValidityPeriodEnum,
        Annual: 'ANNUAL' as ValidityPeriodEnum,
        Quarterly: 'QUARTERLY' as ValidityPeriodEnum,
        SemiAnnual: 'SEMI_ANNUAL' as ValidityPeriodEnum
    };
}


