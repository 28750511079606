import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-option-selection-response',
  templateUrl: './option-select-response.component.html',
  styleUrls: ['./option-select-response.component.css']
})
export class OptionSelectResponseComponent implements OnInit {

  @Output()
  dismissClicked = new EventEmitter<any>();

  @Output()
  yesClicked = new EventEmitter<any>();

  @Input()
  message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
