<div class="container mt-4">
  <div class="row">
    <div class="d-flex align-items-center">
      <p class="fs-3 fw-bold my-3">
        <iconify-icon inline icon="fluent-emoji-high-contrast:incoming-envelope" class="fs-2 me-1"></iconify-icon>
        Custom Subscription Requests
      </p>
    </div>

    <!--     Requests stats card begin-->
    <div class="">
      <div class="container-fluid px-0">
        <div class="row d-flex justify-content-between flex-wrap mt-4">
          <div class="col-xl-4 col-sm-6 my-3 my-xl-0">
            <div class="d-flex flex-column doffice-bg text-white rounded-4 shadow-lg px-4 p-3">
              <h5>Total Requests</h5>
              <p>Total number of requests received.</p>

              <h1 class="stat-card-numbers mb-0 mt-3">
                {{  this.subStatCount?.totalSubscriptionRequest || 0 }}
              </h1>
              <div class="d-flex justify-content-between"></div>
            </div>
          </div>
          <div class="col-xl-4 col-sm-6 my-3 my-xl-0 ">
            <div class="d-flex flex-column px-3 overview-card refcard p-3">
              <h5>Completed requests</h5>
              <p>Total number of completed requests</p>
              <h1 class="mt-4">
                {{this.subStatCount?.totalCompletedSubRequest || 0 }}
              </h1>

              <div class="d-flex justify-content-end"></div>
            </div>
          </div>
          <div class="col-xl-4 col-sm my-3 my-xl-0">
            <div class="d-flex flex-column px-3 overview-card refcard p-3">
              <h5>Pending Requests</h5>
              <p>Total number of pending requests</p>

              <h1 class=" mt-4">
                {{ this.subStatCount?.totalPendingSubRequest || 0 }}
              </h1>

              <div class="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    Requests stats card ends-->

    <div class="">
      <div class="my-3">
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="overview-card p-3">
            <div class="filter-card-bg p-md-4 py-3">
              <div *ngIf="showErrorMessageTrigger">
                <div class="alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
                     [ngClass]="{'alert-danger': isError, 'alert-success': !isError}"
                     role="alert">
                  <p class="m-0 p-0 d-flex align-items-start">
                            <span>
                                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                              {{ getErrorMessage() | removeUnderscores }}
                            </span>
                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                  </p>
                </div>
              </div>
              <div class="container-fluid">
                <div [formGroup]="form" class="row g-3">
                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <input type="text" class="form-control" id="ptn" placeholder="e.g st.Marys"
                           formControlName="name">
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Name</label>
                  </div>
                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <input type="text" class="form-control" placeholder="e.g abc@gmail.com"
                           aria-label="Email Address" formControlName="email">
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.1rem;">Email Address</label>
                  </div>
                  <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
                    <input type="text" class="form-control" placeholder="+1 (754) 343 094"
                           aria-label="Phone Number" formControlName="phoneNumber">
                    <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Phone Number</label>
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0 " style="margin-top: -.6rem;">
                    <div class="">
                      <label class="form-label rfr-label"> Date (from) </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="MM-DD-YYYY" formControlName="startDate"
                               [minDate]="{ year: 1910, month: 1, day: 1 }"
                               [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                               ngbDatepicker #startDate="ngbDatepicker"
                               (click)="startDate.toggle()"/>
                        <span (click)="startDate.toggle()" class="btn-primary-outline input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 mb-md-0" style="
                margin-top: -.6rem;">
                    <div class="">
                      <label class="form-label rfr-label"> Date (to) </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="MM-DD-YYYY" formControlName="endDate"
                               [minDate]="{ year: 1910, month: 1, day: 1 }"
                               [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                               ngbDatepicker #endDate="ngbDatepicker"
                               (click)="endDate.toggle()"/>
                        <span (click)="endDate.toggle()" class="btn-primary-outline input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0  float-end" style="white-space: nowrap;">
                    <button
                      class="bg-primary border-0 d-flex justify-content-center align-items-center px-3 text-light float-end"
                      (click)="loadCustomSubscription()" style=" margin-top: 3px; padding: .35rem;">
                      <iconify-icon inline icon="iconoir:search" class="me-2 fs-5" width="25"
                                    height="25"></iconify-icon>
                      Apply Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row mt-4">
          <div class="overview-card-table p-3">

            <div class="table-responsive">
              <table class="table">
                <thead class="">
                <tr class="overview-card shadow-sm ">
                  <th>ID</th>
                  <th class="">Office Name</th>
                  <th class="">Email Address</th>
                  <th class="">Phone Number</th>
                  <th class="">Date Sent</th>
                  <th class="">Extranet</th>
                  <th class="">More</th>
                  <th class="">Completed</th>
                </tr>
                </thead>
                <tbody class="text-muted" *ngIf="!this.searching">
                <tr *ngFor="let result of this.resultSubject.value.results; let i = index;"
                    [id]="'action' + i">
                  <td [id]="'index' + i" class="align-middle fw-bold fs-6" data-label="ID">
                    {{ offset + i + 1 }}
                  </td>
                  <td class=" fw-bold align-middle" data-label="Office Name">
                    <p class="mb-0">{{ result?.fullName }}</p>
                  </td>
                  <td class="align-middle" data-label="Email Address">
                    <p class="mb-0 email-viewer">{{(result?.email | lowercase) || 'N/A'}}</p>
                  </td>
                  <td class="align-middle" data-label="Phone Number">
                    <p class="mb-0">{{(result?.phoneNumber | lowercase) || 'N/A'}}</p>
                  </td>
                  <td class="align-middle" data-label="Date Created">
                    {{(this.formatUTCMoment(result) | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes)}}
                  </td>
                  <td class="align-middle" data-label="Extranet">
                    {{result?.extranet ? 'YES' : 'NO' }}
                  </td>
                  <td class="align-middle" data-label="Notes" *ngIf="result?.note"
                      (click)="showInviteOptionModal(result)">
                    <a style="text-decoration: none; cursor: pointer; color:#02183D;" class="fw-bold">{{'View Notes'}}</a>
                  </td>
                  <td class="align-middle" data-label="Notes" *ngIf="!result?.note">
                    <a style="text-decoration: underline; cursor: pointer">{{'N/A'}}</a>
                  </td>
                  <td class="align-middle" data-label="Completed">
                    <form [formGroup]="completeForm" class="ml-2">
                      <input type="checkbox" *ngIf="result?.contacted" [checked]="true" [disabled]="result?.contacted"
                             class="form-check-input"/>
                      <input type="checkbox" *ngIf="!result?.contacted" [disabled]="!result?.contacted"
                             formControlName="markCompleted" class="form-check-input"
                             (click)="markAsCompleted(result.id, i)"/>
                    </form>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
                <span *ngIf="!this.resultSubject.value.results ||  this.resultSubject.value.total == 0"
                      class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
                    {{this.resultSubject.value.results
                  ? "NO DATA/RECORDS"
                  : "APPLY FILTERS TO VIEW REQUEST DETAILS"
                  }}</span>
            </div>
            <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3"
                 style="margin-bottom: -1rem; color: #4e3367">
              <app-page-length [pageLength]="getFilter().limit || 10" (pageLengthChanged)="changeLimit($event)">
              </app-page-length>
              <div class="add-group-btn text-right mt-3 mb-3">
                <pagination (pageChanged)="onPageChange($event)" [boundaryLinks]="true" [itemsPerPage]="limit || 10"
                            [maxSize]="5" [totalItems]="this.resultSubject.value.total || 0"
                            firstText="&laquo;" lastText="&raquo;" nextText="&rsaquo;"
                            previousText="&lsaquo;"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
