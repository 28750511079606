/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLogSearchFilter { 
    startDate?: string;
    endDate?: string;
    userName?: string;
    remoteAddress?: string;
    activityType?: ActivityLogSearchFilter.ActivityTypeEnum;
    activityStatus?: ActivityLogSearchFilter.ActivityStatusEnum;
    limit?: number;
    offset?: number;
}
export namespace ActivityLogSearchFilter {
    export type ActivityTypeEnum = 'LOGIN' | 'LOGOUT' | 'BLACK_LIST' | 'CREATE_REFERRAL' | 'VIEW_REFERRAL' | 'PROFILE_UPDATE' | 'CREATE_INVITE' | 'PASSWORD_UPDATE' | 'CREATE_AFFILIATE_USER' | 'CREATE_DENTAL_PROFESSIONAL' | 'CREATE_DENTAL_OFFICE' | 'CREATE_DENTAL_SPECIALIST_OFFICE' | 'CREATE_DENTAL_SPECIALIST' | 'REMOVE_ROLE' | 'ADD_ROLE';
    export const ActivityTypeEnum = {
        Login: 'LOGIN' as ActivityTypeEnum,
        Logout: 'LOGOUT' as ActivityTypeEnum,
        BlackList: 'BLACK_LIST' as ActivityTypeEnum,
        CreateReferral: 'CREATE_REFERRAL' as ActivityTypeEnum,
        ViewReferral: 'VIEW_REFERRAL' as ActivityTypeEnum,
        ProfileUpdate: 'PROFILE_UPDATE' as ActivityTypeEnum,
        CreateInvite: 'CREATE_INVITE' as ActivityTypeEnum,
        PasswordUpdate: 'PASSWORD_UPDATE' as ActivityTypeEnum,
        CreateAffiliateUser: 'CREATE_AFFILIATE_USER' as ActivityTypeEnum,
        CreateDentalProfessional: 'CREATE_DENTAL_PROFESSIONAL' as ActivityTypeEnum,
        CreateDentalOffice: 'CREATE_DENTAL_OFFICE' as ActivityTypeEnum,
        CreateDentalSpecialistOffice: 'CREATE_DENTAL_SPECIALIST_OFFICE' as ActivityTypeEnum,
        CreateDentalSpecialist: 'CREATE_DENTAL_SPECIALIST' as ActivityTypeEnum,
        RemoveRole: 'REMOVE_ROLE' as ActivityTypeEnum,
        AddRole: 'ADD_ROLE' as ActivityTypeEnum
    };
    export type ActivityStatusEnum = 'FAILED' | 'PENDING' | 'SUCCESSFUL';
    export const ActivityStatusEnum = {
        Failed: 'FAILED' as ActivityStatusEnum,
        Pending: 'PENDING' as ActivityStatusEnum,
        Successful: 'SUCCESSFUL' as ActivityStatusEnum
    };
}


