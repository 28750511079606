<div class="modal-content p-md-4 rounded-0 border-0">
  <div class="modal-body">
    <div class="d-flex justify-content-center align-items-center flex-column">
      <h5 class="my-1">Confirmation!</h5>

      <iconify-icon
        icon="et:caution"
        class="text-warning"
        style="font-size: 4rem"></iconify-icon>

      <p class="my-3 text-center">
        {{message}}
      </p>

      <form class="col-12 mb-3 w-100" [formGroup]="form" *ngIf="isDecline">
            <textarea formControlName="reasonNote" class="form-control form-control-lg"
                      rows="2" maxlength="500" placeholder="reason for declining [max 500]"></textarea>
      </form>

    </div>
    <br>
    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-outline-secondary" (click)="dismissClicked.emit()">
        Dismiss
      </button>
      <button
        type="button"
        class="btn btn-primary" (click)="processInvites()">
        <iconify-icon
          *ngIf="sending"
          icon="eos-icons:bubble-loading"
          style="color: #ffffff "></iconify-icon>
        {{isDecline ? 'Yes, I Decline' : 'Yes, I Accept'}}
      </button>
    </div>
  </div>
</div>

