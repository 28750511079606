/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferralRequest } from './referral-request';
import { FirstContactTimeRange } from './first-contact-time-range';
import { FirstContactToken } from './first-contact-token';


export interface FirstContact { 
    status?: FirstContact.StatusEnum;
    createdAt?: string;
    updatedAt?: string;
    question?: string;
    id?: number;
    firstContactToken?: FirstContactToken;
    referralRequest?: ReferralRequest;
    firstContactTimeRange?: FirstContactTimeRange;
}
export namespace FirstContact {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


