import {RouterModule, Routes} from "@angular/router";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {InvitesListComponent} from "./invites-list/invites-list.component";
import {CreatesInvitesComponent} from "./create-invites/creates-invites.component";
import {LoggedInGuard, RoutePermissionRestriction} from "../../shared/logged-in-guard/logged-in.guard";
import {AccountMembershipPojo, SocialMedialSignUpDto} from "dd-core-api-sdk";
import AccountTypeEnum = SocialMedialSignUpDto.AccountTypeEnum;

const routes: Routes = [
  {
    path: '',
    component: InvitesListComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard]
  },
  {
    path: 'create',
    component: CreatesInvitesComponent,
    pathMatch: 'full',
    data:{
      permissions: [
        {
          accountType: AccountTypeEnum.DentalOffice
        },
      ] as RoutePermissionRestriction[]
    },
    canActivate: [LoggedInGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InvitesRoutingModule {
}
