import {RouterModule, Routes} from "@angular/router";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {InboundSearchListComponent} from "./inbound-search-list/inbound-search-list.component";
import {ViewReferralComponent} from "../../../components/view-referral/view-referral.component";

const routes: Routes = [
  {
    path: '',
    component: InboundSearchListComponent,
    pathMatch: 'full',
  },
  {
    path: 'referrals/:id',
    component: ViewReferralComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InboundRoutingModule {
}
