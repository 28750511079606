<div class="modal-content card rounded-1 border-light container-fluid success p-0 m-0">
  <div class="modal-header card-header m-0 rounded-1 border-0 p-4">
    <h5 class="modal-title ms-auto" id="staticBackdropLabel">{{infoTitle}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="this.dismissClicked.emit()"></button>
  </div>
  <div class="modal-body card-body">
    <div class="container">
      <div class="row mb-3">
        <div class="col" *ngIf="walletTransactionPojo?.maskedBankAccountNumber">
                    <span class="float-start me-2 d-block icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path
                        fill="currentColor"
                        d="M5 17v-7h2v7Zm6 0v-7h2v7Zm-9 4v-2h20v2Zm15-4v-7h2v7ZM2 8V6l10-5l10 5v2Z"/></svg>
                    </span>
          <div class="col">
            <span class="small text-start d-block pt-1">{{walletTransactionPojo?.maskedBankAccountNumber}}</span>
          </div>
        </div>
      </div>
      <div class="row mb-3" *ngIf="walletTransactionPojo?.extimatedPaymentArrivalDate">
        <div class="col">
          <div>
            Estimated date of arrival
          </div>
          <span class="float-start me-2 d-block icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path
                        fill="currentColor"
                        d="M21 11.11V5a2 2 0 0 0-2-2h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14a2 2 0 0 0 2 2h6.11c1.26 1.24 2.98 2 4.89 2c3.87 0 7-3.13 7-7c0-1.91-.76-3.63-2-4.89M12 3c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1M6 7h12v2H6V7m3.08 10H6v-2h3.08c-.05.33-.08.66-.08 1s.03.67.08 1M6 13v-2h5.11c-.61.57-1.07 1.25-1.43 2H6m10 8c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m.5-4.75l2.86 1.69l-.75 1.22L15 17v-5h1.5v4.25Z"/></svg>
                    </span>
          <div class="col">
            <span
              class="small text-start d-block pt-1">{{walletTransactionPojo?.extimatedPaymentArrivalDate | datetime}}</span>
          </div>
        </div>
      </div>
      <div class="row mb-3" *ngIf="walletTransactionPojo?.apiMessages?.length > 0">
        <div class="col">
                    <span class="float-start me-2 d-block icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 2.4 24 24"><path
                        fill="currentColor"
                        d="M11 19.4h2v-6h-2v6Zm1-8q.425 0 .713-.287T13 10.4q0-.425-.288-.713T12 9.4q-.425 0-.713.288T11 10.4q0 .425.288.713T12 11.4Zm0 13q-2.075 0-3.9-.787t-3.175-2.138q-1.35-1.35-2.137-3.175T2 14.4q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.138T12 4.4q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 14.4q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 24.4Z"/></svg>
                    </span>
          <div class="col" *ngFor="let mess of walletTransactionPojo?.apiMessages; let i = index;">
            <h5 class="small text-start d-block pt-1">{{'NO ' + (i + 1)}}</h5>
            <span class="small text-start d-block pt-1">{{mess}}</span>
          </div>
        </div>
      </div>
      <div class="row mb-3" *ngIf="affiliateBank?.apiErrorMessages?.length > 0">
        <div class="col">
                    <span class="float-start me-2 d-block icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 2.4 24 24"><path
                        fill="currentColor"
                        d="M11 19.4h2v-6h-2v6Zm1-8q.425 0 .713-.287T13 10.4q0-.425-.288-.713T12 9.4q-.425 0-.713.288T11 10.4q0 .425.288.713T12 11.4Zm0 13q-2.075 0-3.9-.787t-3.175-2.138q-1.35-1.35-2.137-3.175T2 14.4q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.138T12 4.4q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 14.4q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 24.4Z"/></svg>
                    </span>
          <div class="col" *ngFor="let mess of affiliateBank?.apiErrorMessages; let i = index;">
            <h5 class="small text-start d-block pt-1">{{'NO ' + (i + 1)}}</h5>
            <span class="small text-start d-block pt-1">{{mess}}</span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col" *ngIf="walletTransactionPojo?.walletTransactionStatus != this.walletTransactionConstant.Paid">
                    <span class="float-start me-2 d-block icon">
              <iconify-icon
                icon="wpf:paid" style="font-size: 20px;"></iconify-icon>
                    </span>
          <div class="col">
            <span
              class="small text-start d-block pt-1">{{walletTransactionPojo?.walletTransactionStatus | removeUnderscores | uppercase}}</span>
          </div>
        </div>
        <div class="col" *ngIf="walletTransactionPojo?.walletTransactionStatus == this.walletTransactionConstant.Paid">
                    <span class="float-start me-2 d-block icon">
              <iconify-icon
                icon="flat-color-icons:paid" style="font-size: 20px;"></iconify-icon>
                    </span>
          <div class="col">
            <span
              class="small text-start d-block pt-1">{{walletTransactionPojo?.walletTransactionStatus | removeUnderscores | uppercase}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <a class="btn btn-md btn-dark w-100 mt-5 px-5 text-center" data-bs-dismiss="modal"
       (click)="this.closeClicked.emit()">Close</a>
  </div>
</div>
