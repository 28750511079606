<div class="card-body px-2 text-center p-3">
    <div class="my-2"><img src="assets/img/kyc-vector.svg" alt="" class="card-img"></div>
    <h5 class="mt-1" *ngIf="loading">{{infoTitle}}</h5>
    <h5 class="mt-1" *ngIf="!loading">KYC is ready! <br> Click below to go to Stripe Onboarding.</h5>
    <div class="content">
        <div class="row mb-3" *ngIf="loading">
            <div class="col">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="100" viewBox="0 0 24 24">
          <rect x="0" y="0" width="24" height="24" fill="none" stroke="none"/>
          <circle cx="12" cy="2" r="0" fill="#369dbf">
            <animate attributeName="r" begin="0" calcMode="spline" dur="1s"
                     keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0"/>
          </circle>
          <circle cx="12" cy="2" r="0" fill="#369dbf" transform="rotate(45 12 12)">
            <animate attributeName="r" begin="0.125s" calcMode="spline" dur="1s"
                     keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0"/>
          </circle>
          <circle cx="12" cy="2" r="0" fill="#369dbf" transform="rotate(90 12 12)">
            <animate attributeName="r" begin="0.25s" calcMode="spline" dur="1s"
                     keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0"/>
          </circle>
          <circle cx="12" cy="2" r="0" fill="#369dbf" transform="rotate(135 12 12)">
            <animate attributeName="r" begin="0.375s" calcMode="spline" dur="1s"
                     keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0"/>
          </circle>
          <circle cx="12" cy="2" r="0" fill="#369dbf" transform="rotate(180 12 12)">
            <animate attributeName="r" begin="0.5s" calcMode="spline" dur="1s"
                     keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0"/>
          </circle>
          <circle cx="12" cy="2" r="0" fill="#369dbf" transform="rotate(225 12 12)">
            <animate attributeName="r" begin="0.625s" calcMode="spline" dur="1s"
                     keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0"/>
          </circle>
          <circle cx="12" cy="2" r="0" fill="#369dbf" transform="rotate(270 12 12)">
            <animate attributeName="r" begin="0.75s" calcMode="spline" dur="1s"
                     keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0"/>
          </circle>
          <circle cx="12" cy="2" r="0" fill="#369dbf" transform="rotate(315 12 12)">
            <animate attributeName="r" begin="0.875s" calcMode="spline" dur="1s"
                     keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0"/>
          </circle>
        </svg>
            </div>
        </div>
        <div *ngIf="!loading">
            <div class="px-5 mt-4 text-center text-muted color">
                <p class="p text-start">Please note that you'll be supplying details about </p>
                <ul>
                    <li class="li text-start small">Date Of Birth</li>
                    <li class="li text-start small">Address</li>
                    <li class="li text-start small">Social Security Number</li>
                    <li class="li text-start small">Website/Social Media Account</li>
                </ul>
            </div>
            <br>
            <br>
            <div class="mb-2">
                <button type="button" name="button" class="btn btn-primary rounded-1"><a href="{{this.link}}" class="text-light">Begin Onboarding</a></button>
            </div>
            <br>
            <br>
        </div>
    </div>
</div>