import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  BwFileControllerService,
  BwFilePojo,
  DentalReferralControllerService,
  DentalReferralCreationDto,
  FirstContactPojo,
  Procedure,
  ReferralSearchPojo,
  ReferralStatusNotes,
  SpecialistPostReferralControllerService,
  UpdateDentalReferralRequestStatusRequestParams,
  XrayPojo
} from "dd-core-api-sdk";
import * as moment from "moment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {ActivatedRoute, Router} from "@angular/router";
import {Utils} from "../../shared/utils";
import {CompletionModalComponent} from "../../shared/completion-modal/completion-modal.component";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {OptionSelectionModalComponent} from "../../shared/option-selection-modal/option-selection-modal.component";
import {environment} from "../../../environments/environment";
import {DatePipe, ViewportScroller} from "@angular/common";
import {SocialService} from "../../shared/subject/SocialService";
import {InsuranceSnapshotModalComponent} from '../insurance-snapshot-modal/insurance-snapshot-modal.component';
import {
  AppointmentPreferenceModalComponent
} from '../appointment-preference-modal/appointment-preference-modal.component';
import ReferralStatusConstantEnum = ReferralSearchPojo.ReferralStatusConstantEnum;
import PriorityLevelConstantEnum = DentalReferralCreationDto.PriorityLevelConstantEnum;
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-view-referral',
  templateUrl: './view-referral.component.html',
  styleUrls: ['./view-referral.component.css']
})
export class ViewReferralComponent implements OnInit, AfterViewInit, OnDestroy {

  formGroup: FormGroup;
  exportUrl: string;
  sending: boolean;
  referralId: any;
  referralRequestId;
  referralReasons: Array<Procedure>;
  displayName: string;
  docData: any;
  referralStatusEnum = Utils.enumValues(ReferralStatusConstantEnum);
  referralStatus = ReferralStatusConstantEnum;
  updateStatus: boolean = false;
  adultTeethCheck: boolean;
  affectedTeeth: string = '';
  showAppointmentDatePicker: boolean;
  showErrorMessageTrigger = false;
  singleReferral: ReferralSearchPojo;
  specialistOfficeVideo = '';
  dentistOfficeVideo = '';
  errMessage = '';
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  statusNoteEnum = ReferralStatusNotes.StatusEnum;
  status: ReferralStatusConstantEnum;
  priorityEnum = Utils.enumValues(PriorityLevelConstantEnum);

  isInbound = false;
  patientAge: number;
  referralStatusNotesList: Array<ReferralStatusNotes>;
  intervalId: any;
  referralIndex: any;
  notesCompleted: string | undefined;
  insuranceCompany: string;
  selectedStatus: string;
  formattedStatus: ({ values: any[]; header: string })[];

  @Input() extranetReferralId: number;
  @Input() isExtranentInbound = false;
  hasMixedTeeth = false;

  constructor(private authentication: AuthenticationServiceKeycloak,
              private fb: FormBuilder,
              private modalService: BsModalService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe,
              private sanitizer: DomSanitizer,
              private bwFileControllerService: BwFileControllerService,
              private specialistPostReferralService: SpecialistPostReferralControllerService,
              private viewPortScroller: ViewportScroller,
              private generalService: SocialService,
              private dentalReferralController: DentalReferralControllerService) {
    this.priorityEnum = this.priorityEnum.filter(v => v != 'SEMI_URGENT');
    this.referralStatusEnum = this.referralStatusEnum
      .filter(v => v != this.referralStatus.New && v != this.referralStatus.Pending && v != this.referralStatus.Custom && v != this.referralStatus.Cancelled && v != this.referralStatus.Rejected).sort();
  }

  ngAfterViewInit(): void {
    this.scroll();
  }

  ngOnInit() {

    if(this.isExtranentInbound){
      this.isInbound = this.isExtranentInbound;
      this.referralId = this.extranetReferralId;
      this.getSingleReferral(this.referralId);
    } else {
      this.isInbound = this.router.url.split("/")[1] == 'in-bound';
      this.activatedRoute.params.subscribe(param => {
        if (!param['id']) {
          this.router.navigate([this.isInbound ? '/in-bound' : '/out-bound']);
          return;
        }
        this.referralId = param['id'];
        this.referralIndex = param['index'];
        this.getSingleReferral(this.referralId);
      });
    }


    this.formGroup = this.fb.group({
      patientFirstName: [''],
      patientLastName: [''],
      dateOfBirth: [''],
      phoneNumber: [''],
      preMedRequired: [''],
      priorityLevel: [''],
      referralReasonNotes: [''],
      procedureType: [''],
      affectedToothRange: [''],
      xray: [''],
      xrayDate: [''],
      xrayFileName: [''],
      reasonForReferral: [''],
      referralTo: ['', []],
      referralFrom: ['', []],
      otherProcedures: [''],
      tissueType: [''],
      referralStatus: ['', Validators.required],
      appointmentDate: [''],
      postReferral:[''],
      patientInsurance:['']
    });

    this.formGroup.controls.referralStatus.valueChanges.subscribe(v => {
      if (v == this.referralStatus.Scheduled) {
        this.formGroup.get('appointmentDate').enable();
        this.showAppointmentDatePicker = true;
        this.formGroup.get('appointmentDate').setValidators([Validators.required]);
      } else {
        this.formGroup.patchValue({appointmentDate: ''});
        this.showAppointmentDatePicker = false;
      }
    });
    this.formGroup.disable();

    if (this.isInbound) {
      this.formGroup.get('referralStatus').enable({onlySelf: true})
    }

    console.log('is init recalled?');

  }

  scroll() {
    let el = document.getElementById('target');
    el.scrollIntoView({behavior: 'smooth'});
  }

  goBack() {
    if (this.isInbound) {
      this.router.navigate(['/in-bound']);
    } else {
      this.router.navigate(['/out-bound']);
    }
  }

  getReferralStatusNotes(referralId) {
    this.dentalReferralController.getReferralStatusAndNotes({referralRequestId: referralId}).subscribe(res => {
      this.referralStatusNotesList = res;
      /*filtering the option notes  */
      // this.referralStatusEnum = this.referralStatusEnum.filter(v => this.referralStatusNotesList.some(stat => stat.status != v));
      /* Checking status */

      this.processStatusList();

      const statusCheck = this.referralStatusNotesList?.some(stat => stat.status === ReferralStatusNotes.StatusEnum.Completed);
      if (statusCheck)
        clearInterval(this.intervalId);
    })
  }

  showCalled() {
    return this.referralStatusNotesList?.some(e => e.status === this.statusNoteEnum.Pending) && this.referralStatusNotesList?.length == 1;
  }

  showScheduled() {
    return this.referralStatusNotesList?.some(e => e.status === this.statusNoteEnum.Called) && this.referralStatusNotesList?.length == 2;
  }

  showRescheduled() {
    return this.referralStatusNotesList?.some(e => e.status === this.statusNoteEnum.Scheduled) && this.referralStatusNotesList?.length == 3;
  }

  showCompleted() {
    return this.referralStatusNotesList?.some(e => e.status === this.statusNoteEnum.Scheduled) && this.referralStatusNotesList?.length <= 4;
  }


  checkCurrentStatus(referralStatus: ReferralStatusNotes.StatusEnum) {
    if (this.singleReferral?.referralStatusConstant == referralStatus) {
      return true;
    }
    return this.referralStatusNotesList?.some(stat => stat.status === referralStatus);
  }

  pollingForReferralStatusChange(referralId) {
    this.intervalId = setInterval(() => {
      this.getReferralStatusNotes(referralId);
    }, 8000)
  }

  getSingleReferral(id: number) {
    this.dentalReferralController.getSingleReferralData({referralDataId: id}).subscribe(res => {
      this.adultTeethCheck = res.adultTeethCheck;
      this.referralRequestId = res.referralRequestId;
      this.singleReferral = res;
      this.dentistOfficeVideo = res.dentistOfficeVideo;
      this.specialistOfficeVideo = res.specialistOfficeVideo;
      this.selectedStatus = res.referralStatusConstant;
      console.log('this.selectedStatus '+this.selectedStatus);
      this.formGroup.patchValue({
        referralStatus: this.selectedStatus
      });
      console.log('raw',this.formGroup.getRawValue());

      if (this.referralRequestId !== undefined && this.referralRequestId !== null) {
        this.getReferralStatusNotes(this.referralRequestId);
        this.pollingForReferralStatusChange(this.referralRequestId);
      }
      this.addValueToData(res);
      this.updateNewStatus();
      // this.checkIsInbound(res);
    }, error => {
    })
  }

  updateNewStatus() {
    if (!this.isInbound) {
      if (this.singleReferral.referralStatusConstant == this.referralStatus.New) {
        this.formGroup.patchValue({
          referralStatus: 'UNREAD'
        });
        return;
      }
      return;
    }
    if (this.singleReferral.referralStatusConstant == this.referralStatus.New) {
      this.formGroup.patchValue({
        referralStatus: this.referralStatus.Pending
      })
      this.updateStatus = true;
      this.dentalReferralController
        .updateDentalReferralRequestStatus({
          updateStatusNoteDto: {
            referralRequestId: this.referralRequestId,
            referralStatus: this.formGroup.getRawValue().referralStatus,
            appointmentDate: this.formatDateTime(this.formGroup.getRawValue().appointmentDate)
          }
        })
        .subscribe(res => {
          this.updateStatus = false;
        }, error => {
          console.log(error)
          this.updateStatus = false;
        });
    }

  }

  addValueToData(referralSearchPojo: ReferralSearchPojo) {

    this.formGroup.patchValue({patientFirstName: referralSearchPojo?.patient?.firstName});
    this.formGroup.patchValue({patientLastName: referralSearchPojo?.patient?.lastName});
    this.formGroup.patchValue({dateOfBirth: this.format(referralSearchPojo?.patient?.dateOfBirth)});
    this.insuranceCompany = referralSearchPojo.patient.insuranceCompany;
    this.formGroup.patchValue({patientInsurance: referralSearchPojo.patient.insuranceCompany})
    this.formGroup.patchValue({phoneNumber: referralSearchPojo?.patient?.phoneNumber});
    this.formGroup.patchValue({preMedRequired: this.toTitleCase(referralSearchPojo?.preMedRequired?.toString())});
    this.formGroup.patchValue({priorityLevel: referralSearchPojo?.priorityLevel});
    this.formGroup.patchValue({referralReasonNotes: referralSearchPojo?.referralReasonNote});
    this.formGroup.patchValue({affectedToothRange: referralSearchPojo?.affectedToothRange});
    this.formGroup.patchValue(
      {
        referralTo: referralSearchPojo?.dentalOfficeReferralTo && referralSearchPojo?.dentalProfessionalReferralToEntity?.name
          ? `${referralSearchPojo?.dentalOfficeReferralTo?.name} [ Dr. ${referralSearchPojo?.dentalProfessionalReferralToEntity?.name} ]` :
          referralSearchPojo?.dentalOfficeReferralTo?.name && !referralSearchPojo?.dentalProfessionalReferralToEntity?.name ? `${referralSearchPojo?.dentalOfficeReferralTo?.name} [Available Dr.]` : referralSearchPojo?.temporaryDentalOfficePojo?.name + '[Available Dr.]'
      });
    this.formGroup.patchValue({referralFrom: referralSearchPojo?.dentalOfficeReferralFrom?.name && !referralSearchPojo?.dentalProFromFirstName ? referralSearchPojo?.dentalOfficeReferralFrom?.name : referralSearchPojo?.dentalOfficeReferralFrom?.name + ' [' + referralSearchPojo?.dentalProFromFirstName + ' ' + referralSearchPojo?.dentalProFromLastName + ' ] '});
    this.formGroup.patchValue({referralStatus: referralSearchPojo?.referralStatusConstant});

    this.affectedTeeth = referralSearchPojo?.affectedToothRange;
    this.referralReasons = referralSearchPojo?.procedureType;

    this.notesCompleted = referralSearchPojo?.referralStatusNotesList?.filter(v => v.status == 'COMPLETED')[0]?.note;
    this.formGroup.patchValue({postReferral: referralSearchPojo?.referralStatusNotesList?.filter(v => v.status == 'COMPLETED')[0]?.note});

    if (this.referralStatus.Scheduled == referralSearchPojo?.referralStatusConstant) {
      this.showAppointmentDatePicker = false;
    }
    this.displayName = referralSearchPojo?.patient?.displayName;
    this.patientAge = this.getAge(referralSearchPojo?.patient?.dateOfBirth);
  }

  downloadXrayFile(fileId: number) {
    this.bwFileControllerService.getIdFileByte({id: fileId})
      .subscribe((res) => {
        this.docData = res.data;
        console.log(res.contentType);
        const blob = ViewReferralComponent.base64toBlob(this.docData, this.filePath(res.contentType));
        this.exportUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = this.exportUrl;
        a.target = '   _blank';
        a.download = this.displayName + '-file';
        document.body.appendChild(a);
        a.click();
      });
  }

  updateReferralStatus(statusUpdateDto) {
    // if (this.formGroup.getRawValue().referralStatus.toString().toLowerCase() == this.singleReferral.referralStatusConstant.toLowerCase() && this.formGroup.getRawValue().appointmentDate == '') {
    //   this.showErrorMessage('Select an appointment date.');
    //   return;
    // }
    const status = this.formGroup.getRawValue().referralStatus;
    this.updateStatus = true;
    const referralPayload: UpdateDentalReferralRequestStatusRequestParams = {
      updateStatusNoteDto: {
        referralRequestId: this.referralRequestId,
        referralStatus: status,
        appointmentDate: this.formatDateTime(statusUpdateDto?.appointmentDate),
        statusNote: statusUpdateDto?.note,
        specialistPostReferralServiceDto: status == 'COMPLETED' ? this.buildPostReferralDto(statusUpdateDto) : null
      }
    }
    this.dentalReferralController.updateDentalReferralRequestStatus(referralPayload).subscribe(res => {
      //Updating the value of behavioural subject
      this.generalService.reloadListSubject.next(true);
      this.updateStatus = false;
      this.completionModal('Status Updated', 'Referral status has been updated successfully.', 'OK', true);
    }, error => {
      this.completionModal('Error During Update', 'Error in updating referral status.', 'Dismiss', false);
      this.updateStatus = false;
    });
  }

  buildPostReferralDto(params) {

    return {
      estimatedPatientPortion: params.estimatedPatientPortion,
      estimatedInsurancePortion: params.estimatedInsurancePortion,
      file: params.referralPostReport,
      contentType: params.reportContentType,
      fileName: params.reportName
    }
  }

  completionModal(title, subTitle, buttonMessage, isSuccess) {
    let bsModalRef = this.modalService.show(CompletionModalComponent, {
      initialState: {
        isSuccess: isSuccess,
        title: title,
        subTitle: subTitle,
        buttonText: buttonMessage
      },
      keyboard: false,
      backdrop: 'static',
      class: 'modal-sm modal-dialog-centered'
    });

    bsModalRef?.content?.onSubmitButtonClick.subscribe(v => {
      // window.location.reload();
    });
  }

  clickStatus(event) {
    const selectedValue = event.target.value;
    this.formGroup.patchValue({referralStatus: selectedValue});
    if(selectedValue != 'PENDING'){
      this.showOptionsModal(this.formGroup.get('referralStatus').value)
    }
  }

  showOptionsModal(status) {
    let bsModalRef = this.modalService.show(OptionSelectionModalComponent, {
      initialState: {
        modalTitle: 'Update Status',
        modalMessage: '',
        updateBtnText: 'Update Status',
        status: status,
      },
      keyboard: false,
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered'
    });
    bsModalRef?.content?.onSubmitButtonClick.subscribe(statusUpdateDto => {
      bsModalRef.hide();
      this.updateReferralStatus(statusUpdateDto);
    });
    bsModalRef?.content?.onDismissButtonClick.subscribe(v => {
      this.formGroup.patchValue({
        referralStatus: this.selectedStatus
      });
      bsModalRef.hide();
    });
  }

  toTitleCase(str) {
    if (!str) return "";
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, "");
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    window.scroll(0, 0);
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  loadOfficeImage(value){
    if(value){
      return environment.siteUrl + "/files/" + value;
    }

    return "/assets/img/loginLogo.svg";
  }

  getFileURL(file: XrayPojo) {

    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "png", "jpg", "gif"];
    const isValidExtension = validExtensions.indexOf(file.contentType) > -1;
    if (isValidExtension) {
      return environment.siteUrl + "/files/" + file.bwFileId;
    }
    return "/assets/img/image.png";
  }

  getPostReferralDoc(file: BwFilePojo) {
    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "png", "jpg", "gif"];
    const isValidExtension = validExtensions.indexOf(file.contentType) > -1;
    if (isValidExtension) {
      return environment.siteUrl + "/files/" + file.id;
    }
    return "/assets/img/image.png";
  }

  public static base64toBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 1024;
    base64Data = base64Data.replace(/\s/g, '');
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  filePath(path: string) {
    if (path == 'pdf') {
      return 'application/pdf'
    } else if (path == 'png') {
      return 'image/png'
    } else if (path == 'jpg') {
      return 'image/jpg'
    } else if (path == 'jpeg') {
      return 'image/jpeg'
    } else if (path == 'gif') {
      return 'image/gif'
    }

    return path;
  }

  getAge(date) {
    let now = moment(new Date());
    let end = moment(date);
    let duration = moment.duration(now.diff(end));
    return Math.round(duration.asYears());
  }

  format(date: string) {
    if (!date) return '';
    return moment(date).format('MM/DD/YYYY');
  }

  formatDateTime(dateTime: string) {
    if (!dateTime) {
      return null;
    }
    return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
  }

  formatResponse(dateTime: string) {
    if (!dateTime) {
      return null;
    }
    return moment(dateTime).format('ddd, D MMMM YYYY');
  }

  showModalInfo(firstContactPojo?: FirstContactPojo) {
    let bsModalRef = this.modalService.show(InsuranceSnapshotModalComponent, {
      initialState: {
        firstContactPojo: firstContactPojo
      },
      keyboard: false,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
    });

  }

  showAppointmentModal(firstContactPojo?: FirstContactPojo) {
    let bsModalRef = this.modalService.show(AppointmentPreferenceModalComponent, {
      initialState: {
        firstContactPojo: firstContactPojo
      },
      keyboard: false,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
    });

  }

  processStatusList() {
    const groupedStatusList = this.referralStatusNotesList.reduce((acc, curr) => {
      acc[curr.status] = acc[curr.status] || [];
      acc[curr.status].push(curr);
      return acc;
    }, {});

    const filteredStatusList = Object.keys(groupedStatusList).map(status => {
      const entries = groupedStatusList[status];

      const mostRecentEntry = entries.reduce((prev, current) =>
        (new Date(current.updatedAt) > new Date(prev.updatedAt)) ? current : prev
      );

      return status === this.statusNoteEnum.Scheduled ? {
        ...mostRecentEntry,
        referralRequest: {
          ...mostRecentEntry.referralRequest,
          appointmentDate: mostRecentEntry.referralRequest?.appointmentDate
        }
      } : mostRecentEntry;
    });

    this.referralStatusNotesList = filteredStatusList.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  }

  safeUrl(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  downloadReferralForm(referralRequestId: number) {
    var a = document.createElement('a');
    a.href = environment.dentalDoorReportUrl + '/referral-form/' + referralRequestId;
    a.target = '_blank';
    a.download = 'any';
    document.body.appendChild(a);
    a.click();
  }


  adultTeethToggle() {
    this.adultTeethCheck = !this.adultTeethCheck;
  }

  hasMixedTeethCheck(): boolean {
    let hasAdult = false;
    let hasChild = false;

    this.affectedTeeth?.split(',').forEach(tooth => {
      if (!isNaN(Number(tooth.trim()))) {
        hasAdult = true;
      } else {
        hasChild = true;
      }
    });

    return hasAdult && hasChild;
  }
}
