/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountTypeRole } from './portal-account-type-role';
import { PortalAccountMembership } from './portal-account-membership';
import { PortalUser } from './portal-user';


export interface PortalAccountMemberRole { 
    dateCreated?: string;
    dateDeactivated?: string;
    status?: PortalAccountMemberRole.StatusEnum;
    id?: number;
    deactivatedBy?: PortalUser;
    createdBy?: PortalUser;
    membership?: PortalAccountMembership;
    role?: PortalAccountTypeRole;
}
export namespace PortalAccountMemberRole {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


