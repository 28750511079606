/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RevenueItem } from './revenue-item';
import { Subscription } from './subscription';


export interface SubscriptionCycle { 
    dateCreated?: string;
    status?: SubscriptionCycle.StatusEnum;
    billingCycle?: SubscriptionCycle.BillingCycleEnum;
    id?: number;
    subscription?: Subscription;
    revenueItem?: RevenueItem;
}
export namespace SubscriptionCycle {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type BillingCycleEnum = 'MONTHLY' | 'ANNUAL' | 'QUARTERLY' | 'SEMI_ANNUAL';
    export const BillingCycleEnum = {
        Monthly: 'MONTHLY' as BillingCycleEnum,
        Annual: 'ANNUAL' as BillingCycleEnum,
        Quarterly: 'QUARTERLY' as BillingCycleEnum,
        SemiAnnual: 'SEMI_ANNUAL' as BillingCycleEnum
    };
}


