import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AffiliateBankPojo, WalletTransactionStatPojo} from "dd-core-api-sdk";
import {environment} from "../../../../../../../environments/environment";
import {FormBuilder, FormControl} from "@angular/forms";

@Component({
  selector: 'app-wallet-withdrawal-i',
  templateUrl: './wallet-withdrawal-i.component.html',
  styleUrls: ['./wallet-withdrawal-i.component.css']
})
export class WalletWithdrawalIComponent implements OnInit {

  @Output()
  emitCancelButton = new EventEmitter<any>();
  @Output()
  nextClicked = new EventEmitter<any>();

  @Input()
  affiliateBankPojo: AffiliateBankPojo;
  @Input()
  walletListCommissionStat: WalletTransactionStatPojo;
  amountCtr = new FormControl(null);
  totalProcessingFee: any = 0;
  totalDueAmount: any = 0;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  amountValue: any = 0;
  balanceAfterWithDrawal: any;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.amountCtr.valueChanges.subscribe(v => {
      this.amountValue = v ? v : 0;
    })
  }

  buildBankUrl(url: string) {
    return environment.url + url;
  }

  totalWithDrawalAmount() {
    this.totalDueAmount = this.totalProcessingFee + parseFloat(this.amountValue);
    return Number(this.totalDueAmount).toFixed(2);
  }

  balanceAfterWithdrawal() {
    this.balanceAfterWithDrawal =  this.walletListCommissionStat?.totalAmountPayable - (this.totalProcessingFee + parseFloat(this.amountValue));
    return this.balanceAfterWithDrawal;
  }

  processingFee(processingFeePercentage, fixedFee): string {
    if (processingFeePercentage && fixedFee)
      this.totalProcessingFee = (this.amountValue * (processingFeePercentage / 100)) + fixedFee;
    return this.totalProcessingFee;
  }

  goToNextStep() {
    if (this.amountValue == null || this.amountValue == '') {
      this.isError = true;
      this.showErrorMessage('Withdrawal amount cannot be empty!');
      return;
    }
    const value1 = Number(this.totalDueAmount).toFixed(2);
    const value2 = Number(this.walletListCommissionStat?.totalAmountPayable).toFixed(2);

    const maxValue = Math.max(Number(value1), Number(value2));
    const minValue = Math.min(Number(value1), Number(value2));

    // console.log(maxValue)
    // console.log(Number(this.balanceAfterWithDrawal).toFixed(2))
    // console.log(this.totalDueAmount);

    if (maxValue === Number(value1)) {
      if(Number(this.balanceAfterWithDrawal).toFixed(2) == "0.01"){
        this.isError = true;
        this.showErrorMessage('Wallet cannot be empty.');
        return;
      }
      this.isError = true;
      this.showErrorMessage('Withdrawal amount cannot be greater than wallet balance.');
      return;
    }

    this.nextClicked.next({totalAmountDue: this.totalDueAmount, inputedAmount: this.amountValue});
    return;
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }
}
