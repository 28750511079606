<main>


    <div class="docpage-bg" style="z-index: -1;">

    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-12" id="faq">
                <div class="" style="z-index: 1;">
                    <h4 class="mt-4 mb-3 ml-2 text-light font-weight-bold">DentalDoor Policy</h4>
                </div>
                <div class="card border-0 docpage-card">


                    <div class="card-body">
                        <div class="heading mt-0">Introduction</div>
                        <p>This privacy policy has been compiled to better serve those who are concerned with how their
                            'Personally identifiable information' (PII) is being used online. PII, as used in US privacy
                            law and information security, is information that can be used on its own or with other
                            information to identify, contact, or locate a single person, or to identify an individual in
                            context. Please read our privacy policy carefully to get a clear understanding of how we
                            collect, use, protect or otherwise handle your Personally Identifiable Information in
                            accordance with our website</p>

                        <div class="section-divider" style="background:#69BDE6">
                            <div class="row">
                                <div class="col-lg-7">
                                    <div class="text">Information you share with us</div>
                                </div>
                                <div class="col-lg-5 d-none d-lg-block">
                                    <div class="number">1</div>
                                </div>
                            </div>
                        </div>

                        <div class="in-nav">
                            <div class="row">
                                <div class="col-lg-4 sidebar">
                                    <div class="title"><a href="#1">What personal information do we collect from the
                                            people that visit our blog, website or app?</a></div>
                                    <div class="title"><a href="#2">When do we collect information?</a></div>
                                    <div class="title"><a href="#3">How do we use your information?</a></div>
                                    <div class="title"><a href="#4">How do we protect visitorinformation?</a></div>
                                    <div class="title"><a href="#5">Do we use 'cookies'?</a></div>
                                    <div class="title"><a href="#6">Third-party disclosure</a></div>
                                    <div class="title"><a href="#7">Third-party links</a></div>
                                    <div class="title"><a href="#8">Google</a></div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="heading" id="1">What personal information do we collect from the people
                                        that visit our blog, website or app?</div>
                                    <p>When ordering or registering on our site, as appropriate, you may be asked to
                                        enter your name, email address, mailing address, phone number, credit card
                                        information, License number or other details to help you with your
                                        experience.When ordering or registering on our site, as appropriate, you may be
                                        asked to enter your name, email address, mailing address, phone number, credit
                                        card information, License number or other details to help you with your
                                        experience.</p>

                                    <div class="heading" id="2">When do we collect information?</div>
                                    <p>We collect information from you when you register on our site, place an order,
                                        fill out a form, Open a Support Ticket or enter information on our site.</p>

                                    <div class="heading" id="3">How do we use your information?</div>
                                    <p>We may use the information we collect from you when you register, make a
                                        purchase, sign up forour newsletter, respond to a survey or marketing
                                        communication, surf the website, or use certain other site features in the
                                        following ways:</p>

                                    <p class="m-0">
                                        <li>To personalize user's experience and to allow us to deliver the type of
                                            content and product offerings in which you are most interested</li>
                                        <li>To improve our website in order to better serve you.</li>
                                        <li>To allow us to better service you in responding to your customer service
                                            requests.</li>
                                        <li>To administer a contest, promotion, survey or other site feature.</li>
                                        <li>To quickly process your transactions.</li>
                                        <li>To ask for ratings and reviews of services or products</li>
                                        <li>To follow up with them after correspondence (live chat, email or phone
                                            inquiries)</li>
                                    </p>


                                    <div class="heading" id="4">How do we protect visitor information?</div>
                                    <p>Our website is scanned on a regular basis for security holes and known
                                        vulnerabilities in order to make your visit to our site as safe as possible. We
                                        use regular Malware Scanning. Your personal information is contained behind
                                        secured networks and is only accessible by a limited number of persons who have
                                        special access rights to such systems, and are required to keep the information
                                        confidential. In addition, all sensitive/credit information you supply is
                                        encrypted via Secure Socket Layer (SSL) technology. We implement a variety of
                                        security measures when a user places an order enters, submits, or accesses their
                                        information to maintain the safety of your personal information. All
                                        transactions are processed through a gateway provider and are not stored or
                                        processed on our servers.</p>

                                    <div class="heading" id="5">Do we use 'cookies'?</div>
                                    <p>Yes. Cookies are small files that a site or its service provider transfers to
                                        your computer's hard drive through your Web bd-flex flex-wrapser (if you allow)
                                        that enables the site's or service provider's systems to recognize your bd-flex
                                        flex-wrapser and capture and remember certain information. For instance, we use
                                        cookies to help us remember and process the items in your shopping cart. They
                                        are also used to help us understand your preferences based on previous or
                                        current site activity, which enables us to provide you with improved services.
                                        We also use cookies to help us compile aggregate data about site traffic and
                                        site interaction so that we can offer better site experiences and tools in the
                                        future.</p>

                                    <div class="title">We use cookies to:</div>

                                    <ul class="m-0">
                                        <li>Help remember and process the items in the shopping cart.</li>
                                        <li>Understand and save user's preferences for future visits.</li>
                                        <li>Compile aggregate data about site traffic and site interactions in order to
                                            offer better site experiences and tools in the future. We may also use
                                            trusted third-party services that track this information on our behalf</li>
                                    </ul>

                                    <p>You can choose to have your computer warn you each time a cookie is being sent,
                                        or you can choose to turn off all cookies. You do this through your bd-flex
                                        flex-wrapser (like Internet Explorer) settings. Each bd-flex flex-wrapser is a
                                        little different, so look at your bd-flex flex-wrapser's Help menu to learn the
                                        correct way to modify your cookies.</p>

                                    <div class="title">If users disable cookies in their bd-flex flex-wrapser:</div>
                                    <p>If you disable cookies off, some features will be disabled It will turn off some
                                        of the features that make your site experience more efficient and some of our
                                        services will not function properly.</p>
                                    <p>However, you can still place orders by contacting customer service.</p>

                                    <div class="heading" id="6">Third-party disclosure?</div>
                                    <p>We do not sell, trade, or otherwise transfer to outside parties your personally
                                        identifiable information.</p>

                                    <div class="heading" id="7">Third-party links</div>
                                    <p>Occasionally, at our discretion, we may include or offer third-party products or
                                        services on our website. These third-party sites have separate and independent
                                        privacy policies. We therefore have no responsibility or liability for the
                                        content and activities of these linked sites. Nonetheless, we seek to protect
                                        the integrity of our site and welcome any feedback about these sites.</p>

                                    <div class="heading" id="8">Google</div>
                                    <p>place to provide a positive experience for users. <a
                                            href="https://support.google.com/adwordspolicy/answer/1316548?hl=en"
                                            target="_blank">https://support.google.com/adwordspolicy/answer/1316548?hl=en</a>
                                        We have not enabled Google AdSense on our site but we may do so in the future.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="section-divider" style="background: #02183D">
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="text">Additional Information We Receive About You</div>
                                </div>
                                <div class="col-lg-7 d-none d-lg-block">
                                    <div class="number">2</div>
                                </div>
                            </div>
                        </div>

                        <div class="in-nav">
                            <div class="row">
                                <div class="col-lg-4 sidebar">
                                    <div class="title"><a href="#9">California Online Privacy Protection Act</a></div>
                                    <div class="title"><a href="#10">How does our site handle do not track signals?</a>
                                    </div>
                                    <div class="title"><a href="#11">Does our site allow third-party behavioral
                                            tracking?</a></div>
                                    <div class="title"><a href="#12">COPPA (Children Online Privacy Protection Act)</a>
                                    </div>
                                    <div class="title"><a href="#13">Fair Information Practices</a></div>
                                    <div class="title"><a href="#14">CAN SPAM Act</a></div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="heading" id="9">California Online Privacy Protection Act?</div>
                                    <p>CalOPPA is the first state law in the nation to require commercial websites and
                                        online services to post a privacy policy. The law's reach stretches well beyond
                                        California to require a person or company in the United States (and conceivably
                                        the world) that operates websites collecting personally identifiable information
                                        from California consumers to post a conspicuous privacy policy on its website
                                        stating exactly the information being collected and those individuals with whom
                                        it is being shared, and to comply with this policy.</p>
                                    <p>See more at: <a
                                            href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</a>
                                    </p>

                                    <div class="title">According to CalOPPA we agree to the following:</div>
                                    <p>Users can visit our site anonymously. Once this privacy policy is created, we
                                        will add a link to it on our home page or as a minimum on the first significant
                                        page after entering our website. Our Privacy Policy link includes the word
                                        'Privacy' and can be easily be found on the page specified above.</p>
                                    <p>Users will be notified of any privacy policy changes:</p>

                                    <ul class="m-0">
                                        <li>On our Privacy Policy Page</li>
                                    </ul>

                                    <p>Users are able to change their personal information:</p>

                                    <ul class="m-0">
                                        <li>By logging in to their account</li>
                                    </ul>


                                    <div class="heading" id="10">How does our site handle do not track signals?</div>
                                    <p>We honor do not track signals and do not track, plant cookies, or use advertising
                                        when a Do Not Track (DNT) bd-flex flex-wrapser mechanism is in place.</p>

                                    <div class="heading" id="11">Does our site allow third-party behavioral tracking?
                                    </div>
                                    <p>It's also important to note that we do not allow third-party behavioral tracking
                                    </p>

                                    <div class="heading" id="12">COPPA (Children Online Privacy Protection Act)</div>
                                    <p>When it comes to the collection of personal information from children under 13,
                                        the Children's Online Privacy Protection Act (COPPA) puts parents in control.
                                        The Federal Trade Commission, the nation's consumer protection agency, enforces
                                        the COPPA Rule, which spells out what operators of websites and online services
                                        must do to protect children's privacy and safety online.</p>
                                    <p>We do not specifically market to children under 13.</p>

                                    <div class="heading" id="13">Fair Information Practices</div>
                                    <p>The Fair Information Practices Principles form the backbone of privacy law in the
                                        United States and the concepts they include have played a significant role in
                                        the development of data protection laws around the globe. Understanding the Fair
                                        Information Practice Principles and how they should be implemented is critical
                                        to comply with the various privacy laws that protect personal information.</p>

                                    <div class="title">In order to be in line with Fair Information Practices we will
                                        take the following responsive action, should a data breach occur:</div>
                                    <p>We will notify the users via email</p>

                                    <ul class="m-0">
                                        <li>Within 1 business day</li>
                                    </ul>

                                    <p>We also agree to the Individual Redress Principle, which requires that
                                        individuals have a right to pursue legally enforceable rights against data
                                        collectors and processors who fail to adhere to the law. This principle requires
                                        not only that individuals have enforceable rights against data users, but also
                                        that individuals have recourse to courts or government agencies to investigate
                                        and/or prosecute non-compliance by data processors.</p>

                                    <div class="heading" id="14">CAN SPAM Act</div>
                                    <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes
                                        requirements for commercial messages, gives recipients the right to have emails
                                        stopped from being sent to them, and spells out tough penalties for violations.
                                    </p>
                                    <p><strong>We collect your email address in order to:</strong></p>

                                    <ul class="m-0">
                                        <li>Send information, respond to inquiries, and/or other requests or questions.
                                        </li>
                                        <li>Process orders and to send information and updates pertaining to orders.
                                        </li>
                                        <li>We may also send you additional information related to your product and/or
                                            service.</li>
                                        <li>Market to our mailing list or continue to send emails to our clients after
                                            the original transaction has occurred.</li>
                                    </ul>

                                    <p><strong>To be in accordance with CANSPAM we agree to the following:</strong></p>

                                    <ul class="m-0">
                                        <li>NOT use false or misleading subjects or email addresses.</li>
                                        <li>Identify the message as an advertisement in some reasonable way.</li>
                                        <li>Include the physical address of our business or site headquarters.</li>
                                        <li>Monitor third-party email marketing services for compliance, if one is used.
                                        </li>
                                        <li>Honor opt-out/unsubscribe requests quickly.</li>
                                        <li>Allow users to unsubscribe by using the link at the bottom of each email.
                                        </li>
                                    </ul>

                                    <p><strong>If at any time you would like to unsubscribe from receiving future
                                            emails, you can email us at</strong></p>
                                    <p><a href="mailto:help@dentaldoor.com">help@dentaldoor.com</a> and we will promptly
                                        remove you from ALL correspondence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- /row -->
    </div>
    <!-- /container -->


</main>