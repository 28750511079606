<div class="container pt-5 ">
  <div class="row">
    <div class="col-lg-6 title">
      <p style="font-size: 300%; font-weight: bold">Join DentalDoor</p>
      <p>Secure, smart and easy to use dental referral system.</p>
    </div>
    <div class="card col-lg-6">
      <div class="card-body">
<!--        <h6 class="card-subtitle mb-2 text-muted pb-2 pt-2" *ngIf="this.account?.length >= 1">The Admin of {{this.account[0].accountName}}, Let's get to know you more.</h6>-->
        <h6 class="card-subtitle mb-2 text-muted pb-2 pt-2">Let's get to know you more</h6>
        <div style="width: auto">
          <form class="row g-3" [formGroup]="form">
            <div class="form-outline mb-2">
              <label for="firstName" class="form-label required">First Name</label>
              <input type="text" class="form-control" id="firstName" placeholder="Jamie" formControlName="firstName">
            </div>
            <div class="form-outline mb-2">
              <label for="lastName" class="form-label required">Last Name</label>
              <input type="text" class="form-control" id="lastName" placeholder="Doe" formControlName="lastName">
            </div>
            <div class="form-outline mb-2">
              <label class="form-label required">Phone Number </label>
              <ngx-intl-tel-input [cssClass]="'form-control'" [enableAutoCountrySelect]="true"
                                  [enablePlaceholder]="true" [id]="'phoneNumber'" [maxLength]="'20'"
                                  [phoneValidation]="true"
                                  [preferredCountries]="preferredCountries"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [searchCountryFlag]="true"
                                  [selectedCountryISO]="countryISO" [selectFirstCountry]="true"
                                  formControlName="phoneNumber"></ngx-intl-tel-input>
              <app-field-error
                *ngIf="(form.get('phoneNumber')?.errors?.required && form.get('phoneNumber')?.touched) || (form.get('phoneNumber')?.errors?.required && errorSubmission)"
                [message]="'Phone number cannot be blank.'"></app-field-error>

              <app-field-error class="mt-1" [message]="'Phone number already exist.'"
                               *ngIf="form.get('phoneNumber')!.errors?.phoneNumberExists"></app-field-error>
              <app-field-error class="mt-1" [message]="'Phone number is invalid.'"
                               *ngIf="form.get('phoneNumber')!.errors?.invalidPhoneNumber"></app-field-error>
            </div>

            <div>
              <label class="form-label required">Dental Specialization</label>
              <div class="col-sm-6"  *ngFor="let pro of profession; let i=index" >
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" formArrayName="selectedProfession" [value]="pro.value"
                         (change)="onCheckboxChange($event)"/>
                  <label class="form-check-label">
                    {{ pro.name}}
                  </label>
                </div>
              </div>
              <app-field-error
                *ngIf="(this.selectedCountries?.length < 1 && errorSubmission)"
                [message]="'Select at least one specialization.'"></app-field-error>
            </div>


            <div class="d-grid gap-2">
              <button class="btn btn-success" type="button"
                      (click)="form.getRawValue()?.phoneNumber?.e164Number && this.selectedCountries?.length >= 1  ? updateAccountAndSendOTP() : submittedForError()">
                <i class="fa fa-refresh fa-spin" *ngIf="this.sending"></i>
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
