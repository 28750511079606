/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalOffice } from './dental-office';


export interface FavoriteSearchFilter { 
    createdOnOrAfter?: string;
    createdBefore?: string;
    userId?: string;
    entityType?: FavoriteSearchFilter.EntityTypeEnum;
    id?: number;
    entityId?: number;
    dentalOffice?: DentalOffice;
    dislike?: boolean;
    entityName?: string;
    limit?: number;
    status?: FavoriteSearchFilter.StatusEnum;
    offset?: number;
}
export namespace FavoriteSearchFilter {
    export type EntityTypeEnum = 'DentalOffice' | 'DentalProfessional' | 'Promotion' | 'Review' | 'FlashCardSet' | 'Comment';
    export const EntityTypeEnum = {
        DentalOffice: 'DentalOffice' as EntityTypeEnum,
        DentalProfessional: 'DentalProfessional' as EntityTypeEnum,
        Promotion: 'Promotion' as EntityTypeEnum,
        Review: 'Review' as EntityTypeEnum,
        FlashCardSet: 'FlashCardSet' as EntityTypeEnum,
        Comment: 'Comment' as EntityTypeEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


