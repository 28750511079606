import {Component, OnInit} from '@angular/core';
import {AffiliateBankPojo} from "dd-core-api-sdk";

@Component({
  selector: 'app-affiliate-withdrawal',
  templateUrl: './affiliate-withdrawal.component.html',
  styleUrls: ['./affiliate-withdrawal.component.css']
})
export class AffiliateWithdrawalComponent implements OnInit {

  stepSwitch = true;
  tabIndex = 0;
  affiliateBankPojo: AffiliateBankPojo;
  bankKycStatusConstantEnum = AffiliateBankPojo.BankKycStatusConstantEnum;
  bankVerificationStatusConstantEnum = AffiliateBankPojo.BankVerificationStatusConstantEnum;

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.stepSwitch);
  }

  onClick() {
    this.stepSwitch = !this.stepSwitch;
  }

  emitBankDetails($event: AffiliateBankPojo) {
    this.affiliateBankPojo = $event;
    console.log(this.affiliateBankPojo)
  }
}
