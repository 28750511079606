import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {WizardComponent} from "angular-archwizard";
import {FormGroup} from "@angular/forms";
import {ReferralService} from "../../../../shared/subject/referral.service";
import {DentalReferralControllerService, ReferralDraft} from "dd-core-api-sdk";
import {RandomTrackingIdService} from "../../../../services/random-tracking-id.service";
import * as moment from "moment";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-create-referral-wizard',
  templateUrl: './create-referral-wizard.component.html',
  styleUrls: ['./create-referral-wizard.component.css']
})
export class CreateReferralWizardComponent implements OnInit {

  @ViewChild(WizardComponent)
  public wizard!: WizardComponent;
  formOneValues: FormGroup;
  editable: boolean = true;
  affectedTeeth: string;
  files: Array<any>
  temporaryOffice: any;
  formRawValues: any;
  bwFileIdList: any;

  isDraft: boolean;
  draft: ReferralDraft;

  @Output() toothRemoved = new EventEmitter<string>();
  deselectedTooth: any;


  selectedAdultTeeth: string = '';
  selectedChildTeeth : string = '';

  constructor(
    private _activeRoute: ActivatedRoute,
    private dentalReferralService: DentalReferralControllerService,
    private draftCodeGenerator: RandomTrackingIdService,
    private cdr: ChangeDetectorRef,
    private referralService: ReferralService) {
  }

  ngOnInit(): void {
    this._activeRoute.queryParams.subscribe(paramMap => {
      this.isDraft = paramMap?.isDraft;
      this.getDraft(paramMap?.id);
    })
  }

  getDraft(draftId) {
    if (!draftId)
      return
    this.dentalReferralService.getSingleReferralDraft({draftId: draftId})
      .subscribe(res => {
        this.draft = res;
        this.files = res.docs
        this.affectedTeeth = this.draft?.affectedTeeth;
      })
  }

  getValuesFromStep(value: any) {
    console.log("error")
    this.formOneValues = value.formGroup;
    this.bwFileIdList = value.bwFileIdList;
    this.formRawValues = this.formOneValues.getRawValue();

    console.log(this.formRawValues)
    console.log(value.fileDocs)
    this.referralService.saveReferralCreation({
      firstNamePatient: this.formRawValues.patientFirstName,
      lastNamePatient: this.formRawValues?.patientLastName,
      dateOfBirth: this.formRawValues?.dateOfBirth,
      phoneNumber: this.formRawValues?.phoneNumber?.internationalNumber,
      reasonsOfReferral: this.formRawValues?.procedureType,
      priorityLevel: this.formRawValues?.priorityLevel,
      preMedRequired: this.formRawValues?.preMedRequired,
      reasonReferrals: this.formRawValues?.reasonForReferral?.referralToProfValue,
      referralFrom: this.formRawValues?.referredBy,
      referralTo: this.formRawValues?.referralTo,
      affectedToothRange: this.formRawValues?.affectedToothRange,
      uploadedFiles: this.formRawValues?.uploadedFiles,
      procedureType: this.formRawValues?.procedureType,
      isAdult: this.formRawValues.isAdult,
      referralToOfficeId: this.formRawValues.referralToOfficeValue,
      referralToProfId: this.formRawValues.referralToProfValue,
      referredByOfficeAccountId: this.formRawValues.portalAccount,
      referredByProfessionalId: this.formRawValues.referralFrom,
      referralToEmail: this.formRawValues.referralToOfficeEmail,
      referralToAddress: this.formRawValues.referralToOfficeAddress,
      referralToName: this.formRawValues.referralToOfficeName,
      referralNotes: this.formRawValues.referralNote,
      temporaryToOfficeId: this.formRawValues.temporaryOfficeId,
      temporaryOfficeEmail: this.formRawValues.temporaryOfficeEmail,
      temporaryOfficeAddress: this.formRawValues.temporaryOfficeAddress,
      temporaryOfficeName: this.formRawValues.temporaryOfficeName,
      manualFirstName: this.formRawValues.manualFirstName,
      manualLastName: this.formRawValues.manualLastName,
      fileDocs: value.files,
      insuranceCode: this.formRawValues.patientInsurance,
      googleOfficeAddress: this.formRawValues.googleOfficeStreetAddress,
      googleOfficeName: this.formRawValues.googleOfficeName,
      googleOfficePhone: this.formRawValues.googleOfficePhone,


      isExternalReferral: this.formRawValues.isExternalReferral,
      externalReferralOfficeEmail: this.formRawValues.externalReferralOfficeEmail,
      externalReferralOfficePhone: this.formRawValues.externalReferralOfficePhone,

    });

    this.affectedTeeth = this.formRawValues?.affectedToothRange;
    this.editable = false;
    this.temporaryOffice = value?.temporaryOffice;
    this.saveDraft(value);
    console.log(value)

    if (value) {
      this.wizard.goToNextStep();
      return;
    }
  }

  getSelectedTeeth(teeth: Array<any>, isAdult: boolean) {
    if (isAdult) {
      this.selectedAdultTeeth = teeth.length == 0 ? '' : teeth.join(', ');
    } else {
      this.selectedChildTeeth = teeth.length == 0 ? '' : teeth.join(', ');
    }

    // Combine both adult and child teeth selections
    const allSelectedTeeth = [...this.selectedAdultTeeth.split(', ').filter(t => t !== ''), ...this.selectedChildTeeth.split(', ').filter(t => t !== '')];
    this.affectedTeeth = allSelectedTeeth.length == 0 ? '' : allSelectedTeeth.join(', ');
  }

  editFormPage(value: any) {
    this.editable = true;
    this.wizard.goToPreviousStep();
    return;
  }

  deleteDraft(sent: boolean) {
    this.saveDraft(sent)
  }

  deleteFileInfo(file: any) {
    if (file.id)
      this.dentalReferralService.deleteDraftFileInfo({id: Number.parseInt(file.fileId)})
        .subscribe(res => {
        })
  }

  saveDraft(value?, sent?) {
    this.dentalReferralService.saveReferralDraft({
      referralDraftDto: this.buildDraftPayload(value, sent)
    })
      .subscribe(res => {
        this.referralService.setDraftCreationSubject(res)
      })
  }

  buildDraftPayload(body, sent) {
    let value = body.formGroup.getRawValue()
    let draftCode = this.isDraft ? this.draft.draftCode : this.referralService.getDraftCode();
    if (sent) {
      return {
        draftCode: draftCode,
        sent: sent
      }
    }

    return {
      patientFirstName: value.patientFirstName,
      patientLastName: value.patientLastName,
      dateOfBirth: this.format(value.dateOfBirth),
      phoneNumber: value.phoneNumber?.internationalNumber,
      referralNote: value.referralNote,
      preMedRequired: value.preMedRequired,
      affectedTeeth: this.affectedTeeth,
      referralTo: value.referralTo,
      referralFrom: value.referredBy,
      procedureTypes: value.procedureType ? value.procedureType
        .map(procedure => procedure.value)
        .join(", ") : "",
      valid: body.formGroup.valid,
      draftCode: draftCode ? draftCode : this.draftCodeGenerator.getRandomTrackingId(),
      docs: body.files || [],
      priority: value.priorityLevel ? value.priorityLevel : null,
      referralToOfficeId: value.referralToOfficeValue,
      referralToProfId: value.referralToProfValue,
      referredByOfficeAccountId: value.portalAccount,
      referredByProfessionalId: value.referralFrom,
      profManualFirstName: value.manualFirstName,
      profManualLastName: value.manualLastName,
      isAdult: value.isAdult,
      sent: false,
      officeId: body.officeId,

      isExternalReferral: value.isExternalReferral,
      externalReferralOfficeEmail: value.externalReferralOfficeEmail,
      externalReferralOfficePhone: value.externalReferralOfficePhone,
    }
  }

  format(date: any) {
    if (!date) return '';

    return moment(date).subtract('month', 1).format('MM/DD/YYYY');
  }

  onToothDeselected(tooth: any) {

    this.deselectedTooth = tooth;
    this.cdr.detectChanges();
  }

}
