import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {
  CustomSubscriptionSearchFilter,
  CustomSubscriptionStatPojo,
  QueryResultsPojoTemporaryDentalOffice,
  TemporaryDentalOffice,
  TemporaryDentalOfficeControllerService,
  TemporaryDentalOfficeSearchFilter
} from "dd-core-api-sdk";
import * as moment from "moment/moment";
import {BehaviorSubject} from "rxjs";
import {getOffset, removeUndefinedOrNullFields} from "../../../models/search-model";
import {TempDentalOfficeModalComponent} from "../temp-dental-office-modal/temp-dental-office-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-temp-dental-office',
  templateUrl: './temp-dental-office.component.html',
  styleUrls: ['./temp-dental-office.component.css']
})
export class TempDentalOfficeComponent implements OnInit {

  utcOffsetMinutes: string;
  showErrorMessageTrigger = false;
  isError: boolean = true;
  errMessage = '';
  searching = true;
  form: FormGroup;
  limit: number;
  offset: number;
  currentStartDate: any;

  successMessage: any;
  showSuccessMessageTrigger: boolean;

  filterData: TemporaryDentalOfficeSearchFilter = {};

  resultSubject = new BehaviorSubject<QueryResultsPojoTemporaryDentalOffice>({});
  queryResults: QueryResultsPojoTemporaryDentalOffice;

  subStatCount: CustomSubscriptionStatPojo;

  resultArray: Array<TemporaryDentalOffice>;

  data: TemporaryDentalOfficeSearchFilter;

  constructor(private temporaryDentalOfficeService: TemporaryDentalOfficeControllerService,
              private formBuilder: FormBuilder,
              private bsModalService: BsModalService) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [''],
      email: [''],
      phoneNumber: [''],
      startDate: [''],
      endDate: [''],
    });
  }


  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  formatUTCMoment(result: any) {
    return moment(result?.dateCreated).utc(true).toString();
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }


  search(filter: TemporaryDentalOfficeSearchFilter) {
    this.searching = true;
    this.temporaryDentalOfficeService.searchTempDentalOffice({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.offset = v.offset;
      this.queryResults = v;
      this.resultArray = v.results!;
      console.log(v)
      this.resultSubject.next(v);
    }, () => {
      this.searching = false;
    });

    this.data = filter;
    // this.data.page = this.page;
  }

  getFilter(limit?: number): TemporaryDentalOfficeSearchFilter {
    const data = this.form!.value;

    let filter = {} as any;
    filter.name = data.name;
    filter.email = data.email;
    filter.phoneNumber = data.phoneNumber;
    filter.startDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }

  onPageChange($event) {
    const searchFilter: CustomSubscriptionSearchFilter = {
      offset: getOffset($event.page, $event.size || 4),
      limit: $event.itemsPerPage,
    };

    this.search(searchFilter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }


  searchTempDentalOffice() {
    console.log('searching');
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);
    this.search(filter);
  }

  deleteOffice(id: number) {
    this.temporaryDentalOfficeService.deleteTempDentalOffice({temporaryDentalOfficeId: id}).subscribe(() => {
      this.showSuccessMessage('Dental Office Deleted Successfully');
      this.searchTempDentalOffice();
    }, error => {
      this.showErrorMessage('Sorry, could not delete dental office at the moment. Please try again later.');
    });
  }

  showSuccessMessage(success: any) {
    this.successMessage = success;
    this.showSuccessMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showSuccessMessageTrigger = false;
    }, 5000);
  }

  getSuccessMessage() {
    return this.successMessage;
  }

  createOrUpdate(result?: TemporaryDentalOffice) {
      let bsModalRef = this.bsModalService.show(TempDentalOfficeModalComponent, {
        initialState: {
          tempDentalOffice: result
        },
        keyboard: false,
        backdrop: 'static',
        class: 'modal-lg modal-dialog-centered'
      });
      bsModalRef?.content?.actionCompleted.subscribe(msg => {
        bsModalRef.hide();
        this.showSuccessMessage(msg);
        this.searchTempDentalOffice();
      });
  }
}
