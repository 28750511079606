import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CreditCardValidator} from "dd-shared-lib";
import {
  PaymentControllerService,
  UpdateCardInformationRequestParams
} from "dd-core-api-sdk";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../services/UserKeycloak";

@Component({
  selector: 'app-update-credit-card',
  templateUrl: './update-credit-card.component.html',
  styleUrls: ['./update-credit-card.component.css']
})
export class UpdateCreditCardComponent implements OnInit {

  form: FormGroup;
  working = false;
  user: UserKeycloak;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private paymentControllerService: PaymentControllerService,
              private modalRef: BsModalRef,
              private authenticationServiceKeycloak: AuthenticationServiceKeycloak
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      cardNumber: ['', Validators.compose([
        Validators.required,
        CreditCardValidator.validCardNumber
      ])],
      cvc: ['', Validators.compose([
        Validators.required,
        CreditCardValidator.validCvc])
      ],
      expiryDate: ['', Validators.compose([
        Validators.required,
        CreditCardValidator.validExpiryDate])
      ],
      postalCode: ['']
    });

    this.authenticationServiceKeycloak.getUser().subscribe(res => {
      this.user = res;
    });
  }

  close() {
    this.modalService.hide(1);
  }

  updateCard() {
    const formVal = this.form.value;
    const expiryDate = formVal.expiryDate;
    const month = parseInt(expiryDate.substr(0, 2));
    const year = parseInt(expiryDate.substr(-2));
    this.working = true;
    this.paymentControllerService.updateCardInformation(this.buildPayload(month, year)).subscribe(res => {
      this.working = false;
      this.isError = false;
      this.clearForm();
      this.showErrorMessage('Your card has been updated successfully.');
    }, error => {
      this.working = false;
      this.isError = true;
      this.clearForm();
      this.showErrorMessage('Error in updating card details.');
     });
  }

  buildPayload(month, year): UpdateCardInformationRequestParams {
    return {
      dentalOfficeId: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
      customerCardInfo: {
        cardNumber: this.form.value.cardNumber,
        cvc: this.form.value.cvc,
        expiryMonth: month,
        expiryYear: year
      }
    }
  }

  clearForm(){
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

}
