import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PhoneNumberValidator} from "dd-shared-lib";
import {UniqueIdValidatorService} from "../../../shared/unique-id-validator.service";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {UserControllerService, UserRegistrationService} from "dd-core-api-sdk";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-dental-account-update',
  templateUrl: './dental-account-update.component.html',
  styleUrls: ['./dental-account-update.component.css']
})
export class DentalAccountUpdateComponent implements OnInit {

  form: FormGroup;
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  errorSubmission: boolean;
  userId: any;
  sending: boolean;

  profession: Array<any> = [
    { name: 'General Dentist', value: 'General Dentist' },
    { name: 'Endodontist', value: 'Endodontist' },
    { name: 'Orthodontist', value: 'Orthodontist' },
    { name: 'Periodontist', value: 'Periodontist' },
    { name: 'Prediatric Dentist', value: 'Prediatric Dentist' },
    { name: 'Oral Surgeon', value: 'Oral Surgeon' },
    { name: 'Dental Hygenist', value: 'Dental Hygenist' },
    { name: 'Dental Assistant', value: 'Dental Assistant' },
    { name: 'Dental Assistant', value: 'Dental Assistant' },
    { name: 'Student', value: 'Student' },
    { name: 'Manager', value: 'Manager' }
  ];


  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private uniqueIdValidatorService: UniqueIdValidatorService,
              private userRegistration: UserRegistrationService,
              private userController: UserControllerService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (!param['userId']) {
        this.router.navigate(['/']);
        return;
      }
      this.userId = param.userId;
      this.getUserDetails(this.userId);
    });

    this.form = this.fb.group({
      firstName: [],
      lastName: [],
      phoneNumber: ['', [Validators.required, PhoneNumberValidator()]],
    });

    this.form.get('phoneNumber')?.setAsyncValidators([this.uniqueIdValidatorService.phoneNumberVerifier()]);
  }


  getUserDetails(userId: any) {
    this.userController.getUserDetailsFromUserId({userId: userId}).subscribe(res => {
      this.form.get('firstName').setValue(res.firstName);
      this.form.get('firstName').disable();
      this.form.get('lastName').setValue(res.lastName);
      this.form.get('lastName').disable();
    }, error => {
    })
  }

  submittedForError() {
    this.errorSubmission = true;
  }

  updateAccountAndSendOTP() {
    // this.sending = true;
    // const phoneNumber = this.form.getRawValue().phoneNumber.e164Number;
    //
    // const encryptedPhoneNumber = btoa(phoneNumber);
    //
    // this.userRegistration.updateAccount({updateUserAccountDto: {userId: this.userId, phone: phoneNumber, }}).subscribe(res => {
    //   this.router.navigate(['/otp/'+encryptedPhoneNumber+'/'+this.userId])
    //   this.sending = false;
    // }, error => {
    //   this.sending = false;
    // })
  }

}
