/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ListingProcessingDto { 
    response: ListingProcessingDto.ResponseEnum;
    entityId: number;
    reasonForDisapprovalAdditionalComment?: string;
    reasonForDisapprovalEntityCode?: string;
}
export namespace ListingProcessingDto {
    export type ResponseEnum = 'ACCEPTED' | 'REJECTED';
    export const ResponseEnum = {
        Accepted: 'ACCEPTED' as ResponseEnum,
        Rejected: 'REJECTED' as ResponseEnum
    };
}


