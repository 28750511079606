import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfirmFieldValidator} from "dd-shared-lib";
import {ChangePasswordDto, UserRegistrationService} from "dd-core-api-sdk";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  errorSubmission: boolean;
  submitting: boolean;
  @Output()
  emitSuccessResponse = new EventEmitter<any>();
  @Output()
  emitErrorResponse = new EventEmitter<any>();
  isLongEnough: boolean =false;
  hasSpecialChar: boolean =false;
  hasCapitalLetter: boolean =false;
  hasNumber: boolean =false;
  hasSmallLetter: boolean =false;


  constructor(private fb: FormBuilder, private userRegistrationService: UserRegistrationService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      oldPassword: ['', Validators.required, Validators.minLength(2), Validators.maxLength(50)],
      newPassword: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      confirmPassword: ['', Validators.compose([
        Validators.required, Validators.minLength(2), Validators.maxLength(50),
        ConfirmFieldValidator.matchField('newPassword', 'passwordMismatch')
      ])]
    })

    this.form.get('newPassword').valueChanges.subscribe(v =>{
      console.log(v)
      const password=v;
      if(password.length<1){
        this.hasSpecialChar= this.hasNumber=this.hasCapitalLetter=this.isLongEnough=false;
      }

      this.hasSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);
      this.hasNumber = /\d/.test(password);
      this.hasCapitalLetter = /[A-Z]/.test(password);
      this.hasSmallLetter = /[a-z]/.test(password);
      this.isLongEnough = password.length >= 8;
      if(password.length<1){
        this.hasSpecialChar= this.hasNumber=this.hasCapitalLetter=this.isLongEnough=this.hasSmallLetter=false;
      }
    })
  }



  submittedForError() {
    this.errorSubmission = true;
  }

  updatePassword() {
    const data = this.form.getRawValue();


    this.submitting = true;
    const changePasswordDto: ChangePasswordDto ={
      oldPassword: data.oldPassword,
      newPassword: data.newPassword
    }

    if (changePasswordDto.newPassword==changePasswordDto.oldPassword){
      this.emitErrorResponse.emit("New password is same as the old one. Please enter a new password!");
      this.submitting = false;
      this.form.reset()

      this.hasSpecialChar= this.hasNumber=this.hasCapitalLetter=this.isLongEnough=this.hasSmallLetter=false;

      return
    }
    this.userRegistrationService.changePassword({changePasswordDto}).subscribe({
      next: () => {
        this.submitting = false;
        this.emitSuccessResponse.emit();
      }, error: (err) => {
        this.submitting = false;
        this.emitErrorResponse.emit(err.error.message ? err.error.message : 'Error in updating password. Kindly try again later.');
      },
      complete: () => {
        this.submitting = false;
      }
    })
  }

  onPasswordStrengthMessChanged($event: string) {
    if ($event == 'Poor') {
      this.form.controls['newPassword'].setErrors({'notStrong': true});
    } else {
      this.removeErrorFromControl(this.form.controls['newPassword']);
    }
  }

  removeErrorFromControl(control: any) {
    if (control.hasError('notStrong')) {
      delete control.errors['notStrong'];
      control.updateValueAndValidity();
    }
  }
}
