/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SocialMedialSignUpDto { 
    email: string;
    firstName: string;
    lastName: string;
    profileImageUrl?: string;
    generatedPassword?: string;
    mobileNumber?: string;
    accountType?: SocialMedialSignUpDto.AccountTypeEnum;
    userType?: SocialMedialSignUpDto.UserTypeEnum;
    inviteId?: string;
}
export namespace SocialMedialSignUpDto {
    export type AccountTypeEnum = 'DENTAL_DOOR_ADMIN' | 'DENTAL_OFFICE' | 'DENTAL_PROFESSIONAL' | 'DENTAL_SPECIALIST' | 'DENTAL_SPECIALIST_OFFICE' | 'SUPER_ADMIN' | 'AFFILIATE';
    export const AccountTypeEnum = {
        DentalDoorAdmin: 'DENTAL_DOOR_ADMIN' as AccountTypeEnum,
        DentalOffice: 'DENTAL_OFFICE' as AccountTypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as AccountTypeEnum,
        DentalSpecialist: 'DENTAL_SPECIALIST' as AccountTypeEnum,
        DentalSpecialistOffice: 'DENTAL_SPECIALIST_OFFICE' as AccountTypeEnum,
        SuperAdmin: 'SUPER_ADMIN' as AccountTypeEnum,
        Affiliate: 'AFFILIATE' as AccountTypeEnum
    };
    export type UserTypeEnum = 'PATIENT' | 'DENTAL_PROFESSIONAL' | 'AFFILIATE' | 'STUDENT' | 'SERVICE_PROVIDER' | 'GENERAL';
    export const UserTypeEnum = {
        Patient: 'PATIENT' as UserTypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as UserTypeEnum,
        Affiliate: 'AFFILIATE' as UserTypeEnum,
        Student: 'STUDENT' as UserTypeEnum,
        ServiceProvider: 'SERVICE_PROVIDER' as UserTypeEnum,
        General: 'GENERAL' as UserTypeEnum
    };
}


