import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthenticationServiceKeycloak} from 'src/app/services/AuthenticationServiceKeycloak';
import {UserKeycloak} from 'src/app/services/UserKeycloak';
import {AdminDashboardStatisticsPojo, DentalDoorAdminControllerService} from "dd-core-api-sdk";

@Component({
  selector: 'app-admin-referrals',
  templateUrl: './admin-referrals.component.html',
  styleUrls: ['./admin-referrals.component.css']
})
export class AdminReferralsComponent implements OnInit {

  showDraft: boolean;
  user: UserKeycloak;
  data: AdminDashboardStatisticsPojo;


  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authentication: AuthenticationServiceKeycloak,
    private dentalDoorAdminControllerService: DentalDoorAdminControllerService
  ) {
  }

  ngOnInit(): void {
    this.authentication.getUser().subscribe(res => {
      this.user = res;
    });

    this.activeRoute.data.subscribe(data => {
      this.showDraft = data.isDraft;
    });

    this.dentalDoorAdminControllerService.getDentalDoorReferralStatsCard().subscribe(res => {
      this.data = res
    });
  }

}
