<div class="vh-100 sidebar-card d-flex flex-column flex-shrink-0 p-3 px-2" id="siderbar-wrapper" style="width: 7rem">
    <div class="">
        <div class="nav_logo d-flex justify-content-between align-items-center">
            <div class="logo-name-main nav_logo-name">
                <a href="/" class="d-flex align-items-center mb-3 mb-md-0">
                    <img src="/src/assets/img/dashboard-logo.svg" class="img-fluid" alt="" />
                </a>
            </div>
            <div class="nav_logo-name logo-name-icon">
                <a href="/" class="d-flex align-items-center justify-content-center mb-3 mb-md-0">
                    <img src="/assets/img/logoCollapsed.svg" class="img-fluid" alt="" />
                </a>
            </div>
        </div>
    </div>

    <hr class="hr" />

    <div class="mb-auto" style="overflow-y: auto">
        <ul class="nav nav-pills flex-column mb-auto" style="width: fit-content; overflow-x: hidden; overflow-y: auto">
            <li class="nav-item sidebar-item">
                <a [routerLinkActive]="'active'" class="nav-link d-block align-items-center" aria-current="page" [routerLink]="'dashboard'">
                    <iconify-icon inline icon="codicon:dashboard" class="fs-3 ms-3"></iconify-icon>
                    <small class="ms-2">Overview</small>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLinkActive]="'active'" [routerLink]="'/affiliates/linking'" class="nav-link d-block align-items-center">
                    <iconify-icon icon="carbon:partnership" class="fs-3 ms-3"></iconify-icon>
                    <small class="ms-2">Affiliates</small>
                </a>
            </li>
            <li class="sidebar-item">
                <a [routerLinkActive]="'active'" [routerLink]="'/affiliates/withdraw'" class="nav-link d-block align-items-center">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="d-flex align-items-center ms-3">
                            <iconify-icon icon="uil:money-withdrawal" class="fs-3"></iconify-icon>
                        </div>
                    </div>
                    <small class="ms-1">Withdraw</small>
                </a>
            </li>
<!--            <li class="sidebar-item">-->
<!--                <a [routerLinkActive]="'active'" [routerLink]="'/affiliates/settings'" class="nav-link d-block align-items-center">-->
<!--                    <div class="d-flex justify-content-between align-items-center mb-2">-->
<!--                        <div class="d-flex align-items-center ms-3">-->
<!--                            <iconify-icon icon="clarity:settings-line" class="fs-3 mb-1"></iconify-icon>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <small class="ms-1">Settings</small>-->
<!--                </a>-->
<!--            </li>-->
        </ul>
    </div>

    <div>

        <ul class="nav nav-pills flex-column mb-auto">
            <li class="sidebar-item d-flex align-items-center justify-content-center">
                <a href="#offcanvasNavbar" class="navbar-toggler d-block align-items-center" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" style="padding: 1rem; text-decoration: blink;">
                    <iconify-icon icon="codicon:info" class="fs-3 mb-1"></iconify-icon>
                    <small class="d-block">Help?</small>
                </a>
            </li>
        </ul>

        <!-- Help sidebar -->
        <app-dashboard-help></app-dashboard-help>
        <hr class="hr" />

        <div class="dropdown py-3 user-avatar btn-group dropend">
            <div class="d-flex justify-content-between align-items-center pointer text-truncate" data-bs-toggle="dropdown" aria-expanded="false">
                <div class="d-flex align-items-center">
                    <img [src]=getFileURL() alt="" width="40" height="40" class="rounded-circle rounded ms-4" />
                </div>

                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start dropcard small p-3" style="min-width: 300px">
                    <h6 class="p-2 mb-2 fw-bold" *ngIf="user?.accounts > 0">My Accounts</h6>
                    <li>
                        <a class="dropdown-item py-2" *ngFor="let account of user?.accounts" (click)="selectAccount(account)">
                            <div class="d-flex align-items-center">
                                <img [src]=getFileURL() alt="" width="40" height="40" class="rounded-circle rounded me-2" />
                                <div class="user-name-avatar">
                                    <p class="fw-bold mb-0 small">
                                        {{ account.accountName }}
                                    </p>
                                    <p class="mb-0 fw-light small text-muted">
                                        {{ account.accountType | removeUnderscores | uppercase }}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </li>

                    <li>
                        <hr class="hr" />
                    </li>
                    <li>
                        <a class="dropdown-item py-2" (click)="logOut()">
                            <iconify-icon icon="simple-line-icons:logout" class="me-1 mb-0 pb-0 text-danger"></iconify-icon>
                            <span class="text-danger pt-0 mt-0"> Logout </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
