import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AbuseCategoryControllerService } from './api/abuse-category-controller.service';
import { AccountSettingControllerService } from './api/account-setting-controller.service';
import { ActivityLogControllerService } from './api/activity-log-controller.service';
import { AffiliateBankControllerService } from './api/affiliate-bank-controller.service';
import { AffiliateMemberShipControllerService } from './api/affiliate-member-ship-controller.service';
import { AffiliateUserControllerService } from './api/affiliate-user-controller.service';
import { AffiliateUserImageControllerService } from './api/affiliate-user-image-controller.service';
import { AppointmentBookingControllerService } from './api/appointment-booking-controller.service';
import { AppointmentCallLogControllerService } from './api/appointment-call-log-controller.service';
import { AppointmentControllerService } from './api/appointment-controller.service';
import { AppointmentNotificationSettingControllerService } from './api/appointment-notification-setting-controller.service';
import { AppointmentSearchControllerService } from './api/appointment-search-controller.service';
import { AwardInstitutionControllerService } from './api/award-institution-controller.service';
import { BanksControllerService } from './api/banks-controller.service';
import { BwFileControllerService } from './api/bw-file-controller.service';
import { CommentControllerService } from './api/comment-controller.service';
import { CommissionLogsControllerService } from './api/commission-logs-controller.service';
import { CurrencyControllerService } from './api/currency-controller.service';
import { CustomSubscriptionRequestControllerService } from './api/custom-subscription-request-controller.service';
import { DashboardControllerService } from './api/dashboard-controller.service';
import { DentalDoorAdminControllerService } from './api/dental-door-admin-controller.service';
import { DentalOfficeAttributeControllerService } from './api/dental-office-attribute-controller.service';
import { DentalOfficeContentControllerService } from './api/dental-office-content-controller.service';
import { DentalOfficeControllerService } from './api/dental-office-controller.service';
import { DentalOfficeCreationControllerService } from './api/dental-office-creation-controller.service';
import { DentalOfficeImageControllerService } from './api/dental-office-image-controller.service';
import { DentalOfficeProfessionalControllerService } from './api/dental-office-professional-controller.service';
import { DentalOfficeReferralAssignmentControllerService } from './api/dental-office-referral-assignment-controller.service';
import { DentalOfficeServiceControllerService } from './api/dental-office-service-controller.service';
import { DentalProfessionalAwardControllerService } from './api/dental-professional-award-controller.service';
import { DentalProfessionalControllerService } from './api/dental-professional-controller.service';
import { DentalProfessionalEducationControllerService } from './api/dental-professional-education-controller.service';
import { DentalProfessionalEndorsementControllerService } from './api/dental-professional-endorsement-controller.service';
import { DentalProfessionalExtranetSearchControllerService } from './api/dental-professional-extranet-search-controller.service';
import { DentalProfessionalLicenceControllerService } from './api/dental-professional-licence-controller.service';
import { DentalProfessionalWorkAddressControllerService } from './api/dental-professional-work-address-controller.service';
import { DentalProfessionalWorkHistoryControllerService } from './api/dental-professional-work-history-controller.service';
import { DentalReferralControllerService } from './api/dental-referral-controller.service';
import { DentalServiceControllerService } from './api/dental-service-controller.service';
import { DeviceControllerService } from './api/device-controller.service';
import { EducationControllerService } from './api/education-controller.service';
import { EmailValidationControllerService } from './api/email-validation-controller.service';
import { EndorsementRequestControllerService } from './api/endorsement-request-controller.service';
import { EnumerationControllerService } from './api/enumeration-controller.service';
import { FavoritesService } from './api/favorites.service';
import { FeedbackService } from './api/feedback.service';
import { FirstContactTimeRangeControllerService } from './api/first-contact-time-range-controller.service';
import { FirstContactTokenControllerService } from './api/first-contact-token-controller.service';
import { FlashCardCategoryControllerService } from './api/flash-card-category-controller.service';
import { FlashCardControllerService } from './api/flash-card-controller.service';
import { FlashCardDeckSearchControllerService } from './api/flash-card-deck-search-controller.service';
import { FlashCardInvoiceControllerService } from './api/flash-card-invoice-controller.service';
import { FlashCardSetControllerService } from './api/flash-card-set-controller.service';
import { HelpControllerService } from './api/help-controller.service';
import { InsuranceCompanyControllerService } from './api/insurance-company-controller.service';
import { InvitationControllerService } from './api/invitation-controller.service';
import { InviteSearchControllerService } from './api/invite-search-controller.service';
import { IpAddressControllerService } from './api/ip-address-controller.service';
import { LanguageControllerService } from './api/language-controller.service';
import { LocationService } from './api/location.service';
import { NotificationControllerService } from './api/notification-controller.service';
import { PackageItemControllerService } from './api/package-item-controller.service';
import { PasswordUtilsService } from './api/password-utils.service';
import { PatientControllerService } from './api/patient-controller.service';
import { PaymentControllerService } from './api/payment-controller.service';
import { PortalUserImageControllerService } from './api/portal-user-image-controller.service';
import { PortalUserInsuranceProviderControllerService } from './api/portal-user-insurance-provider-controller.service';
import { PreloadedImageControllerService } from './api/preloaded-image-controller.service';
import { PreloadedImageSearchControllerService } from './api/preloaded-image-search-controller.service';
import { PromotionRedemptionControllerService } from './api/promotion-redemption-controller.service';
import { PromotionSearchControllerService } from './api/promotion-search-controller.service';
import { ReasonForDisapprovalControllerService } from './api/reason-for-disapproval-controller.service';
import { ReasonForVisitCategoryControllerService } from './api/reason-for-visit-category-controller.service';
import { ReasonForVisitControllerService } from './api/reason-for-visit-controller.service';
import { RecommendationControllerService } from './api/recommendation-controller.service';
import { RedemptionOverrideRequestControllerService } from './api/redemption-override-request-controller.service';
import { ReportAbuseControllerService } from './api/report-abuse-controller.service';
import { ResourceSearchControllerService } from './api/resource-search-controller.service';
import { ResourceUploadControllerService } from './api/resource-upload-controller.service';
import { ResourceVerificationControllerService } from './api/resource-verification-controller.service';
import { ReviewControllerService } from './api/review-controller.service';
import { ReviewLinkControllerService } from './api/review-link-controller.service';
import { ScheduleControllerService } from './api/schedule-controller.service';
import { SocialSignInControllerService } from './api/social-sign-in-controller.service';
import { SpecialistPostReferralControllerService } from './api/specialist-post-referral-controller.service';
import { SpecializationControllerService } from './api/specialization-controller.service';
import { StatisticsControllerService } from './api/statistics-controller.service';
import { StudentControllerService } from './api/student-controller.service';
import { SubscriptionControllerService } from './api/subscription-controller.service';
import { SubscriptionManagementControllerService } from './api/subscription-management-controller.service';
import { TagSearchControllerService } from './api/tag-search-controller.service';
import { TemporaryDentalOfficeControllerService } from './api/temporary-dental-office-controller.service';
import { TimeSlotDefinitionControllerService } from './api/time-slot-definition-controller.service';
import { UserControllerService } from './api/user-controller.service';
import { UserManagementControllerService } from './api/user-management-controller.service';
import { UserRegistrationService } from './api/user-registration.service';
import { UserSettingsControllerService } from './api/user-settings-controller.service';
import { WalletTransactionControllerService } from './api/wallet-transaction-controller.service';
import { WebNotificationControllerService } from './api/web-notification-controller.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
