

import { AbstractControl, ValidationErrors } from '@angular/forms';

export class FacebookProfileURLValidator {

  static mustBeValid(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;

    if (!control.value) {
      return null;
    }
    if (!(/^www\.facebook.com\/[a-zA-Z0-9-#!.]+/.test(value))) { return { invalidFacebookURL: true }; }

    return null;
  }
}


