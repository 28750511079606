import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserKeycloak} from "../../../services/UserKeycloak";
import {
  DashboardControllerService, NotificationControllerService,
  NotificationSearchPojo,
  QueryResultsPojoNotificationSearchPojo
} from "dd-core-api-sdk";
import {BehaviorSubject} from "rxjs";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {Router} from "@angular/router";

@Component({
  selector: 'app-admin-dashboard-header',
  templateUrl: './admin-dashboard-header.component.html',
  styleUrls: ['./admin-dashboard-header.component.css']
})
export class AdminDashboardHeaderComponent implements OnInit {

  userAccount: UserKeycloak ;
  @Input() user: UserKeycloak;
  notificationData: Array<NotificationSearchPojo>;
  resultSubject = new BehaviorSubject<QueryResultsPojoNotificationSearchPojo>({});
  @Output()
  emitNotificationTotal = new EventEmitter();
  accountName: string | undefined;

  constructor(private authentication: AuthenticationServiceKeycloak,
              private router: Router,
              private dashboardControllerService:DashboardControllerService) {}
  ngOnInit(): void {

    this.authentication.getUser().subscribe(res=>{
      this.userAccount = res;
      this.accountName = AuthenticationServiceKeycloak._currentUserAccount?.accountName;
    });
  }

  logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.authentication.logoutClick(window.location.origin);
  }
}
