/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PromotionDuration { 
    startDate?: string;
    endDate?: string;
    noOfTimesRestarted?: number;
    restartEvery?: number;
    restartEveryTimeUnit?: PromotionDuration.RestartEveryTimeUnitEnum;
    useByDate?: string;
    useBy?: number;
    useByTimeUnit?: PromotionDuration.UseByTimeUnitEnum;
    numberOfRestarts?: number;
    id?: number;
}
export namespace PromotionDuration {
    export type RestartEveryTimeUnitEnum = 'MINUTE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH';
    export const RestartEveryTimeUnitEnum = {
        Minute: 'MINUTE' as RestartEveryTimeUnitEnum,
        Hour: 'HOUR' as RestartEveryTimeUnitEnum,
        Day: 'DAY' as RestartEveryTimeUnitEnum,
        Week: 'WEEK' as RestartEveryTimeUnitEnum,
        Month: 'MONTH' as RestartEveryTimeUnitEnum
    };
    export type UseByTimeUnitEnum = 'MINUTE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH';
    export const UseByTimeUnitEnum = {
        Minute: 'MINUTE' as UseByTimeUnitEnum,
        Hour: 'HOUR' as UseByTimeUnitEnum,
        Day: 'DAY' as UseByTimeUnitEnum,
        Week: 'WEEK' as UseByTimeUnitEnum,
        Month: 'MONTH' as UseByTimeUnitEnum
    };
}


