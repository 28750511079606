/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LanguageListDto } from './language-list-dto';
import { AddressDto } from './address-dto';
import { InsuranceUpdateDto } from './insurance-update-dto';
import { SocialLinkDto } from './social-link-dto';


export interface PersonalInformationUpdateDto { 
    titleName?: string;
    firstName?: string;
    lastName?: string;
    gender?: PersonalInformationUpdateDto.GenderEnum;
    dateOfBirth?: string;
    address?: AddressDto;
    languages?: LanguageListDto;
    profileImageId?: number;
    insurance?: InsuranceUpdateDto;
    socialLinks?: Array<SocialLinkDto>;
    preferredAgeGroupCodes?: Set<string>;
    specializationCodes?: Set<string>;
}
export namespace PersonalInformationUpdateDto {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
}


