/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface Review { 
    name?: string;
    email?: string;
    phoneNumber?: string;
    comment?: string;
    rating?: number;
    dateCreated?: string;
    lastUpdated?: string;
    status?: Review.StatusEnum;
    entityName?: string;
    entityId?: number;
    recordSource?: Review.RecordSourceEnum;
    reviewDate?: string;
    sourceLink?: string;
    reviewId?: string;
    edited?: boolean;
    primaryRating?: number;
    title?: string;
    remainAnonymous?: boolean;
    expiryNotificationCount?: number;
    id?: number;
    reviewer?: PortalUser;
}
export namespace Review {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type RecordSourceEnum = 'DENTAL_DOOR' | 'GOOGLE' | 'FACEBOOK' | 'YELP' | 'BING' | 'YAHOO';
    export const RecordSourceEnum = {
        DentalDoor: 'DENTAL_DOOR' as RecordSourceEnum,
        Google: 'GOOGLE' as RecordSourceEnum,
        Facebook: 'FACEBOOK' as RecordSourceEnum,
        Yelp: 'YELP' as RecordSourceEnum,
        Bing: 'BING' as RecordSourceEnum,
        Yahoo: 'YAHOO' as RecordSourceEnum
    };
}


