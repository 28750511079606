<p class="h1 fw-bold mt-4">
    Sign up</p>
<p>Fill the form to create your affiliate account</p>

<hr class="hr my-4">

<form class=" mb-3" [formGroup]="formGroup">
    <div id="name" class="row g-3 mb-4 px-0">
        <div id="first-name" class="col-sm">
            <label for="first-name-input" class="form-label required">First Name</label>
            <input id="first-name-input" type="text" class="form-control form-control-lg" formControlName="firstName" placeholder="John" />
            <app-field-error *ngIf="(formGroup.get('firstName')?.errors?.required && formGroup.get('firstName')?.touched) || (formGroup.get('firstName')?.errors?.required && errorSubmission)
                      " [message]="'First name cannot be blank.'"></app-field-error>
            <app-field-error *ngIf="(formGroup.get('firstName')?.errors?.minlength && formGroup.get('firstName')?.touched) ||
                        (formGroup.get('firstName')?.errors?.minlength && errorSubmission)
                      " [message]="'First name cannot be less than two(2) characters.'"></app-field-error>
            <app-field-error *ngIf="(formGroup.get('firstName')?.errors?.maxlength && formGroup.get('firstName')?.touched) ||
                      (formGroup.get('firstName')?.errors?.maxlength && errorSubmission)" [message]="'First name cannot be more than fifty(50) characters.'"></app-field-error>
        </div>
        <div id="last-name" class="col-sm">
            <label for="last-name-input" class="form-label required">
        Last Name
      </label>
            <input id="last-name-input" type="text" class="form-control form-control-lg" formControlName="lastName" placeholder="Doe" />
            <app-field-error *ngIf="(formGroup.get('lastName')?.errors?.required && formGroup.get('lastName')?.touched) ||
                        (formGroup.get('lastName')?.errors?.required && errorSubmission)" [message]="'Last name cannot be blank.'"></app-field-error>
            <app-field-error *ngIf="(formGroup.get('lastName')?.errors?.minlength && formGroup.get('lastName')?.touched) ||
                        (formGroup.get('lastName')?.errors?.minlength && errorSubmission) " [message]="'Last name cannot be less than two(2).'"></app-field-error>
            <app-field-error *ngIf="(formGroup.get('lastName')?.errors?.maxlength && formGroup.get('lastName')?.touched) ||
                      (formGroup.get('lastName')?.errors?.maxlength && errorSubmission)" [message]="'Last name cannot be more than fifty(50) characters.'"></app-field-error>
        </div>
    </div>

    <div class="row">
        <div id="office-email" class="mb-4">
            <label for="office-email-input" class="form-label required">Email Address</label>
            <input id="office-email-input" class="form-control form-control-lg" type="text" formControlName="email" placeholder="e.g youremail@mail.com" />
            <app-field-error *ngIf="(formGroup.get('email')?.errors?.required && formGroup.get('email')?.touched) ||
                      (formGroup.get('email')?.errors?.required && errorSubmission)" [message]="'Email address cannot be blank.'"></app-field-error>
            <app-field-error *ngIf="(this.formGroup.get('email').errors?.pattern && formGroup.get('email')?.touched)" [message]="'Invalid email address pattern.'"></app-field-error>

            <app-field-error class="mt-1" [message]="'Email already exist.'" *ngIf="formGroup.get('email')!.errors?.emailExists"></app-field-error>
            <app-field-error *ngIf="(formGroup.get('email')?.errors?.maxlength && formGroup.get('email')?.touched) ||
                      (formGroup.get('email')?.errors?.maxlength && errorSubmission)" [message]="'Email address cannot be more than fifty(50).'"></app-field-error>
        </div>

        <div id="number" class="form-outline mb-4">
            <label class="form-label required">Phone Number</label> <span class="cover"></span>
            <ngx-intl-tel-input [cssClass]="'form-control form-control-lg fresh'"
                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [id]="'phoneNumber'"
                                [maxLength]="'20'" [phoneValidation]="true" [preferredCountries]="preferredCountries"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [searchCountryFlag]="false"
                                [onlyCountries]="['us']"
                                [selectedCountryISO]="countryISO"
                                [selectFirstCountry]="true"
                                formControlName="phoneNumber"></ngx-intl-tel-input>
            <app-field-error *ngIf="(formGroup.get('phoneNumber')?.errors?.required && formGroup.get('phoneNumber')?.touched) ||
                      (formGroup.get('phoneNumber')?.errors?.required && errorSubmission)" [message]="'Phone number cannot be blank.'"></app-field-error>
            <app-field-error class="mt-1" [message]="'Phone number already exist.'" *ngIf="formGroup.get('phoneNumber')!.errors?.phoneNumberExists"></app-field-error>
          <app-field-error class="mt-1" [message]="'Invalid phone number format.'" *ngIf="formGroup.get('phoneNumber')!.errors?.validatePhoneNumber || formGroup.get('phoneNumber')!.errors?.invalidPhoneNumber"></app-field-error>
        </div>
    </div>

    <div class="mb-3 mb-lg-4 text-center">
        <button type="button" class="btn btn-primary btn-lg w-100 mb-1 rounded-0" (click)="!this.formGroup.invalid ? moveToNext() : submissionForError()">Next</button>
        <br>
        <br>
        <p>Already have an account? <a routerLink="/login" class="link">Sign In</a>.</p>
    </div>

</form>
