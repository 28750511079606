<div class="container px-4">
    <main>
        <section id="error-message">
            <div *ngIf="showErrorMessageTrigger">
                <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert">
                    <p class="m-0 p-0 d-flex align-items-start">
                        <span>
          <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
          {{ getErrorMessage() | removeUnderscores }}
        </span>
                        <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                        </iconify-icon>
                    </p>
                </div>
            </div>
        </section>

        <!-- change plan -->
        <div class="h6 mt-4 mb-2 text-center text-muted">Choose A Different Plan.</div>
        <form [formGroup]="switchPlanForm" class="d-flex align-items-center justify-content-center wrapper mx-5">
            <div class=" form-check form-check-inline ">
                <input class="form-check-input" type="radio" id="inlineRadio1" formControlName="cycle" value="0">
                <label class="form-check-label mt-1 px-2" for="inlineRadio1">
                  Monthly
                  <p class="text-light">-</p>
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="inlineRadio2" formControlName="cycle" value="1">
                <label class="form-check-label mt-1 px-2" for="inlineRadio2">
                  Quarterly
                  <p class="text-secondary">(10% off)</p>
                </label>
            </div>
            <div class=" form-check form-check-inline">
                <input class="form-check-input" type="radio" id="inlineRadio3" formControlName="cycle" value="2">
                <label class="form-check-label mt-1 px-2" for="inlineRadio3">
                  Yearly
                  <p class="text-secondary">(20% off)</p>
                </label>
            </div>
        </form>
        <hr class="hr my-3">
        <!-- change plan -->

        <div class="row">
            <div class="card p-3 border-light rounded-0 shadow col-md-5 col-lg-4 order-md-last mb-5" style="padding-left: 0; padding-right: 0;">
                <div class="card-header mb-3 rounded-0" style="color: #fff; background-color: #232D42;">
                    <h4>DentalDoor <code>{{this.valuesFromSub?.subscriptionPackagePojo?.name}}</code></h4>
                    <p class="mt-3" *ngIf="valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.billingCycle == 'MONTHLY'">Estimate Summary Billed Per Month</p>
                    <p class="mt-3" *ngIf="valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.billingCycle == 'QUARTERLY'">Estimate Summary Billed Per Quarter</p>
                    <p class="mt-3" *ngIf="valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.billingCycle == 'ANNUAL'">Estimate Summary Billed Per Year</p>
                </div>
                <div class="card-body">
                    <h6 class="card-title">Package Features</h6>
                    <ul *ngFor="let packageItem of valuesFromSub?.subscriptionPackagePojo?.packageItems; let i= index">
                        <li>{{packageItem?.packageItem?.name}}</li>
                    </ul>
                </div>
                <div class="card-footer bg-light d-flex justify-content-between">
                    <div class="">
                        <h6 class="my-3">Sales Tax</h6>
                    </div>
                    <span class="my-3">$0</span>
                </div>
                <li class="list-group-item d-flex justify-content-between my-3 ">
                    <div class="mt-2 px-3">
                        <div class="d-flex flex-wrap">
                            <div>
                                <h6 class="my-0">Your plan ends on {{calculateNextBillingCycle()}}</h6>
                            </div>
                        </div>
                        <small class="my-3 mb-3"> You are locking this estimate for {{calculateEstimate()}}</small>
                    </div>
                    <div class="">
                        <h6 class="my-0">{{valuesFromSub?.subscriptionPackagePojo?.subscriptionCycles[this.selectedCycle]?.totalPaid | currency:'USD':'symbol'}}</h6>
                    </div>
                </li>
            </div>


            <!-- Card section -->
            <div class="col-md-7 col-lg-8 ">

                <form class="overview-card-table rounded-0 p-3 border-0 needs-validation" [formGroup]="form" *ngIf="valuesFromSub; else loader">
                    <div class="d-flex flex-wrap justify-content-between mb-3">
                        <div class="btn-primary btn pointer d-flex align-items-center justify-content-center" awPreviousStep>
                            <iconify-icon icon="material-symbols:arrow-back-rounded" style="color: #fff;" class="mx-1"></iconify-icon><span> Go Back</span>
                        </div>
                        <div class="d-flex justify-content-end">
                            <p class="text-dentaldoor">
                                <iconify-icon icon="entypo:lock" class="text-dentaldoor"></iconify-icon>
                                Secure Form
                            </p>
                        </div>
                    </div>
                    <p class="fw-bold text-secondary">You are about to subscribe for <code class="fs-6">{{this.valuesFromSub?.subscriptionPackagePojo?.name}}</code> plan</p>
                    <hr class="my-1 mb-3">
                    <h6 class="mb-3 text-secondary">Billing Information</h6>
                    <div class="row gy-3">
                        <div class="col-md-6">
                            <div>
                                <input type="text" class="form-control form-control-lg" id="card-number" formControlName="cardNumber" placeholder="1234 1234 1234 1234" [mask]="'0000 0000 0000 0000'" aria-label="card-number" aria-describedby="card-number">
                            </div>
                            <small *ngIf="(form.controls.cardNumber.touched && form.controls.cardNumber.hasError('invalidCard'))" class="text-danger">{{'Invalid Card'}}</small>
                            <small *ngIf="(form.controls.cardNumber.touched && form.controls.cardNumber.errors?.required)" class="text-danger">{{'Card number cannot be blank.'}}</small>
                            <div class="d-flex p-2">
                                <img src="assets/img/master-card.png" class="" alt="" style="max-width: 50px; max-height:30px;">
                                <img src="assets/img/Visa.svg" alt="" style="max-width: 50px; max-height:30px;">
                                <img src="assets/img/american-express.png" alt="" style="max-width: 50px; max-height:30px;">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <input type="text" class="form-control form-control-lg" [mask]="'00/00'" placeholder="MM/YY" formControlName="expiryDate" id="cc-expiration">
                            <small *ngIf="(form.controls.expiryDate.touched && form.controls.expiryDate.errors?.required)" class="text-danger">{{'Expiry Date cannot be blank'}}</small>
                            <small *ngIf="form.controls.expiryDate.touched && form.controls.expiryDate.hasError('invalidExpiryDate')" class="text-danger">{{'Invalid Expiry Date'}}</small><br/>
                        </div>

                        <div class="col-md-6">
                            <input type="text" formControlName="cvc" [mask]="'0000'" [maxlength]="'4'" class="form-control form-control-lg" id="cc-cvv" placeholder="CVV">

                            <small *ngIf="form.controls.cvc.touched && form.controls.cvc.hasError('invalidCvc')" class="text-danger">{{'Invalid cvv'}}</small>
                            <small *ngIf="(form.controls.cvc.touched && form.controls.cvc.errors?.required)" class="text-danger">{{'CVV cannot be blank'}}</small>
                        </div>
                        <div class="col-md-6">
                            <input type="text" [maxlength]="'20'" formControlName="postalCode" class="form-control form-control-lg" id="cc-zipCode" placeholder="Zip Code (optional)">
                        </div>
                    </div>

                    <button class="w-100 btn btn-dentaldoor btn-lg mt-4" [disabled]="working" (click)="form.invalid ? form.markAllAsTouched() : createSubscriptionInvoice();false;" type="submit">
            <iconify-icon
              *ngIf="working"
              icon="eos-icons:bubble-loading" style="color: #ffffff "></iconify-icon>
            Pay Now!
          </button>
                </form>
            </div>
        </div>
    </main>

</div>
<ng-template #loader>
    <loader class="m-5"></loader>
</ng-template>