/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MyConnectionSearch { 
    offset?: number;
    limit?: number;
    order?: MyConnectionSearch.OrderEnum;
    orderColumn?: string;
    dentalOfficeName?: string;
    specializationName?: string;
    dentalOfficeId?: number;
    specializationId?: number;
}
export namespace MyConnectionSearch {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
}


