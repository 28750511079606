import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {InboundSearchListComponent} from "./inbound-search-list/inbound-search-list.component";
import {InboundRoutingModule} from "./inbound-routing.module";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  declarations: [
    InboundSearchListComponent
  ],
  imports: [
    ReactiveFormsModule,
    InboundRoutingModule,
    CommonModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InboundModule {}
