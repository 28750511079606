
<div  *ngIf="!singleReferral"  class="shadow-sm text-center p-md-5 p-3">

  <div class="container main">
    <div class="d-flex justify-content-center">
    </div>
    <div class=" text-center">
      <div class="logo p-4">
        <img class="img-fluid" width="150" src="/assets/img/dd-logo.svg" alt="">
      </div>
      <h2 class="mb-4">Access Control</h2>
      <div class="logo ">
        <img class="img-fluid" width="120" src="/assets/img/hipaa.png" alt="HIPAA Logo" >
      </div>
      <div>
        <div class="alert alert-danger text-center small" *ngIf="showErrorMessageTrigger">{{ getErrorMessage() }}
        </div>
        <div class="alert alert-success text-center small" *ngIf="showSuccessMessageTrigger">{{ getSuccessMessage() }}
        </div>
      </div>
      <div class="card-body pt-2 justify-content-center">
        <h6 class="mt-4 mb-1">Enter security code sent in the referral email to see patient's details
        </h6>
        <ng-otp-input #otpInput class="p-3" (onInputChange)="onOtpChange($event)"
                      [config]="{length: 6, allowNumbersOnly: true, isPasswordInput: false, inputClass: 'm-2'}">
          <ng-otp-input-wrapper ngClass="otp-input-wrapper"></ng-otp-input-wrapper>
        </ng-otp-input>

        <div class="mt-5 mb-3 mb-lg-4 text-center">
          <button type="button" class="btn btn-primary btn-lg w-100 mb-1 rounded-0" (click)="validateOTP()"
                  [disabled]="verifyingOtp">Verify and proceed
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="logo text-end p-4">
    Powered By:
    <img class="img-fluid" width="100" src="/assets/img/dd-logo.svg" alt=""></div>

  <div *ngIf="this.verifyingOtp">
    <app-full-page-loader></app-full-page-loader>
  </div>
</div>

<ng-container *ngIf="singleReferral">
  <br>
  <br>
  <app-view-referral [extranetReferralId]="referralDataId" [isExtranentInbound]="true"></app-view-referral>
  <br>
  <br>
  <br>
</ng-container>
