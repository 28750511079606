<div class="mt-3">
  <div class="">
    <div class="wrapper">

      <div id="carouselExampleControls" class="carousel slide">
        <div class="carousel-inner">
          <div class="d-flex justify-content-center">
              <div class="alert alert-danger text-center small" *ngIf="showErrorMessageTrigger">{{getErrorMessage()}}</div>
              <div class="alert alert-success text-center small" *ngIf="showSuccessMessageTrigger">{{getSuccessMessage()}}</div>
          </div>
          <!-- Slide card one  -->
          <div
            [formGroup]="basicInfoForm"
            id="office-basic-info"
            class="carousel-item active p-3">
            <div class="mt-md-3">
              <h5 class="text-uppercase text-primary">Office Details</h5>
              <hr class="hr">
              <div class="form-label">Office Type</div>
              <div class="row g-3 my-2 office-choice">
                <div class="card bg-transparent border-0 col-lg-4 col-sm-4 p-2 card1">
                  <div class="form-label">Specialty Office</div>
                  <div class="form-check" *ngFor="let pro of profession; let i = index">
                    <input type="checkbox" [name]="pro.name" [id]="pro.name" [disabled]="isGeneral"
                           class="form-check-input"
                           formArrayName="officeType" [value]="pro.value" (change)="onCheckboxChange($event)">
                    <label class="form-check-label" [for]="pro.name">{{ pro.name }}</label>
                  </div>
                </div>
                <div
                  class="card bg-transparent border-0 col-lg-4 col-sm-6 order-sm-3 order-md-2 order-lg-3 p-2 card2">
                  <div class="card-title pt-lg-4 m-md-0"></div>
                  <div class="form-check" *ngFor="let pro of profession2; let i = index">
                    <input type="checkbox" [id]="pro.name" [name]="pro.name" [disabled]="isGeneral"
                           class="form-check-input" formArrayName="officeType" [value]="pro.value"
                           (change)="onCheckboxChange($event)">
                    <label class="form-check-label" [for]="pro.name">{{ pro.name }}</label>
                  </div>
                </div>

                <div
                  class="card bg-transparent border-0 col-lg-4 col-sm-4 order-sm-3 order-lg-3 p-2 card3 float-xl-end">
                  <div class="form-label">Non-Specialty Office</div>
                  <div class="form-check">
                    <input type="checkbox" [id]="'general'" [name]="'General Dentistry'"
                           class="form-check-input" formArrayName="officeType" [value]="'General Dentistry'"
                           (change)="onCheckboxChange($event)">
                    <label class="form-check-label" [for]="'general'">{{ 'General Dentistry' }}</label>
                  </div>
                  <div class="form-check" *ngFor="let pro of general; let i = index">
                    <input type="checkbox" [disabled]="isSpecialist" [id]="pro.value" [name]="pro.name"
                           class="form-check-input" formArrayName="officeType" [value]="pro.value"
                           (change)="onCheckboxChange($event)"/>
                    <label class="form-check-label" [for]="pro.value">{{ pro.name }}</label>
                  </div>
                </div>
              </div>
              <app-field-error
                *ngIf="
                      (!basicInfoForm.get('officeType')?.errors?.required &&
                        basicInfoForm.get('officeType')?.invalid) && errorSubmission"
                [message]="'Can select only a school'"></app-field-error>
              <app-field-error
                *ngIf="
                      (basicInfoForm.get('officeType')?.errors?.required &&
                        basicInfoForm.get('officeType')?.touched) ||
                      (basicInfoForm.get('officeType')?.errors?.required &&
                        errorSubmission)
                    "
                [message]="'Office Type cannot be blank.'"></app-field-error>
              <div id="office-name" class="mb-4">
                <label for="office-name-input" class="form-label">Enter Office Name</label>
                <input
                  ngx-google-places-autocomplete
                  [options]="placesConfig"
                  id="office-name-input"
                  class="form-control form-control-lg"
                  type="text"
                  #myInput
                  formControlName="officeName"
                  placeholder="eg. ByteHealth Dental Shines"
                  (onAddressChange)="getPlacesAutocomplete($event)"
                />
                <app-field-error
                  *ngIf="
                      (basicInfoForm.get('officeName')?.errors?.required &&
                        basicInfoForm.get('officeName')?.touched) ||
                      (basicInfoForm.get('officeName')?.errors?.required &&
                        errorSubmission)"
                  [message]="'Office name cannot be blank.'"
                ></app-field-error>
                <app-field-error
                  *ngIf="
                      (basicInfoForm.get('officeName')?.errors?.minlength &&
                        basicInfoForm.get('officeName')?.touched) ||
                      (basicInfoForm.get('officeName')?.errors?.minlength &&
                        errorSubmission)
                    "
                  [message]="'Office name cannot be less than two(2) characters.'"
                ></app-field-error>
                <app-field-error
                  *ngIf="
                      (basicInfoForm.get('officeName')?.errors?.maxlength &&
                        basicInfoForm.get('officeName')?.touched) ||
                      (basicInfoForm.get('officeName')?.errors?.maxlength &&
                        errorSubmission)
                    "
                  [message]="'Office name cannot be more than hundred(100) characters.'"
                ></app-field-error>
              </div>
              <div id="office-email" class="mb-4">
                <label for="office-email-input" class="form-label">Enter Office Email</label>
                <input
                  id="office-email-input"
                  class="form-control form-control-lg"
                  type="text"
                  formControlName="email"
                  placeholder="eg. yourofficemail@mail.com"
                />
                <app-field-error
                  *ngIf="
                    (basicInfoForm.get('email')?.errors?.required &&
                        basicInfoForm.get('email')?.touched) ||
                      (basicInfoForm.get('email')?.errors?.required &&
                        errorSubmission)"
                  [message]="'Office Email cannot be blank.'"></app-field-error>
                <app-field-error
                  *ngIf="(this.basicInfoForm.get('email').errors?.pattern && basicInfoForm.get('email')?.touched)"
                  [message]="'Invalid email address pattern.'"></app-field-error>

                <app-field-error class="mt-1" [message]="'Email already exist.'"
                                 *ngIf="basicInfoForm.get('email')!.errors?.emailExists"></app-field-error>
                <app-field-error
                  *ngIf="
                      (basicInfoForm.get('email')?.errors?.maxlength &&
                        basicInfoForm.get('email')?.touched) ||
                      (basicInfoForm.get('email')?.errors?.maxlength &&
                        errorSubmission)
                    "
                  [message]="'Office email cannot be more than fifty(50).'"
                ></app-field-error>
                <app-field-error *ngIf="(this.basicInfoForm.get('email').errors?.notDeliverable &&
                 basicInfoForm.get('email')?.touched) ||(this.basicInfoForm.get('email').errors?.notDeliverable &&
                 errorSubmission) " [message]="'Please double-check email address'"></app-field-error>

              </div>
              <div id="officeAddress" class="mb-4">
                <label for="office-address1" class="form-label">Office Address</label>
                <input id="office-address1" type="test" class="form-control form-control-lg"
                       formControlName="officeAddress"
                       placeholder="Enter Office Address"/>

                <app-field-error
                  *ngIf="
                      (basicInfoForm.get('officeAddress')?.errors?.minlength &&
                        basicInfoForm.get('officeAddress')?.touched) ||
                      (basicInfoForm.get('officeAddress')?.errors?.minlength &&
                        errorSubmission)
                    "
                  [message]="'Office address cannot be less than two(2).'"
                ></app-field-error>
                <app-field-error
                  *ngIf="
                      (basicInfoForm.get('officeAddress')?.errors?.maxlength &&
                        basicInfoForm.get('officeAddress')?.touched) ||
                      (basicInfoForm.get('officeAddress')?.errors?.maxlength &&
                        errorSubmission)
                    "
                  [message]="'Office address cannot be more than hundred(100) characters.'"
                ></app-field-error>
              </div>
              <div id="number" class="form-outline mb-4">
                <label class="form-label">Office Phone Number</label>
                <ngx-intl-tel-input [cssClass]="'form-control form-control-lg fresh'"
                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [id]="'phoneNumber'"
                                    [maxLength]="'20'" [phoneValidation]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [searchCountryFlag]="false"
                                    [onlyCountries]="['us']"
                                    [selectedCountryISO]="countryISO"
                                    [selectFirstCountry]="true"
                                    formControlName="phoneNumber"></ngx-intl-tel-input>
                <app-field-error
                  *ngIf="
                      (basicInfoForm.get('phoneNumber')?.errors?.required &&
                        basicInfoForm.get('phoneNumber')?.touched) ||
                      (basicInfoForm.get('phoneNumber')?.errors?.required &&
                        errorSubmission)
                    "
                  [message]="'Phone number cannot be blank.'"
                ></app-field-error>
                <app-field-error
                  class="mt-1"
                  [message]="'Phone number already exist.'"
                  *ngIf="
                      basicInfoForm.get('phoneNumber')!.errors
                        ?.phoneNumberExists
                    "
                ></app-field-error>
                <app-field-error class="mt-1" [message]="'Invalid phone number format.'" *ngIf="basicInfoForm.get('phoneNumber')!.errors?.validatePhoneNumber || basicInfoForm.get('phoneNumber')!.errors?.invalidPhoneNumber"></app-field-error>
              </div>
            </div>
            <div class="d-flex justify-content-end my-3">
              <div *ngIf="basicInfoForm.invalid || !addressSelected; else moveNext1" class="carousel-control-next">
                <button type="button" class="btn btn-primary me-2" (click)="submittedForError(basicInfoForm)">
                  Next
                </button>
              </div>
              <ng-template #moveNext1>
                <div class="carousel-control-next">
                  <button type="button" (click)="errorSubmission = false" class="btn btn-primary me-2"
                          data-bs-target="#carouselExampleControls" data-bs-slide-to="1">
                    Next
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
          <div *ngIf="false" [formGroup]="addressForm" id="office-address" class="carousel-item p-2 mt-3">
            <h5 class="text-uppercase  text-primary">Office Address</h5>
            <hr class="hr">
            <div class="d-block w-100">
              <div id="street" class="mb-4">
                <label for="street-input" class="form-label">Office Address</label>
                <input id="street-input" type="test" class="form-control form-control-lg"
                       formControlName="officeAddress"
                       placeholder="Enter Office Address"/>
              </div>
              <div id="city-state" class="row g-3 mb-4 px-0">
                <div id="state" class="col-sm">
                  <label for="state-input" class="form-label"> State </label>
                  <input type="text" id="state-input" class="form-control form-control-lg" formControlName="state"
                         placeholder="State"/>
                  <app-field-error *ngIf="
                        (addressForm.get('state')?.errors?.required &&
                          addressForm.get('state')?.touched) ||
                        (addressForm.get('state')?.errors?.required &&
                          errorSubmission)
                      " [message]="'State cannot be blank.'"></app-field-error>
                </div>
                <div class="col-sm">
                  <label for="city-input" class="form-label"> City </label>
                  <input type="text" id="city-input" class="form-control form-control-lg" formControlName="city"
                         placeholder="City"/>
                  <app-field-error *ngIf="
                        (addressForm.get('city')?.errors?.required &&
                          addressForm.get('city')?.touched) ||
                        (addressForm.get('city')?.errors?.required &&
                          errorSubmission)"
                                   [message]="'City cannot be blank.'"
                  ></app-field-error>
                </div>
              </div>
              <div id="country-zip" class="row mb-4 g-3">
                <div id="country" class="col">
                  <label for="country-input" class="form-label">
                    Country
                  </label>
                  <input type="text" id="country-input" class="form-control form-control-lg" formControlName="country"
                         placeholder="Country"/>
                  <app-field-error *ngIf="
                        (addressForm.get('country')?.errors?.required &&
                          addressForm.get('country')?.touched) ||
                        (addressForm.get('country')?.errors?.required &&
                          errorSubmission)
                      "
                                   [message]="'Office name cannot be blank.'"
                  ></app-field-error>
                </div>
                <div id="zip" class="col">
                  <label for="zip-input" class="form-label"> Zip </label>
                  <input type="text" id="zip-input" class="form-control form-control-lg" formControlName="zipCode"
                         placeholder="Zip Code"/>
                  <app-field-error *ngIf="
                        (addressForm.get('zipCode')?.errors?.required &&
                          addressForm.get('zipCode')?.touched) ||
                        (addressForm.get('zipCode')?.errors?.required &&
                          errorSubmission)"
                                   [message]="'Zip code cannot be blank.'"
                  ></app-field-error>
                </div>
              </div>

              <div class="d-flex justify-content-between my-5">
                <div class="carousel-control-prev">
                  <button type="button" class="btn btn-primary ms-2" data-bs-target="#carouselExampleControls"
                          data-bs-slide-to="0">
                    Back
                  </button>
                </div>
                <div *ngIf="addressForm.invalid; else moveNext2" class="carousel-control-next me-2">
                  <button type="button" class="btn btn-primary" (click)="submittedForError(addressForm)">
                    Next
                  </button>
                </div>
                <ng-template #moveNext2>
                  <div class="carousel-control-next">
                    <button type="button" (click)="errorSubmission = false" class="btn btn-primary me-2"
                            data-bs-target="#carouselExampleControls" data-bs-slide-to="2">
                      Next
                    </button>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div [formGroup]="adminForm" id="admin-info" class="carousel-item mt-3">
            <h5 class="text-uppercase text-primary">Affiliate Invitation Code</h5>
            <div id="affiliate" class="col mt-4">
              <label for="affiliate-input" class="form-label"> Affiliate Code </label>
              <input type="text" id="affiliate-input" class="form-control form-control-lg"
                     formControlName="affiliateCode"
                     placeholder="Affiliate Code"/>
              <app-field-error class="mt-1" [message]="'Invalid affiliate invite code.'"
                               *ngIf="adminForm.get('affiliateCode')!.errors?.affiliateCodeNotFound"></app-field-error>
              <app-field-error
                *ngIf="(adminForm.get('affiliateCode')?.errors?.maxlength &&
                        adminForm.get('affiliateCode')?.touched) ||
                      (adminForm.get('affiliateCode')?.errors?.maxlength &&
                        errorSubmission)
                    "
                [message]="'Affiliate code cannot be more than ten(10).'"
              ></app-field-error>
              <app-field-error
                *ngIf="(adminForm.get('affiliateCode')?.errors?.minlength &&
                        adminForm.get('affiliateCode')?.touched) ||
                      (adminForm.get('affiliateCode')?.errors?.minlength &&
                        errorSubmission)
                    "
                [message]="'Affiliate code cannot be less than two(2).'"
              ></app-field-error>
            </div>

            <h5 class="text-uppercase text-primary" style="margin-top: 3rem;">Admin Details</h5>
            <hr class="hr">
            <div class="d-block w-100">
              <div id="name" class="row g-3 mb-4 px-0">
                <div id="first-name" class="col-sm">
                  <label for="first-name-input" class="form-label">First Name</label>
                  <input id="first-name-input" type="text" class="form-control form-control-lg"
                         formControlName="firstName"
                         placeholder="John"/>
                  <app-field-error *ngIf="
                        (adminForm.get('firstName')?.errors?.required &&
                          adminForm.get('firstName')?.touched) ||
                        (adminForm.get('firstName')?.errors?.required &&
                          errorSubmission)
                      " [message]="'First name cannot be blank.'"
                  ></app-field-error>
                  <app-field-error
                    *ngIf="
                        (adminForm.get('firstName')?.errors?.minlength &&
                          adminForm.get('firstName')?.touched) ||
                        (adminForm.get('firstName')?.errors?.minlength &&
                          errorSubmission)
                      " [message]="'First name cannot be less than two(2) characters.'"
                  ></app-field-error>
                  <app-field-error
                    *ngIf="
                      (basicInfoForm.get('firstName')?.errors?.maxlength &&
                        basicInfoForm.get('firstName')?.touched) ||
                      (basicInfoForm.get('firstName')?.errors?.maxlength &&
                        errorSubmission)
                    "
                    [message]="'First name cannot be more than fifty(50) characters.'"
                  ></app-field-error>
                </div>
                <div id="last-name" class="col-sm">
                  <label for="last-name-input" class="form-label">
                    Last Name
                  </label>
                  <input id="last-name-input" type="text" class="form-control form-control-lg"
                         formControlName="lastName"
                         placeholder="Doe"/>
                  <app-field-error *ngIf="
                        (adminForm.get('lastName')?.errors?.required &&
                          adminForm.get('lastName')?.touched) ||
                        (adminForm.get('lastName')?.errors?.required &&
                          errorSubmission)
                      " [message]="'Last name cannot be blank.'"
                  ></app-field-error>
                  <app-field-error
                    *ngIf="
                        (adminForm.get('lastName')?.errors?.minlength &&
                          adminForm.get('lastName')?.touched) ||
                        (adminForm.get('lastName')?.errors?.minlength &&
                          errorSubmission)
                      "
                    [message]="'Last name cannot be less than two(2).'"
                  ></app-field-error>
                  <app-field-error
                    *ngIf="
                      (adminForm.get('lastName')?.errors?.maxlength &&
                        adminForm.get('lastName')?.touched) ||
                      (adminForm.get('lastName')?.errors?.maxlength &&
                        errorSubmission)"
                    [message]="'Last name cannot be more than fifty(50) characters.'"
                  ></app-field-error>
                </div>
              </div>
              <div id="password-field" class="row g-3 mb-4 px-0">
                <div id="password1" class="col-sm">
                  <label for="password-input" class="form-label">
                    Password
                  </label>
                  <input
                    type="password" id="password-input"
                    class="form-control form-control-lg"
                    formControlName="newPassword"
                    placeholder="Password"/>
                  <app-field-error
                    class="mt-1"
                    [message]="'Password is poor.'"
                    *ngIf="
                      adminForm.get('newPassword')!.errors?.notStrong"></app-field-error>
                  <app-field-error
                    *ngIf="
                        (adminForm.get('newPassword')?.errors?.required &&
                          adminForm.get('newPassword')?.touched) ||
                        (adminForm.get('newPassword')?.errors?.required &&
                          errorSubmission)
                      "
                    [message]="'Password cannot be blank.'"
                  ></app-field-error>
                  <app-field-error
                    *ngIf="
                      (adminForm.get('newPassword')?.errors?.minlength &&
                        adminForm.get('newPassword')?.touched) ||
                      (adminForm.get('newPassword')?.errors?.minlength &&
                        errorSubmission)"
                    [message]="'Password cannot be less than two(2).'"
                  ></app-field-error>
                  <app-field-error
                    *ngIf="
                      (adminForm.get('newPassword')?.errors?.maxlength &&
                        adminForm.get('newPassword')?.touched) ||
                      (adminForm.get('newPassword')?.errors?.maxlength &&
                        errorSubmission)"
                    [message]="'Password cannot be more than fifty(50).'"
                  ></app-field-error>

                  <app-ng-password-strength-meter
                    [passwordToCheck]="adminForm.getRawValue().newPassword"
                    (passwordStrengthMessage)="onPasswordStrengthMessChanged($event)"
                  ></app-ng-password-strength-meter>
                </div>
                <!-- Password input -->
                <div *ngIf="false" id="password" class="col-sm">
                  <label class="form-label required" for="pass">Password</label>
                  <div class="input-group">
                    <input
                      class="form-control form-control-lg"
                      placeholder="Password"
                      id="pass"
                      formControlName="newPassword"
                      [type]="displayPassword ? 'text' : 'password'"/>
                    <div class="input-group-append">
                          <span class="input-group-text bg-white px-1 input-bn">
                      </span>
                    </div>
                  </div>
                  <app-field-error
                    class="mt-1"
                    [message]="'Password is poor.'"
                    *ngIf="
                      adminForm.get('newPassword')!.errors?.notStrong"></app-field-error>
                  <app-field-error
                    *ngIf="(adminForm.get('newPassword')?.errors?.required &&
                        adminForm.get('newPassword')?.touched) ||
                      (adminForm.get('newPassword')?.errors?.required &&
                        errorSubmission)"
                    [message]="'Password cannot be blank.'"></app-field-error>
                </div>
                <div id="confirm-password" class="col-sm">
                  <label for="confirm-password-input" class="form-label">
                    Confirm Password
                  </label>
                  <input type="password" id="confirm-password-input" class="form-control form-control-lg"
                         formControlName="confirmPassword" placeholder="Confirm Password"/>
                  <app-field-error *ngIf="
                        adminForm.get('confirmPassword')!.errors
                          ?.passwordMismatch
                      "
                                   [message]="
                        'Password and confirm password must be the same.'
                      "
                  ></app-field-error>
                  <app-field-error
                    *ngIf="
                        (adminForm.get('confirmPassword')?.errors?.required &&
                          adminForm.get('confirmPassword')?.touched) ||
                        (adminForm.get('confirmPassword')?.errors?.required &&
                          errorSubmission)
                      "
                    [message]="'Confirm password cannot be blank.'"
                  ></app-field-error>
                </div>
              </div>
              <div id="t-c" class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="acceptTerms" id="flexCheckDefault"/>
                <label class="form-check-label ms-2 pb-0 login-checker" for="flexCheckDefault">
                  I agree to DentalDoor’s
                  <a routerLink="/terms-and-conditions">Terms of Use</a>,
                  <a routerLink="/review-privacy"> Privacy and Cookie Policy</a>.
                </label>
                <app-field-error *ngIf="
                      (adminForm.get('acceptTerms')?.errors?.required &&
                        adminForm.get('acceptTerms')?.touched) ||
                      (adminForm.get('acceptTerms')?.errors?.required &&
                        errorSubmission)
                    "
                                 [message]="
                      'You must agree to the terms of use, privacy and cookie policy.'
                    "
                ></app-field-error>
              </div>

              <div class="d-flex justify-content-between my-5 ms-1">
                <div class="carousel-control-prev">

                  <button type="button" class="btn btn-primary ms-1" data-bs-target="#carouselExampleControls"
                          data-bs-slide-to="0">
                    Back
                  </button>
                </div>
                <div class="carousel-control-next ">
                  <button type="button" class="btn btn-primary d-flex align-items-center justify-content-center"
                          (click)="submit()" [disabled]="submitting">
                    <iconify-icon
                      *ngIf="submitting"
                      icon="eos-icons:bubble-loading"
                      style="color: #ffffff "></iconify-icon>
                    <div>Confirm</div>

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
