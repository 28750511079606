<div class="modal-content card rounded-1 border-light container-fluid success p-0 m-0">
    <div class="modal-header card-header m-0 rounded-1 border-0 p-4">
        <h5 class="modal-title ms-auto" id="staticBackdropLabel">{{infoTitle}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="this.dismissClicked.emit()"></button>
    </div>
    <div class="modal-body card-body">
        <p class="h6 mt-2 mb-5 text-center">{{commissionLogPojo?.name}}</p>
        <div class="container">
            <div class="row mb-3">
                <div class="col">
                    <span class="float-start me-2 d-block icon"><svg xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                                             height="1.2em" viewBox="0 0 24 24"><path fill="none"
                                                                                                      stroke="currentColor"
                                                                                                      stroke-linecap="round"
                                                                                                      stroke-linejoin="round"
                                                                                                      stroke-width="1.5"
                                                                                                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"/></svg></span>
                    <div class="col">
                        <span class="small text-start d-block">{{commissionLogPojo?.address}}</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <span class="float-start me-2 d-block icon"><svg xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                                             height="1.2em" viewBox="0 0 32 32"><path
              fill="currentcolor"
              d="M18 30h2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h2v-2a3.003 3.003 0 0 0-3-3h-6a3.003 3.003 0 0 0-3 3zm6-6a4 4 0 1 1 4-4a4.004 4.004 0 0 1-4 4zm0-6a2 2 0 1 0 2 2a2.002 2.002 0 0 0-2-2zm1.342-7.94l-22-8A1 1 0 0 0 2.06 3.341l8 22a1.001 1.001 0 0 0 .924.659H11a1 1 0 0 0 .929-.629l3.841-9.602l9.602-3.84a1 1 0 0 0-.03-1.87zm-10.714 4.01l-.398.16l-.159.398l-3.027 7.568L4.672 4.672l17.524 6.372z"/></svg></span>
                    <div class="col">
                        <span class="small text-start d-block pt-1">{{commissionLogPojo?.email}}</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <span class="float-start me-2 d-block icon"><svg xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                                             height="1.2em" viewBox="0 0 32 32"><path fill="#8d98a7"
                                                                                                      d="M16 14h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm-8 4h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm-8 4h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm-8-12h10v2H16z"/><path
              fill="currentColor"
              d="M28 6H14V5a2.002 2.002 0 0 0-2-2H8a2.002 2.002 0 0 0-2 2v1H4a2.002 2.002 0 0 0-2 2v18a2.002 2.002 0 0 0 2 2h24a2.002 2.002 0 0 0 2-2V8a2.002 2.002 0 0 0-2-2ZM8 5h4v17H8Zm20 21H4V8h2v14a2.002 2.002 0 0 0 2 2h4a2.002 2.002 0 0 0 2-2V8h14Z"/></svg></span>
                    <div class="col">
                        <span class="small text-start d-block pt-1">{{commissionLogPojo?.phoneNumber}}</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <span class="float-start me-2 d-block icon">
              <iconify-icon *ngIf="commissionLogPojo?.subscriptionStatusConstant != this.subscriptionStatusConstant.Active" icon="wpf:paid" style="font-size: 20px;"></iconify-icon>
              <iconify-icon  *ngIf="commissionLogPojo?.subscriptionStatusConstant == this.subscriptionStatusConstant.Active" icon="flat-color-icons:paid" style="font-size: 20px;"></iconify-icon></span>
                    <div class="col">
                        <span class="small text-start d-block pt-1">{{commissionLogPojo?.subscriptionStatusConstant == this.subscriptionStatusConstant.Active ? 'PAID' : commissionLogPojo?.subscriptionStatusConstant == this.subscriptionStatusConstant.Expired ? 'EXPIRED': 'NOT PAID'}}</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="commissionLogPojo?.billingCycleConstant">
                <div class="col">
                    <span class="float-start me-2 d-block icon">
              <iconify-icon icon="fluent:reward-12-filled" style="color: gold; font-size: 20px;"></iconify-icon></span>
                    <div class="col">
                        <span class="small text-start d-block pt-1">{{'NO. ' +commissionLogPojo?.paidCommissionMonths + ' / '}}{{commissionLogPojo?.billingCycleConstant == this.billingCycleConstant.Annual ? 'YEARLY PAYMENT' : commissionLogPojo?.billingCycleConstant + ' PAYMENT'}}</span>
                    </div>
                </div>
            </div>
        </div>

        <a class="btn btn-md btn-dark w-100 mt-5 px-5 text-center" data-bs-dismiss="modal" (click)="this.closeClicked.emit()">Close</a>
    </div>
</div>