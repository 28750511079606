/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReviewSummaryPojo } from './review-summary-pojo';
import { UserPojo } from './user-pojo';
import { FlashCardItemPojo } from './flash-card-item-pojo';
import { PaymentInvoicePojo } from './payment-invoice-pojo';
import { PortalUser } from './portal-user';


export interface FlashcardSetPojo { 
    dateCreated?: string;
    dateDeactivated?: string;
    status?: FlashcardSetPojo.StatusEnum;
    description?: string;
    isPrivate?: boolean;
    title?: string;
    lastUpdated?: string;
    id?: number;
    deactivatedBy?: PortalUser;
    createdBy?: UserPojo;
    flashCardItems?: Array<FlashCardItemPojo>;
    totalNumberOfCardsInSet?: number;
    published?: boolean;
    favouriteCount?: number;
    currentUserFavorite?: boolean;
    reviewPojo?: ReviewSummaryPojo;
    globalImageId?: number;
    currentUserInvoice?: PaymentInvoicePojo;
    likeCount?: number;
    dislikeCount?: number;
    currentUserFavoriteStatus?: FlashcardSetPojo.CurrentUserFavoriteStatusEnum;
    price?: number;
    categories?: Array<string>;
    paidFlashCardSet?: boolean;
}
export namespace FlashcardSetPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type CurrentUserFavoriteStatusEnum = 'LIKE' | 'UNLIKE' | 'DISLIKE';
    export const CurrentUserFavoriteStatusEnum = {
        Like: 'LIKE' as CurrentUserFavoriteStatusEnum,
        Unlike: 'UNLIKE' as CurrentUserFavoriteStatusEnum,
        Dislike: 'DISLIKE' as CurrentUserFavoriteStatusEnum
    };
}


