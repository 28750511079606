/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SystemCurrency } from './system-currency';


export interface Coupon { 
    value?: number;
    valueType?: Coupon.ValueTypeEnum;
    id?: number;
    currency?: SystemCurrency;
}
export namespace Coupon {
    export type ValueTypeEnum = 'AMOUNT' | 'PERCENTAGE';
    export const ValueTypeEnum = {
        Amount: 'AMOUNT' as ValueTypeEnum,
        Percentage: 'PERCENTAGE' as ValueTypeEnum
    };
}


