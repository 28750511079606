/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RedemptionOverrideRequestSearchFilter { 
    statusConstant?: RedemptionOverrideRequestSearchFilter.StatusConstantEnum;
    dentalOffice?: number;
    redemptionCode?: string;
    limit?: number;
    offset?: number;
}
export namespace RedemptionOverrideRequestSearchFilter {
    export type StatusConstantEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const StatusConstantEnum = {
        Approved: 'APPROVED' as StatusConstantEnum,
        Disapproved: 'DISAPPROVED' as StatusConstantEnum,
        Revoked: 'REVOKED' as StatusConstantEnum,
        Pending: 'PENDING' as StatusConstantEnum
    };
}


