import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afiliate-terms-and-conditions',
  templateUrl: './afiliate-terms-and-conditions.component.html',
  styleUrls: ['./afiliate-terms-and-conditions.component.css']
})
export class AfiliateTermsAndConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
