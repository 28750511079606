import {HttpClient, HttpResponse} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AsyncSubject, BehaviorSubject, from, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakLoginOptions} from 'keycloak-js';
import {Constant} from 'dd-shared-lib';
import {UserAccountKeycloak} from "./UserAccountKeycloak";
import {PermissionType} from "../shared/account/permission-type.enum";
import {UserKeycloak} from "./UserKeycloak";
import {IpAddressControllerService} from "dd-core-api-sdk";
import { PageManager } from './page-manager';
import {TokenStore} from "../shared/token-store";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationServiceKeycloak {

  public static _user: UserKeycloak;
  private static user: Subject<UserKeycloak | undefined> = new ReplaySubject();
  private static token: Subject<string | null> = new ReplaySubject(undefined);
  private static ongoingFetch: Observable<any> | null;
  private static initialized: boolean;
  public static _currentUserAccount: UserAccountKeycloak | undefined;
  private static currentUserAccount$: Subject<UserAccountKeycloak | null> = new ReplaySubject(undefined);
  private static newUserToken: EventEmitter<string | null> = new EventEmitter();
  public allAccounts!: UserAccountKeycloak[];
  private logUser: any;

  constructor(
    private httpClient: HttpClient,
    private ipAddressControllerService: IpAddressControllerService,
    private keycloak: KeycloakService,
    private pageManager: PageManager,
    private router: Router,
    private tokenStore: TokenStore,
  ) {
    AuthenticationServiceKeycloak.user.subscribe((user: any) => {

      this.logUser = user;
      if (user === undefined) {
        AuthenticationServiceKeycloak._currentUserAccount = undefined;
        AuthenticationServiceKeycloak.currentUserAccount$.next(AuthenticationServiceKeycloak._currentUserAccount);
        return;
      }
      AuthenticationServiceKeycloak.initialized = true;
      AuthenticationServiceKeycloak._user = user;
      if (user.accounts) {
        this.allAccounts = user.accounts.map((it: any) => new UserAccountKeycloak(it));
        const accInStorage = this.getCurrentUserAccountInStorage(user) || this.allAccounts[0];

        if (this.allAccounts.length < 2) {
          sessionStorage.setItem("ACCOUNT_SELECTED", 'true');
        }
        AuthenticationServiceKeycloak._currentUserAccount = accInStorage;
        AuthenticationServiceKeycloak.currentUserAccount$.next(AuthenticationServiceKeycloak._currentUserAccount);

      }
    });

    this.keycloak.keycloakEvents$.subscribe(value => {
      if (value.type === KeycloakEventType.OnAuthSuccess || value.type === KeycloakEventType.OnAuthRefreshSuccess || value.type === KeycloakEventType.OnAuthRefreshError) {
        from(this.keycloak.getToken())
          .subscribe(token => {
            AuthenticationServiceKeycloak.token.next(token);
          });

      } else if (value.type === KeycloakEventType.OnTokenExpired) {
        from(this.keycloak.getToken())
          .subscribe(token => {
            AuthenticationServiceKeycloak.token.next(token);
          });
      }

    });

  }


  public logout(redirectUri?: string) {
    localStorage.setItem("ACTIVITY_LOGIN", 'true');
    sessionStorage.clear();
    this.keycloak.clearToken();
    // @ts-ignore
    return from(this.keycloak.logout(redirectUri)).pipe(tap(x => AuthenticationService.user.next(null)));
  }

  public logoutClick(redirectUri?: string) {
    localStorage.setItem("ACTIVITY_LOGIN", 'true');
    sessionStorage.clear();
    this.getUser().subscribe(res => {
      sessionStorage.clear();
      this.keycloak.clearToken();
      // @ts-ignore
      return from(this.keycloak.logout(redirectUri)).pipe(tap(x => AuthenticationService.user.next(null)));
    });
  }

  public login(loginOptions: KeycloakLoginOptions) {
    return this.keycloak.logout(this.keycloak.getKeycloakInstance().createLoginUrl(loginOptions));
  }

  private permissions(): string[] {
    let currentAccount = AuthenticationServiceKeycloak._currentUserAccount;
    if (!currentAccount) {
      return [];
    }
    return currentAccount.permissions
  }

  public setCurrentAccount(account: UserAccountKeycloak) {
    this.setCurrentUserAccount(account);
  }

  public hasPermission(permissionName: string | PermissionType) {
    return this.permissions().filter((it: string) => it === permissionName).length;
  }

  public hasAnyPermission(permissions: string[] | PermissionType[]) {
    for (let permission of permissions) {
      if (this.hasPermission(permission)) {
        return true;
      }
    }
    return false;
  }

  hasRole(role: string): boolean {
    return AuthenticationServiceKeycloak._currentUserAccount?.roles.find(value => value == role) != null;
  }

  hasAnyRole(roles: string[]): boolean {
    for (let role of roles) {
      if (this.hasRole(role)) {
        return true;
      }
    }
    return false;
  }

  public hasAccountType(accountType: string) {
    return AuthenticationServiceKeycloak._currentUserAccount?.accountType === accountType;
  }

  public requestPasswordReset(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/password/forgot`, data);
  }

  public getUser() {
    return AuthenticationServiceKeycloak.user;
  }

  public getAccount() {
    return AuthenticationServiceKeycloak.currentUserAccount$;
  }


  public forbidAccess() {
    this.router.navigate(['/forbidden']);
  }

  public fetchUser(): Observable<UserKeycloak> {
    if (AuthenticationServiceKeycloak.initialized) {
      return of(AuthenticationServiceKeycloak._user);
    }
    return this.fetch();
  }

  private fetch() {
    const wrapper = new AsyncSubject();
    AuthenticationServiceKeycloak.ongoingFetch = wrapper;
    // console.log('ongoingFetch set');

    this.httpClient.get(`${environment.apiBaseUrl}/me`)
      .subscribe((u: any) => {
        const user = new UserKeycloak(u);
        wrapper.next(user);
        wrapper.complete();

        AuthenticationServiceKeycloak.user.next(user);
        AuthenticationServiceKeycloak.ongoingFetch = null;
      }, (err: any) => {
        wrapper.error(err);
        wrapper.complete();
        AuthenticationServiceKeycloak.user.next(undefined);
      });

    return AuthenticationServiceKeycloak.ongoingFetch;
  }

  private getCurrentUserAccountFromStorage() {
    return this.pageManager.getData('USER_ACCOUNT', 'currentAccount', Constant.Storage.LOCAL);
  }

  private setCurrentUserAccount(userAccount: UserAccountKeycloak) {
    if (!userAccount) {
      AuthenticationServiceKeycloak.currentUserAccount$.next(this.getCurrentUserAccountFromStorage());
    } else {
      AuthenticationServiceKeycloak.currentUserAccount$.next(userAccount);
      this.pageManager.storeData('USER_ACCOUNT', 'currentAccount', userAccount, Constant.Storage.LOCAL);
    }
  }

  private getCurrentUserAccountInStorage(user: UserKeycloak): any {
    const accountInStorage: UserAccountKeycloak = this.getCurrentUserAccountFromStorage();
    if (!accountInStorage) {
      return null;
    }
    return this.allAccounts.find(value => value.accountCode == accountInStorage.accountCode);
  }

}

