/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceCompany } from './insurance-company';
import { InsuranceCompanyWithServicesPojo } from './insurance-company-with-services-pojo';
import { Specialization } from './specialization';
import { NameAndCodeDto } from './name-and-code-dto';
import { FhirConfig } from './fhir-config';
import { DentalServicePojo } from './dental-service-pojo';
import { AddressPojo } from './address-pojo';
import { UserPojo } from './user-pojo';
import { GpsCoordinate } from './gps-coordinate';
import { DentalOfficeImagePojo } from './dental-office-image-pojo';
import { DentalOfficeSubscriptionPojo } from './dental-office-subscription-pojo';


export interface DentalOfficePojo { 
    email?: string;
    mobileNumber?: string;
    name?: string;
    code?: string;
    handle?: string;
    summary?: string;
    uniqueStatement?: string;
    dateCreated?: string;
    id?: number;
    alternatePhoneNumber?: string;
    facebookUrl?: string;
    twitterUrl?: string;
    instagramUrl?: string;
    alternateEmail?: string;
    website?: string;
    activeCouponCount?: number;
    specializationPojoList?: Array<Specialization>;
    specializationDetails?: string;
    displayPictureId?: number;
    languages?: Array<NameAndCodeDto>;
    services?: Array<DentalServicePojo>;
    additionalServices?: Array<DentalServicePojo>;
    insurances?: Array<InsuranceCompanyWithServicesPojo>;
    address?: AddressPojo;
    streetAddress?: string;
    setupComplete?: boolean;
    createdBy?: UserPojo;
    adminUser?: UserPojo;
    approvalStatus?: DentalOfficePojo.ApprovalStatusEnum;
    reasonForDenial?: string;
    dateOfApprovalProcessing?: string;
    dentalOfficeImagePojos?: DentalOfficeImagePojo;
    subscription?: DentalOfficeSubscriptionPojo;
    autoChargeCardForPayment?: boolean;
    fhirConfig?: FhirConfig;
    favouriteCount?: number;
    likeCount?: number;
    dislikeCount?: number;
    currentUserFavoriteStatus?: DentalOfficePojo.CurrentUserFavoriteStatusEnum;
    currentUserFavorite?: boolean;
    requireInsuranceForAppointment?: boolean;
    requireIdentityForAppointment?: boolean;
    appointmentBookingPolicy?: string;
    specialityOffice?: boolean;
    apptProcessingTimeInMinutes?: number;
    dentalOfficeImageId?: number;
    referralImageFromId?: number;
    referralImageToId?: number;
    gpsCoordinate?: GpsCoordinate;
    currentOfficeFavourite?: boolean;
    utcOffsetMinutes?: string;
    aboutUrl?: string;
    insuranceCompany?: Array<InsuranceCompany>;
    dentalDoor?: boolean;
}
export namespace DentalOfficePojo {
    export type ApprovalStatusEnum = 'APPROVED' | 'DISAPPROVED' | 'REVOKED' | 'PENDING';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum,
        Revoked: 'REVOKED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum
    };
    export type CurrentUserFavoriteStatusEnum = 'LIKE' | 'UNLIKE' | 'DISLIKE';
    export const CurrentUserFavoriteStatusEnum = {
        Like: 'LIKE' as CurrentUserFavoriteStatusEnum,
        Unlike: 'UNLIKE' as CurrentUserFavoriteStatusEnum,
        Dislike: 'DISLIKE' as CurrentUserFavoriteStatusEnum
    };
}


