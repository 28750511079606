/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CommentDto { 
    note: string;
    entityId: number;
    entityType: CommentDto.EntityTypeEnum;
    commentType: CommentDto.CommentTypeEnum;
    commentId?: number;
    remainAnonymous?: boolean;
}
export namespace CommentDto {
    export type EntityTypeEnum = 'Review';
    export const EntityTypeEnum = {
        Review: 'Review' as EntityTypeEnum
    };
    export type CommentTypeEnum = 'COMMENT' | 'DISPUTE';
    export const CommentTypeEnum = {
        Comment: 'COMMENT' as CommentTypeEnum,
        Dispute: 'DISPUTE' as CommentTypeEnum
    };
}


