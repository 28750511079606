/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppointmentCancellationReason } from './appointment-cancellation-reason';


export interface AppointmentReminderFeedback { 
    status?: AppointmentReminderFeedback.StatusEnum;
    dateCreated?: string;
    comment?: string;
    feedback?: AppointmentReminderFeedback.FeedbackEnum;
    id?: number;
    reasonForCancellation?: AppointmentCancellationReason;
}
export namespace AppointmentReminderFeedback {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type FeedbackEnum = 'WILL_HONOUR' | 'WILL_NOT_HONOUR';
    export const FeedbackEnum = {
        Honour: 'WILL_HONOUR' as FeedbackEnum,
        NotHonour: 'WILL_NOT_HONOUR' as FeedbackEnum
    };
}


