<div class="container-fluid">
  <div class="row">
    <div class="overview-card p-3">
      <div class="filter-card-bg p-md-4 py-3">
        <div *ngIf="showErrorMessageTrigger">
          <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert">
            <p class="m-0 p-0 d-flex align-items-start">
              <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                {{ getErrorMessage() | removeUnderscores }}
              </span>
              <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
              </iconify-icon>
            </p>
          </div>
        </div>
        <div *ngIf="showSuccessMessageTrigger">
          <div class="alert-success alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show" role="alert">
            <p class="m-0 p-0 d-flex align-items-start">
                <span>
                  <iconify-icon
                    class="iconify me-2"
                    icon="ep:info-filled"
                  ></iconify-icon>
                  {{ getSuccessMessage() | removeUnderscores }}
                </span>
              <iconify-icon
                class="iconify fs-4 ms-auto pointer"
                icon="mdi:close"
                data-bs-dismiss="alert">
              </iconify-icon>
            </p>
          </div>
        </div>
        <div class="container-fluid">
          <div [formGroup]="form" class="row g-3">
            <div class="form-floating col-xl-2 col-md-4 col-sm-6 mb-md-0">
              <input type="email" class="form-control" id="ptn" placeholder="e.g John Doe"
                     formControlName="patientName">
              <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Patient Name</label>
            </div>
            <div class="form-floating col-xl-2 col-md-4 col-sm-6 mb-md-0">
              <input type="email" class="form-control" placeholder="e.g DR000001" aria-label="referralCode"
                     formControlName="referralCode">
              <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Referral Code</label>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6 mb-0 mb-md-0">
              <div class="form-floating">
                <select class="form-select" id="fs" aria-label="Floating label select" formControlName="referralStatus">
                  <option [selected]="true" [value]="''" class="form-label"> Select status...</option>
                  <option *ngFor="let item of referralStatusEnum" [value]="item">
                    {{ item == 'NEW' && !isInbound ? 'UNREAD' : item | uppercase | removeUnderscores }}
                  </option>
                </select>
                <label for="fs">Status </label>
              </div>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0 " style="
    margin-top: -.6rem;
">
              <div class="">
                <label class="form-label rfr-label"> Date (from) </label>
                <div class="input-group">
                  <input class="form-control" placeholder="MM-DD-YYYY" formControlName="startDate"
                         [minDate]="{ year: 1910, month: 1, day: 1 }"
                         [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }" [(ngModel)]="model"
                         ngbDatepicker #startDate="ngbDatepicker" (click)="startDate.toggle()"/>
                  <span (click)="startDate.toggle()" class="btn-primary-outline input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0" style="
                margin-top: -.6rem;">
              <div class="">
                <label class="form-label rfr-label"> Date (to) </label>
                <div class="input-group">
                  <input class="form-control" placeholder="MM-DD-YYYY" formControlName="endDate"
                         [minDate]="{ year: 1910, month: 1, day: 1 }"
                         [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }" [(ngModel)]="model1"
                         ngbDatepicker #endDate="ngbDatepicker" (click)="endDate.toggle()"/>
                  <span (click)="endDate.toggle()" class="btn-primary-outline input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0  float-end" style="white-space: nowrap;">
              <button
                class="bg-primary border-0 d-flex justify-content-center align-items-center px-3 text-light float-end"
                (click)="onSearchClick()" style=" margin-top: 3px; padding: .35rem;">
                <iconify-icon inline icon="iconoir:search" class="me-2 fs-5" width="25" height="25"></iconify-icon>
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row mt-4">
    <div class="overview-card-table p-3">

      <div class="table-responsive">
        <div class="text-secondary my-3">
          <h5 class="m-2 fw-bold"><i>d</i>Referrals</h5>
        </div>
        <table class="table">
          <thead class="">
          <tr class="overview-card shadow-sm">
            <th>ID</th>
            <th class="">Patient</th>
            <th class="">Status</th>
            <th class=""> Referral Code</th>
            <th class="">Referring</th>
            <th class="">{{ isInbound ? "Date Received" : "Sent" }}</th>
            <th class="">Receiving</th>
            <th class="">Action</th>
          </tr>
          </thead>
          <tbody class="text-muted">
          <tr *ngFor="let referral of this.resultSubject.value.results; let i = index; trackBy: trackByFn" [id]="'action' + i">
            <td [id]="'index' + i" class="align-middle fw-bold fs-6" data-label="ID">
              {{ offset + i + 1 }}
            </td>
            <td [id]="'patient' + i" class="align-middle" data-label="Patient"
                [routerLink]="[isInbound ? '/in-bound/referrals' : '/out-bound/referrals', referral.referralDataId]">
              <div class="d-flex align-items-center">
                <p class="my-0 fw-bold">
                  {{ referral.patient.displayName }}
                </p>
                <div class="ms-1" *ngIf="referral?.firstContactId">
                  <iconify-icon icon="material-symbols:mark-email-read-rounded" class="text-success" width="16"
                                height="16"></iconify-icon>
                </div>
              </div>
              <span class="mb-0">{{ referral?.patient?.phoneNumber }}</span><br/>
              <span>{{ this.getAge(referral?.patient?.dateOfBirth) }} </span>
            </td>
            <td [id]="'status' + i" class="fw-bold align-middle text-capitalize" data-label="Status">
                <span class="position-relative px-2"
                      [ngClass]="{'status': referral.referralStatusConstant == referralStatus.New}">
                      {{ !isInbound && referral.referralStatusConstant == referralStatus.New ? "UNREAD" : (referral.referralStatusConstant | removeUnderscores | uppercase) }}
                  <span class="position-absolute top-0 start-100 translate-middle circle info-icon"
                        *ngIf="referral?.referralStatusNotesList?.length > 0" (click)="showStatusNotes(referral)">
                    <iconify-icon icon="bi:info-circle-fill" style="color: #02183d;" width="18" height="18" class="ms-1"
                                  data-bs-toggle="modal" data-bs-target="#messageModal"></iconify-icon>
                  </span>
                </span>
            </td>
            <td [id]="'R' + i" class="align-middle" data-label="Referral Code"
                [routerLink]="[isInbound ? '/in-bound/referrals' : '/out-bound/referrals', referral.referralDataId]">
              {{ referral.code }}
            </td>
            <td [id]="'referring' + i" class="align-middle" data-label="Referring"
                [routerLink]="[isInbound ? '/in-bound/referrals' : '/out-bound/referrals', referral.referralDataId]">
              <span class="mb-1 fs-12 fw-bold"
                    *ngIf="referral.dentalProfessionalFrom || referral.dentalProFromFirstName">
                {{
                  referral.dentalProfessionalFrom?.name ? "Dr. " + referral.dentalProfessionalFrom?.name : 'Dr. ' +
                    referral.dentalProFromFirstName + ' ' + referral.dentalProFromLastName
                }}
              </span> <br/>
              <span class="fw-light fs-12 mt-1" *ngIf="isInbound">
                {{
                  referral.dentalOfficeReferralFrom?.name ||
                  referral.temporaryDentalOfficePojo.name
                }}
              </span>
            </td>
            <td [id]="'date' + i" class="align-middle" data-label="Date Received">
              {{
                isInbound
                  ? (formatUTCMoment(referral.dateCreated) | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes)
                  : (formatUTCMoment(referral.dateCreated) | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes)
              }}
            </td>
            <td [id]="'receiving' + i" class="align-middle" data-label="Receiving">
              <span class="mb-1 fs-12 fw-bold" *ngIf="referral?.dentalProfessionalReferralTo">
                {{ "Dr. " + referral?.dentalProfessionalReferralTo?.name }}
              </span>
              <span class="mb-1 fs-12 fw-bold"
                    *ngIf="!referral?.dentalProfessionalReferralTo || referral?.temporaryDentalOfficePojo">
                {{ "Available Dr." }}
              </span>
              <br/>
              <span class="fw-light fs-12 mt-1" *ngIf="!isInbound">
                {{
                  referral.dentalOfficeReferralTo?.name ||
                  referral?.temporaryDentalOfficePojo?.name
                }}
              </span>
            </td>

            <td [id]="'action' + i" class="table-action-dropdown align-middle">
              <span class="pointer" style="text-decoration: underline" data-bs-toggle="dropdown">Action</span>
              <iconify-icon class="iconify pointer" data-icon="ri:more-2-line"></iconify-icon>
              <ul class="dropdown-menu dropdown-menu-start pointer fs-6">
                <li>
                  <button class="dropdown-item d-flex align-items-center"
                          (click)="downloadReferralForm(referral.referralRequestId)">
                    <iconify-icon icon="eva:download-outline" style="color: #369dbf;" width="25"
                                  height="25"></iconify-icon>
                    Download
                  </button>
                </li>

                <li>
                  <button class="dropdown-item d-flex align-items-center" href="#"
                          (click)="resendNotificationModal(referral)">
                      <span *ngIf="!resending">
                        <iconify-icon icon="bi:arrow-repeat" style="color: #369dbf;" width="25"
                                      height="25"></iconify-icon>
                      </span>
                    <span *ngIf="resending">
                        <iconify-icon icon="line-md:loading-alt-loop"></iconify-icon>
                      </span>
                    &nbsp; Resend
                  </button>
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <span
          *ngIf="!this.resultSubject.value.results ||  this.resultSubject.value.total == 0"
          class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
          {{
            this.resultSubject.value.results
              ? "NO DATA/RECORDS"
              : "APPLY FILTERS TO VIEW REFERRAL DETAILS"
          }}</span>
      </div>
      <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3"
           style="margin-bottom: -1rem; color: #4e3367">
        <app-page-length [pageLength]="getFilter().limit || 10" (pageLengthChanged)="changeLimit($event)">
        </app-page-length>
        <div class="add-group-btn text-right mt-3 mb-3">
          <pagination (pageChanged)="onPageChange($event)" [boundaryLinks]="true" [itemsPerPage]="limit || 10"
                      [(ngModel)]="page" [maxSize]="5"
                      [totalItems]="this.resultSubject?.value?.total || 0" firstText="&laquo;"
                      lastText="&raquo;" nextText="&rsaquo;" previousText="&lsaquo;"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>
