/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AppointmentType { 
    name?: AppointmentType.NameEnum;
    description?: string;
    id?: number;
}
export namespace AppointmentType {
    export type NameEnum = 'CHECKUP' | 'EMERGENCY' | 'FOLLOWUP' | 'ROUTINE' | 'WALKIN';
    export const NameEnum = {
        Checkup: 'CHECKUP' as NameEnum,
        Emergency: 'EMERGENCY' as NameEnum,
        Followup: 'FOLLOWUP' as NameEnum,
        Routine: 'ROUTINE' as NameEnum,
        Walkin: 'WALKIN' as NameEnum
    };
}


