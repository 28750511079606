/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AppointmentCancellationReason { 
    code?: string;
    display?: string;
    actor?: AppointmentCancellationReason.ActorEnum;
    id?: number;
}
export namespace AppointmentCancellationReason {
    export type ActorEnum = 'PATIENT' | 'PRACTITIONER' | 'RELATED_PERSON' | 'DEVICE' | 'HEALTHCARE_SERVICE' | 'LOCATION';
    export const ActorEnum = {
        Patient: 'PATIENT' as ActorEnum,
        Practitioner: 'PRACTITIONER' as ActorEnum,
        RelatedPerson: 'RELATED_PERSON' as ActorEnum,
        Device: 'DEVICE' as ActorEnum,
        HealthcareService: 'HEALTHCARE_SERVICE' as ActorEnum,
        Location: 'LOCATION' as ActorEnum
    };
}


