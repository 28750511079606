/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferralDoc } from './referral-doc';


export interface ReferralDraftDto { 
    patientFirstName?: string;
    patientLastName?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    procedureTypes?: string;
    priority?: ReferralDraftDto.PriorityEnum;
    preMedRequired?: boolean;
    referralNote?: string;
    affectedTeeth?: string;
    referralTo?: string;
    referralFrom?: string;
    completed?: boolean;
    valid?: boolean;
    draftCode?: string;
    xrays?: string;
    referralToOfficeId?: string;
    referredByOfficeAccountId?: string;
    referredByProfessionalId?: string;
    referralToProfId?: string;
    isAdult?: boolean;
    id?: number;
    sent?: boolean;
    profManualFirstName?: string;
    profManualLastName?: string;
    docs?: Array<ReferralDoc>;
    officeId?: number;
}
export namespace ReferralDraftDto {
    export type PriorityEnum = 'NORMAL' | 'SEMI_URGENT' | 'URGENT' | 'EMERGENCY';
    export const PriorityEnum = {
        Normal: 'NORMAL' as PriorityEnum,
        SemiUrgent: 'SEMI_URGENT' as PriorityEnum,
        Urgent: 'URGENT' as PriorityEnum,
        Emergency: 'EMERGENCY' as PriorityEnum
    };
}


