import {Component, OnInit} from '@angular/core';
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../services/UserKeycloak";
import {Router} from "@angular/router";
import {SocialService} from "../../shared/subject/SocialService";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  user: UserKeycloak;

  constructor(
    private authenticationService: AuthenticationServiceKeycloak,
    private router: Router,
    private socialService: SocialService,
  ) {
  }

  ngOnInit(): void {
    this.authenticationService.getUser()
      .subscribe(value => {
        this.user = value;
      });
  }

  async login() {
    if (this.user) {
      this.authenticationService.logout();
    }
    await this.authenticationService.login({
      redirectUri: window.location.origin + '/dashboard',
      scope: 'offline_access',
      prompt: 'login',
    })
  }

  goToSignUp(type: string) {
    window.scroll(0, 0);
    this.socialService.tabSubject.next(type == 'office' ? 0 : 1)
    this.router.navigate(['/signup']);
  }

  goToAffiliates(){
    window.scroll(0, 0);
    this.router.navigate(['/affiliates']);
  }

  copyRightDate(){
    return new Date().getFullYear();
  }
}
