import {Component, OnInit, ViewChild} from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import {DentalOfficeControllerService, DentalOfficePojo, SubscriptionControllerService} from "dd-core-api-sdk";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {HttpErrorResponse} from "@angular/common/http";
import {UserKeycloak} from "../../../services/UserKeycloak";

@Component({
  selector: 'app-billing-wizard',
  templateUrl: './billing-wizard.component.html',
  styleUrls: ['./billing-wizard.component.css']
})
export class BillingWizardComponent implements OnInit {


  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  account: UserKeycloak;
  dentalOffice: DentalOfficePojo;
  valuesFromSub: any;
  subscription: string;

  constructor(private dentalOfficeService: DentalOfficeControllerService,
              private subscriptionControllerService: SubscriptionControllerService,
              private authenticationService: AuthenticationServiceKeycloak,) {
  }

  ngOnInit(): void {
    this.authenticationService.getUser().subscribe(res => {
      const id = res.accounts[0].otherData.id;
      this.account = res;
      this.dentalOfficeService.getDentalOffice({id: id})
        .subscribe((v: DentalOfficePojo) => {
          this.dentalOffice = v;
        }, (e: HttpErrorResponse) => {
        });
    });


  }

  emmitValues(value) {
    this.valuesFromSub = value;
    window.scroll(0, 0);
    this.wizard.goToNextStep();
    return;
  }

  goToNextStep(value) {
    this.subscription = value;
    this.wizard.goToNextStep();
  }

  moveToNextStep($event: any) {
    this.wizard.goToNextStep();
  }
}
