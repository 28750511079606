/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameAndCodeDto } from './name-and-code-dto';
import { StudentPojo } from './student-pojo';
import { AccountMembershipPojo } from './account-membership-pojo';
import { GroupedCountLong } from './grouped-count-long';
import { PortalUserInsuranceProviderPojo } from './portal-user-insurance-provider-pojo';
import { AddressPojo } from './address-pojo';
import { DentalProfessionalPojo } from './dental-professional-pojo';
import { DentalOfficeSubscriptionPojo } from './dental-office-subscription-pojo';


export interface UserPojo { 
    title?: NameAndCodeDto;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobileNumber?: string;
    username?: string;
    userId?: string;
    status?: UserPojo.StatusEnum;
    dateCreated?: string;
    lastUpdated?: string;
    accountCode?: string;
    createdBy?: string;
    lastUpdatedBy?: string;
    displayPictureId?: number;
    requiresPasswordUpdate?: boolean;
    authToken?: string;
    isStudent?: boolean;
    id?: number;
    dateOfBirth?: string;
    gender?: UserPojo.GenderEnum;
    isInstructor?: boolean;
    setupComplete?: boolean;
    ownsDentalOffice?: boolean;
    worksInAcademia?: boolean;
    emailVerified?: boolean;
    invitesSent?: number;
    accounts?: Array<AccountMembershipPojo>;
    dentalProfessional?: DentalProfessionalPojo;
    insuranceProviders?: Array<PortalUserInsuranceProviderPojo>;
    numberOfInvitationsSent?: number;
    languages?: Array<NameAndCodeDto>;
    address?: AddressPojo;
    student?: StudentPojo;
    totalPublishedFlashcards?: GroupedCountLong;
    onboardedItems?: Array<UserPojo.OnboardedItemsEnum>;
    dentalOfficeSubscriptionPojo?: DentalOfficeSubscriptionPojo;
    affiliateCode?: string;
    tourRideStatusConstant?: UserPojo.TourRideStatusConstantEnum;
}
export namespace UserPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type OnboardedItemsEnum = 'DASHBOARD' | 'APPOINTMENT_SEARCH';
    export const OnboardedItemsEnum = {
        Dashboard: 'DASHBOARD' as OnboardedItemsEnum,
        AppointmentSearch: 'APPOINTMENT_SEARCH' as OnboardedItemsEnum
    };
    export type TourRideStatusConstantEnum = 'DASHBOARD' | 'AFFILIATE' | 'END' | 'CREATE_REFERRAL';
    export const TourRideStatusConstantEnum = {
        Dashboard: 'DASHBOARD' as TourRideStatusConstantEnum,
        Affiliate: 'AFFILIATE' as TourRideStatusConstantEnum,
        End: 'END' as TourRideStatusConstantEnum,
        CreateReferral: 'CREATE_REFERRAL' as TourRideStatusConstantEnum
    };
}


