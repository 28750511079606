<div class="container-fluid">
  <div class="row">
    <div class="overview-card p-3">
      <div class="filter-card-bg p-md-4 py-3">
        <div *ngIf="showErrorMessageTrigger">
          <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert">
            <p class="m-0 p-0 d-flex align-items-start">
              <span>
                <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                {{ getErrorMessage() | removeUnderscores }}
              </span>
              <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
              </iconify-icon>
            </p>
          </div>
        </div>
        <div class="container-fluid">
          <div [formGroup]="form" class="row g-3">
            <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
              <input type="text" class="form-control" id="ptn" placeholder="e.g st.Marys"
                     formControlName="dentalOfficeName">
              <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Office Name</label>
            </div>
            <div class="form-floating col-xl-3 col-md-4 col-sm-6 mb-md-0">
              <input type="text" class="form-control" placeholder="e.g abc@gmail.com" aria-label="Email Address"
                     formControlName="email">
              <label for="ptn" class="" style="margin-left: .6rem; margin-top:-.2rem;">Email Address</label>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0 " style="
    margin-top: -.6rem;
">
              <div class="">
                <label class="form-label rfr-label"> Date (from) </label>
                <div class="input-group">
                  <input class="form-control" placeholder="MM-DD-YYYY" formControlName="startDate"
                         [minDate]="{ year: 1910, month: 1, day: 1 }"
                         [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }" [(ngModel)]="model"
                         ngbDatepicker #startDate="ngbDatepicker" (click)="startDate.toggle()"/>
                  <span (click)="startDate.toggle()" class="btn-primary-outline input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0" style="
                margin-top: -.6rem;">
              <div class="">
                <label class="form-label rfr-label"> Date (to) </label>
                <div class="input-group">
                  <input class="form-control" placeholder="MM-DD-YYYY" formControlName="endDate"
                         [minDate]="{ year: 1910, month: 1, day: 1 }"
                         [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }" [(ngModel)]="model1"
                         ngbDatepicker #endDate="ngbDatepicker" (click)="endDate.toggle()"/>
                  <span (click)="endDate.toggle()" class="btn-primary-outline input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6 mb-md-0  float-end" style="white-space: nowrap;">
              <button
                class="bg-primary border-0 d-flex justify-content-center align-items-center px-3 text-light float-end"
                (click)="onSearchClick()" style=" margin-top: 3px; padding: .35rem;">
                <iconify-icon inline icon="iconoir:search" class="me-2 fs-5" width="25" height="25"></iconify-icon>
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row mt-4">
    <div class="overview-card-table p-3">

      <div class="table-responsive">
        <table class="table">
          <thead class="">
          <tr class="overview-card shadow-sm" *ngIf="this.showAllOffices">
            <th>ID</th>
            <th class="">Office Name</th>
            <th class="">Email Address</th>
            <th class="">Phone Number</th>
            <th class="">Date Created</th>
            <th class="">Specialty</th>
          </tr>
          <tr class="overview-card shadow-sm" *ngIf="this.showSubscription">
            <th>ID</th>
            <th class="">Office Name</th>
            <th class="">Email Address</th>
            <th class="">Start Date</th>
            <th class="">End Date</th>
            <th class="">Subscription Status</th>
            <th class="">Plan</th>
            <th class="">Amount</th>
          </tr>
          </thead>
          <tbody class="text-muted" *ngIf="!this.searching">
          <tr *ngFor="let doffices of this.resultSubject.value.results; let i = index;" [id]="'action' + i" >
            <td [id]="'index' + i" class="align-middle fw-bold fs-6" data-label="ID">
              {{ offset + i + 1 }}
            </td>
            <td class=" fw-bold align-middle" data-label="Office Name" *ngIf="this.showAllOffices || showSubscription">
              <p class="mb-0">{{ doffices?.name }}</p>
            </td>
            <td class="align-middle" data-label="Email Address" *ngIf="this.showAllOffices || showSubscription">
              <p class="mb-0">{{ doffices?.email | lowercase}}</p>
            </td>
            <td class="align-middle" data-label="Phone Number" *ngIf="this.showAllOffices">
              {{ doffices?.mobileNumber || 'N/A' }}
            </td>
            <td class="align-middle" data-label="Date Created" *ngIf="this.showAllOffices">
              {{(doffices?.dateCreated | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes)}}
            </td>
            <td class="align-middle" data-label="Specialty" *ngIf="this.showAllOffices">
              {{doffices?.specialityOffice.toString("Yes") | uppercase}}
            </td>

            <td class="align-middle" data-label="Start Date" *ngIf="this.showSubscription">
              {{(doffices?.subscription?.startDate | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes)}}
            </td>
            <td class="align-middle" data-label="End Date" *ngIf="this.showSubscription">
              {{(doffices?.subscription?.endDate | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes)}}
            </td>
            <td class="align-middle" data-label="Subscription Status" *ngIf="this.showSubscription">
              {{doffices?.subscription?.subscriptionStatus | uppercase}}
            </td>
            <td class="align-middle" data-label="Plan" *ngIf="this.showSubscription">
              {{doffices?.subscription?.subscriptionCycle?.billingCycle | uppercase}}
            </td>
            <td class="align-middle" data-label="Amount" *ngIf="this.showSubscription">
              {{doffices?.subscription?.subscriptionCycle?.totalPaid | currency:'USD':'symbol'}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <span
          *ngIf="!this.resultSubject.value.results ||  this.resultSubject.value.total == 0"
          class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
          {{this.resultSubject.value.results
          ? "NO DATA/RECORDS"
          : "APPLY FILTERS TO VIEW OFFICE DETAILS"
          }}</span>
      </div>
      <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3"
           style="margin-bottom: -1rem; color: #4e3367">
        <app-page-length [pageLength]="getFilter().limit || 10" (pageLengthChanged)="changeLimit($event)">
        </app-page-length>
        <div class="add-group-btn text-right mt-3 mb-3">
          <pagination (pageChanged)="onPageChange($event)" [boundaryLinks]="true" [itemsPerPage]="limit || 10"
                      [(ngModel)]="page" [maxSize]="5"
                      [totalItems]="this.resultSubject.value.total || 0" firstText="&laquo;"
                      lastText="&raquo;" nextText="&rsaquo;" previousText="&lsaquo;"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>
