<section class="dashboard-content">
    <div class="container mt-4">
        <div class="row" [ngSwitch]="tabIndex">
            <div class="mb-4 d-flex align-items-center">
                <div class="fs-3 me-2 header-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.4em" viewBox="0 0 24 24">
            <path fill="currentColor"
                  d="M22 2H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h3v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-9h3a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1ZM7 20v-2a2 2 0 0 1 2 2Zm10 0h-2a2 2 0 0 1 2-2Zm0-4a4 4 0 0 0-4 4h-2a4 4 0 0 0-4-4V8h10Zm4-6h-2V7a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v3H3V4h18Zm-9 5a3 3 0 1 0-3-3a3 3 0 0 0 3 3Zm0-4a1 1 0 1 1-1 1a1 1 0 0 1 1-1Z"/>
          </svg>
                </div>
                <div class="">
                    <p class="fs-3 textmuted fw-bold mt-3 mb-0">
                        Withdrawal from wallet
                    </p>
                    <p class="small">Select payment method to withdraw money.</p>
                </div>
            </div>

            <!-- process steps -->
            <section id="process-steps">
                <div class="">
                    <div class="row">
                        <div class="col">
                            <div class="head">
                                <h4 class="h4 text-start lh-2 mb-0">Important Steps to Follow Before Initiating Payout.</h4>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="btn btn-primary" name="button" (click)="onClick()">{{stepSwitch ? 'Hide' : 'Show'}} steps
              </button>
                        </div>
                    </div>
                    <div *ngIf="stepSwitch" id="steps">
                        <hr class="hr-head">
                        <div class="row g-2">
                            <div class="col-md">
                                <div class="card p-2">
                                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill" title="{{affiliateBankPojo ? 'You have completed this step' : 'You haven\'t completed this step'}}">
                    <iconify-icon icon="material-symbols:check-circle" style="color: #34E3B1;" width="30" *ngIf="affiliateBankPojo?.bankKycStatusConstant != null && affiliateBankPojo?.bankKycStatusConstant != undefined"></iconify-icon>
                    <iconify-icon icon="ic:round-cancel" style="color: red;" width="30" *ngIf="affiliateBankPojo?.bankKycStatusConstant == null || affiliateBankPojo?.bankKycStatusConstant == undefined"></iconify-icon>
                  </span>
                                    <div class="row">
                                        <div class="col-sm-auto d-sm-block d-none">
                                            <img src="assets/img/add-bank-ori-vector.svg" class="card-img" alt="">
                                        </div>
                                        <div class="col-sm text-lg-start text-md-center text-sm-start m-2">
                                            <h5>Step 1</h5>
                                            <p class="small">Add bank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr col-auto">
                            <div class="col-md">
                                <div class="card  p-2">
                                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill" title="{{affiliateBankPojo?.bankVerificationStatusConstant == bankVerificationStatusConstantEnum.Successful ? 'You have completed this step' : 'You have not completed this step'}}">
                    <iconify-icon icon="material-symbols:check-circle" style="color: #34E3B1;" width="30" *ngIf="affiliateBankPojo?.bankVerificationStatusConstant == bankVerificationStatusConstantEnum.Successful"></iconify-icon>
                    <iconify-icon icon="ic:round-cancel" style="color: red;" width="30" *ngIf="affiliateBankPojo?.bankVerificationStatusConstant != bankVerificationStatusConstantEnum.Successful"></iconify-icon>
                  </span>
                                    <div class="row">
                                        <div class="col-sm-auto  d-sm-block d-none">
                                            <img src="assets/img/add-bank-vector.svg" class="card-img" alt="">
                                        </div>
                                        <div class="col-sm text-lg-start text-md-center text-sm-start m-2">
                                            <h5>Step 2</h5>
                                            <p class="small">Verify account</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr col-auto">
                            <div class="col-md">
                                <div class="card  p-2">
                                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill" title="{{affiliateBankPojo?.bankKycStatusConstant == bankKycStatusConstantEnum.Successful ? 'You have completed this step' : 'You have not completed this step'}}">
                    <iconify-icon icon="material-symbols:check-circle" style="color: #34E3B1;" width="30" *ngIf="affiliateBankPojo?.bankKycStatusConstant == bankKycStatusConstantEnum.Successful"></iconify-icon>
                    <iconify-icon icon="ic:round-cancel" style="color: red;" width="30" *ngIf="affiliateBankPojo?.bankKycStatusConstant != bankKycStatusConstantEnum.Successful"></iconify-icon>
                  </span>
                                    <div class="row">
                                        <div class="col-sm-auto  d-sm-block d-none">
                                            <img src="assets/img/kyc-vector.svg" class="card-img" alt="">
                                        </div>
                                        <div class="col-sm text-lg-start text-md-center text-sm-start m-2">
                                            <h5>Step 3</h5>
                                            <p class="small">Perform KYC</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr col-auto">
                            <div class="col-md">
                                <div class="card  p-2">
                                    <div class="row">
                                        <div class="col-sm-auto d-sm-block d-none">
                                            <img src="assets/img/withdrawal-vector.svg" class="card-img" alt="">
                                        </div>
                                        <div class="col-sm text-lg-start text-md-center text-sm-start m-2">
                                            <h5>Step 4</h5>
                                            <p class="small">Initiate payout</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="hr-head">
                    </div>
                    <div class="bottom">
                        <p class="text-start lh-2 ">To receive your payout smoothly, follow the necessary steps. These may include providing personal information, verifying your identity, and meeting specific conditions. Follow instructions carefully to avoid delays. Contact support
                            if you have questions.</p>
                    </div>
                </div>
            </section>
            <!-- process steps -->

            <!-- buttons -->
            <section class="buttons-section mb-2">
                <div class="buttons">
                    <div class="row g-3 ps-2">
                        <span class="col-sm-4 btn rounded-0 py-4 me-1" (click)="tabIndex = 0" [ngClass]="{'btn-primary':tabIndex == 0, 'btn-outline-primary': tabIndex != 0}">BANK TRANSFER</span>
                        <span class="col-1 py-2 me-1 d-sm-block d-none"><hr class="line"></span>
                        <span class="col-sm-4 btn rounded-0 py-4 me-1" (click)="tabIndex = 1" [ngClass]="{'btn-primary':tabIndex == 1, 'btn-outline-primary': tabIndex != 1}">WITHDRAWAL HISTORY</span>
                    </div>
                </div>
            </section>
            <!-- buttons -->

            <!-- start body content  -->
            <app-affiliate-bank *ngSwitchCase="0" [tabIndex]="tabIndex" (emitBankDetails)="emitBankDetails($event)"></app-affiliate-bank>
            <app-affiliate-withdrawal-history *ngSwitchCase="1"></app-affiliate-withdrawal-history>
            <!-- end body content  -->
        </div>
    </div>
</section>