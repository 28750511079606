<div class="modal-content">
  <div class="modal-header rounded-0 bg-primary">
    <h1 class="modal-title fs-5 text-light" id="messageModalLabel"> More
      <iconify-icon
        icon="icon-park-outline:message-unread" style="color: #fff;" width="25" height="25">
      </iconify-icon>
    </h1>
    <button type="button" class="btn-close" (click)="dismissModal()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div>
      <div class="mb-3">
        <div id="sender" role="alert">
          <main class="wrap mb-3">
            <div class="mt-2">

              <div class="ms-2 ">
                <p class="m-0 text-muted">Email Address:</p>
                <p class="">{{this.email || 'N/A'}}</p>
              </div>
              <div class="ms-2 " *ngIf="this.phoneNumber">
                <p class="m-0 text-muted">Phone Number:</p>
                <p class="">{{this.phoneNumber || 'N/A'}}</p>
              </div>
              <div class="ms-2 ">
                <p class="m-0 text-muted">Notes:</p>
                <p class="col-auto bg-white rounded-3" [innerText]="this.notes || ''"></p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</div>

