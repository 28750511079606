/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceCompany } from './insurance-company';
import { InsuranceService } from './insurance-service';


export interface InsuranceCompanyService { 
    status?: InsuranceCompanyService.StatusEnum;
    id?: number;
    insuranceCompany?: InsuranceCompany;
    insuranceService?: InsuranceService;
}
export namespace InsuranceCompanyService {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


