export enum Constant {
  nairaSign = '₦',
  SIM_DETAILS = 'SIM_DETAILS',
  CUSTOMER_PERSONAL_INFO = 'CUSTOMER_PERSONAL_INFO',
  VALIDATED_NIN = 'VALIDATED_NIN',
  CUSTOMER_ADDRESS_INFO = 'CUSTOMER_ADDRESS_INFO',
  CUSTOMER_ADDRESS_INFO_FORM = 'CUSTOMER_ADDRESS_INFO_FORM',
  CUSTOMER_ADDRESS_NAMES = 'CUSTOMER_ADDRESS_NAMES',
  FINAL_DATA = 'FINAL_DATA',
  APPLICATION_DETAILS = 'APPLICATION_DETAILS',
  APPLICATION_DETAILS_PAPER = 'APPLICATION_DETAILS_PAPER',
  NIN_VALIDATION_FORM = 'NIN_VALIDATION_FORM',
  AGENT_SIM_SWAP_PAPER_FORM = 'AGENT_SIM_SWAP_PAPER_FORM',
  FAILED_VALIDATION = 'FAILED_VALIDATION',
  SIM_REPLACEMENT_REQUEST = 'SIM_REPLACEMENT_REQUEST',
  CAPTURED_FACE = 'CAPTURED_FACE',
  CAPTURED_FACE_URL = 'CAPTURED_FACE_URL',
  NIN_VALIDATION_RESULT = 'NIN_VALIDATION_RESULT',
  APPLICATION_NUMBER = 'APPLICATION_NUMBER',
  ALREADY_HAS_NIN_VALIDATION = 'ALREADY_HAS_NIN_VALIDATION',
  CAPTURED_FINGERPRINT = 'CAPTURED_FINGERPRINT',
  SIM_SERIAL_DETAILS = 'SIM_SERIAL_DETAILS',


  SIM_REG_PAGE_1_FORM ='SIM_REG_PAGE_1_FORM',
  SIM_REG_PAGE_1_NIN_DETAILS ='SIM_REG_PAGE_1_NIN_DETAILS',
  SIM_REG_PAGE_1_MAX_FOUR_DETAILS ='SIM_REG_PAGE_1_MAX_FOUR_DETAILS',
  SIM_REG_TRACKING_ID ='SIM_REG_TRACKING_ID',

  SIM_REG_PAGE_2_CAPTURED_FACE_BASE64 ='SIM_REG_PAGE_2_CAPTURED_FACE_BASE64',
  SIM_REG_PAGE_2_CAPTURED_FINGER_DATA ='SIM_REG_PAGE_2_CAPTURED_FINGER_DATA',
  SIM_REG_PAGE_2_CAPTURED_FACE_URL ='SIM_REG_PAGE_2_CAPTURED_FACE_URL',
  SIM_REG_PAGE_2_FACE_MATCH_RESULT ='SIM_REG_PAGE_2_FACE_MATCH_RESULT',

  SIM_REG_PAGE_3_SIM_DETAILS = 'SIM_REG_PAGE_3_SIM_DETAILS',

  HAS_OTP ="HAS_OTP",

  ACCOUNT_SELECTED ="ACCOUNT_SELECTED",
  INTERNATIONAL_STATE ="38",
  NIGERIA_CODE ="1",


  SIM_REG_PAGE_4_FORM = 'SIM_REG_PAGE_4_FORM',


  CONSENT_URL = 'CONSENT_URL',
  CUSTOMER_CONSENT = 'CUSTOMER_CONSENT',
  CAMERA_DEVICE_ID = 'CAMERA_DEVICE_ID',
  BYPASS_FINGER_REASON = 'BYPASS_FINGER_REASON',
  MULTIPLE_CAPTURE_DEVICE = 'IBSCAN_ULTIMATE',


  //Agent onBoarding
  AGENT_ONBOARDING_TRACKING_ID = 'AGENT_ONBOARDING_TRACKING_ID',

  WINDOWS_DEVICE_ID='WINDOWS_DEVICE_ID'
}
