import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ActivityLogControllerService, ActivityLogDto, BASE_PATH} from "dd-core-api-sdk";
import {
  AccountSwitchingModule,
  NoWhiteSpacePipe,
  PipesModule,
  UnderscoreToSpacePipe,
} from "dd-shared-lib";
import {NgBootstrapFormValidationModule} from "ng-bootstrap-form-validation";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import {ToastrModule} from "ngx-toastr";
import {environment} from "src/environments/environment";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {HomeComponent} from "./components/home/home.component";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {BsModalService, ModalModule} from "ngx-bootstrap/modal";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {TooltipConfig, TooltipModule} from "ngx-bootstrap/tooltip";
import {CarouselModule} from "ngx-bootstrap/carousel";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "angularx-social-login";
import {ReviewPolicyComponent} from "./components/review-policy/review-policy.component";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {PopoverModule} from "ngx-bootstrap/popover";
import {SpaceToUnderscorePipe} from "../../projects/dd-shared-lib/src/lib/pipe/space-to-underscore.pipe";
import {JoyrideModule, JoyrideStepComponent} from "ngx-joyride";
import {
  KeycloakAngularModule,
  KeycloakEventType,
  KeycloakService,
} from "keycloak-angular";
import {AuthenticationServiceKeycloak} from "./services/AuthenticationServiceKeycloak";
import {GeneralHomePageComponent} from "./components/home/general-home-page/general-home-page.component";
import {NavHeaderComponent} from "./components/nav-header/nav-header.component";
import {FooterComponent} from "./components/footer/footer.component";
import {DentalSignupComponent} from "./components/signup/dental-signup/dental-signup.component";
import {CreateDentalOfficeComponent} from "./components/signup/create-dental-office/create-dental-office.component";
import {OneTimePasswordComponent} from "./components/one-time-password/one-time-password.component";
import {SuccessDialogComponent} from "./shared/success-dialog/success-dialog.component";
import {CreateDentalProfessionalComponent} from "./components/signup/create-dental-professional/create-dental-professional.component";
import {DentalAccountUpdateComponent} from "./components/signup/dental-account-update/dental-account-update.component";
import {VerifyEmailComponent} from "./injector/verify-email/verify-email.component";
import {FieldErrorComponent} from "./shared/field-error/field-error.component";
import {PasswordComponent} from "./shared/password/password.component";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {HttpInterceptorServiceKeycloak} from "./interceptor/http-interceptor-keycloak";
import {LoginComponent} from "./components/login/login.component";
import {SuccessModalComponent} from "./shared/success-modal/success-modal.component";
import {UpdateSocialProfileComponent} from "./components/signup/update-social-profile/update-social-profile.component";
import {VerifyDentalOfficeComponent} from "./components/verify-dental-office/verify-dental-office.component";
import {TermsAndConditionsComponent} from "./components/terms-and-conditions/terms-and-conditions.component";
import {PrivacyPolicyComponent} from "./components/privacy-policy/privacy-policy.component";
import {DashboardModule} from "./modules/dashboard/dashboard.module";
import {SharedModule} from "./shared/shared.module";
import {InboundModule} from "./modules/dRefer/inbound-referral/inbound.module";
import {OutboundModule} from "./modules/dRefer/outbound-referrals/outbound.module";
import {NgbDateParserFormatter, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgbDateCustomParserFormatter} from "./shared/NgbDateCustomParserFormatter";
import {AccountCreatedSuccessComponent} from "./components/account-created-success/account-created-success.component";
import {BillingModule} from "./modules/billing/billing.module";
import {ViewReferralComponent} from "./components/view-referral/view-referral.component";
import {NgOtpInputModule} from "ng-otp-input";
import {InvitesModule} from "./modules/invites/invites.module";
import {AccountSettingsModule} from "./modules/account-settings/account-settings.module";
import {InvitesSignupComponent} from "./components/signup/invites-signup/invites-signup.component";
import {ServiceWorkerModule} from "@angular/service-worker";
import {NgxCaptureModule} from "ngx-capture";
import {AdminModule} from "./modules/admin/admin.module";
import { ExtranetPricingComponent } from './extranet-pricing/extranet-pricing.component';
import {AffiliateModule} from "./modules/affiliates/affiliate.module";
import { ErrorPageComponent } from './error-page/error-page.component';
import { AfiliateTermsAndConditionsComponent } from './afiliate-terms-and-conditions/afiliate-terms-and-conditions.component';
import { CreateOfficeAffiliateComponent } from './components/signup/create-office-affiliate/create-office-affiliate.component';
import { LearnMoreModalComponent } from './learn-more-modal/learn-more-modal.component';
import { FirstContactWizardComponent } from './components/first-contact/first-contact-wizard/first-contact-wizard.component';
import { FirstContactIComponent } from './components/first-contact/first-contact-i/first-contact-i.component';
import { FirstContactIiComponent } from './components/first-contact/first-contact-ii/first-contact-ii.component';
import {ArchwizardModule} from "angular-archwizard";
import { FirstContactIiiComponent } from './components/first-contact/first-contact-iii/first-contact-iii.component';
import { InsuranceImageCaptureComponent } from './components/first-contact/insurance-image-capture/insurance-image-capture.component';
import {WebcamModule} from "ngx-webcam";
import { FirstContactVideoComponent } from './components/first-contact/first-contact-video/first-contact-video.component';
import { InsuranceSnapshotModalComponent } from './components/insurance-snapshot-modal/insurance-snapshot-modal.component';
import { AppointmentPreferenceModalComponent } from './components/appointment-preference-modal/appointment-preference-modal.component';
import {LastContactComponent} from "./components/first-contact/last-contact/last-contact.component";
import {AngularFireModule} from "@angular/fire/compat";
import {NotificationService} from "./notifications/notification-service";
import {AngularFireMessagingModule} from "@angular/fire/compat/messaging";
import { ResendNotificationModalComponent } from './shared/referral-list/resend-notification-modal/resend-notification-modal.component';
import {CurrencyMaskModule} from "ng2-currency-mask";
import {OwlDateTimeModule} from "@danielmoncada/angular-datetime-picker";
import { ExtranetReferralDetailsComponent } from './extranet-referral-details/extranet-referral-details.component';



function initializeKeycloak(keycloak: KeycloakService, authenticationService: AuthenticationServiceKeycloak, action: ActivityLogControllerService, notification: NotificationService) {

  keycloak.keycloakEvents$
    .subscribe(async value => {
      const currentLoginActivity = localStorage.getItem('ACTIVITY_LOGIN');
      if (value.type === KeycloakEventType.OnAuthSuccess) {
        await authenticationService.fetchUser().toPromise().then(res => {
          const activityLogDto: ActivityLogDto = {
            userId: res.userId,
            username: res.username,
            action: ActivityLogDto.ActionEnum.Login
          }
          if (currentLoginActivity == null || currentLoginActivity === 'true') {
            action.logActivity({activityLogDto: activityLogDto}).subscribe();
            localStorage.setItem("ACTIVITY_LOGIN", 'false');
          }

        });

        notification.requestPermission();
      }
    });
  const orgOpen = window.open;
  window.open = function (url?: string, target?: string, features?: string, replace?: boolean) {
    if (url && url.includes(environment.url) && !url.includes('access_token')) {
      keycloak.getToken().then(value => {
        if (value) {
          const updatedUrl = `${url}${url.includes('?') ? '&' : '?'}access_token=${value}`;
          return orgOpen(updatedUrl, target, features, replace);
        } else {
          return orgOpen(url, target, features, replace);
        }
      });
    } else {
      return orgOpen(url, target, features, replace);
    }
  };
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        checkLoginIframe:environment.production,
        enableLogging: !environment.production,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: (environment.production)?window.location.origin + '/assets/silent-check-sso.html':null,
      },
    });
}

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: "MM/DD/YYYY",
  });
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ReviewPolicyComponent,
    GeneralHomePageComponent,
    NavHeaderComponent,
    FooterComponent,
    DentalSignupComponent,
    CreateDentalOfficeComponent,
    OneTimePasswordComponent,
    SuccessDialogComponent,
    CreateDentalProfessionalComponent,
    DentalAccountUpdateComponent,
    VerifyEmailComponent,
    PasswordComponent,
    LoginComponent,
    SuccessModalComponent,
    UpdateSocialProfileComponent,
    VerifyDentalOfficeComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    AccountCreatedSuccessComponent,
    ViewReferralComponent,
    InvitesSignupComponent,
    ExtranetPricingComponent,
    ErrorPageComponent,
    AfiliateTermsAndConditionsComponent,
    CreateOfficeAffiliateComponent,
    LearnMoreModalComponent,
    InsuranceSnapshotModalComponent,
    AppointmentPreferenceModalComponent,
    FirstContactWizardComponent,
    FirstContactIComponent,
    FirstContactIiComponent,
    FirstContactIiiComponent,
    InsuranceImageCaptureComponent,
    FirstContactVideoComponent,
    LastContactComponent,
    ResendNotificationModalComponent,
    ExtranetReferralDetailsComponent
  ],
  imports: [
    BrowserModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot({preventDuplicates: true}),
    AccordionModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    PipesModule,
    TimepickerModule.forRoot(),
    CollapseModule.forRoot(),
    JoyrideModule.forRoot(),
    SocialLoginModule,
    NgxMaskModule.forRoot(maskConfig),
    ProgressbarModule.forRoot(),
    AccountSwitchingModule,
    NgxIntlTelInputModule,
    DashboardModule,
    InboundModule,
    OutboundModule,
    BillingModule,
    NgbModule,
    NgOtpInputModule,
    InvitesModule,
    AccountSettingsModule,
    DashboardModule,
    AffiliateModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxCaptureModule,
    AdminModule,
    ArchwizardModule,
    WebcamModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    FormsModule,
    CurrencyMaskModule,
    OwlDateTimeModule
  ],
  providers: [
    BsModalService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationServiceKeycloak, ActivityLogControllerService, NotificationService],
    },
    {
      provide: BASE_PATH,
      useValue: environment.ddCoreApiBaseUrl,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorServiceKeycloak,
      multi: true,
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("789457722051171"),
          },
        ],
        onError: (err) => {
        },
      } as SocialAuthServiceConfig,
    },
    TooltipConfig,
    {provide: BsDatepickerConfig, useFactory: getDatepickerConfig},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    UnderscoreToSpacePipe,
    NoWhiteSpacePipe,
    SpaceToUnderscorePipe,
  ],
  bootstrap: [AppComponent],
  exports: [FieldErrorComponent],
  entryComponents: [JoyrideStepComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
