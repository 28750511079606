/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppointmentType } from './appointment-type';
import { TimeSlotRecurrence } from './time-slot-recurrence';
import { Schedule } from './schedule';


export interface TimeSlotDefinition { 
    startTime?: string;
    endTime?: string;
    dateCreated?: string;
    lastUpdated?: string;
    status?: TimeSlotDefinition.StatusEnum;
    startDate?: string;
    name?: string;
    id?: number;
    appointmentType?: AppointmentType;
    timeSlotRecurrence?: TimeSlotRecurrence;
    schedule?: Schedule;
}
export namespace TimeSlotDefinition {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


