import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'header'
})
export class HeaderPipe implements PipeTransform {

  transform(value: string, args): string {
    if (value === null || value.trim() == '') return value;
    const headerSize:number = args;
    return `<h${headerSize}>${value}</h${headerSize}>`;
  }
}
