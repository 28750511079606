import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {BwFileControllerService, HelpControllerService, UploadFileRequestParams} from "dd-core-api-sdk";
import {HttpEventType} from "@angular/common/http";
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-dashboard-help',
  templateUrl: './dashboard-help.component.html',
  styleUrls: ['./dashboard-help.component.css']
})
export class DashboardHelpComponent implements OnInit {
  formGroup: FormGroup;
  private loggedInEmail: string;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';
  img: string;
  bwFileIdList: Array<any> = [];
  fileDocs: Array<any> = [];
  progress: number;
  fileName: any;

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationServiceKeycloak,
              private helpService: HelpControllerService,
              private bwFileController: BwFileControllerService) {
    this.authenticationService.getUser().subscribe(res => {
      if (res) {
        this.loggedInEmail = res?.email;
      }
    });
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      email: [this.loggedInEmail, [Validators.required]],
      description: ['', [Validators.required]],
      imageFileId: [''],
    });
  }

  submitHelp() {
    this.formGroup.markAllAsTouched();
    const data = this.formGroup.getRawValue();

    this.helpService.logHelpData({
      helpDto: {
        senderEmail: data.email, fileId: data.imageFileId ? +data.imageFileId : null,
        description: data.description
      }
    }).subscribe({
      next: (v) => {
        this.clearForm();
        this.isError = false;
        this.showErrorMessage('Thank you for your feedback we would reply shortly.');
      },
      error: (e) => {
        this.isError = true;
        this.showErrorMessage('Error in sending help!!. Try again later.')
      },
      complete: () => {
      }
    });
  }

  takeScreenShot() {


  }

  onFileChange(event) {
    let f = event.target.files[0];
    if (f.size > 1024 * 1024 * 10) {
      this.isError = true;
      this.showErrorMessage('File size must not be more than 10MB');
      return;
    }

    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "image/gif", "application/pdf"];
    const isValidExtension = validExtensions.indexOf(f.type) > -1;

    if (!isValidExtension) {
      this.isError = true;
      this.showErrorMessage('Invalid File Type. Select a PDF or an image');
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.fileName = f.name;
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        this.uploadXrayFiles(reader.result, f.type, f.name)
      }
    }
  }

  uploadXrayFiles(base64Data, contentType, filename) {
    const uploadFileDto: UploadFileRequestParams = {
      uploadFileDto: {
        file: base64Data,
        contentType: contentType,
        fileName: filename
      }
    }

    this.bwFileController.uploadFile(uploadFileDto, "events", true)
      .subscribe(resp => {
        if (resp.type === HttpEventType.Response) {
          this.progress = 100;
        }
        if (resp.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * resp.loaded / resp.total);
        }
        // @ts-ignore
        const fileId = resp?.body?.fileId;
        // @ts-ignore
        const fileName = resp?.body?.fileName;
        // @ts-ignore
        const contentType = resp?.body?.contentType;

        const file = {fileId: fileId, fileName: fileName, contentType: contentType};
        this.fileDocs.push(file);

        this.bwFileIdList.push(fileId);
        this.formGroup.patchValue({imageFileId: fileId})
      });
  }

  onFileDelete(index) {
    this.bwFileController.deleteSingleFile({id: this.bwFileIdList[index]}).subscribe(res => {
      this.bwFileIdList.splice(index, 1);
      this.fileDocs.splice(index, 1);
    });
  }

  clearForm() {
    this.formGroup.markAsUntouched();
    this.formGroup.reset();
    this.fileDocs = [];
    this.bwFileIdList = [];
  }

  getFileURL(file, id) {
    console.log(file)
    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
    const isValidExtension = validExtensions.indexOf(file.contentType) > -1;

    if (isValidExtension) {
      return environment.siteUrl + "/files/" + id;
    }
    return "/assets/img/image.png";
  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

}
