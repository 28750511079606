import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";

@Component({
  selector: 'app-completion-modal',
  templateUrl: './completion-modal.component.html',
  styleUrls: ['./completion-modal.component.css']
})
export class CompletionModalComponent implements OnInit {

  @Output()
  onSubmitButtonClick = new EventEmitter();
  @Output()
  onSuccessButtonClick = new EventEmitter();
  @Input() isSuccess = true;
  @Input() shouldNavigate = false;
  @Input() title;
  @Input() subTitle;
  @Input() buttonText;
  @Input() buttonRoute;
  @Input() showPostReferral = false;
  @Input()
  patientName: string;
  @Input()
  professionalName: string;
  @Input()
  dentalOfficeName: string;
  @Input()
  referralRequestId: number;

  constructor(public modalRef: BsModalRef, private modalService: BsModalService, private router: Router) {
  }

  ngOnInit(): void {
  }

  buttonClicked() {
    if (this.shouldNavigate) {
      window.scroll(0, 0);
      this.onSuccessButtonClick.emit();
      this.modalRef.hide();
    } else {
      window.scroll(0, 0);
      this.onSubmitButtonClick.emit();
      this.modalRef.hide();
    }

  }

  postReferralModal() {
    this.onSubmitButtonClick.emit();
    this.modalRef.hide();
  }
}
