/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SupportingDocumentType { 
    supportingDocumentEnum?: SupportingDocumentType.SupportingDocumentEnumEnum;
    supportingDocumentBase64?: string;
    dateCreated?: string;
    bwFileId?: number;
}
export namespace SupportingDocumentType {
    export type SupportingDocumentEnumEnum = 'XRAY' | 'CONSULTATION_DOC' | 'MEDICAL_HISTORY';
    export const SupportingDocumentEnumEnum = {
        Xray: 'XRAY' as SupportingDocumentEnumEnum,
        ConsultationDoc: 'CONSULTATION_DOC' as SupportingDocumentEnumEnum,
        MedicalHistory: 'MEDICAL_HISTORY' as SupportingDocumentEnumEnum
    };
}


