<div>
  <div class="container">
    <div class="row">
      <div class="d-flex align-items-center">
        <p class="fs-3 fw-bold mt-5">
          <iconify-icon icon="carbon:connection-receive" class="fs-3 me-2"></iconify-icon>
          My Invites
        </p>
      </div>

      <div class="mt-0 mb-3" *ngIf="!user?.dentalProfessional">


        <div class="row">
          <div class="col-md-6 d-flex align-items-center">
            <div
              (click)="goToCreate()"
              *ngIf="!this.user?.dentalProfessional"
              style="border-radius: 10px; !important; border: 2px solid #369dbf;"
              class="pointer btn refer-btn-custom-top d-flex align-items-center p-2 justify-content-between"
            >
              <h4 class="mb-0 mb-0 mx-2">Click To Invite Dental and Specialist Offices</h4>
              <div
                class="badge rounded-pill text-bg-primary p-1 d-flex justify-content-end align-items-center text-end mb-0 mx-1"
              >
                <iconify-icon icon="carbon:add" class="fs-5"></iconify-icon>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
            <button (click)="toggleFilter()" class="btn btn-outline-secondary d-flex justify-content-center mx-3"
                    type="button"
                    data-bs-toggle="modal">
              <span *ngIf="!showFilters"><iconify-icon inline icon="basil:filter-outline" class="me-2 "></iconify-icon></span>
              <span *ngIf="showFilters"><iconify-icon inline icon="mdi:filter-off-outline" class="me-2"></iconify-icon></span>
              {{ showFilters ? 'Hide' : 'Show '}} Filters
            </button>
            <button (click)="goToCreate()" class="btn btn-primary d-flex justify-content-center px-5"
                    type="button"
                    data-bs-toggle="modal">
              <iconify-icon inline icon="wpf:sent" class="me-2 fs-6"></iconify-icon>
              Invite Referring Partners
            </button>
          </div>
        </div>
      </div>

      <div class="container-fluid" *ngIf="showFilters">
        <div class="row">
          <div class="overview-card p-3">
            <div class="filter-card-bg p-md-4 py-3">
              <div *ngIf="showErrorMessageTrigger">
                <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show" role="alert"
                     [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
                  <p class="m-0 p-0 d-flex align-items-start">
                    <span>
                      <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                      {{ getErrorMessage() | removeUnderscores }}
                    </span>

                    <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                    </iconify-icon>
                  </p>
                </div>
              </div>
              <div class="container-fluid">
                <form class="row" [formGroup]="this.form">
                  <div class="col-xl-3 col-md-4 col-sm-6 mb-2 mb-md-0">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="firstName" placeholder="e.g John Doe"
                             aria-label="Name"
                             formControlName="name"/>
                      <label for="firstName" class="">Name</label>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-4 col-sm-6 mb-2 mb-md-0">
                    <div class="form-floating">
                      <select class="form-select" id="fs" aria-label="Floating label select"
                              formControlName="statusConstant">
                        <option [selected]="true" [value]="''" class="form-label"> Select status...</option>
                        <option *ngFor="let item of invitesStatusEnum" [value]="item">
                          {{ item | uppercase | removeUnderscores }}
                        </option>
                      </select>
                      <label for="fs">Status </label>
                    </div>
                  </div>
                  <div class="col-xl-2 col-md-4 col-sm-6 mb-2 mb-md-0" style="
    margin-top: -1.12rem;
">
                    <div class="mb-3">
                      <label class="rfr-label small"> Date (from) </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="MM-DD-YYYY" formControlName="startDate"
                               [minDate]="{ year: 1910, month: 1, day: 1 }"
                               [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }" ngbDatepicker
                               #startDate="ngbDatepicker" (click)="startDate.toggle()"/>
                        <span class="btn-primary-outline input-group-text" (click)="startDate.toggle()">
                          <i class="fa fa-calendar"></i>
                          </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2 col-md-4 col-sm-6 mb-2 mb-md-0" style="
    margin-top: -1.12rem;
">
                    <div class="mb-3">
                      <label class="rfr-label small"> Date (to) </label>
                      <div class="input-group">
                        <input [minDate]="{ year: 1910, month: 1, day: 1 }"
                               [maxDate]="{ year: maxYear(), month: +maxMonth(), day: +maxDay() }"
                               ngbDatepicker #endDate="ngbDatepicker" formControlName="endDate"
                               (click)="endDate.toggle()" class="form-control"
                               placeholder="MM-DD-YYYY"/>
                        <span class="btn-primary-outline input-group-text" (click)="endDate.toggle()">
                          <i class="fa fa-calendar"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-2 col-md-8 col-sm-12 mb-2 mb-md-0">
                    <div class="d-flex justify-content-end float-end" style="white-space: nowrap;">
                      <button
                        class="bg-primary border-0 d-flex justify-content-center align-items-center px-3 text-light float-end"
                        (click)="loadInvitations()" style="margin-top: 3px; padding: .35rem;">
                        <iconify-icon inline icon="iconoir:search" *ngIf="!this.searching" class="me-2 fs-5" width="25"
                                      height="25">
                        </iconify-icon>
                        <iconify-icon *ngIf=" this.searching" icon="eos-icons:bubble-loading" style="color: #ffffff ">
                        </iconify-icon>
                        Apply Filter
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container-fluid">
        <div class="row mt-4">
          <div class="overview-card-table p-3">
            <div class="text-secondary my-3">
              <h5 class="m-2 fw-bold">Invites</h5>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead class="py-3">
                <tr class="overview-card shadow-sm">
                  <th class="">ID</th>
                  <th class="">Invited User</th>
                  <th class="">Status</th>
                  <th class="">Date Sent</th>
                  <th class="">Type</th>
                  <th>Action</th>
                </tr>
                </thead>

                <tbody class="text-muted">
                <tr *ngFor="let invites of resultSubject.value.results; let i = index">
                  <td class="align-middle" data-label="ID">{{ offset + i + 1 }}</td>
                  <td class="align-middle" data-label="Name" *ngIf="invites.dentalOffice.id != this.officeID">
                    <p class="mb-0 fw-bold">{{ invites?.dentalOffice?.name| titlecase}}</p>
                  </td>
                  <td class="align-middle" data-label="Name" *ngIf="invites.dentalOffice.id == this.officeID">
                    <p class="mb-0 fw-bold">{{ invites.dentalOfficeName || invites?.name || invites?.invitedUser?.name | titlecase}}</p>
                  </td>
                  <td class="align-middle" data-label="Status">{{ invites.invitationStatus | uppercase}}</td>
<!--                  <td class="align-middle" data-label="Notified?" *ngIf="invites.dentalOffice.id == this.officeID">-->
<!--                    {{ invites.notificationSent ? invites.notificationSent.valueOf().toString() : 'N/A' | uppercase}}-->
<!--                  </td>-->

                  <td class="align-middle" data-label="Date Sent">
                    {{(formatUTCMoment(invites.dateCreated) | date: 'd MMM, y, h:mm a': this.utcOffsetMinutes || 'N/A')}}
                  </td>

                  <td class="align-middle" data-label="Type">{{invites.type == type.DentalPractitioner ? 'DENTAL PROFESSIONAL' : invites.type | uppercase | removeUnderscores}}</td>

                  <td  *ngIf="invites.invitationStatus == invitesStatus.Pending && invites.dentalOffice.id != this.officeID">
                    <span class="pointer" style="text-decoration: underline" data-bs-toggle="dropdown">Action</span>
                    <iconify-icon class="iconify pointer"  data-icon="ri:more-2-line"></iconify-icon>
                    <ul class="dropdown-menu dropdown-menu-start">
                      <li class="pointer">
                        <a class="dropdown-item" (click)="showInviteOptionModal(invites.id, invites.dentalOffice.id, false, i)">
                          Accept Invite
                          <iconify-icon icon="ep:edit" style="width: 40px; color: rgb(39, 40, 39);"></iconify-icon>
                          </a>
                      </li>
                      <li class="pointer">
                        <a class="dropdown-item" (click)="showInviteOptionModal(invites.id, invites.dentalOffice.id, true, i)">
                          Decline Invite
                          <iconify-icon icon="ri:delete-bin-6-line" style="width: 40px; color: rgb(246, 19, 19);"></iconify-icon>
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td class="table-action-dropdown align-middle" *ngIf="invites.invitationStatus == invitesStatus.Pending && invites.dentalOffice.id == this.officeID">
                    <iconify-icon icon="ic:outline-pending-actions" style="width: 40px; color: rgb(40,167,69);"></iconify-icon>
                  </td>
                  <td class="table-action-dropdown align-middle" *ngIf="invites.invitationStatus != invitesStatus.Pending">
                    <iconify-icon icon="material-symbols:check-box-outline-sharp" style="width: 40px; color: rgb(40,167,69);"></iconify-icon>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <span *ngIf="!resultSubject.value.results || resultSubject.value.total == 0"
                    class="d-flex justify-content-center text-center text-uppercase my-4 w-100 text-bolder">
                {{
                resultSubject.value.results
                  ? "NO DATA/RECORDS"
                  : "APPLY FILTERS TO VIEW INVITES"
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="align-items-center d-flex justify-content-between rounded-top px-2 py-2 mt-3"
           style="margin-bottom: -1rem; color: #4e3367">
        <app-page-length [pageLength]="getFilter().limit || 10" (pageLengthChanged)="changeLimit($event)">
        </app-page-length>

        <div class="add-group-btn text-right mt-3 mb-3">
          <pagination (pageChanged)="onPageChange($event)" [boundaryLinks]="true" [itemsPerPage]="limit || 10"
                      [maxSize]="5"
                      [totalItems]="resultSubject.value.total || 0" firstText="&laquo;" lastText="&raquo;"
                      nextText="&rsaquo;"
                      previousText="&lsaquo;"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>
