/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Wallet } from './wallet';
import { AffiliateBank } from './affiliate-bank';
import { PortalUser } from './portal-user';


export interface WalletTransaction { 
    amount?: number;
    description?: string;
    type?: WalletTransaction.TypeEnum;
    transactionCode?: string;
    walletTransactionStatus?: WalletTransaction.WalletTransactionStatusEnum;
    createdAt?: string;
    status?: WalletTransaction.StatusEnum;
    updatedAt?: string;
    expectedArrivalDate?: string;
    transferSuccessful?: boolean;
    transferRetryCount?: number;
    payoutRetryCount?: number;
    payoutTransactionId?: string;
    id?: number;
    portalUser?: PortalUser;
    wallet?: Wallet;
    affiliateBank?: AffiliateBank;
}
export namespace WalletTransaction {
    export type TypeEnum = 'DEBIT' | 'CREDIT' | 'PAYMENT' | 'REVERSAL' | 'FAILED';
    export const TypeEnum = {
        Debit: 'DEBIT' as TypeEnum,
        Credit: 'CREDIT' as TypeEnum,
        Payment: 'PAYMENT' as TypeEnum,
        Reversal: 'REVERSAL' as TypeEnum,
        Failed: 'FAILED' as TypeEnum
    };
    export type WalletTransactionStatusEnum = 'PROCESSING' | 'FAILED_TRANSFER' | 'REVERSED' | 'PAID' | 'PENDING' | 'TRANSFERED' | 'IN_TRANSIT' | 'FAILED_PAYOUT';
    export const WalletTransactionStatusEnum = {
        Processing: 'PROCESSING' as WalletTransactionStatusEnum,
        FailedTransfer: 'FAILED_TRANSFER' as WalletTransactionStatusEnum,
        Reversed: 'REVERSED' as WalletTransactionStatusEnum,
        Paid: 'PAID' as WalletTransactionStatusEnum,
        Pending: 'PENDING' as WalletTransactionStatusEnum,
        Transfered: 'TRANSFERED' as WalletTransactionStatusEnum,
        InTransit: 'IN_TRANSIT' as WalletTransactionStatusEnum,
        FailedPayout: 'FAILED_PAYOUT' as WalletTransactionStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


