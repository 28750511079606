<div class="m-0">
  <div class="modal-header">
    <div class="pull-left">
      <h6 class="modal-title" id="modal-title">{{ header }}</h6>
    </div>
    <span
      (click)="close()"
      *ngIf="showCloseButton"
      class="close pull-right"
      type="button"
    >
      <span aria-hidden="true"><i class="fa fa-close"></i></span>
    </span>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <div>
        <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M54.1243 34.7372H63.8754V44.4884H54.1243V34.7372ZM54.1243 54.2395H63.8754V83.4929H54.1243V54.2395ZM58.9998 10.3594C32.0867 10.3594 10.2441 32.2019 10.2441 59.1151C10.2441 86.0282 32.0867 107.871 58.9998 107.871C85.913 107.871 107.756 86.0282 107.756 59.1151C107.756 32.2019 85.913 10.3594 58.9998 10.3594ZM58.9998 98.1196C37.4986 98.1196 19.9953 80.6163 19.9953 59.1151C19.9953 37.6138 37.4986 20.1105 58.9998 20.1105C80.5011 20.1105 98.0044 37.6138 98.0044 59.1151C98.0044 80.6163 80.5011 98.1196 58.9998 98.1196Z" fill="#3894FF"/>
        </svg>
      </div>
      <div
        style="
          font-size: 1rem;
          font-weight: 600;
          margin: 0 auto 10px;
          max-width: 340px;
        "
      >
        {{ body }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="showConfirmButton"
      (click)="confirm(true)"
      class="btn btn-primary mt-1"
      type="button"
      [disabled]="loading"
    >
      <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i> &nbsp;
      {{ confirmButtonText }}
    </button>
    <span
      (click)="confirm(false)"
      class="btn btn-outline-danger mt-1 mr-2"
      type="button"
    >
      <i class="fa fa-close mx-2 mr-2"></i>{{ cancelButtonText }}
    </span>
  </div>
</div>
