<div class="container mt-4">
  <div class="row">
    <div class="d-flex align-items-center">
      <p class="fs-3 fw-bold my-3">
        <iconify-icon
          inline
          icon="iconoir:mail-out"
          class="fs-3 me-2"
        ></iconify-icon>
        Outbound
      </p>
    </div>

    <app-referral-stat-card></app-referral-stat-card>

    <div class="">
      <div class="my-3">
        <div class="d-flex justify-content-between align-items-center">
        </div>
      </div>
      <app-referral-list *ngIf="!showDraft"></app-referral-list>
      <app-referral-draft-list *ngIf="showDraft"></app-referral-draft-list>
    </div>
  </div>
</div>
