import {Component, Input, OnInit} from '@angular/core';
import {UserAccountKeycloak} from "../../../../services/UserAccountKeycloak";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {BehaviorSubject} from "rxjs";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {Constant} from "../../../../shared/constant";
import {
  AffiliateUserControllerService,
  AffiliateUserImageControllerService,
  AffiliateUserResponse
} from "dd-core-api-sdk";

@Component({
  selector: 'app-affiliate-sidebar',
  templateUrl: './affiliate-sidebar.component.html',
  styleUrls: ['./affiliate-sidebar.component.css']
})
export class AffiliateSidebarComponent implements OnInit {

  userAccount: UserAccountKeycloak;
  @Input() user: UserKeycloak;
  @Input() notificationTot: any;
  currentRoute: string;
  count: number;
  @Input()
  screenShotEle: any;
  pictureId = new BehaviorSubject<any>({id: ''});
  newInbound: number;
  affiliateProfile: AffiliateUserResponse


  constructor(private authentication: AuthenticationServiceKeycloak,
              private affiliateUserService: AffiliateUserControllerService,
              private router: Router) {}

  ngOnInit(): void {
    this.userAccount = AuthenticationServiceKeycloak._currentUserAccount;
    this.router.events.subscribe((url:any) => this.getCurrentUrl(url.url));

    if (AuthenticationServiceKeycloak._currentUserAccount?.otherData?.displayPictureId) {
      this.pictureId.next({id: String(AuthenticationServiceKeycloak?._currentUserAccount?.otherData?.displayPictureId)});
    }
    this.affiliateUserService.getAffiliateUserProfile().subscribe(res=>this.affiliateProfile=res)


    this.affiliateUserService.getAffiliateUserProfile().subscribe(res => {
      this.affiliateProfile = res;

    })

    this.affiliateUserService.getAffiliateUserProfile().subscribe(res => {
      this.affiliateProfile = res;

    })

  }
  getFileURL() {
    if (this.affiliateProfile?.profileImageId != null) {
      return environment.siteUrl + "/files/" + this.affiliateProfile?.profileImageId;
    }
    return "./assets/img/user.png";
  }

  selectAccount(account: UserAccountKeycloak) {
    this.authentication.setCurrentAccount(account);
    sessionStorage.setItem(Constant.ACCOUNT_SELECTED, 'true');
    window.location.replace('/dashboard');
  }

  getCurrentUrl(url: string){
    if(url != undefined || url != null){
      this.currentRoute = url;
    }
  }


  logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.authentication.logoutClick(window.location.origin);
  }
}
