import { Component } from '@angular/core';

@Component({
  selector: 'lib-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.css']
})
export class DummyComponent {

}
