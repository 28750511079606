import { Component, OnInit } from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
      private keycloak: KeycloakService,
      private authenticationService: AuthenticationServiceKeycloak) { }

  ngOnInit(): void {
    this.login();
  }

  async login() {
    this.authenticationService.logout(
        this.keycloak.getKeycloakInstance().createLoginUrl({
          redirectUri: window.location.origin + '/dashboard',
          prompt: 'login',
        })
    );
  }

}
